import { CheckEquiomentIsFreeReq } from './../book-appointment/book-appointment';
import { SharedDataService } from './../services/shared-data.service';
import { faCalendarAlt, faCog } from '@fortawesome/free-solid-svg-icons';
import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription, ReplaySubject } from 'rxjs';
import { SharedApiService } from '../services/shared-api.service';
import { environment } from '../../environments/environment';
import { debounceTime } from 'rxjs/operators';
import { RedirectService } from '../services/redirect.service';
import { ApiService } from '../core/http/api.service';
import { ToastrService } from 'ngx-toastr';
import { consoleTestResultHandler } from 'tslint/lib/test';

declare var $: any;

@Component({
  selector: 'app-multicalendar-week',
  templateUrl: './multicalendar-week.component.html',
  styleUrls: ['./multicalendar-week.component.css']
})
export class MulticalendarWeekComponent implements OnInit {

  faCog = faCog;
  faCalendarAlt = faCalendarAlt;
  showSettings = false;

  @ViewChild('idopontkezelo') closeModal: ElementRef;
  @ViewChild('showBookingModal') showBookingModal: ElementRef;

  equipmentsControl: FormControl;

  constructor(public authService: AuthService,
    private http: HttpClient,
    private router: Router,
    public sharedDataService: SharedDataService,
    private sharedApiService: SharedApiService,
    private apiService: ApiService,
    private toastr: ToastrService,
    private openTab: RedirectService
  ) { }

  @Input() calendarID: string;
  @Input() twoColumns: boolean;

  apiUrl = environment.apiUrl;
  calendarLoading = false;
  selectedCsId = null;
  selectedCsIdSubscription: Subscription;
  collumnHeader = 1;

  error = false;
  errorDescription = '';

  dateFrom = this.sharedDataService.getCurrentDate();

  updateCalendarEntrySubscription: Subscription;

  calendarRefreshSubscription: Subscription;
  multiCalendarChangedSubscription: Subscription;

  availServic = [];
  availServicSubscription: Subscription;
  availServiceEmpty = true;
  selectedService = null;

  service = new FormControl();
  eventType = new FormControl();
  servicePoint = new FormControl();
  patient = new FormControl();
  sortLogic = new FormControl();
  serviceSelect = new FormGroup({
    'service': this.service,
    'eventType': this.eventType,
    'servicePoint': this.servicePoint,
    'patient': this.patient,
    'sortLogic': this.sortLogic
  });

  currentTimeMarkerPosition = -1;
  currentScrollPosition = -100;

  sortOptions = [
    {
      sortName: 'Szolgáltatás, dátum',
      sortId: 1
    },
    {
      sortName: 'Munkahely, dátum',
      sortId: 2
    },
    {
      sortName: 'Dátum, szolgáltatás',
      sortId: 3
    },
    {
      sortName: 'Dátum, munkahely',
      sortId: 4
    }
  ];

  etList = [];
  spList = [];
  etListFiltered = [];
  spListFiltered = [];
  calendarListDataAll = [];
  calendarListDataRows = [];
  calendarListDataArray = [];
  calendarListDataRowItemCount = [];
  firstCalendarAppTimestamp: number;
  firstTimeStampSubscription: Subscription;
  dateSubscription: Subscription;
  lastCalendarAppTimeStamp: number;
  additionalSlots: number;
  firstDisplayTimeStamp = -1;

  calendarLoadSubscription: Subscription;

  autoRefreshCalendarData = null;

  calendarRefreshTimer = environment.calendarRefreshTimer.DEFAULT;

  calendarEntryDuration = 900000;

  layoutWidthSubscription: Subscription;
  layoutWidth = 1;
  calendarTimeAxis = [];
  calendarLoaded = false;
  columns = 0;
  col_size = 3;
  width_multiplier = 8.4;

  scrolled = false;
  currentScrollPos: ElementRef;

  parallelAppointments = 1;
  Brand = '';

  callNumber = '---';
  callPatientLoading = false;

  arrivePatientLoading = false;

  calendarMinHour = 24;
  calendarMaxHour = 0;
  calendarMinTime = 0;
  calendarMinTimeString = '';
  calendarDays = [];
  selectedPatient = null;

  bookedPatients = [];

  selectedCalendarEntries = [];
  showMultiBooking = false;
  showDetails = false;

  loadCalendarOther = new ReplaySubject<any>(1);

  days = [
    { shortName: 'V', name: 'Vasárnap' },
    { shortName: 'H', name: 'Hétfő' },
    { shortName: 'K', name: 'Kedd' },
    { shortName: 'Sz', name: 'Szerda' },
    { shortName: 'Cs', name: 'Csütörtök' },
    { shortName: 'P', name: 'Péntek' },
    { shortName: 'Szo', name: 'Szombat' }];

  patientName = new FormControl();
  patientAlias = new FormControl();
  comment = new FormControl();

  bookingDetails = new FormGroup({
    'patientName': this.patientName,
    'patientAlias': this.patientAlias,
    'comment': this.comment
  });

  bookingFormValid = false;
  saveBookingInProgress = false;
  patientListDataSubscription: Subscription;
  patientList = [];
  patientListLoading = true;
  collator = new Intl.Collator('hu', { numeric: true, sensitivity: 'base' });

  @ViewChild('currentScrollPos') set csp(currentScrollPos: ElementRef) {
    if (currentScrollPos) {
      this.currentScrollPos = currentScrollPos;
    }

    if (this.calendarID === '1') {
      if (this.currentScrollPos && this.scrolled === false) {  // Scroll window to current time
        // console.log('Scroll window to current time');
        // console.log('*****currentScrollPos:', this.currentScrollPos);
        // console.log('*****currentScrollPos TOP:', this.currentScrollPos.nativeElement.style.top);
        // console.log('*****currentScrollPosition:', this.currentScrollPosition);
        if (this.currentScrollPos.nativeElement.style.top !== '-100px') {
          this.scrolled = true;
          this.currentScrollPos.nativeElement.scrollIntoView();
        }
      }
    }
    // this.calculateCurrentTimeMarkerPosition('@ViewChild(currentScrollPos)');
  }


  SecsSinceMidnight(date) {
    let dateObj;
    let miliSeconds = null;
    if (date) {
      try {
        dateObj = new Date(this.sharedDataService.DateStringConvert(date));
      } catch (e) {
        //  console.log(e);
      }
      if (!dateObj) {
        try {
          dateObj = new Date(date);
        } catch (e) {
          // console.log(e);
        }
      }
    }
    if (dateObj) {
      miliSeconds = ((dateObj.getHours() * 60) + dateObj.getMinutes()) * 60000;
    }
    return miliSeconds;
  }

  getMinutes(date) {
    let dateObj;
    let minutes = null;
    if (date) {
      try {
        dateObj = new Date(this.sharedDataService.DateStringConvert(date));
      } catch (e) {
        //  console.log(e);
      }
      if (!dateObj) {
        try {
          dateObj = new Date(date);
        } catch (e) {
          // console.log(e);
        }
      }
    }
    if (dateObj) {
      minutes = (dateObj.getMinutes());
    }
    return minutes;
  }



  calculateStartTimeStamp(dateFrom, minTime) {
    const newDateFrom = dateFrom - this.SecsSinceMidnight(dateFrom) + minTime;
    return newDateFrom;
  }

  ngOnInit() {

    this.patientListDataSubscription = this.sharedDataService.patientListShared.subscribe((patientList) => {
      this.patientListLoading = false;
      this.patientList = patientList;
    });

    if (this.sharedDataService.getConfig('multiRangeCollumnHeader') == 0 || this.sharedDataService.getConfig('multiRangeCollumnHeader') == 1) {
      this.collumnHeader = this.sharedDataService.getConfig('multiRangeCollumnHeader');
    }

    // console.log('refreshTimer', (this.calendarRefreshTimer));

    if (localStorage.getItem('multiRangeSort') && localStorage.getItem('multiRangeSort') !== null) {
      this.sortLogic.setValue(JSON.parse(localStorage.getItem('multiRangeSort')));
    } else {
      this.sortLogic.setValue(this.sortOptions[0]);
    }

    this.sharedDataService.spListShared.subscribe(
      value => {
        value.sort((a, b) => { return this.collator.compare(a.spName, b.spName) });
        this.spList = value;
        this.spListFiltered = value;
      });

    this.sharedDataService.etListShared.subscribe(
      value => {
        value.sort((a, b) => { return this.collator.compare(a.etName, b.etName) });
        this.etList = value;
        this.etListFiltered = value;
      });

    // console.log('calendar init');
    this.updateCalendarEntrySubscription = this.sharedDataService.updateCalendarEntryShared.subscribe(data => {
      this.updateCalendarEntryStatus(data.calendarId, data.status);
    }
    );

    this.Brand = this.authService.getBrand();
    this.calendarRefreshTimer = environment.calendarRefreshTimer[this.Brand];
    this.selectedCsIdSubscription = this.sharedDataService.selectedCsIdShared.subscribe(data => {
      this.selectedCsId = data;
      // console.log ('calendar selected csid change:', data);
    });

    this.layoutWidthSubscription = this.sharedDataService.layoutWidthShared.subscribe(value => {
      // console.log ('calendar layout width change arrived');
      this.layoutWidth = value;
      this.calculateCurrentTimeMarkerPosition('layoutWidthSubscription');
    });



    this.firstTimeStampSubscription = this.sharedDataService.calendarFirstTimeStampUpdate.subscribe((timestampData: Object) => {   // deprecated in multiRange only applicable in two collumn mode
      // console.log('timestamp data arrived into calendar:' + this.calendarID);
      // console.log(timestampData);
      let otherCalendarFirstTimeStamp: number;
      if (this.calendarID === '1') {
        if (timestampData[2] > 0) {
          otherCalendarFirstTimeStamp = timestampData[2];
          // console.log('other calendar timestamp: ' + timestampData[2]);
        }
      } else if (this.calendarID === '2') {
        if (timestampData[1] > 0) {
          otherCalendarFirstTimeStamp = timestampData[1];
          // console.log('other calendar timestamp: ' + timestampData[1]);
        }
      }

      this.additionalSlots = ((this.firstCalendarAppTimestamp - this.firstDisplayTimeStamp) / this.calendarEntryDuration);
      // console.log('Slots needed :' + this.additionalSlots);
      if (this.additionalSlots > 0) {
        this.calculateCurrentTimeMarkerPosition('firstTimeStampSubscription');
      }

    });


    this.availServicSubscription = this.sharedDataService.availServicesShared.subscribe(data => {

      // console.log('availservice: ', data);
      if (data.length === 0) {
        this.availServiceEmpty = true;
        this.availServic = data;
        this.calendarListDataAll = [];
        this.selectedService = null;
        this.service.setValue(null);
      } else {

        this.availServiceEmpty = false;
        // console.log ('Role', this.sharedDataService.getSelectedRole());
        if (this.authService.getBrand() === 'UDMED' && (this.sharedDataService.getSelectedRole()['roleCode'] === 'AUTH$UDMED-VIRT_CCS' ||
          this.sharedDataService.getSelectedRole()['roleCode'] === 'AUTH$KENEZY_SCC')) {
          const serviceTemp = [];
          // console.log ('UDMED Service --');
          data.forEach(entry => {
            // console.log ('availService entry: ', entry);
            // console.log ('entry index: ', entry.etCode.indexOf('ELOJEGYZES'));
            if (entry.etCode.indexOf('ELOJEGYZES') > -1 || entry.etCode.indexOf('BETEGFELVETEL') > -1) {
              serviceTemp.push(entry);
            }
          });
          this.availServic = serviceTemp;

          this.calendarListDataAll = [];
          this.selectedService = null;
          this.service.setValue(null);
          // console.log ('availservice', this.availServic);

        } else {
          if (this.Brand === 'MEDCSEPP') {
            data.forEach(entry => {
              // console.log ('availService entry: ', entry);
              if (entry.etId !== 12720854 || entry.spName === 'Intézményi Diszpécser M') {
                this.availServic.push(entry);
              }
            });

            this.calendarListDataAll = [];
            this.selectedService = null;
            this.service.setValue(null);
          } else {
            this.availServic = data;
            this.calendarListDataAll = [];
            this.selectedService = null;
            this.service.setValue(null);
          }
        }
      }
    });

    this.eventType.setValue(JSON.parse(localStorage.getItem('et')));
    this.servicePoint.setValue(JSON.parse(localStorage.getItem('sp')));
    this.setServices();

    this.dateSubscription = this.sharedDataService.selectedDateChange.subscribe(
      value => {
        if (value) {
          this.dateFrom = value;
          this.eventType.setValue(JSON.parse(localStorage.getItem('et')));
          this.servicePoint.setValue(JSON.parse(localStorage.getItem('sp')));
          this.setServices();
        }
      });

    this.multiCalendarChangedSubscription = this.sharedDataService.mulriCalendarChanged.subscribe(
      value => {
        if (value) {
          // $('#idopontkezelo').modal('hide');
          this.eventType.setValue(JSON.parse(localStorage.getItem('et')));
          this.servicePoint.setValue(JSON.parse(localStorage.getItem('sp')));
          this.setServices();
        }
      });

  }

  ngOnDestroy() {
    //this.updateCalendarEntrySubscription.unsubscribe();
    this.dateSubscription.unsubscribe();
    this.patientListDataSubscription.unsubscribe();
    /* this.availServicSubscription.unsubscribe();
     this.calendarRefreshSubscription.unsubscribe();
     this.layoutWidthSubscription.unsubscribe();*/
    clearInterval(this.autoRefreshCalendarData);
  }

  emptyCalendarMessage = '';

  initCalendar(refresh, services) {
    this.emptyCalendarMessage = '';
    let ids = '';
    let firstItem = -1;

    if (services) {
      services.forEach(
        s => {
          ids += s.svId + ',';
        });
      ids = ids.slice(0, -1);
    }

    this.calendarLoadSubscription = this.getCalendarListDataMulti(ids, refresh).subscribe(
      (calendarData: Response) => {
        this.calendarListDataArray = [];
        let calendarDays = [];
        this.calendarLoaded = true;
        this.calendarMinTime = 0;
        this.calendarMinHour = 24;
        this.calendarMaxHour = 0;
        this.bookedPatients = [];

        this.calendarLoading = false;

        // console.log('!calendarData[data]: ', calendarData['data'].length === 0);

        // console.log('et: ', JSON.parse(localStorage.getItem('et')));
        // console.log('sp: ', JSON.parse(localStorage.getItem('sp')));

        if (calendarData['data'].length === 0) {
          if ((!JSON.parse(localStorage.getItem('et')) && !JSON.parse(localStorage.getItem('sp')))) {
            this.emptyCalendarMessage = 'Az adott időszakra nincsen megjeleníthető adat.';
            return;
          } else if (calendarData['data'].length === 0 && (JSON.parse(localStorage.getItem('et')) || JSON.parse(localStorage.getItem('sp')))) {
            this.emptyCalendarMessage = 'Az adott időszakra nincsen megjeleníthető adat a beállított szűrési feltételekkel.';
            return;
          }
        }

        if (this.autoRefreshCalendarData) {
          clearInterval(this.autoRefreshCalendarData);
        }

        // console.log ('calendarRefreshTimer', this.calendarRefreshTimer);
        if (this.calendarRefreshTimer >= 1000) {

          this.autoRefreshCalendarData = setInterval(() => {
            this.initCalendar(true, services);
          }, this.calendarRefreshTimer);
        }


        if (calendarData['code'] === 1) {

          let i = 0;

          calendarData['data'].forEach(calendarColumn => {
            let calendarMultiColumn = [];

            // console.log('calendarColumn', calendarColumn);
            calendarColumn.calendarData.forEach(item => {

              const dateFromObj = new Date(item['dateTo']);
              const dateString = dateFromObj.getFullYear() + '-' + (dateFromObj.getMonth() + 1) + '-' + dateFromObj.getDate();
              if (this.calendarDays.indexOf(dateString) === -1) {
                this.calendarDays.push(dateString)
              }
              if (!calendarMultiColumn[dateString]) { calendarMultiColumn[dateString] = [] };
              calendarMultiColumn[dateString].push(item);
            });

            calendarColumn.calendarData = calendarMultiColumn;

            calendarColumn.calendarData.forEach(day => {
              day.sort(this.sortCalendarItems);
              const dateFrom = this.sharedDataService.DateStringConvert(day[0].dateFrom);
              this.SecsSinceMidnight(dateFrom);
              if (this.calendarMinTime > this.SecsSinceMidnight(dateFrom) || this.calendarMinTime === 0) { this.calendarMinTime = this.SecsSinceMidnight(dateFrom); }
            })
          });

          // console.log ( calendarData['data']);

          calendarData['data'].forEach(calendarColumns => {
            // console.log ('calendarColumns', calendarColumns);
            // console.log ('calendarColumns serviceFk', calendarColumns.serviceFk);
            // console.log ('calendarColumns calendarData', calendarColumns.calendarData);
            // console.log ('calendarColumns calendarData length', calendarColumns.calendarData);


            Object.entries(calendarColumns.calendarData).forEach(([key, calendarColumn]) => {
              // console.log ('-- calendarColumn', calendarColumn);
              let calendarDataColumn = this.loadCalendarColumn(calendarColumn);
              this.calendarListDataArray[i] = {};
              this.calendarListDataArray[i].items = calendarDataColumn.items;
              this.calendarListDataArray[i].itemCount = calendarDataColumn.itemCount;
              if (calendarColumns['serviceFk'] && this.availServic.find(obj => obj.svId === calendarColumns['serviceFk'])) {
                this.calendarListDataArray[i].header = (this.availServic.find(obj => obj.svId === calendarColumns['serviceFk']));
              }
              // console.log ('0: ', calendarDataColumn.items[0][0]);
              const dayDate = new Date(calendarDataColumn.items[0][0].dateFrom);

              // console.log ('dow ', days[ dayDate.getDay()]);
              // console.log ('down ', dayDate.getDay());
              this.calendarListDataArray[i].date = (dayDate.getMonth() + 1) + '.' + (dayDate.getDate()) + '.';
              this.calendarListDataArray[i].dateFull = this.sharedDataService.dateToDateString(dayDate);
              this.calendarListDataArray[i].dayOfWeek = this.days[dayDate.getDay()];
              // if (i>0) {console.log ( this.calendarListDataArray[i-1].dayOfWeek);}
              // console.log ( this.calendarListDataArray[i].dayOfWeek);
              i++;

            });

            this.calendarListDataArray.forEach(column => {
              const dateFrom = column.items[0][0].dateFrom;
              // console.log ('df: ', dateFrom);
              // console.log ('item secsSinceMidnight:', this.SecsSinceMidnight(dateFrom));
              //  console.log ('(this.getMinutes(dateFrom)):', (this.getMinutes(dateFrom)));
              if (this.calendarMinTime < this.SecsSinceMidnight(dateFrom)) {
                // console.log ('pop');
                column.items.unshift([
                  {
                    dateFrom: this.calculateStartTimeStamp(column.items[0][0].dateFrom, this.calendarMinTime),
                    dateTo: column.items[0][0].dateFrom,
                    eventTypeFk: 0, // entry['eventTypeFk']
                    calendarStatus: '_',
                    slotLength: ((column.items[0][0].dateFrom - this.calculateStartTimeStamp(column.items[0][0].dateFrom, this.calendarMinTime)) / this.calendarEntryDuration) + ((this.getMinutes(this.calendarMinTime) * 60000) / this.calendarEntryDuration),
                    specialEntry: 'pop'
                  }]);
              }
              else if ((this.getMinutes(dateFrom)) > 0 && (!column.items[0][0].specialEntry && column.items[0][0].specialEntry !== 'pop')) {
                // console.log ('correction');
                column.items.unshift([
                  {
                    dateFrom: (dateFrom - (this.getMinutes(dateFrom) * 60000)),
                    dateTo: dateFrom,
                    eventTypeFk: 0, // entry['eventTypeFk']
                    calendarStatus: '_',
                    slotLength: (this.getMinutes(dateFrom) * 60000) / this.calendarEntryDuration,
                  }]);
              }

            });

            // this.calendarListDataArray.sort(this.sortCalCollumns);
            this.bookedPatients.sort((a, b) => { return this.collator.compare(a.participantName, b.participantName) });

            // console.log ('this.bookedPatients  ', this.bookedPatients);
            // console.log ('selectedPatient ', this.selectedPatient);
            if (!this.bookedPatients.find(e => e === this.selectedPatient)) {
              this.patient.reset();
              this.selectedPatient = null;
            }
          });

          this.reSortCalCollumns(this.sortLogic.value);
          //  console.log('this.calendarListDataArray', this.calendarListDataArray);



          this.columns = i;
          if (this.columns < 11 && this.columns > 2) {
            this.col_size = 2 + Math.floor(40 / this.columns);
            this.width_multiplier = 50;
          } else if (this.columns >= 11) {
            this.col_size = 3;
            this.width_multiplier = 8.4;
          } else if (this.columns <= 2) {
            this.col_size = 20;
            this.width_multiplier = 50;
          }
          // console.log('(columns * ((col_size*50)+3))', (this.columns * ((this.col_size * 50) + 3)));
          // console.log(calendarData['data']);
          this.calendarTimeAxis = [];
          for (let o = this.calendarMinHour; o <= this.calendarMaxHour; o++) {
            this.calendarTimeAxis.push(o);
          }
        }
      });
  }

  loadCalendarColumn(calendarInput) {

    let previousAppointmentDateFrom = 0;
    let previousAppointmentDateTo = 0;
    let tempParallelAppointments = 1;
    let appointmentRow = 0;

    this.calendarListDataRows = [];
    this.calendarListDataRowItemCount = [];

    if (calendarInput.length > 0) {

      calendarInput.sort(this.sortCalendarItems);

      // console.log ('firstItem: ', this.SecsSinceMidnight(calendarInput[0].dateFrom));
      if (this.calendarMinTime > this.SecsSinceMidnight(calendarInput[0].dateFrom) || this.calendarMinTime === 0) {
        this.calendarMinTime = this.SecsSinceMidnight(calendarInput[0].dateFrom);
      }

      this.calendarEntryDuration = 300000;

      // this.calendarEntryDuration = this.sharedDataService.DateStringConvert(this.calendarListDataAll[0]['dateTo']) -
      //   this.sharedDataService.DateStringConvert(this.calendarListDataAll[0]['dateFrom']);

      // console.log(this.calendarEntryDuration);

      calendarInput.forEach(entry => {

        entry['dateTo'] = this.sharedDataService.DateStringConvert(entry['dateTo']);
        entry['dateFrom'] = this.sharedDataService.DateStringConvert(entry['dateFrom']);

        if (entry && entry['dateFrom'] && new Date(entry['dateFrom']).getHours() < this.calendarMinHour) {
          // console.log ('calendarMinHour', new Date(entry['dateFrom']).getHours());
          this.calendarMinHour = new Date(entry['dateFrom']).getHours();
        }

        let dateTo = new Date(entry['dateTo']);
        if (entry && entry['dateTo'] && dateTo.getHours() > this.calendarMaxHour) {
          if (dateTo.getMinutes() > 0) {
            this.calendarMaxHour = dateTo.getHours();
          } else {
            this.calendarMaxHour = dateTo.getHours() - 1;
          }
          // console.log ('this.calendarMaxHour', this.calendarMaxHour);
        }



        if (previousAppointmentDateTo !== entry['dateFrom'] && previousAppointmentDateTo > 0) {
          appointmentRow++;
          this.calendarListDataRows[appointmentRow] = [];
          // console.log('add Empty Space');
          // add empty space
          // console.log('previousAppointmentDateTo', previousAppointment2DateTo);
          // console.log('entry[DateFrom]', entry['dateFrom']);
          this.calendarListDataRows[appointmentRow][tempParallelAppointments - 1] = {
            dateFrom: previousAppointmentDateTo,
            dateTo: entry['dateFrom'],
            eventTypeFk: 0, // entry['eventTypeFk']
            calendarStatus: '_'

          }

          this.calendarListDataRows[appointmentRow][tempParallelAppointments - 1]['slotLength'] = ((this.calendarListDataRows[appointmentRow][tempParallelAppointments - 1]['dateTo'] - this.calendarListDataRows[appointmentRow][tempParallelAppointments - 1]['dateFrom']) / this.calendarEntryDuration);

          this.parallelAppointments = tempParallelAppointments;
          tempParallelAppointments = 1;

          this.calendarListDataRowItemCount[appointmentRow] = 12 / this.calendarListDataRows[appointmentRow].length;

        }



        if (previousAppointmentDateFrom === entry['dateFrom']) {
          tempParallelAppointments++;
        } else {
          this.parallelAppointments = tempParallelAppointments;
          tempParallelAppointments = 1;
          if (previousAppointmentDateFrom !== 0) {
            this.calendarListDataRowItemCount[appointmentRow] = 12 / this.calendarListDataRows[appointmentRow].length;
            appointmentRow++;
          }
        }

        if (!this.calendarListDataRows[appointmentRow]) {
          this.calendarListDataRows[appointmentRow] = [];
        }

        previousAppointmentDateFrom = entry['dateFrom'];
        previousAppointmentDateTo = entry['dateTo'];

        entry['slotLength'] = ((entry['dateTo'] - entry['dateFrom']) / this.calendarEntryDuration);

        if (entry['participantName']) {
          if (entry['participantUserID']) {
            const patient = {
              participantUserID: entry['participantUserID'],
              participantName: entry['participantName']
            }

            if (this.bookedPatients.findIndex(e => { return e.participantUserID == patient.participantUserID; }) === -1) {
              this.bookedPatients.push(patient);
            }
          }

          entry['displayData'] = (entry['participantName']).trim();
          if (entry['calendarComment'] && entry['calendarComment'] !== '') {
            if (entry['displayData'] !== '') {
              entry['displayData'] += ', ' + entry['calendarComment'];
            } else {
              entry['displayData'] = entry['calendarComment'];
            }
          }

        }

        this.calendarListDataRows[appointmentRow][tempParallelAppointments - 1] = entry;
      });
      this.calendarListDataRowItemCount[appointmentRow] = 12 / this.calendarListDataRows[appointmentRow].length;

      return { 'header': { 'name': 'oszlop' }, items: this.calendarListDataRows, itemCount: this.calendarListDataRowItemCount };


    }
  }

  onLogout() {
    this.authService.doLogout();
    this.router.navigate(['/login']);
  }

  sortCalendarItems(a, b) {
    if (a['dateFrom'] > b['dateFrom']) {
      return 1;
    } else if (a['dateFrom'] < b['dateFrom']) {
      return -1;
    }
    return 0;
  }


  logEntry(entry) {
    console.log(entry);
  }


  // sortCalCollumns(a, b) {
  //   if (a['header']['etId'] > b['header']['etId']) {
  //     return 1;
  //   } else if (a['header']['etId'] < b['header']['etId']) {
  //     return -1;
  //   }
  //   return 0;
  // }


  // sortCalCollumns(a, b) {
  //   if (a['header']['etId'] === b['header']['etId']){
  //     return a['dateFull'] < b['dateFull'] ? -1 : 1
  //   } else {
  //     return a['header']['etId'] < b['header']['etId'] ? -1 : 1
  //   }
  // }

  sortCalCollumns(a, b, method) {
    if (method === 1) {
      if (a.header?.etName?.localeCompare(b.header?.etName, 'hu') === 0) {
        return a['dateFull'] < b['dateFull'] ? -1 : 1;
      } else {
        return a.header?.etName?.localeCompare(b.header?.etName, 'hu');
      }
    }
    if (method === 2) {
      if (a.header?.spName?.localeCompare(b.header?.spName, 'hu') === 0) {
        return a['dateFull'] < b['dateFull'] ? -1 : 1;
      } else {
        return a.header?.spName?.localeCompare(b.header?.spName, 'hu');
      }
    }
    if (method === 3) {
      if (a['dateFull'] === b['dateFull']) {
        return a.header?.etName?.localeCompare(b.header?.etName, 'hu');
      } else {
        return a['dateFull'] < b['dateFull'] ? -1 : 1;
      }
    }
    if (method === 4) {
      if (a['dateFull'] === b['dateFull']) {
        return a.header?.spName?.localeCompare(b.header?.spName, 'hu');
      } else {
        return a['dateFull'] < b['dateFull'] ? -1 : 1;
      }
    }
  }


  // sortName: 'Szolgáltatás, dátum', sortId: 1
  // sortName: 'Munkahely, dátum', sortId: 2
  // sortName: 'Dátum, szolgáltatás', sortId: 3
  // sortName: 'Dátum, munkahely', sortId: 4

  reSortCalCollumns($event) {
    localStorage.setItem('multiRangeSort', JSON.stringify($event))
    // console.log ('event', $event);
    this.calendarListDataArray.sort((a, b) => this.sortCalCollumns(a, b, $event.sortId));
  }

  calendarEntryDetails(calendarListDataColumn, calendarEntry, mode) {
    // console.log(calendarListDataColumn);
    let patient = '';
    let detailsString = '';
    // console.log ('calendarListDataColumn', calendarListDataColumn);
    // console.log ('calendarEntry', calendarEntry);
    if (calendarEntry?.displayData) {
      patient = calendarEntry.displayData;
    }
    const df = new Date(calendarEntry?.dateFrom);
    const dt = new Date(calendarEntry?.dateTo);
    if (mode == 1) {
      detailsString = 'Időpont: ' + calendarListDataColumn.dateFull + ' (' + this.days[df.getDay()]?.name + ') ' + df.getHours() + ':' +
        (('0' + df.getMinutes()).slice(-2)) + ' - ' + dt.getHours() + ':' + (('0' + dt.getMinutes()).slice(-2));
      if (patient !== '') { detailsString = detailsString + '\nPáciens: ' + patient + ' '; }
      detailsString = detailsString + '\nSzolgáltatás: ' + calendarListDataColumn.header?.etName + '\nMunkahely: ' + calendarListDataColumn.header?.spName;
      // detailsString = detailsString + '\nSlotLength: ' + calendarEntry.slotLength;
      return (detailsString);
    }
    if (mode == 2 && patient !== '') {
      let detailsArray = [];
      if (patient !== '') { detailsArray.push(`Páciens: ` + patient + ' '); }
      detailsArray.push('Időpont: ' + df.getHours() + ':' +
        (('0' + df.getMinutes()).slice(-2)) + ' - ' + dt.getHours() + ':' + (('0' + dt.getMinutes()).slice(-2)));
      detailsArray.push(`Szolgáltatás: ` + calendarListDataColumn.header?.etName);
      detailsArray.push(`Munkahely: ` + calendarListDataColumn.header?.spName);
      return (detailsArray);
    }
    return;
  }

  calculateCurrentTimeMarkerPosition(callerID) {
    // console.log ('--------------------------------------------');
    // console.log ('callerID: ', callerID);
    // console.log ((new Date).toISOString() + ' calculateCurrentTimeMarkerPosition');
    const firstItem = new Date(this.firstDisplayTimeStamp);
    const minutes = (new Date).getMinutes();
    const seconds = (new Date).getSeconds();
    // const now = new Date('2019-11-11 14:' + minutes + ':' + seconds);
    const now = new Date();

    const firstItemDate = firstItem.getFullYear() + '-' + firstItem.getMonth() + '-' +
      firstItem.getDate();

    const nowDate = now.getFullYear() + '-' + now.getMonth() + '-' +
      now.getDate();

    let position = -1;
    let scrollPosition = -100;
    // console.log('firstItemDate', firstItemDate);
    if (firstItemDate === nowDate) {  // Only calculate marker and scroll if the displayed day is today
      // console.log('today');
      // console.log('now.getTime()', now.getTime());
      // console.log('this.lastCalendarAppTimeStamp', this.lastCalendarAppTimeStamp);
      const deltaTime = (now.getTime() - this.firstDisplayTimeStamp) / 1000;

      if (deltaTime >= 0 && now.getTime() <= this.lastCalendarAppTimeStamp) { // Only calculate if it's now is between start and end

        // console.log ('this.calendarEntryDuration', this.calendarEntryDuration / 900000);

        if (this.layoutWidth === 2) {
          position = (deltaTime / 60) * 3 / (this.calendarEntryDuration / 300000);
          scrollPosition = position - 250;
        } else {
          position = (deltaTime / 60) * 5.06666666666667 / (this.calendarEntryDuration / 900000);
          scrollPosition = position - 250;
        }

        this.currentScrollPosition = Math.round(scrollPosition);
        this.currentTimeMarkerPosition = Math.round(position);

        if (this.calendarID === '1') {
          if (this.currentScrollPos && this.scrolled === false) {  // Scroll window to current time
            // console.log('Scroll window to current time');
            // console.log('*****currentScrollPos:', this.currentScrollPos);
            // console.log('*****currentScrollPos TOP:', this.currentScrollPos.nativeElement.style.top);
            // console.log('*****currentScrollPosition:', this.currentScrollPosition);
            if (this.currentScrollPos.nativeElement.style.top !== '-100px') {
              this.scrolled = true;
              this.currentScrollPos.nativeElement.scrollIntoView();
            }
          } else {
            // console.log('NO Scroll window to current time');
            if (this.currentScrollPos) {
              // console.log('this.currentScrollPos', this.currentScrollPos.nativeElement.style.top);
            }
            // console.log('this.scrolled', this.scrolled);
          }
        }
      }
    }
    this.currentScrollPosition = Math.round(scrollPosition);
    this.currentTimeMarkerPosition = Math.round(position);
  }

  getCalendarListDataMulti(serviceId: string, updateOnly: boolean) {

    if (updateOnly !== true) {
      this.calendarLoading = true;
    }

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.getSelectedRole()['roleCode'],
        currentServicePointId: this.sharedDataService.getSelectedRole()['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      dateFrom: this.dateFrom,
      days: 7,
      serviceId: serviceId
    };

    return this.http.post(this.apiUrl + '/rest/neu/calendarlist/getcalendarlistdatamultirange', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }

  logDetails(calendarEntry) {
    console.log('calendarEntry', calendarEntry);
  }

  calendarItemStatus(entry: any) {

    if (entry['calendarStatus'] === 'S') {
      return '';
    } else if (entry['calendarStatus'] === 'F') {
      return 'appointment_free';
    } else {
      return '';
    }

  }

  setPatientData(data: any, type: string) {
    // console.log('---- calendar setpatientdata');
    // console.log('type', type);
    // console.log('data', data);
    data['NGdestinationService'] = type;
    this.sharedDataService.setPatientData(data);
  }

  setAppointmentData(data: any, header: any, type: string) {

    if (this.selectedCalendarEntries.length > 0) {
      // console.log ('multi booking');
    } else {
      if (data?.calendarStatus == 'F' || data?.calendarStatus == 'S') {
        // console.log('data', data);
        // console.log('header', header);
        // console.log('type', type);
        data['NGdestinationService'] = type;
        data['servicePointFk'] = header.spId;
        data['serviceFk'] = header.svId;
        data['eventTypeFk'] = header.etId;
        data['multi'] = true;
        this.sharedDataService.setAppointmentData(data);
        this.showBookingModal.nativeElement.click();
      }
    }
  }


  onGetCalendarDetails(calendarFk) {
    this.sharedApiService.getCalendarDetail(calendarFk).subscribe(data => {
      // console.log('Calendar Detail');
      // console.log(data);
    });
  }

  refreshCalendar() {
    // this.initialize(false);
  }

  onCallPatient(calendarId, calendarStatus) {
    // console.log ('callPatient clicked');
    if (this.callPatientLoading !== true && this.arrivePatientLoading !== true) {
      this.callPatientLoading = true;
      this.callNumber = '';
      // console.log ('callPatient calendarId: ' + calendarId + ', csId: ' + this.selectedCsId);
      this.callPatient(calendarId, this.selectedCsId).subscribe(response => {
        this.callPatientLoading = false;
        if (response['code'] === 200) {
          // console.log('callPatient:', response);
          this.callNumber = response['callNumber'];
          if (calendarStatus !== 'DD') {
            this.updateCalendarEntryStatus(response['calendarId'], 'DN');
          }
        } else {
          this.callNumber = '---';
          console.error('callPatient ERROR', response);
        }
      });
    } else {
      // console.log('Művelet folyamatban...');
    }
  }

  callPatient(calendarId, csId) {
    const params = {
      // messageHeader: {
      //   currentTapass: this.authService.getUserData()['ekCardNo'],
      //   currentRole: this.sharedDataService.getSelectedRole()['roleCode'],
      //   currentServicePointId: this.sharedDataService.getSelectedRole()['spId'],
      //   locale: 'hu',
      //   deviceId: this.authService.getUUID()
      // },
      csId: csId,
      calendarId: calendarId
    };

    return this.http.post(this.sharedDataService.callerSystemAPIURL + 'callpatient', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  onArrivePatient(ssn, calendarId) {
    if (this.callPatientLoading !== true && this.arrivePatientLoading !== true) {

      this.arrivePatientLoading = true;
      this.arrivePatient(ssn, calendarId, this.selectedCsId).subscribe(response => {
        this.arrivePatientLoading = false;
        if (response['code'] === 200) {
          this.updateCalendarEntryStatus(calendarId, 'DH');
          // console.log('arrivePatient:', response);

        } else {
          this.callNumber = '---';
          console.error('arrivePatient ERROR', response);
        }
      });
    } else {
      // console.log('Művelet folyamatban...');
    }
  }

  arrivePatient(ssn, calendarId, csId) {
    const params = {
      // messageHeader: {
      //   currentTapass: this.authService.getUserData()['ekCardNo'],
      //   currentRole: this.sharedDataService.getSelectedRole()['roleCode'],
      //   currentServicePointId: this.sharedDataService.getSelectedRole()['spId'],
      //   locale: 'hu',
      //   deviceId: this.authService.getUUID()
      // },
      calendarId: calendarId,
      csId: csId,
      ssn: ssn
    };

    return this.http.post(this.sharedDataService.callerSystemAPIURL + 'arrivepatient', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  updateCalendarEntryStatus(calendarId, status) {
    // console.log('updateCalendarEntryStatus ID:', calendarId);
    // console.log('updateCalendarEntryStatus status:', status);
    // console.log ('this.calendarListDataRows:', this.calendarListDataRows);
    // console.log('calendar', this.calendarListDataRows);
    // obj => obj.grpCode === item['grpCode'])
    const calendarListDataRowsTemp = this.calendarListDataRows;
    calendarListDataRowsTemp.forEach(row => {
      // console.log ('checking row:', row);
      row.forEach(item => {
        // console.log ('checking calendarFk:', item.calendarFk);
        if (item.calendarFk == calendarId) {
          item.calendarStatus = status;
          // console.log('item found', item);
        }
      });
    });
    this.calendarListDataRows = calendarListDataRowsTemp;
    // console.log('calendar', this.calendarListDataRows);

  }


  getPrescriptionInitData(calId) {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.getSelectedRole()['roleCode'],
        currentServicePointId: this.sharedDataService.getSelectedRole()['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calId: calId
    };

    return this.http.post(this.apiUrl + '/rest/neu/pres/getprescriptioninitdata', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  initVenyiro(calId) {
    this.getPrescriptionInitData(calId).subscribe(response => {
      if (response['code'] === 1) {
        const params = {};
        Object.keys(response['data']).forEach(key => {
          // console.log('key: ' + key + ' - ' + response['data'][key]);
          params[this.ucFirst(key)] = response['data'][key];
        });
        this.openTab.postHtml(params, 'http://localhost:8888/$');
      } else {
        this.error = true;
        this.errorDescription = 'Vényíró funkció: ' + response['message'];
        console.error('getPrescriptionInitData ERROR', response);
      }
    });


  }

  ucFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  calStatusUpdate(calendarId) {
    this.calStatusSave(calendarId, 'DD').subscribe(response => {
      if (response['code'] === 200) {
        this.updateCalendarEntryStatus(calendarId, 'DD');
        // console.log('calStatusSave:', response);

      } else {
        this.callNumber = '---';
        console.error('calStatusSave ERROR', response);
      }
    });
  }


  calStatusSave(calendarId, status) {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.getSelectedRole()['roleCode'],
        currentServicePointId: this.sharedDataService.getSelectedRole()['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calendarId: calendarId,
      status: status
    };

    return this.http.post(this.sharedDataService.callerSystemAPIURL + 'updatecalendar', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  closeError() {
    this.error = false;
    this.errorDescription = '';
  }

  setServices() {
    // console.log ('setServices');
    let filteredService = [];

    localStorage.setItem('et', null);
    localStorage.setItem('sp', null);

    if (this.servicePoint.value) {
      filteredService = this.availServic.filter(avail => avail.spId === this.servicePoint.value.spId);
      localStorage.setItem('sp', JSON.stringify(this.servicePoint.value));

    }

    if (this.eventType.value) {
      filteredService = this.availServic.filter(avail => avail.etId === this.eventType.value.etId);
      localStorage.setItem('et', JSON.stringify(this.eventType.value));
    }

    if (this.eventType.value && this.servicePoint.value) {
      filteredService = this.availServic.filter(avail => avail.etId === this.eventType.value.etId
        && avail.spId === this.servicePoint.value.spId);
    }

    this.filterEt(this.servicePoint.value);
    this.filterSp(this.eventType.value);
    this.initCalendar(false, filteredService);
    this.showSettings = false;
  }

  filterPatients(event) {
    // console.log ('filterPatient: ', event);
    if (event) {
      this.selectedPatient = event;
    } else {
      this.selectedPatient = null;
    }
  }


  filterEt(event) {
    if (event && event.spId) {
      const etList = [];
      this.availServic.forEach(
        serv => {
          if (serv.spId === event.spId && !etList.find(e => e.etId === serv.etId)) {
            etList.push({ etId: serv.etId, etName: serv.etName });
          }
        });
      this.etListFiltered = etList;

    } else {
      this.etListFiltered = this.etList;
    }
  }

  filterSp(event) {

    if (event && event.etId) {
      const spList = [];
      this.availServic.forEach(
        serv => {
          if (serv.etId === event.etId && !spList.find(s => s.spId === serv.spId)) {
            spList.push({ spId: serv.spId, spName: serv.spName });
          }
        });
      spList.sort((a, b) => { return this.collator.compare(a.spName, b.spName) });
      this.spListFiltered = spList;

    } else {
      this.spList.sort((a, b) => { return this.collator.compare(a.spName, b.spName) });
      this.spListFiltered = this.spList;
    }
  }

  setEquipments(equiments: string[]) {
    const equipmentList = [];
    equiments.forEach(
      eq => {
        const splittedEq = eq.split('=');
        equipmentList.push({ name: splittedEq[1], type: splittedEq[0] });
      }
    );
    return equipmentList;
  }

  selectCalendarEntry(event: MouseEvent, calendarEntry, header) {
    event.preventDefault();
    if (header && header !== null && header.etName) {
      calendarEntry.etName = header.etName;
    }

    // console.log (calendarEntry);
    if (calendarEntry.calendarStatus === 'F') {
      if (this.selectedCalendarEntries.indexOf(calendarEntry) === -1) {
        // console.log ('this.compareEntryStartEnd(calendarEntry)', this.compareEntryStartEnd(calendarEntry));
        if (this.compareEntryStartEnd(calendarEntry) === -1) {
          // console.log ('push');
          const sv = this.availServic.find(serv => serv.etId === calendarEntry.eventTypeFk && serv.spId === calendarEntry.servicePointFk);
          if (sv.svDetail.svDetail.rules && sv.svDetail.svDetail.rules.subTypes) {
            const request: CheckEquiomentIsFreeReq = {
              etCode: sv.etCode,
              types: sv.svDetail.svDetail.rules.subTypes.replace(/\;/g, ','),
              dateFrom: this.sharedDataService.dateToDateTimeString(new Date(calendarEntry.dateFrom)),
              dateTo: this.sharedDataService.dateToDateTimeString(new Date(calendarEntry.dateTo))
            };

            this.apiService.checkEquipmentsIsFree(request).subscribe(
              response => {
                calendarEntry['equipments'] = this.setEquipments(response.equipments);
                calendarEntry['equipmentsControl'] = new FormControl(null, Validators.required);
              }
            );
          }
          this.selectedCalendarEntries.push(calendarEntry);
          this.selectedCalendarEntries.sort(this.sortCalendarItems);
        }
      } else {
        // console.log ('remove');
        this.selectedCalendarEntries.splice(this.selectedCalendarEntries.indexOf(calendarEntry), 1);
      }
    }
  }

  selectedCalendarEntry(calendarEntry) {
    if (this.selectedCalendarEntries.find(a => { return a.scheduleFk == calendarEntry.scheduleFk })) {
      // console.log ('filter found:', calendarEntry);
      return true;
    }
    return false;
  }
  compareEntryStartEnd(newEntry) {
    let i = -1;
    this.selectedCalendarEntries.forEach(entry => {
      // console.log ('------');
      // console.log (entry.dateFrom + ' - ' + newEntry.dateFrom);
      // console.log (entry.dateTo + ' - ' + newEntry.dateTo);7
      if ((new Date(entry.dateFrom) <= new Date(newEntry.dateFrom) && new Date(entry.dateTo) >= new Date(newEntry.dateTo))
        || (new Date(entry.dateFrom) >= new Date(newEntry.dateFrom) && new Date(entry.dateTo) <= new Date(newEntry.dateTo))
         || new Date(newEntry.dateTo) > new Date(entry.dateFrom) && new Date(newEntry.dateTo) < new Date(entry.dateTo)
         || new Date(newEntry.dateFrom) > new Date(entry.dateFrom) && new Date(newEntry.dateFrom) < new Date(entry.dateTo)
        ) {
        i++;
      }
    });
    return i;
  }

  toggleBookingList() {
    this.showMultiBooking = !this.showMultiBooking;
  }

  timestampGetDayOfWeek(timestamp) {
    const date = new Date(timestamp);
    return (this.days[date.getDay()].shortName);
  }


  bookingPatientDropdownChanged(event) {
    if (event) {
      this.patientAlias.reset();
    }
    this.validateBookingForm();
  }

  bookingFormChanged(event) {
    if (this.bookingDetails.value.patientAlias !== '') {
      this.patientName.reset();
    }
    this.validateBookingForm();
  }

  validateBookingForm() {
    if (this.bookingDetails.value.patientName !== null || (this.bookingDetails.value.patientAlias && this.bookingDetails.value.patientAlias.trim() !== '')) {
      this.bookingFormValid = true;
    } else {
      this.bookingFormValid = false;
    }
  }

  saveMultiBooking() {
    let ids = '';
    let equipments = '';
    let equipmentIsNotChoosed = false;
    this.selectedCalendarEntries.forEach((entry, index) => {
      ids += entry.scheduleFk + ',';
      if (entry?.equipmentsControl && entry?.equipmentsControl.invalid) {
        equipmentIsNotChoosed = true;
        return;
      }
      if (entry?.equipmentsControl?.value) {
        equipments += entry.equipmentsControl.value.type;
        if (index !== this.selectedCalendarEntries.length - 1) {
          equipments += ',';
        }
      } else {
        equipments += ',';
      }
    });

    if (equipmentIsNotChoosed) {
      this.toastr.error('kérem válasszon eszközt a szolgáltatáshoz!');
      return;
    }
    ids = ids.slice(0, -1);
    if (ids !== '') {
      this.callSaveSchedule(ids, equipments);
    }
  }


  callSaveSchedule(ids, equipments) {
    let tapass = '';
    let ssnType = '';
    let ssnNumber = '';
    this.saveBookingInProgress = true;
    if (this.patientName.value) {
      if (this.patientName.value.tapass) {
        tapass = this.patientName.value.tapass;
        ssnType = this.patientName.value.medicalType;
        ssnNumber = this.patientName.value.medicalId;
      }
    }

    let params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      doctorId: '',
      patientTapass: tapass,
      providerUserId: '',
      ssnType: ssnType,
      ssnNumber: ssnNumber,
      patientAlias: this.patientAlias.value,
      scheduleFkList: ids,
      diagnosisComment: this.comment.value,
      equipment: equipments
    };


    this.apiService.saveScheduleDay(params).subscribe(res => {
      if (res.code > -1) {
        this.bookingFormValid = false;
        // this.sharedDataService.mulriCalendarChanged.next(true);
        this.saveBookingInProgress = false;
        this.toastr.success('Sikeres mentés!');
        this.patientName.setValue(null);
        this.patientAlias.setValue('');
        this.comment.setValue('');
        this.selectedCalendarEntries = [];
        this.setServices();
      } else {
        this.saveBookingInProgress = false;
        this.toastr.error(res.message);
      }
    }, err => {
      this.toastr.error(err);
      this.saveBookingInProgress = false;
    });
  }

  toggleDetails() {
    this.showDetails = !this.showDetails;
  }

  toggleCollumnHeader(value) {
    this.collumnHeader = value;
    this.sharedDataService.setConfig('multiRangeCollumnHeader', value);
  }
}
