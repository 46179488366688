import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'eknumber'
})
export class EknumberPipe implements PipeTransform {

  transform(value: string): string {
    // 2940 7172 9889
    let result = value;
    if (value && value.length > 11) {

      result = value.substring(0, 4) + ' ' + value.substring(4, 8) + ' ' + value.substring(8, 12);
    }
    return result;
  }
}
