import { catchError, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/auth.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-check-ausz',
  templateUrl: './check-ausz.component.html',
  styleUrls: ['./check-ausz.component.css']
})
export class CheckAuszComponent implements OnInit {

  auszControl = new FormControl('', Validators.required);

  apiUrl = environment.apiUrl;

  errorMessage: string;

  isAuszCodeValidEmitter = new EventEmitter<boolean>();

  constructor(
    private authService: AuthService,
    private sharedDataService: SharedDataService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
  }

  checkUserAusz() {
    if (!this.auszControl.valid) {
      this.auszControl.markAsTouched();
      return;
    }

    this.errorMessage = null;
    this.isCurrentUserAusz().pipe(
      switchMap(isCurrentUserAuszResponse => {

        if (isCurrentUserAuszResponse['code'] < 0) {
          this.errorMessage = isCurrentUserAuszResponse['message'];
          return of(null);
        }
        return this.isAuszCodeValid(this.auszControl.value).pipe(
          tap(isAuszCodeValidResponse => {
            if (isAuszCodeValidResponse['code'] < 0) {
              this.errorMessage = isAuszCodeValidResponse['message'];
            } else {
              this.isAuszCodeValidEmitter.emit(true);
            }
          }),
          catchError((error: HttpErrorResponse) => {
            this.errorMessage = error.error.message;
            return of(null);
          })
        );
      }),
      catchError((error: HttpErrorResponse) => {
        this.errorMessage = error.error.message;
        return of(null);
      })
    ).subscribe();
  }

  isCurrentUserAusz() {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      }
    };

    return this.http.post(this.apiUrl + '/rest/neu/authentication/iscurrentuserausz', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  isAuszCodeValid(auszCode: string) {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      auszCode
    };

    return this.http.post(this.apiUrl + '/rest/neu/authentication/isauszcodevalid', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

}
