import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  constructor() { }

  post(obj, url) {
    var tempForm = document.createElement('form');
    tempForm.target = '_blank';
    tempForm.method = 'POST'; // or "post" if appropriate
    tempForm.enctype = 'text/plain';
    tempForm.action = url;
    let data = JSON.stringify(obj) ;
    data = data.slice (0, -1);
    data = data + ', "placeHolder" : "';
    var tempInput = document.createElement('input');
    tempInput.type = 'hidden';
    tempInput.name = data;
    tempInput.value = '"}';
    tempForm.appendChild(tempInput);
    document.body.appendChild(tempForm);
    tempForm.submit();
  }

  postHtml(obj, url) {
    var tempForm = document.createElement('form');
    tempForm.target = '_blank';
    tempForm.method = 'POST'; // or "post" if appropriate
    tempForm.enctype = 'text/html';
    tempForm.action = url;
    let data = JSON.stringify(obj) ;
    // data = data.slice (0, -1);
    // data = data + ', "placeHolder" : "';
    var tempInput = document.createElement('input');
    tempInput.type = 'hidden';
    tempInput.name = 'data';
    tempInput.value = data;
    tempForm.appendChild(tempInput);
    document.body.appendChild(tempForm);
    tempForm.submit();
  }
}
