import { ServicePoint } from './../shared/models/servicePoint';
import { ScheduleDialogComponent } from './schedule-dialog/schedule-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SaveAppointmentWithRuleRequest, SimpleSchedule } from './../shared/models/request/saveAppointmentRequest';
import { OnInit, OnDestroy, Component, Input } from '@angular/core';
import { SharedDataService } from '../services/shared-data.service';
import { AuthService } from '../auth/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { SharedApiService } from '../services/shared-api.service';
import { environment } from '../../environments/environment';
import { WeekScheduleDay } from '../shared/models/weekschedule';
import { DateRange } from '../shared/models/dateRange';
import { ApiService } from '../core/http/api.service';
import { SaveAppointmentWeeklyRequest } from '../shared/models/request/saveAppointmentRequest';
import { ToastrService } from 'ngx-toastr';

export interface BookedCells {
  dateFrom: number,
  dateTo: number,
  name: string,
  status: string,
  displayData: string
}

export interface FreeCell {
  dateFrom: number,
  dateTo: number,
  name: string
}

export interface SaveScheduleList {
  dateFrom: Date;
  dateTo: Date;
  isEmergency: boolean;
}

export interface Equioment {
  name: string;
  type: string;
}

export interface CheckEquiomentIsFreeReq {
  etCode: string;
  types: string;
  dateFrom: string;
  dateTo: string;
}

@Component({
  selector: 'app-book-appointment',
  templateUrl: './book-appointment.html',
  styleUrls: ['./book-appointment.css']
})
export class BookAppointmentComponent implements OnInit, OnDestroy {

  apiUrl = environment.apiUrl;

  constructor(
    public authService: AuthService,
    public sharedDataService: SharedDataService,
    private http: HttpClient,
    // private datePicker: NgbdDatepickerMultiple,
    private sharedApiService: SharedApiService,
    private apiService: ApiService,
    private toastr: ToastrService,
    private dialog: MatDialog
  ) { }

  // @ViewChild('saveResultModal') saveResultModal: ElementRef;

  weekSchedule: Array<WeekScheduleDay> = [];

  start: Date;
  end: Date;

  dayLenghtInHours = {
    hourFrom: 8,
    hourTo: 16
  }

  scheduleToDeleteCalendarFk: number;
  bookedCells = [];
  freeCells = [];
  tempCells = [];
  coCells = [];
  bookingDateFrom: Date;
  bookingDateTo: Date;
  Brand = '';

  selectedRole = null;
  availServic = [];
  availServicSubscription: Subscription;
  availServiceLoading = true;

  patientListDataSubscription: Subscription;
  patientList = [];
  patientListLoading = true;
  isEmergency = false;
  szentVincent = false;

  calendarModReasonsSubscription: Subscription;
  calendarModReasons = null;
  calendarModReasonsLoading = true;

  deleteAppointmentModal = false;
  bookingDataLoading = false;
  timeTypes = ['NAPONTA', 'HETENTE', 'HAVONTA'];

  roleSelect: FormGroup;
  service = new FormControl();
  eventType = new FormControl();
  servicePoint = new FormControl();
  equipment = new FormControl();
  patientAlias = new FormControl('', [Validators.required, Validators.minLength(1)]);
  comment = new FormControl();
  time = new FormControl(null, [Validators.min(1), Validators.pattern('^[0-9]*$')]);
  timeType = new FormControl();
  quantity = new FormControl(null, [Validators.min(1), Validators.pattern('^[0-9]*$')]);


  schedules = new Map<SimpleSchedule, SimpleSchedule[]>();

  multiCal = false;
  multiCalendar: Subscription;

  etList = [];
  etListFiltered = [];
  spList = [];
  spListFiltered = [];
  equipmentList = [];
  equipmentListFiltered = [];

  calendarListDataSubscription: Subscription;

  serviceSelect = new FormGroup({
    'service': this.service,
    'eventType': this.eventType,
    'servicePoint': this.servicePoint,
    'equipment': this.equipment,
  });

  patientName = new FormControl();

  bookingDetails = new FormGroup({
    'patientName': this.patientName
  });

  modReasonSelect = new FormControl();

  modReason = new FormGroup({
    modReasonSelect: this.modReasonSelect
  });

  calendarStatus = '';

  selectedService = null;
  selectedEvent = null;
  selectedServiceId = null;

  serviceChange = null;

  scheduleColorList = null;
  dateRangeSubscirption: Subscription;
  dateFrom = null;

  dateRange: DateRange;

  calendarLoading = false;
  freeAppointmentsLoading = false;

  calendarListDataAll = null;
  calendarListDataBookings = null;

  selectedAppointments = [];
  renderedCalendarItems = [];
  daysArray = ['Vasárnap', 'Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Szombat'];
  saveScheduleList: SaveScheduleList[] = [];

  selectedPatient = null;
  selectedPatientName = '';
  bookingSaveResult = null;
  successMessage = '';
  saveBookingInProgress = false;

  bookingDataValid = false;
  showSaveWithRule = false;
  saveWithRule = false;
  saveBookingErrorDescription = '';
  patientDataValue = null;
  isLoading = false;

  appointmentDetails = null;

  calendarDisplayMonths = 2;

  deleteInProgress = false;

  bookedCellDisplayData: string;

  scheduleFk: number;

  /* @ViewChild(SchedulerModalComponent) scheduler: SchedulerModalComponent; */

  // Create new booking from existing one, setting only patient date

  @Input() set patientData(data) {
    if (data !== null && data['calendarFk']) {
      this.patientDataValue = data;
      this.isLoading = true;
      // console.log('availservic', this.availServic);
      const serviceData = this.availServic.find(obj => obj.spId === data['servicePointFk']);
      const servicePointData = this.availServic.find(obj => obj.spId === data['servicePointFk']);
      const eventTypeData = this.availServic.find(obj => obj.etId === data['eventTypeFk']);

      if (serviceData) {
        this.service.setValue(serviceData);
      }
      if (servicePointData) {
        this.servicePoint.setValue(servicePointData);
      }
      if (eventTypeData) {
        this.eventType.setValue(eventTypeData);
      }
      this.sharedApiService.getCalendarDetail(data['calendarFk']).subscribe((responseData) => {
        this.isLoading = false;
        if (responseData['code'] === 1) {
          if (responseData['data']['participantTapass'] !== '') {
            this.patientName.setValue(responseData['data']['participantTapass']);
            this.selectedPatient = responseData['data'];
          } else {
            this.patientAlias.setValue(responseData['data']['participantAlias']);
          }
        } else {
          console.log('Error loading patient details calendarId: ' + data['calendarFk']);
          console.log(responseData);
        }
      });

    }
  }

  // Create new booking from empty slot
  // Modify, delete existing booking

  @Input() set appointmentData(data) {
    // console.log ('app data',data);
    if (data !== undefined) {
      if (data['servicePointFk']) {
        this.eventType.disable();
        this.servicePoint.disable();
        // console.log('appointment data:', data);
        // console.log('availService:', this.availServic);
        // console.log('availService find:', this.availServic.find(obj => obj.spId === data['servicePointFk'] && obj.svId === data['serviceFk']));
        this.bookingDateFrom = data.dateFrom;
        this.bookingDateTo = data.dateTo;
        this.saveScheduleList = [{ dateFrom: this.bookingDateFrom, dateTo: this.bookingDateTo, isEmergency: false }];
        this.calendarStatus = data['calendarStatus'];
        if (data['calendarStatus'] === 'S') {
          this.service.disable();
          this.eventType.disable();
          this.servicePoint.disable();
          this.patientAlias.disable();
          this.patientName.disable();
          this.time.disable();
          this.timeType.disable();
          this.quantity.disable();
          this.comment.disable();
          this.showSaveWithRule = false;
        }

        let sp = null;
        let et = null;
        let service = null;

        if (this.Brand === 'UDMED') {
          if (this.availServic.find(obj => obj.etId === data['eventTypeFk'])) {
            this.service.setValue(this.availServic.find(obj => obj.etId === data['eventTypeFk']));
          }
        } else {
          if (this.availServic.find(obj => obj.spId === data['servicePointFk'] && obj.svId === data['serviceFk'])) {
            // this.service.setValue(this.availServic.find(obj => obj.spId === data['servicePointFk'] && obj.svId === data['serviceFk']));
            service = this.availServic.find(obj => obj.spId === data['servicePointFk'] && obj.svId === data['serviceFk']);
          }
          if (this.availServic.find(obj => obj.spId === data['servicePointFk'])) {
            const spObj = this.availServic.find(obj => obj.spId === data['servicePointFk']);
            sp = { spId: spObj.spId, spName: spObj.spName }
            // this.servicePoint.setValue({ spId: sp.spId, spName: sp.spName });
          }
          if (this.availServic.find(obj => obj.etId === data['eventTypeFk'])) {
            const etObj = this.availServic.find(obj => obj.etId === data['eventTypeFk']);
            et = { etId: etObj.etId, etName: etObj.etName }
            // this.eventType.setValue({ etId: et.etId, etName: et.etName });
          }

          console.log('data: ', data);

          this.serviceSelect.setValue({
            'service': service,
            'eventType': et,
            'servicePoint': sp,
            'equipment': null
          });

          const sv = this.availServic.find(serv => serv.spId === this.servicePoint.value.spId && serv.etId === this.eventType.value.etId);

          if (sv.svDetail.svDetail.rules && sv.svDetail.svDetail.rules.subTypes) {

            const request: CheckEquiomentIsFreeReq = {
              etCode: sv.etCode,
              types: sv.svDetail.svDetail.rules.subTypes.replace(/\;/g, ','),
              dateFrom: this.sharedDataService.dateToDateTimeString(new Date(data.dateFrom)),
              dateTo: this.sharedDataService.dateToDateTimeString(new Date(data.dateTo))
            };

            this.apiService.checkEquipmentsIsFree(request).subscribe(
              response => {
                this.equipmentList = this.setEquipments(response.equipments);
                this.equipment.setValidators(Validators.required);
                this.equipment.updateValueAndValidity();
              }
            );
          }
          this.filterEt(this.servicePoint.value);
        }
        this.sharedDataService.setBookingDate(data['dateFrom']);
        this.appointmentDetails = data;
        this.showSaveWithRule = true;
        if (data['participantUserID']) {
          if (data['participantUserID'] !== '') {
            this.sharedApiService.getCalendarDetail(data['calendarFk']).subscribe((responseData) => {
              this.isLoading = false;
              if (responseData['code'] === 1) {
                if (responseData['data']['participantTapass'] !== '') {
                  // console.log('set patient');
                  this.patientName.setValue(responseData['data']['participantTapass']);
                  this.selectedPatient = responseData['data'];
                  // console.log('this.selectedPatient: ', this.selectedPatient);
                } else {
                  this.patientAlias.setValue(responseData['data']['participantAlias']);
                }
                this.comment.setValue(responseData['data']['diagnosisComment']);
              } else {
                console.log('Error loading patient details calendarId: ' + data['calendarFk']);
                console.log(responseData);
              }
            });
          }
        } else {
          console.log('else set name');
          this.patientAlias.setValue(data['participantName']?.trim());
          this.comment.setValue(data['calendarComment']);
          //this.patientAlias.enable();
          this.validateDataSetForBooking();
        }
      }
    }
  }

  ngOnInit() {
    if (this.sharedDataService.getSelectedRole()['roleCode'].includes('AUTH$PL-ECHOKFT') ||
      this.sharedDataService.getSelectedRole()['roleCode'].includes('AUTH$SZTVINCENT')) {
      this.szentVincent = true;
    }

    this.time.valueChanges.subscribe(
      t => {
        const pattern = new RegExp('^[0-9 ]*$');
        if (t !== '' && t !== null && !pattern.test(this.time.value)) {
          this.time.setValue(+this.time.value?.toString().slice(0, -1));
        }
        if (t && t < 1) {
          this.time.setValue(1);
        }
      });

    this.quantity.valueChanges.subscribe(
      q => {
        const pattern = new RegExp('^[0-9 ]*$');
        if (q !== '' && q !== null && !pattern.test(this.quantity.value)) {
          this.quantity.setValue(+this.quantity.value?.toString().slice(0, -1));
        }
        if (q && q < 1) {
          this.quantity.setValue(1);
        } else if (q > 30) {
          this.toastr.error('Maximum 30 időpont kiválasztására van lehetőség!');
          this.quantity.setValue(30);
        }
      });

    this.sharedDataService.spListShared.subscribe(
      value => {
        this.spList = value;
        this.spListFiltered = value;
      });

    this.sharedDataService.etListShared.subscribe(
      value => {
        this.etList = value;
        this.etListFiltered = value;
      });

    this.Brand = this.authService.getBrand();
    if (window.innerWidth <= 480) {
      this.calendarDisplayMonths = 1;
    } else {
      this.calendarDisplayMonths = 2;
    }

    this.selectedRole = this.sharedDataService.getSelectedRole();

    this.calendarModReasonsSubscription = this.sharedDataService.calendarModReasonsShared.subscribe(data => {
      if (data) {
        this.calendarModReasons = data;
        this.calendarModReasonsLoading = false;
        // console.log(data);
      }
    });

    this.availServicSubscription = this.sharedDataService.availServicesShared.subscribe(data => {
      const availServiceTemp = [];
      const etIdList = [];
      if (this.authService.getBrand() !== 'UDMED') {
        if (data) {
          if (data.length > 0) {
            data.forEach(serviceItem => {
              serviceItem.displayName = serviceItem.etName + ' - ' + serviceItem.spName;
              if (this.Brand === 'MEDCSEPP') {
                if (serviceItem.etId !== 12720854) {
                  availServiceTemp.push(serviceItem);
                }
              } else {
                availServiceTemp.push(serviceItem);
              }
            });
          }
        }
        this.availServiceLoading = false;
        this.availServic = availServiceTemp;
        // console.log('availServic: ', this.availServic);
      } else {
        if (data) {
          if (data.length > 0) {
            data.forEach(serviceItem => {
              serviceItem.displayName = serviceItem.etName + ' - ' + serviceItem.spName;
              if (etIdList.indexOf(serviceItem.etId) === -1) {
                etIdList.push(serviceItem.etId);
                availServiceTemp.push(serviceItem);
              }
            });
          }
        }
        this.availServiceLoading = false;
        this.availServic = availServiceTemp;
        // console.log('availServic: ', this.availServic);
      }
    });

    // console.log ('rolecode at init');
    // console.log (this.selectedRole['roleCode']);
    // console.log ('avail service at init');
    // console.log (this.availServic);

    // console.log('booking modal');
    // console.log('booking modal selected role: ' + this.selectedRole);
    // console.log('booking modal avail service: ' + this.availServic);

    this.patientListDataSubscription = this.sharedDataService.patientListShared.subscribe((patientList) => {
      this.patientListLoading = false;
      this.patientList = patientList;
    });

    this.serviceChange = this.serviceSelect.valueChanges.subscribe(selectedService => {

      if (selectedService.service && (
        this.selectedService !== selectedService.service['spId'] ||
        this.selectedServiceId !== selectedService.service['svId'] ||
        this.selectedEvent !== selectedService.service['etId']
      )) {
        this.calendarListDataAll = null;

        this.selectedService = selectedService.service['spId'];
        this.selectedServiceId = selectedService.service['svId'];
        this.selectedEvent = selectedService.service['etId'];
        this.selectedAppointments = [];
        this.validateDataSetForBooking();


        this.getScheduleColorList().subscribe(
          (data: Response) => {
            // console.log('Schedule Color List:', data);
            if (data['code'] === 1) {
              data['objScheduleColor'].forEach(scheduleColorEntry => {

                scheduleColorEntry['dateFrom'] = this.sharedDataService.DateStringConvert(scheduleColorEntry['dateFrom']);

              });
              this.scheduleColorList = data['objScheduleColor'];
              this.sharedDataService.setCalendarData(data['objScheduleColor']);
            }
          }
        );
        if (this.dateRange) {
          if (this.dateRange.fromDate !== null) {
            this.getBookingData();
          }
        }
      } else if (selectedService.eventType && selectedService.servicePoint) {
        const selectedAvailServc = this.availServic.find(s => s.etId === selectedService.eventType.etId
          && s.spId === selectedService.servicePoint.spId);
        this.selectedService = selectedAvailServc['spId'];
        this.selectedServiceId = selectedAvailServc['svId'];
        this.selectedEvent = selectedAvailServc['etId'];
      }
    }
    );
    this.dateRangeSubscirption = this.sharedDataService.bookingDateRangeChange.subscribe((dateRange: DateRange) => {
      this.dateRange = dateRange;
      /* this.dateRange.fromDate.setDate(this.dateRange.fromDate.getDate()+1);
      this.dateRange.toDate?.setDate(this.dateRange.toDate.getDate()+1); */
      this.weekSchedule = [];
      const dateRangeFromCopy = dateRange.fromDate ? new Date(dateRange.fromDate.getTime()) : null;
      if (dateRangeFromCopy) {
        if (dateRange.toDate) {
          while (dateRangeFromCopy <= dateRange.toDate) {
            dateRangeFromCopy.setDate(dateRangeFromCopy.getDate() + 1);
            const params: WeekScheduleDay = {
              name: this.sharedDataService.dateToFormattedDateString(dateRangeFromCopy) + '\n' + this.daysArray[dateRangeFromCopy.getDay()],
              timeStamp: 5
            };
            this.weekSchedule.push(params);
          }
          if (this.isServiceSelected()) {
            this.getBookingData();
          }
        } else {
          dateRangeFromCopy.setDate(dateRangeFromCopy.getDate() + 1);
          const params: WeekScheduleDay = {
            name: this.sharedDataService.dateToFormattedDateString(dateRangeFromCopy) + '\n' + this.daysArray[dateRangeFromCopy.getDay()],
            timeStamp: 5
          };
          this.weekSchedule.push(params);
          if (this.isServiceSelected()) {
            this.getBookingData();
          }
        }
      } else {
        this.toastr.warning('a kiválasztott napok száma maximum 9 lehet!');
      }
    });

    /* this.dateSubscription = this.sharedDataService.bookingDateChange.subscribe((date: string) => {
      if (this.dateFrom !== date) {
        this.dateFrom = date;

        //  console.log('booking date change', date);

        if (this.selectedService) {
          this.getBookingData();
        }
      }
    }); */

    this.multiCalendar = this.sharedDataService.appointmentDataShared.subscribe(
      value => {
        this.start = new Date(value.dateFrom);
        this.end = new Date(value.dateTo);
        this.multiCal = value.multi;
        this.scheduleFk = value.scheduleFk;
      });
  }

  isServiceSelected() {
    return this.serviceSelect.controls.service.value ||
      (this.serviceSelect.controls.eventType.value && this.serviceSelect.controls.servicePoint.value);
  }

  // Oninit END ----------------

  ngOnDestroy() {
    this.resetBookingModal();
    this.sharedDataService.setAppointmentData({ NGdestinationService: 'bookAppointment', multi: false });
    this.availServicSubscription.unsubscribe();
    this.patientListDataSubscription.unsubscribe();
    this.serviceChange.unsubscribe();
    if (this.multiCalendar) {
      this.multiCalendar.unsubscribe();
    }

  }

  /* calculateBookedCells(dateFrom: Date, dateTo: Date): BookedCells {
    let params: BookedCells = {
      name: dateFrom.getFullYear() + '/' + dateFrom.getMonth()+1 + '/' + dateFrom.getDate() + '\n' + this.daysArray[dateFrom.getDay()],
      dateFrom: dateFrom.getHours() * 100 + dateFrom.getMinutes(),
      dateTo: dateTo.getHours() * 100 + dateTo.getMinutes()
    }
    return params;
  } */

  changeDateRange(event) {
    this.dayLenghtInHours = { hourFrom: event.hourFrom, hourTo: event.hourTo };
  }

  showBookedCellInformationModal(event) {
    this.bookedCellDisplayData = '';

    if (event?.displayData) {
      this.bookedCellDisplayData = event.displayData;
      this.scheduleToDeleteCalendarFk = +event.displayData.substring(0, event.displayData.indexOf('/'));
      this.deleteAppointmentModal = true;
    }
  }

  bookCells(event) {
    this.saveScheduleList = [];

    if (event) {
      if (event.length === 1) {
        this.showSaveWithRule = true && this.sharedDataService.getSelectedRole()['roleCode'].includes('AUTH$PL-ECHOKFT') ||
          this.sharedDataService.getSelectedRole()['roleCode'].includes('AUTH$SZTVINCENT');
      } else {
        this.quantity.reset();
        this.time.reset();
        this.timeType.reset();
        this.showSaveWithRule = false;
      }
      event.forEach(
        (e, i) => {
          this.isEmergency = e.status === 'F' ? true : false;
          this.bookingDateFrom = new Date(e.name.substring(0, e.name.indexOf('\n')));
          this.bookingDateTo = new Date(this.bookingDateFrom);
          this.bookingDateFrom.setHours(e.dateFrom.hour);
          this.bookingDateFrom.setMinutes(e.dateFrom.minute);
          this.bookingDateTo.setHours(e.dateTo.hour);
          this.bookingDateTo.setMinutes(e.dateTo.minute);

          const sv = this.availServic.find(serv => serv.spId === this.servicePoint.value.spId && serv.etId === this.eventType.value.etId);

          if (sv.svDetail.svDetail.rules && sv.svDetail.svDetail.rules.subTypes) {

            const request: CheckEquiomentIsFreeReq = {
              etCode: sv.etCode,
              types: sv.svDetail.svDetail.rules.subTypes.replace(/\;/g, ','),
              dateFrom: this.sharedDataService.dateToDateTimeString(this.bookingDateFrom),
              dateTo: this.sharedDataService.dateToDateTimeString(this.bookingDateTo)
            };

            this.apiService.checkEquipmentsIsFree(request).subscribe(
              response => {
                this.equipmentList = this.setEquipments(response.equipments);
                this.equipment.setValidators(Validators.required);
                this.equipment.updateValueAndValidity();
              }
            );
          }


          this.saveScheduleList.push({ dateFrom: this.bookingDateFrom, dateTo: this.bookingDateTo, isEmergency: this.isEmergency });
        });
    } else {
      this.showSaveWithRule = false;
      this.bookingDateFrom = null;
      this.bookingDateTo = null;
    }

  }

  deleteCells(event) {
    if (event) {
      this.scheduleToDeleteCalendarFk = +event.substring(0, event.indexOf('/'));
      this.deleteAppointmentModal = true;
    }
  }
  getBookingData() {

    this.bookingDataLoading = true;
    if (this.dateRange) {

      if (this.calendarListDataSubscription) {
        this.calendarListDataSubscription.unsubscribe();
      }

      this.calendarListDataSubscription = this.getCalendarListData(this.selectedRole['roleCode'], this.selectedService).subscribe(
        (calendarData: Response) => {
          this.calendarLoading = false;
          this.freeAppointmentsLoading = false;
          this.bookingDataLoading = false;

          if (calendarData['code'] === 1) {
            const calendarListDataBookings = [];
            const searchedSchedules = [];
            const bookedCellsCopy = []
            const freeCellsCopy = [];
            const tempCellsCopy = [];
            const coCellsCopy = [];

            this.calendarListDataAll = calendarData['data'];
            this.bookedCells = [];
            this.freeCells = [];
            this.tempCells = [];
            if (this.calendarListDataAll.length > 0) {
              this.calendarListDataAll.forEach(entry => {
                entry['slotLength'] = ((entry['dateTo'] - entry['dateFrom']) / 900000);
                if (entry['participantName']) {
                  if (entry['participantName'].indexOf('#') > -1) {
                    entry['participantUserID'] = entry['participantName'].substring(entry['participantName'].indexOf('#') + 1);
                    entry['participantName'] = (entry['participantName'].substring(0, entry['participantName'].indexOf('#'))).trim();
                    entry['registeredPatient'] = true;
                  }
                  entry['displayData'] = (entry['participantName']).trim();
                  if (entry['calendarComment'] && entry['calendarComment'] !== '') {
                    if (entry['displayData'] !== '') {
                      entry['displayData'] += ', ' + entry['calendarComment'];
                    } else {
                      entry['displayData'] = entry['calendarComment'];
                    }
                  }
                }

                let groupedSchedule = null;
                if (!searchedSchedules.includes(entry.scheduleFk)) {
                  const groupedByIterationSchedule = this.calendarListDataAll.filter(x => x.scheduleFk === entry.scheduleFk);
                  groupedSchedule = groupedByIterationSchedule[0];
                  groupedSchedule.dateTo = groupedByIterationSchedule[groupedByIterationSchedule.length - 1].dateTo;
                  searchedSchedules.push(entry.scheduleFk);
                }

                if (entry['calendarStatus'] === 'S') {
                  bookedCellsCopy.push(this.getBookedCell(entry, 'B', entry['calendarFk'] + '/' + entry['longDescription']));
                } else if (groupedSchedule['calendarStatus'] === 'F' && groupedSchedule) {
                  freeCellsCopy.push(this.getBookedCell(groupedSchedule, 'A', ''));
                } else if (entry['calendarStatus'] === 'T') {
                  tempCellsCopy.push(this.getBookedCell(entry, 'T', entry['calendarFk'] + '/' + entry['longDescription']));
                } else if (entry['calendarStatus'] === 'CO') {
                  coCellsCopy.push(this.getBookedCell(entry, 'CO', entry['calendarFk'] + '/' + entry['longDescription']));
                }

                if (this.appointmentDetails) {
                  if (entry['scheduleFk'] === this.appointmentDetails['scheduleFk']) {
                    entry['isSelected'] = true;
                    this.selectedAppointments.push(entry);
                    this.validateDataSetForBooking();
                  }
                }

              });
            }
            this.bookedCells = bookedCellsCopy;
            this.freeCells = freeCellsCopy;
            this.tempCells = tempCellsCopy;
            this.coCells = coCellsCopy;

            this.calendarListDataBookings = calendarListDataBookings.slice();
          } else {
            // hiba
          }
        }
      );

    }
  }

  getBookedCell(entry, status, displayData): BookedCells {
    entry['dateTo'] = this.sharedDataService.DateStringConvert(entry['dateTo']);
    entry['dateFrom'] = this.sharedDataService.DateStringConvert(entry['dateFrom']);
    let dateFrom = new Date(entry['dateFrom']).getHours() * 100 + new Date(entry['dateFrom']).getMinutes();
    let dateTo = new Date(entry['dateTo']).getHours() * 100 + new Date(entry['dateTo']).getMinutes();
    let name = new Date(entry['dateFrom']).getFullYear() + '/' + (('0' + (new Date(entry['dateFrom']).getMonth() + 1)).slice(-2)) + '/' + (('0' + new Date(entry['dateFrom']).getDate()).slice(-2)) + '\n' + this.daysArray[new Date(entry['dateFrom']).getDay()];
    return {
      dateFrom,
      dateTo,
      name,
      status,
      displayData
    }
  }

  setPatientData(data: any, type: string) {
    data['NGdestinationService'] = type;
    this.sharedDataService.setPatientData(data);
  }

  resetBookingModal() {
    // console.log(' close booking modal - reset');
    this.service.enable();
    this.servicePoint.enable();
    this.eventType.enable();
    this.patientAlias.enable();
    this.patientName.enable();
    this.comment.enable();
    this.scheduleToDeleteCalendarFk = null;
    this.weekSchedule = [];
    this.time.enable();
    this.timeType.enable();
    this.quantity.enable();
    this.time.setValue('');
    this.timeType.setValue('');
    this.quantity.setValue('');
    this.patientName.setValue(null);
    this.patientAlias.setValue('');
    this.comment.setValue('');
    this.serviceSelect.reset();
    this.eventType.reset();
    this.servicePoint.reset();
    this.weekSchedule = [];
    this.tempCells = [];
    this.freeCells = [];
    this.coCells = [];
    this.equipmentList = [];
    this.calendarListDataAll = null;
    this.setPatientData({}, 'bookAppointment');
    this.sharedDataService.setCalendarData(null);
    this.sharedDataService.setAppointmentData({ NGdestinationService: 'bookAppointment', multi: false });
    this.selectedService = null;
    this.bookingDataValid = false;
    this.dateFrom = null;
    this.calendarStatus = '';
    this.bookingSaveResult = null;
    this.saveBookingErrorDescription = '';
    this.modReasonSelect.setValue(null);

    this.etListFiltered = this.etList;
    this.spListFiltered = this.spList;

  }

  onSelectedPatientChange($event) {
    if ($event !== undefined) {
      this.selectedPatient = $event;
      this.selectedPatientName = this.selectedPatient.displayData;
      this.validateDataSetForBooking();
      this.bookingSaveResult = null;
      this.patientAlias.setValue(null);
    } else {
      this.selectedPatient = null;
      this.selectedPatientName = '';
      this.validateDataSetForBooking();
      this.bookingSaveResult = null;
    }
  }

  onPatientAliasChange($event) {
    if (this.patientAlias.valid === true) {
      this.patientName.setValue(null);
      this.selectedPatient = null;
    }
    this.validateDataSetForBooking();
  }

  getCalendarListData(role: string, servicePointFk: string) {
    this.selectedAppointments = [];
    this.validateDataSetForBooking();

    // console.log('Booking getCalendarListData role: ' + role + ', spId: ' + servicePointFk);

    this.calendarLoading = true;
    this.freeAppointmentsLoading = true;

    // params:
    //   •	tapass: User tapass. Kötelező paraméter. : string
    //   •	actualRole: A kérő user aktuálisan kiválasztott szerepköre. Kötelező paraméter. : string
    //   •	dateFrom: Előjegyzések kezdő dátuma. Kötelező paraméter. Formátum: yyyy-MM-dd : string
    //   •	dateTo: Előjegyzések befejező dátuma. Kötelező paraméter. Formátum: yyyy-MM-dd : string
    //   •	deviceId: Kérő eszköz azonosító (ip, mac, eszköz azonosító stb.). : string
    //   •	locale: Lekérés nyelvterülete. Pl. "hu" Magyarország : string
    const dateFromCopy = new Date(this.dateRange.fromDate);
    dateFromCopy.setDate(dateFromCopy.getDate() + 1);
    const dateToCopy = this.dateRange.toDate ? new Date(this.dateRange.toDate) : null;
    dateToCopy?.setDate(dateToCopy.getDate() + 1);
    const dateFrom = dateFromCopy.toISOString().substring(0, dateFromCopy.toISOString().indexOf('T'));
    const dateTo = dateToCopy ? dateToCopy.toISOString().substring(0, dateToCopy.toISOString().indexOf('T')) : dateFrom;
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      servicePointId: this.Brand === 'UDMED' ? null : (servicePointFk ? servicePointFk : this.servicePoint.value.spId),
      dateFrom,
      dateTo,
      showFreeAppointments: true,
      eventId: this.selectedEvent
    };


    return this.http.post(this.apiUrl + '/rest/neu/calendarlist/getcalendarlistdata', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getScheduleColorList() {
    // console.log ('getScheduleColorList this.selectedService', this.selectedService);

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      doctorId: '',
      eventTypeId: this.selectedEvent,
      servicePointId: this.Brand === 'UDMED' ? null : this.selectedService,
      contextParam: ''
    };



    return this.http.post(this.apiUrl + '/rest/neu/appointment/getschedulecolorlistv2', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getScheduleColorList2() {
    // console.log ('getScheduleColorList this.selectedService', this.selectedService);

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      doctorId: '',
      eventTypeId: this.selectedEvent,
      servicePointId: this.selectedService,
      contextParam: ''
    };


    return this.http.post(this.apiUrl + '/rest/neu/appointment/getschedulecolorlistv2', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }


  calendarItemStatus(entry: any) {

    if (entry['calendarStatus'] === 'S' || entry['calendarStatus'] === 'SG') {
      return '';
    } else if (entry['calendarStatus'] === 'F') {
      return 'appointment_free';
    } else {
      return '';
    }

  }

  showAppointmentDetails(i: number) {
    // console.log('appointment details');
    // console.log(this.calendarListDataAll[i]);
    if (this.calendarListDataAll[i].calendarFk !== null) {

    }

  }

  updateScheduleColorlist() {
    this.getScheduleColorList2().subscribe(
      (data: Response) => {
        if (data['code'] === 1) {
          data['objScheduleColor'].forEach(scheduleColorEntry => {

            scheduleColorEntry['dateFrom'] = this.sharedDataService.DateStringConvert(scheduleColorEntry['dateFrom']);

          });
          this.scheduleColorList = data['objScheduleColor'];
          this.sharedDataService.setCalendarData(data['objScheduleColor']);
        }
      }
    );
  }


  selectAppointment(i: number) {

    // console.log('select appointment');
    // console.log(this.calendarListDataAll[i]);

    // console.log ('check selected appointments:', this.selectedAppointments);

    if (this.selectedAppointments.length > 0) {

      const newSelectedAppointments = this.selectedAppointments.slice();
      newSelectedAppointments.push(this.calendarListDataAll[i]);

      if (this.checkSelectedAppointmentsConsistency(newSelectedAppointments) === true) {
        this.calendarListDataAll[i]['isSelected'] = true;
        this.selectedAppointments.push(this.calendarListDataAll[i]);
        this.validateDataSetForBooking();
        this.bookingSaveResult = null;
      } else {

        // console.log('Nem konzisztens idopontok');
      }

    } else {

      this.calendarListDataAll[i]['isSelected'] = true;
      this.selectedAppointments.push(this.calendarListDataAll[i]);
      this.bookingSaveResult = null;
      this.validateDataSetForBooking();

    }

  }

  deSelectAppointment(i: number) {
    this.selectedAppointments.sort(this.sortSelectedAppointments);

    // console.log('deselect appointment', this.calendarListDataAll[i]);
    // console.log('selected appointments', this.selectedAppointments);
    // console.log('index of appointment to delete', this.selectedAppointments.indexOf(this.calendarListDataAll[i]));

    if (this.selectedAppointments.indexOf(this.calendarListDataAll[i]) === 0 ||
      this.selectedAppointments.indexOf(this.calendarListDataAll[i]) === this.selectedAppointments.length - 1) {
      this.selectedAppointments.splice(this.selectedAppointments.indexOf(this.calendarListDataAll[i]), 1);
      // console.log('item removed');
      this.calendarListDataAll[i]['isSelected'] = false;
      this.validateDataSetForBooking();
      this.bookingSaveResult = null;
    } else {
      // console.log('Item cannot be removed');
    }
  }

  sortSelectedAppointments(a, b) {
    if (a['dateFrom'] > b['dateFrom']) {
      return 1;
    } else if (a['dateFrom'] < b['dateFrom']) {
      return -1;
    }
    return 0;
  }

  validateDataSetForBooking() {
    // console.log ('this.patientAlias.valid', this.patientAlias.valid);
    if (/* this.selectedAppointments.length > 0 && */ (this.selectedPatient !== null || this.patientAlias.valid)) {
      this.bookingDataValid = true;
    } else {
      this.bookingDataValid = false;
    }
  }

  checkSelectedAppointmentsConsistency(selectedAppointments) {

    const appointmentList = selectedAppointments.slice();

    if (appointmentList.length > 1) {

      appointmentList.sort(function (a, b) {
        const startTimeA = a.dateFrom;
        const startTimeB = b.dateFrom;
        return startTimeA - startTimeB;
      });

      let previousDateTo = 0;

      for (const item of appointmentList) {
        if (previousDateTo > 0) {
          if (item.dateFrom !== previousDateTo) {
            return false;
          }
        }
        previousDateTo = item.dateTo;
      }

      return true;

    } else {
      return true;
    }
  }

  saveAppointment($event) {
    if (this.equipment.invalid) {
      this.toastr.error('kérem válasszon eszközt a listából!');
      return;
    }

    this.saveWithRule = false;
    if (this.quantity.value && this.timeType.value && this.time.value) {
      this.saveWithRule = true;
    }



    if (!this.saveWithRule) {
      if (this.multiCal) {
        this.bookingSaveResult = null;
        this.saveBookingInProgress = true;
        this.callSaveSchedule(this.scheduleFk.toString());

      } else if (this.bookingDateFrom && this.bookingDateTo) {
        this.bookingSaveResult = null;
        this.saveBookingInProgress = true;
        this.saveSchedule(this.patientAlias.value, this.comment.value);
      } else {
        this.saveBookingInProgress = false;
        this.toastr.error('kérem adjon meg egy időintervallumot!');
      }
    } else {
      this.bookingSaveResult = null;
      this.saveBookingInProgress = true;
      // if ()

      if (this.bookingDateFrom && this.bookingDateTo) {
        for (let i = 0; i < this.saveScheduleList.length; i++) {
          const dateFrom = new Date(this.saveScheduleList[i].dateFrom);

          const saveSceduleWithRule: SaveAppointmentWithRuleRequest = {
            startDate: dateFrom,
            messageHeader: {
              currentTapass: this.authService.getUserData()['ekCardNo'],
              currentRole: this.selectedRole['roleCode'],
              currentServicePointId: this.sharedDataService.selectedRole['spId'],
              locale: 'hu',
              deviceId: this.authService.getUUID(),
              sessionId: null
            },
            equipment: this.equipment.value,
            tapass: this.selectedPatient?.tapass,
            serviceId: this.selectedServiceId,
            quantity: this.quantity.value,
            time: this.time.value,
            timeType: this.timeType.value,
            schedules: null
          };

          this.apiService.saveAppointmentWithRule(saveSceduleWithRule).subscribe(
            response => {
              this.saveBookingInProgress = false;
              const data = {
                tapass: this.selectedPatient?.tapass,
                comment: this.comment.value,
                alias: this.patientAlias.value,
                etId: this.selectedEvent,
                patient: this.selectedPatient,
                serviceId: this.selectedServiceId,
                servicePointId: this.selectedService,
                currentRole: this.selectedRole['roleCode'],
                schedules: response,
                equipment: this.equipment.value
              };


              const dialogRef = this.dialog.open(ScheduleDialogComponent,
                {
                  id: 'schedule-dialog',
                  disableClose: true,
                  data,
                  minHeight: '50%',
                  minWidth: '50%',
                  width: '50%',
                  height: '50%'
                });

              dialogRef.afterClosed().subscribe(result => {
                this.getBookingData();
                this.time.setValue('');
                this.timeType.setValue(null);
                this.quantity.setValue('');

              });
            });
        }
      } else {
        this.saveBookingInProgress = false;

        this.toastr.error('kérem adjon meg egy időintervallumot!');
      }
    }
  }

  saveSchedule(patientAlias, comment) {
    /* this.bookingDateTo.setHours(this.bookingDateTo.getHours());
    this.bookingDateFrom.setHours(this.bookingDateFrom.getHours()); */
    if (this.saveScheduleList[0].isEmergency) {
      this.sharedApiService.getUserSimple(this.bookingDetails.controls.patientName.value).subscribe(res => {
        let params: SaveAppointmentWeeklyRequest = {

          participantAlias: patientAlias,
          comment,
          dateFrom: this.sharedDataService.dateToDateTimeString(this.bookingDateFrom),
          dateTo: this.sharedDataService.dateToDateTimeString(this.bookingDateTo),
          isEmergency: this.isEmergency,
          messageHeader: {
            currentTapass: this.authService.getUserData()['ekCardNo'],
            currentRole: this.selectedRole['roleCode'],  // currentRole: this.sharedDataService.selectedRole['roleCode'],
            currentServicePointId: this.sharedDataService.selectedRole['spId'],
            locale: 'hu',
            deviceId: this.authService.getUUID(),
            sessionId: null //this.bookingDetails.controls.patientName.value
          },
          eventTypeId: this.selectedEvent,
          participantId: res['user']['userId'],
          servicePointId: this.selectedService
        };
        this.apiService.saveAppointmentWeekly(params).subscribe(res => {
          this.bookingDataValid = false;
          if (res.code > -1) {
            this.updateScheduleColorlist();
            this.saveBookingInProgress = false;
            this.toastr.info(res.message);
            this.patientName.setValue(null);
            this.patientAlias.setValue('');
            this.comment.setValue('');
            this.bookingDateFrom = null;
            this.bookingDateTo = null;
            this.getBookingData();
          } else {
            this.saveBookingInProgress = false;
            this.toastr.error(res.message);
          }
        }, err => {
          this.toastr.error(err);
          this.saveBookingInProgress = false;
        });
      }, err => {
        this.bookingDataValid = false;
      });
    } else {

      let ids = '';
      let j = 0;
      for (let i = 0; i < this.saveScheduleList.length; i++) {

        let dateFrom = new Date(this.saveScheduleList[i].dateFrom);
        let dateTo = new Date(this.saveScheduleList[i].dateTo);
        /*dateFrom.setHours(dateFrom.getHours() - 1);
        dateTo.setHours(dateTo.getHours() - 1);*/
        dateTo.setMinutes(dateTo.getMinutes() - 1);

        let getScheduleListParams = {
          messageHeader: {
            currentTapass: this.authService.getUserData()['ekCardNo'],
            currentRole: this.sharedDataService.selectedRole['roleCode'],
            currentServicePointId: this.sharedDataService.selectedRole['spId'],
            locale: 'hu',
            deviceId: this.authService.getUUID()
          },
          doctorId: '',
          eventId: this.selectedEvent,
          servicePointId: this.selectedService,
          dateFrom: dateFrom,
          dateTo: dateTo,
        }

        this.apiService.getScheduleList(getScheduleListParams).subscribe(res => {
          ids += res.scheduleFkList + ',';
          if (this.saveScheduleList.length - 1 === j) {
            this.callSaveSchedule(ids.slice(0, -1));
          }
          j++;
        }, err => {
          console.log('getlist error:', err);
        });
      }
    }

    /* let scheduleFkList = '';
    for (const item of this.selectedAppointments) {
      scheduleFkList = scheduleFkList + ',' + item.scheduleFk;
    }
    scheduleFkList = scheduleFkList.substring(1);

    // console.log('scheduleFkList', scheduleFkList);
    // console.log ('selected Patient:', this.selectedPatient);

    let tapass = '';
    let ssnType = '';
    let ssnNumber = '';

    if (this.selectedPatient) {
      if (this.selectedPatient.tapass) {
        tapass = this.selectedPatient.tapass;
        ssnType = this.selectedPatient.medicalType;
        ssnNumber = this.selectedPatient.medicalId;
      } else if (this.selectedPatient.participantTapass) {
        tapass = this.selectedPatient.participantTapass;
      }
    }

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.selectedRole['roleCode'],  // currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.selectedService,
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      patientTapass: tapass,
      providerUserId: '',
      ssnType: ssnType,
      ssnNumber: ssnNumber,
      patientAlias: patientAlias,
      scheduleFkList: scheduleFkList,
      diagnosisComment: comment
    };

    return this.http.post(this.apiUrl + '/rest/neu/appointment/saveschedule', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }); */
  }

  callSaveSchedule(ids) {
    let tapass = '';
    let ssnType = '';
    let ssnNumber = '';

    if (this.selectedPatient) {
      if (this.selectedPatient.tapass) {
        tapass = this.selectedPatient.tapass;
        ssnType = this.selectedPatient.medicalType;
        ssnNumber = this.selectedPatient.medicalId;
      } else if (this.selectedPatient.participantTapass) {
        tapass = this.selectedPatient.participantTapass;
      }
    }

    let params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      doctorId: '',
      eventTypeId: this.eventType.value?.etId ? this.eventType.value.etId : null,
      patientTapass: tapass,
      providerUserId: '',
      ssnType,
      ssnNumber,
      patientAlias: this.patientAlias.value,
      scheduleFkList: ids,
      diagnosisComment: this.comment.value,
      equipment: this.equipment?.value
    }


    this.apiService.saveScheduleDay(params).subscribe(res => {
      this.bookingDataValid = false;
      if (res.code > -1) {
        this.updateScheduleColorlist();
        this.sharedDataService.mulriCalendarChanged.next(true);
        this.saveBookingInProgress = false;
        this.toastr.success('Sikeres mentés!');
        this.patientName.setValue(null);
        this.patientAlias.setValue('');
        this.comment.setValue('');
        this.bookingDateFrom = null;
        this.bookingDateTo = null;
        this.getBookingData();
        this.saveScheduleList = [];
        if (this.multiCal) {
          const button = document.getElementById('closeButton') as HTMLElement;
          button.click();
          this.scheduleToDeleteCalendarFk = null;
          this.service.setValue(null);
          this.eventType.setValue(null);
          this.servicePoint.setValue(null);
          this.calendarListDataAll = null;
          this.bookingDataValid = false;
          this.dateFrom = null;
          this.calendarStatus = '';
          this.bookingSaveResult = null;
          this.saveBookingErrorDescription = '';
          this.service.enable();
          this.servicePoint.enable();
          this.eventType.enable();
          this.patientAlias.enable();
          this.patientName.enable();
          this.comment.enable();
          this.multiCal = false;
        }
      } else {
        this.saveBookingInProgress = false;
        this.toastr.error(res.message);
      }
    }, err => {
      this.bookingDataValid = false;
      this.toastr.error(err);
      this.saveBookingInProgress = false;
    });
  }
  closeResult() {
    this.bookingSaveResult = null;
  }

  onResize($event) {
    if ($event.target.innerWidth <= 480 && this.calendarDisplayMonths === 2) {
      // console.log('switch calendar to one month');
      this.calendarDisplayMonths = 1;
    } else if ($event.target.innerWidth > 480 && this.calendarDisplayMonths === 1) {
      // console.log('switch calendar to two months');
      this.calendarDisplayMonths = 2;
    }
  }

  showDeleteAppointment(show: boolean) {
    this.deleteAppointmentModal = show;
  }

  updateCalendar(params: {}) {
    return this.http.post(this.apiUrl + '/rest/neu/calendarlist/updatecalendar', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }

  deleteAppointment() {

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.selectedRole['roleCode'],  // currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calendarId: /* this.appointmentDetails.calendarFk */this.scheduleToDeleteCalendarFk ? this.scheduleToDeleteCalendarFk : this.appointmentDetails['calendarFk'],
      patientId: /* this.appointmentDetails.registeredPatient ? this.appointmentDetails.participantUserID :  */'',
      doctorId: '',
      status: 'X',
      calModReasonCode: this.modReasonSelect.value.code,
      diagnosisComment: '',
      isCalDetailUpdateEnabled: true
    };

    this.deleteInProgress = true;

    this.updateCalendar(params).subscribe(response => {
      this.showDeleteAppointment(false);
      this.bookedCellDisplayData = '';
      this.deleteInProgress = false;
      if (response['code'] === 1) {
        this.sharedDataService.mulriCalendarChanged.next(true);
        if (this.multiCal) {
          this.calendarStatus = '';
          this.bookingSaveResult = null;
          this.saveBookingErrorDescription = '';
          this.multiCal = false;
        }
        this.bookingDateFrom = null;
        this.bookingDateTo = null;
        this.saveScheduleList = [];
        this.time.enable();
        this.timeType.enable();
        this.quantity.enable();
        this.calendarListDataAll = null;
        this.scheduleToDeleteCalendarFk = null;
        this.service.enable();
        this.eventType.enable();
        this.servicePoint.enable();
        this.comment.enable();
        this.equipmentList = [];
        this.showSaveWithRule = false;
        this.comment.reset();
        this.modReasonSelect.setValue(null);
        this.bookingSaveResult = 0;
        this.successMessage = 'Foglalás sikeresen törölve!';
        setTimeout(() => { this.bookingSaveResult = null; }, 3000);

        this.calendarStatus = 'F';
        // console.log ('selected appointments:', this.selectedAppointments);
        let bookingDate;
        if (this.appointmentDetails) {
          bookingDate = new Date(this.appointmentDetails['dateFrom']);
          this.sharedDataService.refeshCalendarWithDate(this.selectedService, bookingDate);
        }
        this.selectedAppointments = [];
        this.appointmentDetails = null;
        this.validateDataSetForBooking();
        this.getBookingData();
      } else {
        this.bookingSaveResult = -1;
        this.saveBookingErrorDescription = response['code'] + ' - ' + response['message'];
      }
    });
  }

  filterEt(event) {
    this.equipment.reset();
    this.equipmentList = [];
    this.equipment.clearValidators();
    this.showSaveWithRule = false;

    if (event && event.spId) {
      const etList = [];
      this.availServic.forEach(
        serv => {
          if (serv.spId === event.spId && !etList.find(e => e.etId === serv.etId)) {
            etList.push({ etId: serv.etId, etName: serv.etName });
          }
        });
      this.etListFiltered = etList;

      if (this.eventType.value) {
        this.updateScheduleColorlist();
      } else {
        this.sharedDataService.setCalendarData(null);
      }
      if (this.dateRange) {
        if (this.dateRange.fromDate !== null) {
          this.getBookingData();
        }
      }
    } else {
      this.etListFiltered = this.etList;
      this.sharedDataService.setCalendarData(null);
    }
    this.equipment.updateValueAndValidity();
  }

  setEquipments(equipmentList: string[]) {
    const equipments = [];
    equipmentList.forEach(
      eq => {
        const splittedEq = eq.split('=');
        equipments.push({ name: splittedEq[1], type: splittedEq[0] });
      }
    );
    return equipments;
  }

  filterSp(event) {
    this.equipment.reset();
    this.equipmentList = [];
    this.equipment.clearValidators();
    this.showSaveWithRule = false;

    if (event && event.etId) {
      const spList = [];
      this.availServic.forEach(
        serv => {
          if (serv.etId === event.etId && !spList.find(s => s.spId === serv.spId)) {
            spList.push({ spId: serv.spId, spName: serv.spName });
          }
        });
      this.spListFiltered = spList;

      if (this.servicePoint.value) {
        this.updateScheduleColorlist();
      } else {
        this.sharedDataService.setCalendarData(null);
      }
      if (this.dateRange) {
        if (this.dateRange.fromDate !== null) {
          this.getBookingData();
        }
      }
    } else {
      this.spListFiltered = this.spList;
      this.sharedDataService.setCalendarData(null);
    }
    this.equipment.updateValueAndValidity();
  }

  openHelp(anchor) {
    const newWindow = window.open('https://' + this.authService.getHostname() + '/kezikonyv/' + anchor);
  }
}

// if (data['message'] === '0') { // Foglalas sikeresen mentve
//   // console.log('Foglalas sikeresen mentve');
//   const bookingDate = new Date(this.selectedAppointments[0]['dateFrom']);

//   this.sharedDataService.refeshCalendarWithDate(this.selectedService, bookingDate);

//   this.bookingSaveResult = 0;
//   this.selectedAppointments = [];
//   this.selectedPatient = null;
//   this.patientName.setValue(null);
//   this.patientAlias.setValue('');
//   this.comment.setValue('');

//   this.validateDataSetForBooking();
//   setTimeout(() => { this.bookingSaveResult = null; }, 3000);

//   this.getBookingData();

// } else {   // Hiba a foglalas mentesenel
//   // console.log('Hiba a foglalas mentesenel');
//   this.bookingSaveResult = -1;
//   this.saveBookingErrorDescription = data['message'];
// }
