<div class="reloginQuitConfirmation">
    <div class="quitConfirmationContent">
        <h6 class="warning"><i class="fa fa-exclamation-circle"></i> A munkamenet lejárt, kérem jelentkezzen be újra</h6>
      <div class="col-md-12 mt-4">
          <form class="form-signin" [formGroup]="loginForm">
              <label for="inputEmail">Felhasználónév</label>
              <input type="email" class="form-control mb-2"  required="" autofocus=""
                formControlName="email" >
              <label for="inputPassword">Jelszó</label>
              <input type="password" class="form-control mb-4" placeholder="" required=""
                formControlName="password">
            </form>
        <div class="row">
          <div class="col-6">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="logout()" [disabled]="reloginInProgress">Kilépés</button>
          </div>
          <div class="col-6 text-right">
            <button type="button" class="btn btn-success" (click)="relogin()" [disabled]="reloginInProgress">
                <i *ngIf="reloginInProgress" class="fa fa-spinner fa-spin"></i>
              Bejelentkezés
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
