<div class="error" [ngClass]="{'showResult': error == true}">
    <div class="closeResult" (click)="closeError()">x</div>
    <div class="errorContent">
        <span>{{ errorDescription }}</span>
    </div>
</div>


<div class="calendar-list-container">
    <button #showBookingModal data-toggle="modal" data-target="#idopontkezelo" data-backdrop="static"
        data-keyboard="false" style="display:none;"></button>
    <!-- <div *ngIf="!calendarLoading && calendarListDataAll.length == 0 && !availServiceEmpty">A megadott napra nincs
      bejegyzés</div> -->
    <div *ngIf="!calendarLoading">
        <div *ngIf="showSettings" class="meter">
            <span style="width:100%;">
          <span class="progress" [style.animation-duration.s]="calendarRefreshTimer/ 1000"
            *ngIf="calendarLoaded"></span>
            <span class="progress" *ngIf="!calendarLoaded"></span>
            </span>
        </div>
        <div>
            <div class="mc-col-1" *ngIf="showSettings">
                <div class="calendar_items one-column height-2">
                    <button class="col-sm-12 btn btn-primary rounded-0 d-flex w-100" *ngIf="showSettings" (click)="showSettings=false">
                    <fa-icon class="mx-auto my-auto" [icon]="faCalendarAlt"></fa-icon>
                  </button>
                </div>

            </div>

            <div *ngIf="!showSettings" class="mc-col-1">
                <div class="calendar_items one-column height-2">
                    <button class="col-sm-12 btn btn-primary rounded-0 d-flex w-100" *ngIf="!showSettings" (click)="showSettings=true">
                  <fa-icon class="mx-auto my-auto" [icon]="faCog"></fa-icon>
                </button>

                </div>
                <ng-container *ngIf="!showSettings">
                    <div *ngFor="let timeItem of calendarTimeAxis; let counter=index" class="calendar_items one-column height-12">{{timeItem}}</div>
                </ng-container>

            </div>
            <!-- [ngStyle]="{'height': 'calc(100% /' + week[z].cells.length + ')'}"  -->
            <div *ngIf="!showSettings  && emptyCalendarMessage === ''" [ngStyle]="{'width': (100+ (columns * ((col_size*50)+3))) + 'px'}">

                <div *ngIf="additionalSlots > 0" class="height-{{ additionalSlots }} empty-item row" [ngClass]="{'two-columns': twoColumns, 'one-column': !twoColumns}">
                </div>
                <div *ngIf="currentTimeMarkerPosition >= 0 && calendarListDataAll.length > 0 " [style.top.px]="currentTimeMarkerPosition" #currentTimeMarker class="currentTimeMarker"> </div>

                <div style="position: absolute;" [style.top.px]="currentScrollPosition" #currentScrollPos class="scrollPos"></div>


                <div>
                    <div class="mc-col-{{col_size}}" style="margin-left: 3px;" *ngFor="let calendarListDataColumn of calendarListDataArray; let i = index">
                        <!-- Oszlop fejlec, munkahely es szolgaltatas neve -->
                        <div class="calendar_items one-column height-2 header-text" style="display: table; width: 100%;">
                            <p class="service-header">
                                {{calendarListDataColumn.header?.etName}}
                                <small>
                                  {{calendarListDataColumn.header?.spName}}
                              </small>
                            </p>

                        </div>

                        <div *ngFor="let calendarEntryRow of calendarListDataColumn.items; let h = index" class="r-{{h}}">

                            <div *ngFor="let calendarEntry of calendarEntryRow; let i = index" [ngClass]="{'calendar_item_right_space': calendarListDataColumn.itemCount[h] < 12}" class="calendar_item_container col-md-{{calendarListDataColumn.itemCount[h]}}">

                                <!-- *ngIf="calendarEntry['calendarStatus'] != 'F'" -->
                                <div *ngIf="calendarEntry?.calendarStatus !== '_' && calendarEntry?.calendarStatus !== 'T' && calendarEntry?.eventTypeFk != 12720854" class="calendar_items height-{{ calendarEntry?.slotLength }} col-md-12" [ngClass]="{'two-columns': twoColumns, 'one-column': !twoColumns,
                                            'appointment_arrived': calendarEntry?.calendarStatus == 'A',
                                            'appointment_discarded': calendarEntry?.calendarStatus == 'D',
                                            'free-appointment': calendarEntry?.calendarStatus == 'F',
                                            'appointment-S': calendarEntry?.calendarStatus == 'S',
                                            'appointment-CO': calendarEntry?.calendarStatus == 'CO',
                                            'appointment-DD': calendarEntry?.calendarStatus == 'DD',
                                            'appointment-DH': calendarEntry?.calendarStatus == 'DH',
                                            'appointment-DN': calendarEntry?.calendarStatus == 'DN'
                                            }"
                                            (click)="setAppointmentData(calendarEntry, calendarListDataColumn.header, 'bookAppointment')">

                                    <div class="row_emulator">

                                        <div *ngIf="calendarEntry?.calendarStatus != 'F' && calendarEntry?.calendarStatus != '_'" [ngClass]="{'cal-detail-dispatcher': Brand == 'MEDCSEPP'}">
                                            <!-- <span>{{ calendarEntry['calendarStatus']}}</span> -->
                                            <!-- <small>{{ calendarEntry['displayData'] }}, Naplószám: {{ calendarEntry['calendarFk']}}</small> -->
                                            <small>{{ calendarEntry?.displayData }}</small>


                                        </div>
                                        <!-- <button (click)="updateCalendarEntryStatus(0)">T</button> -->
                                        <!-- calendarEntry['calendarStatus'] === 'DN' -->


                                        <div *ngIf="calendarEntry?.calendarStatus == 'F' && calendarEntry?.eventTypeFk != '12234835'  && calendarEntry?.eventTypeFk != '52342809'" class="cal-time-col small-lineheight">
                                            <small>{{ calendarEntry['dateFrom'] | date:'H:mm' }}-{{ calendarEntry['dateTo'] | date:'H:mm'}}</small>
                                        </div>

                                        <div *ngIf="calendarEntry?.calendarStatus == 'F'" class="cal-detail-col"></div>
                                        <div *ngIf="calendarEntry?.calendarStatus == 'F'" class="cal-buttons-col small-padding" [ngClass]="{'col-buttons-dispatcher': Brand == 'MEDCSEPP'}">

                                            <button *ngIf="!calendarEntry['registeredPatient'] && Brand === 'UDMED'" type="button" class="btn btn-danger mr-1 mb-xs-1" title="Páciens regisztrálása" data-toggle="modal" data-target="#paciensreg" (click)="setPatientData(calendarEntry, 'registerPatient')">

                                            </button>

                                        </div>

                                    </div>

                                </div>

                                <div *ngIf="calendarEntry?.calendarStatus === '_' || calendarEntry?.calendarStatus === 'T' && calendarEntry.eventTypeFk != 12720854" class="calendar_items height-{{ calendarEntry['slotLength'] }} col-md-12" [ngClass]="{'two-columns': twoColumns, 'one-column': !twoColumns}">

                                    <div class="row_emulator">

                                        <div *ngIf="calendarEntry?.calendarStatus != 'F' && calendarEntry?.calendarStatus != '_'" [ngClass]="{'cal-detail-dispatcher': Brand == 'MEDCSEPP'}">
                                            <!-- <span>{{ calendarEntry['calendarStatus']}}</span> -->
                                            <!-- <small>{{ calendarEntry['displayData'] }}, Naplószám: {{ calendarEntry['calendarFk']}}</small> -->
                                            <small>{{ calendarEntry['displayData'] }}</small>


                                        </div>
                                        <!-- <button (click)="updateCalendarEntryStatus(0)">T</button> -->
                                        <!-- calendarEntry['calendarStatus'] === 'DN' -->


                                        <div *ngIf="calendarEntry?.calendarStatus == 'F' && calendarEntry['eventTypeFk'] != '12234835'  && calendarEntry['eventTypeFk'] != '52342809'" class="cal-time-col small-lineheight">
                                            <small>{{ calendarEntry['dateFrom'] | date:'H:mm' }}-{{ calendarEntry['dateTo'] | date:'H:mm'}}</small>
                                        </div>

                                        <div *ngIf="calendarEntry?.calendarStatus == 'F'" class="cal-detail-col"></div>
                                        <div *ngIf="calendarEntry?.calendarStatus == 'F'" class="cal-buttons-col small-padding" [ngClass]="{'col-buttons-dispatcher': Brand == 'MEDCSEPP'}">

                                            <button *ngIf="!calendarEntry['registeredPatient'] && Brand === 'UDMED'" type="button" class="btn btn-danger mr-1 mb-xs-1" title="Páciens regisztrálása" data-toggle="modal" data-target="#paciensreg" (click)="setPatientData(calendarEntry, 'registerPatient')">

                                            </button>

                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row mt-3" *ngIf="!showSettings && emptyCalendarMessage !== ''">
                <div class="w-100">
                    <h3>{{emptyCalendarMessage}}</h3>
                </div>

            </div>
            <!-- <div class="col-md-3 other-patients" *ngIf="Brand == 'MEDCSEPP'">
          <app-calendar-lab-other></app-calendar-lab-other>
        </div> -->
        </div>

        <div class="row mt-3" *ngIf="showSettings">
            <div class="w-100">
                <div class="w-100">
                    <!-- <ng-select class="col-sm-8" style="float: left;" [closeOnSelect]="false" [multiple]="true" [items]="availServic" [formControl]="service" bindLabel="displayName" placeholder="Válassza ki a szolgáltatásokat"></ng-select> -->
                    <ng-select class="w-25" [searchable]="true" placeholder="Válasszon szolgáltatást..." [formControl]="eventType" [items]="etListFiltered" bindLabel="etName" (change)="filterSp($event)" [clearable]="true" loadingText="Betöltés..." notFoundText="Nincs találat">
                        <!-- [loading]="availServiceLoading" -->
                    </ng-select>
                    <ng-select class="w-25 mt-3" [searchable]="true" placeholder="Válasszon szolgáltatási pontot..." [formControl]="servicePoint" [items]="spListFiltered" bindLabel="spName" (change)="filterEt($event)" [clearable]="true" loadingText="Betöltés..." notFoundText="Nincs találat">
                        <!-- [loading]="availServiceLoading" -->
                    </ng-select>
                    <button class="w-25 btn btn-primary mt-3" style="float: left;" (click)="setServices()">Szolgáltatás beállítása</button>
                </div>
            </div>

        </div>
    </div>

    <div *ngIf="calendarLoading" class="calendarLoading">
        <div class="osahanloading"></div>
        <div class="osahanloadingcontent">Loading calendar...</div>
    </div>
