<div class="env" [ngClass]="{'udmed-env': authService.getBrand() == 'UDMED'}">
    <span>v{{ environment.version }} </span>
    <span *ngIf="apiUrl == 'https://ekrest.nauticom.hu' ">
    {{ apiUrl }}
  </span>
</div>
<div class="sz2020" *ngIf="Brand == 'DECISIO' ">
    <img class="sz2020_blokk" src="/assets/infoblokk_kedv_final_RGB_ERFA.png" />
</div>
<div class="container">
    <div class="loginForm" [ngClass]="{'udmed': authService.getBrand() === 'UDMED'}">
        <div>
            <div class="logo" *ngIf="Brand == 'NAUCARE'"><img src="/assets/nauCare_logo_plain.png" width="60"></div>
            <div class="logo" *ngIf="Brand == 'MEDCSEPP'"><img src="/assets/medcsepp_logo_small.png" width="60"></div>
            <div class="logo-decisio" *ngIf="Brand == 'DECISIO'"><img src="/assets/decisio_logo_small.png" width="60"></div>
            <div class="logo-title" *ngIf="Brand == 'NAUCARE'">
                <h3>NauCare</h3>
            </div>
            <div class="logo-title-medcsepp" *ngIf="Brand == 'MEDCSEPP'">
                <h3>medcsepp</h3>
            </div>
            <div class="logo-title-decisio" *ngIf="Brand == 'DECISIO'">
                <h3>DECISIO</h3>
            </div>
            <div class="logo-udmed" *ngIf="authService.getBrand() === 'UDMED'">
                <img src="/assets/de_eszolg_logo.svg" height="50" class="d-inline-block align-top mt-2" alt="">
            </div>
            <!-- <div class="site" *ngIf="authService.getBrand() === 'UDMED'">
            <h3 class="site-name">Debreceni Egyetem </h3>
            <h2 class="site-name-second">UD Health Services</h2>
          </div> -->
        </div>
        <div class="clear"></div>
        <form class="form-signin" [formGroup]="loginForm" (ngSubmit)="onLogin()">

            <label for="inputEmail">Felhasználónév</label>
            <input type="email" id="inputEmail" class="form-control mb-2" placeholder="" required="" autofocus="" formControlName="email">
            <label for="inputPassword">Jelszó</label>
            <input type="password" id="inputPassword" class="form-control mb-4" placeholder="" required="" formControlName="password">
            <!-- <div class="checkbox">
         <label>
          <input type="checkbox" value="remember-me"> Remember me
        </label>
      </div> -->
            <button class="btn btn-lg btn-primary btn-block" [ngClass]="{'brightgreen': authService.getBrand() === 'UDMED'}" type="submit" [disabled]="!loginForm.valid">Bejelentkezés</button>
        </form>
        <div *ngIf="error" class="error">{{ errorMessage }}</div>
    </div>
    <div *ngIf="loggingIn" class="animationload">
        <div class="osahanloading"></div>
        <div class="osahanloadingcontent">Logging in...</div>
    </div>
    <div *ngIf="rssMessages && Brand !== 'NauLight'" class="rssMessage">
        <span class="rssMessageHeader">EESZT hírcsatorna</span>
        <div *ngFor="let rssMessage of rssMessages" style="width: 90%;">
            <hr>
            <span>
        {{rssMessage}}
      </span>
        </div>
    </div>
    <!-- <a (click)="openNewPage()">open</a>  -->
</div>