<div class="container">
    <div class="service-select">
        <div class="col-12">
            <div class="row mb-2" [formGroup]="serviceSelect">
                <div class="col-sm-3">
                    <ng-select class="" [searchable]="true" formControlName="instName" [items]="instNameElements" bindLabel="instName" (clear)="clearEt(); clearSp(); clearDr();" (change)="clearEt(); clearSp(); filterByInstName()" loadingText="Betöltés..." placeholder="válasszon intézményt!"
                        notFoundText="Nincs találat">
                    </ng-select>
                </div>
                <div class="col-sm-3">
                    <ng-select class="" [searchable]="true" formControlName="etName" [items]="etElements" bindLabel="etName" (clear)="clearDr();" (change)="filterServices(); onSelectedServiceChange($event); selectDoctor($event)" loadingText="Betöltés..." placeholder="válasszon szolgáltatást!"
                        notFoundText="Nincs találat">
                    </ng-select>
                </div>
                <div class="col-sm-3">
                    <ng-select class="" [searchable]="true" formControlName="spName" [items]="spElements" bindLabel="spName" (clear)="clearDr();" (change)=" filterEt(); onSelectedServiceChange($event); selectDoctor($event);" loadingText="Betöltés..." placeholder="válasszon szolgáltatót!"
                        notFoundText="Nincs találat">
                    </ng-select>
                </div>
                <div class="col-sm-3">
                    <ng-select class="" [searchable]="true" formControlName="doctor" [items]="doctors" bindLabel="name" bindValue="id" (change)="onSelectedServiceChange($event)" loadingText="Betöltés..." placeholder="válasszon orvost!" notFoundText="Nincs találat">
                    </ng-select>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <mat-progress-bar *ngIf="isScheduleLoading" class="mb-3" mode="determinate" [value]="shceduleProgressPercentage"></mat-progress-bar>
    <div class="row">
        <div class="col-sm-6" [formGroup]="patientDetails">
            <div class="row form-group">



                <label for="dateofbirth" class="col-sm-4 control-label">Születési idő<span
            class="mandatory">*</span></label>
                <div class="col-sm-8 form-item">

                    <div class="input-group mr-1">
                        <input class="form-control datepicker_mobile" placeholder="yyyy-mm-dd" name="dp" formControlName="dateofbirth" ngbDatepicker [ngClass]="{'invalid-form': patientDetails.controls.dateofbirth.touched && patientDetails.controls.dateofbirth.invalid}" #d="ngbDatepicker"
                            [maxDate]=todayDate [minDate]="{year: 1900, month: 1, day: 1}" [ngClass]="{'date-invalid': dateInvalid === true}">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                        </div>
                    </div>

                    <!--  <input type="date" id="dateofbirth" placeholder="Születési idő" class="form-control"
          [formControl]="dateofbirth" max="{{ birthDateMax }}" (change)="changeIn4T()"> -->

                    <div class="formError" *ngIf="dateofbirth.invalid && dateofbirth.touched">Nem megfelelő születési idő
                    </div>
                </div>


                <label for="ssn2t" class="col-sm-4 control-label">TAJ szám</label>
                <div class="col-sm-8 form-item">
                    <input type="text" id="ssn2t" placeholder="TAJ szám" class="form-control" [formControl]="ssn2t">
                    <div class="formError" *ngIf="ssn2t.invalid && ssn2t.touched">Nem megfelelő név (minimum 4 karakter)
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <button type="button" class="btn btn-primary" (click)="check2T($event)" [disabled]="dateofbirth.invalid || ssn2t.invalid">
            keresés</button>
                </div>


                <label for="birthname" class="col-sm-4 control-label">Születési név<span
            class="mandatory">*</span></label>
                <div class="col-sm-8 form-item">
                    <input type="text" id="birthname" [ngClass]="{'invalid-form': patientDetails.controls.birthname.touched && patientDetails.controls.birthname.invalid}" placeholder="Születési név" class="form-control" appTitleCase [formControl]="birthname">
                </div>


                <label for="birthplace" class="col-sm-4 control-label">Születési hely<span
            class="mandatory">*</span></label>
                <div class="col-sm-8 form-item">
                    <input type="text" id="birthplace" [ngClass]="{'invalid-form': patientDetails.controls.birthplace.touched && patientDetails.controls.birthplace.invalid}" placeholder="Születési hely" class="form-control" [formControl]="birthplace">
                    <div class="formError" *ngIf="birthplace.invalid && birthplace.touched">Nem megfelelő születési hely (minimum 2 karakter)</div>
                </div>


                <label for="mothersname" class="col-sm-4 control-label">Anyja neve<span
            class="mandatory">*</span></label>
                <div class="col-sm-8 form-item">
                    <input type="text" id="mothersname" [ngClass]="{'invalid-form': patientDetails.controls.mothersname.touched && patientDetails.controls.mothersname.invalid}" placeholder="Anyja neve" class="form-control" [formControl]="mothersname" appTitleCase>
                    <div class="formError" *ngIf="mothersname.invalid && mothersname.touched">Nem megfelelő név (minimum 4 karakter)
                    </div>
                </div>

                <!-- <div class="col-12 mb-2">
          <button type="button" class="btn btn-primary" (click)="check4T($event)"
            [disabled]="!birthname.valid || !birthplace.valid || !dateofbirth || !mothersname.valid">
            Globális keresés 4T</button>
        </div> -->
                <div class="col-sm-12 mb-2"><strong>{{ check4TResultMessage }}</strong></div>


                <label for="aliasname" class="col-sm-4 control-label">Név<span class="mandatory">*</span></label>
                <div class="col-sm-8 form-item">
                    <input type="text" id="aliasname" placeholder="Név" class="form-control" [ngClass]="{'invalid-form': patientDetails.controls.aliasname.touched && patientDetails.controls.aliasname.invalid}" [formControl]="aliasname" appTitleCase>
                    <div class="formError" *ngIf="aliasname.invalid && aliasname.touched">Nem megfelelő név (minimum 4 karakter)</div>
                </div>

                <label for="email" class="col-sm-4 control-label">Email<span class="mandatory">*</span></label>
                <div class="col-sm-8 form-item">
                    <input type="text" id="email" placeholder="Email" class="form-control" [formControl]="email">
                    <div class="formError" *ngIf="email.invalid && email.touched">Nem megfelelő email cím</div>
                </div>


                <div class="col-md-12">
                    <h3>Lakcím</h3>
                </div>

                <label for="country" class="col-sm-4 control-label">Ország</label>
                <div class="col-sm-8 form-item">
                    <select class="form-control" id="country" [formControl]="country">
            <option ngValue="HUN">Magyarország</option>
          </select>
                    <div class="formError" *ngIf="country.invalid && country.touched">Nem megfelelő orszag (minimum 3 karakter)
                    </div>
                </div>

                <label for="zip" class="col-sm-4 control-label">Irányítószám</label>
                <div class="col-sm-8 form-item">
                    <input type="text" id="zip" placeholder="Irányítószám" class="form-control" [formControl]="zip">
                    <div class="formError" *ngIf="zip.invalid && zip.touched">Nem megfelelő irányítószám (minimum 3 karakter)
                    </div>
                </div>


                <label for="city" class="col-sm-4 control-label">Város</label>
                <div class="col-sm-8 form-item">
                    <input type="text" id="city" placeholder="Város" class="form-control" [formControl]="city">
                    <div class="formError" *ngIf="city.invalid && city.touched">Nem megfelelő város (minimum 2 karakter)
                    </div>
                </div>

                <label for="address" class="col-sm-4 control-label">Utca, házszám</label>
                <div class="col-sm-8 form-item">
                    <input type="text" id="address" placeholder="Utca, házszám" class="form-control" appUpperCaseFirst [formControl]="address">
                    <div class="formError" *ngIf="address.invalid && address.touched">Nem megfelelő utca, házszám (minimum 4 karakter)</div>
                </div>
                <div class="col-md-12">
                    <h3>Beutaló információk</h3>
                </div>
                <label for="city" class="col-sm-4 control-label">iránydiagnózisa *</label>
                <div class="col-sm-8 form-item">
                    <input type="text" id="city" placeholder="diagnózis" class="form-control" [formControl]="preliminaryDiagnosis">
                    <div class="formError" *ngIf="preliminaryDiagnosis.invalid && preliminaryDiagnosis.touched">Nem megfelelő iránydiagnózis</div>
                </div>
                <label for="city" class="col-sm-4 control-label">jelen panasza *</label>
                <div class="col-sm-8 form-item">
                    <textarea type="text" id="city" placeholder="panasz" class="form-control" [formControl]="patientComplaint"></textarea>
                    <div class="formError" *ngIf="patientComplaint.invalid && patientComplaint.touched">Nem megfelelő panasz </div>
                </div>
                <label for="city" class="col-sm-4 control-label"> megjegyzés *</label>
                <div class="col-sm-8 form-item">
                    <textarea type="text" id="city" placeholder="megjegyzés" class="form-control" [formControl]="referralComment"></textarea>
                    <div class="formError" *ngIf="referralComment.invalid && referralComment.touched">Nem megfelelő megjegyzés</div>
                </div>
                <!-- <div>
          <button class="btn btn-success" (click)="saveAdmission()" [disabled]="!check4TStatus">
            beutaló rögzítése
          </button>
        </div> -->
            </div>
        </div>
        <div class="col-sm-6">
            <div class="row mb-2 d-flex">
                <ngbd-datepicker class="ml-auto mr-auto" [displayMonths]="2" [selectedDateRange]="selectedDateRange"></ngbd-datepicker>
            </div>
            <!-- <div class="row d-flex">
        <button class="search-button mb-2" [disabled]="!selectedDateFrom" [ngClass]="{'enabled-search-button': selectedDateFrom, 'disabled-search-button': !selectedDateFrom}" (click)="loadCalendar()" class="search-button ml-auto mr-auto"><fa-icon style="size: 20px;" [icon]="faSearch"></fa-icon></button>
      </div> -->
            <div *ngIf="!isScheduleLoading">
                <div *ngFor="let calendarEntry of calendarListDataAll; let i = index">
                    <div *ngIf="calendarEntry['calendarStatus'] === 'F'" class="calendarListElement row mb-1 rounded d-flex justify-content-between ">
                        <div class="mt-auto mb-auto"><b>{{calendarEntry['dateFrom'] | date:'yyyy-MM-dd H:mm'}} - {{calendarEntry['dateTo'] | date:'H:mm'}} | {{calendarEntry['servicePointName']}}</b></div>
                        <button class="btn btn-primary" [disabled]="patientDetails.invalid ||
                                !patientDetails.controls.birthname.value ||
                                !patientDetails.controls.birthplace.value ||
                                !patientDetails.controls.mothersname.value" (click)="isPatientValid(calendarEntry);">
              beutalás
            </button>
                    </div>
                </div>
            </div>
            <div *ngIf="isScheduleLoading" class="loadingSpinner">
                <i class="fa fa-spinner fa-spin"></i>
            </div>
        </div>
    </div>
</div>