<div class="modal" id="medcsepp-worklist" tabindex="-1" role="dialog" aria-labelledby="settings" aria-hidden="true">
  <div class="modal-dialog modal-lg " role="document">

    <div [ngClass]="{'hidden': !closeConfirmationDialogueVisible}" id="quitConfirmation" class="medCseppWorklistQuitConfirmation">
      <div class="quitConfirmationContent">
        <h6>Az adatok megtartása a későbbi folytatáshoz?</h6>
        <div class="col-md-12 mt-4">
          <div class="row">
            <div class="col-md-4">
              <button type="button" class="btn btn-primary" (click)="hideCloseConfirmationDialogue()"
                data-dismiss="modal">Igen</button>
            </div>
            <div class="col-md-4">
              <button type="button" class="btn btn-danger" data-dismiss="modal"
                (click)="resetModalAndHideDialogue()">Nem</button>
            </div>
            <div class="col-md-4">
              <button type="button" class="btn btn-secondary" (click)="hideCloseConfirmationDialogue()">Mégsem</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div [ngClass]="{'hidden': !saveInProgress}" class="medCseppWorklistQuitConfirmation">
      <div class="quitConfirmationContent">
        Mentés folyamatban...
      </div>
    </div>

    <!-- <div [ngClass]="{'hidden': screenMessage == ''}" class="quitConfirmation">
      <div class="quitConfirmationContent">
        <div class="row">
          <div class="col-12 text-center mb-4">{{ screenMessage }}</div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <button type="button" class="btn btn-secondary" (click)="clearScreenMessage()">Ok</button>
          </div>
        </div>

      </div>
    </div> -->

    <div class="modal-content modal-fh">
      <div class="modal-header">

        <div id="saveSuccess" [ngClass]="{'showResult': saveResult == 0}">
          Beállítások sikeresen mentve!
        </div>
        <div id="saveError" [ngClass]="{'showResult': saveResult == -1}">
          <div class="closeResult" (click)="closeResult()">x</div>
          <div class="saveErrorContent">Hiba!
            <span>{{ saveErrorDescription }}</span>
          </div>
        </div>
        <h5 class="modal-title" id="exampleModalLongTitle">Laborvizsgálat kérések és szállítási lista</h5>
        <div class="col-md-6">
        </div>
        <button type="button" class="btn btn-secondary" (click)="resetModal()" data-dismiss="modal">X</button>
      </div>

      <div class="modal-body">
        <div class="container-fluid">

          <mat-form-field>
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Szűrés" #filterTable
              [formControl]="filterInput">
            <i mat-button matSuffix mat-icon-button class="fa fa-times"
              (click)="filterInput.reset(); applyFilter('')"></i>
          </mat-form-field>
          <button type="button" class="btn btn-primary" (click)="onPrintSampleList()" [disabled]="worklistLoading">
            <i *ngIf="worklistLoading" class="fa fa-spinner fa-spin"></i>Szállítási lista generálása</button>

          <mat-table [dataSource]="dataSource" matSort id="print-section">
            <ng-container matColumnDef="date">
              <mat-header-cell *matHeaderCellDef> Dátum </mat-header-cell>
              <mat-cell *matCellDef="let element"><span class="ellipsis">{{ element.request.reqDate }}</span> </mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef> Név </mat-header-cell>
              <mat-cell *matCellDef="let element"><span class="ellipsis"> {{element.request.patName}}</span> </mat-cell>
            </ng-container>
            <ng-container matColumnDef="ssn">
              <mat-header-cell *matHeaderCellDef mat-sort-header> TAJ </mat-header-cell>
              <mat-cell *matCellDef="let element"><span class="ellipsis"> {{element.request.patSsn}} </span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="bdate">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Szül.dátum </mat-header-cell>
              <mat-cell *matCellDef="let element"><span class="ellipsis"> {{element.request.patBDate}} </span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Státusz </mat-header-cell>
              <mat-cell *matCellDef="let element"><span class="ellipsis"> {{element.request.reqStat}} </span></mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>


        </div>
      </div>
    </div>
