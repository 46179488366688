import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input, AfterViewInit } from '@angular/core';
import { SharedDataService } from '../services/shared-data.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SharedApiService } from '../services/shared-api.service';
import { CalendarComponent } from '../calendar/calendar.component';


@Component({
  selector: 'app-lab-sample',
  templateUrl: './lab-sample.component.html',
  styleUrls: ['./lab-sample.component.css']
})

export class LabSampleComponent implements OnInit, OnDestroy, AfterViewInit {

  barcodeValue;

  apiUrl = environment.apiUrl;

  validLab = false;

  focused = null;

  labExamListSubscription: Subscription;
  labExamList = [];
  labExamListLoading = true;
  selectedLabExams = [];
  labExamListExtExamContainerMap = [];
  labExamIdChangeSubscription: Subscription;
  labExamNameChangeSubscription: Subscription;
  ssnSearchChangeSubscription: Subscription;
  lantszChangeSubscription: Subscription;

  getSpSubscription: Subscription;
  getDoctorToSpSubscription: Subscription;
  tajSearchSubscription: Subscription;

  labOrderSelectSubscription: Subscription;

  newantsz = false;
  newInstitute = false;
  existingDoctor = false;

  patient = [];

  newPatient = false;
  userData = [];
  calendarDetail = [];

  operationInProgress = false;
  operationDescription = '';

  labExamListExtExamCodeMap = [];

  labOrderListData = null;

  labOrderList = [];

  labOrderSelect = new FormControl();
  labOrderGroup = new FormGroup({
    labOrderSelect: this.labOrderSelect
  });

  activeTab = 0;
  saveInProgress = false;
  tajSearchLoading = false;

  lpecsetszamChangeSubscription: Subscription;

  terkatItems = [
    { name: 'OEP finanszírozott', code: '1' },
    { name: 'Térítéses', code: '4' }
  ];




  containerList = [];
  existingContainerList = [];
  containerExamList = [];
  calendarData = [];

  samples = new FormGroup({});

  lintezmenyLista = [];
  doctorLoading = false;
  spLoading = false;

  countryList = environment.countryList;

  patientDataValue = null;
  isLoading = false;
  emptyLabOrderList = false;

  saveResult = null;
  saveErrorDescription = '';

  @Input() set patientData(data) {
    // console.log('laborder Input data', data);

    if (data !== null && data['calendarId']) {
      data['calendarFk'] = data['calendarId'];
      this.calendarData = data;
    }

    if (data !== null && data['calendarFk']) {
      this.reset();
      // console.log('data', data);

      this.patientDataValue = data;
      this.isLoading = true;
      // console.log('lab order patientDataValue:', this.patientDataValue);

      this.getLaborderList(data['calendarFk']).subscribe((responseData) => {
        this.isLoading = false;
        this.emptyLabOrderList = false;
        // console.log ('getLaborderList:', responseData);
        if (responseData['code'] === 1 && responseData['labOrderList'] && responseData['labOrderList']['labOrderList']) {
          // console.log('+++++++++++++++++:');
          // console.log('Previous lab order data:', responseData);
          responseData['labOrderList']['labOrderList'].forEach(element => {
            element.visibleTitle = element.rn +'. ' + element.nauRequestId;
          });
          this.labOrderList = responseData['labOrderList']['labOrderList'];
          if (this.labOrderList && this.labOrderList.length === 1) {
            this.labOrderSelect.setValue(this.labOrderList[0]);
          }
        } else {
          this.emptyLabOrderList = true;
        }
      });

      if (data['registeredPatient']) {

        if (this.ssnSearchChangeSubscription) {
          this.ssnSearchChangeSubscription.unsubscribe();
        }

        this.getCalendarDetail(data['calendarFk']).subscribe((responseData) => {
          this.isLoading = false;
          if (responseData['code'] === 1) {
            this.calendarDetail = responseData['data'];
            const event = { tapass: responseData['data']['participantTapass'] };

            this.sharedApiService.getUserSimple(responseData['data']['participantTapass']).subscribe(
              (userData: Response) => {
                if (userData['code'] === 1) {

                  userData['user']['street'] = userData['user']['street'] ? userData['user']['street'] : '';

                  userData['user']['street'] += (userData['user']['streetType'] ? ' ' + userData['user']['streetType'] : '') +
                    (userData['user']['houseNumber'] ? ' ' + userData['user']['houseNumber'] : '') +
                    (userData['user']['floor'] ? ' ' + userData['user']['floor'] : '') +
                    (userData['user']['door'] ? '/' + userData['user']['door'] : '');

                  // console.log('user', userData['user']);
                  // console.log('ssn', userData['user']['socialSecurityNumber']);

                  this.userData = userData['user'];
                } else {
                  // hiba
                }
              }
            );


          } else {
            // console.log('Error loading patient details calendarId: ' + data['calendarFk']);
            // console.log(responseData);
          }
        });
      } else {
        // alias-os foglalas
        if (data['participantDetail'] && data['participantDetail'].length > 8) {
          // check if alias contains TAJ
        } else {
          // console.log ('invalid TAJ 2');
        }
        // console.log('Lab order alias-os foglalas!');
      }
    }
  }


  constructor(
    public authService: AuthService,
    private http: HttpClient,
    private calendarComponent: CalendarComponent,
    private sharedDataService: SharedDataService,
    private sharedApiService: SharedApiService
  ) { }

  @ViewChild('labExamNameSearchField') labExamNameSearchField: ElementRef;
  @ViewChild('labExamIdSearchField') labExamIdSearchField: ElementRef;

  labExamId = new FormControl('');
  labExamName = new FormControl('');

  labExamSelect = new FormGroup({
    labExamId: this.labExamId,
    labExamName: this.labExamName
  });

  labExamIdSearch = null;
  labExamNameSearch = null;
  filteredLabExams = [];
  filteredLabExam = false;
  selectedLabExamItemList = [];

  today = {};

  ngAfterViewInit() {
    // this.barecodeScanner.start();
  }

  onValueChanges(result) {
    this.barcodeValue = result.codeResult.code;
    if (this.focused) {
      let obj = {};
      obj[this.focused] = result.codeResult.code;
      this.samples.patchValue(obj);
    }
  }

  onStarted() {
    console.log('camera started');
  }

  loadOrderDetails(labOrderData) {

    const lastLaborder = labOrderData;
    // const lastLaborder = 1;
    this.patient = labOrderData['patient'];
    this.samples = new FormGroup({});
    this.containerExamList = [];

    
    const containerList = [];
    const existingContainerList = [];
    this.labOrderListData = labOrderData;

    labOrderData['sampleData']['samples'].forEach(element => {
      if (!containerList[element['containerName'] + ' - ' + element['sampleName']]) {
        containerList[element['containerName'] + ' - ' + element['sampleName']] = [];
      }
      if (!existingContainerList[element['containerName'] + ' - ' + element['sampleName']]) {
        existingContainerList[element['containerName'] + ' - ' + element['sampleName']] = [];
        existingContainerList[element['containerName'] + ' - ' + element['sampleName']].push(element);
      }
      containerList[element['containerName'] + ' - ' + element['sampleName']].push(element);
      const fc = new FormControl('');
      this.samples.addControl(element.sampleId, fc);
    });


    this.existingContainerList = existingContainerList;
    this.containerList = containerList;
    // console.log('containerList', this.containerList);
    // console.log('existingContainerList', this.existingContainerList);
    // console.log ('containerList', JSON.stringify(this.containerList));

    if (labOrderData['exams'].length > 0) {
      this.validLab = true;
      labOrderData['exams'].forEach(element => {
        let containerType = this.labExamListExtExamContainerMap[element.id];
        if (!this.containerExamList[containerType]) {
          this.containerExamList[containerType] = [];
        }
        this.containerExamList[containerType].push(element);
      });
      // console.log('containerExamList', this.containerExamList);
    }
  }


  ngOnInit() {

    const todayDate = new Date();
    this.today = { year: todayDate.getFullYear(), month: (todayDate.getMonth() + 1), day: todayDate.getDate() };

    this.labExamListSubscription = this.sharedDataService.labExamListShared.subscribe(data => {
      this.labExamList = data['data'];
      this.labExamListLoading = data['isLoading'];
      // console.log('labExamList', this.labExamList);

      this.labExamListExtExamCodeMap = [];
      this.labExamList.forEach((row, rowIndex) => {
        row.data.forEach((item, itemIndex) => {
          if (item.extExamCode) {
            this.labExamListExtExamCodeMap[item.examId] = item.extExamCode;
            this.labExamListExtExamContainerMap[item.examId] = item['containerName'] + ' - ' + item.sampleName;
          }
        });
      });
      // console.log ('labExamListExtExamCodeMap:', this.labExamListExtExamCodeMap);
    });
    
    this.labOrderSelectSubscription = this.labOrderSelect.valueChanges.subscribe(value => {
      // console.log ('labOrderSelected:', value);
      this.loadOrderDetails(value);
    });

  }

  ngOnDestroy() {
    if (this.labOrderSelectSubscription) { this.labOrderSelectSubscription.unsubscribe(); }
    if (this.labExamListSubscription) { this.labExamListSubscription.unsubscribe(); }
  }

  isEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  resetModal() {

    // console.log('laborder form', this.labOrderDetails);
    const data = [];
    data['NGdestinationService'] = 'labSample';
    this.sharedDataService.setPatientData(data);
    this.activeTab = 0;
    this.labOrderList = [];
    this.emptyLabOrderList = false;
  }

  openHelp(anchor) {
    const newWindow = window.open('https://' + this.authService.getHostname() + '/kezikonyv/' + anchor);
  }



  switchTab(tabID) {
    if (!this.saveInProgress) {
      this.activeTab = tabID;
    }
  }

  sendLabOrder(labData) {

    const params = labData;

    params.messageHeader = {
      currentTapass: this.authService.getUserData()['ekCardNo'],
      currentRole: this.sharedDataService.getSelectedRole()['roleCode'],
      currentServicePointId: this.sharedDataService.getSelectedRole()['spId'],
      locale: 'hu',
      deviceId: this.authService.getUUID()
    };

    params.senderDoctor = params.sender.doctor;
    params.senderSp = params.sender.sp;

    delete params.sender;

    params.status = 'RQ';
    params.calendarId = this.calendarData['calendarFk'];

    // const params = {
    //   messageHeader: {
    //     currentTapass: this.authService.getUserData()['ekCardNo'],
    //     currentRole: this.sharedDataService.getSelectedRole()['roleCode'],
    //     currentServicePointId: this.sharedDataService.getSelectedRole()['spId'],
    //     locale: 'hu',
    //     deviceId: this.authService.getUUID()
    //   }
    // ,
    // laborServiceFk: this.sharedDataService.laborSvId,    // Vizsgálatot végző labor munkahely id.
    // calendarId: this.patientDataValue['calendarFk'], // Foglalás CalID-ja
    // remark: this.lcomment.value, // Megjegyzés a diszpécser felületről
    // sendDate: this.sharedDataService.NgbDateToDateString(this.lbeutaloKelte.value), // Beutaló kelte diszpészer felületről
    // status: 'RQ', // Vizsgálatkérés státusza. Értékkészlet: RQ (Piszkozat), PS (Küldhető), SD (Elküldve), AC (Elfogadva), RJ (Elutasítva), PR (Folyamatban), RS (R. teljesített), VD (Teljesített), X (Törölt)
    // request: {
    //   msgId: null,
    //   payCat: this.lterkat.value, // Térítési kategória kód Felületről
    //   bnoList: 'A0010', // BNO lista felületről
    //   attendCode: this.lnaploszam.value,  // Naplószám a diszpécser felületről
    //   reqId: null
    // },
    // patient: {
    //   id: this.calendarDetail['participantId'],
    //   tapass: this.calendarDetail['participantTapass'],
    //   name: this.lbirthname.value,
    //   birthDate: this.sharedDataService.NgbDateToDateString(this.ldateofbirth.value), // *
    //   birthPlace: this.lbirthplace.value, // *
    //   birthName: this.lbirthname.value, // *
    //   motherName: this.lmothersname.value, // *
    //   gender: this.lgender.value, // *
    //   bloodType: '',  // opcionális
    //   ssn: this.lssnSearch.value, // *
    //   ssnType: this.lsst.value, // *
    //   natio: this.lnationality.value, // *
    //   country: this.lcountry.value, // *
    //   zip: this.lzip.value, // *
    //   city: this.lcity.value, // *
    //   address: this.laddress.value // *
    // },
    // senderDoctor: {
    //   id: null,
    //   externalCode: this.lpecsetszam.value, // Pecsétszám
    //   shortName: this.lorvos.value,  // Orvos név
    //   longName: this.lorvos.value  // Orvos név
    // },
    // senderSp: {
    //   id: null,
    //   externalCode: this.lantszselect.value['spCode'] === -1 ? this.lantsz.value : this.lantszselect.value['spCode'],
    //   // Beküldő intézmény kódja
    //   shortName: this.lantszselect.value['spCode'] === -1 ? this.lintezmeny.value : this.lantszselect.value['spName'],
    //   // Beküldő intézmény neve
    //   longName: this.lantszselect.value['spCode'] === -1 ? this.lintezmeny.value : this.lantszselect.value['spName']
    // },
    // examList: examList.examList,  // labExamlist-ből kiválasztott vizsgálatok examId listája
    // sampleData: examList.sampleData
    // };

    // console.log (params);
    // return null;

    this.operationInProgress = true;
    this.operationDescription = 'Mentés folyamatban';
    this.saveLabOrder(params).subscribe(
      (data: Response) => {
        // console.log('SaveLabOrder success', data);
        this.saveResult = 0;
        setTimeout(() => { this.saveResult = null; }, 3000);
        if (data['code'] >= 0) {
          // console.log('saveLabOrder result', data);  // {code: 1, message: "OK", nauRequestId: 13141167}
          const params2 = {
            messageHeader: {
              currentTapass: this.authService.getUserData()['ekCardNo'],
              currentRole: this.sharedDataService.getSelectedRole()['roleCode'],
              currentServicePointId: this.sharedDataService.getSelectedRole()['spId'],
              locale: 'hu',
              deviceId: this.authService.getUUID()
            },
            status: 'SD',
            calendarId: this.calendarData['calendarFk'],
            nauRequestId: data['nauRequestId']
          };

          this.saveLabOrder(params2).subscribe(
            (data2: Response) => {
              this.operationInProgress = false;
              this.operationDescription = '';
              this.saveResult = 0;
              setTimeout(() => { this.saveResult = null; }, 3000);
              if (data2['code'] >= 0) {
                // console.log('saveLabOrder finalize result', data2);  // {code: 1, message: "OK", nauRequestId: 13141167}
                // console.log('update disp cal entry patient', this.patientDataValue);
                // this.sharedDataService.updateDispatcherCalEntry(this.patientDataValue, 'DL');
                this.sharedDataService.updateDispatcherCalEntry(this.patientDataValue, 'DD');
                // this.sharedDataService.updateCalendarEntry(this.patientDataValue['calendarFk'], 'DD');
              } else {
                console.error('SaveLabOrder finalize failed', data);
                this.operationInProgress = false;
                this.operationDescription = '';

              }
              this.reset();
            });

          // this.sharedDataService.updateCalendarEntry(this.patientDataValue['calendarFk'], 'DD');
        } else {
          console.error('SaveLabOrder save samples failed', data);
          this.operationInProgress = false;
          this.operationDescription = '';

        }
      });

    // this.calStatusSave(params).subscribe(
    //   (data: Response) => {
    //     if (data['code'] === 1) {
    //     // console.log('lab update cal success', data);
    //       this.sharedDataService.updateCalendarEntry(this.patientDataValue['calendarFk'], 'DD');
    //     } else {
    //       console.error('lab update cal failed', data);
    //     }
    //   });

    // console.log('params', params);

  }

  getRandomBarcode(start, max) {
    return ('' + (start + (Math.floor(Math.random() * Math.floor(max)))));
  }


  logDetails() {
    console.log('selectedLabExamItemList', this.selectedLabExamItemList);
  }

  updateCalendar(params: {}) {
    return this.http.post(this.apiUrl + '/rest/neu/calendarlist/updatecalendar', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }

  reset() {
    this.validLab = false;
    this.patient = [];
    this.userData = null;
    this.containerList = null;
    this.samples = new FormGroup({});
    this.patientDataValue = null;
    this.emptyLabOrderList = false;
    this.labOrderList = [];
    this.containerExamList = [];

  }


  calStatusSave(params: {}) {

    return this.http.post(this.sharedDataService.callerSystemAPIURL + 'updatecalendar', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }


  saveLabOrder(params: {}) {
    return this.http.post(this.apiUrl + '/rest/neu/laborder/savelaborder', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getCalendarDetail(calendarId: string) {

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calendarId: calendarId
    };

    return this.http.post<any[]>(this.apiUrl + '/rest/neu/calendarlist/getcalendardetail', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }


  getLaborderList(calendarId: string) {

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calendarId: calendarId
    };

    return this.http.post<any[]>(this.apiUrl + '/rest/neu/laborder/getlaborderlist', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  test(calendarId) {
    this.sharedDataService.updateCalendarEntry(calendarId, 'DD');

  }



  logForm() {
    console.log('form', this.samples);
  }

  onFocus(id) {
    // console.log('focused', id);
    this.focused = id;
  }

  onFocusOut(id) {
    // console.log('focused', id);
    this.focused = null;
  }

  saveLabSamples() {

    // console.log('form', this.samples);
    // console.log('containerList', this.containerList);
    // console.log('containerExamList', this.containerExamList);
    // console.log ('existing', this.existingContainerList);
    // console.log ('this.labOrderListData.sampleData.samples', this.labOrderListData.sampleData.samples);
    let validRequest = true;

    let allSample = [];

    for (const [key, value] of Object.entries(this.existingContainerList)) {
      value.forEach(element => {
        // console.log ('sampleId', element.sampleId);
        element.barCode = this.samples.value[element.sampleId];
        // console.log('element.barCode', element.barCode);
        if (element.barCode.trim() == '') {
          // console.log('hiba ures');
          this.saveResult = -1;
          this.saveErrorDescription = 'Minden mintaazonosító mező kitöltése kötelező!';
          validRequest = false;
          return;
        }
        allSample.push(element);
      });
    }

    if (validRequest === true) {


      const formEntries = Object.entries(this.samples.value);

      // formEntries.forEach(sampleItem => {
      //   // console.log('this.labOrderListData.sampleData.samples[sampleItem.sampleId]', this.labOrderListData.sampleData.samples[Number(sampleItem[0])]);
      //   if (sampleItem[1] !== '' && this.labOrderListData.sampleData.samples[Number(sampleItem[0])]) {
      //     this.labOrderListData.sampleData.samples[Number(sampleItem[0])]['barCode'] = sampleItem[1];
      //   }
      //   // console.log('sampleItem', sampleItem);
      // });

      let index = null;

      // while (index >= 0) {
      //   index = this.labOrderListData.sampleData.samples.findIndex(sample => {
      //     // console.log('sample', sample);
      //     if (!sample.barCode || sample.barCode === '') {
      //       // console.log('no barcode');
      //       return true;
      //     }
      //   });
      //   if (index >= 0) {
      //     this.labOrderListData.sampleData.samples.splice(index, 1);
      //   }
      //   // console.log(index);
      // }
      this.labOrderListData.sampleData.samples = allSample;

      // console.log('laborder', this.labOrderListData);

      this.sendLabOrder(this.labOrderListData);
    }
  }

  addSample(container) {
    // console.log('add sample', container);

    // console.log('current exsisting', this.existingContainerList[container.key]);
    // console.log('containerList', this.containerList[container.key]);
    if (this.existingContainerList[container.key].length < this.containerList[container.key].length) {
      // const tempContainerList = this.existingContainerList;
      // tempContainerList[container.key].push(this.containerList[container.key][this.existingContainerList[container.key].length -1 ]);
      // this.existingContainerList = tempContainerList;
      this.existingContainerList[container.key].push(this.containerList[container.key][this.existingContainerList[container.key].length]);
    } else {
      let lastSampleId = 0;
      for (const [key, value] of Object.entries(this.existingContainerList)) {
        value.forEach(element => {
          // console.log ('sampleId', element.sampleId);
          if (element.sampleId > lastSampleId) { lastSampleId = parseInt(element.sampleId) };
        });
      }
      // console.log('highest sampleId', lastSampleId);  
      // console.log ('existing', this.existingContainerList[container.key]);
      const newContainer = {
        sampleId: "" + (lastSampleId + 1),
        containerName: this.containerList[container.key][0]['containerName'],
        barCode: '',
        sampleCode: this.containerList[container.key][0]['sampleCode'],
        sampleName: this.containerList[container.key][0]['sampleName']
      };
      this.existingContainerList[container.key].push(newContainer);
      const fc = new FormControl('');
      this.samples.addControl(newContainer.sampleId, fc);

      // console.log ('this.existingContainerList', this.existingContainerList);
    }
  }

  removeSample(container) {
    // console.log('remove sample', container);
    // console.log('current existing', this.existingContainerList[container.key]);
    // console.log('containerList', this.containerList[container.key]);
    if (this.existingContainerList[container.key].length > 1) {
      // const tempContainerList = this.existingContainerList;
      // tempContainerList[container.key].push(this.containerList[container.key][this.existingContainerList[container.key].length -1 ]);
      // this.existingContainerList = tempContainerList;
      const removedElement = this.existingContainerList[container.key].pop();
      // console.log('after remove existing', this.existingContainerList[container.key]);

    }
  }

  closeResult() {
    this.saveResult = null;
    this.saveErrorDescription = '';
  }

}

