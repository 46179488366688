<div class="card">
  <div class="card-header" id="heading{{ instanceId }}">
    <div style="float: right;">
        <!-- <button class="btn btn-success mr-2" [disabled]="!implantDetailsForm.valid || instanceId === 6" (click)="copyImplantDetails()"
        [ngClass]="{'hidden': instanceId >= 6}">
            <i class="fa fa-copy"></i>
          </button> -->
      <button class="btn btn-danger" [disabled]="!implantDetailsForm.dirty" (click)="resetImplantDetails()">
        <i class="fa fa-trash"></i>
      </button>
    </div>
    <div style="float:left;"><h5 class="mb-0">
      <button class="btn btn-link collapsed" type="button" data-toggle="collapse" [attr.data-target]="cardDataTarget"
        aria-expanded="false" [attr.aria-controls]="cardAriaControls">
        Implantátum #{{ instanceId }}
      </button>
      <i class="fa fa-check-circle" [ngClass]="{'gray': implantDetailsForm.dirty == false,
       'red': implantDetailsForm.dirty == true && implantDetailsForm.valid == false,
       'green': implantDetailsForm.valid == true}"></i>
    </h5>
  </div>
  </div>
  <div id="{{ cardAriaControls }}" class="collapse" attr.aria-labelledby="heading{{ instanceId }}"
    data-parent="#accordionExample">
    <div class="card-body">
      <form [formGroup]="implantDetailsForm">
        <div class="row">

          <div class="col-md-6">
            <h4>Beavatkozás</h4>
            <div class="row form-group">

              <label for="impType" class="col-sm-4 control-label">Beavatkozás típusa</label>
              <div class="col-sm-8 form-item mb-2">
                <select class="form-control" [formControl]="impType">
                  <option value="Ú">Új implantátum</option>
                  <option value="CS">Csere</option>
                  <option value="E">Eltávolítás</option>
                </select>
                <!-- <div class="formError" *ngIf="impType.invalid && formSubmitted">Válasszon okmány típust!</div> -->
              </div>

              <label for="impDate" class="col-sm-4 control-label">Beavatkozás dátuma</label>

              <div class="col-sm-8 form-item mb-2">
                  <div class="input-group mr-1">
                      <input class="form-control datepicker_mobile" placeholder="yyyy-mm-dd" name="dp" formControlName="impDate" ngbDatepicker [minDate]="{year: 1900, month: 1, day: 1}"
                        #d="ngbDatepicker" [ngClass]="{'date-invalid': dateInvalid === true}" max="2030-12-31"
                        min="2018-01-01">
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                      </div>
                    </div>
                <!-- <input type="date" class="form-control" [formControl]="impDate" max="2030-12-31"
                min="2018-01-01"> -->
                <!-- <div class="formError" *ngIf="impDate.invalid && formSubmitted">Válasszon okmány típust!</div> -->
              </div>

              <label class="col-sm-4 control-label">Beavatkozás oka</label>
              <div class="col-sm-8 form-item mb-2">

                <ng-select class="full-width" [searchable]="true" placeholder="Beavatkozás oka (kategória)"
                  [items]="bnoList" formControlName="impBnoCat" bindLabel="visibleData" bindValue="id"
                  [clearable]="false" [loading]="bnoListLoading" (change)="onChangeBNOCategory($event)"
                  loadingText="Betöltés..." notFoundText="Nincs találat">
                </ng-select>
                <!-- <div class="formError" *ngIf="impBno.invalid && formSubmitted">Válasszon okmány típust!</div> -->
              </div>

              <label for="impBno" class="col-sm-4 control-label">Beavatkozás oka</label>
              <div class="col-sm-8 form-item mb-2">

                <ng-select class="full-width" [searchable]="true" placeholder="Beavatkozás oka" formControlName="impBno"
                  [items]="bnoItemList" bindLabel="visibleData" bindValue="bnoCode" [clearable]="false"
                  [loading]="bnoItemListLoading" (change)="onChangeBNO($event)"
                  loadingText="Betöltés..." notFoundText="Nincs találat">
                </ng-select>
                <input type="hidden" formControlName="impBnoDetail">
                <!-- <div class="formError" *ngIf="impBno.invalid && formSubmitted">Válasszon okmány típust!</div> -->
              </div>

                <label *ngIf="impType.value=='CS'" for="impReplace" class="col-sm-4 control-label">Csere oka</label>
                <div *ngIf="impType.value=='CS'" class="col-sm-8 form-item mb-2">
                  <input type="text" class="form-control" [formControl]="impReplace">
                  <!-- <div class="formError" *ngIf="impReplace.invalid && formSubmitted">Válasszon okmány típust!</div> -->
                </div>

              <label for="itemMainGrp" class="col-sm-4 control-label">Főcsoport kód</label>
              <div class="col-sm-8 form-item mb-2">
                <ng-select class="full-width" [searchable]="true" placeholder="Főcsoport kód"
                  formControlName="itemMainGrp" [items]="implantRegBaseData['categories']" bindLabel="grpDesc"
                  bindValue="grpCode" [clearable]="false" [loading]="implantRegBaseLoading"
                  (change)="onChangeBaseCategory($event)" loadingText="Betöltés..." notFoundText="Nincs találat">
                </ng-select>
                <!-- <div class="formError" *ngIf="itemMainGrp.invalid && formSubmitted">Válasszon okmány típust!</div> -->
              </div>

              <label for="itemId" class="col-sm-4 control-label">Alcsoport kód</label>
              <div class="col-sm-8 form-item mb-2">
                <ng-select class="full-width" [searchable]="true" placeholder="Alcsoport kód" formControlName="itemId"
                  [items]="implantRegBaseList" bindLabel="visibleDetail" bindValue="id"
                  [loading]="implantRegBaseLoading" [clearable]="false" [compareWith]="compareSelectedItemId"
                  loadingText="Betöltés..." notFoundText="Nincs találat">
                </ng-select>
                <!-- <div class="formError" *ngIf="itemId.invalid && formSubmitted">Válasszon okmány típust!</div> -->
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <h4>Implantátum</h4>

            <div class="row form-group">

              <!--  <label for="implantatumNeve" class="col-sm-4 control-label">Neve</label>
              <div class="col-sm-8 form-item mb-2">
                <select class="form-control" id="implantatumNeve" [formControl]="implantatumNeve">
                  <option value="Teszt">Teszt név</option>
                </select>
                 <div class="formError" *ngIf="sst.invalid && formSubmitted">Válasszon okmány típust!</div>
              </div>
            -->

            <label for="side" class="col-sm-4 control-label">Oldal</label>
            <div class="col-sm-8 form-item mb-2">
                <input type="radio" value="bal" class="form-control radio-item" [formControl]="side" name="side-{{ instanceId }}">
                <div class="form-control radio-item">Bal</div>
                <input type="radio" value="jobb" class="form-control  radio-item" [formControl]="side" name="side-{{ instanceId }}">
                <div class="form-control radio-item">Jobb</div>
                <input type="radio" value="" class="form-control radio-item" [formControl]="side" name="side-{{ instanceId }}">
                <div class="form-control radio-item">Nincs</div>
              </div>

              <label for="itemType" class="col-sm-4 control-label">Típusa</label>
              <div class="col-sm-8 form-item mb-2">
                <input type="text" class="form-control" [formControl]="itemType">
                <!-- <div class="formError" *ngIf="itemType.invalid && formSubmitted">Válasszon okmány típust!</div> -->
              </div>

              <label for="manufactName" class="col-sm-4 control-label">Gyártó</label>
              <div class="col-sm-8 form-item mb-2">
                <input type="text" class="form-control" [formControl]="manufactName">
                <!-- <div class="formError" *ngIf="manufactName.invalid && formSubmitted">Válasszon okmány típust!</div> -->
              </div>

              <label for="manufactNumber" class="col-sm-4 control-label">Gyártási tételszám</label>
              <div class="col-sm-8 form-item mb-2">
                <input type="text" class="form-control" [formControl]="manufactNumber">
                <!-- <div class="formError" *ngIf="manufactNumber.invalid && formSubmitted">Válasszon okmány típust!</div> -->
              </div>

              <label for="manufactRowNumber" class="col-sm-4 control-label">Sorozatszám</label>
              <div class="col-sm-8 form-item mb-2">
                <input type="text" class="form-control" [formControl]="manufactRowNumber">
                <!-- <div class="formError" *ngIf="manufactRowNumber.invalid && formSubmitted">Válasszon okmány típust!</div> -->
              </div>

              <label for="vendorName" class="col-sm-4 control-label">Forgalmazó</label>
              <div class="col-sm-8 form-item mb-2">
                <input type="text" class="form-control" [formControl]="vendorName">
                <!-- <div class="formError" *ngIf="vendorName.invalid && formSubmitted">Válasszon okmány típust!</div> -->
              </div>

              <label for="vendorPlace" class="col-sm-4 control-label">Forgalmazó székhelye</label>
              <div class="col-sm-8 form-item mb-2">
                <input type="text" class="form-control" [formControl]="vendorPlace">
                <!-- <div class="formError" *ngIf="vendorPlace.invalid && formSubmitted">Válasszon okmány típust!</div> -->
              </div>



            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
