import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {ReloginComponent} from './relogin/relogin.component';
import {NotFoundComponent} from './not-found/not-found.component';
import { MainComponent } from './main/main.component';

const appRoutes: Routes = [
    { path: 'relogin', component: ReloginComponent},
    { path: 'login', component: LoginComponent},
    { path: 'main', component: MainComponent},
    { path: '', component: AppComponent},
    { path: 'not-found', component: NotFoundComponent },
    { path: '**', redirectTo: '/not-found'}
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
