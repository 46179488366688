import { SharedDataService } from './services/shared-data.service';
import { Component, Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth/auth.service';
import { Form, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Md5 } from 'md5-typescript';
import { ActivatedRoute, Router } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoggingInterceptorService } from './services/logging-interceptor.service';
import { PlatformLocation } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { PatientDataFromQueryParams } from './shared/models/patientDataFromQueryParams';
import jwt_decode from 'jwt-decode';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

@Injectable()
export class AppComponent implements OnInit {
  title = 'nautest';
  availServic = null;
  calendarListData = null;
  FreeAppointmentList = null;
  serviceSelectDisabled = true;
  loginForm: FormGroup;


  patientDataFromQueryParams: PatientDataFromQueryParams;

  constructor(private authService: AuthService,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: PlatformLocation,
    private titleService: Title,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit() {
    // console.log(this.activatedRoute.snapshot.queryParamMap);

    this.authService.setUUID();

    const hostname = (this.location as any).location.hostname;
    // console.log('hostname: ', hostname);
    // console.log('index of tesztho.naucare.hu : ', hostname.indexOf('tesztho.naucare.hu'));
    // console.log ('Hostname: ', hostname);
    if (hostname.indexOf('medcsepp.hu') > -1) {

      this.authService.setBrand('MEDCSEPP');
      this.titleService.setTitle('Medcsepp');

      if (hostname.indexOf('teszt.medcsepp.hu') > -1) {
        this.authService.setFeature('FULL');
      } else if (hostname.indexOf('tesztligth.medcsepp.hu') > -1) {
        this.authService.setFeature('LIGHT');
      } else if (hostname.indexOf('light.medcsepp.hu') > -1) {
        this.authService.setFeature('LIGHT');
      } else if (hostname.indexOf('medcsepp.hu') > -1) {
        this.authService.setFeature('FULL');
      }

      // console.log ('Hostname: ', hostname);
      // this.titleService.setTitle( 'Decisio' );
    } else if (hostname === 'localhost' || hostname.indexOf('172.16.16.184') > -1) {

      // this.authService.setBrand('UDMED');
      // this.titleService.setTitle( 'Debreceni Egyetem Egészségügyi e-szolgáltatások' );

      // this.authService.setBrand('MEDCSEPP');
      // this.authService.setFeature('FULL');
      // this.titleService.setTitle( 'Medcsepp' );
      // this.authService.setFeature('LIGHT');

      this.titleService.setTitle('NauCare');
      this.authService.setBrand('NAUCARE');

      /*this.titleService.setTitle('Gondozas');
      this.authService.setBrand('GONDOZAS');*/

      /*this.titleService.setTitle('NauLight');
      this.authService.setBrand('NauLight');*/

      /*this.titleService.setTitle('Dolgozói Egészségkártya');
      this.authService.setBrand('DOLGEK');*/

    } else if (hostname === 'teszt.naucare.hu' || hostname === 'demo.naucare.hu') {
      this.authService.setBrand('NAUCARE');
      this.titleService.setTitle('Naucare');
    } else if (hostname.indexOf('.udmed.hu') > -1) {
      this.titleService.setTitle('Debreceni Egyetem Egészségügyi e-szolgáltatások');
      this.authService.setBrand('UDMED');
    } else if (hostname === 'dek.naucare.hu' || hostname === 'dekteszt.naucare.hu') {
      this.authService.setBrand('DOLGEK');
      this.titleService.setTitle('Dolgozói EK');
    } else if (hostname.indexOf('tesztho.naucare.hu') > -1) {
      this.titleService.setTitle('NauLight');
      this.authService.setBrand('NauLight');
    } else if (hostname.indexOf('ho.naucare.hu') > -1) {
      this.titleService.setTitle('NauLight');
      this.authService.setBrand('NauLight');
    } else if (hostname.indexOf('tesztgondozas.naucare.hu') > -1) {
      this.titleService.setTitle('Gondozas');
      this.authService.setBrand('GONDOZAS');
    } else if (hostname.indexOf('gondozas.naucare.hu') > -1) {
      this.titleService.setTitle('Gondozas');
      this.authService.setBrand('GONDOZAS');
    } else {
      this.titleService.setTitle('NauCare');
      this.authService.setBrand('NAUCARE');
    }

    // this.titleService.setTitle('UD Health Service');
    // this.authService.setBrand('UDMED');



    this.authService.checkStoredCredential();
    // console.log ('app component after check Stored Credential');
    this.activatedRoute.queryParams.subscribe(params => {
      if (!(params && Object.keys(params).length === 0 && params.constructor === Object)) {
        const patientData = params['patient_data'];
        const decodedParams = jwt_decode(patientData);
        this.sharedDataService.patientDataFromQueryParams = {
          address: decodedParams['address'],
          birthName: decodedParams['birthName'],
          birthPlace: decodedParams['birthPlace'],
          city: decodedParams['city'],
          country: decodedParams['country'],
          dateOfBirth: decodedParams['dateOfBirth'],
          email: decodedParams['email'],
          mothersname: decodedParams['mothersname'],
          name: decodedParams['name'],
          ssn: decodedParams['ssn'],
          zip: decodedParams['zip'],
          patientComplaint: decodedParams['patientComplaint'],
          preliminaryDiagnosis: decodedParams['preliminaryDiagnosis'],
          referralComment: decodedParams['referralComment']
        }
        this.sharedDataService.isEbpPatientFromQueryParams = true;
      }
      setTimeout(() => {
        this.navigateToPage(hostname);
      }, 1);
      /* this.navigateToPage(hostname); */
    });
  }

  navigateToPage(hostname) {
    if (this.authService.userData === null) {
      this.router.navigate(['login']);
    } else {
      const now = new Date().getTime();
      // console.log ('sessionExpireTimestamp: ' + Number(localStorage.getItem('sessionExpireTimestamp')));
      // console.log ('nov: ' + now);
      if (Number(localStorage.getItem('sessionExpireTimestamp')) > now) {  // login session still valid

        // Should add a request to try if session is still valid !!!!!!!!

        // console.log ('login session still valid');

        this.router.navigate(['main']);
      } else { // logind session expired
        // console.log ('login session expired');
        this.router.navigate(['relogin']);
      }
    }
    this.authService.setHostname(hostname);
  }
}



export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptorService, multi: true }
];
