<div class="container-fluid w-100">
    <h4 class="col-md-6"><b>Foglalt időpontok: </b></h4>
    <form [formGroup]="scheduleForm">
        <ng-container formArrayName="schedules">
            <div *ngFor="let scheduleFormGroup of schedules.controls; let i = index">
                <div class="row mr-3" [formGroup]="scheduleFormGroup">


                    <!-- <ng-select *ngIf="!scheduleFormGroup.value.schedule.wrongDate" class="w-50 mr-2" formControlName="dateControl" style="float: left" bindLabel="dateFrom">
                        <ng-template ng-label-tmp let-item="item">
                            <span>{{ item.dateFrom | date: 'yyyy-MM-dd' }}</span>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item">
                            <span>{{ item.dateFrom | date: 'yyyy-MM-dd' }}</span>
                        </ng-template>
                    </ng-select> -->
                    <div class="datepicker mr-2">
                        <input class="w-100  datepicker-input" (dateSelect)="getCalendarListData($event, i)" formControlName="datePicker" [displayMonths]="calendarDisplayMonths" [navigation]="navigation" [showWeekNumbers]="showWeekNumbers" [outsideDays]="outsideDays" [dayTemplate]="customDay"
                            ngbDatepicker #datepicker="ngbDatepicker" [disabled]="scheduleFormGroup.value.schedule.schId > 0" />
                        <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled" let-focused="focused">
                            <div class="{{ dayStatus(date) }} custom-day " [class.focused]="focused" [ngClass]="[isStartAndEnd(date) ? 'startAndEnd' : isStart(date) ? 'start' : isEnd(date) ? 'end' : isRange(date) ? 'range' : isInside(date) ? 'faded' : '']" (mouseenter)="hoveredDate = date"
                                (mouseleave)="hoveredDate = null"> {{ date.day }}
                            </div>
                        </ng-template>
                        <button class="btn btn-outline-secondary calendar datepicker-button" type="button" (click)="datepicker.toggle()"></button>
                    </div>
                    <ng-container *ngIf="scheduleFormGroup.value.schedule.schId !== -3  && scheduleFormGroup.value.schedule.schId !== -1  || (scheduleFormGroup.value.schedule.schId === -1 && scheduleFormGroup.value.scheduleList)">
                        <ng-select class="w-25 mr-2" [loading]="loadingCalendar && !scheduleFormGroup.value.timeControl" formControlName="timeControl" style="float: left" [items]="scheduleFormGroup.value.scheduleList">
                            <ng-template ng-label-tmp let-item="item">
                                <span>{{ item.dateFrom | date: 'HH:mm' }}</span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                                <span>{{ item.dateFrom | date: 'HH:mm' }}</span>
                            </ng-template>
                        </ng-select>
                        <span style="text-align: center; line-height: 30px;">Foglalás: </span>
                        <i *ngIf="!scheduleFormGroup.value.wrongSchedule && scheduleFormGroup.value.schedule.schId !== -4" class="ml-2 fa fa-check" style="color: green; float: left; font-size: 2em;"></i>
                        <i *ngIf="scheduleFormGroup.value.wrongSchedule && scheduleFormGroup.value.schedule.schId !== -2" class="ml-2 fa fa-times" style="color: red; float: left; font-size: 2em;"></i>
                        <i *ngIf="scheduleFormGroup.value.wrongSchedule && scheduleFormGroup.value.schedule.schId === -2" class="ml-2 fa fa-calendar-times-o" style="color: orange; float: left; font-size: 2em;" title="A páciens már rendelkezik foglalással az adott időpontban!"></i>
                    </ng-container>

                    <ng-container *ngIf="scheduleFormGroup.value.schedule.schId === -1 && !scheduleFormGroup.value.scheduleList">
                        <span style="text-align: center; line-height: 30px; color: red;">
                            A megadott napra nincs elérhető időpont.
                             </span>
                    </ng-container>

                    <ng-container *ngIf="scheduleFormGroup.value.schedule.schId === -1 && scheduleFormGroup.value.scheduleList">
                        <span style="text-align: center; line-height: 30px; color: red;">
                            A kiválasztott időpontban már van foglalás. Válasszon másik időpontot!
                             </span>
                    </ng-container>
                    <ng-container *ngIf="scheduleFormGroup.value.schedule.schId === -3">
                        <span style="text-align: center; line-height: 30px; color: red;">
                            A kiválasztott időpontban nincs szabad eszköz!
                             </span>
                    </ng-container>

                </div>
                <hr>
            </div>
        </ng-container>
    </form>
    <div div class="row" style="position: relative; top: 65%;">
        <button type="button" class="btn btn-danger  mr-3" (click)="closeModal()"> Vissza </button>
        <button *ngIf="!includesWrongDate() && !useCalendarListData" type="button" class="btn btn-primary" (click)="callSaveSchedule()" [disabled]="saveBookingInProgress">
            <i *ngIf="saveBookingInProgress" class="fa fa-spinner fa-spin"></i>
             Mentés </button>
        <button *ngIf="includesWrongDate() || useCalendarListData" [disabled]="scheduleFormGroup.invalid" type="button" class="btn btn-primary" (click)="checkSchedules()"> 
            <i *ngIf="saveBookingInProgress" class="fa fa-spinner fa-spin"></i>
            Ellenőrzés </button>
    </div>
</div>