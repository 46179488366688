import { DocumentationComponent } from './../documentation/documentation.component';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SharedDataService } from '../services/shared-data.service';
import { AuthService } from '../auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { debounceTime } from 'rxjs/operators';


@Component({
  selector: 'app-implant-details',
  templateUrl: './implant-details.component.html',
  styleUrls: ['./implant-details.component.css']
})
export class ImplantDetailsComponent implements OnInit, OnDestroy {

  apiUrl = environment.apiUrl;

  implantDetailsData: {};
  instanceId: number;

  formChangeSubscription: Subscription;

  cardDataTarget = '';
  cardAriaControls = '';

  // impDate : string - Beavatkozás dátuma (yyyy-MM-dd HH-mm-ss)
  // impType : srting – Beavatkozás típus kódja.
  // impBno : string – Beavatkozás okának kódja.
  // impReplace : string – Csere okának kódja.
  // itemId : number -  Implantátum azonosítója.
  // itemCode : string - Implantátum kódja.
  // itemMainGrp : string – Implantátum főcsoportjának kódja.
  // itemSubGrp : string – Implantátum alcsoport kódja.
  // itemName : string – Implantátum neve.
  // itemtType : string – Implantátum típusa.
  // manufactName : string – Gyártó neve.
  // manufactNumber : string – Tételszám.
  // nanufactRowNumber : string – Sorozatszám.
  // vendorName : string – Forgalmazó neve.
  // vendorPlace : string – Forgalmazó székhelye.

  bnoItemList = null;
  bnoItemListLoading = false;

  impType = new FormControl('', Validators.required);
  impDate = new FormControl('', Validators.required);
  impBnoCat = new FormControl('', Validators.required);
  impBno = new FormControl('', Validators.required);
  impBnoDetail = new FormControl('');
  impReplace = new FormControl('');

  itemMainGrp = new FormControl('', Validators.required);
  itemId = new FormControl('', Validators.required);
  itemType = new FormControl('', Validators.required);


  manufactName = new FormControl('', Validators.required);
  manufactNumber = new FormControl('', Validators.required);
  manufactRowNumber = new FormControl('', Validators.required);
  vendorName = new FormControl('', Validators.required);
  vendorPlace = new FormControl('', Validators.required);
  side = new FormControl('');

  implantDetailsForm = new FormGroup({
    impType: this.impType,
    impDate: this.impDate,
    impBno: this.impBno,
    impBnoDetail: this.impBnoDetail, // hidden a dokumentumba valo masolashoz
    impBnoCat: this.impBnoCat,
    impReplace: this.impReplace,
    itemMainGrp: this.itemMainGrp,
    itemId: this.itemId,
    itemType: this.itemType,
    manufactName: this.manufactName,
    manufactNumber: this.manufactNumber,
    manufactRowNumber: this.manufactRowNumber,
    vendorName: this.vendorName,
    vendorPlace: this.vendorPlace,
    side: this.side
  });

  implantRegBaseSubscription: Subscription;
  implantRegBaseData = {};
  implantRegBaseLoading = true;
  implantRegBaseList = [];

  bnoListSubscription: Subscription;
  bnoList = [];
  bnoListLoading = true;

  dateChangeSubscription: Subscription;
  dateInvalid = false;


  @Input() set implantDetailsBaseData(data) {
    // console.log ('implant Data input triggered');
    if (data !== null) {
      // console.log ('implant data arrived instance:' + data['instanceId']);
      this.implantDetailsData = data;
      this.instanceId = data['instanceId'];
      this.cardDataTarget = '#collapse' + this.instanceId;
      this.cardAriaControls = 'collapse' + this.instanceId;

      if (data['implantData']) {  // Populate implant details for with previously saved implants
        // console.log ('implant Details', data);
        this.impDate.setValue(this.sharedDataService.dateStringToNgbDate(data['implantData']['impDate']));
        // console.log ('impDate set', data['implantData']['impDate']);
        this.implantDetailsForm.markAsDirty();
        this.impReplace.setValue(data['implantData']['impReplace']);
        this.impType.setValue(data['implantData']['impType']);

        this.implantRegBaseList = this.implantRegBaseData['types'][data['implantData']['itemMainGrp']];  // Alcsoport lista populalasa

        this.itemMainGrp.setValue(data['implantData']['itemMainGrp']); // Focsoport kivalasztott elem beallitasa
        // console.log ('itemMainGrp', data['implantData']['itemMainGrp']);

        this.itemId.setValue(data['implantData']['itemId']);  // Alcsoport kivalasztott elem beallitasa
        // console.log ('itemId formControl old:', this.itemId);
        // console.log ('itemId formControl new:', this.itemId);
        // console.log ('itemid data:', data['implantData']['itemId']);

        this.itemType.setValue(data['implantData']['itemType']);

        this.manufactName.setValue(data['implantData']['manufactName']);
        this.manufactNumber.setValue(data['implantData']['manufactNumber']);
        this.manufactRowNumber.setValue(data['implantData']['manufactRowNumber']);

        this.vendorName.setValue(data['implantData']['vendorName']);
        this.vendorPlace.setValue(data['implantData']['vendorPlace']);
        this.side.setValue(data['implantData']['side']);

        if (data['implantData']['impBno']) {
          const bnoSubGrp = this.findBNOmainGroup(data['implantData']['impBno']);
          this.onChangeBNOCategory(bnoSubGrp);
          this.impBnoCat.setValue(bnoSubGrp['id']);
          this.impBno.setValue(data['implantData']['impBno']);
        }

      } else {
        this.resetImplantDetails();
      }

      // valid data arrived
      // console.log ('implant details base data arrived');
      // console.log (data);
    } else {
      // console.log ('implant details base data empty');
      // empty data arrived
    }
  }

  @Output() formDataChange = new EventEmitter<Object>();
  @Output() copyImplantData = new EventEmitter<Object>();

  constructor(
    public authService: AuthService,
    private sharedDataService: SharedDataService,
    private http: HttpClient
  ) { }



  ngOnInit() {
    this.formChangeSubscription = this.implantDetailsForm.valueChanges.subscribe(val => {
      this.formDataChange.emit(this.implantDetailsForm);
    });

    this.bnoList = this.sharedDataService.bnoList['data'];
    // console.log ('Base bno list base load', this.bnoList);
    this.bnoListLoading = this.sharedDataService.bnoList['isLoading'];
    this.bnoListSubscription = this.sharedDataService.bnoListShared.subscribe(data => {
      // console.log ('BNO list shared arrived', data);
      this.bnoList = data['data'];
      this.bnoListLoading = data['isLoading'];
    });


    this.implantRegBaseData = this.sharedDataService.implantRegBase;
    this.implantRegBaseLoading = this.sharedDataService.implantRegBase['isLoading'];
    this.implantRegBaseSubscription = this.sharedDataService.implantRegBaseShared.subscribe(data => {
      this.implantRegBaseData = data;
      this.implantRegBaseLoading = data['isLoading'];
    });


    this.dateChangeSubscription = this.impDate.valueChanges.pipe(
      debounceTime(500)
    ).subscribe(value => {
      if (value !== '' && value !== null) {
        // console.log('1 date change value:', value);
        // console.log('2 date change value elements:', value.year + '-' + value.month + '-' + value.day);

        if (value.year !== undefined) { // valid NgbDate is set

          const dateString = value.year + '-' + ('0' + (value.month)).slice(-2) + '-' + ('0' + value.day).slice(-2);
          const dateFromValue = new Date(dateString);
          // console.log('3 dateFromValue:', dateFromValue);

          // this.sharedDataService.setCurrentDate(dateFromValue);
          this.dateInvalid = false;

        } else {
          // console.log (value);
          const dateValue = value.replace(/\./g, '-');
          // console.log('4 dateValue', dateValue);

          const dateObject = this.sharedDataService.dateFromUserinput(dateValue);

          // console.log ('5 dateObject', dateObject);
          if (dateObject && !isNaN(dateObject.getTime())) {
            const newDate = this.sharedDataService.dateToDateString(dateObject);
            // console.log('6 ngbDate converted:', newDate);
            // this.sharedDataService.setCurrentDate(dateObject);
            this.impDate.setValue(this.sharedDataService.dateStringToNgbDate(newDate));
            this.dateInvalid = false;
          } else {
             // console.log('7 date not set, date is invalid');
             this.dateInvalid = true;
          }
        }
        // else {
        //   console.log('9 date not set, date is invalid');
        //      this.dateInvalid = true;
        // }
      }
    });

  }


  ngOnDestroy() {
    this.formChangeSubscription.unsubscribe();
    this.bnoListSubscription.unsubscribe();
    this.implantRegBaseSubscription.unsubscribe();
  }

  resetImplantDetails() {
    this.implantDetailsForm.markAsPristine();
    this.implantDetailsForm.reset();
  }


  compareSelectedItemId(c1: any, c2: any) {
    // console.log ('c1.id: ' + c1.id + ' c2: ' + c2);
    return c1 && c2 ? c1.id == c2 : c1 === c2;

  }

  onChangeBaseCategory($event) {
    // console.log($event);
    if ($event) {
      // console.log ('impreg base list: ', this.implantRegBaseList);
      // console.log('BNO mainGrpCode: ' + $event['mainGrpcode'] + ' subGrpCode: ' + $event['subGrpCode']
      //  + ' grpCode: ' + $event['grpCode']);
      this.implantRegBaseList = this.implantRegBaseData['types'][$event['grpCode']];
    } else {
      this.implantRegBaseList = [];
    }
    // this.itemId.setValue(null);
  }

  onChangeBNOCategory($event) {
    // console.log($event);
    if ($event) {
      // console.log('BNO mainGrpCode: ' + $event['mainGrpcode'] + ' subGrpCode: ' + $event['subGrpCode']);

      this.bnoItemListLoading = true;
      this.getBnoList($event['mainGrpcode'], $event['subGrpCode']).subscribe((data) => {
        this.bnoItemListLoading = false;
        if (data['code'] === 1) {
          // console.log('BNO detail data arrived: ');
          // console.log(data);
          data['bnoList'].forEach(item => {
            item['visibleData'] = item['bnoCode'] + ' ' + item['bnoName'];
          });
          this.bnoItemList = data['bnoList'];


          if (this.impBno.value) {
            const bnoDetail = this.bnoItemList.find(item => {
              if (item['bnoCode'] === this.impBno.value) {
                return true;
              }
              return false;
            }
            );

            if (bnoDetail) {
              this.impBnoDetail.setValue(bnoDetail['visibleData']);
            }
          }

        } else {
          // error
        }

      });
    } else {

    }
  }

  onChangeBNO($event) {
    // console.log('BNO change');
    // console.log($event);
    this.impBnoDetail.setValue($event['visibleData']);
  }


  getBnoList(mainGrpCode: string, subGrpCode: string) {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      mainGrpCode: mainGrpCode,
      subGrpCode: subGrpCode
    };

    return this.http.post<any[]>(this.apiUrl + '/rest/neu/calendarlist/getbnolist', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  findBNOmainGroup(bno: string) {
    let result = {};
    if (this.bnoList) {
      if (this.bnoList.length > 0) {
        this.bnoList.forEach((item, index) => {
          if (bno.localeCompare(item['subGrpLow']) >= 0 && bno.localeCompare(item['subGrpHgh']) <= 0) {
            // console.log('find BNO result');
            // console.log(item);
            result = item;
          }
        });
        return result;
      } else { console.error ('empty bno List - length = 0'); }
    } else { console.error ('empty bno List not defined or null'); }
  }

  copyImplantDetails() {
    // console.log ('copy implant details from:' + this.instanceId + ' to:' + (this.instanceId + 1));
    this.copyImplantData.emit(this.implantDetailsForm);
  }


}
