import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { SharedDataService } from './shared-data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedApiService {

  apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private sharedDataService: SharedDataService,
    private authService: AuthService
  ) { }

  getUserSimple(tapass) {

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      'tapass': tapass
    };

    return this.http.post(this.apiUrl + '/rest/neu/authentication/getusersimple', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }

  getCalendarDetail(calendarId: string) {

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calendarId: calendarId
    };

    return this.http.post<any[]>(this.apiUrl + '/rest/neu/calendarlist/getcalendardetail', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }



}
