import { AuthorityAccess } from './../../shared/models/authorityAccess';
import { ServicePoint } from './../../shared/models/servicePoint';
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractControl } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { MessageHeader } from 'src/app/shared/models/messageHeader';
import { Institute } from 'src/app/shared/models/institute';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  currentInstitute = new ReplaySubject<Institute>(1);
  currentServicePoint = new ReplaySubject<ServicePoint>(1);
  currentRoleCode = new ReplaySubject<string>(1);
  currentWorkSpace = new ReplaySubject<string>(1);
  currentCsId = new ReplaySubject<number>(1);
  loginFailed = new ReplaySubject<boolean>(1);
  loginErrorMessage = new ReplaySubject<string>(1);
  currentUser = new ReplaySubject<object>(1);
  messageHeader = new ReplaySubject<object>(1);

  currentInstituteLocal = null;

  STORAGE_CURRENT_USER_KEY = 'currentUser';

  constructor(
    private router: Router,
  ) { }

  goToPage(path: string) {
    this.router.navigate(['/' + path]);
  }

  setCurrentInstitute(institute: Institute) {
    if (institute === undefined) { institute = { name: null, code: null }; }
    this.currentInstituteLocal = institute;
    this.currentInstitute.next(institute);
    localStorage.setItem('currentInstitute', JSON.stringify(institute));
  }

  initUser() {
    // if (this.cookieService.get('LoggedIn') === 'LoggedIn') {
    //   this.currentUser.next(localStorage.getItem('currentUser'));
    // }
  }

  initServicePoint() {
    console.log('initServicePoint', localStorage.getItem('currentServicePoint'));
    // @ts-ignore
    this.setCurrentServicePoint(localStorage.getItem('currentServicePoint'));
  }

  initMessageHeader() {
    console.log('initMessageHeader', localStorage.getItem('currentServicePoint'));
    // @ts-ignore
    this.setMessageHeader(localStorage.getItem('currentServicePoint'));
  }

  setCurrentServicePoint(servicePoint: ServicePoint) {
    this.currentServicePoint.next(servicePoint);
    localStorage.setItem('currentServicePoint', JSON.stringify(servicePoint));
  }

  setMessageHeader(currentServicePoint: ServicePoint) {
    if (currentServicePoint) {
      const messageHeader: MessageHeader = {
        messageHeader: {
          currentTapass: currentServicePoint.tapass,
          currentRole: currentServicePoint.roleCode,
          currentServicePointId: +currentServicePoint.spId,
          locale: 'hu',
          deviceId: '638dc49d-ac7b-4f80-aeaf-25ccbd0ed326'
        }
      };

      localStorage.setItem('messageHeader', JSON.stringify(messageHeader));
    }
  }

  getMessageHeader() {
    return localStorage.getItem('messageHeader');
  }

  getCurrentCsId() {
    return (localStorage.getItem('medcseppCsId'));
  }

  setCsId(csId: number) {
    this.currentCsId.next(csId);
    localStorage.setItem('medcseppCsId', String(csId));
  }
  setPrinterCsId(printerCsId: number) {
    localStorage.setItem('printerCsId', String(printerCsId));
  }

  setWorkSpace(workSpace: string) {
    this.currentWorkSpace.next(workSpace);
  }

  TAJvalidator(control: AbstractControl): {
    [key: string]: boolean
  } | null {
    if (control.value !== undefined && control.value !== null) {
      let value = control.value.replace(/ /g, '');
      value = value.replace(/-/g, '');
      if (value.length !== 9 || value === '000000000') {
        return { validTAJ: false };
      } else {
        let sum = 0;
        for (let i = 7; i > -1; i--) {
          sum += ((i % 2 === 0) ? value[i] * 3 : value[i] * 7);
        }
        if (Number(value[8]) === sum % 10) {
          return null;
        }
      }
    }
    return { validTAJ: false };
  }

  getCurrentDate(date: Date) {
    const dateTimeString = date.getFullYear() + '-' + (
      ('0' + (
        date.getMonth() + 1
      )).slice(-2)
    ) + '-' + (
        ('0' + date.getDate()).slice(-2)
      );
    return dateTimeString;
  }

  dateToDateTimeString(date: Date) {
    const dateTimeString = date.getFullYear() + '-' + (
      ('0' + (
        date.getMonth() + 1
      )).slice(-2)
    ) + '-' + (
        ('0' + date.getDate()).slice(-2)
      ) + ' ' + (
        ('0' + date.getHours()).slice(-2)
      ) + ':' + (
        ('0' + date.getMinutes()).slice(-2)
      );
    return dateTimeString;
  }

  checkModuleAccess(authorityTypeId: number): AuthorityAccess {
    let authorityAccess: AuthorityAccess;

    if (environment.apiUrl === 'https://ekrest.nauticom.hu') {
      // console.log ('authorityTypeId', authorityTypeId);
      switch (authorityTypeId) {
        case 5112: {  // 55670
          return authorityAccess = {
            worklist: true,
            arrivePatient: true,
            callPatient: true,
            saveAppointment: false,
            callPatientDD: false,
            savePatientToAppointment: true,
            saveLabOrder: true,
            saveSamples: false
          };
        }
        case 5114: { // 55671
          return authorityAccess = {
            worklist: true,
            arrivePatient: false,
            callPatient: true,
            saveAppointment: false,
            callPatientDD: true,
            savePatientToAppointment: true,
            saveLabOrder: true,
            saveSamples: false
          };
        }
        case 5110: { // diszpecser 55672 
          return authorityAccess = {
            worklist: true,
            arrivePatient: true,
            callPatient: false,
            saveAppointment: true,
            callPatientDD: false,
            savePatientToAppointment: true,
            saveLabOrder: true,
            saveSamples: false
          };
        }
        case 5111: { // vervetel 55673
          return authorityAccess = {
            worklist: false,
            arrivePatient: false,
            callPatient: true,
            saveAppointment: false,
            callPatientDD: false,
            savePatientToAppointment: false,
            saveLabOrder: false,
            saveSamples: true

          };
        }
      }
    } else {
      switch (authorityTypeId) {
        case 55670: {
          return authorityAccess = {
            worklist: true,
            arrivePatient: true,
            callPatient: true,
            saveAppointment: false,
            callPatientDD: false,
            savePatientToAppointment: false,
            saveLabOrder: false,
            saveSamples: true
          };
        }
        case 55671: {
          return authorityAccess = {
            worklist: true,
            arrivePatient: false,
            callPatient: true,
            saveAppointment: false,
            callPatientDD: true,
            savePatientToAppointment: false,
            saveLabOrder: false,
            saveSamples: true
          };
        }
        case 55672: {
          return authorityAccess = {
            worklist: true,
            arrivePatient: true,
            callPatient: false,
            saveAppointment: true,
            callPatientDD: false,
            savePatientToAppointment: true,
            saveLabOrder: true,
            saveSamples: false
          };
        }
        case 55673: {
          return authorityAccess = {
            worklist: false,
            arrivePatient: false,
            callPatient: true,
            saveAppointment: false,
            callPatientDD: false,
            savePatientToAppointment: false,
            saveLabOrder: false,
            saveSamples: true
          };
        }
      }
    }


  }
}
