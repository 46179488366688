<div class="error" [ngClass]="{'showResult': error == true}">
    <div class="closeResult" (click)="closeError()">x</div>
    <div class="errorContent">
        <span>{{ errorDescription }}</span>
    </div>
</div>

<div class="calendar-list-container">
    <button #showBookingModal data-toggle="modal" data-target="#idopontkezelo" data-backdrop="static" data-keyboard="false" style="display:none;"></button>
    <!-- Időpontfoglalás bal klikk-es nyitásának segédeleme  -->

    <!-- <div *ngIf="!calendarLoading && calendarListDataAll.length == 0 && !availServiceEmpty">A megadott napra nincs
      bejegyzés</div> -->
    <div *ngIf="!calendarLoading">
        <div class="searchbar">
            <div class="row mb-2">
                <div class="col-12">
                    <div class="row">

                        <div class="col-3">
                            <ng-select [searchable]="true" placeholder="Válasszon szolgáltatást..." [formControl]="eventType" [items]="etListFiltered" bindLabel="etName" (change)="filterSp($event)" [clearable]="true" loadingText="Betöltés..." notFoundText="Nincs találat">
                                <!-- [loading]="availServiceLoading" -->
                            </ng-select>
                        </div>
                        <div class="col-3">
                            <ng-select [searchable]="true" placeholder="Válasszon szolgáltatási pontot..." [formControl]="servicePoint" [items]="spListFiltered" bindLabel="spName" (change)="filterEt($event)" [clearable]="true" loadingText="Betöltés..." notFoundText="Nincs találat">
                                <!-- [loading]="availServiceLoading" -->
                            </ng-select>
                        </div>
                        <div class="col-1">
                            <button class="btn btn-primary" style="float: left;" (click)="setServices()">Szűrés</button>
                        </div>
                        <div class="col-2">
                            <ng-select [searchable]="true" placeholder="Válasszon pácienst..." [formControl]="patient" [items]="bookedPatients" bindLabel="participantName" (change)="filterPatients($event)" [clearable]="true" loadingText="Betöltés..." notFoundText="Nincs találat">
                                <!-- [loading]="availServiceLoading" -->
                            </ng-select>
                        </div>
                        <div class="col-2">
                            <ng-select [searchable]="true" placeholder="Rendezés..." [formControl]="sortLogic" [items]="sortOptions" bindLabel="sortName" (change)="reSortCalCollumns($event)" loadingText="Betöltés..." [clearable]="false" notFoundText="Nincs találat">
                            </ng-select>
                        </div>
                        <div class="col-1">
                            <div class="double-button-container">
                                <button type="button" class="btn mr-1 mb-1 button-counter small-button" [ngClass]="{'btn-light': bcpMessageCountError ==0 && bcpMessageCountNew ==0,
                            'btn-warning': selectedCalendarEntries.length > 0}" (click)="toggleBookingList()" title="Foglalásra megjelölt időpontok">
                                    <!-- 'btn-danger': bcpMessageCountError > 0}" *ngIf="environment.eesztEnabled == true  && Brand !== 'NauLight'" (click)="loadBcpMessages()" title="Eszig login" -->
                                    <i class="fa fa-calendar"></i>
                                    <span *ngIf="selectedCalendarEntries.length > 0" class="button-count">
                                        {{selectedCalendarEntries.length}}
                                    </span>
                                </button>
                                <div class="appointmentPanel" *ngIf="selectedCalendarEntries.length > 0 && showMultiBooking === true">
                                    <table>
                                        <tr *ngFor="let appointment of selectedCalendarEntries; let a = index" style="border-bottom: 1px solid;">
                                            <td style="padding-right: 10px">
                                                {{appointment.dateFrom | date: 'yyyy-MM-dd' }} ({{ timestampGetDayOfWeek(appointment.dateFrom) }})
                                            </td>
                                            <td>
                                                {{appointment.dateFrom | date: 'HH:mm' }}
                                            </td>
                                            <td>
                                                -
                                            </td>
                                            <td style="padding-right: 10px">
                                                {{appointment.dateTo | date: 'HH:mm' }}
                                            </td>
                                            <td>
                                                {{appointment.etName}} {{appointment.servicePointName}}
                                            </td>
                                            <ng-select *ngIf="appointment.equipments?.length > 0" [searchable]="true" placeholder="Válasszon eszközt..." [formControl]="appointment.equipmentsControl" [items]="appointment.equipments" bindLabel="name" [clearable]="true" loadingText="Betöltés..." notFoundText="Nincs találat">
                                                <!-- [loading]="availServiceLoading" -->
                                            </ng-select>
                                            <td style="width: 30px; text-align: right;">
                                                <span (click)="selectCalendarEntry($event, appointment, null)">
                                                    <i class="fa fa-trash"></i>
                                                </span>
                                            </td>
                                        </tr>
                                    </table>
                                    <!-- Foglalási adatok, péciens, alias, megjegyzés -->
                                    <form [formGroup]="bookingDetails" class="form-dropdown-select">
                                        <div class="form-group mt-3" [ngClass]="{'hidden': Brand === 'UDMED'}">
                                            <input type="text" [formControl]="patientAlias" placeholder="Adjon meg páciens alias-t" class="form-control mb-1" (change)="bookingFormChanged($event)">

                                            <ng-select [searchable]="true" formControlName="patientName" placeholder="Válaszon pácienst" [items]="patientList" bindLabel="displayData" [loading]="patientListLoading" loadingText="Betöltés..." notFoundText="Nincs találat" class="mb-1" (change)="bookingPatientDropdownChanged($event)">

                                            </ng-select>
                                            <input type="text" [formControl]="comment" placeholder="Megjegyzés" class="form-control mb-1">
                                        </div>
                                    </form>

                                    <button type="button" class="btn mt-1 btn-primary" [disabled]="!bookingFormValid || saveBookingInProgress" (click)="saveMultiBooking();">
                                        <i *ngIf="saveBookingInProgress" class="fa fa-spinner fa-spin"></i>
                                        Foglalás</button>
                                </div>
                                <button type="button" class="btn mr-1 mb-1 button-counter btn-light small-button" (click)="toggleDetails()" title="Foglalási adatok megjelenítése">
                                    <i class="fa fa-info-circle"></i>
                                </button>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="calendarTopPadding">
            <div *ngIf="calendarRefreshTimer > 0" class="meter">
                <span style="width:100%;">
                    <span class="progress" [style.animation-duration.s]="calendarRefreshTimer/ 1000"
                        *ngIf="calendarLoaded"></span>
                <span class="progress" *ngIf="!calendarLoaded"></span>
                </span>
            </div>

            <div class="topSpace"></div>

            <div class="multiCalendarWrapper" [ngClass]="{'showDetails': showDetails}">

                <!-- STICKY COLUMN HEADER -->
                <div *ngIf="emptyCalendarMessage ==''" [ngStyle]="{'width': (60+ (columns * ((col_size*width_multiplier)+3))) + 'px'}" class="stickyHeader">

                    <div>
                        <div class="mc-col-3">
                            <div class="calendar_items one-column sidespace header-text">
                                <div class="rotated-text">
                                    <p class="service-header" *ngIf="collumnHeader == 1" (click)="toggleCollumnHeader(2)">
                                        <i class="fa fa-bars"></i> Szolg.
                                    </p>
                                    <p class="service-header" *ngIf="collumnHeader == 2" (click)="toggleCollumnHeader(1)">
                                        <i class="fa fa-bars"></i> Munkah.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="mc-col-{{col_size}}" style="padding-left: 3px;" *ngFor="let calendarListDataColumn of calendarListDataArray; let i = index">
                            <!-- Oszlop fejlec, munkahely es szolgaltatas neve -->
                            <div *ngIf="collumnHeader == 1" class="calendar_items one-column height-2 header-text" style="display: table; width: 100%;" title="{{calendarListDataColumn.header?.spName}}">
                                <div class="rotated-text">
                                    <p class="service-header">
                                        {{calendarListDataColumn.header?.etName}}
                                    </p>
                                </div>
                            </div>
                            <div *ngIf="collumnHeader == 2" class="calendar_items one-column height-2 header-text" style="display: table; width: 100%;" title="{{calendarListDataColumn.header?.etName}}">
                                <div class="rotated-text">
                                    <p class="service-header">
                                        {{calendarListDataColumn.header?.spName}}
                                    </p>
                                </div>
                            </div>


                            <div class="calendar_items one-column height-1 header-day" style="display: table; width: 100%;">
                                <p>
                                    {{calendarListDataColumn.dayOfWeek.shortName}}
                                    <br /> {{calendarListDataColumn.date}}
                                </p>
                            </div>
                        </div>


                    </div>
                </div>
                <div style="clear: both;float:none;"></div>
                <!-- CALENDAR CONTENT -->

                <!-- [ngStyle]="{'height': 'calc(100% /' + week[z].cells.length + ')'}"  -->
                <div *ngIf="emptyCalendarMessage == ''" [ngStyle]="{'width': (60+ (columns * ((col_size*width_multiplier)+3))) + 'px'}">

                    <div *ngIf="additionalSlots > 0" class="height-{{ additionalSlots }} empty-item row" [ngClass]="{'two-columns': twoColumns, 'one-column': !twoColumns}">
                    </div>
                    <div *ngIf="currentTimeMarkerPosition >= 0 && calendarListDataAll.length > 0 " [style.top.px]="currentTimeMarkerPosition" #currentTimeMarker class="currentTimeMarker"> </div>

                    <div style="position: absolute;" [style.top.px]="currentScrollPosition" #currentScrollPos class="scrollPos"></div>

                    <!-- TIME AXIS -->
                    <div class="mc-col-3 time-axis">
                        <ng-container *ngIf="!showSettings">
                            <div *ngFor="let timeItem of calendarTimeAxis; let counter=index" class="calendar_items one-column height-12">{{timeItem}}</div>
                        </ng-container>
                    </div>

                    <div class="mc-col-{{col_size}}" style="padding-left: 3px;" *ngFor="let calendarListDataColumn of calendarListDataArray; let i = index">

                        <div *ngFor="let calendarEntryRow of calendarListDataColumn.items; let h = index" class="r-{{h}}">

                            <div *ngFor="let calendarEntry of calendarEntryRow; let i = index" [ngClass]="{'calendar_item_right_space': calendarListDataColumn.itemCount[h] < 12}" class="calendar_item_container col-md-{{calendarListDataColumn.itemCount[h]}}">

                                <!-- *ngIf="calendarEntry['calendarStatus'] != 'F'" -->
                                <div *ngIf="calendarEntry?.calendarStatus !== '_' && calendarEntry?.calendarStatus !== 'T' && calendarEntry?.eventTypeFk != 12720854" class="calendar_items height-{{ calendarEntry?.slotLength }} col-md-12 one-column" [ngClass]="{
                                            'selected-patient-box' : selectedPatient && calendarEntry['participantUserID'] == selectedPatient.participantUserID,
                                            'selected-booking-box' : selectedCalendarEntry(calendarEntry),
                                            'appointment_arrived': calendarEntry?.calendarStatus == 'A',
                                            'appointment_discarded': calendarEntry?.calendarStatus == 'D',
                                            'free-appointment': calendarEntry?.calendarStatus == 'F',
                                            'appointment-S': calendarEntry?.calendarStatus == 'S',
                                            'appointment-CO': calendarEntry?.calendarStatus == 'CO',
                                            'appointment-DD': calendarEntry?.calendarStatus == 'DD',
                                            'appointment-DH': calendarEntry?.calendarStatus == 'DH',
                                            'appointment-DN': calendarEntry?.calendarStatus == 'DN'
                                            }" (click)="setAppointmentData(calendarEntry, calendarListDataColumn.header, 'bookAppointment')" [title]="calendarEntryDetails(calendarListDataColumn, calendarEntry,1)" (contextmenu)="selectCalendarEntry($event, calendarEntry, calendarListDataColumn.header)">
                                    <div class="calEntryDetail">
                                        <p *ngFor="let detail of calendarEntryDetails(calendarListDataColumn, calendarEntry,2);">
                                            {{ detail }}
                                        </p>
                                    </div>
                                </div>

                                <div *ngIf="calendarEntry?.calendarStatus === '_' || calendarEntry?.calendarStatus === 'T' && calendarEntry?.eventTypeFk != 12720854" class="calendar_items height-{{ calendarEntry?.slotLength }} col-md-12 one-column">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-3" *ngIf="!showSettings && emptyCalendarMessage !== ''">
                    <div class="col-12">
                        <h3>{{emptyCalendarMessage}}</h3>
                    </div>
                </div>

            </div>

        </div>
    </div>

    <div *ngIf="calendarLoading" class="calendarLoading">
        <div class="osahanloading"></div>
        <div class="osahanloadingcontent">Loading calendar...</div>
    </div>
