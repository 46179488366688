import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedDataService } from '../services/shared-data.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedApiService } from '../services/shared-api.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-arrive-patient-simple',
  templateUrl: './arrive-patient-simple.component.html',
  styleUrls: ['./arrive-patient-simple.component.css']
})

export class ArrivePatientSimpleComponent implements OnInit, OnDestroy {

  apiUrl = environment.apiUrl;

  constructor(
    private sharedDataService: SharedDataService,
    private authService: AuthService,
    private http: HttpClient,
    private sharedApiService: SharedApiService
  ) { }

  patientDataValue = { participantName: '', servicePointFk: ''};
  isLoading: boolean;

  saveInProgress = false;
  saveResult = null;
  saveErrorDescription = '';
  calendarDetail = null;

  admissionTypes = [];
  admissionTypesSubscription: Subscription;
  admissionTypesLoading = true;

  filteredDoctorsList = [];

  @Input() set patientData(data) {
    // console.log ('arrive patient patientData input triggered');
    if (data !== null && data['calendarFk']) {
      this.patientDataValue = data;
      // console.log('arrive patientData set:');
      // console.log(this.patientDataValue);
      this.isLoading = true;

      // Filter doctors list to current service point fk

      this.filteredDoctorsList = this.doctorsList.filter(item => {
        if (item.servicePointId === data.servicePointFk) {
          // console.log ('item', item.servicePointId, data.servicePointFk);
        }
        return item.servicePointId === data.servicePointFk;
      });
      // console.log ('filteredDoctorsList', this.filteredDoctorsList);

      this.sharedApiService.getCalendarDetail(data['calendarFk']).subscribe((responseData) => {
        this.isLoading = false;
        if (responseData['code'] === 1) {
          // console.log (responseData);
          this.ssn.setValue(responseData['data']['participantSocialSecurityNumber']);
          this.sst.setValue(responseData['data']['participantSsnCode']);
          this.sstd.setValue(responseData['data']['participantSsnTypeDesc']);
          this.calendarDetail = responseData['data'];
          // console.log ('calendarDetail', this.calendarDetail);

          if (this.sst.value == 1) {
            this.consent.setValidators([Validators.required]);
          } else {
            this.consent.setValidators([]);
          }

        } else {
          console.log ('Error loading patient details calendarId: ' + data['calendarFk']);
          // console.log (responseData);
        }
      });
    }
  }


  formSubmitted = false;

  arrivePatientErrorDescription = '';
  arrivePatientSaveResult = null;

  ssn = new FormControl('', [Validators.required, Validators.minLength(4)]);
  sst = new FormControl('', Validators.required);

  sstd = new FormControl(); // , [Validators.required, Validators.minLength(4)]);
  consent = new FormControl('');
  ellatasModja = new FormControl('', Validators.required);
  ellatoOrvos = new FormControl(null, Validators.required);
  ellatasTipusa = new FormControl('', Validators.required);
  admType = new FormControl(null);
  assignedDoctorsOnly = new FormControl(true);

  treatmentDetails = new FormGroup({
    // 'name': this.name,
    'sst': this.sst,
    'ssn': this.ssn,
    'sstd': this.sstd,
    'consent': this.consent,
    'ellatoOrvos': this.ellatoOrvos,
    'ellatasModja': this.ellatasModja,
    'ellatasTipusa': this.ellatasTipusa,
    'admType': this.admType,
    'assignedDoctorsOnly': this.assignedDoctorsOnly
  });

  doctorsListDataSubscription: Subscription;
  doctorsList = [];
  doctorsListLoading = true;

  careTypesSubscription: Subscription;
  careTypes = {};
  careTypesLoading = true;

  careTypeList = [];

  ngOnInit() {
    //  console.log ('arrive patient onInit');
    
    this.patientDataValue = { participantName: '', servicePointFk: ''};
    // this.doctorsList = this.sharedDataService.doctorsList;

    this.admissionTypes = this.sharedDataService.admissionTypes;

    this.admissionTypesSubscription = this.sharedDataService.admissionTypesShared.subscribe((data) => {
      this.admissionTypes = data;
      this.admissionTypesLoading = false;
    });

    this.doctorsListDataSubscription = this.sharedDataService.doctorsListShared.subscribe((data) => {
      // console.log('arrive patient doctors list arrived');
      this.doctorsListLoading = false;
      this.doctorsList = data;
      this.filteredDoctorsList = this.doctorsList.filter(item => {
        if (item.servicePointId === this.patientDataValue.servicePointFk) {
          // console.log ('item', item.servicePointId, this.patientDataValue.servicePointFk);
        }
        return item.servicePointId === this.patientDataValue.servicePointFk;
      });
    });

    this.careTypes = this.sharedDataService.patientCareTypes;
    // console.log ('caretypes', this.careTypes);
    this.careTypesLoading = this.careTypes['isLoading'];

    this.careTypesSubscription = this.sharedDataService.patientCareTypesShared.subscribe((data) => {
      this.careTypes = data;
      this.careTypesLoading = this.careTypes['isLoading'];
      // console.log ('caretypes', this.careTypes);
    });

  }

  ngOnDestroy() {
    this.doctorsListDataSubscription.unsubscribe();
    this.careTypesSubscription.unsubscribe();
  }

  onChangeCareCategory($event) {
    if ($event) {
      if ($event.typeCode === 'ARVTYPE_INPAT') {
        this.admType.setValidators([Validators.required]);
        this.admType.reset();
        // console.log ('setValidators');
      } else if ($event.typeCode === 'ARVTYPE_OUTPAT') {
        this.admType.clearValidators();
        this.admType.reset();
        // console.log ('clearValidators');
      }
      this.careTypeList = this.careTypes['types'][$event['typeCode']];
    } else {
      this.careTypeList = [];
    }
    this.ellatasTipusa.setValue(null);
  }

  closeResult() {
    this.arrivePatientSaveResult = null;
  }

  onArrivePatient() {
    this.saveInProgress = true;

    this.updateCalendar().subscribe((responseData) => {

      this.saveInProgress = false;

      if (responseData['code'] === 1) {
        this.saveResult = 0;
        this.sharedDataService.refeshCalendar(this.calendarDetail['providerSpId']);
        // setTimeout(() => { this.saveResult = null; }, 3000);
        // console.log ('Arrive OK');

      } else {

        this.saveResult = -1;
        this.saveErrorDescription = document['code'] + ' ' + document['message'];

      }

    });
  }


  arrivePatient() {

      let admTypeCatValue = '';
      if (this.ellatasModja.value === 'ARVTYPE_INPAT') {
        admTypeCatValue = 'FEKVO';
      } else if (this.ellatasModja.value === 'ARVTYPE_OUTPAT') {
        admTypeCatValue = 'JARO';
      }

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      admTypeCat: admTypeCatValue,
      admType: this.ellatasTipusa.value,
      admMode: this.admType.value,
      calId: this.patientDataValue['calendarFk'],
      calModReasonCode: 'EBK', // 'HBK_F',
      doctorId: this.ellatoOrvos.value,
      eesztEnabled: this.consent.value === 'true' ? true : false,
      participantSsnCode: this.sst.value,
      participantSsnTypeDesc: this.sstd.value !== null ? this.sstd.value : '',
      participantSocialSecurityNumber: this.ssn.value,
      patientId: this.patientDataValue['participantUserID'],
      status: 'A'
    };


    return this.http.post<any[]>(this.apiUrl + '/rest/neu/calendarlist/arrivepatient', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }

  updateCalendar() {


    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calendarId: this.patientDataValue['calendarFk'],
      status: 'A',
      isCalDetailUpdateEnabled: false
    };


    return this.http.post<any[]>(this.apiUrl + '/rest/neu/calendarlist/updatecalendar', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }

  onClose() {
    this.saveResult = null;
    this.resetForm();
  }

  onChangeSst() {
    if (this.sst.value == 1) {
      this.consent.setValidators([Validators.required]);
    } else {
      this.consent.clearValidators();
      this.consent.updateValueAndValidity();
    }
  }

  logForm() {
    // console.log ('form:', this.treatmentDetails);
  }

  resetForm() {
    this.patientDataValue = { participantName: '', servicePointFk: ''};
    const data = [];
    data['NGdestinationService'] = 'arrivePatient';
    this.sharedDataService.setPatientData(data);
    this.admType.clearValidators();
    this.treatmentDetails.reset();
  }

}
