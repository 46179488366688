<!-- Modal -->

<div [ngClass]="{'hidden': !showImage }" class="imagePopup">
  <div *ngIf="imageLoading == false" class="imageContent">
    <div class="closeButton">
      <button class="btn btn-secondary" (click)="closeImage()">X</button>
    </div>
    <div>
      {{ eventEntry['eventTime'] | date: ' yyyy-MM-dd HH:mm:ss'}} -
      {{ eventEntry['name'] }} -
      {{ eventEntry['deviceName'] }}
    </div>
    <div *ngIf="imageError == true" class="error">
      Hiba a kép betöltése során!
    </div>
    <div *ngIf="imageData !== {}" [ngStyle]="imageData" class='camPicture'> </div>
    <div *ngIf="regImageData" class="regImageOverlay">
      <img height="185" [src]="regImageData | safe: 'resourceUrl'" />
    </div>
  </div>

  <div class="imageContent" *ngIf="imageLoading == true">
    <div class="closeButton">
      <button class="btn btn-secondary" (click)="closeImage()">X</button>
    </div>
    <i class="fa fa-spinner fa-spin"></i>
    Lekérdezés folyamatban...
  </div>
</div>

<div class="modal" tabindex="-1" id="mozgas" role="dialog" aria-labelledby="mozgas" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">


    <div [ngClass]="{'hidden': !loading}" class="dolgMozgQuitConfirmation">
      <div class="quitConfirmationContent">
        <i class="fa fa-spinner fa-spin"></i>
        Lekérdezés folyamatban...
      </div>
    </div>


    <div class="modal-content">
      <div class="modal-header">

        <h5 class="modal-title">Dolgozói mozgás lekérdezése</h5>
        <button class="btn btn-secondary" data-dismiss="modal" (click)="resetModal()">X</button>
      </div>
      <div class="modal-body">
        <div class="container-fluid min-height">

          <div *ngIf="!loading">

            <form class="form-group" [formGroup]="requestDetails">
              <div class="col-md-12 mb-4">
                <div class="row">
                  <div class="col-md-6">
                    <div class="row mb-2">
                      <div class="col-md-4">Intervallum kezdete:</div>
                      <div class="col-md-8">
                        <!-- <input type="date" class="form-control" [formControl]="dateFromInput"
                          (change)="setStartDate()"> -->

                        <div class="input-group mr-1">
                          <input class="form-control mb-2" placeholder="yyyy-mm-dd" name="dp"
                            formControlName="dateFromInput" ngbDatepicker #dFrom="ngbDatepicker" [minDate]="{year: 1900, month: 1, day: 1}"
                            [ngClass]="{'date-invalid': dateInvalidStart === true}">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="dFrom.toggle()"
                              type="button"></button>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">Intervallum vége:</div>
                      <div class="col-md-8">
                        <!-- <input type="date" class="form-control" [formControl]="dateToInput"
                          (change)="setEndDate()"> -->

                        <div class="input-group mr-1">
                          <input class="form-control mb-2" placeholder="yyyy-mm-dd" name="dp"
                            formControlName="dateToInput" ngbDatepicker #dTo="ngbDatepicker" [minDate]="{year: 1900, month: 1, day: 1}"
                            [ngClass]="{'date-invalid': dateInvalidEnd === true}">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="dTo.toggle()"
                              type="button"></button>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">

                    <div class="row  mb-3">
                      <ng-select class="w100" [searchable]="true" placeholder="Eszközök"
                        formControlName="acDeviceFilter" [items]="acDeviceList" bindLabel="deviceName"
                        bindValue="deviceId" [clearable]="false" loadingText="Betöltés..." notFoundText="Nincs találat"
                        [multiple]="true" [closeOnSelect]="false">
                      </ng-select>
                    </div>

                    <div class="row">
                      <ng-select [searchable]="true" formControlName="patientName" placeholder="Dolgozó választás"
                        [items]="patientList" bindLabel="displayData" bindValue="tapass"
                        (change)="onSelectedPatientChange($event)" [loading]="patientListLoading"
                        loadingText="Betöltés..." notFoundText="Nincs találat" class="mr-2 w100-90">
                      </ng-select>

                      <button class="btn btn-primary" (click)="loadEventLogRows()"
                        [disabled]="dateInvalidEnd || dateInvalidStart || requestDetails.invalid">
                        Listáz
                      </button>

                    </div>


                  </div>
                  <div class="col-md-2">
                    <img height="185" [src]="regImageData | safe: 'resourceUrl'" />
                  </div>
                </div>
              </div>
            </form>

            <div class="col-md-12 text-center" *ngIf="eventLogRows.length == 0 && pristine == false">
              <h5>
                A megadott időintervallumban nincs esemény.
              </h5>
            </div>
            <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Szűrés" #filterTable
                [formControl]="filterInput">
              <i mat-button matSuffix mat-icon-button class="fa fa-times"
                (click)="filterInput.reset(); applyFilter('')"></i>
            </mat-form-field>
            <button class="btn btn-primary ml-4" (click)="printMozgas()">Nyomtatás</button>
            <mat-table [dataSource]="dataSource" matSort id="print-section">
              <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef> Dátum </mat-header-cell>
                <mat-cell *matCellDef="let element"><span class="ellipsis">
                    {{element.eventTime | date: ' yyyy-MM-dd HH:mm:ss'}}</span> </mat-cell>
              </ng-container>
              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Név </mat-header-cell>
                <mat-cell *matCellDef="let element"><span class="ellipsis"> {{element.name}}</span> </mat-cell>
              </ng-container>
              <ng-container matColumnDef="device">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Eszköz </mat-header-cell>
                <mat-cell *matCellDef="let element"><span class="ellipsis"> {{element.deviceName}} </span></mat-cell>
              </ng-container>
              <ng-container matColumnDef="picture">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Kép </mat-header-cell>
                <mat-cell *matCellDef="let element"><span class="ellipsis" (click)="loadIpCamPicture(element)">
                    <i class="fa fa-camera"></i>
                  </span></mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->


          </div>



        </div>
      </div>
    </div>
  </div>
</div>
