<div class="env" [ngClass]="{'udmed-env': authService.getBrand() == 'UDMED'}">
    <span>v{{ environment.version }} </span>
    <span *ngIf="apiUrl == 'https://ekrest.nauticom.hu' ">
    {{ apiUrl }}
  </span>
</div>
<div class="mainQuitConfirmation" [ngClass]="{'show': reloginForced}">
    <div class="quitConfirmationContent">
        <h6 class="warning"><i class="fa fa-exclamation-circle"></i> A munkamenet lejárt, kérem jelentkezzen be újra</h6>
        <div class="col-md-12 mt-4">
            <form class="form-signin" [formGroup]="loginForm">
                <label for="inputEmail">Felhasználónév</label>
                <input type="email" class="form-control mb-2" required="" autofocus="" formControlName="email">
                <label for="inputPassword">Jelszó</label>
                <input type="password" class="form-control mb-4" placeholder="" required="" formControlName="password">
            </form>
            <div class="row">
                <div class="col-md-6">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="logout()" [disabled]="reloginInProgress">Kilépés</button>
                </div>
                <div class="col-md-6">
                    <button type="button" class="btn btn-success" (click)="relogin()" [disabled]="reloginInProgress">
            <i *ngIf="reloginInProgress" class="fa fa-spinner fa-spin"></i>
            Bejelentkezés
          </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mainQuitConfirmation" [ngClass]="{'show': offline}">
    <div class="quitConfirmationContent">
        <h6 class="warning"><i class="fa fa-exclamation-circle"></i> Az internetkapcsolat megszakadt...</h6>
    </div>
</div>

<div [ngClass]="{'hidden': eszigAuthInProgress === false}" class="fullSizeCover">
    <div class="fullSizeCoverContent">
        <div class="col-md-12 mt-2">
            <div class="row">
                <div class="col-md-12 text-center">
                    <h5><i class="fa fa-spinner fa-spin mr-2"></i>Eszemélyi authentikáció folyamatban...</h5>
                </div>
            </div>
        </div>
    </div>
</div>

<div [ngClass]="{'hidden': eszigError === 0}" class="fullSizeCover">
    <div class="fullSizeCoverContent">
        <div class="col-md-12 mt-4">
            <div class="row">
                <div class="col-md-12 mt-4 text-center">
                    <h5><i class="fa fa-times-circle mr-2 orangered"></i>{{ eszigErrorMessage }}</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mt-4 text-center">
                    <button class="btn btn-success" (click)="onCloseEszigError()">Bezárás</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div [ngClass]="{'hidden': majorError == false}" class="fullSizeCover">
    <div class="fullSizeCoverContent">
        <div class="col-md-12 mt-4">
            <div class="row">
                <div class="col-md-12 mt-4 text-center">
                    <h5><i class="fa fa-times-circle mr-2 orangered"></i>{{ majorErrorMessage }}</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mt-4 text-center">
                    <button class="btn btn-success" (click)="onCloseMajorError()">Bezárás</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- window resize handler -->
<div #mainWindowSize (window:resize)="onResize($event)">
</div>
<header>
    <nav class="navbar navbar-dark navbar-expand-md navbar-fixed-top bg-naucare py-0" [ngClass]="{
      'bg-test-environment': apiUrl == 'https://ekrest.nauticom.hu' && authService.getBrand() != 'UDMED',
      'bg-decisio': Brand == 'DECISIO',
      'bg-udmed': authService.getBrand() == 'UDMED'
    }">
        <div class="navbar_brand">
            <img *ngIf="Brand == 'DOLGEK'" src="/assets/nauCare_logo_white.png" height="50" class="d-inline-block align-top" alt="">
            <img *ngIf="Brand == 'NAUCARE'" src="/assets/nauCare_logo_white.png" height="50" class="d-inline-block align-top" alt="">
            <img *ngIf="Brand == 'MEDCSEPP'" src="/assets/medcsepp-logo.png" height="50" class="d-inline-block align-top" alt="">
            <img *ngIf="Brand == 'DECISIO'" src="/assets/decisio-logo.png" height="50" class="d-inline-block align-top" alt="">
            <img *ngIf="Brand == 'UDMED'" src="/assets/UDHS-kor-wht.svg" height="50" class="d-inline-block align-top" alt="">
            <!-- <div *ngIf="Brand == 'MEDCSEPP'">MCS</div> -->

        </div>

        <form *ngIf="Brand !== 'DOLGEK'" [formGroup]="baseSettings" class="form-inline mr-auto ml-2 mt-2 mb-2">
            <!-- <input name="selecteddate" class="form-control mb-1 mr-1 input-auto-width" type="date" required formControlName="selecteddate"
            max="{{ dateMax }}"> -->
            <!-- <div class="input-group mr-1" *ngIf="Brand !== 'NauLight'">
        <input class="form-control datepicker_mobile main_datepicker" placeholder="yyyy-mm-dd" name="dp" [minDate]="{year: 1900, month: 1, day: 1}"
          formControlName="selecteddate" ngbDatepicker #d="ngbDatepicker" max="{{ dateMax }}"
          [ngClass]="{'date-invalid': dateInvalid === true}" [disabled]="Brand === 'MEDCSEPP'">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"
            [disabled]="Brand === 'MEDCSEPP'"></button>
        </div>
      </div> -->

            <!-- <mat-form-field appearance="fill" *ngIf="Brand !== 'NauLight'" class="input-group mr-1 my-auto" style="margin-bottom: -1.25em; padding: 0px !important; background-color: white; border-radius: 4px;">
        <mat-label>yyyy.mm.dd</mat-label>
        <input formControlName="selecteddate" [ngClass]="{'date-invalid': dateInvalid === true}" matInput [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="Brand === 'MEDCSEPP'"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field> -->

            <div class="d-flex" style="background-color: white;  border-radius: 4px;" *ngIf="Brand !== 'NauLight'">
                <mat-form-field appearance="fill" class="input-group my-auto" style="width: 40px;margin-bottom: -1.25em; padding: 0px !important; background-color: white; border-radius: 4px;">
                    <mat-label>év</mat-label>
                    <input [ngClass]="{'date-invalid': dateInvalid === true}" (keyup.enter)="onBaseSelecteddateSubmit()" #year [formControl]="selectedDateYear" matInput>
                </mat-form-field>
                <span class="my-auto">&ndash;</span>
                <mat-form-field appearance="fill" class="input-group my-auto" style="width: 31px;margin-bottom: -1.25em; padding: 0px !important; background-color: white; border-radius: 4px;">
                    <mat-label>hó</mat-label>
                    <input [ngClass]="{'date-invalid': dateInvalid === true}" (keyup.enter)="onBaseSelecteddateSubmit()" #month [formControl]="selectedDateMonth" matInput>
                </mat-form-field>
                <span class="my-auto">&ndash;</span>
                <mat-form-field appearance="fill" class="input-group my-auto" style="width: 31px;margin-bottom: -1.25em; padding: 0px !important; background-color: white; border-radius: 4px;">
                    <mat-label>nap</mat-label>
                    <input [ngClass]="{'date-invalid': dateInvalid === true}" (keyup.enter)="onBaseSelecteddateSubmit()" #day [formControl]="selectedDateDay" matInput>
                </mat-form-field>
                <input [ngClass]="{'date-invalid': dateInvalid === true}" formControlName="selecteddate" style="visibility: hidden; width: 1px;" matInput [matDatepicker]="picker">
                <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="Brand === 'MEDCSEPP'"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </div>



            <button type="button" class="btn ml-1 mr-1 mb-1 d-md-block small-button transparent-button" [ngClass]="{'active': calendarDisplayMode===2, 'btn-secondary': calendarDisplayMode !== 2}" *ngIf="Brand !== 'MEDCSEPP'  && Brand !== 'NauLight'" title="2 Munkalista panel megjelenítése"
                (click)="toggleCalendarDisplayMode(2)">
        <i class="fa fa-columns"></i>
      </button>
            <button type="button" class="btn mr-1 mb-1 d-md-block small-button transparent-button" [ngClass]="{'active': calendarDisplayMode==1, 'btn-secondary': calendarDisplayMode!==1}" *ngIf="Brand !== 'MEDCSEPP'  && Brand !== 'NauLight'" title="1 Munkalista panel megjelenítése"
                (click)="toggleCalendarDisplayMode(1)">
        <i class="fa fa-window-maximize"></i>
      </button>
            <button *ngIf="sharedDataService.getSelectedRole()['roleCode'].includes('AUTH$PL-ECHOKFT') ||
            sharedDataService.getSelectedRole()['roleCode'].includes('AUTH$SZTVINCENT') ||
            sharedDataService.getSelectedRole()['roleCode'].includes('AUTH$BJK-BIZTOSJOVO') ||
            sharedDataService.getSelectedRole()['roleCode'].includes('AUTH$BJK-EKMED')" type="button" class="btn mr-1 mb-1 d-md-block small-button transparent-button" [ngClass]="{'active': calendarDisplayMode===3, 'btn-secondary': calendarDisplayMode!==3}"
                title="Több munkalista panel megjelenítése" (click)="toggleCalendarDisplayMode(3)">
      <i class="fa fa-th"></i></button>
            <button *ngIf="sharedDataService.getSelectedRole()['roleCode'].includes('AUTH$PL-ECHOKFT') ||
            sharedDataService.getSelectedRole()['roleCode'].includes('AUTH$SZTVINCENT') ||
            sharedDataService.getSelectedRole()['roleCode'].includes('AUTH$BJK-BIZTOSJOVO') ||
            sharedDataService.getSelectedRole()['roleCode'].includes('AUTH$BJK-EKMED')" type="button" class="btn mr-1 mb-1 d-md-block small-button transparent-button" [ngClass]="{'active': calendarDisplayMode===4, 'btn-secondary': calendarDisplayMode!==4}"
                title="Heti több munkalista panel megjelenítése" (click)="toggleCalendarDisplayMode(4)">
      <i class="fa fa-calendar"></i></button>
        </form>
        <div *ngIf="Brand == 'DOLGEK'" class="form-inline mr-auto ml-2 mt-2 mb-2">

        </div>


        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

        <div class="collapse navbar-collapse navbar-right right-pos-fix" id="navbarSupportedContent">
            <div *ngIf="Brand == 'MEDCSEPP'" class="navbar-medcsepp-role">
                {{medcsepp_role}}
            </div>
            <form [formGroup]="roleSelect" class="form-inline my-2 my-lg-0">
                <!-- <ng-select [searchable]="true" formControlName="role" placeholder="Munkahely és szerepkör"
          [items]="authService.getUserData()['servicePoints']" bindLabel="displayName" bindValue="spAndRoleCode"
          [loading]="false" [clearable]="false" class="mr-1 mb-1 max-width-fix" title="Szerepkör választás"
          loadingText="Betöltés..." notFoundText="Nincs találat">
        </ng-select> -->

                <mat-form-field class="mr-1 my-auto max-width-fix" appearance="fill" style="margin-bottom: -1.25em; background-color: white; border-radius: 4px;">
                    <mat-label>Munkahely és szerepkör</mat-label>
                    <mat-select #select formControlName="role">
                        <mat-select-filter [array]="authService.getUserData()['servicePoints']" *ngIf="select.focused" [noResultsMessage]="'nincs találat!'" [displayMember]="'displayName'" (filteredReturn)="filteredVariables = $event"></mat-select-filter>
                        <mat-option *ngFor="let servicePoint of filteredVariables" [value]="servicePoint.spAndRoleCode">
                            {{servicePoint.displayName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <button *ngIf="Brand !=='DOLGEK'" type="button" class="btn mr-1 mb-1 small-button transparent-button" data-toggle="modal" data-target="#paciensreg" data-backdrop="static" data-keyboard="false" (click)="setRealignInkBar()" title="Páciensek kezelése">
          <i class="fa fa-user"></i>
        </button>
                <!-- <button *ngIf="Brand ==='MEDCSEPP' && Feature === 'FULL'" type="button" class="btn btn-light mr-1 mb-1"
          data-toggle="modal" data-target="#medcsepp-worklist" data-backdrop="static" data-keyboard="false"
          title="Labor mintalista">
          <i class="fa fa-flask"></i>
        </button> -->
                <button *ngIf="Brand =='DOLGEK'" type="button" class="btn btn-light mr-1 mb-1 small-button" data-toggle="modal" data-target="#paciensreg" data-backdrop="static" data-keyboard="false" title="Dolgozók kezelése">
          <i class="fa fa-user"></i>
        </button>
                <button *ngIf="Brand !=='DOLGEK'  && Brand !== 'NauLight'" type="button" class="btn mr-1 mb-1 small-button transparent-button" data-backdrop="static" data-keyboard="false" data-toggle="modal" data-target="#idopontkezelo" title="Előjegyzés">
          <i class="fa fa-calendar"></i>
        </button>
                <button type="button" class="btn btn-danger mr-1 mb-1 small-button" data-toggle="modal" data-target="#idopontkezelo-heti" title="Előjegyzés heti" *ngIf="environment.weeklyBookingViewEnabled">
          <i class="fa fa-calendar"></i>
        </button>
                <button type="button" class="btn mr-1 mb-1 small-button transparent-button" data-toggle="modal" data-target="#settings" title="Beállítások" (click)="realignInkBar = !realignInkBar">
          <i class="fa fa-cogs"></i>
        </button>
                <button type="button" class="btn mr-1 mb-1 small-button transparent-button" (click)="openHelp('#dashboard')" title="Kézikönyv">
          <i class="fa fa-question-circle"></i>
        </button>
                <button *ngIf="openEkatCounter  && Brand !== 'NauLight'" type="button" style="color: red;" disabled [ngClass]="openEkatCounter > 0 ? 'flashingButton btn btn-light  mr-1 mb-1' : 'btn btn-light mr-1 mb-1 normalButton'" class="small-button" title="folyamatban lévő ellátások">
          {{openEkatCounter}}
        </button>
                <button type="button" class="btn mr-1 mb-1 button-counter small-button transpaxrent-button" data-toggle="modal" data-target="#eesztmessagelist" [ngClass]="{'btn-light': bcpMessageCountError ==0 && bcpMessageCountNew ==0,
            'btn-warning': bcpMessageCountError == 0 && bcpMessageCountNew > 0,
            'btn-danger': bcpMessageCountError > 0}" *ngIf="environment.eesztEnabled == true  && Brand !== 'NauLight'" (click)="loadBcpMessages()" title="Eszig login">
          <i class="fa fa-cloud"></i>
          {{bcpCount}}
        </button>
                <!-- <button type="button" class="btn mr-1 mb-1" (click)="initEszig()" *ngIf="environment.eesztEnabled == true"
          [ngClass]="{'btn-success': eszigTokenValid && isEesztUser, 'btn-dark': !eszigTokenValid && isEesztUser}"
          [disabled]="!isEesztUser">
          E
        </button> -->
                <button type="button" class="btn mr-1 mb-1 small-button transparent-button" (click)="open(authContent)" *ngIf="environment.eesztEnabled == true" [ngClass]="{'btn-success': eszigTokenValid && isEesztUser, 'btn-dark': !eszigTokenValid && isEesztUser}" [disabled]="!isEesztUser">
          <fa-icon [icon]="faKey"></fa-icon>
          <span *ngIf="timeLeft">{{timeLeft/1000 | counter}}</span>
        </button>

                <button type="button" class="btn mr-4 mb-1 small-button transparent-button" (click)="onLogout()" title="{{ this.authService.getUserData()['surname'] }}
                {{ this.authService.getUserData()['forename'] }}">
          <span class="small mr-1 username">{{ this.authService.getUserData()['surname'] }}
            {{ this.authService.getUserData()['forename'] }}</span><i class="fa fa-sign-out"></i>
        </button>
            </form>
        </div>
    </nav>
</header>


<ng-template #authContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Hogyan szeretné magát authentikálni?</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click'); otpTokenFormGroup.reset(); mobileTokenFormControl.reset()">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="container">
        <div class="row justify-content-around">
            <div class="col-sm-3 text-center bg-info rounded mt-2 mb-2 d-flex flex-column">
                <img src="../../assets/auth_images/kartyaolaso.png" class="mt-2 mx-auto" width="120px" height="120px"> <br>
                <span>e-Személyi olvasóval történő azonosítás</span> <br>
                <button class="btn btn-dark mb-2 mt-auto" (click)="initEszig()"><i *ngIf="AuthInProgress" class="fa fa-spinner fa-spin"></i>Authentikálás</button>
            </div>
            <div class="col-sm-3 text-center bg-info rounded mt-2 mb-2 d-flex flex-column">
                <img src="../../assets/auth_images/mobiltoken.png" class="mt-2 ml-auto mr-auto" width="120px" height="120px"> <br>
                <span>mobiltokenes azonosítás</span> <br>
                <input type="text" [ngClass]="{'invalidInput': this.mobileTokenFormControl.touched && this.mobileTokenFormControl.invalid}" class="form-control" placeholder="token" [formControl]="mobileTokenFormControl"> <br>
                <button class="btn btn-dark mt-auto mb-2" (click)="initMobilToken()"><i *ngIf="AuthInProgress" class="fa fa-spinner fa-spin"></i>Authentikálás</button>
            </div>
            <div class="col-sm-3 text-center bg-info rounded mt-2 mb-2 d-flex flex-column" [formGroup]="otpTokenFormGroup">
                <img src="../../assets/auth_images/otp.png" class="mt-2 ml-auto mr-auto" width="120px" height="120px"><br>
                <span>otp tokenes azonosítás</span> <br>
                <input type="text" [ngClass]="{'invalidInput': this.otpTokenFormGroup.controls.otpTokenUserNameFormControl.touched &&
                                                       this.otpTokenFormGroup.controls.otpTokenUserNameFormControl.invalid}" formControlName="otpTokenUserNameFormControl" class="form-control" placeholder="felhasználónév"> <br>
                <input type="password" [ngClass]="{'invalidInput': this.otpTokenFormGroup.controls.otpTokenPasswordFormControl.touched &&
                                                       this.otpTokenFormGroup.controls.otpTokenPasswordFormControl.invalid}" formControlName="otpTokenPasswordFormControl" class="form-control" placeholder="jelszó"> <br>
                <input type="text" [ngClass]="{'invalidInput': this.otpTokenFormGroup.controls.otpTokenTokenFormControl.touched &&
                                                       this.otpTokenFormGroup.controls.otpTokenTokenFormControl.invalid}" formControlName="otpTokenTokenFormControl" class="form-control" placeholder="token"> <br>
                <button class="btn btn-dark mt-auto mb-2" (click)="initOtpToken()"><i *ngIf="AuthInProgress" class="fa fa-spinner fa-spin"></i> Authentikálás</button>
            </div>
        </div>
    </div>
</ng-template>

<app-booking-weekly *ngIf="environment.weeklyBookingViewEnabled"></app-booking-weekly>
<!-- Modal -->
<div *ngIf="!isLoading" class="main-holder" [ngClass]="{'main-holder-fixed': allAppointment === 4 }">
    <app-book-appointment [patientData]="patientDataBookAppointment" [appointmentData]="appointmentDataForBooking">
    </app-book-appointment>
    <!-- <app-booking-weekly></app-booking-weekly> -->
    <app-register-patient [patientData]="patientDataRegisterPatient" [realignInkBar]="realignInkBar"></app-register-patient>
    <app-arrive-patient [patientData]="patientDataArrivePatient" [setPatientCalendarDetail]="patientCalendarDetail"></app-arrive-patient>
    <app-arrive-patient-simple [patientData]="patientDataArrivePatientSimple"></app-arrive-patient-simple>
    <app-discard-patient [patientData]="patientDataDiscardPatient"></app-discard-patient>
    <app-discard-patient-simple [patientData]="patientDataDiscardPatientSimple"></app-discard-patient-simple>
    <app-documentation [patientData]="patientDataDocumentation"></app-documentation>
    <app-eeszt-message-list-modal *ngIf="environment.eesztEnabled == true" [eesztMessageList]="eesztMessageList">
    </app-eeszt-message-list-modal>
    <app-lab-order [patientData]="patientDataLabOrder"></app-lab-order>
    <app-eeszt-epro [patientData]="patientDataEesztEpro" *ngIf="environment.eesztEnabled"></app-eeszt-epro>
    <app-medcsepp-worklist *ngIf="Brand === 'MEDCSEPP'"></app-medcsepp-worklist>
    <app-settings [realignInkBar]="realignInkBar"></app-settings>

    <div class="container-wide content">

        <!-- Medcsepp Light & Full Dispatcher -->

        <div class="row">
            <div class="col-6">
                <app-dispatcher *ngIf="Brand === 'MEDCSEPP'"></app-dispatcher>
            </div>
            <div class="col-6">
                <app-lab-sample *ngIf="Brand === 'MEDCSEPP' && authService.getFeature() == 'FULL'" [patientData]="patientDataLabSample"></app-lab-sample>
            </div>
        </div>

        <!-- Medcsepp Light & Full Dispatcher -->


        <!-- Full calendar based medcsepp worklfow -->

        <div class="row" *ngIf="!availServiceEmpty &&  Brand !== 'DOLGEK' && Brand !== 'MEDCSEPP' && Brand !== 'NauLight'">
            <app-calendar *ngIf="calendarDisplayMode < 3" [ngClass]="{'col-md-6 two-columns': calendarDisplayMode === 2 && Brand !== 'MEDCSEPP',
      'col-md-8 two-columns': calendarDisplayMode == 2 && Brand == 'MEDCSEPP', 'col-md-12': calendarDisplayMode !== 2}" calendarID="1" [twoColumns]="calendarDisplayMode === 2"></app-calendar>
            <app-calendar class="col-md-6 two-columns second-column" calendarID="2" *ngIf="calendarDisplayMode == 2" [twoColumns]="calendarDisplayMode === 2">
            </app-calendar>

        </div>

        <app-multicalendar *ngIf="calendarDisplayMode === 3"></app-multicalendar>
        <app-multicalendar-week *ngIf="calendarDisplayMode === 4"></app-multicalendar-week>


        <div *ngIf="Brand === 'NauLight'">
            <app-ebp-booking></app-ebp-booking>
        </div>

        <!-- <div class="row" *ngIf="!availServiceEmpty">
      <app-calendar-lab-sample [ngClass]="{'col-md-6 two-columns': twoColumns && Brand !== 'MEDCSEPP',
    'col-md-8 two-columns': twoColumns && Brand == 'MEDCSEPP', 'col-md-12': !twoColumns}" calendarID="1"
        [twoColumns]="twoColumns"></app-calendar-lab-sample>
    </div> -->

        <!-- Full calendar based medcsepp worklfow -->


        <div class="row" *ngIf="availServiceEmpty && Brand !== 'DOLGEK'">
            A kiválasztott szerepkörhöz nem tartozik szolgáltatási pont.
        </div>

        <div class="row" *ngIf="Brand ==='DOLGEK'">
            <app-dolg-ek-main class="full-width"></app-dolg-ek-main>
        </div>

    </div>
</div>

<div *ngIf="isLoading && Brand !== 'DOLGEK'" class="main-holder">
    <div class="container-wide content">
        <div class="osahanloading"></div>
        <div class="osahanloadingcontent">Loading...</div>
    </div>
</div>

<div class="footer-udmed" *ngIf="authService.getBrand() === 'UDMED'">
    <div class="footer–udmed-right">
        <span class="footer-item">
      <a href="https://klinikaikozpont.unideb.hu" target="_blank">Klinikai központ</a>
    </span>
        <span class="footer-item">
      <a href="https://kenezykorhaz.unideb.hu" target="_blank">Kenézy Gyula Egyetemi Kórház</a>
    </span>
        <span class="footer-item footer-item-last">
      <a href="https://szolgaltatasok.udmed.hu" target="_blank">Kapcsolat</a>
    </span>
    </div>
    <div class="footer–udmed-left">
        <div class="footer-udmed-logo">
            <img src="/assets/de_eszolg_logo.svg" height="56" class="d-inline-block align-top" alt="">
        </div>
    </div>
</div>
