import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoggerService } from '../services/logger.service';
import { environment } from '../../environments/environment';
import { UUID } from 'uuid';

declare var require: any;

@Injectable()
export class AuthService {

  uuid: string;

  apiUrl = environment.apiUrl;

  token: string;

  userData = null;

  hostname = '';

  brand = 'NAUCARE';

  feature = '';

  constructor(private http: HttpClient,
    private loggerService: LoggerService
  ) { }


  doLogin(username: string, password: string) {
    return this.http.get(this.apiUrl + '/rest/neu/authentication/loginusermobile/' +
      username + '/' + password + '/' + this.uuid + '/hu');
    // return this.http.get(this.apiLoginURL2);
  }

  doLogout() {
    this.userData = null;
    localStorage.removeItem('userData');
    localStorage.removeItem('config');
    localStorage.removeItem('eszigTokenExpiration');
  }


  setUserData(userData: object) {
    const sessionExpireTimestamp = new Date().getTime() + environment.sessionValidity;
    userData['user']['isEesztUser'] = userData['isEesztUser'];
    this.userData = userData['user'];
    if (this.brand === 'UDMED') {
      const filteredSpRole = [];
      userData['servicePoints'].forEach((spdata) => {
        if (
          spdata['roleCode'] === 'AUTH$KENEZY_DEKK_DOCTOR' ||
          spdata['roleCode'] === 'AUTH$KENEZY_VDOCTOR' ||
          spdata['roleCode'] === 'AUTH$KENEZY_SCC' ||
          spdata['roleCode'] === 'AUTH$KENEZY_TCC' ||
          spdata['roleCode'] === 'AUTH$UDMED-VIRT_ADMIN' ||
          spdata['roleCode'] === 'AUTH$UDMED-VIRT_CCT' ||
          spdata['roleCode'] === 'AUTH$UDMED-VIRT_DOCTOR' ||
          spdata['roleCode'] === 'AUTH$UDMED-VIRT_CCS'
          ) {
            spdata['displayName'] = spdata['roleName'] + ' ' + spdata['spName'];
            spdata['spAndRoleCode'] = spdata['spCode'] + spdata['roleCode'];
            filteredSpRole.push(spdata);
          }
      });
      this.userData['servicePoints'] = filteredSpRole;
    } else {
      userData['servicePoints'].forEach((spdata) => {
        spdata['displayName'] = spdata['roleName'] + ' ' + spdata['spName'];
        spdata['spAndRoleCode'] = spdata['spCode'] + spdata['roleCode'];
      });
      this.userData['servicePoints'] = userData['servicePoints'];
    }
    localStorage.setItem('userData', JSON.stringify(this.userData));
    localStorage.setItem('sessionExpireTimestamp', sessionExpireTimestamp.toString());
  }

  // 6: {code: "AUTH$KENEZY_VDOCTOR", name: "Orvos (UDMED Virt)"}
  // 7: {code: "AUTH$KENEZY_SCC", name: "Szakmai CC (UDMED Virt)"}
  // 8: {code: "AUTH$KENEZY_TCC", name: "Technikai CC (UDMED Virt)"}

  setEesztSessionExpiration() {

  }

  getUserData() {
    return this.userData;
  }

  checkStoredCredential() {
    // console.log('stored userdata');
    // console.log(localStorage.getItem('userData'));
    if (localStorage.getItem('userData')) {
      this.userData = JSON.parse(localStorage.getItem('userData'));
    }
  }

  setUUID() {

    if (localStorage.getItem('nauCareUUID')) {
      this.uuid = localStorage.getItem('nauCareUUID');
    } else {
      const uuidv4 = require('uuid/v4');
      this.uuid = uuidv4();
      localStorage.setItem('nauCareUUID', this.uuid);
    }
  }

  getUUID() {
    return this.uuid;
  }

  getNonce() {
    const uuidv4 = require('uuid/v4');
    return (uuidv4());
  }

  setHostname(hostname) {
    this.hostname = hostname;
  }

  getHostname() {
    return this.hostname;
  }


  setBrand(value: string) {
    this.brand = value;
    // console.log('BRAND set:', value);
  }

  getBrand() {
    // return 'BJK';
    return this.brand;
  }

  setFeature(value: string) {
    this.feature = value;
    // console.log('BRAND set:', value);
  }

  getFeature() {
    // return 'LIGHT' / 'FULL' Medcsepp;
    return this.feature;
  }

}
