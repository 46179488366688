<div class="row mt-4 main-menu">
  <div class="col-6 mb-4">
    <div class="card text-center main-menu-item"  data-toggle="modal" data-target="#mozgas">
      <div class="card-body">
        <div class="main-menu-icon">
          <i class="fa fa-sign-in"></i>
        </div>
        <h5 class="card-title">Mozgás lekérdezése</h5>
      </div>
    </div>
  </div>
  <div class="col-6 mb-4">
    <div class="card text-center main-menu-item"  data-toggle="modal" data-target="#jelenlet">
      <div class="card-body">
        <div class="main-menu-icon">
          <i class="fa">&#xf017;</i>
        </div>
        <h5 class="card-title">Jelenlét lekérdezése</h5>
      </div>
    </div>
  </div>
</div>
<div class="row mt-4 main-menu">
  <div class="col-6 mb-4">
    <div class="card text-center main-menu-item"  data-toggle="modal" data-target="#dolgPermission">
      <div class="card-body">
        <div class="main-menu-icon">
          <i class="fa fa-user"></i>
        </div>
        <h5 class="card-title">Dolgozói áthaladási szabályok</h5>
      </div>
    </div>
  </div>
  <div class="col-6 mb-4">
    <div class="card text-center main-menu-item"  data-toggle="modal" data-target="#dolgGroupPermission">
      <div class="card-body">
        <div class="main-menu-icon">
          <i class="fa fa-users"></i>
        </div>
        <h5 class="card-title">Csoport áthaladási szabályok</h5>
      </div>
    </div>
  </div>
</div>
<app-dolg-mozgas></app-dolg-mozgas>
<app-dolg-group-permission></app-dolg-group-permission>
<app-dolg-jelenlet></app-dolg-jelenlet>
<app-dolg-permission></app-dolg-permission>