import { Component, Input, OnInit, OnDestroy, Injectable, OnChanges, SimpleChanges } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { SharedDataService } from '../services/shared-data.service';
import { LoggerService } from '../services/logger.service';
import { FormsModule } from '@angular/forms';
import { CalendarComponent } from '../calendar/calendar.component';
import { Subscription } from 'rxjs';
import { SelectedDateRange } from '../shared/models/selectedDateRange';

@Component({
    selector: 'ngbd-datepicker',
    templateUrl: './datepicker.html',
    styleUrls: ['./datepicker.css']
})
export class NgbdDatepickerMultiple implements OnInit, OnChanges, OnDestroy {

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate;
  toDate: NgbDate | null = null;

    @Input() selectedDateRange: SelectedDateRange = {
      dateFrom: null,
      dateTo: null
    };
    @Input() displayMonths = 2;
    navigation = 'select';
    showWeekNumbers = false;
    outsideDays = 'visible';
    datepickerDisabled = false;

    datepickerModel: NgbDateStruct;

    dateSubscription: Subscription;
    dateFrom = null;

    constructor(private calendar: NgbCalendar,
        private sharedDataService: SharedDataService,
        private loggerService: LoggerService
    ) {}

    ngOnInit() {
        this.dateSubscription = this.sharedDataService.bookingDateChange.subscribe((date: string) => {
            if (this.dateFrom !== date) {
                this.dateFrom = date;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
      for (const propName in changes) {
        if (changes.hasOwnProperty(propName)) {
          switch (propName) {
            case 'selectedDateRange': {
              if (this.selectedDateRange) {
                this.fromDate = this.selectedDateRange.dateFrom;
                this.toDate = this.selectedDateRange.dateTo;
                /* this.datepickerModel = this.dateFrom; */
              }
            }
          }
        }
      }
    }

    ngOnDestroy() {
        this.dateSubscription.unsubscribe();
    }

    isAvailable(date: NgbDate) {
        const dayResult = this.sharedDataService.returnCalendarDataforDay(date);
        if (dayResult) {
            if (dayResult['eventFree'] < dayResult['eventMax']) {
                return false;
            } else {
                return true;
            }
            // console.log(date);
            // console.log(dayResult);
        }
    }

    dayStatus(date: NgbDate) {
        const dayResult = this.sharedDataService.returnCalendarDataforDay(date);
        const dateString = date.year + '-' + ('0' + (date.month)).slice(-2) + '-' + ('0' + date.day).slice(-2);
        let dayClass = '';

        if (dayResult) {
            dayClass = 'calendar_' + dayResult['colorCode'].toLowerCase();
        }

        if (dateString === this.dateFrom) {
            dayClass += ' selected-day';
        }

        return dayClass;
    }

    dateChanged(date: NgbDate) {

        const dateString = date.year + '-' + ('0' + (date.month)).slice(-2) + '-' + ('0' + date.day).slice(-2);
        // console.log('Datepicker change:' + dateString);
        const newDate = new Date(dateString);
        this.sharedDataService.setBookingDate(newDate);
    }


    reset() {
        // console.log('datepicker reset');
        // console.log(this.datepickerModel);
    }

    onDateSelection(date: NgbDate) {      
      if (!this.fromDate && !this.toDate) {
        this.fromDate = date;
      } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
        this.toDate = date;
      } else {
        this.toDate = null;
        this.fromDate = date;
      }
      let dateFrom = this.fromDate ? new Date(this.fromDate.year + '-' + ('0' + (this.fromDate.month)).slice(-2) + '-' + ('0' + (this.fromDate.day)).slice(-2)) : null;
      dateFrom.setDate(dateFrom.getDate() -1);
      let dateTo = this.toDate ? new Date(this.toDate?.year + '-' + ('0' + (this.toDate?.month)).slice(-2) + '-' + ('0' + (this.toDate?.day)).slice(-2)) : null;
      dateTo?.setDate(dateTo.getDate() - 1);
      if (((dateTo?.getTime() - dateFrom.getTime()) / (1000 * 3600 * 24)) > 8) {
        dateFrom = null;
        dateTo = null;
      }
      console.log('datefrom: ', dateFrom, ' dateTo: ', dateTo);
      this.sharedDataService.setBookingDateRange(dateFrom, dateTo);
    }

    isHovered(date: NgbDate) {
      return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) {
      return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate) {
      return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
    }

    isStart(date: NgbDate) {
      return date.equals(this.fromDate);
    }

    isEnd(date: NgbDate) {
      if (this.toDate) {
        return date.equals(this.toDate);
      } else if (this.fromDate) {
        return date.equals(this.fromDate);
      }

    }

    isStartAndEnd(date: NgbDate) {
      if (date.equals(this.fromDate) && !this.toDate) {
        return true;
      } else {
        return false;
      }
    }

}


