<!-- Modal -->
<div class="modal" id="epro" tabindex="-1" role="dialog" aria-labelledby="epro" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <!--

    <div [ngClass]="{'hidden': !saveInProgress}" class="quitConfirmation">
      <div class="quitConfirmationContent">
        Adatbeküldés folyamatban...
      </div>
    </div> -->

    <div class="modal-content">
      <div class="modal-header" style="border-bottom: 0px">
        <!--
        <div id="saveSuccess" [ngClass]="{'showResult': processBcpResult == 0}">
          {{ successMessage }}
        </div>
        <div id="saveError" [ngClass]="{'showResult': processBcpResult == -1}">
          <div class="closeResult" (click)="closeResult()">x</div>
          <div class="saveErrorContent">
            <span>{{ processBcpErrorDescription }}</span>
          </div>
        </div> -->

        <h5 *ngIf="calendarDetail" class="modal-title" id="exampleModalLongTitle">eProfil (név: {{calendarDetail['participantSurname']}}, azonosító/érték: {{calendarDetail.participantSsnCode | ssnType}}/{{calendarDetail.participantSocialSecurityNumber}})</h5>
        <!-- <button class="btn btn-primary ml-2" (click)="sendBcpMessages()"
          [disabled]="!isEesztUser || selectedCount === 0">Beküldés</button>
        <button class="btn btn-primary ml-2" (click)="refreshBcpMessageList()"><i class="fa fa-spinner"></i></button> -->
        <div>
          <button type="button" class="btn btn-primary ml-1 mb-1" (click)="openHelp('#patient')" title="kézikönyv">
            <i class="fa fa-question-circle"></i>
          </button>
          <button type="button" class="btn btn-secondary ml-1 mb-1" data-dismiss="modal" (click)="resetEesztEproForm()">X</button>
        </div>
      </div>
      <div>
        <!-- <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab == 0}" (click)="switchTab(0)">Paciens adatai</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab == 1}" (click)="switchTab(1)">Paciens dokumentumai</a>
          </li>
        </ul> -->

      </div>

      <!-- <div>
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab == 0}" style="cursor: pointer;" (click)="switchTab(0)">lekérdezés</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab == 1}" style="cursor: pointer;" (click)="switchTab(1)">feltöltés</a>
          </li>
        </ul>
      </div> -->
      <div class="modal-body">
        <div class="container-fluid">
          <mat-tab-group #eproMainTabGroup [(selectedIndex)]="activeTab">
            <mat-tab (click)="switchTab(0)" label="lekérdezés">
              <ng-template matTabContent>
                <div *ngIf="activeTab == 0" class="col-md-12" style="min-height: 300px;">
                  <div class="epro_entry" *ngIf="calendarDetail">

                    <!-- <div class="mb-2">
                        <input type="checkBox" id="breakGlass" [formControl]="searchDataBreakGlass">
                        <label for="breakGlass" class="ml-1">sürgősségi adatlekérés</label>
                        <input type="text" class="form-control mb-2 w-50" [formControl]="searchDataBreakGlassReason">
                        <button class="btn btn-primary" (click)="searchEproAdat()">Lekérés</button>
                    </div> -->



                    <div class="row">
                      <div class="col-sm-6 form-group">
                          <input type="checkBox" id="breakGlass" [formControl]="searchDataBreakGlass">
                          <label for="breakGlass" class="ml-1">sürgősségi adatlekérés</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6 form-group">
                        <label for="breakGlassReason" class="ml-1">sürgősségi adatlekérés indoklása</label>
                        <input type="text" id="breakGlassReason" class="form-control" [formControl]="searchDataBreakGlassReason">
                      </div>
                      <div class="col-sm-6 form-group">
                        <div *ngIf="this.searchDataBreakGlass.value" class="alert alert-danger" role="alert" style="text-align: justify; text-justify: inter-word;">
                          A "sürgősségi adatlekérés" csak a következő esetekben használható jogszerűen:<br>
                          1.) Sürgős esetben, ha a páciens nem tud, nem képes eseti rendelkezést tenni. (Az Eüak. 10. § (4) bekezdése szerint sürgős szükség esetén a kezelést végző orvos által ismert, a gyógykezeléssel összefüggésbe hozható minden egészségügyi és személyazonosító adat továbbítható.) <br>
                          2.) A páciens 24 órás egyedi engedélye alapján (minta az e-egeszegugy.gov.hu oldalon letölthető).
                        </div>
                      </div>
                    </div>
                    <button class="btn btn-primary mb-1" (click)="searchEproAdat(true)">Lekérés</button>
                  </div>
                  <div *ngIf="isLoading">
                    <div class="osahanloading"></div>
                    <div class="loading-center">Loading eeszt message list...</div>
                  </div>

                  <div *ngIf="!isLoading">
                    <div class="row">
                      <div class="col-12">
                        <!--
                    <mat-table [dataSource]="dataSource" matSort id="print-section">
                      <ng-container matColumnDef="date">
                        <mat-header-cell *matHeaderCellDef> Dátum </mat-header-cell>
                        <mat-cell *matCellDef="let element"><span class="ellipsis">
                            {{element.eventTime | date: ' yyyy-MM-dd HH:mm:ss'}}</span> </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef> Név </mat-header-cell>
                        <mat-cell *matCellDef="let element"><span class="ellipsis"> {{element.name}}</span> </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="device">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Eszköz </mat-header-cell>
                        <mat-cell *matCellDef="let element"><span class="ellipsis"> {{element.deviceName}} </span></mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="picture">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Kép </mat-header-cell>
                        <mat-cell *matCellDef="let element"><span class="ellipsis" (click)="loadIpCamPicture(element)">
                            <i class="fa fa-camera"></i>
                          </span></mat-cell>
                      </ng-container>
                      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table> -->
                    <!-- <mgl-timeline
                      [toggle]="true"
                      [alternate]="false"
                      [side]="'right'"
                      [mobileWidthThreshold]="9999">
                        <mgl-timeline-entry *ngFor="let test of timelineTest; let i = index;" (expand)="onExpandEntry($event, i)">
                            <mgl-timeline-entry-header style="cursor: pointer;" (click)="onHeaderClick($event)">
                              2020-12-13 | teszt fejléc
                            </mgl-timeline-entry-header>
                            <mgl-timeline-entry-content
                                [expandAnimationTiming]="'200ms ease'"
                                [collapseAnimationTiming]="'100ms ease'">
                                <div class="row">
                                  <div class="col-sm-8">
                                    <div>
                                      <span>aktív:</span>
                                      </div>
                                      <div>
                                      </div>
                                      <div>
                                        <span>Megfigyelő orvos:</span>
                                      </div>
                                      <div>
                                        <span>Megfigyelő intézmény:</span>
                                      </div>
                                      <div>
                                        <span>Hitelesítő orvos:</span>
                                      </div>
                                      <div>
                                        <span>Bizonyosság:</span>
                                      </div>
                                      <div>
                                        <span>Egészségügyi dokumentum: </span>
                                      </div>
                                      <div>
                                        <span>Digitális önrendelkezési kategória:</span>
                                      </div>
                                      <div>
                                        <span>Magyarázat:</span>
                                      </div>
                                  </div>
                                  <div class="col-sm-4">
                                    <div
                                      *ngIf="isDeleteMode"
                                      class="alert alert-warning" style="height: 100%;">
                                        <div class="text-center">
                                          <div style="text-align: center; color: black; font-size: 25px;">Biztosan törölni szeretné?</div>
                                        </div>
                                          <div class="d-flex mt-auto justify-content-around">
                                            <div>
                                              <button class="btn btn-success" style="font-size: 20px;" (click)="isDeleteMode = false;" >igen</button>
                                            </div>
                                            <div>
                                              <button class="btn btn-danger" style="font-size: 20px;" (click)="isDeleteMode = false" >nem</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex float-right mt-1">
                                      <button
                                      *ngIf="!isDeleteMode"
                                      class="btn btn-primary mb-1"
                                      (click)="isDeleteMode = true">
                                        <fa-icon class="deleteEkatIcon" [icon]="faTrash"></fa-icon> bejegyzés törlése
                                    </button>
                                    <button
                                      *ngIf="!isDeleteMode"
                                      class="btn  btn-primary mb-1 ml-1">
                                        <fa-icon class="deleteEkatIcon" [icon]="faEdit"></fa-icon>bejegyzés módosítása
                                    </button>
                                    </div>
                                  </div>
                                </div>
                            </mgl-timeline-entry-content>
                            <mgl-timeline-entry-dot [size]="40" style="text-align: center; background-color: lightblue; color: darkblue"></mgl-timeline-entry-dot>
                            <mgl-timeline-entry-side>
                              2020-12-13
                            </mgl-timeline-entry-side>
                        </mgl-timeline-entry>
                    </mgl-timeline> -->
                    <mgl-timeline
                      *ngIf="eproProfilAdatokAdatkitoltessel.length > 0"
                      [toggle]="false"
                      [alternate]="false"
                      [side]="'right'"
                      [mobileWidthThreshold]="9999">
                        <mgl-timeline-entry *ngFor="let adat  of eproProfilAdatokAdatkitoltessel">
                            <mgl-timeline-entry-header style="cursor: pointer;" (click)="onHeaderClick($event)">
                              <span style="z-index: 99;">
                                {{adat.adatkitoltes.businessContent.letrehozasiIdopont | date: 'yyyy/MM/dd'}} | {{adat.adatList.profilAdatSzerkezettel[0].tipusId | filterTorElementName: torElementTypes.torElements.node.rows}} | Hitelesítő orvos: {{adat.adatkitoltes.businessContent.hitelesito}}
                              </span>
                            </mgl-timeline-entry-header>
                            <mgl-timeline-entry-content
                                [expandAnimationTiming]="'200ms ease'"
                                [collapseAnimationTiming]="'100ms ease'">
                                <div class="row">
                                  <div class="col-sm-8">
                                    <div>
                                      <span>aktív:
                                        <i class="fa fa-check-circle green mr-2" *ngIf="adat.aktiv"></i>
                                        <i class="fa fa-times-circle red mr-2" *ngIf="!adat.aktiv"></i>
                                      </span>
                                      </div>
                                      <div>
                                        <span> eProfil bejegyzés azonosítója: {{adat.adatkitoltes.businessObjectIdentification.eesztid.id}}</span>
                                      </div>
                                      <div>
                                        <span>Megfigyelő orvos: {{adat.adatkitoltes.businessContent.megfigyelo}}</span>
                                      </div>
                                      <div>
                                        <span>Megfigyelő intézmény: {{adat.adatkitoltes.businessContent.intezmenyId}}</span>
                                      </div>
                                      <div>
                                        <span>Bizonyosság: {{adat.adatkitoltes.businessContent.bizonyossag}}</span>
                                      </div>
                                      <div *ngIf="adat.adatkitoltes.businessContent.onrendelkezesiKategoria">
                                        <span>Digitális önrendelkezési kategória: {{adat.adatkitoltes.businessContent.onrendelkezesiKategoria}}</span>
                                      </div>
                                      <div *ngIf="adat.adatkitoltes.businessContent.leiras">
                                        <span>Magyarázat: {{adat.adatkitoltes.businessContent.leiras}}</span>
                                      </div>
                                      <div *ngFor="let adatListItem of adat['adatList']['profilAdatSzerkezettel']">
                                        <span>{{adatListItem.kulcs}}</span>
                                        <!-- <span>{{adatListItem.kulcs | w1250ToUtf8}}</span> -->
                                        <span>: </span>
                                        <span>{{adatListItem.ertek | resolveEesztTor :  adatListItem.businessObjectIdentification.eesztid.id }}</span>
                                        <!-- <span>{{adatListItem.ertek | w1250ToUtf8}}</span> -->
                                      </div>
                                  </div>
                                  <div class="col-sm-4">
                                    <div class="d-flex justify-content-around mt-1">
                                      <button
                                        *ngIf="!isDeleteMode"
                                        class="btn btn-primary mb-1"
                                        (click)="deleteEproModal(adat)">
                                          <fa-icon class="deleteEkatIcon" [icon]="faTrash"></fa-icon> bejegyzés törlése
                                      </button>
                                      <button
                                        *ngIf="!isDeleteMode && adat.aktiv"
                                        class="btn  btn-primary mb-1 ml-1"
                                        (click)="copyDataToSaveEpro(adat)">
                                          <fa-icon class="deleteEkatIcon" [icon]="faEdit"></fa-icon>bejegyzés módosítása
                                      </button>
                                    </div>
                                    <div class="d-flex" style="height: 100%;">
                                      <div *ngIf="isDeleteMode && eesztEproData === adat" class="alert alert-warning mt-auto mb-auto" style="padding: 4px; height: 50%; width: 100%;">
                                        <div style="text-align: center; color: black; font-size: 24x;">Biztosan törölni szeretné?</div>
                                        <div class="row d-flex justify-content-around" style="height: 100%;">
                                          <div class="mt-auto mb-auto">
                                            <button class="btn btn-success" (click)="isDeleteMode = false; deleteEpro(eesztEproData)" >igen</button>
                                          </div>
                                          <div class="mt-auto mb-auto">
                                            <button class="btn btn-danger" (click)="isDeleteMode = false" >nem</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </mgl-timeline-entry-content>
                            <mgl-timeline-entry-dot *ngIf="adat.aktiv" [size]="40" style="text-align: center; background-color: lightgreen; color: darkblue; cursor: pointer;"></mgl-timeline-entry-dot>
                            <mgl-timeline-entry-dot *ngIf="!adat.aktiv" [size]="40" style="text-align: center; background-color: rgb(214, 98, 98); color: darkblue; cursor: pointer;"></mgl-timeline-entry-dot>
                        </mgl-timeline-entry>
                    </mgl-timeline>
                      <!-- <div>
                        <div *ngFor="let adat  of eproProfilAdatokAdatkitoltessel" class="epro_entry">
                              <div>
                              <span>eProfil bejegyzés azonosítója: {{adat.adatkitoltes.businessObjectIdentification.eesztid.id}}</span>
                              <span>, aktív:
                                <i class="fa fa-check-circle green mr-2" *ngIf="adat.aktiv"></i>
                                <i class="fa fa-times-circle red mr-2" *ngIf="!adat.aktiv"></i>
                              </span>
                              </div>
                              <div>
                                <span>Bejegyzés ideje:
                                  {{adat.adatkitoltes.businessContent.letrehozasiIdopont | date: 'yyyy-MM-dd HH:mm:ss'}}</span>
                              </div>
                              <div>
                                <span>Megfigyelő orvos: {{adat.adatkitoltes.businessContent.megfigyelo}}</span>
                              </div>
                              <div>
                                <span>Megfigyelő intézmény: {{adat.adatkitoltes.businessContent.intezmenyId}}</span>
                              </div>
                              <div>
                                <span>Hitelesítő orvos: {{adat.adatkitoltes.businessContent.hitelesito}}</span>
                              </div>
                              <div>
                                <span>Bizonyosság: {{adat.adatkitoltes.businessContent.bizonyossag}}</span>
                              </div>
                              <div>
                                <span>Egészségügyi dokumentum: </span>
                              </div>
                              <div>
                                <span>Digitális önrendelkezési kategória: {{adat.adatkitoltes.businessContent.onrendelkezesiKategoria}}</span>
                              </div>
                              <div>
                                <span>Magyarázat: {{adat.adatkitoltes.businessContent.leiras}}</span>
                              </div>
                              <div *ngFor="let adatListItem of adat['adatList']['profilAdatSzerkezettel']">
                                <span>{{adatListItem.kulcs}}</span>
                                <span>{{adatListItem.kulcs | w1250ToUtf8}}</span>
                                <span>: </span>
                                <span>{{adatListItem.ertek | resolveEesztTor :  adatListItem.businessObjectIdentification.eesztid.id }}</span>
                                <span>{{adatListItem.ertek | w1250ToUtf8}}</span>
                              </div>
                              <button *ngIf="!isDeleteMode" class="btn btn-primary mb-1" (click)="deleteEproModal(adat)"><fa-icon class="deleteEkatIcon" [icon]="faTrash"></fa-icon> bejegyzés törlése</button>
                              <button *ngIf="!isDeleteMode && adat.aktiv" class="btn  btn-primary mb-1 ml-1" (click)="copyDataToSaveEpro(adat)"><fa-icon class="deleteEkatIcon" [icon]="faEdit"></fa-icon>bejegyzés módosítása</button>
                              <div *ngIf="isDeleteMode && eesztEproData === adat" class="alert alert-warning" style="padding: 4px;">
                                <div class="row">
                                  <div class="col-sm-8">
                                    <div style="text-align: center; color: black; font-size: 20px;">Biztosan törölni szeretné?</div>
                                  </div>
                                  <div class="col-sm-2">
                                    <button class="btn btn-success" (click)="isDeleteMode = false; deleteEpro(eesztEproData)" >igen</button>
                                  </div>
                                  <div class="col-sm-2">
                                    <button class="btn btn-danger" (click)="isDeleteMode = false" >nem</button>
                                  </div>
                                </div>
                              </div>
                        </div>
                      </div> -->
                        <!-- <ng-template #eproDelete let-modal>
                          <div class="modal-header">
                            <h4 class="modal-title" id="modal-basic-title">biztosan törölni szeretné?</h4>
                            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <div class="row justify-content-md-around">
                              <button class="col-sm-5 btn btn-success" (click)="modal.close('Save click')">igen</button>
                              <button class="col-sm-5 btn btn-danger" (click)="modal.close('Revert click')">nem</button>
                            </div>
                          </div>
                        </ng-template> -->

                        <!-- <div class="row" *ngIf="eesztMessageCount > 0">
                      <div class="col-md-3">Dátum</div>
                      <div class="col-md-2">Típus</div>
                      <div class="col-md-2">Státusz</div>
                      <div class="col-md-5">Leírás</div>

                    </div> -->

                        <!-- <div *ngIf="eesztMessageCount === 0" class="row">
                      <div class="col-md-12 mt-4">
                        <h4 class="text-center">Nincs megjeleníthető eeszt üzenet.</h4>
                      </div>
                    </div> -->

                        <!--
                    <div *ngFor="let eesztMessage of eesztMessageListData; let i = index">
                      <div class="row document-row" [ngClass]="{'eeszt-error': eesztMessage['msgStatus'] === 'ERROR'}">
                        <div class="col-md-3">
                          <input type="checkbox" (click)="itemChange(eesztMessage['transId'], $event, eesztMessage)" [checked]="eesztMessage['checked']">
                          {{ eesztMessage['msgTime'] | date:'yyyy-MM-dd H:mm' }}</div> <div
                            class="col-md-2">{{ eesztMessage['msgModul']}}</div>
                        <div class="col-md-2">{{ eesztMessage['msgStatus']}}</div>
                        <div class="col-md-5" *ngIf="eesztMessage['msgStatus'] === 'ERROR'">
                          faultCode: {{ eesztMessage['msgReplyObj']['eesztResponse']['faultCode'] }}
                          faultString: {{ eesztMessage['msgReplyObj']['eesztResponse']['faultString'] }}
                        </div>

                      </div>
                    </div> -->
                      </div>
                    </div>
                  </div>
              </div>
              <!-- <div class="row">
                <div class="col-xs-4">
                  <form  class="form-dropdown-select">
                    <label>csoport</label>
                    <ng-select [formControl]="groupFromControl" [searchable]="true" notFoundText="Nincs találat">
                    </ng-select>
                  </form>
                  <form  class="form-dropdown-select">
                    <label>csoport</label>
                    <ng-select [formControl]="groupFromControl" [searchable]="true" notFoundText="Nincs találat">
                    </ng-select>
                  </form>
                  <form  class="form-dropdown-select">
                    <label>csoport</label>
                    <ng-select [formControl]="groupFromControl" [searchable]="true" notFoundText="Nincs találat">
                    </ng-select>
                  </form>
                </div>
              </div> -->
              </ng-template>
            </mat-tab>
            <mat-tab (click)="switchTab(1)" label="feltöltés">
              <ng-template matTabContent>
                <div class="col-ms-12" *ngIf="activeTab == 1">
                  <div class="row mb-1">
                      <div class="col-sm-4">
                        <form  class="form-dropdown-select" style="width: 100%;">
                          <label>önrendelkezési kategória</label>
                          <ng-select [formControl]="dorFormControl" [items]="torCategories" bindValue="kod" bindLabel="nev" [searchable]="true" notFoundText="Nincs találat">
                          </ng-select>
                        </form>
                      </div>
                      <div class="col-sm-4">
                        <form  class="form-dropdown-select" style="width: 100%;">
                          <label> <b> bizonyosság *</b></label>
                          <ng-select [ngClass]="{'invalidForm': certainatyFormControl.touched && !certainatyFormControl.valid}"
                                     [formControl]="certainatyFormControl" [items]="torCert" bindValue="kod" bindLabel="nev" [searchable]="true" notFoundText="Nincs találat">
                          </ng-select>
                        </form>
                      </div>
                      <div class="col-sm-4">
                        <label for="detail">leírás</label>
                        <input [formControl]="detailFormControl" id="detail" class="form-control" type="text">
                      </div>
                  </div>
                  <div class="row" style="margin-bottom: 15px;" *ngIf="showEhrFields" [formGroup]="ehrIdFormGroup">
                    <div class="col-sm-6">
                      Ehr Id
                      <div style="padding: 0px;">
                        <input type="text" disabled class="form-control" formControlName="ehrId">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      Ehr verzió
                      <div style="padding: 0px;">
                        <input type="text" disabled class="form-control" formControlName="ehrVersion">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="col-sm-12">
                        <div class="row form-group">
                          <form  class="form-dropdown-select" style="width: 100%;">
                            <label> <b> csoport *</b></label>
                            <ng-select [ngClass]="{'invalidForm': groupFromControl.touched && !groupFromControl.valid}"
                                       [formControl]="groupFromControl" bindLabel="nev" [searchable]="true" [items]="groupItems" [loading]="" (clear)="clearForms()" loadingText="Betöltés..." notFoundText="Nincs találat">
                            </ng-select>
                          </form>
                        </div>
                        <div class="row form-group">
                          <form class="form-dropdown-select" style="width: 100%;">
                            <label><b>típus *</b></label>
                            <ng-select [ngClass]="{'invalidForm': typeFormControl.touched && !typeFormControl.valid}"
                                       [formControl]="typeFormControl" bindLabel="nev" [searchable]="true" [items]="typeItems" [loading]="" (clear)="this.visualDetailFieldWithFormControl = [];" loadingText="Betöltés..." notFoundText="Nincs találat">
                            </ng-select>
                          </form>
                        </div>

                        <div *ngFor="let adat  of eproToModifyDataSource" class="epro_entry_modify alert alert-primary w-100">
                          <div class="row justify-content-between">
                            <div class="col-sm-10">
                              <div>
                                <span>eProfil bejegyzés azonosítója: {{adat.adatkitoltes.businessObjectIdentification.eesztid.id}}</span>
                                <span>, aktív:
                                  <i class="fa fa-check-circle green mr-2" *ngIf="adat.aktiv"></i>
                                  <i class="fa fa-times-circle red mr-2" *ngIf="!adat.aktiv"></i>
                                </span>
                                </div>
                                <div>
                                  <span>Bejegyzés ideje:
                                    {{adat.adatkitoltes.businessContent.letrehozasiIdopont | date: 'yyyy-MM-dd HH:mm:ss'}}</span>
                                </div>
                                <div>
                                  <span>Megfigyelő orvos: {{adat.adatkitoltes.businessContent.megfigyelo}}</span>
                                </div>
                                <div>
                                  <span>Megfigyelő intézmény: {{adat.adatkitoltes.businessContent.intezmenyId}}</span>
                                </div>
                                <div>
                                  <span>Hitelesítő orvos: {{adat.adatkitoltes.businessContent.hitelesito}}</span>
                                </div>
                                <div>
                                  <span>Bizonyosság: {{adat.adatkitoltes.businessContent.bizonyossag}}</span>
                                </div>
                                <div>
                                  <span>Egészségügyi dokumentum: </span>
                                </div>
                                <div>
                                  <span>Digitális önrendelkezési kategória: {{adat.adatkitoltes.businessContent.onrendelkezesiKategoria}}</span>
                                </div>
                                <div>
                                  <span>Magyarázat: {{adat.adatkitoltes.businessContent.leiras}}</span>
                                </div>
                                <div *ngFor="let adatListItem of adat['adatList']['profilAdatSzerkezettel']" style="overflow: hidden;">
                                  <span>{{adatListItem.kulcs}}</span>
                                  <!-- <span>{{adatListItem.kulcs | w1250ToUtf8}}</span> -->
                                  <span>: </span>
                                  <span>{{adatListItem.ertek | resolveEesztTor :  adatListItem.businessObjectIdentification.eesztid.id }}</span>
                                  <!-- <span>{{adatListItem.ertek | w1250ToUtf8}}</span> -->
                                </div>
                            </div>
                            <div class="col-sm-2 float-right">
                              <button class="btn btn-danger" *ngIf="eproToModifyDataSource.length > 0" (click)="eproToModifyDataSource = []">X</button>
                            </div>
                          </div>
                    </div>


                      </div>
                    </div>
                    <div class="col-sm-8" style="border: 2px solid black; border-radius: 4px;">
                      <div *ngFor="let visualdDetailFieldObj of visualDetailFieldWithFormControl" style="margin: 10px">
                        <div [ngSwitch]="visualdDetailFieldObj.visualDetailField[1]">
                          <div *ngSwitchCase="'string'">
                            <label>{{visualdDetailFieldObj.visualDetailField[0]}}</label>
                            <input type="text" [formControl]="visualdDetailFieldObj.visualDetailFormControl" maxlength="64" class="form-control">
                          </div>
                          <div *ngSwitchCase="'date'">
                            <label>{{visualdDetailFieldObj.visualDetailField[0]}}</label>
                            <input type="date" [formControl]="visualdDetailFieldObj.visualDetailFormControl" max="9999-12-31" class="form-control">
                          </div>
                          <div *ngSwitchCase="'dropdown'">
                            <label>{{visualdDetailFieldObj.visualDetailField[0]}}</label>
                            <ng-select [formControl]="visualdDetailFieldObj.visualDetailFormControl" [searchable]="true" bindLabel="displayName" [items]="visualdDetailFieldObj.tpEproItems" [loading]="" loadingText="Betöltés..." notFoundText="Nincs találat">
                            </ng-select>
                          </div>
                          <div *ngSwitchDefault class="alert alert-danger" role="alert">
                            A megadott típusú beviteli mező nem azonosítható!
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="typeFormControl.value">
                    <div class="col-sm-12" style="text-align: end; margin-top: 10px;">
                      <button class="btn btn-success" (click)="saveEpro()"><i *ngIf="eproSaveInProgress" class="fa fa-spinner fa-spin"></i> Mentés</button>
                    </div>
                  </div>
                  <!-- <div>
                    <button class="btn btn-danger" *ngIf="eproToModifyDataSource.length > 0" (click)="eproToModifyDataSource = []">X</button>
                  </div>
                  <div>
                    <table *ngIf="eproToModifyDataSource.length > 0" mat-table [dataSource]="eproToModifyDataSource" class="table-striped">
                      <ng-container matColumnDef="eproId">
                        <th mat-header-cell *matHeaderCellDef class="tablePadding"> eProfil bejegyzés azonosítója </th>
                        <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.adatkitoltes.businessObjectIdentification.eesztid.id}} </td>
                      </ng-container>
                      <ng-container matColumnDef="dateFrom">
                        <th mat-header-cell *matHeaderCellDef class="tablePadding"> bejegyzés ideje </th>
                        <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.adatkitoltes.businessContent.letrehozasiIdopont}} </td>
                      </ng-container>
                      <ng-container matColumnDef="inspDoctor">
                        <th mat-header-cell *matHeaderCellDef class="tablePadding"> megfigyelő orvos </th>
                        <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.adatkitoltes.businessContent.megfigyelo}} </td>
                      </ng-container>
                      <ng-container matColumnDef="inspInstUnit">
                        <th mat-header-cell *matHeaderCellDef class="tablePadding"> megfigyelő intézmény </th>
                        <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.adatkitoltes.businessContent.intezmenyId}} </td>
                      </ng-container>
                      <ng-container matColumnDef="apprDoctor">
                        <th mat-header-cell *matHeaderCellDef class="tablePadding"> hitelesítő orvos </th>
                        <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.adatkitoltes.businessContent.hitelesito}} </td>
                      </ng-container>
                      <ng-container matColumnDef="cert">
                        <th mat-header-cell *matHeaderCellDef class="tablePadding"> bizonyosság </th>
                        <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.adatkitoltes.businessContent.bizonyossag}} </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayColumsEproToModify"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayColumsEproToModify;"></tr>
                    </table>
                  </div> -->

                  <div class="mr-5 mb-2">
                    <input type="checkBox" (click)="showBeu()" class="mr-1">
                    <span>egészségügyi dokumentum</span>
                  </div>
                  <div [ngClass]="{'hidden': !showEhrFields}" class="mb-3">
                    <div class="col-md-12" [formGroup]="patientEhrFormGroup">
                      <div class="row">
                          <div class="col-sm">
                            <label for="patientDateFrom">tól</label>
                            <div class="input-group mr-1">
                              <input class="form-control datepicker_mobile" placeholder="yyyy-mm-dd" name="dp" [minDate]="{year: 1900, month: 1, day: 1}"
                                formControlName="patientDateFrom" ngbDatepicker #dFrom="ngbDatepicker">
                              <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="dFrom.toggle()"
                                  type="button"></button>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm">
                            <label for="patientDateto">ig</label>
                            <div class="input-group mr-1">
                              <input class="form-control datepicker_mobile" placeholder="yyyy-mm-dd" name="dp" [minDate]="{year: 1900, month: 1, day: 1}"
                                formControlName="patientDateTo" ngbDatepicker #dTo="ngbDatepicker">
                              <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="dTo.toggle()"
                                  type="button"></button>
                              </div>
                            </div>
                          </div>
                      </div>


                      <!-- <div class="row">
                        <div class="col-sm-6 form-group">
                            <input type="checkBox" id="breakGlass" formControlName="breakGlass">
                            <label for="breakGlass" class="ml-1">sürgősségi adatlekérés</label>
                            <input type="text" class="form-control" formControlName="breakGlassReason">
                        </div>
                      </div> -->


                      <div class="row">
                        <div class="col-sm-6 form-group">
                            <input type="checkBox" id="breakGlass" formControlName="breakGlass">
                            <label for="breakGlass" class="ml-1">sürgősségi adatlekérés</label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6 form-group">
                          <label for="breakGlassReason" class="ml-1">sürgősségi adatlekérés indoklása</label>
                          <input type="text" id="breakGlassReason" class="form-control" formControlName="breakGlassReason">
                        </div>
                        <div class="col-sm-6 form-group">
                          <div *ngIf="this.patientEhrFormGroup.controls.breakGlass.value" class="alert alert-danger" role="alert" style="text-align: justify; text-justify: inter-word;">
                            A "sürgősségi adatlekérés" csak a következő esetekben használható jogszerűen:<br>
                            1.) Sürgős esetben, ha a páciens nem tud, nem képes eseti rendelkezést tenni. (Az Eüak. 10. § (4) bekezdése szerint sürgős szükség esetén a kezelést végző orvos által ismert, a gyógykezeléssel összefüggésbe hozható minden egészségügyi és személyazonosító adat továbbítható.) <br>
                            2.) A páciens 24 órás egyedi engedélye alapján (minta az e-egeszegugy.gov.hu oldalon letölthető).
                          </div>
                        </div>
                      </div>

                      <button class="btn btn-secondary" (click)="getPatientEhrDocumentList()">
                        <i *ngIf="ehrDetailsInProgress" class="fa fa-spinner fa-spin"></i>Keresés</button>
                        <table *ngIf="patientEhrList" mat-table [dataSource]="patientEhrList" class="table-striped">

                          <ng-container matColumnDef="doctorName">
                            <th mat-header-cell *matHeaderCellDef class="tablePadding"> létrehozó orvos </th>
                            <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.doctor.firstName + ' ' + element.doctor.lastName}} </td>
                          </ng-container>
                          <ng-container matColumnDef="stampNo">
                            <th mat-header-cell *matHeaderCellDef class="tablePadding"> pecsétszám </th>
                            <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.doctor.stampNo}} </td>
                          </ng-container>
                          <ng-container matColumnDef="timeStamp">
                            <th mat-header-cell *matHeaderCellDef class="tablePadding"> küldés ideje </th>
                            <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.timeStamp | date: 'yyyy/MM/dd'}} </td>
                          </ng-container>
                          <ng-container matColumnDef="hospitalDescr">
                            <th mat-header-cell *matHeaderCellDef class="tablePadding"> beküldő intézmény </th>
                            <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.hospitalDescr}} </td>
                          </ng-container>
                          <ng-container matColumnDef="diagnose">
                            <th mat-header-cell *matHeaderCellDef class="tablePadding"> diagnózis </th>
                            <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.diagnose}} </td>
                          </ng-container>
                          <ng-container matColumnDef="documentID">
                            <th mat-header-cell *matHeaderCellDef class="tablePadding"> dokumentum azonosítója </th>
                            <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.documentID}} </td>
                          </ng-container>
                          <ng-container matColumnDef="copyEhrDetails">
                            <th mat-header-cell *matHeaderCellDef class="tablePadding"> Ehr id kiválasztása </th>
                            <td mat-cell *matCellDef="let element" class="tablePadding"> <fa-icon class="selectIdIcon" (click)="copyReferralId(element)" [icon]="faCopy"></fa-icon> </td>
                          </ng-container>
                          <tr mat-header-row *matHeaderRowDef="displayedColumnsEhr"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsEhr;"></tr>
                        </table>
                  </div>
                </div>
                </div>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</div>
