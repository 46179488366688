import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ssnType'
})
export class SsnTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const ssnName = [];
    ssnName['1'] = 'TAJ';
    ssnName['2'] = 'Gyermek TAJ szám';
    ssnName['3'] = 'Útlevélszám';
    ssnName['5'] = 'Befogadó igazolvány';
    ssnName['8'] = 'EU e-kártya';
    ssnName['9'] = 'Menekült azonosítója';
    ssnName['X'] = 'Egyéb okirat';
    if (value !== '') {
      return ssnName[value];
    }
    return '';
  }

}
