import { SharedDataService } from './../../services/shared-data.service';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from './../../core/http/api.service';
import { AuthService } from './../../auth/auth.service';
import { SimpleSchedule, SaveScheduleWithRuleDto, SaveAppointmentWithRuleRequest } from './../../shared/models/request/saveAppointmentRequest';
import { FormArray, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-schedule-dialog',
  templateUrl: './schedule-dialog.component.html',
  styleUrls: ['./schedule-dialog.component.css']
})
export class ScheduleDialogComponent implements OnInit {

  scheduleForm: FormGroup;
  scheduleFormGroup: FormGroup;
  saveBookingInProgress = false;

  navigation = 'select';
  showWeekNumbers = false;
  outsideDays = 'visible';
  datepickerDisabled = false;
  datepickerModel: NgbDateStruct;

  dateFrom = null;

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  useCalendarListData = false;
  loadingCalendar = false;


  constructor(
    @Inject(MAT_DIALOG_DATA) public response: any,
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private apiService: ApiService,
    private authService: AuthService,
    private sharedDataService: SharedDataService,
  ) { }

  ngOnInit(): void {
    this.scheduleForm = this.fb.group({
      schedules: this.fb.array([])
    });

    this.setFormArray();
  }

  setFormArray() {
    if (this.response.schedules.data) {
      for (const sch of this.response.schedules.data) {
        this.addSchedule(sch.scheduleList, sch.schedule, sch.schedule.wrongDate);
      }
    }
  }

  get schedules(): FormArray {
    return this.scheduleForm.get('schedules') as FormArray;
  }

  newSchedule(schedules, value, wrongSchedule): FormGroup {
    this.scheduleFormGroup = this.fb.group({
      schedule: value,
      datePicker: new FormControl({ value: null }),
      wrongSchedule: wrongSchedule,
      scheduleList: new Array(schedules),
      dateControl: new FormControl({ value, disabled: !wrongSchedule }),
      timeControl: new FormControl({ value: schedules || value.schId === -2 ? null : value, disabled: !wrongSchedule }, Validators.required),
    }, Validators.required);
    this.scheduleFormGroup.get('datePicker').setValue(this.sharedDataService.dateStringToNgbDate(value.dateFrom));

    return this.scheduleFormGroup;
  }

  addSchedule(values, value, wrongSchedule) {
    this.schedules.push(this.newSchedule(values, value, wrongSchedule));

  }

  includesWrongDate(): boolean {
    for (const sch of this.response.schedules.data) {
      if (sch.schedule.wrongDate) {
        return true;
      }
    }
    return false;
  }

  checkSchedules() {
    const schedules: SimpleSchedule[] = [];

    const dates = this.schedules.controls.map(g => g.get('datePicker').value.year + '-' + g.get('datePicker').value.month + '-' + g.get('datePicker').value.day);
    const hasDuplicatedElements = dates.some((val, i) => dates.indexOf(val) !== i);

    if (hasDuplicatedElements) {
      this.toastr.error('A gyakoriság megadása során ugyanazokat a napokat választotta ki. Kérem válasszon ki másik napot!');
      return;
    }

    for (const group of this.schedules.controls) {
      let dateFrom = '';

      if (group.get('timeControl').value) {
        if (group.get('timeControl').value.dateFrom.includes('T')) {
          dateFrom = this.sharedDataService.dateToDateTimeString(new Date(group.get('timeControl').value.dateFrom));
          dateFrom = dateFrom.replace('T', ' ');
        } else {
          dateFrom = group.get('timeControl').value.dateFrom;
        }
        const schedule: SimpleSchedule = {
          dateFrom,
          detail: '',
          tapass: this.response.tapass,
          rowNum: null,
          schId: group.get('timeControl').value.schId ? group.get('timeControl').value.schId : group.get('timeControl').value.scheduleFk,
          serviceId: group.get('timeControl').value.serviceId ? group.get('timeControl').value.serviceId : group.get('timeControl').value.serviceFk,
          wrongDate: null,
        };

        schedules.push(schedule);
      }
    }

    this.saveBookingInProgress = true;

    const req: SaveAppointmentWithRuleRequest = {
      startDate: null,
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.response.currentRole,
        currentServicePointId: this.response.servicePointId,
        locale: 'hu',
        deviceId: this.authService.getUUID(),
        sessionId: null
      },
      equipment: null,
      tapass: this.response.tapass,
      serviceId: this.response.serviceId,
      quantity: null,
      time: null,
      timeType: null,
      schedules
    };

    this.apiService.saveAppointmentWithRule(req).subscribe(
      response => {
        this.schedules.clear();
        this.response.schedules = response;
        this.setFormArray();
        this.useCalendarListData = false;

        this.saveBookingInProgress = false;
      }, error => {
        this.saveBookingInProgress = false;
      });
  }

  callSaveSchedule() {
    let ids = '';
    let tapass = '';
    let ssnType = '';
    let ssnNumber = '';
    this.saveBookingInProgress = true;

    for (const group of this.schedules.controls) {
      if (group.get('timeControl').value.schId && group.get('timeControl').value.schId > 0) {
        ids += group.get('timeControl').value.schId + ',';
      } else if (group.get('timeControl').value.scheduleFk) {
        ids += group.get('timeControl').value.scheduleFk + ',';
      }
    }

    if (this.response.patient) {
      if (this.response.patient.tapass) {
        tapass = this.response.patient.tapass;
        ssnType = this.response.patient.medicalType;
        ssnNumber = this.response.patient.medicalId;
      } else if (this.response.patient.participantTapass) {
        tapass = this.response.patient.participantTapass;
      }
    }

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.response.currentRole,
        currentServicePointId: this.response.servicePointId,
        locale: 'hu',
        deviceId: this.authService.getUUID(),
        sessionId: null
      },
      patientTapass: tapass,
      providerUserId: '',
      ssnType,
      ssnNumber,
      patientAlias: this.response.alias,
      scheduleFkList: ids.slice(0, -1),
      diagnosisComment: this.response.comment,
      eventTypeId: this.response.etId,
      equipment: this.response.equipment
    };

    this.apiService.saveScheduleDay(params).subscribe(res => {
      if (res.code > -1) {
        this.sharedDataService.mulriCalendarChanged.next(true);
        this.saveBookingInProgress = false;
        this.matDialog.getDialogById('schedule-dialog').close();
        const button = document.getElementById('closeButton') as HTMLElement;
        button.click();
        this.toastr.success('Sikeres mentés!');
      } else {
        this.saveBookingInProgress = false;
        this.toastr.error(res.message);
      }
    }, err => {
      this.saveBookingInProgress = false;
    });
  }

  closeModal() {
    let ids = '';

    for (const group of this.schedules.controls) {
      if (group.get('timeControl').value && group.get('timeControl').value.schId && group.get('timeControl').value.schId > 0 && !group.get('wrongSchedule').value) {
        ids += group.get('timeControl').value.schId + ',';
      } else if (group.get('timeControl').value && group.get('timeControl').value.scheduleFk) {
        ids += group.get('timeControl').value.scheduleFk + ',';
      }
    }

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.response.currentRole,
        currentServicePointId: this.response.servicePointId,
        locale: 'hu',
        deviceId: this.authService.getUUID(),
        sessionId: null
      },
      patientTapass: null,
      providerUserId: '',
      ssnType: null,
      ssnNumber: null,
      patientAlias: null,
      scheduleFkList: ids.slice(0, -1),
      diagnosisComment: null,
      eventTypeId: null,
      status: 'F'
    };

    this.apiService.saveScheduleDay(params).subscribe(res => {
      if (res.code > -1) {
      } else {
        this.toastr.error(res.message);
      }
    }, err => {
    });

    this.matDialog.getDialogById('schedule-dialog').close();
  }


  dateChanged(date: NgbDate) {

    const dateString = date.year + '-' + ('0' + (date.month)).slice(-2) + '-' + ('0' + date.day).slice(-2);
    // console.log('Datepicker change:' + dateString);
    const newDate = new Date(dateString);
    this.sharedDataService.setBookingDate(newDate);
  }


  reset() {
    // console.log('datepicker reset');
    // console.log(this.datepickerModel);
  }

  dayStatus(date: NgbDate) {
    const dayResult = this.sharedDataService.returnCalendarDataforDay(date);
    const dateString = date.year + '-' + ('0' + (date.month)).slice(-2) + '-' + ('0' + date.day).slice(-2);
    let dayClass = '';

    if (dayResult) {
      dayClass = 'calendar_' + dayResult['colorCode'].toLowerCase();
    }

    if (dateString === this.dateFrom) {
      dayClass += ' selected-day';
    }

    return dayClass;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    let dateFrom = this.fromDate ? new Date(this.fromDate.year + '-' + ('0' + (this.fromDate.month)).slice(-2) + '-' + ('0' + (this.fromDate.day)).slice(-2)) : null;
    dateFrom.setDate(dateFrom.getDate() - 1);
    let dateTo = this.toDate ? new Date(this.toDate?.year + '-' + ('0' + (this.toDate?.month)).slice(-2) + '-' + ('0' + (this.toDate?.day)).slice(-2)) : null;
    dateTo?.setDate(dateTo.getDate() - 1);
    if (((dateTo?.getTime() - dateFrom.getTime()) / (1000 * 3600 * 24)) > 8) {
      dateFrom = null;
      dateTo = null;
    }
    this.sharedDataService.setBookingDateRange(dateFrom, dateTo);
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  isStart(date: NgbDate) {
    return date.equals(this.fromDate);
  }

  isEnd(date: NgbDate) {
    if (this.toDate) {
      return date.equals(this.toDate);
    } else if (this.fromDate) {
      return date.equals(this.fromDate);
    }

  }

  isStartAndEnd(date: NgbDate) {
    if (date.equals(this.fromDate) && !this.toDate) {
      return true;
    } else {
      return false;
    }
  }

  getCalendarListData(date, index) {

    this.loadingCalendar = true;

    if (index >= 0) {
      this.schedules.controls[index].get('schedule').value.schId = -4;
      this.schedules.controls[index].get('timeControl').enable();
      this.schedules.controls[index].get('timeControl').setValue(null);
    }


    const dateFrom = this.sharedDataService.NgbDateToDateString(date);
    const paramsMulti = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.getSelectedRole()['roleCode'],
        currentServicePointId: this.sharedDataService.getSelectedRole()['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      dateFrom: dateFrom,
      serviceId: this.response.serviceId
    };


    this.apiService.getMultiCalendar(paramsMulti).subscribe(
      res => {
        this.useCalendarListData = true;
        this.loadingCalendar = false;
        if (res['data'] && res['data'][0] && res['data'][0]['calendarData']) {
          res['data'][0]['calendarData'] = res['data'][0]['calendarData'].filter(
            sch => {
              return sch.calendarStatus === 'F';
            });

          if (index >= 0) {
            this.schedules.controls[index].get('scheduleList').setValue(res['data'][0]['calendarData']);
          } else {
            return res['data'][0]['calendarData'];
          }
        }

      });
  }
}
