<!-- Modal -->
<div class="modal" id="documentation" tabindex="-1" role="dialog" aria-labelledby="documentation" aria-hidden="true">
    <div class="modal-dialog modal-lg " role="document">

        <div [ngClass]="{'hidden': !closeConfirmationDialogueVisible}" id="quitConfirmation" class="documentQuitConfirmation">
            <div class="quitConfirmationContent">
                <h6>Az adatok megtartása a későbbi folytatáshoz?</h6>
                <div class="col-md-12 mt-4">
                    <div class="row">
                        <div class="col-md-4">
                            <button type="button" class="btn btn-primary" (click)="hideCloseConfirmationDialogue(true)" data-dismiss="modal">Igen</button>
                        </div>
                        <div class="col-md-4">
                            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="resetModalAndHideDialogue()">Nem</button>
                        </div>
                        <div class="col-md-4">
                            <button type="button" class="btn btn-secondary" (click)="hideCloseConfirmationDialogue(false)">Mégsem</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div [ngClass]="{'hidden': !resetTemplateVisible}" id="resetTemplate" class="documentQuitConfirmation">
            <div class="quitConfirmationContent">
                <h6>Kívánja betölteni az alap sablont?</h6>
                <p>Az alapsablon betöltése során a jelenlegi dokumentum minden eddigi módosítása elvész.</p>
                <div class="col-md-12 mt-4">
                    <div class="row">
                        <div class="col-md-6">
                            <button type="button" class="btn btn-danger" (click)="onLoadTemplate(0); resetTemplateVisible = false">Igen</button>
                        </div>
                        <div class="col-md-6">
                            <button type="button" class="btn btn-secondary" (click)="resetTemplateVisible = false">Nem</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div [ngClass]="{'hidden': !saveInProgress}" class="documentQuitConfirmation">
            <div class="quitConfirmationContent">
                Mentés folyamatban...
            </div>
        </div>

        <div class="modal-content modal-fh">
            <div class="modal-header">

                <div id="saveSuccess" [ngClass]="{'showResult': saveResult == 0}">
                    Sikeresen mentve!
                </div>
                <div id="saveError" [ngClass]="{'showResult': saveResult == -1}">
                    <div class="closeResult" (click)="closeResult()">x</div>
                    <div class="saveErrorContent">Hiba a mentés során!
                        <span>{{ saveErrorDescription }}</span>
                    </div>
                </div>
                <div id="saveError" [ngClass]="{'showResult': saveResult == -2}">
                    <div class="closeResult" (click)="closeResult()">x</div>
                    <div class="saveErrorContent">Hiba a mentés során!
                        <span>Minden mező kitöltése kötelező!</span>
                    </div>
                </div>
                <div class="loadError" [ngClass]="{'showResult': loadResult == -1}">
                    <div class="closeLoadResult" (click)="closeLoadResult()">x</div>
                    <div class="saveErrorContent">Hiba az adatok betöltése során!
                        <span>{{ loadErrorDescription }}</span>
                    </div>
                </div>
                <h3 class="modal-title" id="exampleModalLongTitle">Dokumentálás {{ patientDetailsDisplay }}</h3>
                <div class="col-md-6">
                </div>
                <div>
                    <button type="button" class="btn btn-primary ml-1 mb-1" (click)="openHelp('#care')" title="kézikönyv">
            <i class="fa fa-question-circle"></i>
          </button>
                    <button *ngIf="editorDirty" type="button" class="btn btn-secondary mb-1 ml-1" (click)="closeModal()">X</button>
                    <button *ngIf="!editorDirty" type="button" class="btn btn-secondary mb-1 ml-1" (click)="resetModal()" data-dismiss="modal">X</button>
                </div>
            </div>
            <!-- <nav mat-tab-nav-bar #mainTabGroup>
        <a mat-tab-link class="nav-link" (click)="switchTab(0)" [active]="activeTab === 0"> Dokumentum </a>
        <a mat-tab-link class="nav-link" *ngIf="authService.getBrand() !== 'UDMED'" (click)="switchTab(1)" [active]="activeTab === 1"> Implantátum </a>
        <a mat-tab-link class="nav-link" (click)="switchTab(2)" [active]="activeTab === 2"> Dokumentum feltöltés </a>
      </nav> -->
            <!-- <div>
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab == 0}" (click)="switchTab(0)">Dokumentum</a>
          </li>
          <li class="nav-item" *ngIf="authService.getBrand() !== 'UDMED'">
            <a class="nav-link" [ngClass]="{'active': activeTab == 1}" (click)="switchTab(1)">Implantátum</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab == 2}" (click)="switchTab(2)">Dokumentum feltöltés</a>
          </li>
        </ul>

      </div> -->
            <div class="modal-body">
                <div class="container-fluid">
                    <mat-tab-group #mainTabGroup [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="tabClick($event)">
                        <mat-tab label="Dokumentum">
                            <ng-template matTabContent>
                                <div class="col-md-12 mt-3">
                                    <form class="form-dropdown-select" [formGroup]="documentTypeSelectGroup">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="row form-group">

                                                    <ng-select class="full-width" [searchable]="true" placeholder="Dokumentum tipus" formControlName="docTypeSel" [items]="documentTypes" bindLabel="docTypeName" bindValue="docTypeCode" (change)="onSelectedDocTypeChange($event)" [loading]="documentsLoading"
                                                        loadingText="Betöltés..." notFoundText="Nincs találat">
                                                    </ng-select>

                                                </div>
                                                <div class="row form-group">

                                                    <ng-select class="full-width" [searchable]="true" placeholder="Dokumentum sablon" formControlName="docTemplateSel" [items]="documentTemplates" bindLabel="templateName" bindValue="id" (change)="onLoadTemplate(1)" [loading]="documentsLoading" loadingText="Betöltés..."
                                                        notFoundText="Nincs találat">
                                                    </ng-select>

                                                </div>
                                                <div class="row form-group">
                                                    <button class="btn btn-danger mr-2 mb-2" [disabled]="!docTemplateSel.valid" (click)="resetTemplateVisible = true" title="Az eddigi módosítások törlésével az alapsablon lesz látható">
                            <i class="fa fa-file"></i>
                          </button>
                                                    <button *ngIf="authService.getBrand() !== 'UDMED'" class="btn btn-primary mr-2 mb-2" [disabled]="!implantCopyEnabled" (click)="onImplantCopy()" title="Implant adatok másolása">
                            <i class="fa fa-copy"></i>
                          </button>
                                                    <button class="btn btn-primary mr-2 mb-2" [disabled]="!documentLoaded" *ngIf="this.authService.getBrand() !== 'UDMED'" (click)="onSaveDocument($event, 1, false)" title="Mentés és nyomtatás">
                            <i class="fa fa-print"></i>
                          </button>
                                                    <button class="btn btn-primary mr-2 mb-2" [disabled]="!editorDirty" (click)="onSaveDocument($event, 0, false)" title="Mentés">
                            <i class="fa fa-save"></i>
                          </button>
                                                    <!-- <button *ngIf="apiUrl == 'https://ekrest.nauticom.hu'" class="btn btn-primary mr-2 mb-2"
                            [disabled]="!editorDirty || !eesztSendEnabled || !this.bnoFromGroup.controls.bnoList.value"
                            (click)="onSaveDocument($event, 0, true)"
                            title="Mentés és EESZT beküldés">
                            <i class="fa fa-cloud"></i>
                          </button> -->
                                                    <button class="btn btn-primary mr-2 mb-2" [disabled]="!editorDirty || !eesztSendEnabled || !savedBnos" (click)="onSaveDocument($event, 0, true)" title="Mentés és EESZT beküldés">
                            <i class="fa fa-cloud"></i>
                          </button>
                                                    <!-- <button class="btn btn-primary mr-2 mb-2" (click)="logEditorContent()" title="editor Content">
                            <i class="fa fa-terminal"></i>
                          </button> -->

                                                </div>

                                                <div class="mb-2">

                                                    <div class="row mt-2 d-flex">
                                                        <div class="col-10 pl-0">
                                                            <p>Megadott BNO és WHO másolása</p>
                                                        </div>
                                                        <button class="btn btn-primary col-2 copy-bno-to-doc" (click)="copyBnoIntoDocument()" title="diagnózis másolása">
                                                            <i style="font-size: 30px;" class="fa fa-copy"></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div class="row form-group">
                                                    <input type="checkbox" id="docEmailSend" class="" style="float:left" [formControl]="docEmailSend">
                                                    <label for="docEmailSend" class="" style="float:left; margin-left: 15px;">E-mail értesítés küldése</label>
                                                </div>

                                                <div *ngIf="authService.getBrand() !== 'UDMED'">
                                                    <div class="row form-group">
                                                        <h5>Dokumentum címke</h5>
                                                        <ng-select class="full-width" [searchable]="true" placeholder="Dokumentum címke" formControlName="docTagType" [items]="docTagBase" bindLabel="displayData" bindValue="code" [loading]="codebaseLoading" (change)="onChangeDocTag($event)" loadingText="Betöltés..."
                                                            notFoundText="Nincs találat">
                                                        </ng-select>
                                                    </div>
                                                    <div class="row form-group">
                                                        <label for="docTagCode" class="control-label">Címke kódja</label>
                                                        <input type="text" formControlName="docTagCode" class="full-width form-control" [readonly]="docTagReadOnly">
                                                    </div>
                                                    <div class="row form-group">
                                                        <label for="docTagName" class="control-label">Címke neve</label>
                                                        <input type="text" formControlName="docTagName" class="full-width form-control" [readonly]="docTagReadOnly">
                                                    </div>
                                                    <div class="row form-group">
                                                        <label for="docTagDescription" class="control-label">Címke leírása</label>
                                                        <textarea type="text" formControlName="docTagDescription" class="full-width form-control small-text-area" [readonly]="docTagReadOnly"></textarea>
                                                    </div>
                                                    <div class="row form-group">
                                                        <label for="docTagValue" class="control-label">Címke értéke</label>
                                                        <input type="text" formControlName="docTagValue" class="full-width form-control">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-8" *ngIf="showEditor">
                                                <!-- <app-ngx-editor  [config]="editorConfig" ></app-ngx-editor> -->
                                                <!-- <ckeditor [editor]="CKEditor"  (ready)="onReady($event)" formControlName="editor"
                          [config]="{ fullPage: true, allowedContent: true }"></ckeditor> -->


                                                <ckeditor [config]="ckeConfig" formControlName="editor"></ckeditor>



                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab *ngIf="calendarStatus !== 'S'" label="BNO és WHO hozzárendelése">
                            <ng-template matTabContent>
                                <div class="col-md-12 mt-3">
                                    <app-bno-who (savedWhosChanged)="savedWhosChanged($event)" (savedBnosChanged)="savedBnosChanged($event)" (saveInProgress)="saveInProgressFromSurgery($event)" (saveResult)="saveResultFromSurgery($event)" [calendarStatus]="calendarStatus" [bno]="allBno"
                                        [who]="allWho" [diagType]="allDiagType" [admTypeCat]="calendarDetail['admTypeCat']"></app-bno-who>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="Implantátum">
                            <ng-template matTabContent>
                                <div class="col-md-12 mt-3">
                                    <!-- <form class="form-dropdown-select" > -->
                                    <div class="row">
                                        <div class="col-md-8">

                                            <div class="accordion" id="accordionExample">
                                                <app-implant-details [implantDetailsBaseData]="{instanceId: 1, implantData: implantData[0]}" (formDataChange)="onFormDataChange(1, $event)" (copyImplantData)="onCopyImplantData($event, 1)">
                                                </app-implant-details>
                                                <app-implant-details [implantDetailsBaseData]="{instanceId: 2, implantData: implantData[1]}" (formDataChange)="onFormDataChange(2, $event)" (copyImplantData)="onCopyImplantData($event, 2)">
                                                </app-implant-details>
                                                <app-implant-details [implantDetailsBaseData]="{instanceId: 3, implantData: implantData[2]}" (formDataChange)="onFormDataChange(3, $event)" (copyImplantData)="onCopyImplantData($event, 3)">
                                                </app-implant-details>
                                                <app-implant-details [implantDetailsBaseData]="{instanceId: 4, implantData: implantData[3]}" (formDataChange)="onFormDataChange(4, $event)" (copyImplantData)="onCopyImplantData($event, 4)">
                                                </app-implant-details>
                                                <app-implant-details [implantDetailsBaseData]="{instanceId: 5, implantData: implantData[4]}" (formDataChange)="onFormDataChange(5, $event)" (copyImplantData)="onCopyImplantData($event, 5)">
                                                </app-implant-details>
                                                <app-implant-details [implantDetailsBaseData]="{instanceId: 6, implantData: implantData[5]}" (formDataChange)="onFormDataChange(6, $event)"></app-implant-details>
                                            </div>
                                        </div>


                                        <div class="col-md-4">
                                            <h4>Páciens</h4>

                                            <div class="row form-group">

                                                <label for="country" class="col-sm-4 control-label">Ország</label>
                                                <div class="col-sm-8 form-item">
                                                    <select class="form-control" [formControl]="implantCountry">
                            <option ngValue="HUN">Magyarország</option>
                          </select>
                                                    <div class="formError" *ngIf="implantCountry.dirty && !implantCountry.valid">
                                                        Kérem töltse ki az országot!</div>
                                                </div>

                                                <label for="zip" class="col-sm-4 control-label">Irányítószám</label>
                                                <div class="col-sm-8 form-item">
                                                    <input type="text" placeholder="Irányítószám" class="form-control" [formControl]="implantZip">
                                                    <div class="formError" *ngIf="implantZip.dirty && !implantZip.valid">Nem megfelelő irányítószám (minimum 3 karakter)
                                                    </div>
                                                </div>


                                                <label for="city" class="col-sm-4 control-label">Város</label>
                                                <div class="col-sm-8 form-item">
                                                    <input type="text" placeholder="Város" class="form-control" [formControl]="implantCity">
                                                    <div class="formError" *ngIf="implantCity.dirty && !implantCity.valid">Nem megfelelő város (minimum 2 karakter)
                                                    </div>
                                                </div>

                                                <label for="address" class="col-sm-4 control-label">Utca, házszám</label>
                                                <div class="col-sm-8 form-item">
                                                    <input type="text" placeholder="Utca, házszám" class="form-control" [formControl]="implantAddress">
                                                    <div class="formError" *ngIf="implantAddress.dirty && !implantAddress.valid">Nem megfelelő utca, házszám (minimum 4 karakter)</div>
                                                </div>


                                                <label for="phone" class="col-sm-4 control-label">Telefonszám</label>
                                                <div class="col-sm-8 form-item">
                                                    <input type="text" placeholder="Telefonszám" class="form-control" [formControl]="implantPhone">
                                                    <div class="formError" *ngIf="implantPhone.dirty && !implantPhone.valid">Nem megfelelő telefonszám (minimum 8 karakter)</div>
                                                </div>
                                                <button class="btn btn-primary" [disabled]="((!implantAllDetailsValid === true) || (!implantDetails.valid === true))" (click)="saveImplantData()">Mentés</button>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- </form> -->
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="Dokumentum feltöltés">
                            <ng-template matTabContent>
                                <div class="col-md-12 mt-3">

                                    <form [formGroup]="fileUpload" (ngSubmit)="onFileSubmit()">
                                        <div class="row">
                                            <div class="col-sm-12 mb-2">
                                                <h5>Dokumentum</h5>
                                            </div>
                                            <div class="col-sm-12">
                                                <div class="row form-group">
                                                    <label for="phone" class="col-sm-1 control-label">Típus</label>
                                                    <div class="col-sm-3 mb-2">
                                                        <ng-select class="full-width" [searchable]="true" placeholder="Dokumentum tipus" formControlName="fileDocType" [items]="uploadDocumentTypes" bindLabel="description" bindValue="code" [loading]="uploadDocumentTypesLoading" [clearable]="false" loadingText="Betöltés..."
                                                            notFoundText="Nincs találat">
                                                        </ng-select>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <label class="btn btn-primary">
                              Fájl kiválasztása <input type="file" (change)="onFileChange($event)" [formControl]="fileInput"
                                style="display: none;">
                            </label>
                                                    </div>
                                                    <!-- <input type="file" (change)="onFileChange($event)" [formControl]="fileInput" class="form-control"> -->
                                                    <!-- <button type="button" class="btn btn-sm btn-default" (click)="clearFile()">clear file</button> -->
                                                    <div class="col-sm-3 mb-2">
                                                        {{ fileTempData.filename }}
                                                    </div>
                                                    <div class="col-sm-2 mb-2">
                                                        <button type="submit" [disabled]="!fileTempData.valid || uploadInProgress || !fileUpload.valid" class="btn btn-success"><i class="fa fa-spinner fa-spin" *ngIf="uploadInProgress"></i>
                              Feltöltés</button>
                                                    </div>


                                                    <div class="col-sm-12">
                                                        <input type="checkbox" id="fileEmailSend" class="" style="float:left" [formControl]="fileEmailSend">
                                                        <!-- form-control checkbox-item -->
                                                        <label for="fileEmailSend" class="" style="float:left; margin-left: 15px;">E-mail értesítés
                              küldése</label>
                                                        <!-- control-label -->
                                                    </div>


                                                </div>

                                                <div class="row form-group" *ngIf="authService.getBrand() !== 'UDMED'">
                                                    <div class="col-sm-12">
                                                        <h5>Dokumentum címke</h5>
                                                        <ng-select class="full-width" [searchable]="true" placeholder="Dokumentum címke" formControlName="fileTagType" [items]="docTagBase" bindLabel="displayData" bindValue="code" [loading]="codebaseLoading" (change)="onChangeFileTag($event)" loadingText="Betöltés..."
                                                            notFoundText="Nincs találat">
                                                        </ng-select>
                                                    </div>
                                                </div>
                                                <div class="row form-group" *ngIf="authService.getBrand() !== 'UDMED'">
                                                    <div class="col-sm-12">
                                                        <label for="fileTagCode" class="control-label">Címke kódja</label>
                                                        <input type="text" formControlName="fileTagCode" class="full-width form-control" [readonly]="docTagReadOnly">
                                                    </div>
                                                </div>
                                                <div class="row form-group" *ngIf="authService.getBrand() !== 'UDMED'">
                                                    <div class="col-sm-12">
                                                        <label for="fileTagName" class="control-label">Címke neve</label>
                                                        <input type="text" formControlName="fileTagName" class="full-width form-control" [readonly]="docTagReadOnly">
                                                    </div>
                                                </div>
                                                <div class="row form-group" *ngIf="authService.getBrand() !== 'UDMED'">
                                                    <div class="col-sm-12">
                                                        <label for="fileTagDescription" class="control-label">Címke leírása</label>
                                                        <textarea type="text" formControlName="fileTagDescription" class="full-width form-control" [readonly]="docTagReadOnly"></textarea>
                                                    </div>
                                                </div>

                                                <div class="row form-group" *ngIf="authService.getBrand() !== 'UDMED'">
                                                    <div class="col-sm-12">
                                                        <label for="fileTagValue" class="control-label">Címke értéke</label>
                                                        <input type="text" formControlName="fileTagValue" class="full-width form-control">
                                                    </div>
                                                </div>



                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>
</div>