<div class="error" [ngClass]="{'showResult': error == true}">
    <div class="closeResult" (click)="closeError()">x</div>
    <div class="errorContent">
        <span>{{ errorDescription }}</span>
    </div>
</div>

<div class="row service-select" *ngIf="!availServiceEmpty">
    <div class="col-2" *ngIf="Brand == 'MEDCSEPP'">
        <button *ngIf="selectedCsId == null" class="btn btn-primary" style="width: auto;" (click)="onCallPatient('')" [disabled]="true" title="A behíváshoz állítsa be a munkahelyet a medcsepp beállításoknál">Behívás</button>
        <button *ngIf="selectedCsId !== null" class="btn btn-primary" style="width: auto;" (click)="onCallPatient('')">Behívás</button>
        <span class="callNumber badge badge-success ml-2">{{ callNumber }}
      <i *ngIf="callPatientLoading" class="fa fa-spinner fa-spin"></i>
    </span>
    </div>
    <div [ngClass]="{'col-6 two-columns': twoColumns, 'col-10': !twoColumns && Brand == 'MEDCSEPP', 'col-12': !twoColumns && Brand != 'MEDCSEPP'}">
        <div class="row mb-2">
            <div class="col-sm-12" [formGroup]="serviceSelect">
                <ng-select class="" [searchable]="true" placeholder="Válasszon szolgáltatási pontot" formControlName="service" [items]="availServic" bindLabel="displayName" bindValue="spId" (change)="onSelectedServiceChange($event)" loadingText="Betöltés..." notFoundText="Nincs találat">
                </ng-select>
            </div>
        </div>
    </div>
</div>

<!-- <div [ngClass]="{'hidden': !deleteAppointmentModal}" class="calendarQuitConfirmation">
    <div class="quitConfirmationContent">
        <div *ngIf="bookedCellDisplayData && bookedCellDisplayData.length > 0" class="alert alert-primary" role="alert">
            <h4 *ngFor="let bookedCellInforations of bookedCellDisplayData.split(',')"><b>{{bookedCellInforations}}</b></h4>
        </div>
        <h3>Kérem adja meg a törlés okát</h3>
        <div class="col-md-12 mt-4">
            <form [formGroup]="modReason">
                <ng-select class="full-width mb-4" [searchable]="true" placeholder="Válasszon..." formControlName="modReasonSelect" [items]="calendarModReasons" bindLabel="description" [clearable]="false" loadingText="Betöltés..." notFoundText="Nincs találat">
                [loading]="availServiceLoading" 
        </ng-select>
        </form>
        <div class="row">
            <div class="col-md-6">
                <button type="button" class="btn btn-secondary w-100" (click)="showDeleteAppointment(false)" [disabled]="deleteInProgress">Mégsem</button>
            </div>
            <div class="col-md-6 text-right">
                <button type="button" class="btn btn-primary w-100" (click)="deleteAppointment()" [disabled]="deleteInProgress">
                <i *ngIf="deleteInProgress" class="fa fa-spinner fa-spin"></i>
                Törlés
                </button>
            </div>
        </div>
        </div>
        </div>
</div> -->

<div [ngClass]="{'hidden': !showPatientForm || bookingDateFrom === null}" class="patientForm">
    <div class="patientFormContent">

        <div class="row d-flex">
            <span class="mx-auto my-auto">{{bookingDateFrom?.getHours() + ':' + ('0' + bookingDateFrom?.getMinutes()).slice(-2) + '-' + bookingDateTo?.getHours() + ':' + ('0' + bookingDateTo?.getMinutes()).slice(-2)}}</span>
        </div>

        <div class="row form-group" [ngClass]="{'hidden': Brand === 'UDMED'}">
            <input type="text" [formControl]="patientAlias" (change)="onPatientAliasChange($event)" placeholder="Adjon meg páciens alias-t" class="form-control">
        </div>

        <div *ngIf="szentVincent" class="row form-group">
            <ng-select class="w-100" [searchable]="true" placeholder="Válasszon eszközt..." [formControl]="equipment" [items]="equipmentList" bindLabel="name" bindValue="type" [clearable]="true" loadingText="Betöltés..." notFoundText="Nincs találat">
                <!-- [loading]="availServiceLoading" -->
            </ng-select>
        </div>

        <div class="row form-group">
            <form [formGroup]="bookingDetails" class="form-dropdown-select">
                <ng-select [searchable]="true" formControlName="patientName" placeholder="Válaszon pácienst" [items]="patientList" bindLabel="displayData" bindValue="tapass" (change)="onSelectedPatientChange($event)" [loading]="patientListLoading" loadingText="Betöltés..."
                    notFoundText="Nincs találat">
                </ng-select>
            </form>
        </div>


        <div class="row form-group">
            <input type="text" [formControl]="comment" placeholder="Megjegyzés" class="form-control">
        </div>

        <div class="row">
            <div class="col-md-6">
                <button type="button" class="btn btn-success w-100" [disabled]="saveBookingLoading" (click)="saveAppointment()"><i *ngIf="saveBookingLoading" class="fa fa-spinner fa-spin"></i>foglalás</button>
            </div>
            <div class="col-md-6 text-right">
                <button type="button" class="btn btn-danger w-100" (click)="showPatientForm = false; this.weekSchedule.slice(); resetWeek=true">mégsem</button>
            </div>
        </div>
    </div>
</div>

<div class="calendar-list-container">


    <div *ngIf="availServiceEmpty">A kiválasztott szerepkörhöz nem tartozik szolgáltatási pont.</div>

    <div *ngIf="selectedService">
        <div *ngIf="!calendarLoading && calendarListDataAll.length == 0 && !availServiceEmpty">A megadott napra nincs bejegyzés
        </div>
        <div *ngIf="!calendarLoading">
            <div class="meter">
                <span style="width:100%;">
          <span class="progress" [style.animation-duration.s]="calendarRefreshTimer/ 1000"
            *ngIf="calendarLoaded"></span>
                <span class="progress" *ngIf="!calendarLoaded"></span>
                </span>
            </div>
            <div class="row">
                <div [ngClass]="{'col-md-12': Brand !== 'MEDCSEPP', 'col-md-9': Brand == 'MEDCSEPP'}">

                    <!-- <div *ngIf="additionalSlots > 0" class="height-{{ additionalSlots }} empty-item row"
            [ngClass]="{'two-columns': twoColumns, 'one-column': !twoColumns}">
          </div> -->
                    <!-- <div *ngIf="currentTimeMarkerPosition >= 0 && calendarListDataAll.length > 0 "
            [style.top.px]="currentTimeMarkerPosition" #currentTimeMarker class="currentTimeMarker"> </div> -->

                    <div style="position: absolute;" [style.top.px]="currentScrollPosition" #currentScrollPos class="scrollPos">
                    </div>
                    <div class="row mt-1">
                        <div [ngClass]="[twoColumns ? 'col-sm-5' : 'col-sm-3']">
                            <app-scheduler-modal *ngIf="weekSchedule.length > 0 && serviceSelect.controls.service.value" [weekScheduleDay]="weekSchedule" [fromBooking]="false" [coCells]="coCells" [tempCells]="tempCells" [availableCells]="freeCells" [dayLenghtInHours]="dayLenghtInHours"
                                [bookedCells]="bookedCells" [resetSelection]="resetWeek" (bookCells)="bookCells($event)" (deleteCells)="deleteCells($event)" (bookedCellClick)="showBookedCellInformationModal($event)" (dateRangeChange)="changeDateRange($event)"></app-scheduler-modal>
                        </div>
                        <div [ngClass]="[twoColumns ? 'col-sm-7' : 'col-sm-9']">
                            <div *ngFor="let calendarEntryRow of calendarListDataRows; let h = index" class="row r-{{h}}">
                                <div *ngFor="let calendarEntry of calendarEntryRow let i = index" [ngClass]="{'calendar_item_right_space': calendarListDataRowItemCount[h] < 12}" class="calendar_item_container col-md-{{calendarListDataRowItemCount[h]}}">

                                    <!-- *ngIf="calendarEntry['calendarStatus'] != 'F'" -->
                                    <!--  height-{{ calendarEntry['slotLength'] }} -->
                                    <div class="calendar_items col-md-12 ml-1" [ngClass]="{'two-columns': twoColumns, 'one-column': !twoColumns,
                    'appointment_arrived': calendarEntry['calendarStatus'] == 'A',
                    'appointment_discarded': calendarEntry['calendarStatus'] == 'D',
                    'free-appointment': calendarEntry['calendarStatus'] == 'F',
                    'appointment-S': calendarEntry['calendarStatus'] == 'S',
                    'appointment-DD': calendarEntry['calendarStatus'] == 'DD',
                    'appointment-DH': calendarEntry['calendarStatus'] == 'DH',
                    'appointment-DN': calendarEntry['calendarStatus'] == 'DN'
                    }" *ngIf="calendarEntry['calendarStatus'] != 'F' && calendarEntry['calendarStatus'] != 'T'  && calendarEntry['calendarStatus'] != 'CO'">

                                        <div class="row_emulator">

                                            <div *ngIf="calendarEntry['calendarStatus'] != 'F'  && calendarEntry['eventTypeFk'] != '12234835' && calendarEntry['eventTypeFk'] != '52342809'" class="small-lineheight cal-time-col">
                                                <small>{{ calendarEntry['dateFrom'] | date:'H:mm' }}
                          <br>-<br>
                          {{calendarEntry['dateTo'] | date:'H:mm'}}</small>
                                            </div>
                                            <!-- <div *ngIf="calendarEntry['calendarStatus'] != 'F' && calendarEntry['calendarStatus'] != 'DN'&& calendarEntry['calendarStatus'] != 'DH'" class="cal-detail"
                        [ngClass]="{'cal-detail-dispatcher': Brand == 'MEDCSEPP',
                                    'cal-detail-col-expanded-care-started': this.hamburgerButtons.includes(calendarEntry.id) && calendarEntry['calendarStatus'] == 'A',
                                    'cal-detail-col-expanded-care-done': !this.hamburgerButtons.includes(calendarEntry.id) && calendarEntry['calendarStatus'] == 'D',
                                    'cal-detail-col-expanded': this.hamburgerButtons.includes(calendarEntry.id),
                                    'cal-detail-col': !this.hamburgerButtons.includes(calendarEntry.id)
                                    }"> -->
                                            <div *ngIf="calendarEntry['calendarStatus'] != 'F' && calendarEntry['calendarStatus'] != 'DN'&& calendarEntry['calendarStatus'] != 'DH'" class="cal-detail">
                                                <!-- <span>{{ calendarEntry['calendarStatus']}}</span> -->
                                                <small>{{ calendarEntry['displayData'] }}, Naplószám: {{ calendarEntry['calendarFk']}}</small>
                                                <!-- <button type="button" class="btn btn-primary mb-xs-1 ml-2" (click)="onGetCalendarDetails(calendarEntry['calendarFk'])">
                        <i class="fa fa-info-circle"></i>
                        </button> -->

                                                <button type="button" class="btn btn-success ml-4" data-toggle="modal" data-keyboard="false" data-toggle="modal" data-target="#idopontkezelo" title="Törlés és módosítás" *ngIf="calendarEntry['calendarStatus'] == 'S' && Brand != 'MEDCSEPP' && Brand !== 'GONDOZAS'"
                                                    (click)="setAppointmentData(calendarEntry, 'bookAppointment')">
                          <i class="fa fa-edit"></i>
                        </button>
                                            </div>

                                            <div *ngIf="(calendarEntry['calendarStatus'] != 'F' && Brand !== 'MEDCSEPP')" class="cal-buttons-col buttonGroup ml-auto" [ngClass]="{'col-buttons-dispatcher': Brand == 'MEDCSEPP'}">
                                                <button *ngIf="!calendarEntry['registeredPatient'] && calendarEntry['calendarStatus'] != 'DD' && calendarEntry['calendarStatus'] != 'DN'&& calendarEntry['calendarStatus'] != 'DH'" type="button" class="btn btn-danger mr-1 mb-xs-1" title="Páciens regisztrálása"
                                                    data-toggle="modal" data-target="#paciensreg" (click)="setPatientData(calendarEntry, 'registerPatient')">
                          <i class="fa fa-user-plus"></i>
                        </button>


                                                <button *ngIf="calendarEntry['registeredPatient'] && calendarEntry['calendarStatus'] == 'S'
                        && Brand !== 'UDMED' && Brand !== 'GONDOZAS'" type="button" class="btn btn-primary mr-1 mb-xs-1" data-toggle="modal" data-target="#arrivePatient" (click)="isUpdateEkat(false); setPatientData(calendarEntry, 'arrivePatient')" title="Ellátás megkezdése">
                          <i class="fa fa-play"></i>
                        </button>

                                                <button *ngIf="calendarEntry['registeredPatient'] && calendarEntry['calendarStatus'] == 'S'
                        && Brand === 'UDMED'
                        && sharedDataService.getSelectedRole()['roleCode'] != 'AUTH$UDMED-VIRT_CCS'
                        && sharedDataService.getSelectedRole()['roleCode'] != 'AUTH$UDMED-VIRT_CCT'
                        && sharedDataService.getSelectedRole()['roleCode'] != 'AUTH$KENEZY_SCC'
                        && sharedDataService.getSelectedRole()['roleCode'] != 'AUTH$KENEZY_TCC'
                        " type="button" class="btn btn-primary mr-1 mb-xs-1" data-toggle="modal" data-target="#arrivePatientSimple" (click)="setPatientData(calendarEntry, 'arrivePatientSimple')" title="Ellátás megkezdése">
                          <i class="fa fa-play"></i>
                        </button>
                                                <button *ngIf="calendarEntry['registeredPatient'] && calendarEntry['calendarStatus'] == 'A'
                        && Brand !== 'UDMED'  && Brand !== 'GONDOZAS'" type="button" class="btn btn-primary mr-1 mb-xs-1" data-toggle="modal" data-target="#discardPatient" (click)="setPatientData(calendarEntry, 'discardPatient')" title="Ellátás lezárása">
                          <i class="fa fa-stop"></i>
                        </button>
                                                <button *ngIf="calendarEntry['registeredPatient'] && calendarEntry['calendarStatus'] == 'A'
                        && Brand === 'UDMED' && sharedDataService.getSelectedRole()['roleCode'] != 'AUTH$UDMED-VIRT_CCS'
                        && sharedDataService.getSelectedRole()['roleCode'] != 'AUTH$UDMED-VIRT_CCT'
                        && sharedDataService.getSelectedRole()['roleCode'] != 'AUTH$KENEZY_SCC'
                        && sharedDataService.getSelectedRole()['roleCode'] != 'AUTH$KENEZY_TCC'" type="button" class="btn btn-primary mr-1 mb-xs-1" data-toggle="modal" data-target="#discardPatientSimple" (click)="setPatientData(calendarEntry, 'discardPatientSimple')"
                                                    title="Ellátás lezárása">
                          <i class="fa fa-stop"></i>
                        </button>
                                                <button *ngIf="calendarEntry['registeredPatient'] &&  Brand !== 'MEDCSEPP' && calendarEntry['calendarStatus'] != 'DD' && calendarEntry['calendarStatus'] != 'DN'&& calendarEntry['calendarStatus'] != 'DH' &&
                        (Brand === 'UDMED' && sharedDataService.getSelectedRole()['roleCode'] != 'AUTH$UDMED-VIRT_CCT'
                        && sharedDataService.getSelectedRole()['roleCode'] != 'AUTH$KENEZY_TCC') || (Brand !== 'UDMED' && calendarEntry['registeredPatient'])" type="button" class="btn btn-primary mr-1 mb-xs-1" (click)="setPatientData(calendarEntry, 'documentation')"
                                                    data-backdrop="static" data-keyboard="false" data-toggle="modal" data-target="#documentation" title="Ellátás dokumentálása">
                          <i class="fa fa-medkit"></i>
                        </button>
                                                <button type="button" *ngIf="calendarEntry['registeredPatient'] && calendarEntry['calendarStatus'] == 'D'" (click)="openInvoice(calendarEntry['calendarFk'])" class="btn btn-primary mr-1 mb-xs-1" title="Számlázás">
                          <i class="fa fa-calculator"></i></button>
                                                <button *ngIf="Brand != 'MEDCSEPP' && calendarEntry['calendarStatus'] != 'DD' && calendarEntry['calendarStatus'] != 'DN'&& calendarEntry['calendarStatus'] != 'DH' && Brand !== 'GONDOZAS'" type="button" class="btn btn-primary mr-1 mb-xs-1" (click)="setPatientData(calendarEntry, 'bookAppointment')"
                                                    data-toggle="modal" data-target="#idopontkezelo" data-keyboard="false" data-toggle="modal" title="Előjegyzés">
                          <i class="fa fa-calendar"></i>
                        </button>
                                                <button *ngIf="calendarEntry['registeredPatient'] &&  Brand !== 'MEDCSEPP'" type="button" class="btn btn-primary mr-1 mb-xs-1" (click)="setPatientData(calendarEntry, 'registerPatient')" data-toggle="modal" data-target="#paciensreg" data-backdrop="static"
                                                    data-keyboard="false" title="Dokumentumok megtekintése">
                          <i class="fa fa-folder-open"></i>
                        </button>
                                                <button *ngIf="calendarEntry['registeredPatient'] && calendarEntry['calendarStatus'] != 'DD' && calendarEntry['calendarStatus'] != 'DN'&& calendarEntry['calendarStatus'] != 'DH' &&  Brand !== 'MEDCSEPP' && Brand === 'GONDOZAS'" type="button" class="btn btn-primary mb-xs-1 mr-1"
                                                    (click)="openFeter(calendarEntry)" title="Fekvő előjegyzés">
                          <fa-icon [icon]="faBed"></fa-icon>
                        </button>
                                                <!-- <button class="btn btn-primary mr-1 mb-xs-1" *ngIf="calendarEntry['registeredPatient'] && apiUrl == 'https://naucomm.nauticom.hu' && Brand!=='UDMED'  && !limitNaucare"
                                (click)="openButtons(h, calendarEntry)">
                          <fa-icon *ngIf="this.hamburgerButtons.includes(calendarEntry.id)" [icon]="faBars"></fa-icon>
                          <fa-icon *ngIf="!this.hamburgerButtons.includes(calendarEntry.id)" [icon]="faChevronRight"></fa-icon>
                        </button> -->
                                                <button class="btn btn-primary mr-1 mb-xs-1" *ngIf="calendarEntry['registeredPatient'] && Brand!=='UDMED' && calendarEntry['calendarStatus'] != 'DD' && calendarEntry['calendarStatus'] != 'DN'&& calendarEntry['calendarStatus'] != 'DH'  && Brand !== 'GONDOZAS'"
                                                    (click)="openButtons(h, calendarEntry)">
                          <fa-icon *ngIf="this.hamburgerButtons.includes(calendarEntry.id)" [icon]="faBars"></fa-icon>
                          <fa-icon *ngIf="!this.hamburgerButtons.includes(calendarEntry.id)" [icon]="faChevronRight"></fa-icon>
                        </button>
                                                <button *ngIf="h === calendarEntryButtons && calendarEntry['registeredPatient'] && Brand!=='UDMED' && eesztUserId && eesztUserId.startsWith('O')" type="button" class="btn btn-primary mr-1 mb-xs-1" (click)="initVenyiro(calendarEntry['calendarFk'])" title="Vényírás">
                          <fa-icon [icon]="faTablets"></fa-icon>
                        </button>
                                                <button *ngIf="this.hamburgerButtons.includes(calendarEntry.id) && calendarEntry['registeredPatient'] && Brand!=='UDMED'  && eesztUserId && eesztUserId.startsWith('O')" type="button" class="btn btn-primary mr-1 mb-xs-1" (click)="initVenyiro(calendarEntry['calendarFk'])"
                                                    title="Vényírás">
                          <fa-icon [icon]="faTablets"></fa-icon>
                        </button>
                                                <!-- <button
                          *ngIf="this.hamburgerButtons.includes(calendarEntry.id) && calendarEntry['registeredPatient'] && (calendarEntry['calendarStatus'] == 'A' || calendarEntry['calendarStatus'] == 'D')  && apiUrl == 'https://ekrest.nauticom.hu' && Brand!=='UDMED'"
                          type="button" class="btn btn-primary mr-1 mb-xs-1"
                          (click)="isUpdateEkat(true); setPatientCalendarDetail(calendarEntry);  setPatientData(calendarEntry, 'arrivePatient')" title="eseménykatalógus frissítés" data-backdrop="static"
                          data-keyboard="false" data-toggle="modal" data-target="#arrivePatient">
                          <fa-icon [icon]="faUserEdit"></fa-icon>
                        </button> -->
                                                <button *ngIf="this.hamburgerButtons.includes(calendarEntry.id) && calendarEntry['registeredPatient'] && (calendarEntry['calendarStatus'] == 'A' || calendarEntry['calendarStatus'] == 'D') && Brand!=='UDMED'" type="button" class="btn btn-primary mr-1 mb-xs-1"
                                                    (click)="isUpdateEkat(true); setPatientCalendarDetail(calendarEntry);  setPatientData(calendarEntry, 'arrivePatient')" title="eseménykatalógus frissítés" data-backdrop="static" data-keyboard="false" data-toggle="modal"
                                                    data-target="#arrivePatient">
                          <fa-icon [icon]="faUserEdit"></fa-icon>
                        </button>
                                                <!-- <button
                          *ngIf="showButtons && h === calendarEntryButtons && calendarEntry['registeredPatient'] && apiUrl == 'https://ekrest.nauticom.hu' && Brand!=='UDMED'"
                          type="button" class="btn btn-primary mr-1 mb-xs-1"
                          (click)="isUpdateEkat(true);  setPatientData(calendarEntry, 'arrivePatient')" title="eKat update" data-backdrop="static"
                          data-keyboard="false" data-toggle="modal" data-target="#arrivePatient">
                          <fa-icon [icon]="faUserEdit"></fa-icon>
                        </button> -->
                                                <!-- <button
                          *ngIf="showButtons && h === calendarEntryButtons && calendarEntry['registeredPatient'] && apiUrl == 'https://ekrest.nauticom.hu' && Brand!=='UDMED'"
                          type="button" class="btn btn-primary mr-1 mb-xs-1" data-backdrop="static" data-keyboard="false"
                          data-toggle="modal" data-target="#epro" (click)="setPatientData(calendarEntry, 'eesztepro')"
                          title="Epro">
                          <fa-icon [icon]="faIdCard"></fa-icon>
                        </button> -->
                                                <!-- <button
                          *ngIf="this.hamburgerButtons.includes(calendarEntry.id) && calendarEntry['registeredPatient'] && apiUrl == 'https://naucomm.nauticom.hu' && Brand!=='UDMED'"
                          type="button" class="btn btn-primary mr-1 mb-xs-1" data-backdrop="static" data-keyboard="false"
                          data-toggle="modal" data-target="#epro" (click)="setPatientData(calendarEntry, 'eesztepro')"
                          title="eProfil">
                          <fa-icon [icon]="faIdCard"></fa-icon>
                        </button> -->
                                                <button *ngIf="this.hamburgerButtons.includes(calendarEntry.id) && calendarEntry['calendarStatus'] != 'DD' && calendarEntry['calendarStatus'] != 'DN'&& calendarEntry['calendarStatus'] != 'DH' && calendarEntry['registeredPatient'] && apiUrl == 'https://ekrest.nauticom.hu' && Brand!=='UDMED'"
                                                    type="button" class="btn btn-primary mr-1 mb-xs-1" data-backdrop="static" data-keyboard="false" data-toggle="modal" data-target="#epro" (click)="setPatientData(calendarEntry, 'eesztepro')" title="Epro">
                          <fa-icon [icon]="faIdCard"></fa-icon>
                        </button>

                                            </div>
                                            <!-- <button (click)="updateCalendarEntryStatus(0)">T</button> -->
                                            <!-- calendarEntry['calendarStatus'] === 'DN' -->
                                            <div *ngIf="Brand == 'MEDCSEPP'" class="cal-buttons-col" [ngClass]="{'col-buttons-dispatcher': Brand == 'MEDCSEPP'}">
                                                <button *ngIf="!calendarEntry['registeredPatient']" type="button" class="btn btn-danger mr-1 mb-xs-1" title="Páciens regisztrálása" data-toggle="modal" data-target="#paciensreg" (click)="setPatientData(calendarEntry, 'registerPatient')">
                          <i class="fa fa-user-plus"></i>
                        </button>
                                                <button type="button" class="btn btn-primary ml-1 mb-xs-1" data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#laborder" title="Laborder" (click)="setPatientData(calendarEntry, 'labOrder')">
                          <i class="fa fa-folder"></i>
                        </button>
                                                <button type="button" class="btn btn-primary ml-1 mb-xs-1" data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#labsample" title="LabSample" (click)="setPatientData(calendarEntry, 'labSample')">
                          V
                        </button>
                                            </div>
                                            <div *ngIf="(calendarEntry['calendarStatus'] === 'DD' || calendarEntry['calendarStatus'] === 'DH') && Brand == 'MEDCSEPP'" class="cal-buttons-col" [ngClass]="{'col-buttons-dispatcher': Brand == 'MEDCSEPP'}">
                                                <button type="button" class="btn btn-primary mr-1 mb-xs-1" title="Páciens behívása" (click)="onCallPatient(calendarEntry['calendarFk'], calendarEntry['calendarStatus'])" [disabled]="selectedCsId === null">
                          <i class="fa fa-share"></i>
                        </button>
                                            </div>
                                            <div *ngIf="calendarEntry['calendarStatus'] === 'S' && Brand == 'MEDCSEPP'" class="cal-buttons-col" [ngClass]="{'col-buttons-dispatcher': Brand == 'MEDCSEPP'}">
                                                <button type="button" class="btn btn-primary mr-1 mb-xs-1" title="Páciens érkeztetése" (click)="onArrivePatient('', calendarEntry['calendarFk'])">
                          <i class="fa fa-map-marker"></i>
                        </button>
                                            </div>

                                            <div *ngIf="calendarEntry['calendarStatus'] == 'F' && calendarEntry['eventTypeFk'] != '12234835'  && calendarEntry['eventTypeFk'] != '52342809'" class="cal-time-col small-lineheight">
                                                <small>{{ calendarEntry['dateFrom'] | date:'H:mm' }}<br>-
                          <br>{{ calendarEntry['dateTo'] | date:'H:mm'}}</small>
                                            </div>

                                            <div *ngIf="calendarEntry['calendarStatus'] == 'F'" class="cal-detail-col"></div>
                                            <div *ngIf="calendarEntry['calendarStatus'] == 'F'" class="cal-buttons-col small-padding" [ngClass]="{'col-buttons-dispatcher': Brand == 'MEDCSEPP'}">
                                                <button type="button" class="btn btn-success mr-2" data-toggle="modal" data-target="#idopontkezelo" data-keyboard="false" data-toggle="modal" *ngIf="Brand !== 'UDMED' && (calendarEntry['dateFrom'] >= currentDate)" title="Előjegyzés" (click)="setAppointmentData(calendarEntry, 'bookAppointment')">
                          <i class="fa fa-plus-square"></i>
                        </button>
                                                <button *ngIf="!calendarEntry['registeredPatient'] && Brand === 'UDMED'" type="button" class="btn btn-danger mr-1 mb-xs-1" title="Páciens regisztrálása" data-toggle="modal" data-target="#paciensreg" (click)="setPatientData(calendarEntry, 'registerPatient')">
                          <i class="fa fa-user-plus"></i>
                        </button>

                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- [ngClass]="[twoColumns ? "col-sm-4" : "col-sm-3"]"
          [ngClass]="[twoColumns ? "col-sm-8" : "col-sm-9"]"
          -->



                </div>
                <!-- <div class="col-md-3 other-patients" *ngIf="Brand == 'MEDCSEPP'">
          <app-calendar-lab-other></app-calendar-lab-other>
        </div> -->


            </div>

        </div>
    </div>
</div>

<div *ngIf="calendarLoading" class="calendarLoading">
    <div class="osahanloading"></div>
    <div class="osahanloadingcontent">Loading calendar...</div>
</div>