import { environment } from './../../environments/environment';
import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedDataService } from '../services/shared-data.service';
import { fromEvent, Observable, Subscription, timer } from 'rxjs';
import { LoggerService } from '../services/logger.service';
import { Md5 } from 'md5-typescript';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime } from 'rxjs/operators';
import { RedirectService } from '../services/redirect.service';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../core/http/api.service';
import { OtpTokenRequest } from '../shared/models/request/otpTokenRequest';
import { MobilTokenRequest } from '../shared/models/request/mobilTokenRequest';
import { PlatformLocation } from '@angular/common';
import { PatientDataFromQueryParams } from '../shared/models/patientDataFromQueryParams';
import { ViewEncapsulation } from '@angular/core';
import { DateAdapter } from '@angular/material/core';

// import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
// import { filter, debounceTime, switchMap, map } from 'rxjs/operators';
// import { Observable } from 'rxjs';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class MainComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('year') yearInput: ElementRef;
  @ViewChild('month') monthInput: ElementRef;
  @ViewChild('day') dayInput: ElementRef;

  faKey = faKey;

  filteredVariables: Array<any> = new Array<any>();

  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  offline = false;

  realignInkBar = false;

  connectionStatusSubscriptions: Subscription[] = [];
  apiUrl = environment.apiUrl;
  environment = environment;

  majorError = false;
  majorErrorMessage = '';

  closeResult = '';

  availServic = null;
  availServicSubscription: Subscription;
  calendarListDataBookings = null;
  calendarListDataAll = null;
  freeAppointmentList = null;
  scheduleColorList = null;
  reloginForced: boolean;
  reloginInProgress = false;

  AuthInProgress: boolean;

  isLoading = true;


  serviceSelectDisabled = true;
  selectedRole = '';
  selectedService = null;
  selectedEvent = null;
  selectedAppointment = null;
  availServiceEmpty = true;

  isDisabled = true;

  baseSettings: FormGroup;
  roleSelect: FormGroup;
  serviceSelect: FormGroup;
  bookingDetails: FormGroup;

  serviceChange = new FormControl();
  roleChange = new FormControl();
  roleChangeSubscription = null;

  calendarLoading = true;
  freeAppointmentsLoading = true;
  dateFrom: string;
  dateSubscription: Subscription;
  patientDataForArrivalSubscription: Subscription;
  patientDataForArrivalSimpleSubscription: Subscription;

  appointmentDataSubscription: Subscription;
  appointmentDataForBooking: null;

  base_selecteddate = new FormControl(this.dateFrom);
  selectedDateYear = new FormControl(null);
  selectedDateMonth = new FormControl(null);
  selectedDateDay = new FormControl(null);
  two_columns = new FormControl();
  calendarDisplayMode = 1;

  patientDataFromQueryParams: PatientDataFromQueryParams;

  mobileTokenFormControl = new FormControl(null, Validators.required);

  otpTokenUserNameFormControl = new FormControl(null, Validators.required);
  otpTokenPasswordFormControl = new FormControl(null, Validators.required);
  otpTokenTokenFormControl = new FormControl(null, Validators.required);

  otpTokenFormGroup = new FormGroup({
    otpTokenUserNameFormControl: this.otpTokenUserNameFormControl,
    otpTokenPasswordFormControl: this.otpTokenPasswordFormControl,
    otpTokenTokenFormControl: this.otpTokenTokenFormControl
  });

  patientName = new FormControl();
  patientDataDocumentation = null;
  patientDataArrivePatient = null;
  patientDataArrivePatientSimple = null;
  patientDataDiscardPatient = null;
  patientDataDiscardPatientSimple = null;
  patientDataBookAppointment = null;
  patientDataRegisterPatient = null;
  patientDataLabOrder = null;
  patientDataLabSample = null;
  patientDataEesztEpro = null;
  patientDataEesztEkatUpdate = null;

  searchPatientResult = null;
  searchPatientResultLoading = false;
  searchPatientResultEmpty = false;

  dateMax = '2099-12-31';
  dateMin = '2000-01-01';
  dateInvalid = false;

  dateChangeSubscription: Subscription;

  reloginStateSubscription: Subscription;
  Brand = '';
  Feature = '';

  patientCalendarDetail;

  timeLeft: number = null;
  expirationDate: Date;

  constructor(public authService: AuthService,
    private dateAdapter: DateAdapter<Date>,
    private apiService: ApiService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sharedDataService: SharedDataService,
    private loggerService: LoggerService,
    private openTab: RedirectService,
    private location: PlatformLocation,
  ) {

    this.roleSelect = new FormGroup({
      'role': new FormControl()
    });

    // this.bookingDetails = new FormGroup({
    //   'patientName': this.patientName
    // });

    this.baseSettings = new FormGroup({
      'selecteddate': this.base_selecteddate,
      'two_columns': this.two_columns
    });

  }

  email = new FormControl('', Validators.required);
  password = new FormControl('', Validators.required);

  loginForm = new FormGroup({
    email: this.email,
    password: this.password
  });

  layoutWidth = 1;
  bcpMessageCountSubscription: Subscription;
  bcpMessageCountNew = 0;
  bcpMessageCountError = 0;

  eesztMessageListSubscription: Subscription;
  eesztMessageList: [];
  isEesztUser: boolean;
  eszigTokenExpiration = '';
  eszigTokenValid = false;
  eszigTokenExpirationSubscription: Subscription;
  eszigAuthInProgress = false;
  eszigError = 0;
  eszigErrorMessage = '';

  csIdListSubscription: Subscription;
  csIdList = [];
  csIdListLoading = true;

  bcpCount: number;
  openEkatCounter: number;
  setSelectedCsIdSubscription: Subscription;

  medcsepp_role = '';

  @ViewChild('mainWindowSize', { static: true }) mainWindowSize: ElementRef;
  @ViewChild('authContent', { static: true }) content: ElementRef;

  ngAfterViewInit() {
    if (this.sharedDataService.justLoggedIn) {
      if (this.Brand !== 'NauLight') {
        this.open(this.content);
        this.sharedDataService.justLoggedIn = false;
      }
    }
    const windowWidth = this.mainWindowSize.nativeElement.clientWidth;
    let layoutWidth = 1;
    if (this.calendarDisplayMode === 2) {
      if (windowWidth < 1128) {
        layoutWidth = 1;
      } else if (windowWidth >= 1128) {
        layoutWidth = 2;
      }
    } else {
      if (windowWidth < 799) {
        layoutWidth = 1;
      } else if (windowWidth >= 799) {
        layoutWidth = 2;
      }
    }
    this.layoutWidth = layoutWidth;
    // console.log('main layout initial width set:', layoutWidth);
    this.sharedDataService.setLayoutWidth(layoutWidth);
  }

  ngOnInit() {
    this.dateAdapter.setLocale('hu');
    this.dateAdapter.getFirstDayOfWeek = () => { return 1; }
    this.filteredVariables = this.authService.getUserData()['servicePoints'];
    /* this.activatedRoute.queryParams.subscribe(params => {
      this.patientDataFromQueryParams = {
        address: params['address'],
        birthName: params['birthName'],
        birthPlace: params['birthPlace'],
        city: params['city'],
        country: params['country'],
        dateOfBirth: params['dateOfBirth'],
        email: params['email'],
        mothersname: params['mothersname'],
        name: params['name'],
        ssn: +params['ssn'],
        zip: +params['zip']
      }
    });
    console.log(this.patientDataFromQueryParams); */
    this.sharedDataService.openEkatCount.subscribe(ekatCounter => {
      this.openEkatCounter = ekatCounter;
    });
    // console.log (localStorage.getItem('callerSystem'));
    // console.log ('sp', this.authService.getUserData()['servicePoints']);
    if (localStorage.getItem('callerSystem') && localStorage.getItem('callerSystem') === 'test') {
      this.sharedDataService.callerSystemAPIURL = 'https://testcallersystem.nauticom.hu/caller-system/rest/';
      console.log('testcallersystem');
    }

    this.Brand = this.authService.getBrand();
    this.Feature = this.authService.getFeature();
    // console.log ('main brand', this.Brand);
    if (this.Brand === 'MEDCSEPP') {
      this.sharedDataService.reloadSelectedCsId();
      this.sharedDataService.loadCsidList();


      this.setSelectedCsIdSubscription = this.sharedDataService.selectedCsIdShared.subscribe(data => {
        // console.log ('X1', data);
        if (data) {
          // console.log ('X2');
          const csIdObj = this.csIdList.find(obj => obj.csId == data);
          if (csIdObj) {
            // console.log ('X3', csIdObj['name']);
            this.medcsepp_role = csIdObj['name'];
          }
        }
      });
    }


    if (this.Brand === 'MEDCSEPP') {

      this.csIdListSubscription = this.sharedDataService.csIdListShared.subscribe(data => {
        // console.log ('csIdList settings', data);
        this.csIdList = data['data'];
        this.csIdListLoading = data['isLoading'];
        const csIdObj = this.csIdList.find(obj => obj.csId == this.sharedDataService.getSelectedCsId());
        //console.log ('csIdObj', csIdObj);
        if (csIdObj) {
          this.medcsepp_role = csIdObj['name'];
        }
      });
    }




    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.connectionStatusSubscriptions.push(this.onlineEvent.subscribe(e => {
      this.offline = false;
      console.log('Online...');
    }));

    this.connectionStatusSubscriptions.push(this.offlineEvent.subscribe(e => {
      this.offline = true;
      console.log('Offline...');
    }));

    this.isEesztUser = this.authService.getUserData()['isEesztUser'];
    this.eszigTokenExpiration = this.sharedDataService.getEszigTokenExpiration();
    if (Number(this.eszigTokenExpiration) > new Date().getTime()) {
      this.eszigTokenValid = true;
      this.calculateRemainingTime();
    } else {
      this.eszigTokenValid = false;
    }
    this.sharedDataService.isEesztActive = this.eszigTokenValid;

    this.eszigTokenExpirationSubscription = this.sharedDataService.eszigTokenExpirationShared.subscribe(data => {
      this.eszigTokenExpiration = data;
      if (Number(this.eszigTokenExpiration) > new Date().getTime()) {
        this.eszigTokenValid = true;
      } else {
        this.eszigTokenValid = false;
      }
      this.sharedDataService.isEesztActive = this.eszigTokenValid;
    });

    // console.log('main eszigTokenExpiration', this.eszigTokenExpiration);
    // console.log('isEesztUser', this.isEesztUser);

    console.log('%c ---------- nauCare v' + environment.version + ' --------------', 'background: #5555ff; color: white');

    this.availServicSubscription = this.sharedDataService.availServicesShared.subscribe(data => {
      this.availServic = data;
      this.isLoading = false;
      if (this.availServic.length === 0) {
        this.availServiceEmpty = true;
      } else {
        this.availServiceEmpty = false;
      }
    });

    this.reloginStateSubscription = this.sharedDataService.reloginState.subscribe(value => {
      // console.log ('reLogin state update in main:' + value);
      this.reloginForced = value;
    });

    this.email.setValue(this.authService.getUserData()['ekUserName']);
    this.email.disable();

    this.dateFrom = this.sharedDataService.getCurrentDate();
    const dateFromAsDate = new Date(this.dateFrom);
    // console.log('setting date:', this.dateFrom);
    /* this.base_selecteddate.setValue(this.sharedDataService.dateStringToNgbDate(this.dateFrom)); */
    this.base_selecteddate.setValue(this.dateFrom);
    this.selectedDateYear.setValue(dateFromAsDate.getFullYear());
    this.selectedDateMonth.setValue(('0' + (dateFromAsDate.getMonth() + 1)).slice(-2));
    this.selectedDateDay.setValue(('0' + dateFromAsDate.getDate()).slice(-2));

    // check if selected Role is stored in shared Data Service

    if (this.sharedDataService.getSelectedRole() !== null) {
      this.selectedRole = this.sharedDataService.getSelectedRole();
    } else {
      this.selectedRole = this.authService.getUserData()['servicePoints'][0];
      this.sharedDataService.setSelectedRole(this.selectedRole);
    }

    if (this.selectedRole && this.selectedRole['spAndRoleCode']) {
      this.roleSelect.get('role').setValue(this.selectedRole['spAndRoleCode']);
    } else {
      this.majorError = true;
      this.majorErrorMessage = 'A felhasználó nincs hozzárendelve szolgáltatási ponthoz és szerepkörhöz';
    }


    // Service point es Role valtas subscription

    this.roleChangeSubscription = this.roleSelect.valueChanges.subscribe(selectedRole => {
      this.isLoading = true;
      const servicePoints = this.authService.getUserData()['servicePoints'];

      // console.log('find sp and role');
      const selectedSpAndRole = servicePoints.find(obj => obj.spAndRoleCode === selectedRole['role']);

      // console.log(selectedRole['role']);

      if (this.selectedRole === selectedSpAndRole) {
        // console.log('Role didnt change');
      } else {
        this.selectedRole = selectedSpAndRole;
        this.sharedDataService.setSelectedRole(this.selectedRole);
        // console.log('selected Role: ' + JSON.stringify(selectedRole));
        this.sharedDataService.loadPatientList();
        this.sharedDataService.loadInstituteDoctors();
        this.sharedDataService.clearSelectedService();

        // getAvailService
        if (this.selectedRole) {
          this.initialize(this.selectedRole['roleCode'], '');
        }
      }
    });


    // this.sharedDataService.loadPatientList();
    if (this.selectedRole) {
      this.sharedDataService.loadImplantRegBase();
      this.sharedDataService.loadBnoList();
      // this.sharedDataService.loadInstituteDoctors();
      this.sharedDataService.loadPatientCareTypes();
      this.sharedDataService.loadGetawayTypes();
      this.sharedDataService.loadAdmissionTypes();
      this.sharedDataService.loadCalendarModReasons();
    }
    // this.sharedDataService.loadDocumentTypes();

    if (this.sharedDataService.getConfig('calendarDisplayMode') >0 && this.sharedDataService.getConfig('calendarDisplayMode') <5) {
      this.calendarDisplayMode = this.sharedDataService.getConfig('calendarDisplayMode');
    }
    // console.log ('this.calendarDisplayMode', this.calendarDisplayMode);

    /* this.dateChangeSubscription = this.base_selecteddate.valueChanges.pipe(
      debounceTime(1000)
    ).subscribe(value => {
      if (value !== '') {
        console.log('1 date change value:', value);
        // console.log('2 date change value elements:', value.year + '-' + value.month + '-' + value.day);

        if (value.year !== undefined) { // valid NgbDate is set

          const dateString = value.year + '-' + ('0' + (value.month)).slice(-2) + '-' + ('0' + value.day).slice(-2);
          const dateFromValue = new Date(dateString);
          // console.log('3 dateFromValue:', dateFromValue);

          this.sharedDataService.setCurrentDate(dateFromValue);
          this.dateInvalid = false;

        } else {

          const dateValue = value.replace(/\./g, '-');
          // console.log('4 dateValue', dateValue);

          const dateObject = this.sharedDataService.dateFromUserinput(dateValue);

          // console.log ('5 dateObject', dateObject);

          if (dateObject && !isNaN(dateObject.getTime())) {
            const newDate = this.sharedDataService.dateToDateString(dateObject);
            // console.log('6 ngbDate converted:', newDate);
            // this.sharedDataService.setCurrentDate(dateObject);
            this.base_selecteddate.setValue(this.sharedDataService.dateStringToNgbDate(newDate));
            this.dateInvalid = false;
          } else {
            // console.log('7 date not set, date is invalid');
            this.dateInvalid = true;
          }
        }
        // else {
        //   console.log('9 date not set, date is invalid');
        //      this.dateInvalid = true;
        // }
      }
    }); */

    this.dateChangeSubscription = this.base_selecteddate.valueChanges.subscribe(value => {
      if (value) {
        const selectedDate = new Date(value);
        this.selectedDateYear.setValue(selectedDate.getFullYear());
        this.selectedDateMonth.setValue(('0' + (selectedDate.getMonth() + 1)).slice(-2));
        this.selectedDateDay.setValue(('0' + (selectedDate.getDate())).slice(-2));
        this.sharedDataService.setCurrentDate(selectedDate);
      } else {

      }
    });

    this.selectedDateYear.valueChanges.subscribe(userInputYear => {
      if (userInputYear.length === 4) {
        this.monthInput.nativeElement.focus();
      } else if (userInputYear.length > 4) {
        this.selectedDateYear.setValue(userInputYear.substring(0, 4));
        this.selectedDateMonth.setValue(userInputYear.substring(4, userInputYear.length));
      }
    });

    this.selectedDateMonth.valueChanges.subscribe(userInputMonth => {
      if (userInputMonth.length === 2) {
        this.dayInput.nativeElement.focus();
      } else if (userInputMonth.length > 2) {
        this.selectedDateMonth.setValue(userInputMonth.substring(0, 2));
        this.selectedDateDay.setValue(userInputMonth.substring(2, userInputMonth.length));
      } else if (!userInputMonth) {
        this.yearInput.nativeElement.focus();
      }
    });

    this.selectedDateDay.valueChanges.subscribe(userInputDay => {
      if (!userInputDay) {
        this.monthInput.nativeElement.focus();
      }
    });


    // this.patientName.valueChanges.pipe(
    //   filter(val => val.length >= 4),
    //   debounceTime(500),
    //   switchMap(val => {
    //     const params = {
    //       'currentUserTapass': this.authService.getUserData()['ekCardNo'],
    //       'currentRoleCode': this.selectedRole,
    //       'contextParams': 'NAME_PART=' + val + '#'
    //     };

    //     console.log(params);
    //     this.searchPatientResultLoading = true;

    //     return this.http.post(this.apiUrl + '/rest/neu/authentication/searchinstituteusers', params, {
    //       headers: new HttpHeaders({
    //         'Content-Type': 'application/json'
    //       })
    //     });

    //   }
    //   ))
    //   .subscribe(
    //     patients => {
    //       this.searchPatientResultLoading = false;
    //       this.searchPatientResult = patients;
    //       if (this.searchPatientResult.length === 0) {
    //         this.searchPatientResultEmpty = true;
    //       } else {
    //         this.searchPatientResultEmpty = false;
    //       }
    //     }
    //     // this.searchPatient(patientName)
    //   );

    this.patientDataForArrivalSubscription = this.sharedDataService.patientDataForArrival.subscribe((data: any) => {
      if (data['NGdestinationService'] === 'arrivePatient') {
        this.patientDataArrivePatient = data;
      } else if (data['NGdestinationService'] === 'arrivePatientSimple') {
        this.patientDataArrivePatientSimple = data;
      } else if (data['NGdestinationService'] === 'documentation') {
        this.patientDataDocumentation = data;
      } else if (data['NGdestinationService'] === 'discardPatient') {
        this.patientDataDiscardPatient = data;
      } else if (data['NGdestinationService'] === 'discardPatientSimple') {
        this.patientDataDiscardPatientSimple = data;
      } else if (data['NGdestinationService'] === 'bookAppointment') {
        this.patientDataBookAppointment = data;
      } else if (data['NGdestinationService'] === 'registerPatient') {
        this.patientDataRegisterPatient = data;
      } else if (data['NGdestinationService'] === 'labOrder') {
        this.patientDataLabOrder = data;
      } else if (data['NGdestinationService'] === 'labSample') {
        this.patientDataLabSample = data;
      } else if (data['NGdestinationService'] === 'eesztepro') {
        this.patientDataEesztEpro = data;
      } else if (data['NGdestinationService'] === 'eKatUpdate') {
        this.patientDataEesztEkatUpdate = data;
      }
    });

    this.appointmentDataSubscription = this.sharedDataService.appointmentDataShared.subscribe((data: any) => {
      if (data['NGdestinationService'] === 'bookAppointment') {
        this.appointmentDataForBooking = data;
      }
    });


    this.dateSubscription = this.sharedDataService.selectedDateChange.subscribe((date: string) => {
      this.dateFrom = date;
      // this.initialize(this.selectedRole['roleCode'], '');
    }
    );

    this.bcpMessageCountSubscription = this.sharedDataService.bcpMessageCountShared.subscribe(data => {
      this.bcpMessageCountNew = data['newCount'];
      this.bcpMessageCountError = data['errorCount'];
    });

    this.eesztMessageListSubscription = this.sharedDataService.bcpMessageListShared.subscribe(data => {
      // console.log('main eeszt message list', data);
      this.eesztMessageList = data;
    });

    if (this.selectedRole && this.environment.eesztEnabled === true) {
      this.sharedDataService.loadBcpMessageCount();
      this.sharedDataService.loadBcpMessages();
    }

    if (this.selectedRole) {
      this.initialize(this.selectedRole['roleCode'], '');
    }


    this.sharedDataService.patientCalendarDetail.subscribe(patientCalendarDetail => {
      this.patientCalendarDetail = patientCalendarDetail;
    });
    setInterval(() => {
      this.sharedDataService.getBcpMessagesCount().subscribe(amount => {
        this.bcpCount = amount['data']['errorCount'] + amount['data']['newCount'];
      });
    }, 300000);
  }



  // OnInit end

  ngOnDestroy() {
    if (this.availServicSubscription) {
      this.availServicSubscription.unsubscribe();
    }
    if (this.roleChangeSubscription) {
      this.roleChangeSubscription.unsubscribe();
    }
    if (this.dateChangeSubscription) {
      this.dateChangeSubscription.unsubscribe();
    }
    if (this.patientDataForArrivalSubscription) {
      this.patientDataForArrivalSubscription.unsubscribe();
    }
    if (this.dateSubscription) {
      this.dateSubscription.unsubscribe();
    }
    if (this.reloginStateSubscription) {
      this.reloginStateSubscription.unsubscribe();
    }
    if (this.bcpMessageCountSubscription) {
      this.bcpMessageCountSubscription.unsubscribe();
    }
    if (this.eesztMessageListSubscription) {
      this.eesztMessageListSubscription.unsubscribe();
    }
    if (this.eszigTokenExpirationSubscription) {
      this.eszigTokenExpirationSubscription.unsubscribe();
    }
  }

  onBaseSelecteddateSubmit() {
    if (this.selectedDateYear.value && this.selectedDateMonth.value && this.selectedDateDay.value) {
      this.base_selecteddate.setValue(this.selectedDateYear.value + '-' +
        this.selectedDateMonth.value + '-' +
        this.selectedDateDay.value);
    }
  }

  initialize(selectedRoleCode: string, service: string) {
    // console.log('Main init start');

    this.sharedDataService.loadAvailServices();
    // if (this.sharedDataService.getAvailservice(selectedRoleCode) === null) {
    //   // console.log('Load avail services:');
    //   this.getAvailService(selectedRoleCode).subscribe(
    //     (data: any[]) => {
    //       this.sharedDataService.setAvailservices(selectedRoleCode, data['objAvailServicesList']);
    //       this.isLoading = false;
    //     }
    //   );
    // } else {
    //   this.isLoading = false;
    // }

    this.sharedDataService.loadPatientList();
    this.sharedDataService.loadInstituteDoctors();
    this.sharedDataService.loadDocumentTypes();

  }

  toggleCalendarDisplayMode(value) {
    this.calendarDisplayMode = value;
    this.sharedDataService.setConfig('calendarDisplayMode', value);
  }

  returnScheduleColorList() {
    return this.scheduleColorList.slice();
  }

  onLogout() {
    const messageHeader = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID(),
        sessionId: null
      }
    };
    this.apiService.samlLogOut(messageHeader).subscribe(res => {
      if (res.code > 0) {
        console.log('res: ', res);
      } else {
        this.toastr.error(res.message);
      }
    }, err => {
      this.toastr.error(err.message);
    });

    this.authService.doLogout();
    this.sharedDataService.resetAllData();
    this.router.navigate(['/login']);
  }


  relogin() {
    // console.log('reLogin');
    this.reloginInProgress = true;
    this.doLogin(this.authService.getUserData()['ekUserName'], Md5.init(this.loginForm.value['password']))
      .subscribe(
        (data: Response) => {
          this.reloginInProgress = false;
          if (data['code'] === 1) {
            this.authService.setUserData(data);
            this.sharedDataService.setReloginState(false);
          } else {
            // Error on trying to relogin
          }
        }
      );
  }

  doLogin(username: string, password: string) {
    return this.http.get(this.apiUrl + '/rest/neu/authentication/loginusermobile/' +
      username + '/' + password + '/' + this.authService.getUUID() + '/hu');
  }


  logout() {
    this.authService.doLogout();
    this.sharedDataService.resetAllData();
    this.router.navigate(['/login']);
  }


  onResize($event) {

    // console.log('onResize');
    let layoutWidth = 1;
    if (this.calendarDisplayMode === 2) {
      if ($event.target.innerWidth < 1128) {
        layoutWidth = 1;
      } else if ($event.target.innerWidth >= 1128) {
        layoutWidth = 2;
      }
    } else {
      if ($event.target.innerWidth < 799) {
        layoutWidth = 1;
      } else if ($event.target.innerWidth >= 799) {
        layoutWidth = 2;
      }
    }

    if (this.layoutWidth !== layoutWidth) {
      this.layoutWidth = layoutWidth;
      // console.log('main layout width change:', layoutWidth);
      this.sharedDataService.setLayoutWidth(layoutWidth);
    }

  }

  openHelp(anchor) {
    const newWindow = window.open('https://' + this.authService.getHostname() + '/kezikonyv/' + anchor);
  }

  open(content) {

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.selectedRole['roleCode'],
        currentServicePointId: this.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      }
    };
    this.apiService.getEesztUserDetail(params).subscribe(eesztUser => {
      this.otpTokenFormGroup.controls.otpTokenUserNameFormControl.setValue(eesztUser.eesztUserId);
      this.modalService.open(content, { size: 'lg' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed`;
      });
    });

  }

  initOtpToken() {
    if (this.otpTokenFormGroup.valid) {
      const otpTokenRequest: OtpTokenRequest = {
        messageHeader: {
          currentTapass: this.authService.getUserData()['ekCardNo'],
          currentRole: this.sharedDataService.selectedRole['roleCode'],
          currentServicePointId: this.sharedDataService.selectedRole['spId'],
          locale: 'hu',
          deviceId: this.authService.getUUID(),
          sessionId: null
        },
        eesztUserId: this.otpTokenFormGroup.controls.otpTokenUserNameFormControl.value,
        password: this.otpTokenFormGroup.controls.otpTokenPasswordFormControl.value,
        generatedToken: this.otpTokenFormGroup.controls.otpTokenTokenFormControl.value
      };
      this.AuthInProgress = true;
      this.apiService.getOtpToken(otpTokenRequest).subscribe(res => {
        this.AuthInProgress = false;
        if (res.code < 0) {
          this.toastr.error(res.message, 'Hiba történt az authentikáció során!');
        } else {
          this.sharedDataService.setEszigTokenExpiration(res['expirationDate']);
          this.calculateRemainingTime();
          this.toastr.success('sikeres authentikáció');
        }
      }, err => {
        this.toastr.error(err.error.message);
        this.AuthInProgress = false;
      });
    } else {
      this.otpTokenFormGroup.controls.otpTokenUserNameFormControl.markAsTouched();
      this.otpTokenFormGroup.controls.otpTokenPasswordFormControl.markAsTouched();
      this.otpTokenFormGroup.controls.otpTokenTokenFormControl.markAsTouched();

      this.toastr.error('Minden mező kitöltése kötelező!');
    }
  }

  initMobilToken() {
    if (this.mobileTokenFormControl.value) {
      const mobilTokenRequest: MobilTokenRequest = {
        messageHeader: {
          currentTapass: this.authService.getUserData()['ekCardNo'],
          currentRole: this.sharedDataService.selectedRole['roleCode'],
          currentServicePointId: this.sharedDataService.selectedRole['spId'],
          locale: 'hu',
          deviceId: this.authService.getUUID(),
        },
        motp: this.mobileTokenFormControl.value
      };
      this.AuthInProgress = true;
      this.apiService.getMobilToken(mobilTokenRequest).subscribe(res => {
        this.AuthInProgress = false;
        if (res.code < 0) {
          this.toastr.error(res.message, 'Hiba történt az authentikáció során!');
        } else {
          this.sharedDataService.setEszigTokenExpiration(res['expirationDate']);
          this.calculateRemainingTime();
          this.toastr.success('sikeres authentikáció');
        }
      }, err => {
        this.toastr.error(err.error.message);
        this.AuthInProgress = false;
      });
    } else {
      this.mobileTokenFormControl.markAsTouched();
      this.toastr.error('Minden mező kitöltése kötelező!');
    }
  }

  setRealignInkBar() {
    this.realignInkBar = !this.realignInkBar;
  }

  initEszig() {
    if (this.eszigTokenValid === false) {
      this.eszigAuthInProgress = true;
      // console.log('init eszig');
      this.sharedDataService.requireEszigToken().subscribe(
        (data: Response) => {
          if (data['code'] === 1) {
            // console.log('eszig data', data);
            this.eszigAuth(data['response']['data']).subscribe(
              (eszigResponse: Response) => {
                // console.log('eszig response', eszigResponse);
                this.sharedDataService.getEszigToken(eszigResponse['id_token']).subscribe(
                  (eSzigTokenData: Response) => {
                    if (eSzigTokenData['code'] === 1) {
                      this.eszigAuthInProgress = false;
                      this.sharedDataService.setEszigTokenExpiration(eSzigTokenData['expirationDate']);
                      this.calculateRemainingTime();
                    } else {
                      this.eszigAuthInProgress = false;
                      this.eszigError = 1;
                      this.eszigErrorMessage = `Hiba az authentikáció során: ` + eSzigTokenData;
                      console.error('getEszigToken ERROR:', eSzigTokenData);
                    }
                  },
                  (eszigTokenError: Error) => {
                    this.eszigError = 1;
                    this.eszigErrorMessage = `Hiba az authentikáció során: ` + eszigTokenError;
                    this.eszigAuthInProgress = false;
                  });
              },
              eszigError => {
                console.error('eszig error', eszigError);
                this.eszigError = 1;
                this.eszigErrorMessage = `Hiba az eszemélyi klienssel való kommunikáció során,
                kérjük ellenőrízze hogy fut-e az eszemélyi kliens.`;
                this.eszigAuthInProgress = false;
              }
            );
          } else {
            this.eszigAuthInProgress = false;
            // Error
          }
        });
    } else {
      // console.log('eszig token valid');
    }
  }

  calculateRemainingTime() {
    timer(0, 1000).subscribe(
      () => {
        this.timeLeft = Number(this.eszigTokenExpiration) - new Date().getTime();
      });
  }


  loadBcpMessageCount() {
    this.sharedDataService.loadBcpMessageCount();
  }

  loadBcpMessages() {
    this.sharedDataService.loadBcpMessages();
  }


  eszigAuth(token: string) {
    return this.http.get('http://localhost:19750' + token);
  }


  logEkUsername() {
    // console.log('ekusername', this.authService.getUserData()['ekUserName']);
  }

  onCloseEszigError() {
    this.eszigError = 0;
  }

  onCloseMajorError() {
    this.majorError = false;
  }

  initVenyiro() {
    const params = {
      participantId: 1234567,
      providerUserId: 987654
    };

    this.openTab.postHtml(params, 'http://localhost:8888/$');
  }



}



