import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Md5 } from 'md5-typescript';
import { Router } from '@angular/router';
import { LoggerService } from '../services/logger.service';
import { environment } from '../../environments/environment';
import { ApiService } from '../core/http/api.service';
import { SharedDataService } from '../services/shared-data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

@Injectable()
export class LoginComponent implements OnInit, OnDestroy {

  apiUrl = environment.apiUrl;
  Brand = '';
  environment = environment;

  title = 'nautest';

  loginForm: FormGroup;
  loggingIn = false;

  error = null;
  errorMessage = '';

  rssInterval: any;

  rssMessages: string[] = [];

  constructor(public authService: AuthService,
              private http: HttpClient,
              private router: Router,
              private loggerService: LoggerService,
              private apiService: ApiService,
              private sharedDataService: SharedDataService
              ) {}

  ngOnInit() {
    this.initForm();
    this.Brand = this.authService.getBrand();
    this.getRssMessages();
    this.rssInterval = setInterval(() => {
      this.getRssMessages();
    }, 60000);
  }

  ngOnDestroy () {
    clearInterval(this.rssInterval);
  }

  getRssMessages() {
    /*this.apiService.getRss().subscribe(rss => {
      this.rssMessages = [];
      rss.rss.channel.item.forEach(rssItem => {
        this.rssMessages.push(rssItem.description);
      });
    });*/
  }

  initForm() {
    const email = '';
    const password = '';
    const rememberMe = false;


    this.loginForm = new FormGroup({
      'email': new FormControl(email, Validators.required),
      'password': new FormControl(password, Validators.required)
    });
  }

  onLogin() {
    console.log('Login');
    this.loggingIn = true;
    // console.log(this.loginForm.value);
    this.authService.doLogin(this.loginForm.value['email'], Md5.init(this.loginForm.value['password']))
      .subscribe(
        (data: Response) => {
          this.loggingIn = false;
          if (data['code'] === 1) {
            if (data['servicePoints'].length > 0) {
              this.sharedDataService.justLoggedIn = true;
            // console.log('Logged in!');
            // console.log('loginResponse', data);
              this.authService.setUserData(data);
              this.router.navigate(['/main'], { queryParamsHandling: "preserve" });
            } else {
              this.error = 1;
              this.errorMessage = 'A felhasználó nincs hozzárendelve szolgáltatási ponthoz!';
            }
          } else {
            // hiba a bejelentkezés során
            this.error = 1;
            if (data['code'] === -1) {
              this.errorMessage = 'Hibás felhasználónév, vagy jelszó!';
            } else {
              this.errorMessage = data['code'] + ' ' + data['message'];
            }
          }
          // this.initialize();
        }, error => {
          this.loggingIn = false;
          this.error = 1;
          this.errorMessage = 'Hiba a szerverrel való kommunikáció során!';
        }
    );
  }

  onLogUserData() {
    // console.log(this.authService.getUserData());
  }

  openNewPage() {
    const newWindow = window.open('http://index.hu');
    // console.log (newWindow);

  }

  openHelp(anchor) {
    const newWindow = window.open('https://' + this.authService.getHostname() + '/kezikonyv/' + anchor);
  }


}
