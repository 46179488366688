import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTitleCase]'
})
export class TitleCaseDirective {

  constructor(
    public ngControl: NgControl
  ) { }

  @HostListener('ngModelChange', ['$event'])
  onInputChange(value: string) {
    // console.log ('tc change', value);
    if (value !== null && value !== undefined) {

      let arrStr = value.split(' ');
      let titleCaseStr = arrStr.map((str) => (str.charAt(0).toUpperCase() + str.toLowerCase().slice(1))).join(' ');

      arrStr = titleCaseStr.split('-');
      titleCaseStr = arrStr.map((str) => (str.charAt(0).toUpperCase() + str.slice(1))).join('-');
      /* 
       arr = titleCaseStr.split('-');
 
       arr.map((str) => (titleCaseStr += (str.charAt(0).toUpperCase() + str.substring(1, str.length).toLowerCase() + ' ')));
 
       console.log('end: ', titleCaseStr);*/


      /*let arrStr = value.toLowerCase().split('-');*/
      //let titleCaseStr = arrStr.map((str) => (str.charAt(0).toUpperCase() + str.slice(1))).join('-');

      // console.log ('tc new', titleCaseStr);
      this.ngControl.valueAccessor.writeValue(titleCaseStr);
    }
  }

}
