<div [formGroup]="form" style="min-height: 600px;">
    <ng-container formArrayName="allBnoSurgery">
        <ng-container *ngFor="let bnoSurgeryForm of allBnoSurgery.controls; let i = index">
            <div class="row mt-3" [formGroup]="bnoSurgeryForm">

                <div class="col-sm-4">
                    <ng-select placeholder="BNO" bindValue="id" bindLabel="concatedName" [searchable]="true"  formControlName="bnoList" [items]="allBno" [loading]="bnoListLoading" [clearable]="false">
                        <ng-template ng-option-tmp let-item="item">
                            <div title="{{item.concatedName}}">{{item.concatedName}}</div>
                        </ng-template>
                    </ng-select>
                </div>

                <div class="col-sm-3">
                    <ng-select *ngIf="i !== 0" placeholder="Diagnózis típus" [searchable]="true"  formControlName="diagType" [items]="allDiagType" [clearable]="false" bindLabel="concatedName" bindValue="id" [loading]="diagTypeListLoading">
                        <ng-template ng-option-tmp let-item="item">
                            <div title="{{item.concatedName}}">{{item.concatedName}}</div>
                        </ng-template>
                    </ng-select>
                    <ng-select *ngIf="i === 0" placeholder="Diagnózis típus" [searchable]="true"  formControlName="diagType" [items]="reqDiagType" [clearable]="false" bindLabel="concatedName" bindValue="id" [loading]="diagTypeListLoading">
                        <ng-template ng-option-tmp let-item="item">
                            <div title="{{item.concatedName}}">{{item.concatedName}}</div>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="col-sm-2">
                    <ng-select placeholder="Oldaliság" [searchable]="true" formControlName="laterality" bindLabel="name" bindValue="code" [items]="laterality"></ng-select>
                </div>
                <div class="col-sm-1 mt-0" style="display: table;">
                    <a role="button" class="icon" (click)="removeBno(i)">
                        <i class="fa fa-trash" title="Who törlése"></i>
                    </a>
                </div>
            </div>
        </ng-container>
        <p class="other-surgery mt-2 col-sm-2" (click)="addBnoSurgery()">További BNO megadása</p>
    </ng-container>

    <ng-container formArrayName="allWhoSurgery">
        <ng-container *ngFor="let whoSurgeryForm of allWhoSurgery.controls; let i = index">
            <div class="row mt-3" [formGroup]="whoSurgeryForm">

                <div class="col-sm-2">
                    <ng-select placeholder="WHO" [searchable]="true" formControlName="whoList" [items]="allWho" bindLabel="name" bindValue="id" [loading]="whoListLoading" [clearable]="false" (change)="checkAnaestIsReq(i)">
                        <ng-template ng-option-tmp let-item="item">
                            <div title="{{item.name}}">{{item.name}}</div>
                        </ng-template>
                    </ng-select>
                </div>

                <div *ngIf="admTypeCategory === 'FEKVO'" class="col-sm-2">
                    <ng-select placeholder="Anesztézia" [searchable]="true" formControlName="anaestOeno" [items]="anaestOeno" bindLabel="name" bindValue="code" [loading]="whoListLoading" [clearable]="false"></ng-select>
                </div>


                <div class="col-sm-2">
                    <ng-select placeholder="Beavatkozás típusa" formControlName="oenoType" [items]="oenoType" bindLabel="name" bindValue="code">
                        <ng-template ng-option-tmp let-item="item">
                            <div title="{{item.name}}">{{item.name}}</div>
                        </ng-template>
                    </ng-select>
                </div>

                <div class="col-sm-2">
                    <ng-select placeholder="Beavatkozás jellege" formControlName="surgeryType" [items]="surgeryTypes" bindLabel="name" bindValue="code">
                        <ng-template class="w-75" ng-option-tmp let-item="item">
                            <div title="{{item.name}}">{{item.name}}</div>
                        </ng-template>
                    </ng-select>
                </div>

                <div class="input-group col-sm-2 mt-0">
                    <mat-form-field class="w-100">
                        <input matInput style="border-bottom: solid 1px lightgray;" [ngxMatDatetimePicker]="picker" placeholder="Beavatkozás ideje" formControlName="surgeryDate" [min]="minDate" [max]="maxDate" [disabled]="disabled">
                        <mat-datepicker-toggle matSuffix [for]="picker">
                        </mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color">
                        </ngx-mat-datetime-picker>
                    </mat-form-field>

                </div>

                <div class="col-sm-1 mt-0">
                    <input type="text" class="form-control" placeholder="Mennyiség" formControlName="quantity" />
                </div>

                <div class="col-sm-1 mt-0" style="display: table;">
                    <a role="button" class="icon" (click)="removeWho(i)">
                        <i class="fa fa-trash" title="Who törlése"></i>
                    </a>
                </div>

            </div>
            <div *ngIf="admTypeCategory === 'FEKVO'" class="row" [formGroup]="whoSurgeryForm">

                <div class="col-sm-2">
                    <ng-select placeholder="Nosocomiális környezet" [searchable]="true" formControlName="nosocEnv" bindLabel="name" bindValue="code" [items]="nosocEnv"></ng-select>
                </div>
                <div class="col-sm-2">
                    <ng-select placeholder="Oldaliság" [searchable]="true" formControlName="laterality" bindLabel="name" bindValue="code" [items]="laterality">
                        <ng-template ng-option-tmp let-item="item">
                            <div title="{{item.name}}">{{item.name}}</div>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="col-sm-2">
                    <ng-select placeholder="Sebgyógyulás" [searchable]="true" formControlName="healing" [items]="healing" bindLabel="name" bindValue="code">
                        <ng-template ng-option-tmp let-item="item">
                            <div title="{{item.name}}">{{item.name}}</div>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="col-sm-2">
                    <ng-select placeholder="Beavatkozás minősítése" [searchable]="true" formControlName="qualification" [items]="qualification" bindLabel="name" bindValue="code">
                        <ng-template ng-option-tmp let-item="item">
                            <div title="{{item.name}}">{{item.name}}</div>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="form-item">
                    <input type="checkbox" formControlName="nosocInf">
                </div>
                <div class="col-2 form-item">
                    Nosocomiális fertőzés
                </div>
            </div>
        </ng-container>
        <p class="other-surgery col-sm-2 mt-2" (click)="addWhoSurgery()">További WHO megadása</p>
    </ng-container>

    <button *ngIf="status !== 'D'" class="btn btn-primary" (click)="addSurgery(false)">Beavatkozások mentése</button>
    <button *ngIf="status === 'D'" class="btn btn-primary ml-1" (click)="updateEesztDetail()">BNO és WHO beküldése EESZT-be</button>

</div>