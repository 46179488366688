import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { retry, finalize, tap } from 'rxjs/operators';
import { LoggerService } from './logger.service';
import { SharedDataService } from './shared-data.service';

@Injectable()
export class LoggingInterceptorService implements HttpInterceptor {

  constructor(private loggerService: LoggerService,
    private sharedDataService: SharedDataService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const startTime = Date.now();
    let status: string;
    let errorData: HttpErrorResponse;
    let result;

    return next.handle(req).pipe(   // retry(1),    // retry on error
        tap(
          event => {
            status = '';
            if (event instanceof HttpResponse) {
              status = 'succeeded'; result = event;
            }
          },
          error => {status = 'failed'; errorData = error;
        }
        ),
        finalize(() => {
          if (status === 'succeeded') {
            const response = JSON.parse(event.currentTarget['response']);

            if (response['code'] < 1) {
              // console.error (req, response);
              if (response['code'] === -32) { // Login session expired
                console.error ('logging interceptor error -32, session expired');
                this.sharedDataService.setReloginState(true);
              } else if (response['code'] === -16 && req.url.indexOf('checkuserby4t') > 0) {
                // not error: check4t couldn't find a user
              } else if ((response['code'] === -14 || response['code'] === -25)  && req.url.indexOf('searchuserbytaj') > 0) {
                // not error: searchuserbytaj couldn't find a user
              } else if (response['code'] === -102 && req.url.indexOf('callpatient') > 0) {
                // not error: callPatient no patients in queue
              } else if (req.url.indexOf('getdocumentjson') > 0) {
                // not error: getDocumentJson
              } else {
                const elapsedTime = Date.now() - startTime;
                // this.loggerService.logitAPIError(response, req, elapsedTime);
                console.error (req, response);
              }
            }

          } else if (status === 'failed') {

            const elapsedTime = Date.now() - startTime;
            console.error('http error', req);
            if (req.url !== 'https://els.nauticom.hu/') { // don't try to send error report when error occurs while sending error to ELK
             // this.loggerService.logitHTTPError(errorData, req, elapsedTime);
            }

          }
        })
    );
  }
  private logDetails(msg: string) {
    console.log(msg);
  }
}

// https://www.concretepage.com/angular/angular-logging-http-interceptor
