import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedDataService } from '../services/shared-data.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedApiService } from '../services/shared-api.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-discard-patient-simple',
  templateUrl: './discard-patient-simple.component.html',
  styleUrls: ['./discard-patient-simple.component.css']
})
export class DiscardPatientSimpleComponent implements OnInit, OnDestroy {

  apiUrl = environment.apiUrl;
  constructor(
    private sharedDataService: SharedDataService,
    private authService: AuthService,
    private http: HttpClient,
    private sharedApiService: SharedApiService
  ) { }

  patientDataValue = [{ participantName: '' }];
  isLoading: boolean;

  saveInProgress = false;
  saveResult = null;
  saveErrorDescription = '';
  admTypeCat = null;
  calendarDetail = null;

  admissionTypes = [];

  getAwayTypes = [];

  @Input() set patientData(data) {
    // console.log ('discardPatient', data);
    if (data !== null && data['calendarFk']) {
      this.patientDataValue = data;
      this.isLoading = true;

      this.sharedApiService.getCalendarDetail(data['calendarFk']).subscribe((responseData) => {
        this.isLoading = false;
        // console.log ('getCalDetail', data);
        if (responseData['code'] === 1) {

          const calendarDetail = responseData['data'];

          const dscDateString = this.sharedDataService.dateToDateTimeString(new Date());

          this.calendarDetail = calendarDetail;

          if (calendarDetail['admTypeCat'] === 'JARO') {
            this.admTypeCat = 'ARVTYPE_OUTPAT';


            if (this.careTypes) {
              this.careTypeList = this.careTypes['types'][this.admTypeCat];
            }

            this.admDate.setValue(calendarDetail['admDate'].replace(' ', 'T'));
            this.admDate.disable();
            this.dscDate.setValue(dscDateString);
            this.appNumber.setValue(calendarDetail['appNumber']);
            this.ellatoOrvos.setValue(calendarDetail['providerUserId']);
            this.ellatoOrvos.disable();
            this.ellatasTipusa.setValue(calendarDetail['admType']);
            this.ellatasTipusa.disable();
            this.ellatoOsztaly.setValue(calendarDetail['providerSpId']);
            this.ellatoOsztaly.disable();

          } else if (calendarDetail['admTypeCat'] === 'FEKVO') {

            this.admTypeCat = 'ARVTYPE_INPAT';

            if (this.careTypes) {
              this.careTypeList = this.careTypes['types'][this.admTypeCat];
            }

            this.admDate.setValue(calendarDetail['admDate'].replace(' ', 'T'));
            this.admDate.disable();
            this.dscDate.setValue(dscDateString);
            this.appNumber.setValue(calendarDetail['appNumber']);
            this.appNumber.disable();
            this.ellatoOrvos.setValue(calendarDetail['providerUserId']);
            this.ellatoOrvos.disable();
            this.ellatasTipusa.setValue(calendarDetail['admType']);
            this.ellatasTipusa.disable();
            this.ellatoOsztaly.setValue(calendarDetail['providerSpId']);
            this.ellatoOsztaly.disable();
            this.admType.setValue(calendarDetail['admMode']);
            this.admType.disable();
          }

        } else {
          console.log('Error loading patient details calendarId: ' + data['calendarFk']);
          console.log(responseData);
        }
      });
    }
  }


  formSubmitted = false;

  admDate = new FormControl('', Validators.required);
  dscDate = new FormControl('', Validators.required);
  appNumber = new FormControl('', Validators.required);
  admType = new FormControl('', Validators.required);

  ellatoOrvos = new FormControl(null, Validators.required);
  ellatasTipusa = new FormControl('', Validators.required);
  ellatoOsztaly = new FormControl(null, Validators.required);

  tovabbiSors = new FormControl(null, Validators.required);

  treatmentDetailsOutpat = new FormGroup({
    admDate: this.admDate,
    dscDate: this.dscDate,
    appNumber: this.appNumber,
    ellatoOrvos: this.ellatoOrvos,
    ellatoOsztaly: this.ellatoOsztaly,
    ellatasTipusa: this.ellatasTipusa
  });

  treatmentDetailsInpat = new FormGroup({
    admDate: this.admDate,
    dscDate: this.dscDate,
    appNumber: this.appNumber,
    ellatoOrvos: this.ellatoOrvos,
    ellatoOsztaly: this.ellatoOsztaly,
    ellatasTipusa: this.ellatasTipusa,
    tovabbiSors: this.tovabbiSors,
    admType: this.admType
  });

  doctorsListDataSubscription: Subscription;
  doctorsList = [];
  doctorsListLoading = true;

  careTypesSubscription: Subscription;
  careTypes = {};
  careTypesLoading = true;

  careTypeList = [];

  availServic = [];

  availServicSubscription: Subscription;
  getAwayTypesSubscription: Subscription;

  ngOnInit() {

    this.availServicSubscription = this.sharedDataService.availServicesShared.subscribe(data => {
      this.availServic = data;
    });

    this.patientDataValue = [{ participantName: '' }];
    this.doctorsList = this.sharedDataService.doctorsList;
    this.getAwayTypes = [];
    this.sharedDataService.getAwayTypes.forEach(item => {  // filter for inpat
      if (item['typeCode'] === 'DSCTYPE_INPAT') {
        this.getAwayTypes.push(item);
      }
    });

    this.getAwayTypesSubscription = this.sharedDataService.getAwayTypesShared.subscribe(awayData => {
      awayData.forEach(item => {  // filter for inpat
        if (item['typeCode'] === 'DSCTYPE_INPAT') {
          this.getAwayTypes.push(item);
        }
      });
    });

    this.admissionTypes = this.sharedDataService.admissionTypes;

    if (this.doctorsList.length > 0) { this.doctorsListLoading = false; }

    this.doctorsListDataSubscription = this.sharedDataService.doctorsListShared.subscribe((data) => {
      this.doctorsListLoading = false;
      this.doctorsList = data;
    });

    this.careTypes = this.sharedDataService.patientCareTypes;
    this.careTypesLoading = this.careTypes['isLoading'];

    this.careTypesSubscription = this.sharedDataService.patientCareTypesShared.subscribe((data) => {
      this.careTypes = data;
      this.careTypesLoading = this.careTypes['isLoading'];

      if (this.admTypeCat) {
        this.careTypeList = this.careTypes['types'][this.admTypeCat];
      }

    });

  }

  ngOnDestroy() {
    this.availServicSubscription.unsubscribe();
    this.doctorsListDataSubscription.unsubscribe();
    this.careTypesSubscription.unsubscribe();
    this.getAwayTypesSubscription.unsubscribe();
  }

  closeResult() {
    console.log('closeResult');
    this.saveResult = null;
    this.saveErrorDescription = '';
  }

  onDiscardPatient() {
    this.saveInProgress = true;
    this.closeResult();
    this.updateCalendar().subscribe((responseData) => {

      this.saveInProgress = false;

      if (responseData['code'] === 1) {
        this.saveResult = 0;
        this.sharedDataService.refeshCalendar(this.calendarDetail['providerSpId']);
        // setTimeout(() => { this.saveResult = null; }, 3000);
        // console.log('Discard OK');

      } else {

        this.saveResult = -1;
        this.saveErrorDescription = responseData['code'] + ' ' + responseData['message'];

      }

    });
  }


  discardPatient() {

    let dscType = '';
    let params = {};

    if (this.admTypeCat === 'ARVTYPE_INPAT') {
      dscType = 'DSCTYPE_INPAT';
      dscType = this.tovabbiSors.value;

      params = {
        messageHeader: {
          currentTapass: this.authService.getUserData()['ekCardNo'],
          currentRole: this.sharedDataService.selectedRole['roleCode'],
          currentServicePointId: this.sharedDataService.selectedRole['spId'],
          locale: 'hu',
          deviceId: this.authService.getUUID()
        },
        calendarId: this.patientDataValue['calendarFk'],
        doCaseClose: true,
        eesztId: this.calendarDetail['eesztId'],
        providerSpId: this.ellatoOsztaly.value,
        providerUserId: this.ellatoOrvos.value,
        admDate: this.admDate.value,
        dscDate: this.dscDate.value,
        appNumber: this.appNumber.value,
        appNumberRn: this.calendarDetail['appNumber'],
        admMode: this.calendarDetail['admMode'],
        admType: this.calendarDetail['admType'],
        dscType: dscType
      };

    } else if (this.admTypeCat === 'ARVTYPE_OUTPAT') {
      dscType = '';

      params = {
        messageHeader: {
          currentTapass: this.authService.getUserData()['ekCardNo'],
          currentRole: this.sharedDataService.selectedRole['roleCode'],
          currentServicePointId: this.sharedDataService.selectedRole['spId'],
          locale: 'hu',
          deviceId: this.authService.getUUID()
        },
        calendarId: this.patientDataValue['calendarFk'],
        doCaseClose: true,
        eesztId: this.calendarDetail['eesztId'],
        providerSpId: this.ellatoOsztaly.value,
        providerUserId: this.ellatoOrvos.value,
        admDate: this.admDate.value,
        dscDate: this.dscDate.value,
        appNumber: this.appNumber.value,
        appNumberRn: this.calendarDetail['appNumber'],
        admMode: '',
        admType: this.calendarDetail['admType'],
        dscType: dscType
      };

    }



    return this.http.post<any[]>(this.apiUrl + '/rest/neu/calendarlist/discardpatient', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }


  updateCalendar() {

      let params = {
        messageHeader: {
          currentTapass: this.authService.getUserData()['ekCardNo'],
          currentRole: this.sharedDataService.selectedRole['roleCode'],
          currentServicePointId: this.sharedDataService.selectedRole['spId'],
          locale: 'hu',
          deviceId: this.authService.getUUID()
        },
        calendarId: this.patientDataValue['calendarFk'],
        status: 'D',
        isCalDetailUpdateEnabled: false
      };

    return this.http.post<any[]>(this.apiUrl + '/rest/neu/calendarlist/updatecalendar', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }


  onClose() {
    console.log('onClose');
    this.patientDataValue = [];
    const data = [];
    data['NGdestinationService'] = 'discardPatient';
    this.sharedDataService.setPatientData(data);
    this.closeResult();
  }

  compareDoctorId(c1: any, c2: any) {

    return c1 && c2 ? c1.id == c2 : c1 === c2;

  }

  compareSpId(c1: any, c2: any) {

    return c1 && c2 ? c1.spId == c2 : c1 === c2;

  }

}
