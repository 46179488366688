import { Component, OnInit, OnDestroy, Input} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { SharedDataService } from '../services/shared-data.service';
import { RedirectService } from '../services/redirect.service';
import { SharedApiService } from '../services/shared-api.service';
// import { NgxXml2jsonService } from 'ngx-xml2json';
import { environment } from '../../environments/environment';
import { FormControl } from '@angular/forms';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-eeszt-message-list-modal',
  templateUrl: './eeszt-message-list-modal.component.html',
  styleUrls: ['./eeszt-message-list-modal.component.css']
})
export class EesztMessageListModalComponent implements OnInit, OnDestroy {

  faSyncAlt = faSyncAlt;

  constructor(public authService: AuthService,
    private http: HttpClient,
    private sharedDataService: SharedDataService,
    private openTab: RedirectService,
    private sharedApiService: SharedApiService,
   // private ngxXml2jsonService: NgxXml2jsonService,
  ) {}


  apiUrl = environment.apiUrl;

  eesztMessageListLoading = true;
  eesztMessageCount = 0;
  eesztMessageListData = [];

  isEesztUser: boolean;
  bulkSelectIndex = 0;
  selectedCount = 0;

  saveInProgress = false;
  processBcpResult = null;
  successMessage = '';
  processBcpErrorDescription = '';

  isBcpLoading:boolean;

  selectAll = new FormControl(false);

  @Input() set eesztMessageList(data) {
    // console.log ('EESZT data', data);
    if (data && data !== null) {
      this.eesztMessageListLoading = false;
      this.eesztMessageCount = data.length;
      this.isBcpLoading = false;
      data.forEach(element => {
        const parser = new DOMParser();
        const xml = parser.parseFromString(element['msgReply'], 'text/xml');
        // const obj = this.ngxXml2jsonService.xmlToJson(xml);  // XML2JSON
        const obj = {};
        // console.log ('msgReply', obj);
        element['msgReplyObj'] = obj;
        element['checked'] = false;
      });
      this.eesztMessageListData = data;
      // console.log ('eesztMessageCount', this.eesztMessageCount);
      // console.log ('EESZT data arrived to eesztMessageList', data);
    }
  }


  ngOnInit() {
    this.sharedDataService.isBcpListLoading.subscribe(isBcpLoading => {
      this.isBcpLoading = isBcpLoading;
    });

    this.selectAll.valueChanges.subscribe(isSelectAll => {
      if (isSelectAll) {
        this.eesztMessageListData.forEach(element => {
          element['checked'] = true;
          this.selectedCount++;
        });
      } else {
        this.eesztMessageListData.forEach(element => {
          element['checked'] = false;
        });
        this.selectedCount = 0;
      }
    });
    this.isEesztUser = this.authService.getUserData()['isEesztUser'];
    // console.log ('isEesztUser', this.isEesztUser);
  }

  ngOnDestroy() {

  }



  openHelp(anchor) {
    const newWindow = window.open('https://' + this.authService.getHostname() + '/kezikonyv/' + anchor);
  }

  sendBcpMessages() {
    this.saveInProgress = true;
    const idList = [];
    this.eesztMessageListData.forEach(element => {
      if (element['checked'] === true) {
        idList.push(element['transId']);
        // console.log ('element', element);
      } else {
        // console.log ('element not checked', element);
      }
    });

    // console.log ('idList', idList);

    this.processBcpMessages(idList).subscribe((data) => {
      this.saveInProgress = false;
      if (data['code'] === 1) {
        this.processBcpResult = 0;
        this.successMessage = 'Adatok elküldése feldolgozás alatt...';
        setTimeout(() => { this.processBcpResult = null; this.refreshBcpMessageList()}, 3000);

        // console.log ('process Bcp Messages', data);
        this.eesztMessageListData.forEach(element => {
          element['checked'] = false;
        });
        this.selectedCount = 0;
      } else {
        console.error ('process Bcp Messages ERROR', data);
        this.processBcpErrorDescription = 'Hiba az adatok feldolgozása során: ' + data['code'] + ' - ' + data['message'];
      }
    });
  }

  processBcpMessages(idList: Array<number>) {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.getSelectedRole()['roleCode'],
        currentServicePointId: this.sharedDataService.getSelectedRole()['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      bcpMessageIdList: idList
    };

    return this.http.post(this.apiUrl + '/rest/ecm/bcp/processbcpmessages', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  refreshBcpMessageList() {
    this.selectAll.reset();
    this.eesztMessageList = [];
    this.sharedDataService.loadBcpMessageCount();
    this.sharedDataService.loadBcpMessages();
  }

  itemChange(i, $event, eesztMessage) {
    // console.log ('item change', i);
    if ($event['srcElement']['checked']) {
      this.eesztMessageListData[this.eesztMessageListData.indexOf(eesztMessage)]['checked'] = true;
      // console.log ('checked', $event['srcElement']['checked']);
      this.selectedCount++;
    } else {
      this.eesztMessageListData[this.eesztMessageListData.indexOf(eesztMessage)]['checked'] = false;
      this.selectedCount--;
      // console.log ('unchecked', $event['srcElement']['checked']);
    }
  }

  bulkSelect(type) {
    let selectedCount = 0;
    // console.log ('select index', this.bulkSelectIndex);
    if (Number(type) === 1) {
      this.eesztMessageListData.forEach(element => {
        element['checked'] = false;
      });
    } else if (Number(type) === 2) {
      this.eesztMessageListData.forEach(element => {
        element['checked'] = true;
        selectedCount++;
      });
    } else if (Number(type) === 3) {
      this.eesztMessageListData.forEach(element => {
        if (element['msgStatus'] === 'NEW') {
         element['checked'] = true;
         selectedCount++;
        } else {
          element['checked'] = false;
        }
      });
    } else if (Number(type) === 4) {
      this.eesztMessageListData.forEach(element => {
        if (element['msgStatus'] === 'ERROR') {
         element['checked'] = true;
         selectedCount++;
        } else {
          element['checked'] = false;
        }
      });
    }
    this.bulkSelectIndex = 0;
    this.selectedCount = selectedCount;
  }


  closeResult() {
    this.processBcpResult = null;
  }

}
