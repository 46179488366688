import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedDataService } from '../services/shared-data.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedApiService } from '../services/shared-api.service';
import { environment } from '../../environments/environment';
import { ApiService } from '../core/http/api.service';
import { PatientEhrRequest } from '../shared/models/request/patientEhrRequest';
import { EbeuResBody } from '../shared/models/ebeuResBody';
import { PatientEbeuRequest } from '../shared/models/request/patientEbeuRequest';
import { ToastrService } from 'ngx-toastr';
import { faCopy, faTrash } from '@fortawesome/free-solid-svg-icons';
import { SearchEbeuData } from '../shared/models/response/searchEbeuResponse';
import { DeleteEkatRequest } from '../shared/models/request/deleteEkatRequest';
import { UpdateEesztDetailsReques } from '../shared/models/request/UpdateEesztDetailsRequest';
import { BnoMainGroupResponse } from '../shared/models/response/bnoMainGroupResponse';
import { BnoSubGroupResponse } from '../shared/models/response/bnoSubGroupResponse';
import { GetBnoListRequest } from '../shared/models/request/getBnoListRequest';
import { GetBnoListResponse } from '../shared/models/response/getBnoListResponse';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-arrive-patient',
  templateUrl: './arrive-patient.component.html',
  styleUrls: ['./arrive-patient.component.css']
})
export class ArrivePatientComponent implements OnInit, OnDestroy {

  faCopy = faCopy;
  apiUrl = environment.apiUrl;

  constructor(
    private sharedDataService: SharedDataService,
    private authService: AuthService,
    private toastr: ToastrService,
    private http: HttpClient,
    private apiService: ApiService,
    private sharedApiService: SharedApiService
  ) { }

  patientDateFrom = new FormControl(null, Validators.required);
  patientDateTo = new FormControl(null, Validators.required);
  breakGlass = new FormControl(null);
  breakGlassReason = new FormControl(null);
  patientBeuFormGroup = new FormGroup({
    'patientDateFrom': this.patientDateFrom,
    'patientDateTo': this.patientDateTo,
    'breakGlass': this.breakGlass,
    'breakGlassReason': this.breakGlassReason
  });

  showPatientBeuFormControl = new FormControl(false);
  showInstitutionalBeuFormControl = new FormControl(false);

  patientDataValue = { participantName: '', servicePointFk: ''};
  isLoading: boolean;

  admTypeCat = null;

  availServicSubscription: Subscription;

  sstOptions = [
  {id: "1", name: "TAJ szám"},
  {id: "2", name: "Gyermek TAJ szám"},
  {id: "3", name: "Útlevélszám"},
  {id: "5", name: "Befogadó igazolvány"},
  {id: "8", name: "EU e-kártya"},
  {id: "9", name: "Menekült azonosítója"},
  {id: "X", name: "Egyéb okirat"}
  ];

  /* patientEbeuList = new MatTableDataSource<any>(); */
  patientEbeuList: Array<SearchEbeuData> = null;
  displayedColumns: string[] = ["patientName", "patientSsn", "organizationName", "doctorName", "docStampNo", "createDate", "expirationDate", "copyReferralId"];
  saveInProgress = false;
  saveResult = null;
  saveErrorDescription = '';
  calendarDetail = null;

  admissionTypes = [];
  admissionTypesSubscription: Subscription;
  admissionTypesLoading = true;

  filteredDoctorsList = [];

  ehrDetailsInProgress: boolean;
  isEkatModify: boolean;
  /* isPatientDone: boolean; */
  patientCalendarDetail;

  faTrash = faTrash;

  showPatientBeuFields = false;
  showInstitutionalBeuFields = false;

  admDate = new FormControl('', Validators.required);
  dscDate = new FormControl('', Validators.required);
  appNumber = new FormControl('', Validators.required);

  ellatoOsztaly = new FormControl(null, Validators.required);


  tovabbiSors = new FormControl(null, Validators.required);

  bnoList = new FormControl(null, Validators.required);
  /* bnoSubName = new FormControl(null, Validators.required); */

  availServic = [];
  getAwayTypes = [];

  /* bnoSubItems: BnoSubGroupResponse; */
  bnoListItems: GetBnoListResponse;

  treatmentDetailsOutpat = new FormGroup({
    admDate: this.admDate,
    dscDate: this.dscDate,
    appNumber: this.appNumber,
    ellatoOsztaly: this.ellatoOsztaly,
  });

  treatmentDetailsInpat = new FormGroup({
    admDate: this.admDate,
    dscDate: this.dscDate,
    appNumber: this.appNumber,
    ellatoOsztaly: this.ellatoOsztaly,
    tovabbiSors: this.tovabbiSors
  });

  bnoFromGroup = new FormGroup({
    /* bnoSubName: this.bnoSubName, */
    bnoList: this.bnoList
  });

  @Input() set setPatientCalendarDetail(patientCalendarDetail) {
    this.patientCalendarDetail = patientCalendarDetail;
    if (patientCalendarDetail) {
      if (patientCalendarDetail['calendarStatus'] === 'D') {
        this.treatmentDetailsOutpat.enable();
        this.treatmentDetailsInpat.enable();
        if (this.isEkatModify) {
          this.treatmentDetailsInpat.get('ellatoOsztaly').disable();
          this.treatmentDetailsOutpat.get('ellatoOsztaly').disable();
        }
      } else {
        this.treatmentDetailsOutpat.disable();
        this.treatmentDetailsInpat.disable();
      }
    }
  }

  @Input() set patientData(data) {
    // console.log ('arrive patient patientData input triggered');
    if (data !== null && data['calendarFk']) {
      this.patientDataValue = data;
      // console.log('arrive patientData set:');
      // console.log(this.patientDataValue);
      this.isLoading = true;

      // Filter doctors list to current service point fk

      this.filteredDoctorsList = this.doctorsList.filter(item => {
        if (item.servicePointId === data.servicePointFk) {
          // console.log ('item', item.servicePointId, data.servicePointFk);
        }
        return item.servicePointId === data.servicePointFk;
      });
      // console.log ('filteredDoctorsList', this.filteredDoctorsList);

      this.sharedApiService.getCalendarDetail(data['calendarFk']).subscribe((responseData) => {
        this.isLoading = false;
        if (responseData['code'] === 1) {
          this.bnoFromGroup.controls.bnoList.setValue(this.bnoListItems?.bnoList.find(x => x.bnoCode === responseData['data']['diagnosis']));
          this.ssn.setValue(responseData['data']['participantSocialSecurityNumber']);
          /* this.sst.setValue(responseData['data']['participantSsnCode']); */
          this.sst.setValue(responseData['data']['participantSocialSecurityTypeFk']);
          this.sstd.setValue(responseData['data']['participantSsnTypeDesc']);
          this.ellatoOrvos.setValue(this.authService.getUserData()['id']);
          console.log('responseData: ', responseData);
          const dscDateString = this.sharedDataService.dateToDateTimeString(new Date());
          const calendarDetail = responseData['data'];
          this.calendarDetail = responseData['data'];

          if (calendarDetail['admTypeCat'] === 'JARO') {
            this.admTypeCat = 'ARVTYPE_OUTPAT';


            if (this.careTypes) {
              this.careTypeList = this.careTypes['types'][this.admTypeCat];
            }

            this.treatmentDetails.controls.ellatasModja.setValue(responseData['data']['admTypeCat'] === 'FEKVO' ? 'ARVTYPE_INPAT' : 'ARVTYPE_OUTPAT');
            this.admDate.setValue(calendarDetail['admDate'].replace(' ', 'T'));
            this.dscDate.setValue(dscDateString);
            this.appNumber.setValue(calendarDetail['appNumber']);
            this.ellatoOrvos.setValue(+calendarDetail['providerUserId']);
            this.ellatasTipusa.setValue(calendarDetail['admType']);
            this.ellatoOsztaly.setValue(calendarDetail['providerSpId']);

          } else if (calendarDetail['admTypeCat'] === 'FEKVO') {

            this.admTypeCat = 'ARVTYPE_INPAT';

            if (this.careTypes) {
              this.careTypeList = this.careTypes['types'][this.admTypeCat];
            }

            this.treatmentDetails.controls.ellatasModja.setValue(responseData['data']['admTypeCat'] === 'FEKVO' ? 'ARVTYPE_INPAT' : 'ARVTYPE_OUTPAT');
            this.admDate.setValue(calendarDetail['admDate'].replace(' ', 'T'));
            this.dscDate.setValue(dscDateString);
            this.appNumber.setValue(calendarDetail['appNumber']);
            this.tovabbiSors.setValue(calendarDetail['dscType']);
            this.ellatoOrvos.setValue(+calendarDetail['providerUserId']);
            this.ellatasTipusa.setValue(calendarDetail['admType']);
            this.ellatoOsztaly.setValue(calendarDetail['providerSpId']);
            this.admType.setValue(calendarDetail['admMode']);
          }


          if (this.sst.value == 1) {
            this.consent.setValidators([Validators.required]);
          } else {
            this.consent.setValidators([]);
          }

        } else {
          console.log ('Error loading patient details calendarId: ' + data['calendarFk']);
          // console.log (responseData);
        }
      });
    }
  }


  formSubmitted = false;

  arrivePatientErrorDescription = '';
  arrivePatientSaveResult = null;

  ssn = new FormControl('', [Validators.required, Validators.minLength(4)]);
  sst = new FormControl('', Validators.required);

  sstd = new FormControl(); // , [Validators.required, Validators.minLength(4)]);
  consent = new FormControl('');
  ellatasModja = new FormControl('', Validators.required);
  ellatoOrvos = new FormControl(null, Validators.required);
  ellatasTipusa = new FormControl('', Validators.required);
  admType = new FormControl(null);
  assignedDoctorsOnly = new FormControl(true);
  referralIdentification = new FormControl(null);

  treatmentDetails = new FormGroup({
    // 'name': this.name,
    'sst': this.sst,
    'ssn': this.ssn,
    'sstd': this.sstd,
    'consent': this.consent,
    'ellatoOrvos': this.ellatoOrvos,
    'ellatasModja': this.ellatasModja,
    'ellatasTipusa': this.ellatasTipusa,
    'admType': this.admType,
    'assignedDoctorsOnly': this.assignedDoctorsOnly,
    'referralIdentification': this.referralIdentification
  });

  doctorsListDataSubscription: Subscription;
  doctorsList = [];
  doctorsListLoading = true;

  careTypesSubscription: Subscription;
  careTypes = {};
  careTypesLoading = true;

  careTypeList = [];

  bnoListLoading: boolean;

  ngOnInit() {
    this.patientBeuFormGroup.controls.breakGlassReason.disable();
    this.patientBeuFormGroup.controls.breakGlass.valueChanges.subscribe(breakGlass => {
      breakGlass ? this.patientBeuFormGroup.controls.breakGlassReason.enable() : this.patientBeuFormGroup.controls.breakGlassReason.disable();
    });
    //  console.log ('arrive patient onInit');

    this.sharedDataService.isEkatUpdate.subscribe(isEkatUpdate => {
      this.isEkatModify = isEkatUpdate;
      if ( this.isEkatModify ) {
        this.treatmentDetailsInpat.get('ellatoOsztaly').disable();
        this.treatmentDetailsOutpat.get('ellatoOsztaly').disable();
        this.treatmentDetails.controls.ellatasModja.disable();
      } else {
        this.treatmentDetailsInpat.get('ellatoOsztaly').enable();
        this.treatmentDetailsOutpat.get('ellatoOsztaly').enable();
        this.treatmentDetails.controls.ellatasModja.enable();
      }
    });

    this.patientDateFrom.valueChanges.pipe(debounceTime(1000)).subscribe(value => {
      this.convertDatefromUserInput(value, this.patientDateFrom);
    });

    this.patientDateTo.valueChanges.pipe(debounceTime(1000)).subscribe(value => {
      this.convertDatefromUserInput(value, this.patientDateTo);
    });



    /* this.apiService.getBnoSubGroupList(messageHeader).subscribe(res => {
      if (res.code > 0) {
        this.bnoSubItems = res;
      } else {
        console.log('error: ', res.message);
      }
    }, err => {
      console.log('error message: ', err.message);
    });
   */
    this.sharedDataService.getAwayTypes.forEach(item => {  // filter for inpat
      if (item['typeCode'] === 'DSCTYPE_INPAT') {
        this.getAwayTypes.push(item);
      }
    });

    this.getFullBnoList();

    this.availServicSubscription = this.sharedDataService.availServicesShared.subscribe(data => {
      this.availServic = data;
    });

    this.patientDataValue = { participantName: '', servicePointFk: ''};
    // this.doctorsList = this.sharedDataService.doctorsList;

    this.admissionTypes = this.sharedDataService.admissionTypes;

    this.admissionTypesSubscription = this.sharedDataService.admissionTypesShared.subscribe((data) => {
      this.admissionTypes = data;
      this.admissionTypesLoading = false;
    });

    this.doctorsListDataSubscription = this.sharedDataService.doctorsListShared.subscribe((data) => {
      // console.log('arrive patient doctors list arrived');
      this.doctorsListLoading = false;
      this.doctorsList = data;
      this.filteredDoctorsList = this.doctorsList.filter(item => {
        if (item.servicePointId === this.patientDataValue.servicePointFk) {
          // console.log ('item', item.servicePointId, this.patientDataValue.servicePointFk);
        }
        return item.servicePointId === this.patientDataValue.servicePointFk;
      });
    });

    this.careTypes = this.sharedDataService.patientCareTypes;
    // console.log ('caretypes', this.careTypes);
    this.careTypesLoading = this.careTypes['isLoading'];

    this.careTypesSubscription = this.sharedDataService.patientCareTypesShared.subscribe((data) => {
      this.careTypes = data;
      this.careTypesLoading = this.careTypes['isLoading'];
      // console.log ('caretypes', this.careTypes);
    });

  }

  convertDatefromUserInput(value, formControl: FormControl) {
    if (value && value !== '') {
      if (value.year === undefined)  {
        const dateValue = value.replace(/\./g, '-');
        const dateObject = this.sharedDataService.dateFromUserinput(dateValue);
        if (dateObject && !isNaN(dateObject.getTime())) {
          const newDate = this.sharedDataService.dateToDateString(dateObject);
          formControl.setValue(this.sharedDataService.dateStringToNgbDate(newDate));
        }
      }
    }
  }

  getFullBnoList() {
    const messageHeader = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      }
    };
    this.apiService.getFullBnoList(messageHeader).subscribe(res => {
      this.bnoListItems = res;
    });
  }

  copyReferralId(element) {
    this.treatmentDetails.controls.referralIdentification.setValue(element.businessObjectIdentification.eesztid.id);
  }

  ngOnDestroy() {
    this.doctorsListDataSubscription.unsubscribe();
    this.careTypesSubscription.unsubscribe();
  }

  showPatientBeu() {
    this.showInstitutionalBeuFields = false;
    this.showInstitutionalBeuFormControl.setValue(false);
    this.showPatientBeuFields = !this.showPatientBeuFields;
    if (!this.showPatientBeuFields && !this.showInstitutionalBeuFields) {
      this.treatmentDetails.controls.referralIdentification.reset();
      this.patientEbeuList = [];
      this.patientBeuFormGroup.reset();
    }
  }

  /* getBnoListItems() {
    if (this.bnoFromGroup.controls.bnoSubName.value) {
      this.bnoFromGroup.controls.bnoList.reset();
      const getBnoListRequest: GetBnoListRequest = {
        messageHeader: {
          currentTapass: this.authService.getUserData()['ekCardNo'],
          currentRole: this.sharedDataService.selectedRole['roleCode'],
          currentServicePointId: this.sharedDataService.selectedRole['spId'],
          locale: 'hu',
          deviceId: this.authService.getUUID(),
          sessionId: null
        },
        mainGrpCode: this.bnoFromGroup.controls.bnoSubName.value.mainGrpcode,
        subGrpCode: this.bnoFromGroup.controls.bnoSubName.value.subGrpCode
      };
      console.log('this.bnoFromGroup.controls.bnoSubName.value: ', this.bnoFromGroup.controls.bnoSubName.value);
      this.bnoListLoading = true;
      this.apiService.getBnoList(getBnoListRequest).subscribe(bnoList => {
        this.bnoListLoading = false;
        if (bnoList.code > 0) {
          this.bnoListItems = bnoList;
        } else {
          console.log('response ok, error : ', bnoList);
        }
      }, err => {
        this.bnoListLoading = false;
        console.log('response error, error: ', err);
      });
    } else {
      this.bnoListItems = null;
      this.bnoFromGroup.controls.bnoList.reset();
    }

  } */

  /* customBnoSubSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.subGrpName.toLocaleLowerCase().indexOf(term) > -1 ||
    item.subGrpCode.toLocaleLowerCase().indexOf(term) > -1;
  } */

  customBnoListSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.bnoName.toLocaleLowerCase().indexOf(term) > -1 ||
    item.bnoCode.toLocaleLowerCase().indexOf(term) > -1;
 }

  showInstitutionalBeu() {
    this.showPatientBeuFields = false;
    this.showPatientBeuFormControl.setValue(false);
    this.showInstitutionalBeuFields = !this.showInstitutionalBeuFields;
    if (!this.showPatientBeuFields && !this.showInstitutionalBeuFields) {
      this.treatmentDetails.controls.referralIdentification.reset();
      this.patientEbeuList = [];
      this.patientBeuFormGroup.reset();
    }
  }

  getPatientBeuDocumentList() {
    if (this.patientBeuFormGroup.controls.breakGlass.value && !this.patientBeuFormGroup.controls.breakGlassReason.value){
      this.toastr.error('sürgősségi adatlekérés esetén az indoklák megadása kötelező!');
    } else {
      let applyAllFilter: boolean;
      if ((this.showPatientBeuFields && this.showInstitutionalBeuFields) || this.showInstitutionalBeuFields) {
        applyAllFilter = true;
      } else {
        applyAllFilter = false;
      }

      const params: PatientEbeuRequest = {
        messageHeader: {
          currentTapass: this.authService.getUserData()['ekCardNo'],
          currentRole: this.sharedDataService.selectedRole['roleCode'],
          currentServicePointId: this.sharedDataService.selectedRole['spId'],
          locale: 'hu',
          deviceId: this.authService.getUUID()
        },
        breakGlass: this.patientBeuFormGroup.controls.breakGlass.value,
        breakGlassOk: this.patientBeuFormGroup.controls.breakGlassReason.value,
        dateFrom: this.sharedDataService.NgbDateToDateString(this.patientBeuFormGroup.controls.patientDateFrom.value),
        dateTo: this.sharedDataService.NgbDateToDateString(this.patientBeuFormGroup.controls.patientDateTo.value),
        patientTapass: (this.showPatientBeuFields && !this.showInstitutionalBeuFields) ||
                       (this.showPatientBeuFields && this.showInstitutionalBeuFields) ?
                        this.calendarDetail.participantTapass : null,
        applyAllFilter
      };
      this.ehrDetailsInProgress = true;
      this.apiService.searchEbeuMinimal(params).subscribe(res => {
        this.ehrDetailsInProgress = false;
        if (res.code > 0) {
          if (res.errorMessages?.messages.length > 0) {
            res.errorMessages.messages.forEach(errorMessage => {
              this.toastr.error(errorMessage.code, errorMessage.message);
            });
          }
          this.toastr.success('sikeres lekérdezés!');
          this.patientEbeuList = res.data;
        } else {
          this.toastr.error(res.message);
        }
      }, err => {
        this.ehrDetailsInProgress = false;
        this.toastr.error(err.error.message);
      });
    }
  }

  deleteEkatDocument() {
    console.log('patientDataValue: ', this.patientDataValue);
    const ekatElement: DeleteEkatRequest = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calendarId: this.patientDataValue['calendarFk']
    };
    this.apiService.deleteEkat(ekatElement).subscribe(res => {
      console.log('res: ', res);
      if (res.code > 0) {
        this.toastr.success('sikeres törlés!');
      } else {
        this.toastr.error(res.message);
      }
    }, err => {
      this.toastr.error(err.message);
    });
  }

  onChangeCareCategory($event) {
    if ($event) {
      if ($event.typeCode === 'ARVTYPE_INPAT') {
        this.admType.setValidators([Validators.required]);
        this.admType.reset();
        // console.log ('setValidators');
      } else if ($event.typeCode === 'ARVTYPE_OUTPAT') {
        this.admType.clearValidators();
        this.admType.reset();
        // console.log ('clearValidators');
      }
      this.careTypeList = this.careTypes['types'][$event['typeCode']];
    } else {
      this.careTypeList = [];
    }
    this.ellatasTipusa.setValue(null);
  }

  closeResult() {
    this.saveResult = 0;
    this.arrivePatientSaveResult = null;
  }

  onArrivePatient() {
    this.saveInProgress = true;

    this.arrivePatient().subscribe((responseData) => {

      this.saveInProgress = false;

      if (responseData['code'] === 1) {
        this.saveResult = 0;
        // setTimeout(() => { this.saveResult = null; }, 3000);
        // console.log ('Arrive OK');

      } else {
        this.saveResult = -1;
        this.saveErrorDescription = responseData['code'] + ' ' + responseData['message'];
      }

      this.sharedDataService.refeshCalendar(this.calendarDetail['providerSpId']);

    });
  }

  updateArrivedPatientEkat () {
    const admDate = this.admTypeCat === 'ARVTYPE_OUTPAT' ?
                                        this.treatmentDetailsOutpat.controls.admDate.value :
                                        this.treatmentDetailsInpat.controls.admDate.value;
    const appNumber = 'ARVTYPE_OUTPAT' ?
                      this.treatmentDetailsOutpat.controls.appNumber.value :
                      this.treatmentDetailsInpat.controls.appNumber.value;
    const appNumberRn = 'ARVTYPE_OUTPAT' ?
                        this.treatmentDetailsOutpat.controls.appNumber.value :
                        this.treatmentDetailsInpat.controls.appNumber.value;
    const calendarId = this.patientCalendarDetail['calendarFk'];
    const diagnosis = this.bnoFromGroup.controls.bnoList.value?.bnoCode ? this.bnoFromGroup.controls.bnoList.value.bnoCode : null;
    const dscDate = this.admTypeCat === 'ARVTYPE_OUTPAT' ?
                                        this.treatmentDetailsOutpat.controls.dscDate.value :
                                        this.treatmentDetailsInpat.controls.dscDate.value;
    const dscType = this.admTypeCat === 'ARVTYPE_OUTPAT' ?
                                        this.treatmentDetailsInpat.controls.tovabbiSors.value :
                                        null;

    let admTypeCatValue = '';
      if (this.ellatasModja.value === 'ARVTYPE_INPAT') {
        admTypeCatValue = 'FEKVO';
      } else if (this.ellatasModja.value === 'ARVTYPE_OUTPAT') {
        admTypeCatValue = 'JARO';
      }
    const updateEesztDetailsReques: UpdateEesztDetailsReques = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID(),
        sessionId: null
      },
      admTypeCat: admTypeCatValue,
      admType: this.ellatasTipusa.value,
      admMode: this.admType.value,
      calModReasonCode: 'EBK', // 'HBK_F',
      doctorId: this.ellatoOrvos.value,
      eesztEnabled: this.consent.value === 'true' ? true : false,
      participantSsnCode: this.sst.value,
      participantSsnTypeDesc: this.sstd.value !== null ? this.sstd.value : '',
      participantSocialSecurityNumber: this.ssn.value,
      patientId: this.patientDataValue['participantUserID'],
      ebeuId: this.treatmentDetails.controls.referralIdentification.value ?
              this.treatmentDetails.controls.referralIdentification.value :
              null,
      admDate,
      appNumber,
      appNumberRn,
      calendarId,
      diagnosis,
      dscDate,
      dscType
    };

    this.saveInProgress = true;
    this.apiService.updateEesztDetails(updateEesztDetailsReques).subscribe(res => {
      this.saveInProgress = false;
      if (res.code > 0) {
        this.toastr.success('sikeres módosítás!');
      } else {
        this.toastr.error(res.message);
      }
      console.log('updateEesztDetails response: ', res);
    }, err => {
      this.toastr.error(err.message);
      this.saveInProgress = false;
      console.log('updateEesztDetails error: ', err);
    });
  }

  arrivePatient() {
      let admTypeCatValue = '';
      if (this.ellatasModja.value === 'ARVTYPE_INPAT') {
        admTypeCatValue = 'FEKVO';
      } else if (this.ellatasModja.value === 'ARVTYPE_OUTPAT') {
        admTypeCatValue = 'JARO';
      }

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      admTypeCat: admTypeCatValue,
      admType: this.ellatasTipusa.value,
      admMode: this.admType.value,
      calId: this.patientDataValue['calendarFk'],
      calModReasonCode: 'EBK', // 'HBK_F',
      doctorId: this.ellatoOrvos.value,
      eesztEnabled: this.consent.value === 'true' ? true : false,
      participantSsnCode: this.sst.value,
      participantSsnTypeDesc: this.sstd.value !== null ? this.sstd.value : '',
      participantSocialSecurityNumber: this.ssn.value,
      patientId: this.patientDataValue['participantUserID'],
      status: 'A',
      ebeuId: this.treatmentDetails.controls.referralIdentification.value ?
              this.treatmentDetails.controls.referralIdentification.value :
              null,
      diagnosis: this.bnoFromGroup.controls.bnoList.value?.bnoCode ? this.bnoFromGroup.controls.bnoList.value.bnoCode : null
    };


    return this.http.post<any[]>(this.apiUrl + '/rest/neu/calendarlist/arrivepatient', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }

  onClose() {
    this.saveResult = null;
    this.arrivePatientSaveResult = null;
    this.closeResult();
    this.resetForm();
  }

  onChangeSst() {
    if (this.sst.value == 1) {
      this.consent.setValidators([Validators.required]);
    } else {
      this.consent.clearValidators();
      this.consent.updateValueAndValidity();
    }
  }

  logForm() {
    // console.log ('form:', this.treatmentDetails);
  }

  resetForm() {
    this.saveResult = null;
    this.arrivePatientSaveResult = null;
    this.bnoFromGroup.reset();
    this.treatmentDetails.controls.ellatasModja.enable();
    this.isEkatModify = false;
    this.showPatientBeuFields = false;
    this.showPatientBeuFormControl.reset();
    this.showInstitutionalBeuFields = false;
    this.showInstitutionalBeuFormControl.reset();
    this.patientEbeuList = null;
    this.patientDataValue = { participantName: '', servicePointFk: ''};
    const data = [];
    data['NGdestinationService'] = 'arrivePatient';
    this.sharedDataService.setPatientData(data);
    this.admType.clearValidators();
    this.treatmentDetails.reset();
    this.patientBeuFormGroup.reset();
  }

  compareSpId(c1: any, c2: any) {

    return c1 && c2 ? c1.spId == c2 : c1 === c2;

  }

}
