import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { SharedDataService } from '../services/shared-data.service';
import { SharedApiService } from '../services/shared-api.service';
import { RedirectService } from '../services/redirect.service';
import { ExcelService } from '../services/excel.service';
import { environment } from '../../environments/environment';
import { Subscription } from 'rxjs';
import { MatTabGroup } from '@angular/material/tabs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit, OnDestroy {
  @ViewChild('settingsMainTabGroup', {static: false}) settingsMainTabGroup: MatTabGroup;

  constructor(public authService: AuthService,
    private http: HttpClient,
    private sharedDataService: SharedDataService,
    private sharedApiService: SharedApiService,
    private openTab: RedirectService,
    private excelService: ExcelService
  ) { }

  apiUrl = environment.apiUrl;
  version = environment.version;

  activeTab = 1;
  saveInProgress = false;
  saveResult = null;
  saveErrorDescription = '';
  closeConfirmationDialogueVisible = false;
  formSubmitted = false;
  availServic = null;
  screenMessage = '';

  showPasswordStrengthSuggestionts = new FormControl(false);
  oldPassword = new FormControl(null, Validators.required);
  newPassword = new FormControl(null, [Validators.required, Validators.minLength(6)]);
  newPassword2 = new FormControl(null, [Validators.required, Validators.minLength(6)]);
  username = new FormControl(this.authService.getUserData()['ekUserName']);

  changePasswordForm = new FormGroup({
    olPassword: this.oldPassword,
    newPassword: this.newPassword,
    newPassword2: this.newPassword2,
    username: this.username
  });

  newPasswordsValid = 0;
  now = new Date();
  dateMax = this.sharedDataService.dateToDateString(this.now);
  dateMin = '2019-01-01';

  worklist_date = new FormControl();

  worklistGenerate = new FormGroup({
    worklist_date: this.worklist_date
  });

  worklistLoading = false;

  serviceSelect = new FormControl(null, Validators.required);
  dateFrom = new FormControl(null, Validators.required);
  dateTo = new FormControl(null, Validators.required);

  implantRegExport = new FormGroup({
    serviceSelect: this.serviceSelect,
    dateFrom: this.dateFrom,
    dateTo: this.dateTo
  });

  availServicSubscription: Subscription;
  patientListDataSubscription: Subscription;
  patientListLoading = true;

  allPrintersOfInstituteSubscription: Subscription;
  allPrintersOfInstitute = new Array<object>();

  patientList: [];
  patientCount = 0;

  csIdListSubscription: Subscription;
  csIdList = [];
  csIdListLoading = true;
  setSelectedCsIdSubscription: Subscription;
  laborEventTypes = [];

  csIdSelect = new FormControl();
  // emergencyCsIdSelect = new FormControl();
  printerCsIdSelect = new FormControl();
  laborServiceSelect = new FormControl();
  laborEventTypeSelect = new FormControl();
  csIdSelectForm = new FormGroup({
    'csIdSelect': this.csIdSelect,
    'printerCsIdSelect': this.printerCsIdSelect,
    'laborServiceSelect': this.laborServiceSelect,
    'laborEventTypeSelect': this.laborEventTypeSelect
  });


  selectedCsId = null;

  Brand = '';
  medcseppFeatureSet = '';

  laborSvId = null;
  laborServiceList = [];

  @Input() set realignInkBar(isRealign) {
    if (this.settingsMainTabGroup) {
      this.settingsMainTabGroup.realignInkBar();
    }
  }

  ngOnInit() {

    const tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);
    const date = this.sharedDataService.dateToDateString(tomorrow);
    this.worklist_date.setValue(this.sharedDataService.dateStringToNgbDate(date));

    // this.sharedDataService.loadAllPrintersOfInstitute();
    this.medcseppFeatureSet = this.authService.getFeature();
    this.Brand = this.authService.getBrand();
    if (this.Brand === 'MEDCSEPP') {
      this.activeTab = 1;

      this.csIdListSubscription = this.sharedDataService.csIdListShared.subscribe(data => {
        // console.log ('csIdList settings', data);
        let etList = [];
        this.csIdList = data['data'];
        this.csIdListLoading = data['isLoading'];
        const csIdObj = this.csIdList.find(obj => obj.csId == this.sharedDataService.getSelectedCsId());
        // console.log ('csIdObj', csIdObj);
        // console.log ('csIdObj', csIdObj);
        if (csIdObj) {
          this.sharedDataService.gettreeforcsid(csIdObj.csId).subscribe(treeResult => {
            this.laborEventTypes = [];
            // console.log('treeResult', treeResult);
            const parentCsId = this.findWrapper(treeResult);
            // console.log('csIdTreeResult', parentCsId);
            if (parentCsId && parentCsId.eventTypeList && parentCsId.eventTypeList !== '') {
                etList = parentCsId.eventTypeList.split(',');
                // console.log ('availService', this.availServic);
                // console.log ('etList', etList);
                etList.forEach(etItem => {
                  const serviceItem = this.availServic.find(availItem => availItem.etId == etItem);
                  if (serviceItem) {
                    this.laborEventTypes.push(serviceItem);
                  }
                });
                // console.log ('this.laborEventTypes', this.laborEventTypes);
                this.laborEventTypeSelect.setValue(this.sharedDataService.getSelectedEventType());
            }
          });
        }
        this.csIdSelect.setValue(csIdObj);
      });
    }

    this.allPrintersOfInstituteSubscription = this.sharedDataService.allPrintersOfInstituteShared.subscribe(data => {
      this.allPrintersOfInstitute = data;
      // console.log('allPrintersOfInstitute settings', this.allPrintersOfInstitute);
      // console.log('this.sharedDataService.getSelectedPrinterCsId()', this.sharedDataService.getSelectedPrinterCsId());
      const printerCsIdObj = this.allPrintersOfInstitute.find(obj => obj['csId'] == this.sharedDataService.getSelectedPrinterCsId());
      // console.log('printerCsIdObj', printerCsIdObj);
      this.printerCsIdSelect.setValue(printerCsIdObj);
    });

    this.setSelectedCsIdSubscription = this.sharedDataService.selectedCsIdShared.subscribe(data => {
      this.selectedCsId = data;
    });

    this.availServicSubscription = this.sharedDataService.availServicesShared.subscribe(data => {
      this.availServic = data;

      this.laborServiceList = [];
      const laborServiceListTemp = [];
      this.availServic.forEach(item => {
        // console.log ('item', item);
        if (item.etCode === 'LABVIZSG') {
          laborServiceListTemp.push(item);
        }
      });
      this.laborServiceList = laborServiceListTemp;
      this.laborServiceSelect.setValue(this.sharedDataService.getSelectedLaborService());
      // console.log ('this.laborServiceList',this.laborServiceList);
    });

    this.username.setValue(this.authService.getUserData()['ekUserName']);

    this.patientListDataSubscription = this.sharedDataService.patientListShared.subscribe((patientList) => {
      // console.log ('patientList arrived to settings');
      this.patientListLoading = false;
      this.patientList = patientList;
      this.patientCount = patientList.length;
    });
    this.newPassword2.valueChanges.pipe(debounceTime(100)).subscribe(value => {
      this.validatePasswordForm();
    });
    this.newPassword.valueChanges.pipe(debounceTime(100)).subscribe(value => {
      this.validatePasswordForm();
    });
  }

  ngOnDestroy() {
    if (this.allPrintersOfInstituteSubscription) { this.allPrintersOfInstituteSubscription.unsubscribe(); }
    if (this.availServicSubscription) { this.availServicSubscription.unsubscribe(); }
    if (this.patientListDataSubscription) { this.patientListDataSubscription.unsubscribe(); }
    if (this.csIdListSubscription) { this.csIdListSubscription.unsubscribe(); }
    if (this.setSelectedCsIdSubscription) { this.setSelectedCsIdSubscription.unsubscribe(); }
  }

  resetDocumentModal() {

  }

  closeResult() {
    this.saveErrorDescription = '';
    this.saveResult = null;
  }


  closeModal() {
    this.switchTab(0);
  }

  hideCloseConfirmationDialogue() {
    this.closeConfirmationDialogueVisible = false;
  }

  resetModal() {
    this.closeResult();
    // this.documentTypeSelectGroup.reset();
  }

  resetModalAndHideDialogue() {
    this.hideCloseConfirmationDialogue();
    // this.documentTypeSelectGroup.reset();
  }

  switchTab(tabID) {
    this.activeTab = tabID;
    console.log('activeTab: ', this.activeTab);
    
  }

  onFormDataChange(id: number, data: any) {
    // this.validateImplantData();
  }

  findWrapper(treeResult) {
    return (treeResult.find(obj => obj.type == 'C'));
  }

  validatePasswordForm() {
    this.newPasswordsValid = 0;
    if (this.newPassword.valid) {
      if (this.newPassword.value === this.newPassword2.value) {
        if (this.newPassword.value !== this.oldPassword.value) {
          this.newPasswordsValid = 1;
        } else { this.newPasswordsValid = -3; }
      } else { this.newPasswordsValid = -2; }
    }
  }

  onChangePassword() {
    // console.log(this.authService.getUserData());
    // console.log(this.changePasswordForm);

    if (this.newPassword.value !== this.newPassword2.value) {
      console.log('new passwords don\'t match');
    } else {
      this.formSubmitted = true;
      this.saveInProgress = true;
      this.changePassword(this.oldPassword.value, this.newPassword.value).subscribe(
        (data: Response) => {
          this.saveInProgress = false;
          if (data['errorCode'] === 10000) {
            setTimeout(() => { this.saveResult = null; }, 3000);
            this.formSubmitted = false;
            this.saveResult = 0;
            console.log('Password changed!');
            this.changePasswordForm.reset();
          } else {
            this.saveResult = -1;
            this.saveErrorDescription = data['errorMessage'];
            // hiba
          }
        }
      );
    }
  }

  changePassword(oldPassword: string, newPassword: string) {

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      tapass: this.authService.getUserData()['ekCardNo'],
      oldPw: oldPassword,
      newPw: newPassword
    };
    return this.http.post(this.apiUrl + '/rest/neu/authentication/changepassword', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }



  generateExcel() {

    let apiResponse = {};

    this.getImpregReportCsv().subscribe(data => {
      if (data['code'] === 1) {
        apiResponse = data;

        let implantData = apiResponse['data'];
        implantData = implantData.replace(/\\/g, '');
        implantData = implantData.replace(/\"/g, '');
        //    let implantDataList = implantData.split(',');

        const implantArray = [];
        const implantDataList = implantData.split('\r\n');

        implantDataList.forEach(implantRowItem => {

          const implantRowItemArray = implantRowItem.split(',');

          if (implantRowItemArray.length > 1) {

            for (let i = 0; i < 125; i++) {
              implantRowItemArray[i] = implantRowItemArray[i] ? implantRowItemArray[i] : '';
            }

            const spreadsheetData = {
              'Mező neve': '',
              // SZEMELYES ADATOK
              'TELEPHELY(*)': implantRowItemArray[0],
              'BEAV_TIPUS**': implantRowItemArray[1],
              'BEAV_DATUM*': implantRowItemArray[2],
              'KF_BETEGAZON_TIP (*)': implantRowItemArray[3],
              'NKF_BETEGAZON_TIP *': implantRowItemArray[4],
              'BETEGAZONOSÍTÓ*': implantRowItemArray[5],
              'SZUL_DATUM*': implantRowItemArray[6],
              'NKF_BELSO_BETEGAZON (*)': implantRowItemArray[7],
              'SZA_SZULETESI_CSALADI_NEV*': implantRowItemArray[8],
              'SZA_SZULETESI_UTONEVEK*': implantRowItemArray[9],
              'SZA_ANYJA_SZULETESI_CSALADI_NEV*': implantRowItemArray[10],
              'SZA_ANYJA_SZULETESI_UTONEVEK*': implantRowItemArray[11],
              'SZA_VISELT_CSALADI_NEV*': implantRowItemArray[12],
              'SZA_VISELT_UTONEVEK*': implantRowItemArray[13],
              'LAKOHELY*': implantRowItemArray[14],
              'TARTOZKODASI_HELY*': implantRowItemArray[15],
              'TELEFONSZAM*': implantRowItemArray[16],

              // IMPLANT ADATOK EGYSZERI
              'BEAV_OKA*': implantRowItemArray[17],
              'BEAV_INDOKLAS*': implantRowItemArray[18],
              'BEAV_PSZ*': implantRowItemArray[19],
              'CSERE_ESZKMIATT(*)': implantRowItemArray[20],
              'KF_AUTO_ATTOLT(*)': implantRowItemArray[21],
              'MEGJ': implantRowItemArray[22],
              // IMPLANT ADATOK ISMETLODO
              'KF_FIN_TIPUS(*)': implantRowItemArray[23],
              'NKF_FIN_TIPUS(*)': implantRowItemArray[24],
              'FOCSOPORT_KOD*': implantRowItemArray[25],
              'ALCSOPORT1_KOD*': implantRowItemArray[26],
              'ALCSOPORT2_KOD(*)': implantRowItemArray[27],
              'ALCSOPORT3_KOD(*)': implantRowItemArray[28],
              'ALCSOPORT4_KOD(*)': implantRowItemArray[29],
              'ALCSOPORT5_KOD(*)': implantRowItemArray[30],
              'IMPLANT_NEV*': implantRowItemArray[31],
              'IMPLANT_TIP*': implantRowItemArray[32],
              'IMPLANT_GYARTO*': implantRowItemArray[33],
              'IMPLANT_GYTETELSZAM*': implantRowItemArray[34],
              'IMPLANT_SOROZATSZAM': implantRowItemArray[35],
              'IMPLANT_FORGALMAZO*': implantRowItemArray[36],
              'IMPLANT_FORG_SZEKHELY*': implantRowItemArray[37],
              'DARABSZÁM*': implantRowItemArray[38],
              // IMPLANT ADATOK ISMETLODO
              '#2': '',
              'KF_FIN_TIPUS(*)#2': implantRowItemArray[39],
              'NKF_FIN_TIPUS(*)#2': implantRowItemArray[40],
              'FOCSOPORT_KOD*#2': implantRowItemArray[41],
              'ALCSOPORT1_KOD*#2': implantRowItemArray[42],
              'ALCSOPORT2_KOD(*)#2': implantRowItemArray[43],
              'ALCSOPORT3_KOD(*)#2': implantRowItemArray[44],
              'ALCSOPORT4_KOD(*)#2': implantRowItemArray[45],
              'ALCSOPORT5_KOD(*)#2': implantRowItemArray[46],
              'IMPLANT_NEV*#2': implantRowItemArray[47],
              'IMPLANT_TIP*#2': implantRowItemArray[48],
              'IMPLANT_GYARTO*#2': implantRowItemArray[49],
              'IMPLANT_GYTETELSZAM*#2': implantRowItemArray[50],
              'IMPLANT_SOROZATSZAM#2': implantRowItemArray[51],
              'IMPLANT_FORGALMAZO*#2': implantRowItemArray[52],
              'IMPLANT_FORG_SZEKHELY*#2': implantRowItemArray[53],
              'DARABSZÁM*#2': implantRowItemArray[54],
              // IMPLANT ADATOK ISMETLODO
              '#3': '',
              'KF_FIN_TIPUS(*)#3': implantRowItemArray[55],
              'NKF_FIN_TIPUS(*)#3': implantRowItemArray[56],
              'FOCSOPORT_KOD*#3': implantRowItemArray[57],
              'ALCSOPORT1_KOD*#3': implantRowItemArray[58],
              'ALCSOPORT2_KOD(*)#3': implantRowItemArray[59],
              'ALCSOPORT3_KOD(*)#3': implantRowItemArray[60],
              'ALCSOPORT4_KOD(*)#3': implantRowItemArray[61],
              'ALCSOPORT5_KOD(*)#3': implantRowItemArray[62],
              'IMPLANT_NEV*#3': implantRowItemArray[63],
              'IMPLANT_TIP*#3': implantRowItemArray[64],
              'IMPLANT_GYARTO*#3': implantRowItemArray[65],
              'IMPLANT_GYTETELSZAM*#3': implantRowItemArray[66],
              'IMPLANT_SOROZATSZAM#3': implantRowItemArray[67],
              'IMPLANT_FORGALMAZO*#3': implantRowItemArray[68],
              'IMPLANT_FORG_SZEKHELY*#3': implantRowItemArray[69],
              'DARABSZÁM*#3': implantRowItemArray[70],
              // IMPLANT ADATOK ISMETLODO
              '#4': '',
              'KF_FIN_TIPUS(*)#4': implantRowItemArray[71],
              'NKF_FIN_TIPUS(*)#4': implantRowItemArray[72],
              'FOCSOPORT_KOD*#4': implantRowItemArray[73],
              'ALCSOPORT1_KOD*#4': implantRowItemArray[74],
              'ALCSOPORT2_KOD(*)#4': implantRowItemArray[75],
              'ALCSOPORT3_KOD(*)#4': implantRowItemArray[76],
              'ALCSOPORT4_KOD(*)#4': implantRowItemArray[77],
              'ALCSOPORT5_KOD(*)#4': implantRowItemArray[78],
              'IMPLANT_NEV*#4': implantRowItemArray[79],
              'IMPLANT_TIP*#4': implantRowItemArray[80],
              'IMPLANT_GYARTO*#4': implantRowItemArray[81],
              'IMPLANT_GYTETELSZAM*#4': implantRowItemArray[82],
              'IMPLANT_SOROZATSZAM#4': implantRowItemArray[83],
              'IMPLANT_FORGALMAZO*#4': implantRowItemArray[84],
              'IMPLANT_FORG_SZEKHELY*#4': implantRowItemArray[85],
              'DARABSZÁM*#4': implantRowItemArray[86],
              // IMPLANT ADATOK ISMETLODO
              '#5': '',
              'KF_FIN_TIPUS(*)#5': implantRowItemArray[87],
              'NKF_FIN_TIPUS(*)#5': implantRowItemArray[88],
              'FOCSOPORT_KOD*#5': implantRowItemArray[89],
              'ALCSOPORT1_KOD*#5': implantRowItemArray[90],
              'ALCSOPORT2_KOD(*)#5': implantRowItemArray[91],
              'ALCSOPORT3_KOD(*)#5': implantRowItemArray[92],
              'ALCSOPORT4_KOD(*)#5': implantRowItemArray[93],
              'ALCSOPORT5_KOD(*)#5': implantRowItemArray[94],
              'IMPLANT_NEV*#5': implantRowItemArray[95],
              'IMPLANT_TIP*#5': implantRowItemArray[96],
              'IMPLANT_GYARTO*#5': implantRowItemArray[97],
              'IMPLANT_GYTETELSZAM*#5': implantRowItemArray[98],
              'IMPLANT_SOROZATSZAM#5': implantRowItemArray[99],
              'IMPLANT_FORGALMAZO*#5': implantRowItemArray[100],
              'IMPLANT_FORG_SZEKHELY*#5': implantRowItemArray[100],
              'DARABSZÁM*#5': implantRowItemArray[101],
              // IMPLANT ADATOK ISMETLODO
              '#6': '',
              'KF_FIN_TIPUS(*)#6': implantRowItemArray[102],
              'NKF_FIN_TIPUS(*)#6': implantRowItemArray[103],
              'FOCSOPORT_KOD*#6': implantRowItemArray[104],
              'ALCSOPORT1_KOD*#6': implantRowItemArray[105],
              'ALCSOPORT2_KOD(*)#6': implantRowItemArray[106],
              'ALCSOPORT3_KOD(*)#6': implantRowItemArray[107],
              'ALCSOPORT4_KOD(*)#6': implantRowItemArray[108],
              'ALCSOPORT5_KOD(*)#6': implantRowItemArray[109],
              'IMPLANT_NEV*#6': implantRowItemArray[110],
              'IMPLANT_TIP*#6': implantRowItemArray[111],
              'IMPLANT_GYARTO*#6': implantRowItemArray[112],
              'IMPLANT_GYTETELSZAM*#6': implantRowItemArray[113],
              'IMPLANT_SOROZATSZAM#6': implantRowItemArray[114],
              'IMPLANT_FORGALMAZO*#6': implantRowItemArray[115],
              'IMPLANT_FORG_SZEKHELY*#6': implantRowItemArray[116],
              'DARABSZÁM*#6': implantRowItemArray[117],
            };
            implantArray.push(spreadsheetData);
          }

        });
        console.log('implantArray:', implantArray);
        if (implantArray.length > 0) {
          this.excelService.exportAsExcelFile(implantArray, 'ImplantReg_Export.xlsx');
        } else {
          this.screenMessage = 'A megadott paraméterekkel nem található implantátum adat.';
        }
      }
    });

  }

  // generateExcel() {
  //   this.getImpregReportCsv().subscribe(data => {
  //     console.log (data);
  //   });
  // }


  getImpregReportCsv() {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      servicePointId: this.serviceSelect.value,
      calendarIdList: '',
      dateFrom: this.dateFrom.value,
      dateTo: this.dateTo.value
    };
    return this.http.post(this.apiUrl + '/rest/neu/calendarlist/getimpregreportcsv', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  openHelp(anchor) {
    const newWindow = window.open('https://' + this.authService.getHostname() + '/kezikonyv/' + anchor);
  }

  clearScreenMessage() {
    this.screenMessage = '';
  }

  onSelectedCsIdChange($event) {
    console.log('csID change', $event);
    this.sharedDataService.setSelectedCsId($event.csId);
  }

  onSelectedEmergencyCsIdChange($event) {
    console.log('csID change', $event);
    this.sharedDataService.setSelectedEmergencyCsId($event.csId);
  }


  onChangeSelectedPrinter($event) {
    console.log($event);
    this.sharedDataService.setSelectedPrinterCsId($event.csId);
  }

  onChangeLaborServiceList($event) {
    console.log('labor selected', $event);
    this.sharedDataService.setSelectedLaborService($event);
  }

  onChangeLaborEventType($event) {
    console.log('eventType selected', $event);
    this.sharedDataService.setSelectedEventType($event);
  }

  generateWorklist() {

    this.worklistLoading = true;
    const date = this.sharedDataService.NgbDateToDateString(this.worklist_date.value);

    this.getFutureAppointmentlist(date).subscribe(appointments => {
      this.worklistLoading = false;
      // console.log('appointments', appointments);
      if (appointments['code'] === 200) {
        let appointmentsData = appointments['appointments'];
        // console.log('appointments data', appointments['appointments']);
        appointmentsData.sort(this.sortWorklist);
        this.printWorklist(appointmentsData);
      } else {
        // hiba
      }
    });

  }

  sortWorklist(a, b) {
    if (a['timeFrom'] > b['timeFrom']) {
      return 1;
    } else if (a['timeFrom'] < b['timeFrom']) {
      return -1;
    }
    return 0;
  }

  getFutureAppointmentlist(date: string) {
    return this.http.get(environment.callerSystemUrl + 'rest/getfutureappointmentlist/' + this.selectedCsId + '/' + date);
  }



  printWorklist(data: []) {
    console.log ('this.sharedDataService.selectedRole', this.sharedDataService.selectedRole);

    let content = `<title>Napi munkalista</title>
    <head>
    <style>
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }

    td, th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }

    tr:nth-child(even) {
      background-color: #eeeeee;
    }
    </style>
    </head>
    <h2>Napi munkalista</h2>
    <h3>` + this.sharedDataService.selectedRole.spName + `</h3>
    <table>
      <tr>
      <th>Időpont</th>
      <th>Adatok</th>
      </tr>
      `;

    data.forEach(row => {
      content += `<tr>
        <td>` + this.sharedDataService.dateToDateTimeStringHuman(new Date(row['timeFrom'])) + `</td>
        <td>` + row['longDescription'] + `</td>
        </tr>`;
    });
    content += `</table>`;
    const newWindow = window.open('');
    newWindow.document.open();
    newWindow.document.write(content);
    newWindow.print();
    newWindow.document.close();
  }




  generateSamplelist() {

    this.worklistLoading = true;
    const date = this.sharedDataService.NgbDateToDateString(this.worklist_date.value);

    this.getLaborWorklist().subscribe(result => {
      this.worklistLoading = false;
      console.log('Samplelist', result);
      if (result['code'] === 1) {
        if (result['data']) {
          this.printSamplelist(result['data']);
        } else {
          this.saveResult = -1;
          this.saveErrorDescription = 'Nincs laborvizsgálati elem a listában.';
          setTimeout(() => { this.saveResult = null; }, 3000);
        }
      } else {
        this.saveResult = -1;
        this.saveErrorDescription = result['code'] + ', ' + result['message'];
        setTimeout(() => { this.saveResult = null; }, 3000);
        // hiba
      }
    });

  }

  sortSamplelist(a, b) {
    if (a['timeFrom'] > b['timeFrom']) {
      return 1;
    } else if (a['timeFrom'] < b['timeFrom']) {
      return -1;
    }
    return 0;
  }

  getLaborWorklist() {

    const today = this.sharedDataService.dateToDateString(new Date());

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      laborServiceId: this.sharedDataService.getSelectedLaborService().svId,
      dateFrom: today
    };
    return this.http.post(this.apiUrl + '/rest/neu/laborder/getlaborworklist', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }



  printSamplelist(data: []) {
    let content = `<title>Minta átadás-átvételi jegyzék</title>

    <head>
    <style>
    table.main {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }

    td.main, th.main {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }

    tr.main:nth-child(even) {
      background-color: #eeeeee;
    }
    </style>
    </head>
    <p>Mintavételi hely neve: Szolnok-Szandaszőlős mintavételi hely</p>
    <p>Mintavételi hely címe: 5008 Szolnok, Munkácsy Mihály u. 26.</p>
    <h2><center>Minta átadás-átvételi jegyzék</center></h2>
    <h3><center>` + this.sharedDataService.dateToDateTimeStringHuman(new Date()) + `</center></h3>
    <table class="main">
      <tr>
      <th class="main">Sorszám</th>
      <th class="main">Név</th>
      <th class="main">TAJ</th>
      <th class="main">Születési dátum</th>
      <th class="main">Cső azonosító</th>
      </tr>
      `;

    let i = 1;
    let j = 1;
    data.forEach(row => {
      content += `<tr>
        <td class="main">` + i + `</td>
        <td class="main">` + row['request']['patName'] + `</td>
        <td class="main">`  + row['request']['patSsn'] + `</td>
        <td class="main"> ` + row['request']['patBDate'] + `</td>
        <td class="main">
        <table>`;
      let samples = [];
      samples = row['samples'];
      samples.forEach(sample => {
        if (sample['smpCode']) {
          content +=
            `<tr>
              <td>` +
            sample['smpCode'] +
            `</td>
              </tr>`;
          j++;
        }
      });
      content +=

        `</table></tr>`;
      i++;
    });

    if (j > 0) {
      j--;
    }
    content += `</table>
    <br>
    A fent felsorolt `+ j + `. db mintát hiánytalanul és sérülés mentesen átadtam-átvettem.
    <br>
    <br>
    <br>
    <br>
    <table border="0" width="100%">
      <tr>
      <td width="10%">
      </td>
      <td width="30%"><center>
      .....................................<br>
      Átadó neve
      </center>
      </td>
      <td width="20%">
      </td>
      <td width="30%">
      <center>
      .....................................<br>
      Átvevő neve
      </center>
      </td>
      <td width="10%">
      </td>
      </tr>
      <tr>
      <td>&nbsp;<br>&nbsp;</td>
      </tr>
      <tr>
      <td width="10%">
      </td>
      <td width="30%">
      <center>
      .....................................<br>
      Átadó aláírása
      </center>
      </td>
      <td width="20%">
      </td>
      <td width="30%">
      <center>
      .....................................<br>
      Átvevő aláírása
      </center>
      </td>
      <td width="10%">
      </td>
      </tr>
    `;
    const newWindow = window.open('');
    newWindow.document.open();
    newWindow.document.write(content);
    newWindow.print();
    newWindow.document.close();
  }

}
