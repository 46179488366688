import { environment } from './../../environments/environment.test';
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { SharedDataService } from '../services/shared-data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { NgxPrintModule } from 'ngx-print';
import { SharedApiService } from '../services/shared-api.service';

@Component({
  selector: 'app-dolg-permission',
  templateUrl: './dolg-permission.component.html',
  styleUrls: ['./dolg-permission.component.css']
})

export class DolgPermissionComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(public authService: AuthService,
    private sharedApiService: SharedApiService,
    private http: HttpClient,
    private sharedDataService: SharedDataService
  ) { }

  // @ViewChild(MatPaginator) paginator: MatPaginator;

  acDeviceListSubscription: Subscription;
  acDeviceList = [];
  eventLogRows = [];
  loading = false;
  pristine = true;
  showImage = false;
  imageLoading = true;
  eventEntry = {};
  imageError = false;

  saveInProgress = false;
  saveResult = null;
  successMessage = '';
  saveErrorDescription = '';

  regImageData = "";

  userId = null;

  detailsName = '';
  detailsPosition = '';
  detailsRole = '';
  detailsRegPicId = null;
  detail = null;
  detailLoading = false;

  documents = null;
  documentsLoading = false;
  documentsCount = 0;

  patientListLoading = true;
  patientListDataSubscription: Subscription;
  patientList = [];
  tapass = '';

  @ViewChild('filterTable')
  filterTable: ElementRef;

  filterform: FormGroup;

  displayedColumns = ['permname', 'permstatus', 'datefrom', 'dateto', 'archive']; // , 'archive'
  dataSource = new MatTableDataSource<any>();

  imageData = {};

  today = new Date();
  endDate = new Date(this.today.getTime() + 7776000000);

  startDateString = this.today.getFullYear() + '-' +
    (('0' + (this.today.getMonth() + 1)).slice(-2)) + '-' +
    (('0' + this.today.getDate()).slice(-2));

  endDateString = this.endDate.getFullYear() + '-' +
    (('0' + (this.endDate.getMonth() + 1)).slice(-2)) + '-' +
    (('0' + this.endDate.getDate()).slice(-2));

  dateFromInput = new FormControl(this.sharedDataService.dateStringToNgbDate(this.startDateString), Validators.required);
  dateToInput = new FormControl(this.sharedDataService.dateStringToNgbDate(this.endDateString), Validators.required);
  acDeviceFilter = new FormControl('', [Validators.required]);
  patientName = new FormControl();
  filterInput = new FormControl();

  requestDetails = new FormGroup({
    dateFromInput: this.dateFromInput,
    dateToInput: this.dateToInput,
    acDeviceFilter: this.acDeviceFilter,
    patientName: this.patientName
  });

  dateChangeSubscriptionStart: Subscription;
  dateChangeSubscriptionEnd: Subscription;
  refreshAppointmentsSubscription: Subscription;

  dateInvalidStart = null;
  dateInvalidEnd = null;

  apiUrl = environment.apiUrl;
  environment = environment;

  ngOnInit() {
    // this.dataSource.paginator = this.paginator;

    this.acDeviceListSubscription = this.sharedDataService.acDeviceListShared.subscribe(data => {
      const acDeviceList = data;
      acDeviceList.sort(this.sortAcDeviceList);
      // console.log ('acdevlist', acDeviceList);
      this.acDeviceList = acDeviceList;
      // this.acDeviceList = this.environment.acDeviceListTemp;
      // console.log ('adDeviceList', this.acDeviceList);
    });

    this.patientListDataSubscription = this.sharedDataService.patientListShared.subscribe((patientList) => {
      this.patientList = [];
      // console.log ('register patient - patient list updated', patientList);
      this.patientListLoading = false;
      this.patientList = patientList;

    });

  }

  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    if (this.acDeviceListSubscription) {
      this.acDeviceListSubscription.unsubscribe();
    }
    if (this.patientListDataSubscription) {
      this.patientListDataSubscription.unsubscribe();
    }
  }



  onSelectedPatientChange($event) {
    if ($event && $event['tapass'].length !== 0) {
      this.loading = true;
      this.tapass = $event['tapass'];
      this.sharedApiService.getUserSimple(this.tapass).subscribe(
        (userData: Response) => {
          if (userData['code'] === 1) {
            console.log ('dolgPermission getusersimple', userData);
            this.userId = userData['user']['userId'];
            this.getUserAcPermissions(this.userId).subscribe(
              (permissionData: Response) => {
                this.loading = false;
                if (permissionData['code'] === 1) {
                  
                  this.dataSource = new MatTableDataSource(permissionData['data']);
                  console.log ('dolgPermission', permissionData);
                } else {
                }
              });

          } else {
            this.loading = false;
            this.userId = null;
          }
        });
      console.log ('dolgPermission', $event);
    } else {
      this.userId = null;
      this.tapass = '';
    }
  }



  getUserAcPermissions(userId) {

    const devices = [];
    this.acDeviceList.forEach (item => {
      devices.push(item.deviceId);
    });

    const startDateString = this.sharedDataService.NgbDateToDateString(this.dateFromInput.value);
    const endDateString = this.sharedDataService.NgbDateToDateString(this.dateToInput.value);

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      userId: userId,
      dateFrom : '2010-10-01',
      dateTo : '2090-01-01',
      selectedDevices: devices
    };

    return this.http.post(this.apiUrl + '/rest/neu/dlm/getuseracpermissions', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }

  onManageCheckPermission() {


    this.saveInProgress = true;
    const startDateString = this.sharedDataService.NgbDateToDateString(this.dateFromInput.value);
    const endDateString = this.sharedDataService.NgbDateToDateString(this.dateToInput.value);

    this.manageCheckPermission(this.userId, this.acDeviceFilter.value, startDateString, endDateString, '1').subscribe(
      (permissionData: Response) => {
        this.saveInProgress = false;

        if (permissionData['code'] === 1) {

          this.saveResult = 0;
          this.successMessage = 'Jogosultságok mentése sikeres!';
          setTimeout(() => { this.saveResult = null; }, 2000);

          console.log ('manageCheckPermission', permissionData);
          this.loading = true;
          this.getUserAcPermissions(this.userId).subscribe(
            (permissionResult: Response) => {
              this.loading = false;
              if (permissionResult['code'] === 1) {
                
                this.dataSource = new MatTableDataSource(permissionResult['data']);
                console.log ('dolgPermission', permissionResult);
              } else {
              }
            });
        } else {
          this.saveResult = -1;
          this.saveErrorDescription = 'Hiba a jogosultság mentése során: ' + permissionData['code'];
        }
      });
  }

  closeResult() {
    this.saveResult = null;
    this.saveErrorDescription = '';
  }

  manageCheckPermission(userId, acDeviceFilterValue, startDate, endDate, granted) {

    // console.log ('acdevfiltval', acDeviceFilterValue);
    if (acDeviceFilterValue.length < 1) {
      acDeviceFilterValue = [acDeviceFilterValue];
    }

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      deviceIdList: acDeviceFilterValue,
      userId: userId,
      validFrom : startDate,
      validTo : endDate,
      granted: granted
    };

    return this.http.post(this.apiUrl + '/rest/neu/dlm/managecheckpermission', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }


  sortEventLogRows(a, b) {
    if (a['eventTime'] < b['eventTime']) {
      return 1;
    } else if (a['eventTime'] > b['eventTime']) {
      return -1;
    }
    return 0;
  }

  resetModal() {
    this.acDeviceFilter.reset();
    this.patientName.reset();
    this.pristine = true;
    this.eventLogRows = [];
    this.dataSource = new MatTableDataSource([]);
    this.imageData = {};
    this.regImageData = "";
  }

  closeImage() {
    this.showImage = false;
    this.eventEntry = {};
    this.imageError = false;
  }

  applyFilter(filterValue: string) {
    if (filterValue) {
      filterValue = filterValue.trim();
      filterValue = filterValue.toLowerCase();
      this.dataSource.data = this.eventLogRows;
      this.dataSource.filter = filterValue;
      this.dataSource.data = this.dataSource.filteredData;
    } else {
      this.filterTable.nativeElement.value = '';
      this.dataSource = new MatTableDataSource<any>(this.eventLogRows);
    }
  }

  sortAcDeviceList(a, b) {
    return new Intl.Collator('hu').compare(a['deviceName'], b['deviceName']);  // -1
  }





  loadDocuments(tapass) {
    this.imageData = '';
    this.documents = null;
    this.documentsLoading = true;
    let documents = [];
    this.getDocuments(tapass).subscribe(
      (data: Response) => {
        this.documentsLoading = false;
        if (data['code'] === 1) {
          // console.log(data['data']);
          if (data['data'].length > 0) {

            data['data'].forEach(entry => {
              entry['docDate'] = this.sharedDataService.DateStringConvert(entry['docDate']);
              if (entry['docFormat'] !== 'HTML') {
                documents.push(entry);
              }
            });

            documents.sort(this.sortDocuments);
            let regPicId = null;
            documents.forEach(doc => {
              if (doc['docTypeCode'] === 'REG_PIC_JPG' && !regPicId) {
                regPicId = doc['docId'];
              }
            });
            if (regPicId) {
              this.detailsRegPicId = regPicId;
              this.loadRegPic(this.detailsRegPicId);
            }
          }
          this.documents = documents;
          this.documentsCount = this.documents.length;
        } else {
          // hiba
        }
      }
    );
  }

  loadRegPic(documentID) {

    this.getDocument(documentID).subscribe(
      (data: Response) => {
        if (data['code'] == 1) {
          this.regImageData = "data:image/jpeg;base64, " + data['document'];
        } else {
          this.regImageData = "";
          // error
        }
        // console.log(data);
      }
    );
  }


  getDocument(documentID) {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      docId: documentID
    };

    return this.http.post(this.apiUrl + '/rest/neu/docinv/getdocumentjson', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }
  sortDocuments(a, b) {
    if (a['docDate'] > b['docDate']) {
      return -1;
    } else if (a['docDate'] < b['docDate']) {
      return 1;
    } else {
      if (a['docId'] > b['docId']) {
        return -1;
      } else {
        return 1;
      }
      return 0;
    }
  }


  getDocuments(tapass) {

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      requestedUserTapass: tapass,
      dateFrom: null,
      dateTo: null,
      docTypes: null,
      fromIndex: null,
      toIndex: null
    };

    return this.http.post(this.apiUrl + '/rest/neu/docinv/getdocuments', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }

  printMozgas() {
    let content = `<title>Dolgozói mozgás</title>
    <head>
    <style>
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }
    
    td, th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }
    
    tr:nth-child(even) {
      background-color: #eeeeee;
    }
    </style>
    </head>
    <table>
      <tr>
      <th>Dátum</th>
      <th>Név</th>
      <th>Eszköz</th>
      </tr>
      `;

    this.eventLogRows.forEach(row => {
      content += `<tr>
        <td>` + this.sharedDataService.dateToDateTimeStringHuman(new Date(row['eventTime'])) + `</td>
        <td>` + row['name'] + `</td>
        <td>` + row['deviceName'] + `</td>
        </tr>`;
    });
    content += `</table>`;
    const newWindow = window.open('');
    newWindow.document.open();
    newWindow.document.write(content);
    newWindow.print();
    newWindow.document.close();
  }


  archivePermission(element) {
    this.saveInProgress = true;
    // userId, acDeviceFilterValue, startDate, endDate, granted
    console.log ('archivePermission', element);
    this.manageCheckPermission(this.userId, [element.deviceId], element.grantFrom, element.grantTo, '0').subscribe(
      (permissionData: Response) => {
        this.saveInProgress = false;        
        if (permissionData['code'] === 1) {

          this.saveResult = 0;
          this.successMessage = 'Jogosultságok mentése sikeres!';
          setTimeout(() => { this.saveResult = null; }, 2000);

          console.log ('manageCheckPermission', permissionData);
          this.loading = true;
          this.getUserAcPermissions(this.userId).subscribe(
            (permissionResult: Response) => {
              this.loading = false;
              if (permissionResult['code'] === 1) {
                
                this.dataSource = new MatTableDataSource(permissionResult['data']);
                console.log ('dolgPermission', permissionResult);
              } else {
              }
            });
        } else {
          this.saveResult = -1;
          this.saveErrorDescription = 'Hiba a jogosultság mentése során: ' + permissionData['code'];
        }
      });
  }
  
}
