<!-- Modal -->
<div class="modal" id="idopontkezelo-heti" tabindex="-1" role="dialog" aria-labelledby="Időpontkezelő heti" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">

                <h5 class="modal-title" id="exampleModalLongTitle">Időpontkezelő (heti)</h5>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">X</button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">



                        <div class="grid-container">

                            <div class="item header-time">
                                <i class="fa fa-calendar"></i>
                            </div>
                            <div class="item header-day" *ngFor="let day of days; let i = index" [ngClass]="{'header-day2': (i % 2 !== 0)}">{{ day }}</div>
                            <!-- new row -->

                            <div class="item" *ngFor="let time of timeslots; let i = index" [ngStyle]="{
                'grid-row-start': (i+2),
                'grid-row-end': (i+3),
                'grid-column-start': 1,
                'grid-column-end': 2
              }">
                                {{ time }}
                            </div>


                            <div class="item" *ngFor="let item of renderedCalendarItems; let i = index" [ngClass]="{
                'cat-0': item.category === 0,
                'cat-1': item.category === 1,
                'inactive': item.status !== 'F'
                }" [ngStyle]="{
                'grid-column-start': ((item.dayIndex*3)+2+item.category),
                'grid-column-end': ((item.dayIndex*3)+3+item.category),
                'grid-row-start': (item.itemIndex+2),
                'grid-row-end': (item.itemIndex+item.length+2)
              }" (click)='logItemDetails(item)'>
                                <div *ngIf="item.status === 'F'">+</div>
                                <div *ngIf="item.status !== 'F'"> {{ item.status }}</div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>