import { SharedDataService } from './services/shared-data.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'resolveEesztTor'
})

export class ResolveEesztTorPipe implements PipeTransform {

  constructor(
    private sharedDataService: SharedDataService,
  ) { }

  transform(value: any, args?: any): any {
    // console.log('epro pipe value: ', value);
    // console.log('epro pipe args0: ', args);
    // console.log ('epro shared', this.sharedDataService.eproTor);
    if (this.sharedDataService.eproTor && this.sharedDataService.eproTor[args]) {
      // console.log('++ epro pipe', this.sharedDataService.eproTor[args][Object.keys(this.sharedDataService.eproTor[args])]['rows']);

      // if (this.sharedDataService.eproTor && this.sharedDataService.eproTor[args]) {
      //   console.log ('++ epro pipe', this.sharedDataService.eproTor[args]);
      if (this.sharedDataService.eproTor[args][Object.keys(this.sharedDataService.eproTor[args])]['rows']) {
        // console.log ('p', this.sharedDataService.eproTor[args][Object.keys(this.sharedDataService.eproTor[args])]['rows']);
        const foundItem = this.sharedDataService.eproTor[args][Object.keys(this.sharedDataService.eproTor[args])]['rows'].find(item => 
          item.value === value
        );
        if (foundItem && foundItem !== undefined) {
          // console.log ('tor found', foundItem);
          return foundItem.displayName;
          
        }
      }

    }

    return value;
  }
}
