import { SharedApiService } from './../../services/shared-api.service';
import { debounceTime } from 'rxjs/operators';
import { SharedDataService } from './../../services/shared-data.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OnInit, Inject, Component } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-legal-guardian-dialog',
    templateUrl: './legal-guardian-dialog.html',
    styleUrls: ['../register-patient.component.css']
})
export class LegalGuardianDialog implements OnInit {

    countryList = environment.countryList;
    patientList = [];

    birthname = new FormControl('', [Validators.required, Validators.minLength(4)]);
    birthplace = new FormControl('', [Validators.required, Validators.minLength(2)]);
    dateofbirth = new FormControl('', [Validators.required]);
    mothersname = new FormControl('', [Validators.required, Validators.minLength(4)]);
    aliasname = new FormControl('', [Validators.required, Validators.minLength(4)]);
    lgGender = new FormControl(null);
    phone = new FormControl('');
    country = new FormControl('');
    zip = new FormControl('', [Validators.minLength(3)]);
    city = new FormControl('', [Validators.minLength(2)]);
    street = new FormControl('', [Validators.minLength(4)]);
    houseno = new FormControl('', [Validators.minLength(1)]);
    floordoor = new FormControl('');
    billingname = new FormControl('');
    billingtaxid = new FormControl('');
    billingzip = new FormControl('');
    billingcity = new FormControl('');
    billingaddress = new FormControl('');

    legalGuardianDetails = new FormGroup({
        birthname: this.birthname,
        birthplace: this.birthplace,
        mothersname: this.mothersname,
        dateofbirth: this.dateofbirth,
        aliasname: this.aliasname,
        lgGender: this.lgGender,
        phone: this.phone,
        country: this.country,
        zip: this.zip,
        city: this.city,
        street: this.street,
        houseno: this.houseno,
        floordoor: this.floordoor,
        billingname: this.billingname,
        billingtaxid: this.billingtaxid,
        billingzip: this.billingzip,
        billingcity: this.billingcity,
        billingaddress: this.billingaddress
    });


    patientName = new FormControl();

    patientListForm = new FormGroup({
        'patientName': this.patientName
    });

    dateInvalid = false;
    formSubmitted = false;
    closeError = false;
    closeErrorMessage = '';
    patientListLoading = false;


    dateChangeSubscription: Subscription;
    patientListDataSubscription: Subscription;

    constructor(
        private dialogRef: MatDialogRef<LegalGuardianDialog>,
        private sharedDataService: SharedDataService,
        private sharedApiService: SharedApiService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    }

    ngOnInit() {
        if (this.data) {
            this.setLegalGuardianData();
        }

        this.dateChangeSubscription = this.dateofbirth.valueChanges.pipe(
            debounceTime(500)
        ).subscribe(value => {
            if (value !== '' && value !== null) {

                if (value.year !== undefined) {
                    this.dateInvalid = false;
                } else {
                    const dateValue = value.replace(/\./g, '-');
                    const dateObject = this.sharedDataService.dateFromUserinput(dateValue);
                    if (dateObject && !isNaN(dateObject.getTime())) {
                        const newDate = this.sharedDataService.dateToDateString(dateObject);
                        this.dateofbirth.setValue(this.sharedDataService.dateStringToNgbDate(newDate));
                        this.dateInvalid = false;
                    } else {
                        this.dateInvalid = true;
                    }
                }
            }
        });


        this.patientListDataSubscription = this.sharedDataService.patientListShared.subscribe((patientList) => {
            this.patientList = [];
            // console.log ('register patient - patient list updated', patientList);
            this.patientListLoading = false;
            this.patientList = patientList;

        });
    }

    ok() {
        this.formSubmitted = true;
        if (this.legalGuardianDetails.valid || this.legalGuardianDetails.disabled) {
            this.dialogRef.close(this.legalGuardianDetails);
        } else {
            this.closeError = true;
            this.closeErrorMessage = 'A *-gal jelölt mezők megadása kötelező';
        }
    }

    close() {
        this.dialogRef.close();
    }


    onSelectedPatientChange($event) {
        if ($event) {
            const birthdate = new Date($event['chk4TDate']);
            const ageDifMs = Date.now() - birthdate.getTime();
            const ageDate = new Date(ageDifMs);
            const age = Math.abs(ageDate.getUTCFullYear() - 1970);

            if (age < 18) {
                this.closeError = true;
                this.closeErrorMessage = '18 éven aluli személy nem lehet törvényes képviselő!';
            } else {
                this.closeError = false;
                this.closeErrorMessage = '';
                if ($event && $event['tapass'].length !== 0) {
                    this.loadPatientDetails($event['tapass']);
                } else {
                    this.legalGuardianDetails.reset();
                }
            }
        }
    }

    loadPatientDetails(tapass) {
        // console.log ('loadPatient Start');
        this.sharedApiService.getUserSimple(tapass).subscribe(
            (userData: Response) => {
                if (userData['code'] === 1) {
                    this.setUserData(userData);
                }
            }
        );
    }

    setUserData(userData) {

        console.log('userData: ', userData['user']['street']);

        userData['user']['birthForename2'] = !userData['user']['birthForename2'] ? '' : userData['user']['birthForename2'];

        userData['user']['birthForename2'] = userData['user']['birthForename2'] !== '' ? ' ' +
            userData['user']['birthForename2'] : userData['user']['birthForename2'];

        userData['user']['birthForename'] = !userData['user']['birthForename'] ? '' : ' ' + userData['user']['birthForename'];

        userData['user']['birthForename'] = userData['user']['birthForename'] !== '' ? ' ' +
            userData['user']['birthForename'] : userData['user']['birthForename'];

        userData['user']['birthName'] = userData['user']['birthSurname'] + userData['user']['birthForename'] +
            userData['user']['birthForename2'];

        userData['user']['forename'] = userData['user']['forename'] ? ' ' + userData['user']['forename'] : '';
        userData['user']['forename2'] = userData['user']['forename2'] ? ' ' + userData['user']['forename2'] : '';

        userData['user']['name'] = userData['user']['surname'] + userData['user']['forename'] + userData['user']['forename2'];

        userData['user']['street'] = userData['user']['street'] ? userData['user']['street'] + ' ' : '';
        userData['user']['streetType'] = userData['user']['streetType'] ? userData['user']['streetType'] + ' ' : '';
        userData['user']['houseNumber'] = userData['user']['houseNumber'] ? userData['user']['houseNumber'] : '';
        userData['user']['zipCode'] = userData['user']['zipCode'] ? userData['user']['zipCode'] : '';
        userData['user']['email'] = userData['user']['email'] ? userData['user']['email'] : '';
        userData['user']['phoneNumber'] = userData['user']['phoneNumber'] ? userData['user']['phoneNumber'] : '';

        this.aliasname.setValue(userData['user']['name']);
        this.birthname.setValue(userData['user']['birthName']);

        this.dateofbirth.setValue(userData['user']['dateOfBirth']);
        this.birthplace.setValue(userData['user']['placeOfBirth']);
        this.street.setValue(userData['user']['street'] + userData['user']['streetType'] + userData['user']['houseNumber']);
        this.zip.setValue(userData['user']['zipCode']);
        this.lgGender.setValue(userData['user']['gender']);
        this.phone.setValue(userData['user']['phoneNumber']);
        this.mothersname.setValue(userData['user']['mothersName']);
        this.city.setValue(userData['user']['settlement']);
        this.country.setValue(userData['user']['country3Digit']);
        this.billingname.setValue(userData['user']['invoiceName']);
        this.billingtaxid.setValue(userData['user']['invoiceTaxNumber']);
        this.billingcity.setValue(userData['user']['invoiceSettlement']);
        this.billingaddress.setValue(userData['user']['invoiceStreetWithDetails']);
        this.billingzip.setValue(userData['user']['invoiceZipcode']);

        if (userData['user']['id'] !== undefined) {
            userData['user']['userId'] = userData['user']['id'];
        }
    }

    setLegalGuardianData() {
        this.birthname.setValue(this.data.lg?.birthname);
        this.birthplace.setValue(this.data.lg?.birthplace);
        let birthDate = null;
        if (typeof this.data.lg?.dateofbirth === 'string' || this.data.lg?.dateofbirth instanceof String) {
            birthDate = this.sharedDataService.dateStringToNgbDate(this.data.lg?.dateofbirth);
        }
        this.dateofbirth.setValue(birthDate ? birthDate : this.data.lg?.dateofbirth);
        this.mothersname.setValue(this.data.lg?.mothersname);
        this.aliasname.setValue(this.data.lg?.aliasname);
        this.phone.setValue(this.data.lg?.phone);
        this.zip.setValue(this.data.lg?.zip);
        this.city.setValue(this.data.lg?.city);
        this.street.setValue(this.data.lg?.street);
        this.houseno.setValue(this.data.lg?.houseno);
        this.floordoor.setValue(this.data.lg?.floordoor);
        this.country.setValue(this.data.lg?.country);
        this.lgGender.setValue(this.data.lg?.lgGender);
        this.billingname.setValue(this.data.lg?.billingname);
        this.billingtaxid.setValue(this.data.lg?.billingtaxid);
        this.billingaddress.setValue(this.data.lg?.billingaddress);
        this.billingcity.setValue(this.data.lg?.billingcity);
        this.billingzip.setValue(this.data.lg?.billingzip);
    }
}
