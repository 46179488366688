import { MessageHeader } from './../shared/models/messageHeader';
import { SharedDataService } from './../services/shared-data.service';
import { environment } from './../../environments/environment';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { RedirectService } from '../services/redirect.service';
import { SharedApiService } from '../services/shared-api.service';
import { debounceTime, finalize, tap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { UpdateCalendarRequest } from 'src/app/shared/models/request/updateCalendarRequest';
import { ApiService } from './../core/http/api.service';
import { BaseResponse } from './../shared/models/response/baseResponse';
import { MatTableDataSource } from '@angular/material/table';
import { EhrDocList } from '../shared/models/ehrDocList';
import { PatientEhrRequest } from '../shared/models/request/patientEhrRequest';
import { ToastrService } from 'ngx-toastr';
import { faFilePdf, faInfoCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { EkatEventEntryRequest } from '../shared/models/request/ekatEventEntryRequest';
import { EkatEventEntryResponse } from '../shared/models/response/ekatEventEntryResponse';
import { GetDocumentByEesztIdRequest } from '../shared/models/request/getDocumentByEesztIdRequest';
import { EhrDocumentByEesztIdResponse } from '../shared/models/response/ehrDocumentByEesztIdResponse';
import { EhrEesztIdRequest } from '../shared/models/request/ehrEesztIdRequest';
import { DeleteEkatEesztIdRequest } from '../shared/models/request/deleteEkatEesztIdRequest';
import { MatTabGroup } from '@angular/material/tabs';
import { MatDialog } from '@angular/material/dialog';
import { LegalGuardianDialog } from './legal-guardian-dialog/legal-guardian-dialog';
import { GetPatientReservationRequest } from '../shared/models/request/get-patient-reservation-request';
import { PatientReservationData } from '../shared/models/response/get-patient-reservations-response';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { CheckAuszComponent } from './check-ausz/check-ausz.component';

declare let $: any;

@Component({
  selector: 'app-register-patient',
  templateUrl: './register-patient.component.html',
  styleUrls: ['./register-patient.component.css'],
  /*encapsulation: ViewEncapsulation.None*/
})
export class RegisterPatientComponent implements OnInit, OnDestroy {
  @ViewChild('patientMainTabGroup', { static: false }) patientMainTabGroup: MatTabGroup;

  @ViewChild('paciensreg', { static: true })
  paciensreg: ElementRef;

  faInfoCircle = faInfoCircle;
  faTrash = faTrash;
  faFilePdf = faFilePdf;

  url;

  currentEhrEesztId;

  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: WebcamImage = null;

  getPatientReservationLoading = false;
  showWebcamImage = false;
  showEhrDocument: boolean;
  ehrDocumentLoading: boolean;

  imageDataBg = {};

  ekatEventEntry: EkatEventEntryResponse;
  ekatEventEntryDataSource = [];

  ehrDocument: EhrDocumentByEesztIdResponse;
  ehrDocumentDataSource = [];

  employmentDataPositionSubscription: Subscription;
  employmentDataPosition = [];
  employmentDataPositionLoading = true;

  employmentDataOrganizationSubscription: Subscription;
  employmentDataOrganization = [];
  employmentDataOrganizationLoading = true;

  showEkatInfo: boolean;
  ekatEventEntryLoading: boolean;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId

  public videoOptions: MediaTrackConstraints = {
    width: { ideal: 541 },
    height: { ideal: 721 }
  };

  tajSearchSubscription: Subscription;
  tapass = null;
  apiUrl = environment.apiUrl;
  environment = environment;

  patientDataValue = null;
  isLoading = false;

  registerPatientAndBookAppointment = false;

  medcseppWorkflow = false;

  check4TStatus = false;

  bookingSaveResult = null;
  saveBookingInProgress = false;
  ekatDetailsInProgress = false;
  saveBookingErrorDescription = '';
  scheduleFk = null;
  servicePointFk = null;
  imageData = '';

  regPicType = false;

  legalGuardianDetail: any;

  patientReservationsDataSource: MatTableDataSource<PatientReservationData> = new MatTableDataSource<PatientReservationData>();
  displayedColumns: Array<string> = [
    'dateFrom',
    'dateTo',
    'longDescription',
  ];

  @Input() set realignInkBar(isRealign) {
    if (this.patientMainTabGroup) {
      this.patientMainTabGroup.realignInkBar();
    }
  }

  @Input() set patientData(data) {
    // console.log('setData');

    if (data !== null && (data['calendarFk'] || data['calendarId'])) {
      this.patientDataValue = data;

      this.isLoading = true;
      // console.log('patientDataValue:', this.patientDataValue);

      if (data['registeredPatient']) {
        this.getCalendarDetail(data['calendarFk']).subscribe((responseData) => {
          this.isLoading = false;
          if (responseData['code'] === 1) {
            this.patientName.setValue(responseData['data']['participantTapass']);
            const event = { tapass: responseData['data']['participantTapass'] };
            // console.log('tapass: ', this.tapass);

            this.tapass = responseData['data']['participantTapass'];
            this.onSelectedPatientChange(event);
            this.switchTab(1);
            this.patientMainTabGroup.realignInkBar();
          } else {

            console.log('Error loading patient details calendarId: ' + data['calendarFk']);
            // console.log(responseData);
          }
        });
      } else if (data['participantTAJ']) {
        this.tajSearchSubscription = this.searchUserByTaj(data['participantTAJ']).subscribe(
          (userdata: Response) => {
            if (userdata['code'] === 1) {
              // console.log('getDataByTaj:', data['getDataByTaj']);
              this.patientName.setValue(userdata['getDataByTaj']['usrTapass']);
              const event = { tapass: userdata['getDataByTaj']['usrTapass'] };
              this.tapass = userdata['getDataByTaj']['usrTapass'];
              this.onSelectedPatientChange(event);
              this.switchTab(1);
              this.patientMainTabGroup.realignInkBar();
            } else if (data['code'] === -14 || data['code'] === -25) {
              // couldn't find patient by TAJ
              // console.log('could not find patient by TAJ');
            } else {
              // hiba
            }
          }
        );
      } else {
        this.registerPatientToAliasAppointment = true;
        if (data['calendarFk']) {
          this.medcseppWorkflow = false;
          this.registerPatientAndBookAppointment = true;
        } else if (data['calendarId']) {
          this.medcseppWorkflow = true;
          this.registerPatientAndBookAppointment = true;
        }
        // paciens regisztralasa alias-os foglalashoz
        // console.log('Reg patient to alias appointment!');
      }
    } else if (data !== null && data['scheduleFk']) {
      this.scheduleFk = data['scheduleFk'];
      this.servicePointFk = data['servicePointFk'];
      this.registerPatientAndBookAppointment = true;
      // console.log('registerPatientAndBookAppointment');
    }
  }

  detailsName = '';
  detailsOrganization = '';
  detailsPosition = '';
  detailsRegPicId = null;
  detail = null;
  detailLoading = false;

  formSubmitted = false;
  patientSaveResult = null;
  successMessage = '';
  savePatientErrorDescription = '';
  patientDataLoading = false;
  saveInProgress = false;

  registerPatientToAliasAppointment = false;

  patientListLoading = true;
  patientListDataSubscription: Subscription;
  patientList = [];

  dateChangeSubscription: Subscription;
  dateInvalid = false;

  documents = null;
  documentsLoading = false;
  documentsCount = 0;
  patientDetailsFormVisible = false;

  registrationUserId = null;

  activeTab = 0;

  patientName = new FormControl();

  employmentPosition = new FormControl();
  employmentOrganization = new FormControl();

  patientListForm = new FormGroup({
    'patientName': this.patientName
  });

  check4TResultMessage = '';
  //check4TStatus = false;
  copyDetailToBillingEnabled = false;

  registrationInProgress = false;

  justAssingPatient = false;

  offset = new Date().getTimezoneOffset();

  citiesByZip = [];

  // name = new FormControl('', [Validators.required, Validators.minLength(4)]);
  birthname = new FormControl('', [Validators.required, Validators.minLength(4)]);
  birthplace = new FormControl('', [Validators.required, Validators.minLength(2)]);
  dateofbirth = new FormControl('', [Validators.required]);
  mothersname = new FormControl('', [Validators.required, Validators.minLength(4)]);
  ssn = new FormControl('', Validators.required);
  sst = new FormControl(null, Validators.required);
  sstd = new FormControl('');
  aliasname = new FormControl('', [Validators.required, Validators.minLength(4)]);
  gender = new FormControl('');
  nationality = new FormControl('');
  email = new FormControl('', [Validators.email]);
  phone = new FormControl('', [Validators.required, Validators.minLength(6)]);
  country = new FormControl('');
  zip = new FormControl('');
  city = new FormControl('');
  address = new FormControl('');
  comment = new FormControl('');
  allergy = new FormControl('');
  ekWorkflow = new FormControl('');
  ssn2t = new FormControl('');

  patientEhrList: MatTableDataSource<EhrDocList> = null;
  patientEkatList: MatTableDataSource<any>;
  displayedColumnsEhr: string[] = ['doctorName', 'stampNo', 'timeStamp', 'hospitalDescr', 'diagnose', 'documentID', 'details'];
  displayesColumnsEkat: string[] = ['eventType', 'fromDate', 'toDate', 'institute', 'section', 'doctor', 'details'];
  displayedEkatDetails: string[] = ['ekadId', 'instId', 'institute', 'caseId', 'location', 'ssn', 'diag', 'deleteEkat', 'pdf',];
  displayedEventEntry: string[] = ['ssn', 'dateFrom', 'doctor', 'instUnit', 'assistanceId', 'caseType', 'status', 'deleteEkat'];
  billingname = new FormControl('');
  billingtaxid = new FormControl('');
  billingzip = new FormControl('');
  billingcity = new FormControl('');
  billingaddress = new FormControl('');
  cardNfc = new FormControl('', [this.NFCvalidator]);

  patientDetails = new FormGroup({
    // 'name': this.name,
    'birthname': this.birthname,
    'birthplace': this.birthplace,
    'mothersname': this.mothersname,
    'ssn2t': this.ssn2t,
    'sst': this.sst,
    'ssn': this.ssn,
    'sstd': this.sstd,
    'dateofbirth': this.dateofbirth,
    'aliasname': this.aliasname,
    'gender': this.gender,
    'nationality': this.nationality,
    'email': this.email,
    'phone': this.phone,
    'country': this.country,
    'zip': this.zip,
    'city': this.city,
    'address': this.address,
    'billingname': this.billingname,
    'billingtaxid': this.billingtaxid,
    'billingzip': this.billingzip,
    'billingcity': this.billingcity,
    'billingaddress': this.billingaddress,
    'comment': this.comment,
    'allergy': this.allergy,
    'ekWorkflow': this.ekWorkflow,
  });

  patientEhrDateFrom = new FormControl(null, Validators.required);
  patientEhrDateTo = new FormControl(null, Validators.required);
  ehrBreakGlass = new FormControl(null);
  ehrBreakGlassReason = new FormControl(null);

  patientEkatDateFrom = new FormControl(null, Validators.required);
  patientEkatDateTo = new FormControl(null, Validators.required);
  ekatBreakGlass = new FormControl(null);
  ekatBreakGlassReason = new FormControl(null);

  patientEhrFormGroup = new FormGroup({
    'patientDateFrom': this.patientEhrDateFrom,
    'patientDateTo': this.patientEhrDateTo,
    'breakGlass': this.ehrBreakGlass,
    'breakGlassReason': this.ehrBreakGlassReason
  });

  patientEkatFormGroup = new FormGroup({
    'patientDateFrom': this.patientEkatDateFrom,
    'patientDateTo': this.patientEkatDateTo,
    'breakGlass': this.ekatBreakGlass,
    'breakGlassReason': this.ekatBreakGlassReason
  });

  auszcode = new FormControl('', [Validators.required, Validators.minLength(3)]);
  agreement = new FormControl('', [Validators.required, Validators.pattern('^true')]);
  marketing = new FormControl('');

  regValidation = new FormGroup({
    'auszcode': this.auszcode,
    'agreement': this.agreement,
    'marketing': this.marketing
  });

  cardDetailsGroup = new FormGroup({
    'cardNfc': this.cardNfc
  });

  showDocumentVersions = false;
  ehrDetailsInProgress = false;
  patientDataValid = false;

  now = new Date();
  todayDate = this.sharedDataService.dateStringToNgbDate(this.sharedDataService.dateToDateString(this.now));


  closeConfirmationDialogueVisible = false;

  userData = null;
  is4tEditing = false;

  calendarLoading = true;
  calendarListDataAll = [];
  calendarLength = 0;
  error = '';



  fileInput = new FormControl();
  fileDocType = new FormControl('', Validators.required);
  fileTypeSel = new FormControl(null);
  fileTemplateSel = new FormControl(null, Validators.required);
  fileTagType = new FormControl(null);
  fileTagCode = new FormControl(null);
  fileTagName = new FormControl(null);
  fileTagDescription = new FormControl(null);
  fileTagValue = new FormControl(null);
  fileTagReadOnly = true;
  fileEmailSend = new FormControl('');


  fileUpload = new FormGroup({
    fileInput: this.fileInput,
    fileDocType: this.fileDocType,
    fileTagType: this.fileTagType,
    fileTagCode: this.fileTagCode,
    fileTagName: this.fileTagName,
    fileTagDescription: this.fileTagDescription,
    fileTagValue: this.fileTagValue,
    fileEmailSend: this.fileEmailSend
  });

  patientEproFormGroup = new FormGroup({});

  fileTempData = {
    filename: '',
    filetype: '',
    value: '',
    valid: false
  };

  savedDocuments = [];
  saveResult = null;
  saveErrorDescription = '';
  uploadInProgress = false;

  countries: any[] = [];
  nationalities: any[] = [];

  uploadDocumentTypesSubscription: Subscription;
  uploadDocumentTypes = [];
  uploadDocumentTypesLoading = true;

  patientReservationFromFormControl = new FormControl(new NgbDate(this.todayDate.year, this.todayDate.month, this.todayDate.day), Validators.required);
  patientReservationToFormControl = new FormControl(
    (this.todayDate.year + '-' +
      ('0' + this.todayDate.month).slice(-2) + '-' +
      ('0' + this.todayDate.day).slice(-2)), Validators.required);

  constructor(public authService: AuthService,
    private toastr: ToastrService,
    private http: HttpClient,
    public sharedDataService: SharedDataService,
    private openTab: RedirectService,
    private sharedApiService: SharedApiService,
    private apiService: ApiService,
    protected sanitizer: DomSanitizer,
    private dialog: MatDialog
  ) { }



  Brand = this.authService.getBrand();

  ngOnInit() {
    const params: MessageHeader = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
    };

    this.apiService.getCountries(params).subscribe(
      res => {
        this.countries = res.data;
      }
    );

    this.apiService.getnationalities(params).subscribe(
      res => {
        this.nationalities = res.data;
      }
    );

    this.patientDetails.valueChanges.subscribe(
      val => {
        // console.log('2: ', this.patientDetails);
      });
    const oneMonthEarlier = new Date();
    oneMonthEarlier.setDate(oneMonthEarlier.getDate() - 7);
    this.patientReservationFromFormControl.setValue(this.sharedDataService.dateStringToNgbDate(this.sharedDataService.dateToDateString(oneMonthEarlier)));
    this.patientReservationToFormControl.setValue(this.todayDate);
    this.dataMissing = false;
    this.showLegalGuardianButton = false;
    this.patientReservationToFormControl.valueChanges.pipe(debounceTime(1000)).subscribe(value => {
      this.convertDatefromUserInput(value, this.patientReservationToFormControl);
    });
    this.patientReservationFromFormControl.valueChanges.pipe(debounceTime(1000)).subscribe(value => {
      this.convertDatefromUserInput(value, this.patientReservationFromFormControl);
    });
    this.patientEhrDateFrom.valueChanges.pipe(debounceTime(1000)).subscribe(value => {
      this.convertDatefromUserInput(value, this.patientEhrDateFrom);
    });

    this.patientEhrDateTo.valueChanges.pipe(debounceTime(1000)).subscribe(value => {
      this.convertDatefromUserInput(value, this.patientEhrDateTo);
    });

    this.patientEkatDateFrom.valueChanges.pipe(debounceTime(1000)).subscribe(value => {
      this.convertDatefromUserInput(value, this.patientEkatDateFrom);
    });

    this.patientEkatDateTo.valueChanges.pipe(debounceTime(1000)).subscribe(value => {
      this.convertDatefromUserInput(value, this.patientEkatDateTo);
    });

    this.zip.valueChanges.pipe(debounceTime(500)).subscribe(zipCode => {
      if (zipCode) {
        this.apiService.getCityByZipCode(zipCode).subscribe(zipCodeRes => {
          this.citiesByZip = zipCodeRes['city'];
          if (this.citiesByZip.length === 1) {
            this.city.setValue(this.citiesByZip[0]);
          }
        });
      }
    });
    this.patientEhrFormGroup.controls.breakGlassReason.disable();
    this.patientEhrFormGroup.controls.breakGlass.valueChanges.subscribe(breakGlass => {
      breakGlass ? this.patientEhrFormGroup.controls.breakGlassReason.enable() : this.patientEhrFormGroup.controls.breakGlassReason.disable();
    });
    this.patientEkatFormGroup.controls.breakGlassReason.disable();
    this.patientEkatFormGroup.controls.breakGlass.valueChanges.subscribe(breakGlass => {
      breakGlass ? this.patientEkatFormGroup.controls.breakGlassReason.enable() : this.patientEkatFormGroup.controls.breakGlassReason.disable();
    });
    this.patientEhrFormGroup.controls.patientDateFrom.valueChanges.subscribe(value => {
      if (value) {
        if (new Date() < new Date(this.sharedDataService.NgbDateToDateString(value))) {
          this.toastr.error('nem lehet a mai napnál későbbi dátumot megadni');
          this.patientEhrFormGroup.controls.patientDateFrom.reset();
        }
      }
    });
    this.patientEhrFormGroup.controls.patientDateTo.valueChanges.subscribe(value => {
      if (value) {
        if (new Date() < new Date(this.sharedDataService.NgbDateToDateString(value))) {
          this.toastr.error('nem lehet a mai napnál későbbi dátumot megadni');
          this.patientEhrFormGroup.controls.patientDateTo.reset();
        }
      }
    });
    this.patientEkatFormGroup.controls.patientDateFrom.valueChanges.subscribe(value => {
      if (value) {
        if (new Date() < new Date(this.sharedDataService.NgbDateToDateString(value))) {
          this.toastr.error('nem lehet a mai napnál későbbi dátumot megadni');
          this.patientEkatFormGroup.controls.patientDateFrom.reset();
        }
      }
    });
    this.patientEkatFormGroup.controls.patientDateTo.valueChanges.subscribe(value => {
      if (value) {
        if (new Date() < new Date(this.sharedDataService.NgbDateToDateString(value))) {
          this.toastr.error('nem lehet a mai napnál későbbi dátumot megadni');
          this.patientEkatFormGroup.controls.patientDateTo.reset();
        }
      }
    });

    this.patientEhrList = null;
    this.toggleFormDetails(false, false);

    if (this.Brand === 'DOLGEK') {
      this.sharedDataService.loadEmploymentData('medicalPosition');
      this.sharedDataService.loadEmploymentData('medicalOrganization');

      this.employmentDataPositionSubscription = this.sharedDataService.employmentDataPositionShared.subscribe(data => {
        this.employmentDataPositionLoading = false;
        this.employmentDataPosition = data;
        // console.log ('employmentData', this.employmentData);
      });

      this.employmentDataOrganizationSubscription = this.sharedDataService.employmentDataOrganizationShared.subscribe(data => {
        this.employmentDataOrganizationLoading = false;
        this.employmentDataOrganization = data;
        // console.log ('employmentData', this.employmentData);
      });
    }

    this.uploadDocumentTypesSubscription = this.sharedDataService.documentTypesShared.subscribe(value => {
      this.uploadDocumentTypesLoading = false;
      this.uploadDocumentTypes = value;
      this.findRegPicType();
    });

    this.patientListDataSubscription = this.sharedDataService.patientListShared.subscribe((patientList) => {
      this.patientList = [];
      // console.log ('register patient - patient list updated', patientList);
      this.patientListLoading = false;
      this.patientList = patientList;

    });

    this.dateChangeSubscription = this.dateofbirth.valueChanges.pipe(
      debounceTime(500)
    ).subscribe(value => {
      if (value !== '' && value !== null) {
        if (value.year !== undefined) {
          this.dateInvalid = false;
        } else {

          const dateValue = value.replace(/\./g, '-');
          const dateObject = this.sharedDataService.dateFromUserinput(dateValue);

          if (dateObject && !isNaN(dateObject.getTime())) {
            const newDate = this.sharedDataService.dateToDateString(dateObject);
            this.dateofbirth.setValue(this.sharedDataService.dateStringToNgbDate(newDate));
            this.dateInvalid = false;
          } else {
            this.dateInvalid = true;
          }
        }
      }
    });

    this.sst.valueChanges.subscribe(value => {
      if (value) {
        if (value === '1') {
          // console.log('tajvalidator add');
          this.ssn.setValidators(this.TAJvalidator);
        } else {
          // console.log('tajvalidator remove');
          this.ssn.clearValidators();
          this.ssn.setValidators([Validators.required]);
        }
      } else {
        // console.log('tajvalidator remove');
        this.ssn.clearValidators();
        this.ssn.setValidators([Validators.required]);
      }
    });


    if (this.Brand === 'NauLight') {
      this.email.setValidators([Validators.email, Validators.required]);
    }
  }

  convertDatefromUserInput(value, formControl: FormControl) {
    if (value && value !== '') {
      if (value.year === undefined) {
        const dateValue = value.replace(/\./g, '-');
        const dateObject = this.sharedDataService.dateFromUserinput(dateValue);
        if (dateObject && !isNaN(dateObject.getTime())) {
          const newDate = this.sharedDataService.dateToDateString(dateObject);
          formControl.setValue(this.sharedDataService.dateStringToNgbDate(newDate));
        }
      }
    }
  }

  ngOnDestroy() {
    this.patientListDataSubscription.unsubscribe();
    this.dateChangeSubscription.unsubscribe();
    if (this.employmentDataOrganizationSubscription) {
      this.employmentDataOrganizationSubscription.unsubscribe();
    }
    if (this.employmentDataPositionSubscription) {
      this.employmentDataPositionSubscription.unsubscribe();
    }
  }

  getCalendarDetail(calendarId: string) {

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calendarId: calendarId
    };

    return this.http.post<any[]>(this.apiUrl + '/rest/neu/calendarlist/getcalendardetail', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getPatientEhrDocumentList() {
    if (this.patientEhrFormGroup.controls.breakGlass.value && !this.patientEhrFormGroup.controls.breakGlassReason.value) {
      this.toastr.error('sürgősségi adatlekérés esetén az indoklás megadása kötelező!');
    } else {
      const params: PatientEhrRequest = {
        messageHeader: {
          currentTapass: this.authService.getUserData()['ekCardNo'],
          currentRole: this.sharedDataService.selectedRole['roleCode'],
          currentServicePointId: this.sharedDataService.selectedRole['spId'],
          locale: 'hu',
          deviceId: this.authService.getUUID()
        },
        breakGlass: this.patientEhrFormGroup.controls.breakGlass.value,
        breakGlassOk: this.patientEhrFormGroup.controls.breakGlassReason.value,
        fromDate: this.sharedDataService.NgbDateToDateString(this.patientEhrFormGroup.controls.patientDateFrom.value),
        toDate: this.sharedDataService.NgbDateToDateString(this.patientEhrFormGroup.controls.patientDateTo.value),
        patientTapass: this.patientListForm.controls.patientName.value
      };
      this.ehrDetailsInProgress = true;
      this.apiService.getEhrDocumentList(params).subscribe(res => {
        if (res.code > 0) {
          this.patientEhrFormGroup.enable();
          this.ehrDetailsInProgress = false;
          console.log('res.message : ', res.message, ' res.message.includes:', res.message.includes('DOR-1017'));
          if (res.message.includes('DOR-1017')) {
            this.toastr.error(res.message);
          } else {
            this.patientEhrList = new MatTableDataSource(res.data.documentList);
          }
        } else {
          this.toastr.error(res.message);
        }

      }, err => {
        this.patientEhrFormGroup.enable();
        this.ehrDetailsInProgress = false;
        this.toastr.error(err.error.message);
      });
    }
  }

  getPatientEkatDocumentList() {
    if (this.patientEkatFormGroup.controls.breakGlass.value && !this.patientEkatFormGroup.controls.breakGlassReason.value) {
      this.toastr.error('sürgősségi adatlekérés esetén az indoklás megadása kötelező!');
    } else {
      const params: PatientEhrRequest = {
        messageHeader: {
          currentTapass: this.authService.getUserData()['ekCardNo'],
          currentRole: this.sharedDataService.selectedRole['roleCode'],
          currentServicePointId: this.sharedDataService.selectedRole['spId'],
          locale: 'hu',
          deviceId: this.authService.getUUID()
        },
        breakGlass: this.patientEkatFormGroup.controls.breakGlass.value,
        breakGlassOk: this.patientEkatFormGroup.controls.breakGlassReason.value,
        fromDate: this.sharedDataService.NgbDateToDateString(this.patientEkatFormGroup.controls.patientDateFrom.value),
        toDate: this.sharedDataService.NgbDateToDateString(this.patientEkatFormGroup.controls.patientDateTo.value),
        patientTapass: this.patientListForm.controls.patientName.value
      };
      this.ekatDetailsInProgress = true;
      this.apiService.getEkatDocumentList(params).subscribe(res => {
        this.patientEkatFormGroup.enable();
        this.ekatDetailsInProgress = false;
        if (res.code > 0) {
          if (res.message.includes('DOR-1017')) {
            this.toastr.error(res.message);
          } else {
            if (res.ekatEntries.errorMessages?.messages.length > 0) {
              res.ekatEntries.errorMessages.messages.forEach(message => {
                this.toastr.error(message.code, message.message)
              });
            }
            this.patientEkatList = new MatTableDataSource(res.ekatEntries.businessMessageContent.eventEntity);
            console.log('res: ', res);
          }
        } else {
          this.toastr.error(res.message);
        }

      }, err => {
        this.ekatDetailsInProgress = false;
        this.toastr.error(err.error.message);
      });
    }
  }

  showEhrDetails(element) {
    this.currentEhrEesztId = element.eesztid;
    const eesztId: GetDocumentByEesztIdRequest = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      breakGlass: this.patientEhrFormGroup.controls.breakGlass.value,
      eesztId: element.eesztid
    };
    this.ehrDocumentLoading = true;
    this.apiService.getEhrDocumentByEesztId(eesztId).subscribe(res => {
      this.patientEhrFormGroup.disable();
      this.ehrDocumentLoading = false;
      this.showEhrDocument = true;
      this.ehrDocument = res;
      this.ehrDocumentDataSource = [];
      this.ehrDocumentDataSource.push(res);
    });
  }

  downloadEhrPdf() {
    const linkSource = 'data:application/pdf;base64,' + this.ehrDocument.document.data.document;
    const downloadLink = document.createElement("a");
    const fileName = "ehrDocument.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  toggleFormDetails(state: boolean, ekWorkflowEnabled: boolean) {
    if (state === true) {
      this.ssn.enable();
      this.sst.enable();
      this.sstd.enable();
      this.aliasname.enable();
      this.gender.enable();
      this.nationality.enable();
      this.email.enable();
      this.phone.enable();
      this.country.enable();
      this.zip.enable();
      this.city.enable();
      this.address.enable();
      this.billingname.enable();
      this.billingtaxid.enable();
      this.billingzip.enable();
      this.billingcity.enable();
      this.billingaddress.enable();
      this.comment.enable();
      this.allergy.enable();
      this.copyDetailToBillingEnabled = true;
      if (ekWorkflowEnabled === true) {
        this.ekWorkflow.enable();
      }
    } else {
      this.ssn.disable();
      this.sst.disable();
      this.sstd.disable();
      this.aliasname.disable();
      this.gender.disable();
      this.nationality.disable();
      this.email.disable();
      this.phone.disable();
      this.country.disable();
      this.zip.disable();
      this.city.disable();
      this.address.disable();
      this.billingname.disable();
      this.billingtaxid.disable();
      this.billingzip.disable();
      this.billingcity.disable();
      this.billingaddress.disable();
      this.comment.disable();
      this.allergy.disable();
      this.ekWorkflow.disable();
      this.copyDetailToBillingEnabled = false;
    }

  }

  savePatient($event, saveType) {

    const value = this.dateofbirth.value;
    const birthdate = new Date(value.year, value.month - 1, value.day);
    const ageDifMs = Date.now() - birthdate.getTime();
    const ageDate = new Date(ageDifMs);
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);

    if ((age < 16 && (this.legalGuardianDetail && !this.legalGuardianDetail?.invalid)) || age >= 16) {
      this.formSubmitted = true;
      const spDetails = this.sharedDataService.getSelectedRole();
      this.saveInProgress = true;

      if (this.userData == null) {
        this.getTapass().subscribe((tapassData: Response) => {
          if (tapassData['code'] === 1) {
            const patientDetails = this.patientDetails.value;
            patientDetails['newTapass'] = tapassData['tapass'];
            console.log(patientDetails);
            this.userRegistration(spDetails, patientDetails).subscribe(
              (data: Response) => {

                if (this.authService.getBrand() === 'MEDCSEPP' && this.registerPatientToAliasAppointment &&
                  this.medcseppWorkflow === true) {
                  this.saveInProgress = true;
                } else {

                  this.saveInProgress = false;
                }

                if (data['code'] > 0) {
                  this.legalGuardianDetail = null;
                  this.sharedDataService.loadPatientList();
                  this.patientSaveResult = 0;
                  this.successMessage = 'Sikeres regisztráció!';
                  setTimeout(() => { this.patientSaveResult = null; }, 3000);
                  this.registrationUserId = data['code'];
                  if (this.authService.getBrand() !== 'UDMED' && this.authService.getBrand() !== 'DOLGEK' && this.authService.getBrand() !== 'MEDCSEPP') {
                    this.registrationInProgress = true;
                    this.getRegForm(this.registrationUserId);
                  } else if (this.authService.getBrand() === 'DOLGEK') {
                    let tapassLocal = tapassData['tapass'];
                    this.resetRegistrationModal();
                    this.sharedDataService.loadPatientList();
                    this.patientName.setValue(tapassLocal);
                    this.loadPatientDetails(tapassLocal);
                    this.tapass = tapassLocal;
                  } else if (this.authService.getBrand() === 'MEDCSEPP') {
                    this.getRegForm(this.registrationUserId);
                  }
                  if (this.authService.getBrand() === 'UDMED' || this.authService.getBrand() === 'NAUCARE' || this.authService.getBrand() === 'GONDOZAS') {
                    if (this.registerPatientToAliasAppointment) {
                      this.updateCalendar(this.registrationUserId).subscribe(response => {
                        if (response['code'] === 1) {
                          this.patientSaveResult = 0;
                          this.successMessage = 'Páciens sikeresen hozzárendelve az időponthoz!';
                          setTimeout(() => { this.patientSaveResult = null; }, 3000);
                          this.sharedDataService.refeshCalendar(this.patientDataValue['servicePointFk']);
                        } else {
                          this.patientSaveResult = -1;
                          this.savePatientErrorDescription = data['message'] + ' (' + data['code'] + ')';
                        }
                      });
                    }
                    if (this.registerPatientAndBookAppointment && (saveType === 2 || saveType === 3)) {
                      this.bookingSaveResult = null;
                      this.saveBookingInProgress = true;
                      const socialSecurityNumber = this.patientDetails.value.ssn ? this.patientDetails.value.ssn : '';
                      const socialSecurityType = this.patientDetails.value.sst ? this.patientDetails.value.sst : '';
                      this.saveSchedule(tapassData['tapass'], socialSecurityType, socialSecurityNumber).subscribe(
                        (scheduleData: Response) => {
                          if (scheduleData['code'] > 0) {
                            this.sharedDataService.refeshCalendar(this.servicePointFk);
                          }
                        }
                      );
                    }
                    let tapassLocal = tapassData['tapass'];
                    this.patientName.setValue(tapassLocal);
                    this.loadPatientDetails(tapassLocal);
                    this.tapass = tapassLocal;
                  }

                  if (this.authService.getBrand() === 'MEDCSEPP' && this.registerPatientToAliasAppointment &&
                    this.medcseppWorkflow === true) {
                    this.saveInProgress = true;
                    const userData = [];
                    userData['userId'] = this.registrationUserId;
                    userData['name'] = this.patientDetails.value.aliasname;
                    this.updateCalendarMedcsepp(this.patientDataValue, userData).subscribe(response => {
                      this.saveInProgress = false;
                      this.registrationInProgress = true;
                      if (response['code'] === 1) {
                        this.patientSaveResult = 0;
                        this.successMessage = 'Páciens sikeresen hozzárendelve az időponthoz!';
                        setTimeout(() => { this.patientSaveResult = null; }, 3000);
                        this.sharedDataService.refeshCalendar(this.patientDataValue['servicePointFk']);
                      } else {
                        console.log('Hiba az időpont módosítása során:', response);
                        this.patientSaveResult = -1;
                        this.savePatientErrorDescription = data['message'] + ' (' + data['code'] + ')';
                      }
                    });


                  }

                } else {
                  console.log('21');
                  console.log('Hiba a regisztráció során: ');
                  this.patientSaveResult = -1;
                  this.savePatientErrorDescription = data['message'] + ' (' + data['code'] + ')';
                }
                // console.log(data);
              });

          } else {
            console.log('Hiba az új azonosító lekérése során');
            this.patientSaveResult = -1;
            this.savePatientErrorDescription = tapassData['message'] + ' (' + tapassData['code'] + ')';
          }
        });


      } else { // paciens update

        // console.log('savePatient 3');
        this.userUpdate(spDetails, this.patientDetails.value).subscribe(
          (data: Response) => {

            if (!this.registerPatientToAliasAppointment || !this.justAssingPatient) {
              this.saveInProgress = false;
            }

            if (data['code'] > 0) {
              this.legalGuardianDetail = null;
              // console.log('update userData', this.userData);
              if (this.registerPatientAndBookAppointment && saveType == 3) {
                this.disable4Tediting();
                this.bookingSaveResult = null;
                this.saveBookingInProgress = true;
                const socialSecurityNumber = this.patientDetails.value.ssn ? this.patientDetails.value.ssn : '';
                const socialSecurityType = this.patientDetails.value.sst ? this.patientDetails.value.sst : '';
                this.saveSchedule(this.tapass, socialSecurityType, socialSecurityNumber).subscribe(
                  (scheduleData: Response) => {
                    if (scheduleData['code'] > 0) {
                      this.sharedDataService.refeshCalendar(this.servicePointFk);
                      // console.log('scheduleData', scheduleData);
                    }
                  }
                );

                // console.log('save appointment start');

              }


              this.sharedDataService.loadPatientList();
              this.patientDetails.markAsPristine();
              this.patientSaveResult = 0;
              this.successMessage = 'Adatok sikersen módosítva!';
              setTimeout(() => { this.patientSaveResult = null; }, 3000);
              // console.log('reg data');
              // console.log(data);

              // console.log ('tapass: ',this.tapass);
              // this.loadPatientDetails(this.tapass);
              // console.log ('userData', this.userData);

              // if (this.authService.getBrand() !== 'DOLGEK' && this.authService.getBrand() !== 'UDMED') {
              //   this.showValidationModal(2);
              // }

              // if (this.userData['usrValid'] !== '1') { // nem validalt paciens validalasa


              this.patientSaveResult = null;
              this.registrationUserId = this.userData['userId'];

              if (this.authService.getBrand() !== 'UDMED' && this.authService.getBrand() !== 'DOLGEK'
                && this.authService.getBrand() !== 'MEDCSEPP') {
                this.saveInProgress = false;
                if (this.is4tEditing && this.birthname.enabled && this.mothersname.enabled &&  this.dateofbirth.enabled && this.birthplace.enabled) {
                  this.registrationInProgress = true;
                  this.getRegForm(this.userData['userId']);
                }
                this.disable4Tediting();
              } else if (this.authService.getBrand() === 'DOLGEK') {
                this.disable4Tediting();
                this.saveInProgress = false;
                const tapassLocal = this.tapass;
                this.resetRegistrationModal();
                this.sharedDataService.loadPatientList();
                this.patientName.setValue(tapassLocal);
                this.loadPatientDetails(tapassLocal);
                this.tapass = tapassLocal;
              }
              // }

              this.disable4Tediting();
              if (this.registerPatientToAliasAppointment && this.justAssingPatient) {
                if (this.medcseppWorkflow === false) {
                  this.updateCalendar(this.userData['userId']).subscribe(response => {
                    if (response['code'] === 1) {
                      this.patientSaveResult = 0;
                      this.successMessage = 'Páciens sikeresen hozzárendelve az időponthoz!';
                      setTimeout(() => { this.patientSaveResult = null; }, 3000);
                      this.saveInProgress = false;
                      this.registrationInProgress = true;
                      this.sharedDataService.refeshCalendar(this.patientDataValue['servicePointFk']);
                    } else {
                      console.log('Hiba az időpont módosítása során:', response);
                      this.patientSaveResult = -1;
                      this.savePatientErrorDescription = data['message'] + ' (' + data['code'] + ')';
                    }
                  });
                } else {
                  this.updateCalendarMedcsepp(this.patientDataValue, this.userData).subscribe(response => {
                    this.saveInProgress = false;
                    this.registrationInProgress = true;
                    if (response['code'] === 1) {
                      this.patientSaveResult = 0;
                      this.successMessage = 'Páciens sikeresen hozzárendelve az időponthoz!';
                      setTimeout(() => { this.patientSaveResult = null; }, 3000);
                      this.sharedDataService.refeshCalendar(this.patientDataValue['servicePointFk']);
                    } else {
                      console.log('Hiba az időpont módosítása során:', response);
                      this.patientSaveResult = -1;
                      this.savePatientErrorDescription = data['message'] + ' (' + data['code'] + ')';
                    }
                  });
                }

              } else if (this.registerPatientToAliasAppointment && !this.justAssingPatient && this.medcseppWorkflow === true) {
                this.updateCalendarMedcsepp(this.patientDataValue, this.userData).subscribe(response => {
                  this.saveInProgress = false;
                  this.registrationInProgress = true;
                  if (response['code'] === 1) {
                    this.patientSaveResult = 0;
                    this.successMessage = 'Páciens sikeresen hozzárendelve az időponthoz!';
                    setTimeout(() => { this.patientSaveResult = null; }, 3000);
                    this.sharedDataService.refeshCalendar(this.patientDataValue['servicePointFk']);
                  } else {
                    console.log('Hiba az időpont módosítása során:', response);
                    this.patientSaveResult = -1;
                    this.savePatientErrorDescription = data['message'] + ' (' + data['code'] + ')';
                  }
                });


              }


            } else {
              // console.log('savePatient 4');
              // console.log('Hiba a páciens adatok mentése során: ');
              this.patientSaveResult = -1;
              this.savePatientErrorDescription = data['message'] + ' (' + data['code'] + ')';
            }
            // console.log(data);
          });
      }
    } else {
      this.dataMissing = true;
      this.savePatientErrorDescription = '16 év alatti páciens számára kötelező a törvényes képviselő megadása!';
      setTimeout(
        () => { this.savePatientErrorDescription = ''; this.dataMissing = false; }, 3000);

    }
  }


  showValidationModal(type) {
    if (type === 1) {
      this.registrationInProgress = true;
      // console.log ('reg uid', this.registrationUserId);
      // console.log ('userData', this.userData);
      this.registrationUserId = this.userData['userId'];
    } else if (type === 2) {
      this.registrationUserId = this.userData['userId'];
      this.registrationInProgress = true;
      this.getRegForm(this.registrationUserId);
    }
  }



  validatePatient($event) {
    this.showLegalGuardianButton = false;
    this.legalGuardianDetail = null;

    this.saveInProgress = true;
    // console.log('validation started');

    this.validateUser().subscribe(
      (data: Response) => {
        // console.log('validation response received');
        this.saveInProgress = false;
        if (data['code'] > 0) {
          this.registrationInProgress = false;
          // console.log('validation code > 0');

          this.patientSaveResult = 0;
          this.successMessage = 'Páciens sikeresen validálva!';
          setTimeout(() => { this.patientSaveResult = null; }, 3000);
          this.sharedDataService.loadPatientList();

          if (this.registerPatientToAliasAppointment) {
            // console.log('validation data for patient to alias appointment');
            // console.log(data);
            this.patientSaveResult = 0;
            this.successMessage = 'Páciens sikeresen validálva!';
            setTimeout(() => { this.patientSaveResult = null; }, 3000);

            // Update appointment with newly registered user data
            if (this.authService.getBrand() !== 'UDMED' && this.authService.getBrand() !== 'MEDCSEPP') {
              this.updateCalendar(this.registrationUserId).subscribe(response => {
                if (response['code'] === 1) {
                  // console.log('validation updated calendar success');
                  this.patientSaveResult = 0;
                  this.successMessage = 'Páciens sikeresen hozzárendelve az időponthoz!';
                  setTimeout(() => { this.patientSaveResult = null; }, 3000);
                  this.sharedDataService.refeshCalendar(this.patientDataValue['servicePointFk']);
                  this.resetRegistrationModal();
                } else {
                  console.log('validation Hiba az időpont módosítása során:', response);
                  this.patientSaveResult = -1;
                  this.savePatientErrorDescription = response['message'] + ' (' + response['code'] + ')';
                }
              });
            } else {
              this.resetRegistrationModal();
            }
          } else {
            this.resetRegistrationModal();
            this.patientSaveResult = 0;
            this.successMessage = 'Páciens sikeresen validálva!';
            setTimeout(() => { this.patientSaveResult = null; }, 3000);
          }


        } else {
          console.log('Hiba a regisztráció véglegesítése során: ', data);
          this.patientSaveResult = -1;
          this.savePatientErrorDescription = data['message'] + ' (' + data['code'] + ')';
        }
        // console.log('validation data: ', data);
      });
  }

  validatePatientSimple($event) {

    this.saveInProgress = true;
    // console.log('validation started');

    this.validateUser().subscribe(
      (data: Response) => {
        // console.log('validation response received');
        this.saveInProgress = false;
        if (data['code'] > 0) {
          this.registrationInProgress = false;
          // console.log('validation code > 0');

          this.patientSaveResult = 0;
          this.successMessage = 'Páciens sikeresen validálva!';
          setTimeout(() => { this.patientSaveResult = null; }, 3000);
          this.sharedDataService.loadPatientList();

          this.resetRegistrationModal();
          this.patientSaveResult = 0;
          this.successMessage = 'Páciens sikeresen validálva!';
          setTimeout(() => { this.patientSaveResult = null; }, 3000);

        } else {
          console.log('Hiba a regisztráció véglegesítése során: ', data);
          this.patientSaveResult = -1;
          this.savePatientErrorDescription = data['message'] + ' (' + data['code'] + ')';
        }
        // console.log('validation data: ', data);
      });
  }

  validateUser() {

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      patientUserId: this.registrationUserId,
      auszcode: this.auszcode.value,
      marketingBoolean: this.marketing.value === true ? true : false,
      agreementBoolean: this.agreement.value === true ? true : false

    };

    return this.http.post(this.apiUrl + '/rest/neu/authentication/validateuser', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  disable4Tediting() {
    this.birthname.disable();
    this.dateofbirth.disable();
    this.birthplace.disable();
    this.mothersname.disable();
    this.is4tEditing = false;
  }

  enable4Tediting() {
    this.is4tEditing = true;
    this.birthname.enable();
    this.dateofbirth.enable();
    this.birthplace.enable();
    this.mothersname.enable();
  }

  userUpdate(spDetails: any, patientData: any) {

    let legalGuardian = null;

    if (this.legalGuardianDetail) {
      legalGuardian = {
        name: this.legalGuardianDetail.value.aliasname,
        birthName: this.legalGuardianDetail.value.birthname,
        gender: this.legalGuardianDetail.value.lgGender,
        dateOfBirth: this.sharedDataService.NgbDateToDateString(this.legalGuardianDetail.value.dateofbirth),
        placeOfBirth: this.legalGuardianDetail.value.birthplace,
        mothersName: this.legalGuardianDetail.value.mothersname,
        country: this.legalGuardianDetail.value.country,
        zipCode: this.legalGuardianDetail.value.zip,
        settlement: this.legalGuardianDetail.value.city,
        street: this.legalGuardianDetail.value.street,
        phone: this.legalGuardianDetail.value.phone,
        houseNumber: this.legalGuardianDetail.value.houseno,
        floorDoor: this.legalGuardianDetail.value.floordoor,
        invoiceName: this.legalGuardianDetail.value.billingname,
        invoiceTaxNumber: this.legalGuardianDetail.value.billingtaxid,
        invoiceZipcode: this.legalGuardianDetail.value.billingzip,
        invoiceSettlement: this.legalGuardianDetail.value.billingcity,
        invoiceStreetWithDetails: this.legalGuardianDetail.value.billingaddress,
      }
    }

    let params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      userId: this.userData['userId'].toString(),
      name: patientData.aliasname ? patientData.aliasname : '',
      birthName:  this.is4tEditing && this.birthname.enabled ? patientData.birthname : this.userData['birthName'],
      mothersName:  this.is4tEditing && this.mothersname.enabled ? patientData.mothersname : this.userData['mothersName'],
      dateOfBirth:  this.is4tEditing && this.dateofbirth.enabled ? this.sharedDataService.NgbDateToDateString(patientData.dateofbirth) : this.userData['dateOfBirth'],
      placeOfBirth: this.is4tEditing && this.birthplace.enabled ? patientData.birthplace : this.userData['placeOfBirth'],
      gender: patientData.gender ? patientData.gender : '',
      citizen3digit: patientData.nationality ? patientData.nationality : '',
      country3Digit: patientData.country ? patientData.country : '',
      zipcode: patientData.zip ? patientData.zip : '',
      email: patientData.email ? patientData.email : '',
      socialSecurityNumber: patientData.ssn ? patientData.ssn : '',
      socialSecurityType: patientData.sst ? patientData.sst : '',
      socialSecurityTypeDescription: patientData.sstd ? patientData.sstd : '',
      phoneNumber: patientData.phone ? patientData.phone : '',
      spCode: spDetails['spCode'],
      spId: this.sharedDataService.selectedRole['spId'],
      settlement: patientData.city ? patientData.city : '',
      streetWithDetails: patientData.address ? patientData.address : '',
      comment: patientData.comment ? patientData.comment : '',
      drugIntolerance: patientData.allergy ? patientData.allergy : '',
      invoiceName: patientData.billingname ? patientData.billingname : '',
      invoiceTaxNumber: patientData.billingtaxid ? patientData.billingtaxid : '',
      invoiceZipcode: patientData.billingzip ? patientData.billingzip : '',
      invoiceSettlement: patientData.billingcity ? patientData.billingcity : '',
      invoiceStreetWithDetails: patientData.billingaddress ? patientData.billingaddress : '',
      isEkWorkflow: patientData.ekWorkflow ? patientData.ekWorkflow : '',
      legalGuardian
    };

    if (this.cardDetailsGroup.value.cardNfc !== '') {
      params['cardNfc'] = this.cardDetailsGroup.value.cardNfc;
      params['tapass'] = this.tapass;
    }

    // return this.http.post('http://localhost:8090' + '/rest/neu/authentication/userupdate', params, {
    return this.http.post(this.apiUrl + '/rest/neu/authentication/userupdate', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }
  updateCalendar(userId) {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calendarId: this.patientDataValue['calendarFk'],
      patientId: userId,
      status: this.patientDataValue['calendarStatus']
    };

    return this.http.post(this.apiUrl + '/rest/neu/calendarlist/updatecalendar', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  updateCalendarMedcsepp($event, patientData) {
    console.log('event:', $event);
    console.log('userData', patientData);

    const currentStatus = $event.nauStatus;
    // let status: string;

    // if (currentStatus === 'DH') {
    //   status = 'DN';
    // } else if (currentStatus === 'DN') {
    //   status = 'DD';
    // }

    // console.log('getMessageHeader', this.generalService.getMessageHeader());
    const request: UpdateCalendarRequest = {
      calModReasonCode: null,
      calendarId: $event.calendarId,
      callNumber: $event.callNumber,
      deviceId: null,
      diagnosisComment: null,
      doctorId: null,
      patientId: patientData['userId'],
      status: currentStatus,
      isCalDetailUpdateEnabled: true,
      longDescription: patientData['name'],
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      }
    };

    // Update the current calendar
    return this.http.post<BaseResponse>(this.environment.callerSystemUrl + 'rest/updatecalendar', request, {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json' }
      )
    });

  }


  dataMissing = false;

  getTapass() {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      spCode: this.sharedDataService.selectedRole['spId']
    };
    return this.http.post(this.apiUrl + '/rest/neu/authentication/gettapass', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  userRegistration(spDetails: any, patientData: any) {
    let legalGuardian = null;
    let tapass = '';
    if (patientData['newTapass']) {
      tapass = patientData['newTapass'];
    }

    if (this.legalGuardianDetail && !this.legalGuardianDetail.invalid) {
      legalGuardian = {
        name: this.legalGuardianDetail.value.aliasname,
        birthName: this.legalGuardianDetail.value.birthname,
        gender: this.legalGuardianDetail.value.lgGender,
        dateOfBirth: this.sharedDataService.NgbDateToDateString(this.legalGuardianDetail.value.dateofbirth),
        placeOfBirth: this.legalGuardianDetail.value.birthplace,
        mothersName: this.legalGuardianDetail.value.mothersname,
        country: this.legalGuardianDetail.value.country,
        zipCode: this.legalGuardianDetail.value.zip,
        settlement: this.legalGuardianDetail.value.city,
        street: this.legalGuardianDetail.value.street,
        phone: this.legalGuardianDetail.value.phone,
        houseNumber: this.legalGuardianDetail.value.houseno,
        floorDoor: this.legalGuardianDetail.value.floordoor,
        invoiceName: this.legalGuardianDetail.value.billingname,
        invoiceTaxNumber: this.legalGuardianDetail.value.billingtaxid,
        invoiceZipcode: this.legalGuardianDetail.value.billingzip,
        invoiceSettlement: this.legalGuardianDetail.value.billingcity,
        invoiceStreetWithDetails: this.legalGuardianDetail.value.billingaddress,
      }
    }


    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      userId: '',
      ekUserName: '',
      name: patientData.aliasname ? patientData.aliasname : '',
      birthName: patientData.birthname ? patientData.birthname : '',
      mothersName: patientData.mothersname ? patientData.mothersname : '',
      gender: patientData.gender ? patientData.gender : '',
      citizen3digit: patientData.nationality ? patientData.nationality : '',
      placeOfBirth: patientData.birthplace ? patientData.birthplace : '',
      dateOfBirth: patientData.dateofbirth ? patientData.dateofbirth.year + '-' +
        patientData.dateofbirth.month + '-' + patientData.dateofbirth.day : '',
      // countryName: patientData.country ? patientData.country : '',
      country3Digit: patientData.country ? patientData.country : '',
      zipcode: patientData.zip ? patientData.zip : '',
      email: patientData.email ? patientData.email : '',
      socialSecurityNumber: patientData.ssn ? patientData.ssn : '',
      socialSecurityType: patientData.sst > 0 || patientData.sst === 'X' ? patientData.sst : '',
      socialSecurityTypeDescription: patientData.sstd ? patientData.sstd : '',
      phoneNumber: patientData.phone ? patientData.phone : '',
      tapass: tapass,
      spCode: spDetails['spCode'], // '7A9BA3A9ACD343B1E05011AC0D092706',
      spId: this.sharedDataService.selectedRole['spId'],
      settlement: patientData.city ? patientData.city : '',
      streetWithDetails: patientData.address ? patientData.address : '',
      comment: patientData.comment ? patientData.comment : '',
      drugIntolerance: patientData.allergy ? patientData.allergy : '',
      invoiceName: patientData.billingname ? patientData.billingname : this.legalGuardianDetail?.value?.billingname,
      invoiceTaxNumber: patientData.billingtaxid ? patientData.billingtaxid : this.legalGuardianDetail?.value?.billingtaxid,
      invoiceZipcode: patientData.billingzip ? patientData.billingzip : this.legalGuardianDetail?.value?.billingzip,
      invoiceSettlement: patientData.billingcity ? patientData.billingcity : this.legalGuardianDetail?.value?.billingcity,
      invoiceStreetWithDetails: patientData.billingaddress ? patientData.billingaddress : this.legalGuardianDetail?.value?.billingcity,
      isEkWorkflow: patientData.ekWorkflow ? patientData.ekWorkflow : '',
      legalGuardian
      // isEkWorkflow	boolean
      // xml: OrderedMap { "name": "isEkWorkFlow" }
      // default: false
      // Munkafolyamat specifikus adatok (BLC, BJK stb.) tárolását vezérli az új userre vonatkozóan.
      // True: Nem tárolja, ragaszkodik az ek wf-hez. False: Tárolja
    };

    // return this.http.post('http://localhost:8090'  + '/rest/neu/authentication/userregistration', params, {
    return this.http.post(this.apiUrl + '/rest/neu/authentication/userregistration', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }


  switchTab(tabID) {
    this.patientEkatFormGroup.reset();
    this.patientEhrFormGroup.reset();
    if (!this.registrationInProgress) {
      this.activeTab = tabID;
    }
  }

  onSelectedPatientChange($event) {
    this.check4TStatus = true;
    this.legalGuardianDetail = null;
    this.is4tEditing = false;

    if ($event && $event['tapass'].length !== 0) {
      this.tapass = $event['tapass'];
      this.loadPatientDetails($event['tapass']);
      this.loadDocuments($event['tapass']);
      this.getPreviousAppointments($event['tapass']);
      this.loadUserDetail('EMPLOYMENT', $event['tapass']);
    } else {
      this.documents = null;
      this.resetRegistrationModal2();
      this.calendarLoading = true;
      this.calendarListDataAll = [];
    }
  }

  loadDocuments(tapass) {
    this.webcamImage = null;
    this.showWebcamImage = false;
    this.imageData = '';
    this.imageDataBg = {};
    this.documents = null;
    this.documentsLoading = true;
    let documents = [];
    this.getDocuments(tapass).subscribe(
      (data: Response) => {
        this.documentsLoading = false;
        if (data['code'] === 1) {
          // console.log(data['data']);
          if (data['data'].length > 0) {

            data['data'].forEach(entry => {
              entry['docDate'] = this.sharedDataService.DateStringConvert(entry['docDate']);
              if (entry['docFormat'] !== 'HTML' && entry['docFormat'] !== 'XML') {
                documents.push(entry);
              }
            });

            documents.sort(this.sortDocuments);
            let regPicId = null;
            documents.forEach(doc => {
              if (doc['docTypeCode'] === 'REG_PIC_JPG' && !regPicId) {
                regPicId = doc['docId'];
              }
            });
            if (regPicId) {
              this.detailsRegPicId = regPicId;
              this.loadRegPic(this.detailsRegPicId);
            } else {
              this.imageData = '';
              this.imageDataBg = {};
            }
          }
          this.documents = documents;
          this.documentsCount = this.documents.length;
        } else {
          // hiba
        }
      }
    );
  }

  loadRegPic(documentID) {

    this.getDocument(documentID).subscribe(
      (data: Response) => {
        if (data['code'] == 1) {
          this.imageData = "data:image/jpeg;base64, " + data['document'];
          this.imageDataBg = { 'background-image': "url('data:image/png;base64, " + data['document'] + "')" };
          // console.log ('imageDataBg', this.imageDataBg);
        } else {
          this.imageData = '';
          this.imageDataBg = {};
          // error
        }
        // console.log(data);
      }
    );
  }


  getDocument(documentID) {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      docId: documentID
    };

    return this.http.post(this.apiUrl + '/rest/neu/docinv/getdocumentjson', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }
  sortDocuments(a, b) {
    if (a['docDate'] > b['docDate']) {
      return -1;
    } else if (a['docDate'] < b['docDate']) {
      return 1;
    } else {
      if (a['docId'] > b['docId']) {
        return -1;
      } else {
        return 1;
      }
      return 0;
    }
  }


  getDocuments(tapass) {

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      requestedUserTapass: tapass,
      dateFrom: null,
      dateTo: null,
      docTypes: null,
      fromIndex: null,
      toIndex: null
    };

    return this.http.post(this.apiUrl + '/rest/neu/docinv/getdocuments', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }

  getRegForm(userId: string) {

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      userId: userId

    };
    this.openTab.post(params, this.apiUrl + '/rest/neu/authentication/getuserdisclaimer/');

  }

  loadPatientDetails(tapass) {
    // console.log ('loadPatient Start');
    this.patientDetailsFormVisible = true;
    this.patientDataLoading = true;
    this.userData = null;
    this.is4tEditing = false;
    this.sharedApiService.getUserSimple(tapass).subscribe(
      (userData: Response) => {
        this.toggleFormDetails(true, false);
        this.patientDataLoading = false;
        if (userData['code'] === 1) {
          this.disable4Tediting();
          this.is4tEditing = true;
          if (this.registerPatientToAliasAppointment) {
            this.justAssingPatient = true;
          }
          // console.log ('loadPatient', userData);
          this.setUserData(userData);
        } else {
          // hiba
        }
      }
    );
    // console.log ('loadPatient End');
  }

  openCheckAuszDialog() {
    const dialogRef = this.dialog.open(CheckAuszComponent);
    dialogRef.componentInstance.isAuszCodeValidEmitter.pipe(
      tap((isAuszCodeValid: boolean) => {
        if (isAuszCodeValid) {
          dialogRef.close();
          this.enable4Tediting();
        }
      })
    ).subscribe();
  }

  setUserData(userData) {

    // console.log('userdata', userData);
    this.check4TResultMessage = '';
    //this.check4TStatus = true;

    userData['user']['birthForename2'] = !userData['user']['birthForename2'] ? '' : userData['user']['birthForename2'];

    userData['user']['birthForename2'] = userData['user']['birthForename2'] !== '' ? ' ' +
      userData['user']['birthForename2'] : userData['user']['birthForename2'];

    userData['user']['birthForename'] = !userData['user']['birthForename'] ? '' : ' ' + userData['user']['birthForename'];

    userData['user']['birthForename'] = userData['user']['birthForename'] !== '' ? ' ' +
      userData['user']['birthForename'] : userData['user']['birthForename'];

    userData['user']['birthName'] = userData['user']['birthSurname'] + userData['user']['birthForename'] +
      userData['user']['birthForename2'];

    userData['user']['forename'] = userData['user']['forename'] ? ' ' + userData['user']['forename'] : '';
    userData['user']['forename2'] = userData['user']['forename2'] ? ' ' + userData['user']['forename2'] : '';

    userData['user']['name'] = userData['user']['surname'] + userData['user']['forename'] + userData['user']['forename2'];

    userData['user']['street'] = userData['user']['street'] ? userData['user']['street'].trim() : '';
    userData['user']['streetType'] = userData['user']['streetType'] ? userData['user']['streetType'] : '';
    userData['user']['houseNumber'] = userData['user']['houseNumber'] ? userData['user']['houseNumber'] : '';
    userData['user']['zipCode'] = userData['user']['zipCode'] ? userData['user']['zipCode'] : '';
    userData['user']['email'] = userData['user']['email'] ? userData['user']['email'] : '';
    userData['user']['phoneNumber'] = userData['user']['phoneNumber'] ? userData['user']['phoneNumber'] : '';

    this.detailsName = userData['user']['name'];

    this.aliasname.setValue(userData['user']['name']);
    this.birthname.setValue(userData['user']['birthName']);

    this.dateofbirth.setValue(userData['user']['dateOfBirth']);
    this.birthplace.setValue(userData['user']['placeOfBirth']);
    this.ssn.setValue(userData['user']['socialSecurityNumber'] ? userData['user']['socialSecurityNumber'] : '');
    this.sst.setValue(userData['user']['socialSecurityTypeCode'] ? userData['user']['socialSecurityTypeCode'] : '');
    this.sstd.setValue(userData['user']['socialSecurityTypeDescription'] ? userData['user']['socialSecurityTypeDescription'] : '');
    this.address.setValue(userData['user']['street'] + ' ' + userData['user']['streetType'] + ' ' + userData['user']['houseNumber']);
    this.zip.setValue(userData['user']['zipCode']);
    this.gender.setValue(userData['user']['gender']);
    this.email.setValue(userData['user']['email']);
    this.phone.setValue(userData['user']['phoneNumber']);
    this.nationality.setValue(userData['user']['citizen3Digit']);
    this.mothersname.setValue(userData['user']['mothersName']);
    this.city.setValue(userData['user']['settlement']);
    this.country.setValue(userData['user']['country3Digit']);

    // console.log('userData', userData);

    if (userData['user']['id'] !== undefined) {
      userData['user']['userId'] = userData['user']['id'];
    }

    this.billingname.setValue(userData['user']['invoiceName']);
    this.billingtaxid.setValue(userData['user']['invoiceTaxNumber']);
    this.billingzip.setValue(userData['user']['invoiceZipcode']);
    this.billingcity.setValue(userData['user']['invoiceSettlement']);
    this.billingaddress.setValue(userData['user']['invoiceStreetWithDetails']);

    this.comment.setValue(userData['user']['comment']);
    this.allergy.setValue(userData['user']['drugIntolerance']);

    this.userData = userData['user'];

    if (userData.user.legalGuardian) {
      console.log('van');

      const value = {
        aliasname: userData.user.legalGuardian?.name,
        birthname: userData.user.legalGuardian?.birthName,
        lgGender: userData.user.legalGuardian?.gender,
        dateofbirth: userData.user.legalGuardian?.dateOfBirth,
        birthplace: userData.user.legalGuardian?.placeOfBirth,
        mothersname: userData.user.legalGuardian?.mothersName,
        country: userData.user.legalGuardian?.country,
        zip: userData.user.legalGuardian?.zipCode,
        city: userData.user.legalGuardian?.settlement,
        street: userData.user.legalGuardian?.street,
        phone: userData.user.legalGuardian?.phone,
        houseno: userData.user.legalGuardian?.houseNumber,
        floordoor: userData.user.legalGuardian?.floorDoor,
        billingname: userData.user.legalGuardian?.invoiceName,
        billingtaxid: userData.user.legalGuardian?.invoiceTaxNumber,
        billingzip: userData.user.legalGuardian?.invoiceZipcode,
        billingcity: userData.user.legalGuardian?.invoiceSettlement,
        billingaddress: userData.user.legalGuardian?.invoiceStreetWithDetails
      };

      this.legalGuardianDetail = {
        value
      };
    } else {
      this.legalGuardianDetail = null;
    }

  }

  showLegalGuardianButton = false;

  openLegalGuardian() {
    const value = this.dateofbirth.value;
    const birthdate = new Date(value.year, value.month - 1, value.day);
    var ageDifMs = Date.now() - birthdate.getTime();
    var ageDate = new Date(ageDifMs);
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);

    if (age < 16) {
      this.showLegalGuardianButton = true;
      this.openDialog();
    } else {
      this.showLegalGuardianButton = false;
    }
  }

  openDialog() {
    $(this.paciensreg.nativeElement).modal("hide");
    const dialogRef = this.dialog.open(LegalGuardianDialog,
      {
        disableClose: true,
        data: { lg: this.legalGuardianDetail?.value, update: this.userData ? true : false }
      });

    dialogRef.afterClosed().subscribe(result => {
      $(this.paciensreg.nativeElement).modal('show');

      if (result) {
        this.legalGuardianDetail = result;
        this.billingname.setValue(this.legalGuardianDetail.value.billingname);
        this.billingzip.setValue(this.legalGuardianDetail.value.billingzip);
        this.billingaddress.setValue(this.legalGuardianDetail.value.billingaddress);
        this.billingtaxid.setValue(this.legalGuardianDetail.value.billingtaxid);
        this.billingcity.setValue(this.legalGuardianDetail.value.billingcity);
      }

    });
  }


  checkUserBy4T() {

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      birthName: this.birthname.value,
      dateOfBirth: this.dateofbirth.value.year + '-' + this.dateofbirth.value.month + '-' + this.dateofbirth.value.day,
      placeOfBirth: this.birthplace.value,
      mothersName: this.mothersname.value
    };

    return this.http.post(this.apiUrl + '/rest/neu/authentication/checkuserby4t', params, {
      // return this.http.post('http://localhost:8090' + '/rest/neu/authentication/checkuserby4t', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }


  checkUserBy2T() {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      ssnType: '1',
      ssnNumber: this.ssn2t.value,
      dateOfBirth: this.dateofbirth.value.year + '-' + this.dateofbirth.value.month + '-' + this.dateofbirth.value.day,
    };

    return this.http.post(this.apiUrl + '/rest/neu/authentication/checkuserby2t', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  changeIn4T() {
    this.toggleFormDetails(false, false);
    this.check4TResultMessage = '';
    //this.check4TStatus = false;
  }

  check4T($event) {
    this.toggleFormDetails(false, false);
    this.userData = null;
    this.checkUserBy4T().subscribe(
      (userData: Response) => {
        this.patientDetailsFormVisible = true;
        this.check4TResultMessage = userData['message'];
        if (userData['code'] === 1) { // existing patient reg found
          this.check4TStatus = true;
          if (this.registerPatientToAliasAppointment) {
            this.justAssingPatient = true;
          }
          this.toggleFormDetails(true, false);

          const dateOfBirth = new Date(this.sharedDataService.DateStringConvert(userData['user']['dateOfBirth']));

          userData['user']['dateOfBirth'] = this.sharedDataService.dateToDateString(dateOfBirth);

          // console.log('userData[user][dateOfBirth]: ', userData['user']['dateOfBirth']);

          this.setUserData(userData);
          this.disable4Tediting();
          this.is4tEditing = true;
        } else if (userData['code'] === -16) {

          this.check4TStatus = true;
          this.openLegalGuardian();
          this.toggleFormDetails(true, true);
          //this.check4TStatus = true;
          this.aliasname.setValue(this.birthname.value);
          this.phone.setValue('+36');
          this.check4TResultMessage = 'Nem létező felhasználó, kérjük töltse ki az adatokat!';
        } else if (userData['code'] === -25) {
          this.check4TStatus = false;
          this.check4TResultMessage = 'Érvénytelen paraméterek, kérjük javítsa az adatokat, majd próbálja meg újra!';
        }
      }
    );
  }


  check2T($event) {
    // console.log('patientDataValue: ', this.patientDataValue);
    // console.log('check user by 4t started');
    this.toggleFormDetails(false, false);
    this.userData = null;
    this.checkUserBy2T().subscribe(
      (userData: Response) => {
        this.patientDetailsFormVisible = true;
        // console.log('check user by 4t response arrived');
        // console.log(userData);
        this.check4TResultMessage = userData['message'];
        if (userData['code'] === 1) { // existing patient reg found
          if (this.registerPatientToAliasAppointment) {
            this.justAssingPatient = true;
          }
          this.toggleFormDetails(true, false);
          this.tapass = userData['user']['ekCardNo'];

          const dateOfBirth = new Date(this.sharedDataService.DateStringConvert(userData['user']['dateOfBirth']));

          userData['user']['dateOfBirth'] = this.sharedDataService.dateToDateString(dateOfBirth);

          // console.log('userData[user][dateOfBirth]: ', userData['user']['dateOfBirth']);

          this.setUserData(userData);
          this.check4TStatus = true;
          this.disable4Tediting();
          this.is4tEditing = true;
        } else if (userData['code'] === -16) {
          this.toggleFormDetails(true, true);
          //this.check4TStatus = true;
          this.aliasname.setValue(this.birthname.value);
          this.phone.setValue('+36');
          this.check4TResultMessage = 'Nem létező felhasználó, kérjük töltse ki az adatokat!';
        } else if (userData['code'] === -25) {
          this.check4TResultMessage = 'Érvénytelen paraméterek, kérjük javítsa az adatokat, majd próbálja meg újra!';
        }
      }
    );
  }

  setPatientData(data: any, type: string) {
    data['NGdestinationService'] = type;
    this.sharedDataService.setPatientData(data);
  }

  closeRegistrationModal() {
    this.registrationInProgress = false;
    // console.log('dialogue visible');
    this.userData = null;
    this.showLegalGuardianButton = false;
    this.patientReservationsDataSource = null;
    this.legalGuardianDetail = null;
    if (this.patientDetails.dirty === true) {
      this.closeConfirmationDialogueVisible = true;
      this.setPatientData({}, 'registerPatient');
    }
  }

  hideCloseConfirmationDialogue() {
    this.closeConfirmationDialogueVisible = false;
    // console.log(this.patientDetails);
  }

  resetRegistrationModal() {
    this.patientReservationsDataSource.data = [];
    this.patientEhrFormGroup.enable();
    this.patientEkatFormGroup.enable();
    this.patientEhrList = null;
    this.showEhrDocument = false;
    this.ehrDocumentLoading = false;
    this.ehrDetailsInProgress = false;
    this.ehrDocument = null;
    this.ekatDetailsInProgress = false;
    this.patientEkatList = null;
    this.showEkatInfo = false;
    this.ekatEventEntryLoading = false;
    this.ekatEventEntry = null;
    this.cardNfc.reset();
    this.employmentOrganization.reset();
    this.employmentPosition.reset();
    this.webcamImage = null;
    this.showWebcamImage = false;
    this.imageData = '';
    this.imageDataBg = {};
    this.detailsOrganization = '';
    this.detailsPosition = '';
    this.tapass = '';
    this.detailsName = '';
    this.userData = null;
    this.toggleFormDetails(false, false);
    this.check4TResultMessage = '';
    this.patientDetails.reset();
    this.patientName.setValue('');
    this.documents = null;
    this.regValidation.reset();
    this.registrationInProgress = false;
    this.formSubmitted = false;
    this.activeTab = 0;
    this.closeResult();
    this.enable4Tediting();
    this.setPatientData({}, 'registerPatient');
    this.justAssingPatient = false;
    this.registerPatientToAliasAppointment = false;
    this.scheduleFk = null;
    this.servicePointFk = null;
    this.registerPatientAndBookAppointment = null;
    this.webcamImage = null;
    this.patientEkatFormGroup.reset();
    this.patientEhrFormGroup.reset();
    this.is4tEditing = false;
  }

  resetRegistrationModal2() {
    this.cardNfc.reset();
    this.employmentOrganization.reset();
    this.employmentPosition.reset();
    this.webcamImage = null;
    this.showWebcamImage = false;
    this.imageData = '';
    this.imageDataBg = {};
    this.detailsOrganization = '';
    this.detailsPosition = '';
    this.tapass = '';
    this.detailsName = '';
    this.userData = null;
    this.toggleFormDetails(false, false);
    this.check4TResultMessage = '';
    this.patientDetails.reset();
    this.patientName.setValue('');
    this.documents = null;
    this.regValidation.reset();
    this.registrationInProgress = false;
    this.formSubmitted = false;
    this.activeTab = 0;
    this.closeResult();
    this.enable4Tediting();
    this.setPatientData({}, 'registerPatient');
    this.scheduleFk = null;
    this.servicePointFk = null;
    this.is4tEditing = false;
  }

  resetRegistrationModalAndHideDialogue() {
    this.hideCloseConfirmationDialogue();
    this.resetRegistrationModal();

  }

  closeResult() {
    this.patientSaveResult = null;
    this.check4TStatus = false;
    this.is4tEditing = false;
    this.savePatientErrorDescription = '';
  }


  openDocument(documentEntry: {}) {
    // console.log('documentEntry', documentEntry);

    if (documentEntry['docFormat'] === 'PDF'
      || documentEntry['docFormat'] === 'JPG'
      || documentEntry['docFormat'] === 'PNG') {
      const documentId = documentEntry['docId'];

      const params = {
        messageHeader: {
          currentTapass: this.authService.getUserData()['ekCardNo'],
          currentRole: this.sharedDataService.selectedRole['roleCode'],
          currentServicePointId: this.sharedDataService.selectedRole['spId'],
          locale: 'hu',
          deviceId: this.authService.getUUID()
        },
        docId: documentId

      };
      this.openTab.post(params, this.apiUrl + '/rest/neu/docinv/getdocument/');
    } else if (documentEntry['docFormat'] === 'TXT') {
      this.getDocumentJSON(documentEntry).subscribe(document => {
        // console.log('document', document);
        // console.log('document data', atob(document['document']));
        this.openTxtDoc(`<head>
        <meta charset="UTF-8">
      </head>` + '<pre>' + atob(document['document']) + '</pre>');
      });
      console.log('documentEntry', documentEntry);
    }
  }

  copyDetailToBilling() {
    this.billingname.setValue(this.aliasname.value);
    this.billingzip.setValue(this.zip.value);
    this.billingcity.setValue(this.city.value);
    this.billingaddress.setValue(this.address.value);
  }

  openHelp(anchor) {
    const newWindow = window.open('https://' + this.authService.getHostname() + '/kezikonyv/' + anchor);
  }

  openCard() {
    let tapassString = '';

    if (this.tapass && this.tapass.length > 11) {
      tapassString = this.tapass.substring(0, 4) + ' ' + this.tapass.substring(4, 8) + ' ' + this.tapass.substring(8, 12);
    }

    const newWindow = window.open('');
    newWindow.document.open();

    if (this.sharedDataService.selectedRole['roleCode'] === 'AUTH$SZOLNOKMAV_IAUSZ' ||
      this.sharedDataService.selectedRole['roleCode'] === 'AUTH$SZOLNOKMAC_IAUSZ'
    ) {


      newWindow.document.write(`
<style>
* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  }

.card-container {
  width: 860px;
  height: 545px;
}

.card-details-name {
  position: absolute;
  margin-top: 30px;
  margin-left: 234px;
  color: black;
  font-size: 35px;
  width: 600px;
  text-align:right
}
.card-details-position {
  position: absolute;
  margin-top: 440px;
  margin-left: 24px;
  color: white;
  font-size: 24px;
}

.card-details-number {
  position: absolute;
  margin-top: 82px;
  margin-left: 641px;
  color: black;
  font-size: 26px;
}

</style>
<link href="https://fonts.googleapis.com/css2?family=Roboto:wght@500;700;900&display=swap" rel="stylesheet">

  <title>` + this.detailsName + `</title>
    <div class="card-container">
    <div class="card-photo">
      <div style="background-image: ` + this.imageDataBg['background-image'] + `" class='camPicture'> </div>
    </div>
    <div class="card-details-name">` + this.detailsName + `</div>
    <div class="card-details-position">
    ` + this.detailsOrganization + `
      <br/>
      ` + this.detailsPosition + `
    </div>
    <div class="card-details-number">
      ` + tapassString + `
    </div>
  </div>
</div>`);


    } else if (this.sharedDataService.selectedRole['roleCode'] === 'AUTH$VESZPREM_IAUSZ') {




      newWindow.document.write(`
<style>
* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  }

.card-container {
  background-image: url("/assets/veszprem_kartya_bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 860px;
  height: 545px;
}

.card-photo {
  margin-top: 75px;
  margin-left: 85px;
  width: 200px;
  height: 265px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
}

.card-details-name {
  position: absolute;
  margin-top: 390px;
  margin-left: 24px;
  color: white;
  font-size: 42px;
}
.card-details-position {
  position: absolute;
  margin-top: 440px;
  margin-left: 24px;
  color: white;
  font-size: 24px;
}

.card-details-number {
  position: absolute;
  margin-top: 485px;
  margin-left: 610px;
  color: #23a04f;
  font-size: 24px;
}
.camPicture {
  width: 200px;
  height: 265px;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
<link href="https://fonts.googleapis.com/css2?family=Roboto:wght@500;700;900&display=swap" rel="stylesheet">

  <title>` + this.detailsName + `</title>
    <div class="card-container">
    <div class="card-photo">
      <div style="background-image: ` + this.imageDataBg['background-image'] + `" class='camPicture'> </div>
    </div>
    <div class="card-details-name">` + this.detailsName + `</div>
    <div class="card-details-position">
    ` + this.detailsOrganization + `
      <br/>
      ` + this.detailsPosition + `
    </div>
    <div class="card-details-number">
      ` + tapassString + `
    </div>
  </div>
</div>`);

    } else if (this.sharedDataService.selectedRole['roleCode'] === 'AUTH$SZOLNOK_IAUSZ') {


      newWindow.document.write(`
    <style>
    * {
      margin: 0;
      padding: 0;
      font-family: 'Roboto', sans-serif;
      }

    .card-container {
      background-image: url("/assets/ek_szolnok_hetenyi_web.jpg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 860px;
      height: 545px;
    }

    .card-details-name {
      position: absolute;
      margin-top: 95px;
      margin-left: 13px;
      color: white;
      font-size: 35px;
      width: 600px;
      text-align:left
    }
    .card-details-position {
      position: absolute;
      margin-top: 132px;
      margin-left: 13px;
      color: white;
      font-size: 20px;
    }

    .card-details-number {
      position: absolute;
      margin-top: 40px;
      margin-left: 641px;
      color: white;
      font-size: 26px;
    }

    </style>
    <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@500;700;900&display=swap" rel="stylesheet">

      <title>` + this.detailsName + `</title>
        <div class="card-container">
        <div class="card-photo">
          <div style="background-image: ` + this.imageDataBg['background-image'] + `" class='camPicture'> </div>
        </div>
        <div class="card-details-name">` + this.detailsName + `</div>
        <div class="card-details-position">
        ` + this.detailsOrganization + `
          <br/>
          ` + this.detailsPosition + `
        </div>
        <div class="card-details-number">
          ` + tapassString + `
        </div>
      </div>
    </div>`);


    } else if (this.sharedDataService.selectedRole['roleCode'] === 'AUTH$MAGY_ADMIN') {

      newWindow.document.write(`
<style>
* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  }

.card-container {
  background-image: url("/assets/magy-kartya-web.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 860px;
  height: 545px;
}

.card-photo {
  margin-top: 75px;
  margin-left: 85px;
  width: 200px;
  height: 265px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
}

.card-details-name {
  position: absolute;
  margin-top: 439px;
  margin-left: 30px;
  color: white;
  font-size: 40px;
}

.card-details-number {
  position: absolute;
  margin-top: 485px;
  margin-left: 30px;
  color: white;
  font-size: 30px;
}
.camPicture {
  width: 200px;
  height: 265px;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
<link href="https://fonts.googleapis.com/css2?family=Roboto:wght@500;700;900&display=swap" rel="stylesheet">

  <title>` + this.detailsName + `</title>
    <div class="card-container">
    <div class="card-details-name">` + this.detailsName + `</div>
    <div class="card-details-number">
      ` + tapassString + `
    </div>
  </div>
</div>`);

    }

    newWindow.print();
    newWindow.document.close();
  }


  openTxtDoc(content: string) {
    const newWindow = window.open('');
    newWindow.document.open();
    newWindow.document.write(content);
    newWindow.document.close();
  }

  toggleShowDocumentVersions() {
    this.showDocumentVersions = !this.showDocumentVersions;
  }

  loadUserDetail(type, tapass) {
    this.detail = null;
    this.detailLoading = true;
    this.detailsOrganization = '';
    this.detailsPosition = '';
    this.getUserDetails(type, tapass).subscribe(
      (data: Response) => {
        this.detailLoading = false;
        if (data['userDetail']) {
          if (data['userDetail']['employment'] && data['userDetail']['employment']['institute'] &&
            data['userDetail']['employment']['institute']['medicalOrganization'] &&
            data['userDetail']['employment']['institute']['medicalPosition']) {
            console.log('empdata', data['userDetail']['employment']['institute']);
            this.detailsOrganization = data['userDetail']['employment']['institute']['medicalOrganization'];
            this.detailsPosition = data['userDetail']['employment']['institute']['medicalPosition'];

            this.employmentPosition.setValue(this.detailsPosition);
            this.employmentOrganization.setValue(this.detailsOrganization);
          }
        } else {

          // hiba
        }
      }
    );
  }


  getUserDetails(type, tapass) {

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      tapass: tapass,
      detailCategories: [
        type
      ]
    };

    return this.http.post(this.apiUrl + '/rest/neu/authentication/getuserdetail', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }

  saveUserDetail(tapass) {
    this.uploadInProgress = true;

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      tapass: tapass,
      userDetail: {
        employment: {
          institute: {
            code: this.sharedDataService.getRootDetail()['rootCode'],
            medicalPosition: this.employmentPosition.value,
            medicalOrganization: this.employmentOrganization.value
          }
        }
      }
    };

    return this.http.post(this.apiUrl + '/rest/neu/authentication/saveuserdetail', params, {
      //return this.http.post('http://localhost:8090' +  + '/rest/neu/authentication/saveuserdetail', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }


  getDocumentJSON(documentEntry: {}) {
    const documentId = documentEntry['docId'];

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      docId: documentId

    };

    return this.http.post(this.apiUrl + '/rest/neu/docinv/getdocumentjson/', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }


  getPreviousAppointments(tapass) {

    // console.log ('appointments component getBookingData');
    // this.error = '';

    this.getAppointmentList(tapass).subscribe(
      (calendarData: Response) => {

        this.calendarLoading = false;
        if (calendarData['code'] === 1) {
          // console.log ('appointments calendarData arrived', calendarData);


          this.calendarLoading = false;
          // console.log('Calendar:');
          // console.log(calendarData);
          this.calendarListDataAll = calendarData['objAppointmentList'];
          this.calendarLength = 0;

          if (this.calendarListDataAll.length > 0) {
            // console.log('calendarListDataAll.length >0 - PROCESSING');

            this.calendarListDataAll.forEach(entry => {

              entry['dateTo'] = this.sharedDataService.DateStringConvert(entry['dateTo']);
              entry['dateFrom'] = this.sharedDataService.DateStringConvert(entry['dateFrom']);
              entry['slotLength'] = ((entry['dateTo'] - entry['dateFrom']) / 900000);

              if (entry['participantName']) {

                if (entry['participantName'].indexOf('#') > -1) {
                  entry['participantUserID'] = entry['participantName'].substring(entry['participantName'].indexOf('#') + 1);
                  entry['participantName'] = (entry['participantName'].substring(0, entry['participantName'].indexOf('#'))).trim();
                  entry['registeredPatient'] = true;
                  // console.log('registered patient');
                }
                entry['displayData'] = (entry['participantName']).trim();
                if (entry['calendarComment'] && entry['calendarComment'] !== '') {
                  if (entry['displayData'] !== '') {
                    entry['displayData'] += ', ' + entry['calendarComment'];
                  } else {
                    entry['displayData'] = entry['calendarComment'];
                  }
                }

              }
            });
            this.calendarLength = this.calendarListDataAll.length;
          }

        } else {
          // hiba
          this.calendarLength = 0;
          this.error = calendarData['code'] + ' - ' + calendarData['message'];
        }
      },
      error => {
        this.calendarLoading = false;
        this.calendarLength = 0;
        this.error = 'Hiba a kiszolgálóval való kommunikáció során...';
        // hiba;
      }
    );
  }



  getAppointmentList(tapass) {

    this.calendarLoading = true;

    const today = new Date();
    const todayString = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    const oneYear = new Date(today.getTime() - (60 * 60 * 1000 * 24 * 365));
    const oneYearString = oneYear.getFullYear() + '-' + (oneYear.getMonth() + 1) + '-' + oneYear.getDate();

    const params = {
      messageHeader: {
        currentTapass: tapass,
        currentRole: 'AUTH$PATIENT',
        currentServicePointId: null,
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      servicePointId: null,
      eventId: null,
      doctorId: null,
      dateFrom: oneYearString,
      dateTo: todayString,
      mode: 2,
      status: 'D'
    };


    return this.http.post(this.apiUrl + '/rest/neu/appointment/getappointmentlist', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }


  searchUserByTaj(taj) {

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      taj: taj,
      wsUrl: null
    };

    return this.http.post(this.apiUrl + '/rest/neu/authentication/searchuserbytaj', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }



  TAJvalidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value !== undefined && control.value !== null) {
      let value = control.value.replace(/ /g, '');
      value = value.replace(/-/g, '');
      if (value.length !== 9 || value === '000000000') {
        return { validTAJ: false };
      } else {
        let sum = 0;
        for (let i = 7; i > -1; i--) {
          sum += ((i % 2 === 0) ? value[i] * 3 : value[i] * 7);
        }
        if (Number(value[8]) === sum % 10) {
          return null;
          // console.log('1');
        }
      }
    }
    // console.log('2');
    return { validTAJ: false };
  }



  saveSchedule(tapass, ssnType, ssnNumber) {

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      patientTapass: tapass,
      providerUserId: '',
      ssnType: ssnType,
      ssnNumber: ssnNumber,
      scheduleFkList: this.scheduleFk,
      patientAlias: null
    };

    return this.http.post(this.apiUrl + '/rest/neu/appointment/saveschedule', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  // Dokumentum feltöltés




  onFileSubmit() {
    // console.log('onFileSubmit');
    // console.log('fileUpload', this.fileUpload);
    // console.log('fileInput', this.fileInput.value);

    // console.log(this.fileTempData.filetype);

    if (this.fileTempData.value !== '' && (this.fileTempData.filetype === 'application/pdf' || this.fileTempData.filetype === 'image/jpeg'
      || this.fileTempData.filetype === 'image/png')) {

      this.uploadInProgress = true;

      this.savePdf().subscribe(response => {

        this.uploadInProgress = false;

        if (response['code'] > 0) {

          this.loadDocuments(this.tapass);

          // console.log ('upload successful', response);
          setTimeout(() => { this.saveResult = null; }, 3000);
          this.saveResult = 0;
          this.saveErrorDescription = '';

          this.fileUpload.reset();
          this.fileTempData = {
            filename: '',
            filetype: '',
            value: '',
            valid: false
          };

        } else {
          console.error('upload failed', response);
          this.saveResult = -1;
          this.saveErrorDescription = response['code'] + ' - ' + response['message'];
        }

      },
        error => {
          this.uploadInProgress = false;
          console.error('upload http error', error);
          this.saveResult = -1;
          this.saveErrorDescription = 'Hiba a kiszolgálóval való kommunikáció során...';
        }
      );

    }

  }



  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (file.type === 'application/pdf' || 'image/jpeg' || 'image/png') {
          this.fileTempData = {
            filename: file.name,
            filetype: file.type,
            value: (<string>reader.result).split(',')[1],
            valid: true
          };
        } else {
          this.fileTempData = {
            filename: '',
            filetype: '',
            value: '',
            valid: false
          };
        }
      };
    }
  }

  saveCardDetails() {
    this.saveInProgress = true;
    const spDetails = this.sharedDataService.getSelectedRole();
    this.userUpdate(spDetails, this.patientDetails.value).subscribe(
      (data: Response) => {
        this.saveInProgress = false;
        if (data['code'] > 0) {
          this.sharedDataService.loadPatientList();
          this.patientDetails.markAsPristine();
          this.patientSaveResult = 0;
          this.successMessage = 'Adatok sikersen módosítva!';
          setTimeout(() => { this.patientSaveResult = null; }, 3000);
        } else {
          this.patientSaveResult = -1;
          this.savePatientErrorDescription = data['message'] + ' (' + data['code'] + ')';
        }
      });
  }


  savePdf() {

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calId: '',
      docType: this.fileDocType.value,
      pdfInBytes: this.fileTempData['value'],
      patientTapass: this.tapass
    };

    return this.http.post(this.apiUrl + '/rest/neu/docinv/savepdf', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }


  NFCvalidator(control: AbstractControl): { [key: string]: boolean } | null {
    const regexp = /^[0-9a-fA-F]+$/;
    if (control.value !== undefined && control.value !== null) {
      const value = control.value;
      if (value.length !== 8 || regexp.test(value) === false) {
        return { validNFC: false };
      } else {
        return (null);
      }
    }
    return { validNFC: false };
  }

  public handleInitError(error: WebcamInitError): void {
    if (error.mediaStreamError && error.mediaStreamError.name === 'NotAllowedError') {
      console.warn('Camera access was not allowed by user!');
    }
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public handleImage(webcamImage: WebcamImage): void {
    // console.log('received webcam image', webcamImage);
    this.webcamImage = webcamImage;
    this.showWebcamImage = true;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  backToCamera() {
    this.showWebcamImage = false;
  }

  findRegPicType() {
    let regPicType = this.uploadDocumentTypes.find(item => item['code'] === 'REG_PIC_JPG');
    // console.log ('regPicType', regPicType);
    if (regPicType) {
      this.regPicType = regPicType;
    }
  }


  onSaveRegPic() {
    // console.log('onFileSubmit');
    // console.log('fileUpload', this.fileUpload);
    // console.log('fileInput', this.fileInput.value);
    // console.log(this.fileTempData.filetype);

    if (this.webcamImage) {

      this.uploadInProgress = true;

      this.saveRegPic().subscribe(response => {

        this.uploadInProgress = false;

        if (response['code'] > 0) {

          this.loadDocuments(this.tapass);

          // console.log ('upload successful', response);
          setTimeout(() => { this.saveResult = null; }, 3000);
          this.saveResult = 0;
          this.saveErrorDescription = '';

          this.fileUpload.reset();
          this.fileTempData = {
            filename: '',
            filetype: '',
            value: '',
            valid: false
          };

        } else {
          console.error('upload failed', response);
          this.saveResult = -1;
          this.saveErrorDescription = response['code'] + ' - ' + response['message'];
        }

      },
        error => {
          this.uploadInProgress = false;
          console.error('upload http error', error);
          this.saveResult = -1;
          this.saveErrorDescription = 'Hiba a kiszolgálóval való kommunikáció során...';
        }
      );
    }
  }

  saveRegPic() {

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calId: '',
      docType: this.regPicType['code'],
      pdfInBytes: this.webcamImage.imageAsBase64,
      patientTapass: this.tapass
    };

    return this.http.post(this.apiUrl + '/rest/neu/docinv/savepdf', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }

  _base64ToArrayBuffer(base64) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  logDetails() {
    console.log('userData', this.authService.getUserData());
    console.log('selectedRole', this.sharedDataService.selectedRole);
  }

  saveEmploymentDetails() {
    this.saveInProgress = true;
    this.saveUserDetail(this.tapass).subscribe(response => {

      this.saveInProgress = false;

      if (response['code'] > 0) {
        this.loadUserDetail('EMPLOYMENT', this.tapass);
        // console.log ('upload successful', response);
        setTimeout(() => { this.saveResult = null; }, 3000);
        this.saveResult = 0;
        this.saveErrorDescription = '';

      } else {
        console.error('employment detail save failed', response);
        this.saveResult = -1;
        this.saveErrorDescription = response['code'] + ' - ' + response['message'];
      }

    },
      error => {
        this.uploadInProgress = false;
        console.error('upload http error', error);
        this.saveResult = -1;
        this.saveErrorDescription = 'Hiba a kiszolgálóval való kommunikáció során...';
      }
    );
  }

  showEkatDetails(element) {
    const ekatRequestParams: EkatEventEntryRequest = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      callerTransactionId: '1',
      eesztId: element.businessObjectIdentification.eesztid,
      ellatasId: element.businessContent.ellatasId,
      surgos: this.patientEkatFormGroup.controls.breakGlass.value,
      integrationId: element.businessObjectIdentification.integrationId,
      tokenStr: null
    };
    this.ekatEventEntryLoading = true;
    this.apiService.getEkatEventEntry(ekatRequestParams).subscribe(res => {
      this.ekatEventEntryLoading = false;
      console.log('ekatentryres: ', res);
      this.ekatEventEntry = res;
      this.ekatEventEntryDataSource = [];
      this.ekatEventEntryDataSource.push(res);
      this.showEkatInfo = true;
    });
  }

  deleteEhrDocument(ehrDocument) {
    const ehrRequest: EhrEesztIdRequest = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      eesztId: this.currentEhrEesztId,
      integrationId: null
    };
    this.apiService.deleteEhrDocument(ehrRequest).subscribe(res => {
      if (res.code > 0) {
        this.toastr.success('sikeres törlés!');
        this.getPatientEhrDocumentList();
        this.showEhrDocument = false;
      } else {
        this.toastr.error(res.message);
      }
    });
  }

  deleteEkatDocument(ekatElement) {
    console.log('ekatelement: ', ekatElement);
    const deleteEkatEesztIdRequest: DeleteEkatEesztIdRequest = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      eesztId: ekatElement.response.data.businessMessageContent.businessObjectIdentification.eesztid
    };
    this.patientEkatFormGroup.disable();
    this.apiService.deleteEkatEesztidMinimal(deleteEkatEesztIdRequest).subscribe(res => {
      if (res.code > 0) {
        this.toastr.success('sikeres törlés!');
        this.showEkatInfo = false;
        this.getPatientEkatDocumentList();
      } else {
        this.toastr.error(res.message);
      }
    }, err => {
      this.toastr.error(err.message);
    });
  }

  getPatientReservations(): void {
    if (this.patientName.value && this.patientReservationFromFormControl.value && this.patientReservationToFormControl.value) {
      this.patientReservationsDataSource.data = [];
      this.getPatientReservationLoading = true;
      this.apiService.getPatientReservations(this.createPatientReservationRequest()).pipe(finalize(() => { this.getPatientReservationLoading = false })).subscribe(res => {
        this.patientReservationsDataSource.data = res.data;
      }, err => {
        this.toastr.error('Hiba történt a páciens időpontjainak lekérdezése során!');
      });
    }
  }

  createPatientReservationRequest(): GetPatientReservationRequest {
    return {
      dateFrom:
        this.patientReservationFromFormControl.value.year + '-' +
        ('0' + this.patientReservationFromFormControl.value.month).slice(-2) + '-' +
        ('0' + this.patientReservationFromFormControl.value.day).slice(-2),
      dateTo:
        this.patientReservationToFormControl.value.year + '-' +
        ('0' + this.patientReservationToFormControl.value.month).slice(-2) + '-' +
        ('0' + this.patientReservationToFormControl.value.day).slice(-2),
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],  // currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID(),
        sessionId: null
      },
      doctorId: '',
      searchAlias: '',
      eventId: null,
      searchTapass: this.patientName.value,
      servicePointId: '',
      showFreeAppointments: false
    };
  }

}

