import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { ApiService } from '../core/http/api.service';
import { SharedDataService } from '../services/shared-data.service';
import { PatientDataFromQueryParams } from '../shared/models/patientDataFromQueryParams';
import { SaveAdmissionRequest } from '../shared/models/request/saveAdmissionRequest';
import { SaveScheduleRequest } from '../shared/models/request/saveScheduleRequest';
import { SelectedDateRange } from '../shared/models/selectedDateRange';
import { faSearch } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-ebp-booking',
  templateUrl: './ebp-booking.component.html',
  styleUrls: ['./ebp-booking.component.css']
})
export class EbpBookingComponent implements OnInit {

  apiUrl = environment.apiUrl;

  faSearch = faSearch;

  Brand = '';
  availServic = [];
  instNameElements = [];
  etElements = [];
  spElements = [];
  doctors = [];
  availServiceEmpty = false;
  calendarListDataAll = [];
  selectedService = null;
  tapass = null;
  etName = new FormControl();
  spName = new FormControl();
  doctor = new FormControl();
  instName = new FormControl();
  serviceSelect = new FormGroup({
    instName: this.instName,
    etName: this.etName,
    spName: this.spName,
    doctor: this.doctor
  });

  calendarDataArrived = false;

  shceduleProgressPercentage = 0;

  detailsName = '';
  patientRegistrationRequered = false;

  userData = null;
  dateChangeSubscription: Subscription;
  now = new Date();
  todayDate = this.sharedDataService.dateStringToNgbDate(this.sharedDataService.dateToDateString(this.now));
  patientDetailsFormVisible = false;
  isScheduleLoading = false;

  dateInvalid = false;
  registerPatientToAliasAppointment = false;
  check4TStatus = false;

  check4TResultMessage = '';


  birthname = new FormControl({ value: '', disabled: true }, [Validators.required, Validators.minLength(4)]);
  birthplace = new FormControl({ value: '', disabled: true }, [Validators.required, Validators.minLength(2)]);
  dateofbirth = new FormControl('', [Validators.required]);
  mothersname = new FormControl({ value: '', disabled: true }, [Validators.required, Validators.minLength(4)]);
  aliasname = new FormControl({ value: '', disabled: true }, [Validators.required, Validators.minLength(4)]);
  email = new FormControl({ value: '', disabled: false }, [Validators.required, Validators.email]);
  country = new FormControl({ value: '', disabled: true });
  zip = new FormControl({ value: '', disabled: true });
  city = new FormControl({ value: '', disabled: true });
  address = new FormControl({ value: '', disabled: true });
  comment = new FormControl({ value: '', disabled: true });
  ssn2t = new FormControl('');
  patientComplaint = new FormControl('', Validators.required);
  preliminaryDiagnosis = new FormControl('', Validators.required);
  referralComment = new FormControl('', Validators.required);
  patientDetails = new FormGroup({
    // 'name': this.name,
    'birthname': this.birthname,
    'birthplace': this.birthplace,
    'mothersname': this.mothersname,
    'ssn2t': this.ssn2t,
    'dateofbirth': this.dateofbirth,
    'aliasname': this.aliasname,
    'email': this.email,
    'country': this.country,
    'zip': this.zip,
    'city': this.city,
    'address': this.address,
    'comment': this.comment,
    'preliminaryDiagnosis': this.preliminaryDiagnosis,
    'referralComment': this.referralComment,
    'patientComplaint': this.patientComplaint
  });

  dateFrom = this.sharedDataService.getCurrentDate();

  selectedDateFrom = null;
  selectedDateTo = null;

  selectedDateRange: SelectedDateRange = null;

  selectedRole = null;
  dateSubscription: Subscription;
  availServicSubscription: Subscription;

  constructor(private apiService: ApiService,
    private authService: AuthService,
    private sharedDataService: SharedDataService,
    private toastr: ToastrService,
    private http: HttpClient) { }

  ngOnInit(): void {
    this.zip.valueChanges.pipe(debounceTime(2000)).subscribe(zip => {
      this.apiService.getCityByZipCode(zip).subscribe(city => {
        if (city && city['city']) {
          this.city.setValue(city['city'][0]);
        }
      });
    });
    if (this.sharedDataService.isEbpPatientFromQueryParams) {
      this.patientDetails.controls.dateofbirth.setValue(new NgbDate(+this.sharedDataService.patientDataFromQueryParams.dateOfBirth.split('-')[0], +this.sharedDataService.patientDataFromQueryParams.dateOfBirth.split('-')[1], +this.sharedDataService.patientDataFromQueryParams.dateOfBirth.split('-')[2]));
      this.patientDetails.controls.address.setValue(this.sharedDataService.patientDataFromQueryParams.address);
      this.patientDetails.controls.birthname.setValue(this.sharedDataService.patientDataFromQueryParams.birthName);
      this.patientDetails.controls.birthplace.setValue(this.sharedDataService.patientDataFromQueryParams.birthPlace);
      this.patientDetails.controls.city.setValue(this.sharedDataService.patientDataFromQueryParams.city);
      this.patientDetails.controls.country.setValue(this.sharedDataService.patientDataFromQueryParams.country);
      this.patientDetails.controls.email.setValue(this.sharedDataService.patientDataFromQueryParams.email);
      this.patientDetails.controls.mothersname.setValue(this.sharedDataService.patientDataFromQueryParams.mothersname);
      this.patientDetails.controls.aliasname.setValue(this.sharedDataService.patientDataFromQueryParams.name);
      this.patientDetails.controls.ssn2t.setValue(this.sharedDataService.patientDataFromQueryParams.ssn);
      this.patientDetails.controls.zip.setValue(this.sharedDataService.patientDataFromQueryParams.zip);
      this.patientDetails.controls.preliminaryDiagnosis.setValue(this.sharedDataService.patientDataFromQueryParams.preliminaryDiagnosis);
      this.patientDetails.controls.patientComplaint.setValue(this.sharedDataService.patientDataFromQueryParams.patientComplaint);
      this.patientDetails.controls.referralComment.setValue(this.sharedDataService.patientDataFromQueryParams.referralComment);
      this.check2T(null);
      if (!this.check4TStatus) {
        this.check4T(null);
      }
    }
    this.sharedDataService.bookingDateRangeChange.subscribe(bookingDate => {
      if (bookingDate.fromDate) {
        const dateFrom = bookingDate.fromDate;
        dateFrom.setDate(dateFrom.getDate() + 1);
        const dateTo = bookingDate.toDate;
        dateTo?.setDate(dateTo.getDate() + 1);
        this.selectedDateFrom = dateFrom;
        this.selectedDateTo = dateTo;
        console.log(this.selectedDateFrom);
        if (this.serviceSelect.controls.spName.value || this.serviceSelect.controls.etName.value) {
          this.loadCalendar();
        }
      } else {
        this.selectedDateFrom = null;
      }
    });
    /* this.patientDetails.controls.dateofbirth.valueChanges.pipe(debounceTime(200)).subscribe(dateOfBirth => {
      if (dateOfBirth) {
        if (dateOfBirth.year != this.global2TValidDateOfBirth?.year ||
          dateOfBirth.month != this.global2TValidDateOfBirth?.month ||
          dateOfBirth.day != this.global2TValidDateOfBirth?.day ||
          this.patientDetails.controls.ssn2t.value != this.global2TValidSsn) {
            this.check4TStatus = false;
        } else {
          this.check4TStatus = true;
          this.setUserData(this.global2TValidUser, true);
        }
      }

    });
    this.patientDetails.controls.ssn2t.valueChanges.pipe(debounceTime(200)).subscribe(ssn2t => {
      if (ssn2t) {
        if (ssn2t != this.global2TValidSsn ||
          this.patientDetails.controls.dateofbirth.value.year != this.global2TValidDateOfBirth?.year ||
          this.patientDetails.controls.dateofbirth.value.month != this.global2TValidDateOfBirth?.month ||
          this.patientDetails.controls.dateofbirth.value.day != this.global2TValidDateOfBirth?.day) {
            this.check4TStatus = false;
        } else {
          this.check4TStatus = true;
          this.setUserData(this.global2TValidUser, true);
        }
      }
    }); */
    this.birthname.valueChanges.pipe(debounceTime(1000)).subscribe(birthname => {
      if (birthname && this.dateofbirth.valid && this.birthplace.valid && this.mothersname.valid && !this.check4TStatus) {
        this.check4T(null);
      }
    });
    this.birthplace.valueChanges.pipe(debounceTime(1000)).subscribe(birthplace => {
      if (this.birthname.valid && this.dateofbirth.valid && birthplace && this.mothersname.valid && !this.check4TStatus) {
        this.check4T(null);
      }
    });
    this.mothersname.valueChanges.pipe(debounceTime(1000)).subscribe(mothersname => {
      if (this.birthname.valid && this.dateofbirth.valid && this.birthplace.valid && mothersname && !this.check4TStatus) {
        this.check4T(null);
      }
    });
    this.getSelectedDate();
    this.getAvailableServices();

    this.dateChangeSubscription = this.dateofbirth.valueChanges.pipe(
      debounceTime(500)
    ).subscribe(value => {
      if (value !== '' && value !== null) {
        if (value.year !== undefined) {
          const dateString = value.year + '-' + ('0' + (value.month)).slice(-2) + '-' + ('0' + value.day).slice(-2);
          const dateFromValue = new Date(dateString);
          this.dateInvalid = false;

        } else {

          const dateValue = value.replace(/\./g, '-');

          const dateObject = this.sharedDataService.dateFromUserinput(dateValue);
          if (dateObject && !isNaN(dateObject.getTime())) {
            const newDate = this.sharedDataService.dateToDateString(dateObject);
            this.dateofbirth.setValue(this.sharedDataService.dateStringToNgbDate(newDate));
            this.dateInvalid = false;
          } else {
            this.dateInvalid = true;
          }
        }
      }
    });
    this.Brand = this.authService.getBrand();
  }

  getAvailableServices() {
    this.availServicSubscription = this.sharedDataService.availServicesShared.subscribe(data => {
      this.availServic = data;
      /* this.instNameElements = [...this.availServic]; */
      this.availServic.forEach(service => {
        if (!this.instNameElements.find(x => x.instCode === service.instCode)) {
          this.instNameElements.push(service);
        }
      });
      this.calendarListDataAll = [];
      this.selectedService = null;
      this.etName.setValue(null);
      this.spName.setValue(null);
      if (data.length === 0) {
        this.availServiceEmpty = true;
      }
    });
  }

  getCalendarListData() {
    let dateFrom = this.selectedDateFrom ? this.sharedDataService.dateToDateString(this.selectedDateFrom) : this.dateFrom;
    let dateTo = new Date(dateFrom);
    dateTo.setDate(dateTo.getDate() + 7);
    if (!this.selectedDateFrom) {
      let ngbDateFrom = new NgbDate(this.sharedDataService.dateStringToNgbDate(dateFrom).year,
        this.sharedDataService.dateStringToNgbDate(dateFrom).month,
        this.sharedDataService.dateStringToNgbDate(dateFrom).day);
      let ngbDateTo = new NgbDate(dateTo.getFullYear(), dateTo.getMonth() + 1, dateTo.getDate());
      this.selectedDateRange = {
        dateFrom: ngbDateFrom,
        dateTo: ngbDateTo
      }
    }
    const getCalendarListDataRequest = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.getSelectedRole()['roleCode'],
        currentServicePointId: this.sharedDataService.getSelectedRole()['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      dateFrom,
      dateTo: this.selectedDateTo ? this.sharedDataService.dateToDateString(this.selectedDateTo) : this.selectedDateFrom ? dateFrom : this.sharedDataService.dateToDateString(dateTo),
      showFreeAppointments: true,
      searchTapass: '',
      searchAlias: '',
      statusList: 'F',
      eventId: this.serviceSelect.get('etName').value?.etId,
      servicePointId: this.serviceSelect.get('spName').value?.spId,
      doctorId: this.serviceSelect.get('doctor').value
    };
    return this.apiService.getCalendarListData(getCalendarListDataRequest);
  }

  filterByInstName() {
    if (this.serviceSelect.controls.instName.value) {
      this.availServic.filter(x => x.instCode === this.serviceSelect.controls.instName.value.instCode).forEach(service => {
        if (!this.etElements.find(x => x.etId === service.etId)) {
          this.etElements.push(service);
        }
        if (!this.spElements.find(x => x.spId === service.spId)) {
          this.spElements.push(service);
        }
      });
    }
  }

  filterServices() {
    if (this.serviceSelect.controls.etName.value) {
      this.spElements = this.availServic.filter(x => x.instCode === this.serviceSelect.controls.etName.value.instCode && x.etId === this.serviceSelect.controls.etName.value.etId);
    } else {
      const selectedSpName = this.serviceSelect.controls.spName.value;
      this.clearEt();
      this.clearSp();
      this.filterByInstName();
      if (selectedSpName) {
        this.serviceSelect.controls.spName.setValue(selectedSpName);
        this.filterEt();
      }
    }
  }

  filterEt() {
    if (this.serviceSelect.controls.spName.value) {
      this.etElements = this.availServic.filter(x => x.instCode === this.serviceSelect.controls.spName.value.instCode && x.spId === this.serviceSelect.controls.spName.value.spId);
    } else {
      const selectedEtName = this.serviceSelect.controls.etName.value;
      this.clearEt();
      this.clearSp();
      this.filterByInstName();
      if (selectedEtName) {
        this.serviceSelect.controls.etName.setValue(selectedEtName);
        this.filterServices();
      }
    }
  }

  onSelectedServiceChange($event) {
    this.selectedService = $event;
    this.sharedDataService.setSelectedService(this.selectedService, '1'/* this.calendarID */);
    if (this.selectedService) {
      this.getDayColors();
    }
  }

  selectDoctor($event) {
    this.getDoc($event.svId);
  }

  getDayColors() {
    this.getScheduleColorList().subscribe(
      (data: Response) => {
        // console.log('Schedule Color List:', data);
        if (data['code'] === 1) {
          data['objScheduleColor'].forEach(scheduleColorEntry => {

            scheduleColorEntry['dateFrom'] = this.sharedDataService.DateStringConvert(scheduleColorEntry['dateFrom']);

          });
          this.sharedDataService.setCalendarData(data['objScheduleColor']);
          this.loadCalendar();
        }
      }
    );
  }

  getScheduleColorList() {
    // console.log ('getScheduleColorList this.selectedService', this.selectedService);

    const dateFrom = new Date();
    dateFrom.setDate(1);
    const dateTo = new Date(dateFrom.getFullYear(), dateFrom.getMonth() + 2, 0);
    const dateToString = dateTo.toISOString();
    const dateFromTring = dateFrom.toISOString();
    console.log('sp: ', this.serviceSelect.get('spName').value);
    console.log('et: ', this.serviceSelect.get('etName').value);

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID(),
        sessionId: null
      },
      doctorId: this.serviceSelect.get('doctor').value,
      eventTypeId: this.serviceSelect.get('etName').value?.etId,
      servicePointId: this.Brand === 'UDMED' ? null : this.serviceSelect.get('spName').value?.spId,
      dateFrom: dateFromTring.substring(0, dateFromTring.indexOf('T')),
      dateTo: dateToString.substring(0, dateToString.indexOf('T')),
      contextParam: ''
    };

    return this.http.post(this.apiUrl + '/rest/neu/appointment/getschedulecolorlist/smp', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
    /* return this.http.post(this.apiUrl + '/rest/neu/appointment/getschedulecolorlistv2', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }); */
  }

  getDoc(serviceId: number) {
    this.getDoctors(serviceId).subscribe(
      (response: any) => {
        this.doctors = response.doctors;
      });
  }

  getDoctors(serviceId: number) {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID(),
        sessionId: null
      },
      serviceId: serviceId,
    };

    return this.http.post(this.apiUrl + '/rest/neu/appointment/getdoc/by/service', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getSelectedDate() {
    this.dateSubscription = this.sharedDataService.selectedDateChange.pipe(
      debounceTime(500)
    ).subscribe((date: string) => {
      this.dateFrom = date;
      this.loadCalendar();
    });
  }

  loadCalendar() {
    this.isScheduleLoading = true;
    this.startTimer();
    this.getCalendarListData().subscribe((calendarData: Response) => {
      this.calendarDataArrived = true;
      this.isScheduleLoading = false;
      if (calendarData['code'] === 1) {
        this.calendarListDataAll = calendarData['data'];
        if (calendarData['data'].length < 1) {
          this.toastr.warning('ehhez a szolgáltatási ponthoz nem tartozik kiírt időpont!');
        }
      } else {
        this.toastr.error(calendarData['message']);
      }
    }, err => {
      this.isScheduleLoading = false;
      this.toastr.error(err);
    });
  }

  startTimer() {
    const timer$ = interval(10);
    let percentageJump = 1;
    const sub = timer$.subscribe((sec) => {
      percentageJump = percentageJump / 1.015;
      this.shceduleProgressPercentage += percentageJump;
      if (this.calendarDataArrived) {
        sub.unsubscribe();
        this.calendarDataArrived = false;
        this.shceduleProgressPercentage = 0;
        /* this.shceduleProgressPercentage = percentageJump;
        this.isScheduleLoading = false;
        if (calendarData['code'] === 1) {
          this.calendarListDataAll = calendarData['data'];
          if (calendarData['data'].length < 1) {
            this.toastr.warning('ehhez a szolgáltatási ponthoz nem tartozik kiírt időpont!');
          }
        } else {
          this.toastr.error(calendarData['message']);
        } */
      }
    });
  }

  clearEt() {
    this.etElements = [];
    this.serviceSelect.controls.etName.setValue(null);
  }

  clearSp() {
    this.spElements = [];
    this.serviceSelect.controls.spName.setValue(null);
  }

  clearDr() {
    this.doctors = [];
    this.serviceSelect.controls.doctor.reset();
  }

  check2T($event) {
    this.toggleFormDetails(false, false);
    this.userData = null;
    this.checkUserBy2T().subscribe(
      (userData: Response) => {
        this.patientDetailsFormVisible = true;
        this.check4TResultMessage = userData['message'];
        if (userData['code'] === 1) { // existing patient reg found
          this.toggleFormDetails(false, false);
          this.tapass = userData['user']['ekCardNo'];

          const dateOfBirth = new Date(this.sharedDataService.DateStringConvert(userData['user']['dateOfBirth']));

          userData['user']['dateOfBirth'] = this.sharedDataService.dateToDateString(dateOfBirth);

          this.setUserData(userData);
          this.check4TStatus = true;
          this.disable4Tediting();
        } else if (userData['code'] === -16) {
          if (!this.sharedDataService.isEbpPatientFromQueryParams) {
            this.reset4TDetails();
            this.toggleFormDetails(true, true);
            this.aliasname.setValue(this.birthname.value);
          }
          this.check4TStatus = false;
          this.check4TResultMessage = 'Nem létező felhasználó, kérjük töltse ki az adatokat!';

        } else if (userData['code'] === -25) {
          this.reset4TDetails();
          this.check4TStatus = false;
          this.check4TResultMessage = 'Érvénytelen paraméterek, kérjük javítsa az adatokat, majd próbálja meg újra!';
        }
      }
    );
  }

  isPatientValid(calendarEntry) {
    this.isScheduleLoading = true;
    this.shceduleProgressPercentage = 0;
    if (this.check4TStatus) {
      this.saveSchedule(calendarEntry);
    } else {
      this.patientRegistrationRequered = true;
      this.userRegistration(calendarEntry);
    }
  }

  saveSchedule(calendarEntry) {
    this.apiService.saveScheduleDay(this.getSaveScheduleDay(calendarEntry)).subscribe(saveScheduleRes => {
      if (saveScheduleRes['code'] > 0) {
        this.apiService.saveAdmission(this.getSaveAdmissionObject()).subscribe(saveAdmissionRes => {
          if (saveAdmissionRes['code'] > 0) {
            this.toastr.success('beutaló rögzítve');
            this.shceduleProgressPercentage = this.patientRegistrationRequered ? 66 : 50;
            setTimeout(() => {
              this.apiService.saveAdmission(this.getSaveAdmissionRequest(saveAdmissionRes, saveScheduleRes)).subscribe(updateAdmissionResSecond => {
                if (updateAdmissionResSecond['code'] > 0) {
                  this.toastr.success('időpont lefoglalva');
                  this.shceduleProgressPercentage = 100;
                  setTimeout(() => {
                    this.patientRegistrationRequered = false;
                    this.patientDetails.reset();
                    this.patientComplaint.reset();
                    this.preliminaryDiagnosis.reset();
                    this.referralComment.reset();
                    this.serviceSelect.controls.etName.reset();
                    this.serviceSelect.controls.spName.reset();
                    this.sharedDataService.setCalendarData(null);
                    this.calendarListDataAll = [];
                    this.isScheduleLoading = false;
                    this.sharedDataService.isEbpPatientFromQueryParams = false;
                    this.sharedDataService.patientDataFromQueryParams = null;
                    this.selectedDateRange = {
                      dateFrom: null,
                      dateTo: null
                    }
                    this.shceduleProgressPercentage = 0;
                  }, 500);
                } else {
                  this.toastr.error(updateAdmissionResSecond['message']);
                  this.isScheduleLoading = false;
                }
              }, error => {
                this.toastr.error(error.error.message);
                this.isScheduleLoading = false;
              });
            }, 1000);
          } else {
            this.toastr.error(saveAdmissionRes['message']);
            this.isScheduleLoading = false;
          }
        }, err => {
          this.toastr.error(err.error.message);
          this.isScheduleLoading = false;
        });
      } else {
        this.toastr.error(saveScheduleRes.message);
        this.isScheduleLoading = false;
      }
    }, err => {
      this.toastr.error(err.error.message);
      this.isScheduleLoading = false;
    });


  }

  userRegistration(calendarEntry) {
    this.getTapass().subscribe(res => {
      if (res['code'] > 0) {
        const params = {
          messageHeader: {
            currentTapass: this.authService.getUserData()['ekCardNo'],
            currentRole: this.sharedDataService.selectedRole['roleCode'],
            currentServicePointId: this.sharedDataService.selectedRole['spId'],
            locale: 'hu',
            deviceId: this.authService.getUUID()
          },
          userId: '',
          ekUserName: '',
          name: this.patientDetails.controls.aliasname.value ? this.patientDetails.controls.aliasname.value : '',
          birthName: this.patientDetails.controls.birthname.value ? this.patientDetails.controls.birthname.value : '',
          mothersName: this.patientDetails.controls.mothersname.value ? this.patientDetails.controls.mothersname.value : '',
          placeOfBirth: this.patientDetails.controls.birthplace.value ? this.patientDetails.controls.birthplace.value : '',
          dateOfBirth: this.patientDetails.controls.dateofbirth.value ? this.patientDetails.controls.dateofbirth.value.year + '-' +
            this.patientDetails.controls.dateofbirth.value.month + '-' + this.patientDetails.controls.dateofbirth.value.day : '',
          countryName: this.patientDetails.controls.country.value ? this.patientDetails.controls.country.value : '',
          /* country3Digit: this.patientDetails.controls.country.value ? this.patientDetails.controls.country.value : '', */
          zipcode: this.patientDetails.controls.zip.value ? this.patientDetails.controls.zip.value : '',
          email: this.patientDetails.controls.email.value ? this.patientDetails.controls.email.value : '',
          socialSecurityNumber: this.patientDetails.controls.ssn2t.value ? this.patientDetails.controls.ssn2t.value : '',
          socialSecurityType: 1,
          tapass: res['tapass'],
          spCode: this.sharedDataService.selectedRole['spId'], // '7A9BA3A9ACD343B1E05011AC0D092706',
          spId: this.sharedDataService.selectedRole['spId'],
          settlement: this.patientDetails.controls.city.value ? this.patientDetails.controls.city.value : '',
          streetWithDetails: this.patientDetails.controls.address.value ? this.patientDetails.controls.address.value : '',
          isEkWorkflow: true
          // isEkWorkflow	boolean
          // xml: OrderedMap { "name": "isEkWorkFlow" }
          // default: false
          // Munkafolyamat specifikus adatok (BLC, BJK stb.) tárolását vezérli az új userre vonatkozóan.
          // True: Nem tárolja, ragaszkodik az ek wf-hez. False: Tárolja
        };

        this.apiService.userRegistration(params).subscribe(userRegistrationRes => {
          if (userRegistrationRes['code'] > 0) {
            this.toastr.success('páciens regisztrálva');
            this.shceduleProgressPercentage = 33;
            setTimeout(() => {
              this.checkUserBy4T().subscribe(checkUserBy4TRes => {
                this.setUserData(checkUserBy4TRes);
                this.saveSchedule(calendarEntry);
              });
            }, 1000);
          } else {
            this.isScheduleLoading = false;
            this.toastr.error(userRegistrationRes['message']);
          }
        }, err => {
          this.isScheduleLoading = false;
          this.toastr.error(err.error.message);
        });
      } else {
        this.isScheduleLoading = false;
        this.toastr.error(res['message']);
      }
    }, err => {
      this.isScheduleLoading = false;
      this.toastr.error(err.error.message);
    });

  }

  getTapass() {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      spCode: this.sharedDataService.selectedRole['spId']
    };
    return this.http.post(this.apiUrl + '/rest/neu/authentication/gettapass', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getSaveScheduleDay(calendarEntry): SaveScheduleRequest {
    let eventTypeId = null;
    if (this.serviceSelect.controls.spName.value) {
      eventTypeId = this.availServic.find(x => x.spId === this.serviceSelect.controls.spName.value.spId).etId;
    } else if (this.serviceSelect.controls.etName.value) {
      eventTypeId = this.availServic.find(x => x.etId === this.serviceSelect.controls.etName.value.etId).etId;
    }
    return {
      diagnosisComment: this.referralComment.value,
      eventTypeId: eventTypeId,
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID(),
        sessionId: null
      },
      patientAlias: null,
      patientTapass: this.userData['ekCardNo'],
      providerUserId: this.authService.getUserData()['ekCardNo'],
      scheduleFkList: calendarEntry.scheduleFk.toString(),
      ssnNumber: this.userData['socialSecurityNumber'],
      ssnType: '1',
      ssnTypeDesc: '',
      status: 'S',
      email: this.email.value
    };
  }

  getSaveAdmissionObject(): SaveAdmissionRequest {
    this.getDateFrom();
    this.getDateTo();
    const validFrom = this.getDateFrom();
    let validTo = this.getDateTo();
    let targetEtCode = null;
    let targetSpCode = null;
    if (this.serviceSelect.controls.spName.value) {
      targetEtCode = this.availServic.find(x => x.spId === this.serviceSelect.controls.spName.value.spId).etId;
    } else if (this.serviceSelect.controls.spName.value) {
      targetEtCode = this.availServic.find(x => x.spId === this.serviceSelect.controls.etName.value.spId).etId;
    }
    if (this.serviceSelect.controls.spName.value) {
      targetSpCode = this.serviceSelect.controls.spName.value.spId;
    } else if (this.serviceSelect.controls.etName.value) {
      targetSpCode = this.serviceSelect.controls.etName.value.spId;
    }
    return {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID(),
        sessionId: null
      },
      bno: this.preliminaryDiagnosis.value, // páciens diagnózis
      bnoDesc: null, //null
      id: null, // null
      patientTapass: this.userData['ekCardNo'],
      senderApId: 'NAULIGHT',
      senderCalendarId: null, // null for now
      senderDoctorId: this.authService.getUserData()['ekCardNo'],
      senderServicePointId: this.sharedDataService.selectedRole['spId'],
      status: 'NEW',
      targetCalendarId: null, // null
      targetEtCode,
      targetSpCode,
      comment: this.referralComment.value, // beu megjegyzés
      presentComplaint: this.patientComplaint.value, // jelen panasz
      validFrom: validFrom,
      validTo: validTo
    }
  }

  getDateFrom(): string {
    const validFrom = new Date();
    return validFrom.getFullYear() + '-' +
      (('0' + (validFrom.getMonth() + 1)).slice(-2)) + '-' +
      (('0' + validFrom.getDate()).slice(-2)) + ' ' +
      (('0' + validFrom.getHours()).slice(-2)) + ':' +
      (('0' + validFrom.getMinutes()).slice(-2));
  }

  getDateTo(): string {
    let validTo = new Date();
    validTo.setDate(validTo.getDate() + 90)
    return validTo.getFullYear() + '-' +
      (('0' + (validTo.getMonth() + 1)).slice(-2)) + '-' +
      (('0' + validTo.getDate()).slice(-2)) + ' ' +
      (('0' + validTo.getHours()).slice(-2)) + ':' +
      (('0' + validTo.getMinutes()).slice(-2));
  }

  getSaveAdmissionRequest(res, saveScheduleRes) {
    let targetEtCode = null;
    let targetSpCode = null;
    if (this.serviceSelect.controls.spName.value) {
      targetEtCode = this.availServic.find(x => x.spId === this.serviceSelect.controls.spName.value.spId).etId;
    } else if (this.serviceSelect.controls.spName.value) {
      targetEtCode = this.availServic.find(x => x.spId === this.serviceSelect.controls.etName.value.spId).etId;
    }
    if (this.serviceSelect.controls.spName.value) {
      targetSpCode = this.serviceSelect.controls.spName.value.spId;
    } else if (this.serviceSelect.controls.etName.value) {
      targetSpCode = this.serviceSelect.controls.etName.value.spId;
    }
    return {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID(),
        sessionId: null
      },
      bno: this.preliminaryDiagnosis.value,
      bnoDesc: null,
      id: res['code'],
      patientTapass: this.userData['ekCardNo'],
      senderApId: 'NAULIGHT',
      senderCalendarId: null,
      senderDoctorId: this.authService.getUserData()['ekCardNo'],
      senderServicePointId: this.sharedDataService.selectedRole['spId'],
      status: 'UPDATE',
      targetCalendarId: saveScheduleRes['code'],
      targetEtCode,
      targetSpCode,
      comment: this.referralComment.value,
      presentComplaint: this.patientComplaint.value,
      validFrom: this.getDateFrom(),
      validTo: this.getDateTo()
    };
  }

  changeIn4T() {
    this.toggleFormDetails(false, false);
    this.check4TResultMessage = '';
    this.check4TStatus = false;
  }

  check4T($event) {
    /* this.toggleFormDetails(false, false); */
    this.userData = null;
    this.checkUserBy4T().subscribe(
      (userData: Response) => {
        this.patientDetailsFormVisible = true;
        this.check4TResultMessage = userData['message'];
        if (userData['code'] === 1) {
          /* this.toggleFormDetails(false, false); */

          const dateOfBirth = new Date(this.sharedDataService.DateStringConvert(userData['user']['dateOfBirth']));

          userData['user']['dateOfBirth'] = this.sharedDataService.dateToDateString(dateOfBirth);
          this.setUserData(userData);
          this.check4TStatus = true;
          this.disable4Tediting();
          this.toggleFormDetails(false, true);
        } else if (userData['code'] === -16) {
          this.toggleFormDetails(true, true);
          this.check4TStatus = false;
          this.check4TResultMessage = 'Nem létező felhasználó, kérjük töltse ki az adatokat!';
        } else if (userData['code'] === -25) {
          this.check4TStatus = false;
          this.check4TResultMessage = 'Érvénytelen paraméterek, kérjük javítsa az adatokat, majd próbálja meg újra!';
        }
      }
    );
  }

  reset4TDetails() {
    this.birthplace.reset();
    this.birthname.reset();
    this.mothersname.reset();
    this.aliasname.reset();
    this.email.reset();
    this.country.reset();
    this.zip.reset();
    this.city.reset();
    this.address.reset();
  }

  checkUserBy4T() {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      birthName: this.birthname.value,
      dateOfBirth: this.dateofbirth.value.year + '-' + this.dateofbirth.value.month + '-' + this.dateofbirth.value.day,
      placeOfBirth: this.birthplace.value,
      mothersName: this.mothersname.value
    };

    return this.apiService.checkUserBy4t(params);
  }

  disable4Tediting() {
    this.birthname.disable();
    /* this.dateofbirth.disable(); */
    this.birthplace.disable();
    this.mothersname.disable();
  }

  setUserData(userData) {
    console.log(userData);
    this.check4TResultMessage = '';
    this.check4TStatus = true;

    userData['user']['birthForename2'] = !userData['user']['birthForename2'] ? '' : userData['user']['birthForename2'];

    userData['user']['birthForename2'] = userData['user']['birthForename2'] !== '' ? ' ' +
      userData['user']['birthForename2'] : userData['user']['birthForename2'];

    userData['user']['birthForename'] = !userData['user']['birthForename'] ? '' : ' ' + userData['user']['birthForename'];

    userData['user']['birthForename'] = userData['user']['birthForename'] !== '' ? ' ' +
      userData['user']['birthForename'] : userData['user']['birthForename'];

    userData['user']['birthName'] = userData['user']['birthSurname'] + userData['user']['birthForename'] +
      userData['user']['birthForename2'];

    userData['user']['forename'] = userData['user']['forename'] ? ' ' + userData['user']['forename'] : '';
    userData['user']['forename2'] = userData['user']['forename2'] ? ' ' + userData['user']['forename2'] : '';

    userData['user']['name'] = userData['user']['surname'] + userData['user']['forename'] + userData['user']['forename2'];

    userData['user']['street'] = userData['user']['street'] ? userData['user']['street'] : '';
    userData['user']['streetType'] = userData['user']['streetType'] ? userData['user']['streetType'] : '';
    userData['user']['houseNumber'] = userData['user']['houseNumber'] ? userData['user']['houseNumber'] : '';
    userData['user']['zipCode'] = userData['user']['zipcode'] ? userData['user']['zipcode'] : '';
    userData['user']['email'] = userData['user']['email'] ? userData['user']['email'] : '';
    userData['user']['phoneNumber'] = userData['user']['phoneNumber'] ? userData['user']['phoneNumber'] : '';

    this.detailsName = userData['user']['name'];
    console.log(userData);
    this.birthname.setValue(userData['user']['birthName']);
    this.birthplace.setValue(userData['user']['placeOfBirth']);
    this.mothersname.setValue(userData['user']['mothersName']);
    /* this.dateofbirth.setValue(userData['user']['dateOfBirth']); */
    this.ssn2t.setValue(userData['user']['socialSecurityNumber']);
    this.aliasname.setValue(userData['user']['name']);
    this.address.setValue(userData['user']['street'] + ' ' + userData['user']['streetType'] + ' ' + userData['user']['houseNumber']);
    this.zip.setValue(userData['user']['zipCode']);
    this.email.setValue(userData['user']['email']);
    this.city.setValue(userData['user']['settlement']);
    this.country.setValue(userData['user']['country3Digit']);


    if (userData['user']['id'] !== undefined) {
      userData['user']['userId'] = userData['user']['id'];
    }

    this.comment.setValue(userData['user']['comment']);

    this.userData = userData['user'];

    if (this.registerPatientToAliasAppointment) {

    }
  }

  toggleFormDetails(state: boolean, ekWorkflowEnabled: boolean) {
    if (state === true) {
      this.birthname.enable({ emitEvent: false });
      this.birthplace.enable({ emitEvent: false });
      this.mothersname.enable({ emitEvent: false });
      this.aliasname.enable();
      this.email.enable();
      this.country.enable();
      this.zip.enable();
      this.city.enable();
      this.address.enable();
      this.comment.enable();
    } else {
      this.birthname.disable({ emitEvent: false });
      this.birthplace.disable({ emitEvent: false });
      this.mothersname.disable({ emitEvent: false });
      this.aliasname.disable();
      //this.email.disable();
      this.country.disable();
      this.zip.disable();
      this.city.disable();
      this.address.disable();
      this.comment.disable();
    }
  }

  checkUserBy2T() {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      ssnType: '1',
      ssnNumber: this.ssn2t.value,
      dateOfBirth: this.dateofbirth.value.year + '-' + this.dateofbirth.value.month + '-' + this.dateofbirth.value.day,
    };

    return this.apiService.checkUserBy2t(params);
  }

  saveAdmission() {

  }
}
