import { environment } from '../../environments/environment.test';
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { SharedDataService } from '../services/shared-data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { NgxPrintModule } from 'ngx-print';

@Component({
  selector: 'app-dolg-jelenlet',
  templateUrl: './dolg-jelenlet.component.html',
  styleUrls: ['./dolg-jelenlet.component.css']
})
export class DolgJelenletComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(public authService: AuthService,
    private http: HttpClient,
    private sharedDataService: SharedDataService
  ) { }

  // @ViewChild(MatPaginator) paginator: MatPaginator;

  acDeviceListSubscription: Subscription;
  acDeviceList = [];
  eventLogRows = [];
  loading = false;
  pristine = true;
  showImage = false;
  imageLoading = true;
  eventEntry = {};
  imageError = false;

  reportParams = {};

  regImageData = "";

  detailsName = '';
  detailsPosition = '';
  detailsRole = '';
  detailsRegPicId = null;
  detail = null;
  detailLoading = false;

  documents = null;
  documentsLoading = false;
  documentsCount = 0;

  patientListLoading = true;
  patientListDataSubscription: Subscription;
  patientList = [];
  tapass = '';

  @ViewChild('filterTable')
  filterTable: ElementRef;

  filterform: FormGroup;

  displayedColumns = ['date', 'durationInMinutes'];
  dataSource = new MatTableDataSource<any>();

  imageData = {};

  today = new Date();
  startDate = new Date(this.today.getTime() - 7776000000);

  endDateString = this.today.getFullYear() + '-' +
    (('0' + (this.today.getMonth() + 1)).slice(-2)) + '-' +
    (('0' + this.today.getDate()).slice(-2));

  startDateString = this.startDate.getFullYear() + '-' +
    (('0' + (this.startDate.getMonth() + 1)).slice(-2)) + '-' +
    (('0' + this.startDate.getDate()).slice(-2));

  dateFromInput = new FormControl(this.sharedDataService.dateStringToNgbDate(this.startDateString), Validators.required);
  dateToInput = new FormControl(this.sharedDataService.dateStringToNgbDate(this.endDateString), Validators.required);
  acDeviceIn = new FormControl('', [Validators.required]);
  acDeviceOut = new FormControl('', [Validators.required]);
  patientName = new FormControl('', [Validators.required]);
  filterInput = new FormControl();

  requestDetails = new FormGroup({
    dateFromInput: this.dateFromInput,
    dateToInput: this.dateToInput,
    acDeviceIn: this.acDeviceIn,
    acDeviceOut: this.acDeviceOut,
    patientName: this.patientName
  });

  dateChangeSubscriptionStart: Subscription;
  dateChangeSubscriptionEnd: Subscription;
  refreshAppointmentsSubscription: Subscription;

  dateInvalidStart = null;
  dateInvalidEnd = null;

  apiUrl = environment.apiUrl;
  environment = environment;

  ngOnInit() {
    // this.dataSource.paginator = this.paginator;

    this.acDeviceListSubscription = this.sharedDataService.acDeviceListShared.subscribe(data => {
      const acDeviceList = data;
      acDeviceList.sort(this.sortAcDeviceList);
      // console.log ('acdevlist', acDeviceList);
      this.acDeviceList = acDeviceList;
      // this.acDeviceList = this.environment.acDeviceListTemp;
      // console.log ('adDeviceList', this.acDeviceList);
    });

    this.patientListDataSubscription = this.sharedDataService.patientListShared.subscribe((patientList) => {
      this.patientList = [];
      // console.log ('register patient - patient list updated', patientList);
      this.patientListLoading = false;
      this.patientList = patientList;

    });

  }

  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    if (this.acDeviceListSubscription) {
      this.acDeviceListSubscription.unsubscribe();
    }
    if (this.patientListDataSubscription) {
      this.patientListDataSubscription.unsubscribe();
    }
  }



  onSelectedPatientChange($event) {
    if ($event && $event['tapass'].length !== 0) {
      this.loadDocuments($event['tapass']);
      this.tapass = $event['tapass'];
    } else {
      this.tapass = '';
      this.documents = null;
      this.regImageData = '';
    }
  }

  loadEmployeeWorkingHours() {
    this.pristine = false;
    this.loading = true;
    let eventLogRows = [];
    this.getEmployeeWorkingHours().subscribe(hoursData => {
      this.loading = false;
      if (hoursData['code'] === 1) {
        this.reportParams['currName'] = this.patientName.value.currName;
        this.reportParams['dateFrom'] = this.dateFromInput.value.year + '-' +
                                        this.dateFromInput.value.month + '-' + this.dateFromInput.value.day;
        this.reportParams['dateTo'] = this.dateToInput.value.year + '-' + this.dateToInput.value.month + '-' + this.dateToInput.value.day;
        eventLogRows = hoursData['workingHoursDetails'];
        eventLogRows.sort(this.sortEventLogRows);
        eventLogRows.forEach(item => {
          item['hoursAndMinutes'] = this.calcHoursAndMinutes(item['durationInMinutes']);
        });
        this.eventLogRows = eventLogRows;
        this.dataSource = new MatTableDataSource(eventLogRows);
        console.log('getEmployeeWorkingHours', hoursData);
      } else {
        console.log ('error loading getEmployeeWorkingHours', hoursData);
      }
    });

    // this.dataSource.paginator = this.paginator;
  }

  getEmployeeWorkingHours() {

    const startDateString = this.dateFromInput.value.year + '-' + this.dateFromInput.value.month + '-' + this.dateFromInput.value.day;
    const endDateString = this.dateToInput.value.year + '-' + this.dateToInput.value.month + '-' + this.dateToInput.value.day;

    let cardId = null;
    if (this.patientName.value.tapass) {
      cardId = this.patientName.value.tapass;
      console.log ('patientName', this.patientName.value);
    }

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      cardId: cardId,
      name: null,
      dateFrom: startDateString + ' 00:00:00',
      dateTo: endDateString + ' 23:59:59',
      inDeviceId: this.acDeviceIn.value,
      outDeviceId: this.acDeviceOut.value
    };

    return this.http.post(this.apiUrl + '/rest/neu/dlm/getemployeeworkinghours', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }

  loadIpCamPicture(eventEntry) {
    this.imageLoading = true;
    this.showImage = true;
    this.eventEntry = eventEntry;
    this.imageData = {};
    this.getIpCamPicture(eventEntry['dlmId'], eventEntry['picLocation']).subscribe((responseData) => {
      this.imageLoading = false;
      if (responseData['code'] === 1) {
        // this.imageData = "data:image/jpeg;base64, " + responseData['image'];
        this.imageData = { 'background-image': "url('data:image/png;base64, " + responseData['image'] + "')" };
        // console.log (this.imageData);
      } else {
        this.imageError = true;
        console.error('Error loading IP cam picture');
      }
    });
  }

  getIpCamPicture(dlmId, relativeLocation) {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      dlmId: dlmId,
      relativeLocation: relativeLocation,
    };

    return this.http.post(this.apiUrl + '/rest/neu/dlm/getipcampicture', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }


  sortEventLogRows(a, b) {
    if (a['date'] < b['date']) {
      return 1;
    } else if (a['date'] > b['date']) {
      return -1;
    }
    return 0;
  }

  resetModal() {
    this.acDeviceIn.reset();
    this.acDeviceOut.reset();
    this.patientName.reset();
    this.pristine = true;
    this.eventLogRows = [];
    this.dataSource = new MatTableDataSource([]);
    this.imageData = {};
    this.regImageData = "";
  }

  closeImage() {
    this.showImage = false;
    this.eventEntry = {};
    this.imageError = false;
  }

  applyFilter(filterValue: string) {
    if (filterValue) {
      filterValue = filterValue.trim();
      filterValue = filterValue.toLowerCase();
      this.dataSource.data = this.eventLogRows;
      this.dataSource.filter = filterValue;
      this.dataSource.data = this.dataSource.filteredData;
    } else {
      this.filterTable.nativeElement.value = '';
      this.dataSource = new MatTableDataSource<any>(this.eventLogRows);
    }
  }

  sortAcDeviceList(a, b) {
    return new Intl.Collator('hu').compare(a['deviceName'], b['deviceName']);  // -1
  }





  loadDocuments(tapass) {
    this.imageData = '';
    this.documents = null;
    this.documentsLoading = true;
    let documents = [];
    this.getDocuments(tapass).subscribe(
      (data: Response) => {
        this.documentsLoading = false;
        if (data['code'] === 1) {
          // console.log(data['data']);
          if (data['data'].length > 0) {

            data['data'].forEach(entry => {
              entry['docDate'] = this.sharedDataService.DateStringConvert(entry['docDate']);
              if (entry['docFormat'] !== 'HTML') {
                documents.push(entry);
              }
            });

            documents.sort(this.sortDocuments);
            let regPicId = null;
            documents.forEach(doc => {
              if (doc['docTypeCode'] === 'REG_PIC_JPG' && !regPicId) {
                regPicId = doc['docId'];
              }
            });
            if (regPicId) {
              this.detailsRegPicId = regPicId;
              this.loadRegPic(this.detailsRegPicId);
            }
          }
          this.documents = documents;
          this.documentsCount = this.documents.length;
        } else {
          // hiba
        }
      }
    );
  }

  loadRegPic(documentID) {

    this.getDocument(documentID).subscribe(
      (data: Response) => {
        if (data['code'] == 1) {
          this.regImageData = "data:image/jpeg;base64, " + data['document'];
        } else {
          this.regImageData = "";
          // error
        }
        // console.log(data);
      }
    );
  }


  getDocument(documentID) {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      docId: documentID
    };

    return this.http.post(this.apiUrl + '/rest/neu/docinv/getdocumentjson', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }
  sortDocuments(a, b) {
    if (a['docDate'] > b['docDate']) {
      return -1;
    } else if (a['docDate'] < b['docDate']) {
      return 1;
    } else {
      if (a['docId'] > b['docId']) {
        return -1;
      } else {
        return 1;
      }
      return 0;
    }
  }


  getDocuments(tapass) {

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      requestedUserTapass: tapass,
      dateFrom: null,
      dateTo: null,
      docTypes: null,
      fromIndex: null,
      toIndex: null
    };

    return this.http.post(this.apiUrl + '/rest/neu/docinv/getdocuments', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }

  printJelenlet() {
    let content = `<title>Dolgozói jelenlét</title>
    <head>
    <style>
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }
    
    td, th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }
    
    tr:nth-child(even) {
      background-color: #eeeeee;
    }
    </style>
    </head>
    <h2>Dolgozói jelenlét kimutatás</h2>
    <h3>Név: ` + this.reportParams['currName'] + `</h3>
    <h3>Időszak: ` + this.reportParams['dateFrom'] + ` - ` + this.reportParams['dateTo'] + `</h3>
    <table>
      <tr>
      <th>Dátum</th>
      <th>Időtartam (perc)</th>
      </tr>
      `;

    this.eventLogRows.forEach(row => {
      content += `<tr>
        <td>` + this.sharedDataService.dateToDateTimeStringHuman(new Date(row['date'])) + `</td>
        <td>` + row['hoursAndMinutes'] + `</td>
        </tr>`;
    });
    content += `</table>`;
    const newWindow = window.open('');
    newWindow.document.open();
    newWindow.document.write(content);
    newWindow.print();
    newWindow.document.close();
  }

  calcHoursAndMinutes(duration) {
    let hours = 0;
    let minutes = 0;
    let hoursAndMinutes = '00:00';
    if (duration && duration > 0) {
      hours = Math.floor(duration / 60);
      minutes = duration - (hours * 60);
      hoursAndMinutes = ('0' + hours.toString()).slice(-2) + ':' + ('0' + minutes.toString()).slice(-2);
    }
    return hoursAndMinutes;
  }



}
