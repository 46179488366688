import { Subscription } from 'rxjs';
import { SharedApiService } from './../../services/shared-api.service';
import { ApiService } from './../../core/http/api.service';
import { SharedDataService } from './../../services/shared-data.service';
import { AuthService } from './../../auth/auth.service';
import { FormBuilder, FormArray, Validators } from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-bno-who',
  templateUrl: './bno-who.component.html',
  styleUrls: ['./bno-who.component.css']
})
export class BnoWhoComponent implements OnInit {

  @ViewChild('picker') picker: any;

  @Output() savedBnosChanged = new EventEmitter<Object>();
  @Output() savedWhosChanged = new EventEmitter<Object>();
  @Output() saveInProgress = new EventEmitter<Object>();
  @Output() saveResult = new EventEmitter<Object>();

  @Input() set who(data) {
    this.allWho = data;
    this.whoListLoading = false;
  }

  @Input() set bno(data) {
    this.allBno = data;
    this.bnoListLoading = false;
  }

  @Input() set calendarStatus(data) {
    this.status = data;
  }

  @Input() set diagType(data) {
    this.allDiagType = data;
    this.reqDiagType = this.allDiagType.filter(diag => diag.code === '3');
    this.allDiagType = this.allDiagType.filter(diag => diag.code !== '3');
    this.diagTypeListLoading = false;
  }

  @Input() set admTypeCat(data) {
    this.admTypeCategory = data;
  }

  allWho = [];
  allBno = [];
  healing = [];
  oenoType = [];
  nosocEnv = [];
  laterality = [];
  anaestOeno = [];
  allDiagType = [];
  reqDiagType = [];
  qualification = [];

  savedBnos = [];
  savedWhos = [];

  surgeryTypes: any;
  dscDateString: any;
  calendarDetail: any;

  dateInvalid = false;
  bnoListLoading = false;
  whoListLoading = false;
  diagTypeListLoading = false;


  status: string;
  admTypeCategory: string;

  public date: Date;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: Date;
  public maxDate: Date;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  public stepHours = [1, 2, 3, 4, 5];
  public stepMinutes = [1, 5, 10, 15, 20, 25];
  public stepSeconds = [1, 5, 10, 15, 20, 25];

  form = this.fb.group({
    allBnoSurgery: this.fb.array([], Validators.required),
    allWhoSurgery: this.fb.array([], Validators.required)
  });


  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private sharedApiService: SharedApiService,
    private authService: AuthService,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit(): void {
    this.addBnoSurgery();
    console.log('req: ', this.reqDiagType);
    this.allBnoSurgery.controls[0].get('diagType').setValue(this.reqDiagType[0].id);
    this.allBnoSurgery.controls[0].get('diagType').disable();
    this.addWhoSurgery();

    this.getCalendarBno();
    this.getCalendarWho();

    this.getSurgeryType();
    this.getNosoEnv();
    this.getHealing();
    this.getLaterality();
    this.getQualification();
    this.getOenoType();
    this.getAnaestOeno();
  }


  get allBnoSurgery() {
    return this.form.controls['allBnoSurgery'] as FormArray;
  }


  get allWhoSurgery() {
    return this.form.controls['allWhoSurgery'] as FormArray;
  }

  addBnoSurgery() {
    const bnoSurgeryForm = this.fb.group({
      bnoList: [null, Validators.required],
      diagType: [null, Validators.required],
      laterality: [null]
    });

    this.allBnoSurgery.push(bnoSurgeryForm);
  }

  addWhoSurgery() {
    const whoSurgeryForm = this.fb.group({
      whoList: [null, Validators.required],
      surgeryType: [null, Validators.required],
      surgeryDate: [null, Validators.required],
      quantity: [1, [Validators.required, Validators.min(1)]],
      laterality: [null],
      healing: [null],
      qualification: [null],
      nosocInf: [false],
      nosocEnv: [null],
      oenoType: [null],
      anaestOeno: [null]
    });

    this.allWhoSurgery.push(whoSurgeryForm);
  }

  getSurgeryType() {
    const messageHeaderParam = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      }
    };

    this.apiService.getSurgeryType(messageHeaderParam).subscribe(res => {
      this.surgeryTypes = res.surgeryType;
    });
  }

  getNosoEnv() {
    const messageHeaderParam = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      }
    };
    this.apiService.getNosovEnv(messageHeaderParam).subscribe(res => {
      this.nosocEnv = res.nosoEnv;
    });
  }

  getHealing() {
    const messageHeaderParam = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      }
    };
    this.apiService.getHealing(messageHeaderParam).subscribe(res => {
      this.healing = res.healing;
    });
  }

  getLaterality() {
    const messageHeaderParam = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      }
    };
    this.apiService.getLaterality(messageHeaderParam).subscribe(res => {
      this.laterality = res.laterality;
    });
  }

  getQualification() {
    const messageHeaderParam = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      }
    };
    this.apiService.getQualification(messageHeaderParam).subscribe(res => {
      this.qualification = res.qualification;
    });
  }

  getOenoType() {
    const messageHeaderParam = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      }
    };
    this.apiService.getOenoType(messageHeaderParam).subscribe(res => {
      this.oenoType = res.oenoType;
    });
  }

  getAnaestOeno() {
    const messageHeaderParam = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      }
    };
    this.apiService.getAnaestOeno(messageHeaderParam).subscribe(res => {
      this.anaestOeno = res.anaestOeno;
    });
  }


  addSurgery(update) {

    let controlInvalid = false;

    if (this.allBnoSurgery.invalid) {
      this.saveResult.emit(-2);
      return;
    }

    if (this.allBnoSurgery.invalid) {
      this.saveResult.emit(-2);
      return;
    }

    this.allBnoSurgery.controls.forEach(
      control => {
        if (control.invalid) {
          controlInvalid = true;
          return;
        }
      });

    this.allWhoSurgery.controls.forEach(
      control => {
        if (control.invalid) {
          controlInvalid = true;
          return;
        }
      });

    if (controlInvalid) {
      this.saveResult.emit(-2);
      return;
    }

    this.saveInProgress.emit(true);

    const bnos = [];
    const whos = [];


    console.log('allBno:: ', this.allBno);


    this.allBnoSurgery.controls.forEach(
      control => {
        const bno = this.allBno.find(
          b => b.id === control.get('bnoList').value
        );

        let diagType = this.allDiagType.find(
          d => d.id === control.get('diagType').value
        );

        if (!diagType) {
          diagType = this.reqDiagType.find(
            d => d.id === control.get('diagType').value
          );
        }

        const laterality = control.get('laterality').value;
        bnos.push({ id: bno.id, code: bno.code, name: bno.name, codeType: 'BNO10', diagTypeId: diagType.id, diagTypeCode: diagType.code, laterality });
      });

    this.allWhoSurgery.controls.forEach(
      control => {
        const who = this.allWho.find(
          w => w.id === control.get('whoList').value
        );
        const surgeryType = control.get('surgeryType').value;

        const sd: Date = control.get('surgeryDate').value;

        let month = sd.getMonth().toString();
        if (sd.getMonth() < 10) {
          month = '0' + sd.getMonth().toString();
        }
        let day = sd.getMonth().toString();
        if (sd.getDate() < 10) {
          day = '0' + sd.getDate().toString();
        }

        let hour = sd.getHours().toString();
        if (sd.getHours() < 10) {
          hour = '0' + sd.getHours().toString();
        }

        let minute = sd.getMinutes().toString();
        if (sd.getMinutes() < 10) {
          minute = '0' + sd.getHours().toString();
        }

        const surgeryDate = sd.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':00';

        whos.push(
          {
            id: who.id,
            code: who.surgeryCode,
            name: who.name,
            codeType: 'OENO',
            quantity: control.get('quantity').value,
            surgeryDate,
            surgeryType,
            lateralityCode: control.get('laterality').value,
            healingCode: control.get('healing').value,
            qualCode: control.get('qualification').value,
            nInf: control.get('nosocInf').value,
            nosoCode: control.get('nosocEnv').value,
            oenoTypeCode: control.get('oenoType').value,
            anaestOenoCode: control.get('anaestOeno').value
          });
      });

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calendarId: this.sharedDataService.patientData['calendarFk'],
      bnos,
      whos,
      status: this.status
    };

    this.apiService.updateCalendarDetail(params).subscribe(
      res => {
        this.saveInProgress.emit(false);
        let code = 0;
        if (res.code === 1) {
          this.getCalendarBno();
          this.getCalendarWho();
          if (update) {
            this.getCalendarDetail();
          }
        } else {
          code = res.code;
        }

        this.saveResult.emit(code);
      });
  }


  updateEesztDetail() {
    this.addSurgery(true);
  }

  getCalendarDetail() {
    this.sharedApiService.getCalendarDetail(this.sharedDataService.patientData['calendarFk']).subscribe((responseData) => {

      if (responseData['code'] === 1) {
        const calendarDetail = responseData['data'];
        const dscDateString = this.sharedDataService.dateToDateTimeString(new Date());
        this.dscDateString = dscDateString;
        this.calendarDetail = calendarDetail;
        this.discardPatient();

      } else {
        // console.log(responseData);
      }
    });
  }

  discardPatient() {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calendarId: this.sharedDataService.patientData['calendarFk'],
      doCaseClose: true,
      eesztId: this.calendarDetail['eesztId'],
      providerSpId: this.calendarDetail['providerSpId'],
      providerUserId: this.calendarDetail['providerUserId'],
      admDate: this.calendarDetail['admDate'].replace(' ', 'T'),
      dscDate: this.dscDateString,
      appNumber: this.calendarDetail['appNumber'],
      appNumberRn: this.calendarDetail['appNumber'],
      admMode: this.calendarDetail['admMode'],
      admType: this.calendarDetail['admType'],
      dscType: this.calendarDetail['dscType']
    };

    this.apiService.discardPatient(params).subscribe(
      response => {

      });
  }


  getCalendarBno() {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calendarId: this.sharedDataService.patientData['calendarFk']
    };

    this.apiService.getCalendarBno(params).subscribe(
      res => {
        this.savedBnos = res?.bnos;
        this.savedBnosChanged.emit(this.savedBnos);

        if (this.savedBnos && this.savedBnos.length > 0) {
          const reqDiagType = this.savedBnos.filter(bno => bno.diagTypeCode === '3');

          this.allBnoSurgery.controls[0].get('bnoList').setValue(reqDiagType[0].id);
          this.allBnoSurgery.controls[0].get('laterality').setValue(reqDiagType[0]?.laterality);

          this.savedBnos = this.savedBnos.filter(bno => bno.diagTypeId !== '3');

          for (let i = 1; i < this.savedBnos.length; i++) {
            if (this.savedBnos.length !== this.allBnoSurgery.length) {
              this.addBnoSurgery();
            }
            this.allBnoSurgery.controls[i].get('diagType').setValue(this.savedBnos[i].diagTypeId);
            this.allBnoSurgery.controls[i].get('bnoList').setValue(this.savedBnos[i].id);
            this.allBnoSurgery.controls[i].get('laterality').setValue(this.savedBnos[i]?.laterality);
          }
        }
      });
  }

  getCalendarWho() {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calendarId: this.sharedDataService.patientData['calendarFk']
    };

    this.apiService.getCalendarWho(params).subscribe(
      res => {
        this.savedWhos = res.whos;
        if (this.savedWhos && this.savedWhos.length > 0) {
          for (let i = 0; i < this.savedWhos.length; i++) {
            if (i !== 0 && this.savedWhos.length !== this.allWhoSurgery.length) {
              this.addWhoSurgery();
            }
            const surgeryDate = new Date(this.savedWhos[i].surgeryDate);
            surgeryDate.setMonth(surgeryDate.getMonth() + 1);

            this.allWhoSurgery.controls[i].get('whoList').setValue(this.savedWhos[i].id);
            this.allWhoSurgery.controls[i].get('quantity').setValue(this.savedWhos[i].quantity);
            this.allWhoSurgery.controls[i].get('surgeryDate').setValue(surgeryDate);
            this.allWhoSurgery.controls[i].get('surgeryType').setValue(this.savedWhos[i].surgeryType);
            this.allWhoSurgery.controls[i].get('laterality').setValue(this.savedWhos[i].lateralityCode);
            this.allWhoSurgery.controls[i].get('healing').setValue(this.savedWhos[i].healingCode);
            this.allWhoSurgery.controls[i].get('qualification').setValue(this.savedWhos[i].qualCode);
            this.allWhoSurgery.controls[i].get('nosocEnv').setValue(this.savedWhos[i].nosoCode);
            this.allWhoSurgery.controls[i].get('nosocInf').setValue(this.savedWhos[i].nInf);
            this.allWhoSurgery.controls[i].get('anaestOeno').setValue(this.savedWhos[i].anaestCode);
            this.allWhoSurgery.controls[i].get('oenoType').setValue(this.savedWhos[i].oenoTypeCode);
          }
        }
        this.savedWhosChanged.emit(this.savedWhos);
      });
  }

  checkAnaestIsReq(i: number) {
    const who = this.allWho.find(w => w.id === this.allWhoSurgery.controls[i].get('whoList').value);

    if (who.onlyAnaest === '1') {
      this.allWhoSurgery.controls[i].get('anaestOeno').setValidators([Validators.required]);
      this.allWhoSurgery.controls[i].get('anaestOeno').updateValueAndValidity();
    }
  }

  removeBno(index: number) {
    if (this.allBnoSurgery.length === 1) {
      this.allBnoSurgery.reset();
      return;
    }
    this.allBnoSurgery.removeAt(index);
  }
  removeWho(index: number) {
    if (this.allWhoSurgery.length === 1) {
      this.allWhoSurgery.reset();
      return;
    }
    this.allWhoSurgery.removeAt(index);
  }

  toggleMinDate(evt: any) {
    if (evt.checked) {
      this._setMinDate();
    } else {
      this.minDate = null;
    }
  }

  toggleMaxDate(evt: any) {
    if (evt.checked) {
      this._setMaxDate();
    } else {
      this.maxDate = null;
    }
  }

  closePicker() {
    this.picker.cancel();
  }

  private _setMinDate() {
    const now = new Date();
    this.minDate = new Date();
    this.minDate.setDate(now.getDate() - 1);
  }


  private _setMaxDate() {
    const now = new Date();
    this.maxDate = new Date();
    this.maxDate.setDate(now.getDate() + 1);
  }

}
