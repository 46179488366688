<div class="noselect" id="scheduleBody">
    <div class="row scheduler-table">
        <button class="col-sm-2 btn btn-primary rounded-0 d-flex w-100" *ngIf="!showSettings" (click)="showSettings=true">
        <fa-icon class="mx-auto my-auto" [icon]="faCog"></fa-icon>
      </button>
        <button class="col-sm-2 btn btn-primary rounded-0 d-flex w-100" *ngIf="showSettings" (click)="showSettings=false">
        <fa-icon class="mx-auto my-auto" [icon]="faCalendarAlt"></fa-icon>
      </button>
        <div *ngFor="let day of weekScheduleDay; let i = index" [ngClass]="hoveredCell.dayName === day.name ? 'highlightedElement' : ''" class="col-sm day-cell text-center">
            {{day.name}}
        </div>
    </div>
    <div *ngIf="!showSettings" class="row">
        <div class="col-sm-2">
            <div class="row" *ngFor="let hour of dayHours;">
                <div [ngClass]="hoveredCell.hour === hour ? 'highlightedElement hour-cell d-flex w-100' : 'hour-cell d-flex w-100'">
                    <span class="ml-auto mr-auto mb-auto" *ngIf="hour >= 10">{{hour}}:00</span>
                    <span class="ml-auto mr-auto mb-auto" *ngIf="hour < 10">0{{hour}}:00</span>
                </div>
            </div>
        </div>
        <div class="col-sm" *ngFor="let day of week; let z = index">
            <ng-container *ngFor="let cell of week[z].cells; let isLast = last; let cellIndex = index;">
                <div *ngIf="week[z].cells[cellIndex].name !== 'B'" class="row cellContent" [ngClass]="[((cellIndex+1)*day.timeStamp)%60 === 0 && (week[z].cells[cellIndex].name === 'F' ||  week[z].cells[cellIndex].name === 'A') ? 'lastIndexOfHour' : week[z].cells[cellIndex].name === 'F' || week[z].cells[cellIndex].name === 'A' ? 'cellElement' : '',
                      week[z].cells[cellIndex].bookStart && week[z].cells[cellIndex].bookEnd ? 'startAndEndCell' :
                        week[z].cells[cellIndex].bookStart ? 'startCell' :
                          week[z].cells[cellIndex].bookEnd && (!isLast &&  (week[z].cells[cellIndex+1].name === 'F' || week[z].cells[cellIndex+1].name === 'A')) ? 'endCell' :
                            week[z].cells[cellIndex].name !== 'F' ? 'innerCell' : '',
                      week[z].cells[cellIndex].name === 'CO' ? 'coCell' :
                      week[z].cells[cellIndex].name === 'T' ? 'tempCell' :
                        (week[z].cells[cellIndex].name === 'A' ? 'availableCell' :
                          (week[z].cells[cellIndex].name === 'S' ? 'selectedCell' : 'freeCell')),
                      isCellCurrentTime(week[z].cells[cellIndex]) ? 'current-time-marker' : '']" [ngStyle]="{'height': 'calc(100% /' + week[z].cells.length + ')'}" (mouseover)="setCellByHover(cellIndex, z); hoveredCell={dayName: week[z].name, hour: week[z].cells[cellIndex].hour}"
                    (mousedown)="setCellByClick(cellIndex, z)">
                    <!-- <span *ngIf="week[z].cells[cellIndex].name === 'B'" style="position: absolute; top: 0; right: 0;">
            <fa-icon style="cursor: pointer;" *ngIf="week[z].cells[cellIndex].bookStart" [ngClass]="{'mr-1': week[z].cells[cellIndex].displayData}" (click)="deleteBooking(week[z].cells[cellIndex].displayData)" [icon]="faTrash"></fa-icon>
            <fa-icon style="cursor: pointer;" *ngIf="week[z].cells[cellIndex].displayData && week[z].cells[cellIndex].bookStart" (click)="showData(week[z].cells[cellIndex].displayData)" [icon]="faInfo"></fa-icon>
          </span> -->
                </div>
                <div *ngIf="!fromBooking && week[z].cells[cellIndex].name === 'B'" class="row cellContent" [ngClass]="[((cellIndex+1)*day.timeStamp)%60 === 0 && (week[z].cells[cellIndex].name === 'F' ||  week[z].cells[cellIndex].name === 'A') ? 'lastIndexOfHour' : week[z].cells[cellIndex].name === 'F' || week[z].cells[cellIndex].name === 'A' ? 'cellElement' : '',
                      week[z].cells[cellIndex].bookStart && week[z].cells[cellIndex].bookEnd ? 'startAndEndCell' :
                        week[z].cells[cellIndex].bookStart ? 'startCell' :
                          week[z].cells[cellIndex].bookEnd && (!isLast &&  (week[z].cells[cellIndex+1].name === 'F' || week[z].cells[cellIndex+1].name === 'A')) ? 'endCell' :
                            week[z].cells[cellIndex].name !== 'F' ? 'innerCell' : '',
                      week[z].cells[cellIndex].name === 'B' ? 'bookedCell' : '',
                      isCellCurrentTime(week[z].cells[cellIndex]) ? 'current-time-marker' : '']" [ngStyle]="{'height': 'calc(100% /' + week[z].cells.length + ')'}" (mouseover)="setCellByHover(cellIndex, z); hoveredCell={dayName: week[z].name, hour: week[z].cells[cellIndex].hour}"
                    (mousedown)="setCellByClick(cellIndex, z)" data-toggle="modal" data-target="#idopontkezelo" (click)="setAppointmentData()">
                </div>
                <div *ngIf="fromBooking && week[z].cells[cellIndex].name === 'B'" class="row cellContent" [ngClass]="[((cellIndex+1)*day.timeStamp)%60 === 0 && (week[z].cells[cellIndex].name === 'F' ||  week[z].cells[cellIndex].name === 'A') ? 'lastIndexOfHour' : week[z].cells[cellIndex].name === 'F' || week[z].cells[cellIndex].name === 'A' ? 'cellElement' : '',
                      week[z].cells[cellIndex].bookStart && week[z].cells[cellIndex].bookEnd ? 'startAndEndCell' :
                        week[z].cells[cellIndex].bookStart ? 'startCell' :
                          week[z].cells[cellIndex].bookEnd && (!isLast &&  (week[z].cells[cellIndex+1].name === 'F' || week[z].cells[cellIndex+1].name === 'A')) ? 'endCell' :
                            week[z].cells[cellIndex].name !== 'F' ? 'innerCell' : '',
                      week[z].cells[cellIndex].name === 'B' ? 'bookedCell' : '',
                      isCellCurrentTime(week[z].cells[cellIndex]) ? 'current-time-marker' : '']" [ngStyle]="{'height': 'calc(100% /' + week[z].cells.length + ')'}">
                </div>
            </ng-container>
        </div>
    </div>
    <div *ngIf="showSettings" [formGroup]="settingsFormGroup">
        <div class="row settingsWindow d-flex justify-content-around">
            <div class="col-sm-5">
                <label>kezdés</label>
                <ng-select class="w-100" formControlName="startTimeFormControl" [closeOnSelect]="true" [items]="timeOptions" [clearable]="false" (change)="updateDateSubject();">
                </ng-select>
            </div>
            <div class="col-sm-5">
                <label>zárás</label>
                <ng-select class="w-100" formControlName="endTimeFormControl" [closeOnSelect]="true" [items]="timeOptions" [clearable]="false" (change)="updateDateSubject();">
                </ng-select>
            </div>
        </div>
    </div>
</div>