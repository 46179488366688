import { BusinessContent, BeutaloOrvos } from './../shared/models/response/searchEbeuResponse';
import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { SharedDataService } from '../services/shared-data.service';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SharedApiService } from '../services/shared-api.service';
import { CalendarComponent } from '../calendar/calendar.component';
import { ChangeDetectorRef } from '@angular/core';
import { faCopy, faTrash } from '@fortawesome/free-solid-svg-icons';
import { SearchEbeuData } from '../shared/models/response/searchEbeuResponse';
import { ToastrService } from 'ngx-toastr';
import { PatientEbeuRequest } from '../shared/models/request/patientEbeuRequest';
import { ApiService } from '../core/http/api.service';


@Component({
  selector: 'app-lab-order',
  templateUrl: './lab-order.component.html',
  styleUrls: ['./lab-order.component.css']
})
export class LabOrderComponent implements OnInit {

  apiUrl = environment.apiUrl;

  labExamListSubscription: Subscription;
  labExamList = [];
  labExamListLoading = true;
  selectedLabExams = [];
  labExamIdChangeSubscription: Subscription;
  labExamNameChangeSubscription: Subscription;
  ssnSearchChangeSubscription: Subscription;
  lantszChangeSubscription: Subscription;

  standaloneExamItemList = [];
  invoiceItemList = [];

  getSpSubscription: Subscription;
  getDoctorToSpSubscription: Subscription;
  tajSearchSubscription: Subscription;

  newantsz = false;
  newInstitute = false;
  existingDoctor = false;

  therapyRequired = false;

  newPatient = false;
  userData = [];
  calendarDetail = [];

  operationInProgress = false;
  operationDescription = '';

  previousLaborder = false;

  lnbo1ChangeSubscription: Subscription;
  lnbo2ChangeSubscription: Subscription;
  lnbo3ChangeSubscription: Subscription;
  lnbo4ChangeSubscription: Subscription;
  lnbo5ChangeSubscription: Subscription;

  lpecsetszamNoChange = false;

  dateInvalid = true;

  dateChangeSubscription: Subscription;

  labExamListExtExamCodeMap = [];

  labPanelListSubscription: Subscription;
  labPanelList = [];
  labPanelListLoading = true;

  selectedPanels = [];
  invoicePanelList = [];
  selectedExamCount = 0;

  activeTab = 0;
  saveInProgress = false;
  tajSearchLoading = false;

  lpecsetszamChangeSubscription: Subscription;

  terkatItems = [
    { name: 'NEAK finanszírozott', code: '1' },
    // { name: 'OEP finanszírozott', code: '1' },
    { name: 'Térítéses', code: '4' }
  ];
  saveErrorDescription = '';
  saveResult = null;

  totalPrice = 0;
  displayedColumns = ['examName', 'examCode', 'grossPrice'];

  lssnSearch = new FormControl('', [this.TAJvalidator]);
  lsst = new FormControl('1');
  lbirthname = new FormControl('', [Validators.required, Validators.minLength(4)]);
  lname = new FormControl('', [Validators.required, Validators.minLength(4)]);
  lbirthplace = new FormControl('', [Validators.required, Validators.minLength(2)]);
  ldateofbirth = new FormControl('', [Validators.required]);
  lmothersname = new FormControl('', [Validators.required, Validators.minLength(4)]);
  lgender = new FormControl(''); //, [Validators.required]);
  lnationality = new FormControl(''); // , [Validators.required]);
  lcountry = new FormControl(''); // , [Validators.required]);
  lzip = new FormControl(''); // , [Validators.required, Validators.minLength(4)]);
  lcity = new FormControl(''); //, [Validators.required, Validators.minLength(2)]);
  laddress = new FormControl(''); //, [Validators.required, Validators.minLength(4)]);
  lelltip = new FormControl('O', [Validators.required]);
  lpecsetszam = new FormControl('', [Validators.required, Validators.minLength(5)]);
  lorvos = new FormControl('', [Validators.required, Validators.minLength(4)]);
  lantszselect = new FormControl('', [Validators.required]);
  lantsz = new FormControl('');
  lintezmeny = new FormControl('');
  lterkat = new FormControl('1', [Validators.required]);
  // lbeutaloKelte = new FormControl('', [this.beutaloValidator]); // , [this.beutaloValidator]
  lbeutaloKelte = new FormControl(''); // , [this.beutaloValidator]
  lnaploszam = new FormControl('', [Validators.required]);
  lcomment = new FormControl('');
  lsurgos = new FormControl('');
  lfizetos = new FormControl('');

  lterapia = new FormControl('');
  referralIdentification = new FormControl(null);


  lterapiaGroup = new FormGroup({
    'lterapia': this.lterapia
  });


  bno = [
    new FormControl(null, Validators.required),
    new FormControl(),
    new FormControl(),
    new FormControl(),
    new FormControl()
  ];


  labOrderDetails = new FormGroup({
    'lssnSearch': this.lssnSearch,
    'lsst': this.lsst,
    'lbirthname': this.lbirthname,
    'lname': this.lname,
    'lbirthplace': this.lbirthplace,
    'ldateofbirth': this.ldateofbirth,
    'lmothersname': this.lmothersname,
    'lgender': this.lgender,
    'lnationality': this.lnationality,
    'lcountry': this.lcountry,
    'lzip': this.lzip,
    'lcity': this.lcity,
    'laddress': this.laddress,
    'lelltip': this.lelltip,
    'lpecsetszam': this.lpecsetszam,
    'lorvos': this.lorvos,
    'lantszselect': this.lantszselect,
    'lantsz': this.lantsz,
    'lintezmeny': this.lintezmeny,
    'lterkat': this.lterkat,
    'lbeutaloKelte': this.lbeutaloKelte,
    'lnaploszam': this.lnaploszam,
    'lcomment': this.lcomment,
    'lsurgos': this.lsurgos,
    'lfizetos': this.lfizetos,
    'bno0': this.bno[0],
    'bno1': this.bno[1],
    'bno2': this.bno[2],
    'bno3': this.bno[3],
    'bno4': this.bno[4],
    'referralIdentification': this.referralIdentification
  });

  lintezmenyLista = [];
  doctorLoading = false;
  spLoading = false;

  bno1Valid = null;
  bno2Valid = null;
  bno3Valid = null;
  bno4Valid = null;
  bno5Valid = null;

  countryList = environment.countryList;

  patientDataValue = null;
  isLoading = false;

  patientDob = '';
  patientName = '';
  patientMothersName = '';
  patientSsn = '';


  labOrderSelect = new FormControl('');

  labOrderGroup = new FormGroup({
    'labOrderSelect': this.labOrderSelect
  });


  bnoListSubscription: Subscription;
  bnoList = [];
  bnoListLoading = true;

  bnoItemList = [];
  bnoItemListFiltered = [];
  bnoItemListLoading = [false, false, false, false, false];
  bnoItemListCat = [];

  // #1_NINCS~Nincs
  // #2_SYNCUMAR~Syncumar
  // #3_KONVHEP~Konvencionális heparin
  // #4_KISHEP~Kis molsúlyu (frakcionált) heparin
  // #5_WARFARIN~Warfarin
  // #6_FIBRINOL~Fibrinolitikus
  // #7_EGYEB~Egyéb

  lTerapiaTipusok = [
    { code: '1_NINCS', name: 'Nincs' },
    { code: '2_SYNCUMAR', name: 'Syncumar' },
    { code: '3_KONVHEP', name: 'Konvencionális heparin' },
    { code: '4_KISHEP', name: 'Kis molsúlyú (frakcionált) heparin' },
    { code: '5_WARFARIN', name: 'Warfarin' },
    { code: '6_FIBRINOL', name: 'Fibrinolitikus' },
    { code: '7_EGYEB', name: 'Egyéb' }
  ];

  nauRequestId = null;

  previousLaborderList = [];



  faTrash = faTrash;
  faCopy = faCopy;

  showPatientBeuFields = false;
  showInstitutionalBeuFields = false;
  eBeutaloSzoveg = null;

  patientEbeuList: Array<SearchEbeuData> = null;
  displayedColumnsEBEU: string[] = ["patientName", "patientSsn", "organizationName", "doctorName", "docStampNo", "createDate", "expirationDate", "copyReferralId"];

  patientDateFrom = new FormControl(null, Validators.required);
  patientDateTo = new FormControl(null, Validators.required);
  breakGlass = new FormControl(null);
  breakGlassReason = new FormControl(null);
  patientBeuFormGroup = new FormGroup({
    'patientDateFrom': this.patientDateFrom,
    'patientDateTo': this.patientDateTo,
    'breakGlass': this.breakGlass,
    'breakGlassReason': this.breakGlassReason
  });

  showPatientBeuFormControl = new FormControl(false);
  showInstitutionalBeuFormControl = new FormControl(false);

  ehrDetailsInProgress: boolean;








  @Input() set patientData(data) {
    // console.log('laborder Input data', data);

    if (data !== null && data['calendarId']) {
      // console.log('data 2');
      data['calendarFk'] = data['calendarId'];
      if (data['patientId'] > 0) {
        // console.log('data 3');
        data['registeredPatient'] = true;
      }
    }

    // console.log('data 4', data);

    if (data !== null && data['calendarFk']) {
      // console.log('data5', data);

      this.patientDataValue = data;
      this.isLoading = true;
      // console.log('lab order patientDataValue:', this.patientDataValue);



      if (data['registeredPatient']) {
        // console.log('data 4');
        if (this.ssnSearchChangeSubscription) {
          this.ssnSearchChangeSubscription.unsubscribe();
        }

        this.getLaborderList(data['calendarFk']).subscribe((orderResponseData) => {
          this.isLoading = false;

          if (orderResponseData['code'] === 1) {
            // console.log('+++++++++++++++++:');
            if (orderResponseData['labOrderList']['labOrderList'] && orderResponseData['labOrderList']['labOrderList'].length > 0) {
              orderResponseData['labOrderList']['labOrderList'].forEach(element => {
                element.visibleTitle = element.rn +'. ' + element.nauRequestId;
              });
              this.previousLaborder = true;
              this.previousLaborderList = orderResponseData['labOrderList']['labOrderList'];
              this.previousLaborderList.push({ visibleTitle: 'Új vizsgálatkérés' });
              // console.log('Existing lab order data:', orderResponseData );
              this.activeTab = 2;
            } else {
              this.previousLaborder = false;
              this.previousLaborderList = [];
            }
          }
        });


        this.getCalendarDetail(data['calendarFk']).subscribe((responseData) => {
          this.isLoading = false;
          if (responseData['code'] === 1) {
            this.calendarDetail = responseData['data'];
            // this.lbirthname.setValue(responseData['data']['participantSurname']);
            const event = { tapass: responseData['data']['participantTapass'] };

            this.sharedApiService.getUserSimple(responseData['data']['participantTapass']).subscribe(
              (userData: Response) => {
                if (userData['code'] === 1) {

                  userData['user']['street'] = userData['user']['street'] ? userData['user']['street'] : '';

                  userData['user']['street'] += (userData['user']['streetType'] ? ' ' + userData['user']['streetType'] : '') +
                    (userData['user']['houseNumber'] ? ' ' + userData['user']['houseNumber'] : '') +
                    (userData['user']['floor'] ? ' ' + userData['user']['floor'] : '') +
                    (userData['user']['door'] ? '/' + userData['user']['door'] : '');

                  this.lssnSearch.setValue(userData['user']['socialSecurityNumber']);
                  // console.log('user', userData['user']);
                  // console.log('ssn', userData['user']['socialSecurityNumber']);
                  this.lssnSearch.markAsDirty();
                  this.lsst.setValue(userData['user']['socialSecurityTypeCode']);
                  // console.log('(userData[user][surname])', (userData['user']['surname']));
                  this.lname.setValue(userData['user']['surname']);
                  this.lbirthname.setValue(userData['user']['birthSurname']);
                  this.ldateofbirth.setValue(this.sharedDataService.dateStringToNgbDate(userData['user']['dateOfBirth']));
                  this.lbirthplace.setValue(userData['user']['placeOfBirth']);
                  this.lmothersname.setValue(userData['user']['mothersName']);
                  this.lgender.setValue(userData['user']['gender']);
                  this.lnationality.setValue(userData['user']['citizen3Digit']);
                  this.lcountry.setValue(userData['user']['country3Digit']);
                  this.lzip.setValue(userData['user']['zipCode']);
                  this.lcity.setValue(userData['user']['settlement']);
                  this.laddress.setValue(userData['user']['street']);
                  this.userData = userData['user'];
                  // console.log('labOrder userData set');

                  const forename = userData['user']['forename'] ? ' ' + userData['user']['forename'] : '';
                  const forename2 = userData['user']['forename2'] ? ' ' + userData['user']['forename2'] : '';

                  this.patientDob = userData['user']['dateOfBirth'];
                  this.patientName = userData['user']['surname'] + forename + forename2;
                  this.patientSsn = userData['user']['socialSecurityNumber'];
                  this.patientMothersName = userData['user']['mothersName'];
                } else {
                  // hiba
                }
                this.ssnSearchSubscribe();
              }
            );


          } else {
            // console.log('Error loading patient details calendarId: ' + data['calendarFk']);
            // console.log(responseData);
            this.ssnSearchSubscribe();
          }
        });
      } else {
        // alias-os foglalas
        if (data['participantDetail'] && data['participantDetail'].length > 8) {
          // check if alias contains TAJ
          const tajToCheck = data['participantDetail'].substring(0, 9);

          if (this.validateTAJ(tajToCheck)) {
            // console.log ('valid TAJ');
            this.lsst.setValue(1);
            this.lssnSearch.reset(tajToCheck);
            this.lssnSearch.markAsDirty();
          } else {
            // console.log ('invalid TAJ 1');
          }
        } else {
          // console.log ('invalid TAJ 2');
        }
        // console.log('Lab order alias-os foglalas!');
      }
    }
  }

  bnoTextInput0 = new Subject<string | null>();
  bnoTextInput1 = new Subject<string | null>();
  bnoTextInput2 = new Subject<string | null>();
  bnoTextInput3 = new Subject<string | null>();
  bnoTextInput4 = new Subject<string | null>();

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    private calendarComponent: CalendarComponent,
    private toastr: ToastrService,
    private sharedDataService: SharedDataService,
    private apiService: ApiService,
    private sharedApiService: SharedApiService,
    private ref: ChangeDetectorRef
  ) { }

  @ViewChild('labExamNameSearchField') labExamNameSearchField: ElementRef;
  @ViewChild('labExamIdSearchField') labExamIdSearchField: ElementRef;

  labExamId = new FormControl('');
  labExamName = new FormControl('');

  labExamSelect = new FormGroup({
    labExamId: this.labExamId,
    labExamName: this.labExamName
  });

  labExamIdSearch = null;
  labExamNameSearch = null;
  filteredLabExams = [];
  filteredLabExam = false;
  selectedLabExamItemList = [];

  today = {};

  terkatChangeSub: Subscription;

  ebeuDataCopy = false;
  ebeuDataCopy2 = false;

  ngOnInit() {

    this.bnoTextInput0.pipe(debounceTime(200)).subscribe((bnoFragment) => { this.handleBnoTextInput(bnoFragment, 0); });
    this.bnoTextInput1.pipe(debounceTime(200)).subscribe((bnoFragment) => { this.handleBnoTextInput(bnoFragment, 1); });
    this.bnoTextInput2.pipe(debounceTime(200)).subscribe((bnoFragment) => { this.handleBnoTextInput(bnoFragment, 2); });
    this.bnoTextInput3.pipe(debounceTime(200)).subscribe((bnoFragment) => { this.handleBnoTextInput(bnoFragment, 3); });
    this.bnoTextInput4.pipe(debounceTime(200)).subscribe((bnoFragment) => { this.handleBnoTextInput(bnoFragment, 4); });

    this.terkatChangeSub = this.lterkat.valueChanges.subscribe(value => {
      this.terkatChangeEvent(value);
    });

    // console.log('bno', this.bno);

    const todayDate = new Date();
    this.today = { year: todayDate.getFullYear(), month: (todayDate.getMonth() + 1), day: todayDate.getDate() };

    this.labExamListSubscription = this.sharedDataService.labExamListShared.subscribe(data => {

      this.labExamList = data['data'];
      this.labExamListLoading = data['isLoading'];
      // console.log('labExamList', this.labExamList);

      this.labExamListExtExamCodeMap = [];
      this.labExamList.forEach((row, rowIndex) => {
        row.data.forEach((item, itemIndex) => {
          if (item.extExamCode) {
            this.labExamListExtExamCodeMap[item.examId] = item.extExamCode;
          }
        });
      });
      // console.log ('labExamListExtExamCodeMap:', this.labExamListExtExamCodeMap);
    });

    this.labPanelListSubscription = this.sharedDataService.labPanelListShared.subscribe(data => {
      this.labPanelList = data['data'];
      this.labPanelListLoading = data['isLoading'];
      // console.log('labPanelList', this.labExamList);
    });


    this.ssnSearchSubscribe();


    this.lpecsetszamChangeSubscription = this.lpecsetszam.valueChanges.pipe(
      debounceTime(50)
    ).subscribe(value => {
      // console.log('pecsetszam change:', value);
      // console.log ('ebeuDataCopy', this.ebeuDataCopy);
      if (this.ebeuDataCopy !== true) {
        if (this.lpecsetszam.valid === true) {
          // console.log('findPecsetszam:', value);
          this.onGetDoctorToSp(value, null);
        } else {
          this.lorvos.reset();
          this.lantsz.reset();
          this.lintezmeny.reset();
          // console.log('intezmeny reset');
        }
      } else {
        this.ebeuDataCopy = false;
      }
    });

    this.lantszChangeSubscription = this.lantsz.valueChanges.pipe(
      debounceTime(500)
    ).subscribe(value => {
      // console.log('lantsz change:', value);
      if (this.ebeuDataCopy2 !== true)  {

        if (this.lantsz.valid === true) {
          // console.log('lantsz:', value);
          this.onGetSp(value);
        } else {
          this.lintezmeny.reset();
        }
      } else {
        this.ebeuDataCopy2 = false;
      }
    });

    this.labExamIdChangeSubscription = this.labExamId.valueChanges.pipe(
      debounceTime(50)
    ).subscribe(value => {
      if (value !== '' && value !== null) {
        this.labExamName.reset();
        this.labExamIdSearch = value;
        // console.log('labExamIdSearch', this.labExamIdSearch);
      } else {
        this.labExamIdSearch = null;
        // console.log('labExamIdSearch clear', this.labExamIdSearch);
      }
    });

    this.labExamNameChangeSubscription = this.labExamName.valueChanges.pipe(
      debounceTime(50)
    ).subscribe(value => {
      // console.log('name search');
      const filteredLabExamList = [];
      if (value !== '' && value !== null) {
        this.labExamId.reset();
        this.labExamList.forEach((row, rowIndex) => {
          row.data.forEach((item, itemIndex) => {
            if (item.examCode) {
              if (item.examCode.toLowerCase().indexOf(value.toLowerCase()) === 0 ||
                item.examName.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
                // console.log('item found id:', itemIndex);
                filteredLabExamList.push(item.extExamCode);
              }
            }
          });
        });

        this.filteredLabExams = filteredLabExamList;
        this.filteredLabExam = true;

        // console.log('lab exams filtered list', this.filteredLabExams);

      } else {
        this.filteredLabExam = false;
        this.filteredLabExams = [];
        // console.log('labExamNameSearch clear', this.labExamNameSearch);
      }
    });


    this.bnoList = this.sharedDataService.bnoList['data'];
    // console.log ('Base bno list base load', this.bnoList);
    this.bnoListLoading = this.sharedDataService.bnoList['isLoading'];
    this.bnoListSubscription = this.sharedDataService.bnoListShared.subscribe(data => {
      // console.log ('BNO list shared arrived', data);
      this.bnoList = data['data'];
      this.bnoListLoading = data['isLoading'];
    });




    this.dateChangeSubscription = this.lbeutaloKelte.valueChanges.pipe(
      debounceTime(1000)
    ).subscribe(value => {
      // console.log ('beutaloKelte valuechanged');
      if (value !== '' && value !== null && !isNaN(value.year)) {
        // console.log('1 date change value:', value);
        // console.log('2 date change value elements:', value.year + '-' + value.month + '-' + value.day);

        if (value && value.year !== undefined) { // valid NgbDate is set

          const dateString = value.year + '-' + ('0' + (value.month)).slice(-2) + '-' + ('0' + value.day).slice(-2);
          const dateFromValue = new Date(dateString);
          // console.log('3 dateFromValue:', dateFromValue);

          // this.sharedDataService.setCurrentDate(dateFromValue);
          this.dateInvalid = false;


        } else {
          let dateValue = '';
          if (value) {
            dateValue = value.replace(/\./g, '-');
          }
          // console.log('4 dateValue', dateValue);

          const dateObject = this.sharedDataService.dateFromUserinput(dateValue);

          // console.log ('5 dateObject', dateObject);

          if (dateObject && !isNaN(dateObject.getTime())) {
            const newDate = this.sharedDataService.dateToDateString(dateObject);
            // console.log('6 ngbDate converted:', newDate);
            // this.sharedDataService.setCurrentDate(dateObject);
            this.lbeutaloKelte.setValue(this.sharedDataService.dateStringToNgbDate(newDate));
            this.dateInvalid = false;
          } else {
            // console.log('7 date not set, date is invalid');
            this.dateInvalid = true;
          }
        }
        // else {
        // console.log('9 date not set, date is invalid');
        //      this.dateInvalid = true;
        // }
      }
    });

  }

  terkatChangeEvent(value) {
    // console.log('terkatChangeEvent', value);
    if (value == 1) {
      this.lssnSearch.setValidators([this.TAJvalidator]);
      this.laddress.setValidators([Validators.required, Validators.minLength(4)]);
      this.lbirthplace.setValidators([Validators.required, Validators.minLength(2)]);
      this.lmothersname.setValidators([Validators.required, Validators.minLength(4)]);
      this.lcity.setValidators([Validators.required, Validators.minLength(2)]);
      this.lbeutaloKelte.setValidators([this.beutaloValidator]);
      this.lnaploszam.setValidators([Validators.required]);
      this.bno[0].setValidators([Validators.required]);
      this.lpecsetszam.setValidators([Validators.required, Validators.minLength(5)]);
      this.lorvos.setValidators([Validators.required, Validators.minLength(4)]);
      this.lantszselect.setValidators([Validators.required]);

      this.lssnSearch.updateValueAndValidity();
      this.laddress.updateValueAndValidity();
      this.lbirthplace.updateValueAndValidity();
      this.lmothersname.updateValueAndValidity();
      this.lcity.updateValueAndValidity();
      this.lbeutaloKelte.updateValueAndValidity();
      this.lnaploszam.updateValueAndValidity();
      this.bno[0].updateValueAndValidity();
      this.lpecsetszam.updateValueAndValidity();
      this.lorvos.updateValueAndValidity();
      this.lantszselect.updateValueAndValidity();

    } else if (value == 4) {
      this.lssnSearch.clearValidators();
      this.laddress.clearValidators();
      this.lbirthplace.clearValidators();
      this.lmothersname.clearValidators();
      this.lcity.clearValidators();
      this.lbeutaloKelte.clearValidators();
      this.lnaploszam.clearValidators();
      this.bno[0].clearValidators();
      this.lpecsetszam.clearValidators();
      this.lorvos.clearValidators();
      this.lantszselect.clearValidators();

      this.lssnSearch.updateValueAndValidity();
      this.laddress.updateValueAndValidity();
      this.lbirthplace.updateValueAndValidity();
      this.lmothersname.updateValueAndValidity();
      this.lcity.updateValueAndValidity();
      this.lbeutaloKelte.updateValueAndValidity();
      this.lnaploszam.updateValueAndValidity();
      this.bno[0].updateValueAndValidity();
      this.lpecsetszam.updateValueAndValidity();
      this.lorvos.updateValueAndValidity();
      this.lantszselect.updateValueAndValidity();
      // console.log ('lpecsetszam', this.lpecsetszam.value);
      if (this.lpecsetszam.value == '' || this.lpecsetszam.value == null) {
        this.lpecsetszam.setValue('00000');
        this.ebeuDataCopy = true;
        this.lorvos.setValue('FIZETŐS');
        this.lantszselect.setValue({ spCode: -1, spName: 'Más intézmény', intezmenyDisplayName: 'Más intézmény' });
        this.antszChange();
        this.lantsz.setValue('960003333');
        this.ebeuDataCopy2 = true;
        this.lintezmeny.setValue('Egészségkártya Medical Kft.');
        this.lpecsetszam.updateValueAndValidity();

      }
      // console.log ('lnaploszam', this.lnaploszam.value);
      if (this.lnaploszam.value == '' || this.lnaploszam.value == null) {
        this.lnaploszam.setValue('0');
      }
    }
    // console.log('this.labOrderDetails', this.labOrderDetails);
  }

  handleBnoTextInput(bnoFragment: string, id) {
    bnoFragment = bnoFragment.toUpperCase();
    let bnoSearch = bnoFragment + '0000';
    bnoSearch = bnoSearch.slice(0, 5);

    // console.log('bnoFragment', bnoFragment);
    // console.log('BNO mainGroup', this.findBNOmainGroup(bnoSearch));
    const bnoSubGrp = this.findBNOmainGroup(bnoSearch);
    if (bnoSubGrp['id']) {
      if (!this.bnoItemListCat[id] || this.bnoItemListCat[id] !== bnoSubGrp) {
        //  console.log('+++ trigger BNO reload');
        this.bnoItemListCat[id] = bnoSubGrp;
        this.onChangeBNOCategory(bnoSubGrp, id, '', bnoFragment);
      } else {
        this.filterBNOList(bnoFragment, id);
      }
    } else {
      this.bnoItemListCat[id] = '';
      this.bnoItemList[id] = [];
      this.bnoItemListFiltered[id] = [];
    }
  }


  getLaborderList(calendarId: string) {

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calendarId: calendarId
    };

    return this.http.post<any[]>(this.apiUrl + '/rest/neu/laborder/getlaborderlist', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }


  filterBNOList(bnoFragment, id) {
    const bnoFilteredList = [];
    // console.log ('filter bnoFragment', bnoFragment);
    // console.log ('this.bnoItemList[id].length', this.bnoItemList[id].length);

    if (this.bnoItemList[id] && this.bnoItemList[id].length > 0) {
      this.bnoItemList[id].forEach(bnoItem => {
        // console.log ('bnoItem.bnoCode.indexOf(bnoFragment)', bnoItem.bnoCode.indexOf(bnoFragment));
        if (bnoItem.bnoCode.indexOf(bnoFragment) === 0) {
          bnoFilteredList.push(bnoItem);
        }
      });
    }
    this.bnoItemListFiltered[id] = bnoFilteredList;
  }

  onChangeBNOCategory($event, id, bnoItem, bnoFragment) {
    if ($event) {
      this.bnoItemListLoading[id] = true;
      let selectedBNO = null;
      this.getBnoList($event['mainGrpcode'], $event['subGrpCode']).subscribe((data) => {
        this.bnoItemListLoading[id] = false;
        this.bno[id].reset();
        if (data['code'] === 1) {
          this.bnoItemList[id] = [];
          data['bnoList'].forEach(item => {
            item['visibleData'] = item['bnoCode'] + ' ' + item['bnoName'];
            if (item['bnoCode'] === bnoItem) {
              selectedBNO = item;
            }
          });
          this.bnoItemList[id] = data['bnoList'];

          if (selectedBNO !== null) {
            // console.log ('id', id);
            // console.log ('selected BNO', selectedBNO);
            this.bno[id].setValue(selectedBNO);
          }
          if (bnoFragment !== '') {
            this.filterBNOList(bnoFragment, id);
          }

        } else {
          // error
        }
      });
    } else {
    }
  }

  getBnoList(mainGrpCode: string, subGrpCode: string) {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      mainGrpCode: mainGrpCode,
      subGrpCode: subGrpCode
    };

    return this.http.post<any[]>(this.apiUrl + '/rest/neu/calendarlist/getbnolist', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  findBNO(item, term) {
    // console.log('item', item);
    // console.log('term', term);
  }

  findBNOmainGroup(bno: string) {
    let result = {};
    if (this.bnoList) {
      if (this.bnoList.length > 0) {
        this.bnoList.forEach((item, index) => {
          if (bno.localeCompare(item['subGrpLow']) >= 0 && bno.localeCompare(item['subGrpHgh']) <= 0) {
            // console.log('find BNO result');
            // console.log(item);
            result = item;
          }
        });
        return result;
      } else { console.error('empty bno List - length = 0'); }
    } else { console.error('empty bno List not defined or null'); }
  }


  ssnSearchSubscribe() {

    // if (this.ssnSearchChangeSubscription) {
    //   this.ssnSearchChangeSubscription.unsubscribe();
    // }

    // this.ssnSearchChangeSubscription = this.lssnSearch.valueChanges.pipe(
    //   debounceTime(50)
    // ).subscribe(value => {

    //   this.lbirthname.reset();
    //   this.lbirthplace.reset();
    //   this.lbirthname.reset();
    //   this.lmothersname.reset();

    //   if (this.lssnSearch.valid === true) {
    //     // console.log('findByTAJ:', value);
    //     const ssn = value.replace(/[\s -]+/g, '');
    //     this.onSearchUserByTaj(ssn);

    //   }
    // });

  }

  selectLabExam(id, mode) {
    // console.log('labExam id', id);
    const rowIndex = Math.floor(id / 10);
    const itemIndex = id - (rowIndex * 10);
    console.log ('this.labExamList', this.labExamList);
    const labExamItem = this.labExamList[rowIndex].data[itemIndex];
    // console.log ('pre this.selectedLabExamItemList', this.selectedLabExamItemList);

    if (this.selectedLabExams[id]) {
      if (this.selectedLabExams[id] === true) {
        if (!this.isEmpty(labExamItem)) {
          if (mode === 0 && this.labExamNameSearchField || (this.labExamName.value !== '' && this.labExamName.value !== null)) {
            this.labExamNameSearchField.nativeElement.focus();
            this.labExamName.reset();
          }
          if (mode !== 2) {
            // console.log ('selectLabExam a');
            this.selectedLabExams[id] = false;
            this.selectedLabExamItemList.splice(this.selectedLabExamItemList.indexOf(labExamItem), 1);
          }
          // console.log(labExamItem);
        }
        // console.log('sel lab 1');
      } else {
        if (!this.isEmpty(labExamItem)) {
          if (mode === 0 && this.labExamNameSearchField || (this.labExamName.value !== '' && this.labExamName.value !== null)) {
            this.labExamNameSearchField.nativeElement.focus();
            this.labExamName.reset();
          }
          if (mode !== 3) {
            // console.log ('selectLabExam b');
            this.selectedLabExams[id] = true;
            this.selectedLabExamItemList.push(labExamItem);
          }
          // console.log(labExamItem);
        }
        // console.log('sel lab 2');
      }
    } else {
      if (!this.isEmpty(labExamItem)) {
        if (mode === 0 && this.labExamNameSearchField || (this.labExamName.value !== '' && this.labExamName.value !== null)) {
          this.labExamNameSearchField.nativeElement.focus();
          this.labExamName.reset();
          // console.log('jump', this.labExamName.value);
        }
        if (mode !== 3) {
          // console.log ('selectLabExam c');
          this.selectedLabExams[id] = true;
          this.selectedLabExamItemList.push(labExamItem);
        }
        // console.log(labExamItem);
      }
      // console.log('sel lab 3');
    }
    this.selectedExamCount = this.selectedLabExamItemList.length;
    // console.log ('this.selectedLabExamItemList', this.selectedLabExamItemList);
    // console.log ('selectedExamCount', this.selectedExamCount);
    this.verifyLabexamRequiredAction();
    if (mode !== 2 && mode !== 3) {
      this.generateInvocing();
    }
  }

  isEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  resetModal() {
    this.selectedExamCount = 0;
    this.labExamId.reset();
    this.labExamName.reset();
    this.labExamIdSearch = null;
    this.labExamNameSearch = null;
    this.selectedLabExams = [];
    this.selectedLabExamItemList = [];
    this.selectedPanels = [];
    this.labOrderDetails.reset();
    this.lelltip.setValue('O');
    this.lterkat.setValue('1');
    this.nauRequestId = null;
    // console.log('laborder form', this.labOrderDetails);
    const data = [];
    data['NGdestinationService'] = 'labOrder';
    this.sharedDataService.setPatientData(data);
    this.activeTab = 0;
    this.ssnSearchSubscribe();
    this.labOrderSelect.reset();
    this.totalPrice = 0;
    this.eBeutaloSzoveg = null;
  }

  openHelp(anchor) {
    const newWindow = window.open('https://' + this.authService.getHostname() + '/kezikonyv/' + anchor);
  }

  addLabExamById() {
    this.selectLabExam(this.labExamIdSearch, 0);
    this.labExamIdSearchField.nativeElement.focus();
    this.labExamId.reset();
  }

  addLabExamByName() {
    if (this.filteredLabExams.length === 1) {
      // console.log('filtered:', this.filteredLabExams);
      this.selectLabExam(this.filteredLabExams[0], 1);
      this.labExamNameSearchField.nativeElement.focus();
      this.labExamName.reset();
    }
  }

  selectLabPanel(panelIndex) {

    const panelExamList = this.labPanelList[panelIndex].examList.split(',');
    const selectedLabExamsCopy = Array.from(this.selectedLabExams);

    if (panelExamList.length > 0) {
      let rowIndex = 0;
      let itemIndex = 0;
      panelExamList.forEach(panelItem => {
        // console.log (panelItem);
        // console.log (this.labExamListExtExamCodeMap[panelItem]);
        selectedLabExamsCopy[this.labExamListExtExamCodeMap[panelItem]] = true;
        rowIndex = Math.floor(this.labExamListExtExamCodeMap[panelItem] / 10);
        itemIndex = this.labExamListExtExamCodeMap[panelItem] - (rowIndex * 10);

        if (this.selectedPanels[panelIndex] && this.selectedPanels[panelIndex].selected === true) {
          // console.log ('sel 3'); // remove items
          this.selectLabExam(this.labExamListExtExamCodeMap[panelItem], 3);
        } else {
          // console.log ('sel 2');  // add items
          this.selectLabExam(this.labExamListExtExamCodeMap[panelItem], 2);
        }

        // if (this.labExamList[rowIndex]) {
        //   const labExamItem = this.labExamList[rowIndex].data[itemIndex];
        //   if (this.selectedLabExamItemList.indexOf(labExamItem) === -1) {
        //     this.selectedLabExamItemList.push(labExamItem);
        //   }
        // }

      });

      if (this.selectedPanels[panelIndex] && this.selectedPanels[panelIndex].selected === true) {
        const tempSelectedPanels = [];
        this.selectedPanels.forEach((value, index, array) => {
          if (index !== panelIndex) {
            // console.log ('index', index);
            // console.log ('value', value);
            tempSelectedPanels[index] = value;
          }
        });
        // console.log ('tempSelectedPanels', tempSelectedPanels);
        this.selectedPanels = tempSelectedPanels;
      } else {
        this.selectedPanels[panelIndex] = {};
        this.selectedPanels[panelIndex].selected = true;
        this.selectedPanels[panelIndex].details = this.labPanelList[panelIndex];
      }

      // console.log ('Selected Panels', this.selectedPanels);

    }

    // console.log ('labpanel:', panelIndex);
    // console.log ('panelExamList:', this.labPanelList[panelIndex].examList);

    // console.log ('panelExamList', panelExamList);
    // this.selectedLabExams = selectedLabExamsCopy;
    // console.log ('this.selectedLabExams', this.selectedLabExams);
    this.verifyLabexamRequiredAction();
    this.generateInvocing();
  }

  clearSelectedExams() {
    this.selectedLabExams = [];
    this.selectedLabExamItemList = [];
    this.verifyLabexamRequiredAction();
    this.totalPrice = 0;
    this.selectedPanels = [];
  }

  returnLabExamItemById(id: number) {
    const rowIndex = Math.floor(id / 10);
    const itemIndex = id - (rowIndex * 10);
    return this.labExamList[rowIndex].data[itemIndex];
  }

  verifyLabexamRequiredAction() {
    let therapyRequired = false;

    this.selectedLabExams.forEach((key, value) => {
      if (key) {
      }
      if (key === true && this.returnLabExamItemById(value)['containerName'] === 'CITRáTOS' &&
        this.returnLabExamItemById(value)['examId'] !== 55755012  // WE Citratos kivetel fixalasa
      ) {
        // console.log ('this.selectedLabExams', this.selectedLabExams);
        // console.log ('found:', this.returnLabExamItemById(value)['containerName'] );
        therapyRequired = true;
      }
    });

    if (therapyRequired === false) {
      this.lterapia.clearValidators();
      this.lterapia.reset();
    } else {
      this.lterapia.setValidators([Validators.required]);
    }
    // console.log ('therapyRequired', therapyRequired);
    this.therapyRequired = therapyRequired;
  }

  switchTab(tabID) {
    if (!this.saveInProgress) {
      this.activeTab = tabID;
    }
  }

  TAJvalidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value !== undefined && control.value !== null) {
      let value = control.value.replace(/ /g, '');
      value = value.replace(/-/g, '');
      if (value.length !== 9 || value === '000000000') {
        return { validTAJ: false };
      } else {
        let sum = 0;
        for (let i = 7; i > -1; i--) {
          sum += ((i % 2 === 0) ? value[i] * 3 : value[i] * 7);
        }
        if (Number(value[8]) === sum % 10) {
          return null;
        }
      }
    }
    return { validTAJ: false };
  }

  beutaloValidator(control: AbstractControl): { [key: string]: boolean } | null {
    // console.log(control.value);
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    if (control.value) {
      const firstDate = new Date(control.value.year, control.value.month, control.value.day).getTime();
      const secondDate = new Date().getTime();

      const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
      // console.log('diffDays', diffDays);
      if (diffDays < 90 && diffDays >= 0) {
        return null;
      } else {
        return { dateValid: false };
      }
    } else {
      return { dateValid: false };
    }
  }


  validateTAJ(taj) {
    if (taj !== undefined && taj !== null) {
      let value = taj.replace(/ /g, '');
      value = value.replace(/-/g, '');
      if (value.length !== 9 || value === '000000000') {
        return { validTAJ: false };
      } else {
        let sum = 0;
        for (let i = 7; i > -1; i--) {
          sum += ((i % 2 === 0) ? value[i] * 3 : value[i] * 7);
        }
        if (Number(value[8]) === sum % 10) {
          return true;
        }
      }
    }
    return false;
  }


  BNOvalidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value !== undefined && control.value !== null && control.dirty) {
      let value = control.value;
      value = value.toUpperCase();
      const bnoValid = /^[A-Z]{1}\d{4}$/i.test(value);
      if (bnoValid === true) {
        return null;
      } else {
        return { bnoValid: false };
      }
    }
    if (!control.dirty) {
      return null;
    }
    return { bnoValid: false };
  }


  onSearchUserByTaj(taj) {
    this.tajSearchLoading = true;
    this.newPatient = false;
    if (this.tajSearchSubscription) {
      this.tajSearchSubscription.unsubscribe();
    }
    this.tajSearchSubscription = this.searchUserByTaj(taj).subscribe(
      (data: Response) => {
        this.tajSearchLoading = false;
        if (data['code'] === 1) {
          // console.log('getDataByTaj:', data['getDataByTaj']);
          this.lgender.setValue(data['getDataByTaj']['gender']);
          this.lmothersname.setValue(data['getDataByTaj']['mothersName']);
          this.lbirthplace.setValue(data['getDataByTaj']['placeOfBirth']);
          this.ldateofbirth.setValue(this.sharedDataService.dateStringToNgbDate(data['getDataByTaj']['dateOfBirth']));
          this.lnationality.setValue(data['getDataByTaj']['citizen3Digit']);
          this.lsst.setValue(data['getDataByTaj']['socialSecurityTypeCode']);
          this.lcountry.setValue(data['getDataByTaj']['country']);
          this.lzip.setValue(data['getDataByTaj']['zipCode']);
          this.lcity.setValue(data['getDataByTaj']['settlement']);
          this.laddress.setValue(data['getDataByTaj']['street']);
          this.lbirthname.setValue(data['getDataByTaj']['birthName']);


        } else if (data['code'] === -14 || data['code'] === -25) {
          // couldn't find patient by TAJ
          // console.log('could not find patient by TAJ');
          this.newPatient = true;
        } else {
          // hiba
        }
      }
    );
  }


  searchUserByTaj(taj) {

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      taj: taj,
      wsUrl: null
    };

    return this.http.post(this.apiUrl + '/rest/neu/authentication/searchuserbytaj', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }

  onGetDoctorToSp(sealNumber, spCode) {
    if (this.getSpSubscription) {
      this.getSpSubscription.unsubscribe();
    }
    this.lintezmenyLista = [];

    if (sealNumber !== '' && sealNumber !== null) {

      this.doctorLoading = true;
      this.getSpSubscription = this.getDoctorToSp(sealNumber, spCode).subscribe(
        (data: Response) => {
          this.doctorLoading = false;
          if (data['code'] === 1) {
            // console.log('getDoctorToSp:', data['doctors']);
            if (data['doctors'].length >= 1) {
              this.existingDoctor = true;
              this.lorvos.setValue(data['doctors'][0]['doctorName']);
              this.lantszselect.setValue(data['doctors'][0]);
              const intezmenyLista = data['doctors'];
              intezmenyLista.forEach(item => {
                item['intezmenyDisplayName'] = item['spCode'] + ' - ' + item['spName'];
              });
              intezmenyLista.push({ spCode: -1, spName: 'Más intézmény', intezmenyDisplayName: 'Más intézmény' });
              this.lintezmenyLista = intezmenyLista;
              this.newantsz = false;
            } else {
              const intezmenyLista = [];
              intezmenyLista.push({ spCode: -1, spName: 'Más intézmény', intezmenyDisplayName: 'Más intézmény' });
              this.lintezmenyLista = intezmenyLista;
              this.existingDoctor = false;
              this.lorvos.reset();
              this.lantsz.reset();
              this.lintezmeny.reset();
              this.lantszselect.setValue({ spCode: -1, spName: 'Más intézmény', intezmenyDisplayName: 'Más intézmény' });
              this.newantsz = true;
            }
          } else {
            this.lorvos.reset();
            this.lantsz.reset();
            this.lintezmeny.reset();
            return false;
            // hiba
          }
        }
      );
    }
  }

  onGetSp(spCode) {
    if (this.getDoctorToSpSubscription) {
      this.getDoctorToSpSubscription.unsubscribe();
    }
    if (spCode !== '' && spCode !== null) {
      this.spLoading = true;
      this.getDoctorToSpSubscription = this.getDoctorToSp(null, spCode).subscribe(
        (data: Response) => {
          this.spLoading = false;
          if (data['code'] === 1) {
            if (data['doctors'].length >= 1) {
              this.newInstitute = false;
              // console.log('getDoctorToSp:', data['doctors']);
              this.lintezmeny.setValue(data['doctors'][0]['spName']);
            } else {
              this.newInstitute = true;
              this.lintezmeny.reset();
            }
          } else {
            this.lintezmeny.reset();
            return false;
            // hiba
          }
        }
      );
    }
  }

  getDoctorToSp(sealNumber, spCode) {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      sealNumber: sealNumber,
      spCode: spCode,
    };

    return this.http.post(this.apiUrl + '/rest/neu/laborder/getdoctortosp', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }


  onValidateCodebase(codeBaseType, codeBaseItems) {
    this.validateCodebase(codeBaseType, codeBaseItems).subscribe(
      (data: Response) => {
        this.operationInProgress = false;
        this.operationDescription = '';
        if (data['code'] === 1) {
          // console.log ('2');
          // this.displayBNOCheckResult(data['validCodeBaseItems']);
        } else {
          // console.log ('3');
          // console.log('validateCodebase ERROR:', data['message']);
        }
      }
    );
  }



  validateCodebase(codeBaseType, codeBaseItems) {

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      codeBaseType: codeBaseType,
      codeBaseItems: codeBaseItems
    };

    return this.http.post(this.apiUrl + '/rest/neu/util/validatecodebase', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }

  toInvoiceSummary() {
    let invoiceItemList = [];
    this.standaloneExamItemList.forEach(item => {
      if (item) {
        invoiceItemList.push(item);
      }
    });
    this.invoiceItemList = invoiceItemList;
    this.activeTab = 3;
  }

  generateInvocing() {
    // console.log ('--------------  generate invoicing');
    let standaloneExamItemList = [];
    let panelExamList = [];
    let totalPrice = 0;
    // console.log ('standaloneExamItemList', standaloneExamItemList);
    // console.log ('this.selectedLabExamItemList', this.selectedLabExamItemList);

    this.selectedPanels.forEach((panelItem, index, array) => {
      // console.log ('panelItem.details', panelItem.details);
      if (panelItem && panelItem.details && panelItem.details.examList) {
        const panelExamListTemp = panelItem.details.examList.split(',');
        // console.log ('panelExamListTemp', panelExamListTemp);

        // Check if any items are still selected from panel if no deselect panel and don't add price
        let panelSelectedExamCount = 0;
        panelExamListTemp.forEach(element => {
          if (this.selectedLabExamItemList.find(examItem => examItem.examId == element)) {
            panelSelectedExamCount++;
          }
        });

        if (panelSelectedExamCount === 0) {
          // console.log ('panelItem has no exams selected: ', panelItem);
          this.selectLabPanel(index);
        } else {

          panelExamList = panelExamList.concat(panelExamListTemp);
          if (panelItem.details.grossPrice) {
            // console.log ('panel:', panelItem.details.panelName);
            // console.log ('panel ar:', parseFloat(panelItem.details.grossPrice));
            totalPrice += panelItem.details.grossPrice ? parseFloat(panelItem.details.grossPrice) : 0;
          }
        }
      }
    });
    // console.log ('standaloneExamItemList', standaloneExamItemList);
    // console.log ('panelExamList', panelExamList);

    this.selectedLabExamItemList.forEach((value, index, array) => {
      if (!panelExamList.includes('' + value.examId)) {
        // console.log ('examId', '' + value.examId);
        // console.log (panelExamList.includes('' + value.examId));
        standaloneExamItemList[index] = value;
      }
    });

    // panelExamList.forEach(panelItem => {
    //   // console.log ('index', standaloneExamItemList.findIndex(examItem => examItem.examId == panelItem));
    //   const removeIndex = standaloneExamItemList.findIndex(examItem => examItem.examId == panelItem);
    //   if (removeIndex > -1) {
    //     standaloneExamItemList.splice(removeIndex, 1);
    //   }
    // });

    standaloneExamItemList.forEach(exam => {
      totalPrice += exam.grossPrice ? exam.grossPrice : 0;
      // console.log ('standalone exam:', exam);
    });
    this.totalPrice = totalPrice;
    this.standaloneExamItemList = standaloneExamItemList;
    this.invoicePanelList = [];
    this.selectedPanels.forEach(value => {
      this.invoicePanelList.push(value);
    });
  }


  toSelectLabExams() {
    this.saveResult = null;
    this.activeTab = 1;
    // return;

    if (this.lterkat.value === '1') {     // tajJogviszony(taj, birthPlace, dateOfBirth, gender)
      this.saveErrorDescription = '';
      this.operationDescription = 'TAJ ellenőrzés folyamatban...';
      this.operationInProgress = true;
      this.tajJogviszony(this.lssnSearch.value, this.lbirthplace.value, this.ldateofbirth.value, this.lgender.value).subscribe(result => {
        this.operationDescription = '';
        this.operationInProgress = false;
        if (result['code'] === 1) {
          if (result['data']['hibaKod'] == 0) {
            // console.log('patientData', this.patientDataValue);

            // result['data']['jogviszony'] lehetséges értékei

            // A megadott adatok alapján beazonosítható-e egy érvényes jogviszony. és TAJ szám
            // Z érték esetén TAJ érvényes, jogviszonya rendezett,
            // P érték esetén TAJ érvényes, jogviszonya rendezetlen,
            // K érték esetén külföldön biztosított, a TAJ átmenetileg érvénytelen,
            // B érték esetén TAJ egyéb okból érvénytelen
            // S Korlátozott ellátásra jogosult, érvényes TAJ
            // I válasz csupán annyit jelent, hogy a lekédezett TAJ érvényes.

            // Figyelem! A kizárólag csak gyógyászati segédeszköz vagy gyógyfürdő szerződött tevékenységekkel
            // rendelkező intézményeknek a 141/2009. (VII.3.) Korm. rendelet alapján nem adhatóak át a jogviszonyra
            // vonatkozó információk. Tehát esetükben az I válasz csupán annyit jelent, hogy a lekédezett TAJ érvényes.
            // Ennek tényét a hibaszövegben küldött üzenetben is folyamatosan közöljük. (A hibaKod ez esetben is 0.)

            if (result['data']['jogviszony'] === 'Z') {
              this.activeTab = 1;
            } else {
              this.saveResult = -1;
              this.saveErrorDescription = 'A beteg ellátása a mintavételi helyen nem lehetséges.';
            }

            // else if (result['data']['jogviszony'] === 'P') {
            //   this.saveResult = -1;
            //   this.saveErrorDescription = 'TAJ érvényes, jogviszonya rendezetlen';
            // } else if (result['data']['jogviszony'] === 'K') {
            //   this.saveResult = -1;
            //   this.saveErrorDescription = 'Külföldön biztosított, a TAJ átmenetileg érvénytelen';
            // } else if (result['data']['jogviszony'] === 'B') {
            //   this.saveResult = -1;
            //   this.saveErrorDescription = 'TAJ egyéb okból érvénytelen';
            // } else if (result['data']['jogviszony'] === 'S') {
            //   this.saveResult = -1;
            //   this.saveErrorDescription = 'Korlátozott ellátásra jogosult, érvényes TAJ';
            // } else if (result['data']['jogviszony'] === 'S') {
            //   this.saveResult = -1;
            //   this.saveErrorDescription = 'A lekédezett TAJ érvényes, jogviszony státusza nem ismert';
            // }


          } else {
            // console.log('Taj invalid');
            this.saveResult = -1;
            this.saveErrorDescription = 'Hiba a TAJ validálás során, hibakód: ' + result['data']['hibaKod'] + ', ' + result['data']['hibaSzoveg'];
          }
        } else {
          console.error('Taj validalas hiba');
        }
      });
    } else {
      this.activeTab = 1;
    }
    // console.log('form:', this.labOrderDetails);
  }

  antszChange() {
    // console.log('intezemeny valasztas');
    // console.log('antsz change', this.lantszselect);
    if (this.lantszselect.value['spCode'] === -1) {
      this.newantsz = true;
      // console.log ('new antsz');
    } else {
      this.newantsz = false;
      this.lantsz.reset();
      this.lintezmeny.reset();
      // console.log ('intezmeny reset');
    }
  }

  // saveLabOrderPrint(){
  //   const socket = require('net').socket.notify('create', true);

  //   socket.connect('172.16.16.233', '9100');
  //   socket.sendData('hello world');
  //   socket.disconnect();
  //   socket.close();
  // }

  sendLabOrder() {

    const examList = this.getExamList();

    const bnoList = [];

    this.bno.forEach(bnoItem => {
      if (bnoItem.value) {
        bnoList.push({ name: bnoItem.value.bnoName, code: bnoItem.value.bnoCode });
      }
    });

    let therapyCode = '';
    let therapyName = '';

    if (this.lterapia.value) {
      therapyCode = this.lterapia.value.code;
      therapyName = this.lterapia.value.name;
    }

    let i = 0;
    examList.sampleData.samples.forEach(sample => {
      sample.sampleId = '' + i;
      i++;
    });

    let externalCode = '';
    let shortName = '';
    let longName = '';

    if (this.lantszselect.value && this.lantszselect.value['spCode']) {
      externalCode = this.lantszselect.value['spCode'] === -1 ? this.lantsz.value :
        this.lantszselect.value ? this.lantszselect.value['spCode'] : '';
      // Beküldő intézmény kódja
      shortName = this.lantszselect.value['spCode'] === -1 ? this.lintezmeny.value :
        this.lantszselect.value ? this.lantszselect.value['spName'] : '';
      // Beküldő intézmény neve
      longName = this.lantszselect.value['spCode'] === -1 ? this.lintezmeny.value :
        this.lantszselect.value ? this.lantszselect.value['spName'] : '';
    }

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.getSelectedRole()['roleCode'],
        currentServicePointId: this.sharedDataService.getSelectedRole()['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      laborServiceFk: this.sharedDataService.getSelectedLaborService().svId,    // Vizsgálatot végző labor munkahely id.
      calendarId: this.patientDataValue['calendarFk'], // Foglalás CalID-ja
      remark: this.lcomment.value, // Megjegyzés a diszpécser felületről
      status: 'RQ', // Vizsgálatkérés státusza. Értékkészlet: RQ (Piszkozat), PS (Küldhető), SD (Elküldve), AC (Elfogadva), RJ (Elutasítva), PR (Folyamatban), RS (R. teljesített), VD (Teljesített), X (Törölt)
      request: {
        sendDate: this.sharedDataService.NgbDateToDateString(this.lbeutaloKelte.value) !== '' ?
          this.sharedDataService.NgbDateToDateString(this.lbeutaloKelte.value) : null, // Beutaló kelte diszpészer felületről
        msgId: null,
        payCat: this.lterkat.value, // Térítési kategória kód Felületről
        bnoList:
          bnoList, // BNO lista felületről
        attendCode: this.lnaploszam.value,  // Naplószám a diszpécser felületről
      },
      nauRequestId: this.nauRequestId,
      patient: {
        id: this.calendarDetail['participantId'],
        tapass: this.calendarDetail['participantTapass'],
        name: this.lname.value,
        birthDate: this.sharedDataService.NgbDateToDateString(this.ldateofbirth.value), // *
        birthPlace: this.lbirthplace.value, // *
        birthName: this.lbirthname.value, // *
        motherName: this.lmothersname.value, // *
        gender: this.lgender.value, // *
        bloodType: '',  // opcionális
        ssn: this.lssnSearch.value, // *
        ssnType: this.lsst.value, // *
        natio: this.lnationality.value, // *
        country: this.lcountry.value, // *
        zip: this.lzip.value, // *
        city: this.lcity.value, // *
        address: this.laddress.value // *
      },
      senderDoctor: {
        id: null,
        externalCode: this.lpecsetszam.value, // Pecsétszám
        shortName: this.lorvos.value,  // Orvos név
        longName: this.lorvos.value  // Orvos név
      },
      senderSp: {
        id: null,
        externalCode: externalCode,
        // Beküldő intézmény kódja
        shortName: shortName,
        // Beküldő intézmény neve
        longName: longName
      },
      examList: examList.examList,  // labExamlist-ből kiválasztott vizsgálatok examId listája
      sampleData: examList.sampleData,
      therapy: {
        codeBase: 'L_THER1',
        code: therapyCode,
        name: therapyName
      }
    };

    this.operationInProgress = true;
    this.operationDescription = 'Mentés folyamatban';
    this.saveLabOrder(params).subscribe(
      (data: Response) => {
        this.nauRequestId = data['nauRequestId'];
        this.operationInProgress = false;
        this.operationDescription = '';
        // console.log('SaveLabOrder success', data);
        this.saveResult = 0;
        setTimeout(() => { this.saveResult = null; }, 3000);
        // this.sharedDataService.updateDispatcherCalEntry(this.patientDataValue, 'DD');

        if (data['code'] === 1) {
          //  this.sharedDataService.updateCalendarEntry(this.patientDataValue['calendarFk'], 'DD');
        } else {
          console.error('SaveLabOrder failed', data);
        }
      });

    // this.calStatusSave(params).subscribe(
    //   (data: Response) => {
    //     if (data['code'] === 1) {
    //     // console.log('lab update cal success', data);
    //       this.sharedDataService.updateCalendarEntry(this.patientDataValue['calendarFk'], 'DD');
    //     } else {
    //       console.error('lab update cal failed', data);
    //     }
    //   });

    // console.log('params', params);

  }

  getExamList() {
    // console.log('selectedLabExamItemList', this.selectedLabExamItemList);
    const labExamList = {
      examList: '',
      sampleList: []
    };

    const todayString = this.today['year'] + '-' + this.today['month'] + '-' + this.today['day'];
    const labExamListArray = {
      examList: [],
      sampleData: {
        samplingTime: todayString,
        samplingPlace: '',
        samples: []
      }

    };
    this.selectedLabExamItemList.forEach(item => {
      labExamList.examList += item['examId'] + ',';
      labExamListArray.examList.push(
        {
          id: item['examId'],
          examCode: item['examCode'],
          examName: item['examName'],
          examPriority: ''

        });
      labExamList.sampleList.push(
        {
          sampleId: '',
          containerCode: item['containerCode'],
          sampleCode: item['sampleCode'],
          containerName: item['containerName'],  // labExamlist-ből
          barCode: '', // this.getRandomBarcode(10000, 30000), // Diszpécseres rögzítésnél üres
          sampleName: item['sampleName'], // labExamlist-ből
          samplingTime: '' // Vérvétel elvégzésénél current time beállítandó
        }
      );
    });
    labExamListArray.sampleData.samples = labExamList.sampleList;
    labExamList.examList = labExamList.examList.slice(0, -1);
    return labExamListArray;
  }

  getRandomBarcode(start, max) {
    return ('' + (start + (Math.floor(Math.random() * Math.floor(max)))));
  }


  logDetails() {
    console.log('selectedLabExamItemList', this.selectedLabExamItemList);
  }

  updateCalendar(params: {}) {
    return this.http.post(this.apiUrl + '/rest/neu/calendarlist/updatecalendar', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }


  calStatusSave(params: {}) {

    return this.http.post(this.sharedDataService.callerSystemAPIURL + 'updatecalendar', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }


  saveLabOrder(params: {}) {
    return this.http.post(this.apiUrl + '/rest/neu/laborder/savelaborder', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getCalendarDetail(calendarId: string) {

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calendarId: calendarId
    };

    return this.http.post<any[]>(this.apiUrl + '/rest/neu/calendarlist/getcalendardetail', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  test(calendarId) {
    this.sharedDataService.updateCalendarEntry(calendarId, 'DD');

  }


  checkUserBy2T() {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      ssnType: '1',
      ssnNumber: this.lssnSearch.value,
      dateOfBirth: this.ldateofbirth.value.year + '-' + this.ldateofbirth.value.month + '-' + this.ldateofbirth.value.day,
    };

    return this.http.post(this.apiUrl + '/rest/neu/authentication/checkuserby2t', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }


  tajValidate(taj) {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      taj: taj
    };

    return this.http.post(this.apiUrl + '/rest/neu/taj/validate', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }


  tajJogviszony(taj, birthPlace, dateOfBirth, gender) {

    const year = dateOfBirth.year;
    const month = dateOfBirth.month;
    const day = dateOfBirth.day;

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      validatorParams: {
        mode: '01',
        taj: taj,
        szVezetek: '',
        szUtonev1: '',
        szUtonev2: null,
        szHely: birthPlace,
        szEv: year,
        szHo: month,
        szNap: day,
        neme: gender,
        date: null,
        anyjaVezetek: '',
        anyjaUtonev1: '',
        anyjaUtonev2: null
      }
    };

    // mode: '01',
    // taj: '028516060',
    // szVezetek: 'POLGÁRDI',
    // szUtonev1: 'GYÖRGY',
    // szUtonev2: null,
    // szHely: 'BUDAPEST',
    // szEv: '1922',
    // szHo: '12',
    // szNap: '26',
    // neme: 'F',
    // date: null,
    // anyjaVezetek: 'KÁRÁSZ',
    // anyjaUtonev1: 'RÓZA',
    // anyjaUtonev2: null

    return this.http.post(this.apiUrl + '/rest/neu/taj/jogviszony', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }


  setUserData(userData) {

    userData['user']['birthForename2'] = !userData['user']['birthForename2'] ? '' : userData['user']['birthForename2'];

    userData['user']['birthForename2'] = userData['user']['birthForename2'] !== '' ? ' ' +
      userData['user']['birthForename2'] : userData['user']['birthForename2'];

    userData['user']['birthForename'] = !userData['user']['birthForename'] ? '' : ' ' + userData['user']['birthForename'];

    userData['user']['birthForename'] = userData['user']['birthForename'] !== '' ? ' ' +
      userData['user']['birthForename'] : userData['user']['birthForename'];

    userData['user']['birthName'] = userData['user']['birthSurname'] + userData['user']['birthForename'] +
      userData['user']['birthForename2'];

    userData['user']['forename'] = userData['user']['forename'] ? ' ' + userData['user']['forename'] : '';
    userData['user']['forename2'] = userData['user']['forename2'] ? ' ' + userData['user']['forename2'] : '';

    userData['user']['name'] = userData['user']['surname'] + userData['user']['forename'] + userData['user']['forename2'];

    userData['user']['street'] = userData['user']['street'] ? userData['user']['street'] : '';
    userData['user']['streetType'] = userData['user']['streetType'] ? userData['user']['streetType'] : '';
    userData['user']['houseNumber'] = userData['user']['houseNumber'] ? userData['user']['houseNumber'] : '';
    userData['user']['zipCode'] = userData['user']['zipCode'] ? userData['user']['zipCode'] : '';
    userData['user']['email'] = userData['user']['email'] ? userData['user']['email'] : '';
    userData['user']['phoneNumber'] = userData['user']['phoneNumber'] ? userData['user']['phoneNumber'] : '';

    // this.detailsName = userData['user']['name'];

    // this.aliasname.setValue(userData['user']['name']);
    this.lname.setValue(userData['user']['surname']);
    this.lbirthname.setValue(userData['user']['birthName']);

    // this.ldateofbirth.setValue(userData['user']['dateOfBirth']);
    this.lbirthplace.setValue(userData['user']['placeOfBirth']);
    this.lssnSearch.setValue(userData['user']['socialSecurityNumber'] ? userData['user']['socialSecurityNumber'] : '');
    this.lsst.setValue(userData['user']['socialSecurityTypeCode'] ? userData['user']['socialSecurityTypeCode'] : '');
    this.laddress.setValue(userData['user']['street'] + ' ' + userData['user']['streetType'] + ' ' + userData['user']['houseNumber']);
    this.lzip.setValue(userData['user']['zipCode']);
    this.lgender.setValue(userData['user']['gender']);
    this.lnationality.setValue(userData['user']['citizen3Digit']);
    this.lmothersname.setValue(userData['user']['mothersName']);
    this.lcity.setValue(userData['user']['settlement']);
    this.lcountry.setValue(userData['user']['country3Digit']);

    // console.log('userData', userData);

    if (userData['user']['id'] !== undefined) {
      userData['user']['userId'] = userData['user']['id'];
    }
    this.userData = userData['user'];

    // if (this.registerPatientToAliasAppointment) {

    // }
  }


  check2T($event) {
    // console.log('patientDataValue: ', this.patientDataValue);
    // console.log('check user by 4t started');
    // this.toggleFormDetails(false, false);
    this.userData = null;
    this.checkUserBy2T().subscribe(
      (userData: Response) => {
        // this.patientDetailsFormVisible = true;
        // console.log('check user by 4t response arrived');
        // console.log(userData);
        // this.check4TResultMessage = userData['message'];
        if (userData['code'] === 1) { // existing patient reg found
          // if (this.registerPatientToAliasAppointment) {
          //   this.justAssingPatient = true;
          // }
          // this.toggleFormDetails(true, false);
          // this.tapass = userData['user']['ekCardNo'];

          const dateOfBirth = new Date(this.sharedDataService.DateStringConvert(userData['user']['dateOfBirth']));

          userData['user']['dateOfBirth'] = this.sharedDataService.dateToDateString(dateOfBirth);

          // console.log('userData[user][dateOfBirth]: ', userData['user']['dateOfBirth']);

          this.setUserData(userData);
          // this.check4TStatus = true;
          // this.disable4Tediting();
        } else if (userData['code'] === -16) {
          // this.toggleFormDetails(true, true);
          // this.check4TStatus = true;
          // this.aliasname.setValue(this.birthname.value);
          // this.sst.setValue('0');
          // this.phone.setValue('+36');
          // this.check4TResultMessage = 'Nem létező felhasználó, kérjük töltse ki az adatokat!';
        } else if (userData['code'] === -25) {
          // this.check4TResultMessage = 'Érvénytelen paraméterek, kérjük javítsa az adatokat, majd próbálja meg újra!';
        }
      }
    );
  }
  logFormGroup() {
    console.log('labOrderDetails', this.labOrderDetails);
  }

  closeResult() {
    this.saveResult = null;
    this.saveErrorDescription = '';
  }

  toSelectLabOrder() {
    this.activeTab = 2;
  }

  toLabOrderDetail() {
    this.activeTab = 0;
  }

  selectPreviousLabOrder($event) {
    //    console.log ('labroder event', $event);
    // console.log ('this.labOrderSelect.value', this.labOrderSelect.value);
    if (this.labOrderSelect.value.nauRequestId === 'Új vizsgálatkérés') {
      this.activeTab = 0;
    } else {
      this.loadPreviousLaborderDetails(this.labOrderSelect.value);
    }
  }

  loadPreviousLaborderDetails(values) {
    this.activeTab = 0;

    this.lterkat.clearValidators();

    this.nauRequestId = this.labOrderSelect.value['nauRequestId'];

    this.lssnSearch.setValue(values.patient.ssn);
    this.lsst.setValue(values.patient.ssnType);
    this.lname.setValue(values.patient.name);
    this.lbirthname.setValue(values.patient.birthName);
    this.lbirthplace.setValue(values.patient.birthPlace);
    this.ldateofbirth.setValue(this.sharedDataService.dateStringToNgbDate(values.patient.birthDate));
    this.lmothersname.setValue(values.patient.motherName);
    this.lgender.setValue(values.patient.gender);
    this.lnationality.setValue(values.patient.natio);
    this.lcountry.setValue(values.patient.country);
    this.lzip.setValue(values.patient.zip);
    this.lcity.setValue(values.patient.city);
    this.laddress.setValue(values.patient.address);
    this.lelltip.setValue('0');

    if (values.sender.doctor.externalCode !== '00000' && values.sender.doctor.externalCode !== '') {
      this.lpecsetszam.setValue(values.sender.doctor.externalCode);
      this.lorvos.setValue(values.sender.doctor.longName);
      this.lantszselect.setValue(values.sender.sp.externalCode);
    } else {
      this.lpecsetszam.reset();
    }
    this.lterkat.setValue(values.request.payCat);
    this.terkatChangeEvent(values.request.payCat);

    // console.log ('values.request.sendDate',values.request.sendDate);
    // console.log ('values.request.payCat',values.request.payCat);

    if (values.request.sendDate !== '') {
      // console.log('set SendDate');
      this.lbeutaloKelte.setValue(this.sharedDataService.dateStringToNgbDate(values.request.sendDate));
    }
    if ((values.request.sendDate === '' || !values.request.sendDate) && values.request.payCat == '4') {
      this.lbeutaloKelte.reset();
      // console.log ('fizetos');
    }

    this.lnaploszam.setValue(values.request.attendCode);
    this.lcomment.setValue(values.remark);
    this.lterapia.setValue(values.therapy);

    let bnoList = [];
    bnoList = values.request.bnoList;
    // console.log ('bnoList', bnoList);
    let i = 0;
    [...bnoList].forEach(bnoItem => {
      if (bnoItem !== null && bnoItem != 'null') {
        const bnoSubGrp = this.findBNOmainGroup(bnoItem.code);
        this.onChangeBNOCategory(bnoSubGrp, i, bnoItem.code, '');
        i++;
      }
    });

    this.lterapia.setValue(values.therapy);
    console.log ('exams', values.exams);
    values.exams.forEach(examItem => {
      this.selectLabExam(this.labExamListExtExamCodeMap[examItem.id], 0);
    });

    // console.log ('form', this.labOrderDetails);

  }

  onChangeBNO($event, id) {
    // console.log ('change BNo #', id);
    // console.log ($event);
  }

  logOrderDetails() {
    console.log(this.labOrderDetails);
  }




  showPatientBeu() {
    this.showPatientBeuFields = !this.showPatientBeuFields;
    if (!this.showPatientBeuFields && !this.showInstitutionalBeuFields) {
      this.labOrderDetails.controls.referralIdentification.reset();
      this.patientEbeuList = [];
      this.patientBeuFormGroup.reset();
    }
  }

  showInstitutionalBeu() {
    this.showInstitutionalBeuFields = !this.showInstitutionalBeuFields;
    if (!this.showPatientBeuFields && !this.showInstitutionalBeuFields) {
      this.labOrderDetails.controls.referralIdentification.reset();
      this.patientEbeuList = [];
      this.patientBeuFormGroup.reset();
    }
  }

  getPatientBeuDocumentList() {
    if (this.patientBeuFormGroup.controls.breakGlass.value && !this.patientBeuFormGroup.controls.breakGlassReason.value) {
      this.toastr.error('sürgősségi adatlekérés esetén az indoklák megadása kötelező!');
    } else {
      let applyAllFilter: boolean;
      if ((this.showPatientBeuFields && this.showInstitutionalBeuFields) || this.showInstitutionalBeuFields) {
        applyAllFilter = true;
      } else {
        applyAllFilter = false;
      }

      const params: PatientEbeuRequest = {
        messageHeader: {
          currentTapass: this.authService.getUserData()['ekCardNo'],
          currentRole: this.sharedDataService.selectedRole['roleCode'],
          currentServicePointId: this.sharedDataService.selectedRole['spId'],
          locale: 'hu',
          deviceId: this.authService.getUUID()
        },
        breakGlass: this.patientBeuFormGroup.controls.breakGlass.value,
        breakGlassOk: this.patientBeuFormGroup.controls.breakGlassReason.value,
        dateFrom: this.sharedDataService.NgbDateToDateString(this.patientBeuFormGroup.controls.patientDateFrom.value),
        dateTo: this.sharedDataService.NgbDateToDateString(this.patientBeuFormGroup.controls.patientDateTo.value),
        patientTapass: (this.showPatientBeuFields && !this.showInstitutionalBeuFields) ||
          (this.showPatientBeuFields && this.showInstitutionalBeuFields) ?
          this.calendarDetail['participantTapass'] : null,
        applyAllFilter
      };
      this.ehrDetailsInProgress = true;
      this.apiService.searchEbeuMinimal(params).subscribe(res => {
        this.ehrDetailsInProgress = false;
        if (res.code > 0) {
          if (res.errorMessages && res.errorMessages.messages.length > 0) {
            res.errorMessages.messages.forEach(errorMessage => {
              this.toastr.error(errorMessage.code, errorMessage.message);
            });
          }
          this.toastr.success('sikeres lekérdezés!');
          this.patientEbeuList = res.data;
        } else {
          this.toastr.error(res.message);
        }
      }, err => {
        this.ehrDetailsInProgress = false;
        this.toastr.error(err.error.message);
      });
    }
  }

  copyReferralId(element) {
    // console.log (element);
    this.ebeuDataCopy = true;
    this.lpecsetszam.setValue(element.businessContent.beutaloOrvos.stampNo);
    this.lantszselect.setValue({ spCode: -1, spName: 'Más intézmény', intezmenyDisplayName: 'Más intézmény' });
    this.antszChange();
    this.lorvos.setValue(element.businessContent.beutaloOrvos.lastName + ' ' + element.businessContent.beutaloOrvos.firstName);
    this.lantsz.setValue(element.businessContent.beutaloIntezmeny.intezmenyKod);
    this.ebeuDataCopy2 = true;
    this.lintezmeny.setValue(element.businessContent.beutaloIntezmeny.intezmenyMegnevezes);
    // console.log ('intezmeny', element.businessContent.beutaloIntezmeny.intezmenyMegnevezes);
    this.lbeutaloKelte.setValue(this.sharedDataService.dateToNgbDate(this.sharedDataService.dateFromISO8601(element.businessContent.kiallitasDatuma)));
    this.referralIdentification.setValue(element.businessContent.beutaloAzonosito);
    this.lnaploszam.setValue(element.businessContent.szakmaiAdatok.beutaloNaploSorszam);

    const bnoSubGrp = this.findBNOmainGroup(element.businessContent.szakmaiAdatok.beutaloDiagnozis);
    this.onChangeBNOCategory(bnoSubGrp, 0, element.businessContent.szakmaiAdatok.beutaloDiagnozis, '');

    // this.handleBnoTextInput(element.businessContent.szakmaiAdatok.beutaloDiagnozis, 0);
    // this.bno[0].setValue(element.businessContent.szakmaiAdatok.beutaloDiagnozis);
    this.eBeutaloSzoveg = element.businessContent.szakmaiAdatok.beutaloSzoveg;
  }


  logstandaloneExamItemList() {
    console.log ('standaloneExamItemList', this.standaloneExamItemList);
  }
}
