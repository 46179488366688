import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';

import { registerLocaleData } from '@angular/common';
import localeHu from '@angular/common/locales/hu';
import localeHuExtra from '@angular/common/locales/extra/hu';
registerLocaleData(localeHu, 'hu-HU', localeHuExtra);

import {NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';

import { AuthService } from './auth/auth.service';
import { ReactiveFormsModule } from '@angular/forms';
import { NotFoundComponent } from './not-found/not-found.component';
import { MainComponent } from './main/main.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NgbdDatepickerMultiple } from './datepicker/datepicker';
import { SharedDataService } from './services/shared-data.service';
import { BookAppointmentComponent } from './book-appointment/book-appointment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CalendarComponent } from './calendar/calendar.component';
import { LoggerService } from './services/logger.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { RegisterPatientComponent } from './register-patient/register-patient.component';
import { ArrivePatientComponent } from './arrive-patient/arrive-patient.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { ImplantDetailsComponent } from './implant-details/implant-details.component';
import { RedirectService } from './services/redirect.service';
import { DiscardPatientComponent } from './discard-patient/discard-patient.component';
import { httpInterceptorProviders } from './app.component';
import { SharedApiService } from './services/shared-api.service';
import { SettingsComponent } from './settings/settings.component';
import { ExcelService } from './services/excel.service';
import { BookingWeeklyComponent } from './booking-weekly/booking-weekly.component';
import { ReloginComponent } from './relogin/relogin.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { EesztMessageListModalComponent } from './eeszt-message-list-modal/eeszt-message-list-modal.component';
import { LabOrderComponent } from './lab-order/lab-order.component';
import { CalendarLabOtherComponent } from './calendar-lab-other/calendar-lab-other.component';
import { ArrivePatientSimpleComponent } from './arrive-patient-simple/arrive-patient-simple.component';
import { DiscardPatientSimpleComponent } from './discard-patient-simple/discard-patient-simple.component';
import { TitleCaseDirective } from './title-case.directive';
import { UpperCaseFirstDirective } from './upper-case-first.directive';
import { SafePipe } from './safe.pipe';
import { EknumberPipe } from './eknumber.pipe';
import { DolgEkMainComponent } from './dolg-ek-main/dolg-ek-main.component';
import { DolgMozgasComponent } from './dolg-mozgas/dolg-mozgas.component';
import { DolgJelenletComponent } from './dolg-jelenlet/dolg-jelenlet.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WebcamModule } from 'ngx-webcam';
import { NgxPrintModule } from 'ngx-print';
import { EesztEproComponent } from './eeszt-epro/eeszt-epro.component';
import { W1250ToUtf8Pipe } from './w1250-to-utf8.pipe';
import { SsnTypePipe } from './ssn-type.pipe';
import { ResolveEesztTorPipe } from './resolve-eeszt-tor.pipe';
import { DolgPermissionComponent } from './dolg-permission/dolg-permission.component';
import { DolgGroupPermissionComponent } from './dolg-group-permission/dolg-group-permission.component';
import { DispatcherComponent } from './dispatcher/dispatcher.component';
import { CalendarLabSampleComponent } from './calendar-lab-sample/calendar-lab-sample.component';
import { LabSampleComponent } from './lab-sample/lab-sample.component';
import { MedcseppWorklistComponent } from './medcsepp-worklist/medcsepp-worklist.component';
import { ToastrModule } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { CounterPipe } from './counter.pipe';
import { MglTimelineModule } from 'angular-mgl-timeline';
import { FilterTorElementNamePipe } from './filter-tor-element-name.pipe';
import { SchedulerModalComponent } from './scheduler-modal/scheduler-modal.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { EbpBookingComponent } from './ebp-booking/ebp-booking.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MatDialogModule } from '@angular/material/dialog';
import { LegalGuardianDialog } from './register-patient/legal-guardian-dialog/legal-guardian-dialog';
import { BnoWhoComponent } from './documentation/bno-who/bno-who.component';
import { ScheduleDialogComponent } from './book-appointment/schedule-dialog/schedule-dialog.component';
import { MulticalendarComponent } from './multicalendar/multicalendar.component';
import { MulticalendarWeekComponent } from './multicalendar-week/multicalendar-week.component';
import { CheckAuszComponent } from './register-patient/check-ausz/check-ausz.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotFoundComponent,
    MainComponent,
    NgbdDatepickerMultiple,
    BookAppointmentComponent,
    CalendarComponent,
    RegisterPatientComponent,
    ArrivePatientComponent,
    DocumentationComponent,
    ImplantDetailsComponent,
    DiscardPatientComponent,
    SettingsComponent,
    BookingWeeklyComponent,
    ReloginComponent,
    EesztMessageListModalComponent,
    LabOrderComponent,
    CalendarLabOtherComponent,
    ArrivePatientSimpleComponent,
    DiscardPatientSimpleComponent,
    TitleCaseDirective,
    UpperCaseFirstDirective,
    SafePipe,
    EknumberPipe,
    DolgEkMainComponent,
    DolgMozgasComponent,
    DolgJelenletComponent,
    EesztEproComponent,
    W1250ToUtf8Pipe,
    SsnTypePipe,
    ResolveEesztTorPipe,
    DolgPermissionComponent,
    DolgGroupPermissionComponent,
    DispatcherComponent,
    CalendarLabSampleComponent,
    LabSampleComponent,
    MedcseppWorklistComponent,
    CounterPipe,
    FilterTorElementNamePipe,
    SchedulerModalComponent,
    EbpBookingComponent,
    LegalGuardianDialog,
    BnoWhoComponent,
    ScheduleDialogComponent,
    MulticalendarComponent,
    MulticalendarWeekComponent,
    CheckAuszComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    MatTableModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatInputModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgSelectModule,
    CKEditorModule,
    BrowserAnimationsModule,
    WebcamModule,
    NgxPrintModule,
    MglTimelineModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectFilterModule,
    MatPasswordStrengthModule.forRoot(),
    ToastrModule.forRoot(),
    MatDialogModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule
 ],
  providers: [
    AuthService,
    Title,
    SharedDataService,
    LoggerService,
    NgbdDatepickerMultiple,
    ArrivePatientComponent,
    RedirectService,
    httpInterceptorProviders,
    { provide: MAT_DATE_LOCALE, useValue: 'hu-HU' },
   // {provide: ErrorHandler, useClass: GlobalErrorHandlerService},
    SharedApiService,
    ExcelService,
    CalendarComponent,
    DatePipe
  ],
  entryComponents: [LegalGuardianDialog, ScheduleDialogComponent, CheckAuszComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
