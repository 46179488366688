<!-- Modal -->
<div class="modal" id="discardPatientSimple" tabindex="-1" role="dialog" aria-labelledby="discardPatientSimple" aria-hidden="true">
  <div class="modal-dialog" role="document">

    <div [ngClass]="{'hidden': saveResult !== 0}" class="fullSizeCover">
      <div class="fullSizeCoverContent">
        <div class="col-md-12 mt-4">
          <div class="row">
            <div class="col-md-12 mt-4 text-center">
              <h3><i class="fa fa-check-circle green mr-2"></i>Páciens sikeresen elbocsátva!</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mt-4 text-center">
              <button class="btn btn-success" data-dismiss="modal" (click)="onClose()">Bezárás</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-content">
      <div class="modal-header">
        <div id="saveError" [ngClass]="{'showResult': saveResult == -1}">
          <div class="closeResult" (click)="closeResult()">x</div>
          <div class="saveErrorContent">Hiba az elbocsátás során!
            <span>{{ saveErrorDescription }}</span>
          </div>
        </div>

        <h5 class="modal-title">Páciens elbocsátása ({{ patientDataValue['participantName'] }})</h5>
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="onClose()">X</button>
      </div>
      <div class="modal-body">
        <div class="container-fluid" *ngIf="!isLoading">
          <div class="col-sm-12 text-right">
            <button class="btn btn-danger mt-4" (click)="onDiscardPatient()" [disabled]="saveInProgress">
              <i *ngIf="saveInProgress" class="fa fa-spinner fa-spin"></i> Elbocsátás</button>
          </div>

        </div>
        <div class="container-fluid" *ngIf="isLoading">Loading...</div>
      </div>
    </div>
  </div>
</div>
