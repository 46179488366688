import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  environment = environment;

  constructor(private http: HttpClient) { }

  log(msg: any) {
     console.log(msg);
  }

  logitErrorSend(params) {

    // return this.http.put<any[]>('https://api.logit.io/v2', params, {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     'LogType': 'general',
    //     'ApiKey': '053a1d23-b64f-4221-b4f0-810028bbc821'
    //   })
    // });
    params.AppName = 'naucare';
    params.AppVersion = this.environment.version;

    return this.http.post<any[]>('https://els.nauticom.hu/', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }), responseType: 'text' as 'json'
    });
  }

  logitHTTPError(error: HttpErrorResponse, req: HttpRequest<any>, time) {

    const requestDetailsJson = {
      requestURL: req.urlWithParams,
      requestBody: req.body,
      requestMethod: req.method,
      requestTime: time
    };

    const requestDetailsJsonString = JSON.stringify(requestDetailsJson);

    const params = {
      logData: 'HTTP ERROR',
      errorDetails: {
        errorMessage: error.message,
        errorStatus: error.status,
        errorStatusText: error.statusText
      },
      requestDetails: {
        requestURL: req.urlWithParams,
        requestBody: req.body,
        requestMethod: req.method,
        requestTime: time
      },
      requestDetailsJson: requestDetailsJsonString
    };

    this.logitErrorSend(params).subscribe(
      response => {
        console.log ('HTTP Error sent to logit');
      }
    );

  }


  logitAPIError(data: {}, req: HttpRequest<any>, time) {

    const requestDetailsJson = {
      requestURL: req.urlWithParams,
      requestBody: req.body,
      requestMethod: req.method,
      requestTime: time
    };

    const requestDetailsJsonString = JSON.stringify(requestDetailsJson);

    const responseDetailsJson = {
      responseCode: data['code'],
      responseMessage: data['message'],
      responseData: data,
      responseTime: time
    };

    const responseDetailsJsonString = JSON.stringify(responseDetailsJson);

    const params = {
      logData: 'API ERROR',
      responseDetails: {
        responseCode: data['code'],
        responseMessage: data['message'],
        responseData: data,
        responseTime: time
      },
      responseDetailsJson: responseDetailsJsonString,
      requestDetails: {
        requestURL: req.urlWithParams,
        requestBody: req.body,
        requestMethod: req.method,
        requestTime: time
      },
      requestDetailsJson: requestDetailsJsonString
    };

    this.logitErrorSend(params).subscribe(
      response => {
        console.log ('API Error sent to logit');
      }
    );
  }


  logitClientError(error) {
    // console.log (error.name);
    // console.log (error.status);
    // console.log (error.message);
    // console.log (error.stack);
    const params = {
      logData: 'CLIENT ERROR',
      errorDetails: {
        name: error.name,
        status: error.status,
        message: error.message,
        stack: error.stack
      }
    };

    this.logitErrorSend(params).subscribe(
      response => {
        console.log ('Client Error sent to logit');
      }
    );
  }
}
