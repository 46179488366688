<!-- Modal -->

<!-- 
    <div [ngClass]="{'hidden': !closeConfirmationDialogueVisible}" id="quitConfirmation" class="quitConfirmation">
      <div class="quitConfirmationContent">
        <h6>Az adatok megtartása a későbbi folytatáshoz?</h6>
        <div class="col-md-12 mt-4">
          <div class="row">
            <div class="col-md-4">
              <button type="button" class="btn btn-primary" (click)="hideCloseConfirmationDialogue(true)"
                data-dismiss="modal">Igen</button>
            </div>
            <div class="col-md-4">
              <button type="button" class="btn btn-danger" data-dismiss="modal"
                (click)="resetModalAndHideDialogue()">Nem</button>
            </div>
            <div class="col-md-4">
              <button type="button" class="btn btn-secondary"
                (click)="hideCloseConfirmationDialogue(false)">Mégsem</button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
<!-- 

    <div class="modal-content modal-fh">
      <div class="modal-header">
        <div id="saveSuccess" [ngClass]="{'showResult': saveResult == 0}">
          Sikeresen mentve!
        </div> -->
 
        <div id="saveError" [ngClass]="{'showResult': saveResult == -1}">
          <div class="closeResult" (click)="closeResult()">x</div>
          <div class="saveErrorContent">Hiba a mentés során!
            <span>{{ saveErrorDescription }}</span>
          </div>
        </div>
<!--
        <div class="loadError" [ngClass]="{'showResult': loadResult == -1}">
          <div class="closeLoadResult" (click)="closeLoadResult()">x</div>
          <div class="saveErrorContent">Hiba az adatok betöltése során!
            <span>{{ loadErrorDescription }}</span>
          </div>
        </div>
         -->

<div [ngClass]="{'hidden': !operationInProgress}" class="quitConfirmation">
  <div class="quitConfirmationContent">
    {{ operationDescription }}
  </div>
</div>
<div id="saveSuccess" [ngClass]="{'showResult': saveResult == 0}">
  Sikeresen mentve!
</div>
<!-- <h2 *ngIf="patientDataValue !== null">{{ patientDataValue['longDescription']}}</h2> -->
<div class="row mt-2" *ngIf="labOrderList && labOrderList.length > 0">
  <div class="col-12">
    <form [formGroup]="labOrderGroup" role="form" class="form-group full-width">
      <ng-select [searchable]="true" placeholder="Válasszon vizsgálatkérést..." formControlName="labOrderSelect"
        [items]="labOrderList" bindLabel="visibleTitle" [clearable]="false" loadingText="Betöltés..."
        notFoundText="Nincs találat">
      </ng-select>
    </form>
  </div>
</div>
<div class="row mt-2" *ngIf="emptyLabOrderList">
  <div class="col-12">
    Nem található függőben lévő vizsgálatkérés.
  </div>
</div>
<div *ngIf="patient && patient['name']">
  <div class="row mt-2">
    <div class="col-10">
      <span class="modal-title mb-2" id="exampleModalLongTitle" *ngIf="patient && patient['name'] != ''">
        <strong>
          {{ patient['name'] }}, {{ patient['ssn']}}, {{ patient['birthDate']}}, {{ patient['motherName']}}
        </strong>
      </span>
    </div>
    <div class="col-2">
      <button class="btn btn-success" [disabled]="emptyLabOrderList || !validLab" (click)="saveLabSamples()">Mentés</button>
    </div>
  </div>
</div>
<form [formGroup]="samples">
  <div *ngFor="let container of containerList | keyvalue">
    <div class="row">
      <div class="col-4">
        <strong> {{ container.key }} </strong><br />
      </div>
      <div class="col-8">
        <!-- [disabled]="existingContainerList[container.key].length == containerList[container.key].length" -->
        <button class="btn btn-success" (click)="addSample(container)"><strong>+</strong></button>
        <button class="btn btn-danger ml-2" (click)="removeSample(container)"
          [disabled]="existingContainerList[container.key].length == 1"><strong>-</strong></button>
      </div>
    </div>
    Vizsgálatok:
    <span *ngFor="let examItem of containerExamList[container.key]">
      {{ examItem.internalCode }},
    </span>
    <br />
    <span *ngFor="let exam of existingContainerList[container.key]">
      <input type="text" formControlName="{{ exam.sampleId }}" class="form-control sample-input mr-2 mb-2"
        on-focus="onFocus(exam.sampleId)">
      <!--    
              {{ exam.msgId }}, 
              {{ exam.sampleId }}
              {{ exam.containerName }}, 
              {{ exam.sampleCode }}, 
              {{ exam.sampleId }}, 
              {{ exam.sampleName }}  
            -->
    </span>
  </div>
</form>
<!-- <button (click)="logForm()">Log</button> -->


<div class="col-4">
  <!-- <barcode-scanner-livestream type="i2of5" (valueChanges)="onValueChanges($event)" (started)="onStarted()">
  </barcode-scanner-livestream> -->
  <div [hidden]="!barcodeValue">
    {{barcodeValue}}
  </div>
</div>
<!-- </div>

      

    </div> -->