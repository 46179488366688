import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SharedDataService } from '../services/shared-data.service';
import { SharedApiService } from '../services/shared-api.service';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-medcsepp-worklist',
  templateUrl: './medcsepp-worklist.component.html',
  styleUrls: ['./medcsepp-worklist.component.css']
})
export class MedcseppWorklistComponent implements OnInit {

  apiUrl = environment.apiUrl;
  version = environment.version;

  worklistLoading = false;
  saveInProgress = false;
  saveResult = null;
  saveErrorDescription = '';
  closeConfirmationDialogueVisible = false;
  screenMessage = '';

  workList = [];

  @ViewChild('filterTable', { static: true })
  filterTable: ElementRef;

  filterform: FormGroup;

  displayedColumns = ['date', 'name', 'ssn', 'bdate', 'status'];
  dataSource = new MatTableDataSource<any>();

  filterInput = new FormControl();

  labReqData = [];

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    private sharedDataService: SharedDataService,
    private sharedApiService: SharedApiService
  ) { }

  ngOnInit() {
    this.generateSamplelist();
  }



  closeResult() {
    this.saveErrorDescription = '';
    this.saveResult = null;
  }


  hideCloseConfirmationDialogue() {
    this.closeConfirmationDialogueVisible = false;
  }

  resetModal() {
    this.closeResult();
    // this.documentTypeSelectGroup.reset();
  }

  resetModalAndHideDialogue() {
    this.hideCloseConfirmationDialogue();
    // this.documentTypeSelectGroup.reset();
  }


  applyFilter(filterValue: string) {
    if (filterValue) {
      filterValue = filterValue.trim();
      filterValue = filterValue.toLowerCase();
      this.dataSource.data = this.workList;
      this.dataSource.filter = filterValue;
      this.dataSource.data = this.dataSource.filteredData;
    } else {
      this.filterTable.nativeElement.value = '';
      this.dataSource = new MatTableDataSource<any>(this.workList);
    }
  }

  // this.pristine = false;

  // let workList = [];
  //   if (responseData['code'] === 1) {
  //     workList = responseData['data'];
  //     workList.sort(this.sortEventLogRows);
  //     this.eventLogRows = eventLogRows;
  //     this.dataSource = new MatTableDataSource(eventLogRows);

  onPrintSampleList() {
    this.printSamplelist(this.labReqData);
  }

  generateSamplelist() {

    if (this.sharedDataService.getSelectedLaborService()) {
      this.worklistLoading = true;

      this.getLaborWorklist().subscribe(result => {
        this.worklistLoading = false;
        // console.log('Samplelist', result);
        if (result['code'] === 1) {
          if (result['data']) {
            this.labReqData = result['data'];
            this.dataSource = new MatTableDataSource(result['data']);
          } else {
            this.saveResult = -1;
            this.saveErrorDescription = 'Nincs laborvizsgálati elem a listában.';
            setTimeout(() => { this.saveResult = null; }, 3000);
          }
        } else {
          this.saveResult = -1;
          this.saveErrorDescription = result['code'] + ', ' + result['message'];
          setTimeout(() => { this.saveResult = null; }, 3000);
          // hiba
        }
      });
    }
    
  }

  sortSamplelist(a, b) {
    if (a['timeFrom'] > b['timeFrom']) {
      return 1;
    } else if (a['timeFrom'] < b['timeFrom']) {
      return -1;
    }
    return 0;
  }

  getLaborWorklist() {

    const today = this.sharedDataService.dateToDateString(new Date());

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      laborServiceId: this.sharedDataService.getSelectedLaborService().svId,
      dateFrom: today
    };
    return this.http.post(this.apiUrl + '/rest/neu/laborder/getlaborworklist', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }



  printSamplelist(data) {
    let content = `<title>Minta átadás-átvételi jegyzék</title>
    
    <head>
    <style>
    table.main {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }
    
    td.main, th.main {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }
    
    tr.main:nth-child(even) {
      background-color: #eeeeee;
    }
    </style>
    </head>
    <p>Mintavételi hely neve: Szolnok-Szandaszőlős mintavételi hely</p>
    <p>Mintavételi hely címe: 5008 Szolnok, Munkácsy Mihály u. 26.</p>
    <h2><center>Minta átadás-átvételi jegyzék</center></h2>
    <h3><center>` + this.sharedDataService.dateToDateTimeStringHuman(new Date()) + `</center></h3>
    <table class="main">
      <tr>
      <th class="main">Sorszám</th>
      <th class="main">Név</th>
      <th class="main">TAJ</th>
      <th class="main">Születési dátum</th>
      <th class="main">Cső azonosító</th>
      </tr>
      `;

    let i = 1;
    let j = 1;
    data.forEach(row => {
      content += `<tr>
        <td class="main">` + i + `</td>
        <td class="main">` + row['request']['patName'] + `</td>
        <td class="main">`  + row['request']['patSsn'] + `</td>
        <td class="main"> ` + row['request']['patBDate'] + `</td>
        <td class="main">
        <table>`;
      let samples = [];
      samples = row['samples'];
      samples.forEach(sample => {
        if (sample['smpCode']) {
          content +=
            `<tr>
              <td>` +
            sample['smpCode'] +
            `</td>
              </tr>`;
          j++;
        }
      });
      content +=

        `</table></tr>`;
      i++;
    });
    content += `</table>
    <br>
    A fent felsorolt `+ j + `. db mintát hiánytalanul és sérülés mentesen átadtam-átvettem.
    <br>
    <br>
    <br>
    <br>
    <table border="0" width="100%">
      <tr>
      <td width="10%">
      </td>
      <td width="30%"><center>
      .....................................<br>
      Átadó neve
      </center>
      </td>
      <td width="20%">
      </td>
      <td width="30%">
      <center>
      .....................................<br>
      Átvevő neve
      </center>
      </td>
      <td width="10%">
      </td>
      </tr>
      <tr>
      <td>&nbsp;<br>&nbsp;</td>
      </tr>
      <tr>
      <td width="10%">
      </td>
      <td width="30%">
      <center>
      .....................................<br>
      Átadó aláírása
      </center>
      </td>
      <td width="20%">
      </td>
      <td width="30%">
      <center>
      .....................................<br>
      Átvevő aláírása
      </center>
      </td>
      <td width="10%">
      </td>
      </tr>
    `;
    const newWindow = window.open('');
    newWindow.document.open();
    newWindow.document.write(content);
    newWindow.print();
    newWindow.document.close();
  }

}
