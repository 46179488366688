<!-- Modal -->

<div class="modal" id="arrivePatient" tabindex="-1" role="dialog" aria-labelledby="arrivePatient" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">

    <div [ngClass]="{'hidden': saveResult != 0}" class="fullSizeCover">
      <div class="fullSizeCoverContent">
        <div class="col-md-12 mt-4">
          <div class="row">
            <div class="col-md-12 mt-4 text-center">
              <h3><i class="fa fa-check-circle green mr-2"></i>Páciens sikeresen érkeztetve!</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mt-4 text-center">
              <button class="btn btn-success" data-dismiss="modal" (click)="onClose()">Bezárás</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-content">
      <div class="modal-header">
        <div id="saveError" [ngClass]="{'showResult': saveResult == -1}">
          <div class="closeResult" (click)="closeResult()">x</div>
          <div class="saveErrorContent">Hiba az érkeztetés során!
            <span>{{ saveErrorDescription }}</span>
          </div>
        </div>

        <h5 *ngIf="!isEkatModify" class="modal-title">Páciens érkeztetés ({{ patientDataValue['participantName'] }})</h5>
        <h5 *ngIf="isEkatModify" class="modal-title">Páciens eseménykatalógus módosítása ({{ patientDataValue['participantName'] }})</h5>
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="resetForm()">X</button>
      </div>

      <div class="modal-body">
        <div class="container-fluid" *ngIf="!isLoading">
          <div class="mb-5">
            <div class="container-fluid mt-3">
              <div class="row" [formGroup]="bnoFromGroup">
                <!-- <div class="col-sm-6">
                  <span *ngIf="!isEkatModify"><b>BNO főcsoport *</b></span>
                  <span *ngIf="isEkatModify">BNO főcsoport</span>
                  <ng-select
                    [searchFn]="customBnoSubSearchFn"
                    class="full-width"
                    [searchable]="true"
                    formControlName="bnoSubName"
                    [items]="bnoSubItems?.data"
                    (change)="getBnoListItems()"
                    [clearable]="true">
                      <ng-template ng-label-tmp let-item="item">
                        <span>{{ item.subGrpName + ' / ' + item.subGrpCode}}</span>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item">
                        <span >{{ item.subGrpName + ' / ' + item.subGrpCode }}</span>
                      </ng-template>
                  </ng-select>
                </div> -->
                <div class="col-sm-12">
                  <span *ngIf="!isEkatModify"><b>BNO alcsoport *</b></span>
                  <span *ngIf="isEkatModify">BNO alcsoport</span>
                  <ng-select
                    [searchFn]="customBnoListSearchFn"
                    class="full-width"
                    [searchable]="true"
                    formControlName="bnoList"
                    [virtualScroll]="true"
                    [items]="bnoListItems?.bnoList"
                    [loading]="bnoListLoading"
                    [clearable]="false">
                      <ng-template ng-label-tmp let-item="item">
                        <span>{{ item.bnoName + ' / ' + item.bnoCode}}</span>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item">
                        <span >{{ item.bnoName + ' / ' + item.bnoCode }}</span>
                      </ng-template>
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
          <form [formGroup]="treatmentDetails">
            <div class="row">

              <div class="col-md-4">
                <b>Kérem adja meg az ellátás módját! *</b>
                <div class="col-sm-12 form-item">
                  <ng-select class="full-width" [searchable]="true" placeholder="Ellátás módja"
                    formControlName="ellatasModja" [items]="careTypes['categories']" bindLabel="typeDesc"
                    bindValue="typeCode" (change)="onChangeCareCategory($event)" [loading]="careTypesLoading"
                    [clearable]="false" loadingText="Betöltés..." notFoundText="Nincs találat">
                  </ng-select>
                </div>
                <b>Kérem adja meg az ellátás típusát! *</b>
                <div class="col-sm-12 form-item">
                  <ng-select class="full-width" [searchable]="true" placeholder="Ellátás típusa"
                    formControlName="ellatasTipusa" [items]="careTypeList" bindLabel="description" bindValue="code"
                    [loading]="careTypesLoading" [clearable]="false" loadingText="Betöltés..." notFoundText="Nincs találat">
                  </ng-select>
                </div>
                <div *ngIf="ellatasModja.value==='ARVTYPE_INPAT'">
                  <b>Fekvőbeteg felvétel jellege *</b>
                  <div class="col-sm-12 form-item">
                    <ng-select class="full-width" [searchable]="true" placeholder="Fekvőbeteg felvétel jellege"
                      formControlName="admType" [items]="admissionTypes" bindLabel="description" bindValue="code"
                      [clearable]="false" [loading]="admissionTypesLoading" loadingText="Betöltés..." notFoundText="Nincs találat">
                    </ng-select>

                  </div>
                </div>
                <div *ngIf="showPatientBeuFields || showInstitutionalBeuFields">
                  <div>
                    <span *ngIf="showPatientBeuFields && !showInstitutionalBeuFields" >Páciens beutaló azonosító</span>
                    <span *ngIf="showInstitutionalBeuFields && !showPatientBeuFields" >Intézményi beutaló azonosító</span>
                    <span *ngIf="showInstitutionalBeuFields && showPatientBeuFields">beutaló azonosító</span>
                    <div class="col-sm-12 mb-2">
                      <input type="text" id="beutAzon" disabled class="form-control" formControlName="referralIdentification">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <b>Kérem adja meg az ellátó orvost! *</b>
                <div class="row">
                  <div class="col-sm-12 form-item">
                    <ng-select *ngIf="assignedDoctorsOnly.value" class="full-width" [searchable]="true" placeholder="Ellátó orvos"
                      formControlName="ellatoOrvos" [items]="filteredDoctorsList" bindLabel="nameWithTitle"
                      bindValue="id" [clearable]="false" [loading]="admissionTypesLoading" loadingText="Betöltés..." notFoundText="Nincs találat">
                    </ng-select>
                    <ng-select *ngIf="!assignedDoctorsOnly.value" class="full-width" [searchable]="true" placeholder="Ellátó orvos"
                    formControlName="ellatoOrvos" [items]="doctorsList" bindLabel="nameWithTitle"
                    bindValue="id" [clearable]="false" [loading]="admissionTypesLoading" loadingText="Betöltés..." notFoundText="Nincs találat">
                  </ng-select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-2 form-item">
                    <input type="checkbox" [formControl]="assignedDoctorsOnly">
                  </div>
                  <div class="col-10 form-item">
                    Csak a szolgáltatási ponthoz rendelt orvosok
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <b>Beteg EÜ azonosítója! *</b>
                <div class="col-sm-12 form-item">
                  <ng-select class="full-width" [searchable]="true" placeholder="Beteg EÜ azonosítója"
                  formControlName="sst" [items]="sstOptions" bindLabel="name" (change)="onChangeSst()"
                  bindValue="id" [clearable]="false" loadingText="Betöltés..." notFoundText="Nincs találat">
                </ng-select>
                  <div class="formError" *ngIf="sst.invalid && formSubmitted">Válasszon okmány típust!</div>
                </div>
                <div class="col-sm-12 form-item">
                  <input type="text" placeholder="Azonosító" class="form-control" [formControl]="ssn">
                  <div class="formError" *ngIf="ssn.invalid && formSubmitted">Nem megfelelő TAJ szám (minimum 4
                    karakter)</div>
                </div>

                <label for="sstd" class="col-sm-12 control-label" *ngIf="sst.value=='X'">Okmány típusának
                  leírása</label>
                <div class="col-sm-12 form-item" *ngIf="sst.value=='X'">
                  <input type="text" id="sstd" placeholder="Okmány típusának leírása" class="form-control"
                    [formControl]="sstd">
                  <div class="formError" *ngIf="sst.value == 'X' && sstd.invalid && formSubmitted">Nem megfelelő
                    leírás (minimum 3
                    karakter)</div>
                </div>

                <div *ngIf="sst.value=='1' && !isEkatModify" class="col-sm-12">
                  <h3>Az ellátás során a beteg a TAJ használathoz: *</h3>
                  <input type="radio" value="true" [formControl]="consent" class="form-control radio-item">
                  <div class="form-control radio-item">Hozzájárul</div>
                  <input type="radio" value="false" [formControl]="consent" class="form-control  radio-item">
                  <div class="form-control radio-item">Nem járul hozzá</div>
                  <div class="formError" *ngIf="ssn.invalid && formSubmitted">Töltse ki a hozzájárulást!</div>
                </div>

                <!-- <div class="col-md-12 center margin-top" style="text-align: right" *ngIf="!isEkatModify">
                  <button class="btn btn-primary" (click)="onArrivePatient()"
                    [disabled]="!treatmentDetails.valid || saveInProgress || !bnoFromGroup.controls.bnoList.value">
                    <i *ngIf="saveInProgress" class="fa fa-spinner fa-spin"></i> Ellátás megkezdése</button>
                </div> -->
              </div>
            </div>
          </form>

          <!-- <div>
            <div class="container-fluid mt-3">
              <div class="row" [formGroup]="bnoFromGroup">
                <div class="col-sm-6">
                  bno főcsoport
                  <ng-select
                    [searchFn]="customBnoSubSearchFn"
                    class="full-width"
                    [searchable]="true"
                    formControlName="bnoSubName"
                    [items]="bnoSubItems?.data"
                    (change)="getBnoListItems()"
                    [clearable]="true">
                      <ng-template ng-label-tmp let-item="item">
                        <span>{{ item.subGrpName + ' / ' + item.subGrpCode}}</span>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item">
                        <span >{{ item.subGrpName + ' / ' + item.subGrpCode }}</span>
                      </ng-template>
                  </ng-select>
                </div>
                <div class="col-sm-6">
                  bno alcsoport
                  <ng-select
                    [searchFn]="customBnoListSearchFn"
                    class="full-width"
                    [searchable]="true"
                    formControlName="bnoList"
                    [items]="bnoListItems?.bnoList"
                    [loading]="bnoListLoading"
                    [clearable]="false">
                      <ng-template ng-label-tmp let-item="item">
                        <span>{{ item.bnoName + ' / ' + item.bnoCode}}</span>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item">
                        <span >{{ item.bnoName + ' / ' + item.bnoCode }}</span>
                      </ng-template>
                  </ng-select>
                </div>
              </div>
            </div>
          </div> -->
          <div class="col-md-12 center margin-top" style="text-align: right" *ngIf="!isEkatModify">
            <button class="btn btn-primary" (click)="onArrivePatient()"
              [disabled]="!treatmentDetails.valid || saveInProgress || !bnoFromGroup.controls.bnoList.value">
              <i *ngIf="saveInProgress" class="fa fa-spinner fa-spin"></i> Ellátás megkezdése</button>
          </div>
          <div class="container-fluid mt-3" *ngIf="isEkatModify">
            <div *ngIf="admTypeCat === 'ARVTYPE_OUTPAT'">
              <form [formGroup]="treatmentDetailsOutpat">
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <label for="ellatoOrvos" class="col-sm-4 control-label">Ellátó osztály</label>
                      <div class="col-sm-8 form-item">
                        <ng-select class="full-width" [searchable]="true" placeholder="Ellátó osztály"
                          formControlName="ellatoOsztaly" [items]="availServic" bindLabel="spName" bindValue="spId"
                          [clearable]="false" [compareWith]="compareSpId"
                          loadingText="Betöltés..." notFoundText="Nincs találat"></ng-select>
                      </div>

                      <label for="admDate" class="col-sm-4 control-label">Ellátás kezdete</label>
                      <div class="col-sm-8 form-item">
                        <input type="datetime-local" id="admDate" placeholder="Ellátás kezdete" class="form-control"
                          [formControl]="admDate">
                      </div>

                      <label for="dscDate" class="col-sm-4 control-label">Ellátás vége</label>
                      <div class="col-sm-8 form-item">
                        <input type="datetime-local" id="dscDate" placeholder="Ellátás kezdete" class="form-control"
                          [formControl]="dscDate">
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="row">

                      <label for="appNumber" class="col-sm-4 control-label">Naplószám</label>
                      <div class="col-sm-8 form-item">
                        <input type="text" id="appNumber" placeholder="Naplószám" class="form-control"
                          [formControl]="appNumber">
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div *ngIf="admTypeCat === 'ARVTYPE_INPAT'">
              <form [formGroup]="treatmentDetailsInpat">
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <label for="ellatoOrvos" class="col-sm-4 control-label">Ellátó osztály</label>
                      <div class="col-sm-8 form-item">
                        <ng-select class="full-width" [searchable]="true" placeholder="Ellátó osztály"
                          formControlName="ellatoOsztaly" [items]="availServic" bindLabel="spName" bindValue="spId"
                          [clearable]="false" [compareWith]="compareSpId"
                          loadingText="Betöltés..." notFoundText="Nincs találat"></ng-select>
                      </div>

                      <label for="admDate" class="col-sm-4 control-label">Ellátás kezdete</label>
                      <div class="col-sm-8 form-item">
                        <input type="datetime-local" id="admDate" placeholder="Ellátás kezdete" class="form-control"
                          [formControl]="admDate">
                      </div>

                      <label for="dscDate" class="col-sm-4 control-label">Ellátás vége</label>
                      <div class="col-sm-8 form-item">
                        <input type="datetime-local" id="dscDate" placeholder="Ellátás kezdete" class="form-control"
                          [formControl]="dscDate">
                      </div>

                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="row">

                      <label for="tovabbiSors" class="col-sm-4 control-label">További sors</label>
                      <div class="col-sm-8 form-item">
                        <ng-select class="full-width" [searchable]="true" placeholder="További sors"
                          formControlName="tovabbiSors" [items]="getAwayTypes" bindLabel="description" bindValue="code"
                          [clearable]="false" loadingText="Betöltés..." notFoundText="Nincs találat">
                        </ng-select>
                      </div>


                      <label for="appNumber" class="col-sm-4 control-label">Törzsszám</label>
                      <div class="col-sm-8 form-item">
                        <input type="text" id="appNumber" placeholder="Törzsszám" class="form-control"
                          [formControl]="appNumber">
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-12 center margin-top" style="text-align: right" *ngIf="isEkatModify">
            <button class="btn btn-primary" (click)="updateArrivedPatientEkat()"
              [disabled]="saveInProgress">
              <i *ngIf="saveInProgress" class="fa fa-spinner fa-spin"></i> eseménykatalógus módosítás</button>
          </div>



        </div>
        <div class="container-fluid" *ngIf="isLoading">Loading...</div>

      </div>
      <div class="mb-2 ml-5">
        <button class="btn btn-primary" *ngIf="isEkatModify" (click)="deleteEkatDocument()"><fa-icon class="deleteEkatIcon" [icon]="faTrash"></fa-icon> esemény törlése az EESZT-ből</button>
      </div>
      <div class="mb-2 ml-5">
        <input type="checkBox" [formControl]="showPatientBeuFormControl" (change)="showPatientBeu()" class="mr-1">
        <span>páciens beutaló csatolása</span>
      </div>
      <div class="mb-2 ml-5">
        <input type="checkBox" [formControl]="showInstitutionalBeuFormControl" (change)="showInstitutionalBeu()" class="mr-1">
        <span>intézményi beutaló csatolása</span>
      </div>
      <div [ngClass]="{'hidden': !showPatientBeuFields && !showInstitutionalBeuFields}" class="mb-3">
        <div class="col-md-12" [formGroup]="patientBeuFormGroup">
          <div class="row">
              <div class="col-sm">
                <label for="patientDateFrom">tól</label>
                <div class="input-group mr-1">
                  <input class="form-control datepicker_mobile" placeholder="yyyy-mm-dd" name="dp" [minDate]="{year: 1900, month: 1, day: 1}"
                    formControlName="patientDateFrom" ngbDatepicker #dFrom="ngbDatepicker">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="dFrom.toggle()"
                      type="button"></button>
                  </div>
                </div>
              </div>
              <div class="col-sm">
                <label for="patientDateto">ig</label>
                <div class="input-group mr-1">
                  <input class="form-control datepicker_mobile" placeholder="yyyy-mm-dd" name="dp" [minDate]="{year: 1900, month: 1, day: 1}"
                    formControlName="patientDateTo" ngbDatepicker #dTo="ngbDatepicker">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="dTo.toggle()"
                      type="button"></button>
                  </div>
                </div>
              </div>
          </div>
          <div class="row">
            <div class="col-sm-6 form-group">
                <input type="checkBox" id="breakGlass" formControlName="breakGlass">
                <label for="breakGlass" class="ml-1">sürgősségi adatlekérés</label>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 form-group">
              <label for="breakGlassReason" class="ml-1">sürgősségi adatlekérés indoklása</label>
              <input type="text" id="breakGlassReason" class="form-control" formControlName="breakGlassReason">
            </div>
            <div class="col-sm-6 form-group">
              <div *ngIf="this.breakGlass.value" class="alert alert-danger" role="alert" style="text-align: justify; text-justify: inter-word;">
                A "sürgősségi adatlekérés" csak a következő esetekben használható jogszerűen:<br>
                      1.) Sürgős esetben, ha a páciens nem tud, nem képes eseti rendelkezést tenni. (Az Eüak. 10. § (4) bekezdése szerint sürgős szükség esetén a kezelést végző orvos által ismert, a gyógykezeléssel összefüggésbe hozható minden egészségügyi és személyazonosító adat továbbítható.) <br>
                      2.) A páciens 24 órás egyedi engedélye alapján (minta az e-egeszegugy.gov.hu oldalon letölthető).
              </div>
            </div>
          </div>
          <button class="btn btn-secondary" (click)="getPatientBeuDocumentList()">
            <i *ngIf="ehrDetailsInProgress" class="fa fa-spinner fa-spin"></i>Keresés</button>
            <mat-table *ngIf="patientEbeuList" [dataSource]="patientEbeuList" matSort>
              <ng-container matColumnDef="patientName">
                <mat-header-cell *matHeaderCellDef> beteg neve </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.businessContent.beteg.nev.firstName + ' ' + element.businessContent.beteg.nev.lastName}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="patientSsn">
                <mat-header-cell mat-header-cell *matHeaderCellDef> beteg tajszáma </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.businessContent.beteg.taj}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="organizationName">
                <mat-header-cell *matHeaderCellDef> beutaló intézmény neve </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.businessContent.beutaloIntezmeny.intezmenyMegnevezes}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="doctorName">
                <mat-header-cell *matHeaderCellDef> beutaló orvos </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.businessContent.beutaloOrvos.firstName + ' ' + element.businessContent.beutaloOrvos.lastName}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="docStampNo">
                <mat-header-cell *matHeaderCellDef> beutaló pecsétszáma </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.businessContent.beutaloOrvos.stampNo}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="createDate">
                <mat-header-cell *matHeaderCellDef> kiállítá dátuma </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.businessContent.kiallitasDatuma | date: 'yyyy/MM/dd'}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="expirationDate">
                <mat-header-cell *matHeaderCellDef> érvényesség ideje </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.businessContent.ervenytessegDatuma | date: 'yyyy/MM/dd'}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="copyReferralId">
                <mat-header-cell *matHeaderCellDef> beutaló azonosító kiválasztása </mat-header-cell>
                <mat-cell *matCellDef="let element"> <fa-icon class="selectIdIcon" (click)="copyReferralId(element)" [icon]="faCopy"></fa-icon> </mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
      </div>
    </div>
  </div>
</div>
