import { environment } from './../../environments/environment.test';
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { SharedDataService } from '../services/shared-data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { NgxPrintModule } from 'ngx-print';
import { SharedApiService } from '../services/shared-api.service';

@Component({
  selector: 'app-dolg-group-permission',
  templateUrl: './dolg-group-permission.component.html',
  styleUrls: ['./dolg-group-permission.component.css']
})

export class DolgGroupPermissionComponent implements OnInit, OnDestroy {

  constructor(public authService: AuthService,
    private sharedApiService: SharedApiService,
    private http: HttpClient,
    private sharedDataService: SharedDataService
  ) { }

  employmentDataPositionSubscription: Subscription;
  employmentDataPosition = [];
  employmentDataPositionLoading = true;
   
  saveInProgress = false;
  saveResult = null;
  successMessage = '';
  saveErrorDescription = '';

  acDeviceListSubscription: Subscription;
  acDeviceList = [];
  eventLogRows = [];
  loading = false;
  pristine = true;
  showImage = false;
  imageLoading = true;
  eventEntry = {};
  imageError = false;

  userId = null;

  detailsName = '';
  detailsPosition = '';
  detailsRole = '';
  detailsRegPicId = null;
  detail = null;
  detailLoading = false;

  documents = null;
  documentsLoading = false;
  documentsCount = 0;

  patientListLoading = true;
  patientListDataSubscription: Subscription;
  patientList = [];
  tapass = '';

  employmentPosition = new FormControl('', [Validators.required]);

  today = new Date();
  endDate = new Date(this.today.getTime() + 7776000000);

  startDateString = this.today.getFullYear() + '-' +
    (('0' + (this.today.getMonth() + 1)).slice(-2)) + '-' +
    (('0' + this.today.getDate()).slice(-2));

  endDateString = this.endDate.getFullYear() + '-' +
    (('0' + (this.endDate.getMonth() + 1)).slice(-2)) + '-' +
    (('0' + this.endDate.getDate()).slice(-2));

  dateFromInput = new FormControl(this.sharedDataService.dateStringToNgbDate(this.startDateString), Validators.required);
  dateToInput = new FormControl(this.sharedDataService.dateStringToNgbDate(this.endDateString), Validators.required);
  acDeviceFilter = new FormControl('', [Validators.required]);

  requestDetails = new FormGroup({
    dateFromInput: this.dateFromInput,
    dateToInput: this.dateToInput,
    acDeviceFilter: this.acDeviceFilter,
    employmentPosition: this.employmentPosition
    });

  dateChangeSubscriptionStart: Subscription;
  dateChangeSubscriptionEnd: Subscription;
  refreshAppointmentsSubscription: Subscription;

  dateInvalidStart = null;
  dateInvalidEnd = null;

  apiUrl = environment.apiUrl;
  environment = environment;

  ngOnInit() {
    // this.dataSource.paginator = this.paginator;

    this.acDeviceListSubscription = this.sharedDataService.acDeviceListShared.subscribe(data => {
      const acDeviceList = data;
      acDeviceList.sort(this.sortAcDeviceList);
      // console.log ('acdevlist', acDeviceList);
      this.acDeviceList = acDeviceList;
      // this.acDeviceList = this.environment.acDeviceListTemp;
      // console.log ('adDeviceList', this.acDeviceList);
    });

    this.patientListDataSubscription = this.sharedDataService.patientListShared.subscribe((patientList) => {
      this.patientList = [];
      // console.log ('register patient - patient list updated', patientList);
      this.patientListLoading = false;
      this.patientList = patientList;

    });

    this.employmentDataPositionSubscription = this.sharedDataService.employmentDataPositionShared.subscribe(data => {
      this.employmentDataPositionLoading = false;
      this.employmentDataPosition = data;
      // console.log ('employmentData', this.employmentData);
    });

  }

  ngOnDestroy() {
    if (this.acDeviceListSubscription) {
      this.acDeviceListSubscription.unsubscribe();
    }
    if (this.patientListDataSubscription) {
      this.patientListDataSubscription.unsubscribe();
    }
    if (this.employmentDataPositionSubscription) {
      this.employmentDataPositionSubscription.unsubscribe();
    }
  }
  closeResult() {
    this.saveResult = null;
    this.saveErrorDescription = '';
  }

  onManageCheckPermission() {
    this.saveInProgress = true;
    this.manageCheckPermission(this.userId).subscribe(
      (permissionData: Response) => {
        this.saveInProgress = false;
        if (permissionData['code'] === 1) {
          this.saveResult = 0;
          this.successMessage = 'Jogosultságok mentése sikeres!';
          setTimeout(() => {this.saveResult = null;}, 2000);
          console.log ('manageCheckPermission', permissionData);

        } else {
          this.saveResult = -1;
          this.saveErrorDescription = 'Hiba a jogosultság mentése során: ' + permissionData['code'];
        }
      });
  }

  manageCheckPermission(userId) {

    let acDeviceFilterValue = [];
    if (this.acDeviceFilter.value.length < 1) {
      acDeviceFilterValue = [this.acDeviceFilter.value];
    } else {
      acDeviceFilterValue = this.acDeviceFilter.value;
    }

    const startDateString = this.sharedDataService.NgbDateToDateString(this.dateFromInput.value);
    const endDateString = this.sharedDataService.NgbDateToDateString(this.dateToInput.value);

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      deviceIdList: acDeviceFilterValue,
      validFrom : startDateString,
      validTo : endDateString,
      granted: '1',
      positionDescriptionType: 'medicalPosition',
      positionDescriptionList : [this.employmentPosition.value]
    };

    return this.http.post(this.apiUrl + '/rest/neu/dlm/managecheckpermission', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }



  resetModal() {
    this.acDeviceFilter.reset();
    this.pristine = true;
    this.employmentPosition.reset();
  }


  sortAcDeviceList(a, b) {
    return new Intl.Collator('hu').compare(a['deviceName'], b['deviceName']);  // -1
  }

  
}
