<!-- Modal -->
<div class="modal" id="settings" tabindex="-1" role="dialog" aria-labelledby="settings" aria-hidden="true">
    <div class="modal-dialog modal-lg " role="document">

        <div [ngClass]="{'hidden': !closeConfirmationDialogueVisible}" id="quitConfirmation" class="settingsQuitConfirmation">
            <div class="quitConfirmationContent">
                <h6>Az adatok megtartása a későbbi folytatáshoz?</h6>
                <div class="col-md-12 mt-4">
                    <div class="row">
                        <div class="col-md-4">
                            <button type="button" class="btn btn-primary" (click)="hideCloseConfirmationDialogue()" data-dismiss="modal">Igen</button>
                        </div>
                        <div class="col-md-4">
                            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="resetModalAndHideDialogue()">Nem</button>
                        </div>
                        <div class="col-md-4">
                            <button type="button" class="btn btn-secondary" (click)="hideCloseConfirmationDialogue()">Mégsem</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div [ngClass]="{'hidden': !saveInProgress}" class="settingsQuitConfirmation">
            <div class="quitConfirmationContent">
                Mentés folyamatban...
            </div>
        </div>

        <div [ngClass]="{'hidden': screenMessage == ''}" class="settingsQuitConfirmation">
            <div class="quitConfirmationContent">
                <div class="row">
                    <div class="col-12 text-center mb-4">{{ screenMessage }}</div>
                </div>
                <div class="row">
                    <div class="col-12 text-center">
                        <button type="button" class="btn btn-secondary" (click)="clearScreenMessage()">Ok</button>
                    </div>
                </div>

            </div>
        </div>

        <div class="modal-content modal-fh">
            <div class="modal-header">

                <div id="saveSuccess" [ngClass]="{'showResult': saveResult == 0}">
                    Beállítások sikeresen mentve!
                </div>
                <div id="saveError" [ngClass]="{'showResult': saveResult == -1}">
                    <div class="closeResult" (click)="closeResult()">x</div>
                    <div class="saveErrorContent">Hiba!
                        <span>{{ saveErrorDescription }}</span>
                    </div>
                </div>
                <h5 class="modal-title" id="exampleModalLongTitle">Beállítások</h5>
                <div class="col-md-6">
                </div>
                <button type="button" class="btn btn-secondary" (click)="resetModal()" data-dismiss="modal">X</button>
            </div>
            <!-- <div>
        <ul class="nav nav-tabs">
          <li class="nav-item" *ngIf="Brand !== 'MEDCSEPP' && Brand !== 'UDMED' && Brand !== 'DOLGEK'">
            <a class="nav-link" [ngClass]="{'active': activeTab == 0}" (click)="switchTab(0)">Implant reg export</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab == 1}" (click)="switchTab(1)">Jelszó változtatás</a>
          </li>
          <li class="nav-item" *ngIf="Brand !== 'DOLGEK'">
            <a class="nav-link" [ngClass]="{'active': activeTab == 2}" (click)="switchTab(2)">Pácienslista</a>
          </li>
          <li class="nav-item" *ngIf="Brand !== 'UDMED' && Brand !== 'DOLGEK'">
            <a class="nav-link" [ngClass]="{'active': activeTab == 3}" (click)="switchTab(3)">Számlázás</a>
          </li>
          <li class="nav-item" *ngIf="Brand === 'MEDCSEPP'">
            <a class="nav-link" [ngClass]="{'active': activeTab == 4}" (click)="switchTab(4)">Medcsepp beállítások</a>
          </li>
          <li class="nav-item" *ngIf="Brand === 'MEDCSEPP'">
            <a class="nav-link" [ngClass]="{'active': activeTab == 5}" (click)="switchTab(5)">Munkalista generálás</a>
          </li>
          <li class="nav-item" *ngIf="Brand === 'MEDCSEPP' && medcseppFeatureSet === 'FULL'">
            <a class="nav-link" [ngClass]="{'active': activeTab == 6}" (click)="switchTab(6)">Szállítási lista generálás</a>
          </li>
        </ul>

      </div> -->
            <div class="modal-body">
                <div class="version">{{ version }}</div>
                <div class="container-fluid">
                    <mat-tab-group #settingsMainTabGroup [(selectedIndex)]="activeTab">
                        <mat-tab *ngIf="Brand !== 'MEDCSEPP' && Brand !== 'UDMED' && Brand !== 'DOLGEK'" label="Implant reg export">
                            <ng-template matTabContent>
                                <div class="col-md-12">
                                    <form [formGroup]="implantRegExport">
                                        <label for="serviceSelect" class="col-sm-4 control-label">Szolgáltatási pont</label>
                                        <div class="col-sm-8 form-item">
                                            <ng-select class="full-width" [searchable]="true" placeholder="Szolgáltatási pont" formControlName="serviceSelect" [items]="availServic" bindLabel="spName" bindValue="spId" [clearable]="false" loadingText="Betöltés..." notFoundText="Nincs találat"></ng-select>
                                        </div>

                                        <label for="dateFrom" class="col-sm-4 control-label">Kezdő dátum<span class="mandatory">*</span></label>
                                        <div class="col-sm-8 form-item">
                                            <input type="date" placeholder="Kezdő dátum" class="form-control" [formControl]="dateFrom" max="{{ dateMax }}" min="{{ dateMin }}">
                                            <div class="formError" *ngIf="dateFrom.invalid && formSubmitted">Nem megfelelő kezdő dátum</div>
                                        </div>

                                        <label for="dateTo" class="col-sm-4 control-label">Vég dátum<span class="mandatory">*</span></label>
                                        <div class="col-sm-8 form-item">
                                            <input type="date" placeholder="Vég dátum" class="form-control" [formControl]="dateTo" max="{{ dateMax }}" min="{{ dateMin }}">
                                            <div class="formError" *ngIf="dateTo.invalid && formSubmitted">Nem megfelelő vég dátum</div>
                                        </div>

                                    </form>
                                    <button type="button" class="btn btn-primary" (click)="generateExcel()">Excel file generálása</button>
                                    <div class="help">
                                        <i class="fa fa-lg fa-question-circle" (click)="openHelp('#group-add-implant')" title="kézikönyv"></i>
                                    </div>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="Jelszó változtatás">
                            <ng-template matTabContent>
                                <div class="col-md-12">
                                    <div class="row mt-4">
                                        <div class="col-md-2"></div>
                                        <div class="col-md-8">
                                            <form class="form-dropdown-select" [formGroup]="changePasswordForm" (change)="validatePasswordForm()">
                                                <div class="row">
                                                    <!-- <label for="oldPassword" class="col-sm-4 control-label">Régi jelszó</label>
                          <div class="col-sm-8 form-item">
                            <input type="password" id="oldPassword" autocomplete="current-password" placeholder="Régi jelszó"
                              class="form-control" [formControl]="oldPassword" (change)="validatePasswordForm()">
                            <div class="formError" *ngIf="oldPassword.invalid && formSubmitted">Nem megfelelő telefonszám
                              (minimum
                              8 karakter)</div>
                          </div> -->

                                                    <mat-form-field class="col-sm-8 form-item">
                                                        <mat-label>Régi jelszó</mat-label>
                                                        <input matInput [formControl]="oldPassword" type="password" autocomplete="new-password">
                                                    </mat-form-field>

                                                    <!-- <label for="newPassword" class="col-sm-4 control-label">Új jelszó</label>
                          <div class="col-sm-8 form-item">
                            <input type="password" id="newPassword" autocomplete="new-password" placeholder="Új jelszó"
                              class="form-control" [formControl]="newPassword" (change)="validatePasswordForm()">
                            <div class="formError" *ngIf="newPassword.invalid && formSubmitted">Nem megfelelő telefonszám
                              (minimum
                              8 karakter)</div>
                          </div> -->

                                                    <mat-form-field class="col-sm-8 form-item">
                                                        <mat-label>Új jelszó</mat-label>
                                                        <input matInput [formControl]="newPassword" type="password" autocomplete="new-password">
                                                        <mat-password-strength class="password-strength-progressbar" #passwordComponentWithValidation [password]="newPassword.value"></mat-password-strength>
                                                        <mat-password-strength-info *ngIf="showPasswordStrengthSuggestionts.value" class="password-strength-info" lowerCaseCriteriaMsg="legalább 1 kisbetű ajánlott" upperCaseCriteriaMsg="legalább 1 nagybetű ajánlott" digitsCriteriaMsg="legalább 1 szám ajánlott"
                                                            specialCharsCriteriaMsg="legalább 1 speciális karakter ajánlott" minCharsCriteriaMsg="legalább 8 karakter ajánlott" [passwordComponent]="passwordComponentWithValidation">
                                                        </mat-password-strength-info>
                                                    </mat-form-field>
                                                    <mat-slide-toggle class="m-auto" [formControl]="showPasswordStrengthSuggestionts">Jelszó validálása</mat-slide-toggle>

                                                    <!-- <label for="newPassword2" class="col-sm-4 control-label">Új jelszó ismét</label>
                          <div class="col-sm-8 form-item">
                            <input type="password" id="newPassword2" autocomplete="new-password" placeholder="Új jelszó ismét"
                              class="form-control" [formControl]="newPassword2" (change)="validatePasswordForm()">
                            <div class="formError" *ngIf="newPasswordsValid == -2">A két új jelszó nem egyezik meg!</div>
                            <div class="formError" *ngIf="newPasswordsValid == -3">A két új jelszó nem egyezik meg!</div>
                          </div>
                          <input type="text" name="username" [formControl]="username" style="display: none;"> -->

                                                    <mat-form-field class="col-sm-8 form-item">
                                                        <mat-label>Új jelszó ismét</mat-label>
                                                        <input matInput [formControl]="newPassword2" type="password" autocomplete="new-password">
                                                    </mat-form-field>

                                                    <div class="col-sm-8">
                                                        <span *ngIf="newPassword.value && newPassword2.value && newPassword.value !== newPassword2.value" class="formError"><b>A két új jelszó nem egyezik meg!</b></span>
                                                        <span *ngIf="oldPassword.value && newPassword.value && oldPassword.value === newPassword.value" class="formError"><b>Az új jelszó megegyzezik a régivel</b></span>
                                                    </div>

                                                    <div class="col-sm-12" style="text-align: right;">
                                                        <button type="button" class="btn btn-danger" [disabled]="!changePasswordForm.valid || (newPasswordsValid !== 1) || saveInProgress" (click)="onChangePassword()">Új jelszó mentése</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="col-md-2"></div>
                                    </div>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab *ngIf="Brand !== 'DOLGEK'" label="Pácienslista">
                            <ng-template matTabContent>
                                <div class="col-md-12" style="min-height: 400px;">
                                    <table class="w-50">
                                        <tr>
                                            <th>#</th>
                                            <th class="w-25">Név</th>
                                            <th class="w-25">Szül. dátum</th>
                                            <th class="w-25">EÜ azonosító</th>
                                            <th class="w-25">Felhasználónév</th>
                                        </tr>
                                        <tr *ngFor="let patient of patientList; let i = index">
                                            <td>{{ i }}</td>
                                            <td>{{ patient['currName']}}</td>
                                            <td>{{ patient['chk4TDate'] | date:'yyyy-MM-dd'}}</td>
                                            <td>{{ patient['medicalId'] }}</td>
                                            <td>{{ patient['loginName'] }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <!-- <mat-tab *ngIf="Brand !== 'UDMED' && Brand !== 'DOLGEK'" label="Számlázás">
                            <ng-template matTabContent>
                                <div class="col-md-12">
                                    <div class="row mt-4">
                                        <div class="col-md-2"></div>
                                        <div class="col-md-8">
                                            <p>
                                                <strong>Amennyiben Ön a NauCare szoftver segítségével kíván számlázni, kérem olvassa el figyelmesen az
                          alábbi információkat!</strong>
                                            </p>
                                            <p>
                                                A NauCare szoftverben való számlázáshoz nélkülözhetetlen az Ön cégének <a href="https://szamlazz.hu" target="_blank">szamlazz.hu</a> online számlázó szoftverbe történő regisztrálása. Annak érdekében, hogy
                                                a NauCare rendszer tudjon az Ön cége nevében számlát kiállítani - vagyis a páciens számlázási adatait és az elvégzett szolgáltatásokat, és azok díját átadni a <a href="https://szamlazz.hu" target="_blank">szamlazz.hu</a>                                                részére - szükséges Társaságunkkal egy megállapodás, melynek keretein belül Ön rendelkezésünkre bocsátja a <a href="https://szamlazz.hu" target="_blank">szamlazz.hu</a> belépési nevét és jelszavát.
                                            </p>
                                            <p>
                                                A NauCare rendszer a szamlazz.hu „megbízott automatikus számlakibocsátás” funkcióját használja. Az Ön által regisztrált fiókot az üzemeltető egy bizonyos Agent díjjal terhel, melynek alakulása a kibocsátott számlák függvényében a következő:
                                            </p>
                                            <table class="szamlazzhu">
                                                <tr>
                                                    <th>Papír alapú és elektronikus bizonylatok darabszáma havonta</th>
                                                    <th>Havi díj</th>
                                                </tr>
                                                <tr>
                                                    <td>0</td>
                                                    <td>Nincs havi díj</td>
                                                </tr>
                                                <tr>
                                                    <td>1 – 50</td>
                                                    <td>1 905 Ft (1 500 Ft+áfa)</td>
                                                </tr>
                                                <tr>
                                                    <td>51 – 3 000</td>
                                                    <td>6 350 Ft (5 000 Ft+áfa)</td>
                                                </tr>
                                                <tr>
                                                    <td>3 001 – 10 000</td>
                                                    <td>9 525 Ft (7 500 Ft+áfa)</td>
                                                </tr>
                                                <tr>
                                                    <td>10 001 – 30 000</td>
                                                    <td>11 430 Ft (9 000 Ft+áfa)</td>
                                                </tr>
                                                <tr>
                                                    <td>30 001 – 100 000</td>
                                                    <td>19 050 Ft (15 000 Ft+áfa)</td>
                                                </tr>
                                                <tr>
                                                    <td>100 001 – 300 000</td>
                                                    <td>35 560 Ft (28 000 Ft+áfa)</td>
                                                </tr>
                                            </table>
                                            <p>Az Agent díj és megbízott automatikus számlázás kapcsán bővebb információk a <a href="https://szamlazz.hu" target="_blank">szamlazz.hu</a> oldalon állnak rendelkezésére.</p>
                                            <p>Bármilyen felmerülő kérdés esetén az <a href="mailto:info@econsult.hu">info@econsult.hu</a> email címen forduljon hozzánk bizalommal vagy a <a href="tel:06213833789">06 21 3833789</a> számon hívja telefonos
                                                ügyfélszolgálatunkat.
                                            </p>
                                        </div>
                                        <div class="col-md-2"></div>
                                    </div>
                                </div>
                            </ng-template>
                        </mat-tab> -->
                        <mat-tab *ngIf="Brand === 'MEDCSEPP'" label="Medcsepp beállítások">
                            <ng-template matTabContent>
                                <div class="col-md-12" style="min-height: 300px;">
                                    <form [formGroup]="csIdSelectForm">
                                        <ng-select class="" [searchable]="true" placeholder="Válasszon diszpécserpontot" formControlName="csIdSelect" [items]="csIdList" bindLabel="name" (change)="onSelectedCsIdChange($event)" [loading]="csIdListLoading" loadingText="Betöltés..." notFoundText="Nincs találat">
                                        </ng-select>

                                        <div *ngIf="allPrintersOfInstitute && allPrintersOfInstitute.length > 0" class="welcome">Kérem válasszon nyomtatót!
                                        </div>
                                        <ng-select *ngIf="allPrintersOfInstitute && allPrintersOfInstitute.length > 0" formControlName="printerCsIdSelect" (change)="onChangeSelectedPrinter($event)" class="selectDevice" [clearable]="true" [closeOnSelect]="true" [items]="allPrintersOfInstitute"
                                            bindLabel="name" [searchable]="true">
                                        </ng-select>

                                        <div *ngIf="medcseppFeatureSet === 'FULL'">
                                            <div class="welcome">Kérem válasszon céllabort!
                                            </div>
                                            <ng-select *ngIf="laborServiceList && laborServiceList.length > 0" formControlName="laborServiceSelect" bindLabel="spName" (change)="onChangeLaborServiceList($event)" class="selectDevice" [clearable]="true" [closeOnSelect]="true" [items]="laborServiceList"
                                                [searchable]="true">
                                            </ng-select>
                                        </div>

                                        <div>
                                            <div class="welcome">Kérem válasszon szolgáltatást!</div>
                                            <ng-select *ngIf="laborServiceList && laborServiceList.length > 0" formControlName="laborEventTypeSelect" bindLabel="etName" (change)="onChangeLaborEventType($event)" class="selectDevice" [clearable]="true" [closeOnSelect]="true" [items]="laborEventTypes"
                                                [searchable]="true">
                                            </ng-select>
                                        </div>

                                    </form>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab *ngIf="Brand === 'MEDCSEPP'" label="Munkalista generálás">
                            <ng-template matTabContent>
                                <div class="col-md-12" style="min-height: 300px;">
                                    <form [formGroup]="worklistGenerate">
                                        <div class="input-group mr-1 mb-2">
                                            <input class="form-control datepicker_mobile main_datepicker" placeholder="yyyy-mm-dd" name="dp" [minDate]="{year: 1900, month: 1, day: 1}" formControlName="worklist_date" ngbDatepicker #d="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                            </div>
                                        </div>
                                        <button type="button" class="btn btn-primary" (click)="generateWorklist()" [disabled]="worklistLoading">
                      <i *ngIf="worklistLoading" class="fa fa-spinner fa-spin"></i> Munkalista generálása</button>
                                    </form>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab *ngIf="Brand === 'MEDCSEPP' && medcseppFeatureSet === 'FULL'" label="Szállítási lista generálás">
                            <ng-template matTabContent>
                                <div class="col-md-12" style="min-height: 300px;">
                                    <button type="button" class="btn btn-primary" (click)="generateSamplelist()" [disabled]="worklistLoading">
                    <i *ngIf="worklistLoading" class="fa fa-spinner fa-spin"></i>Szállítási lista generálása</button>
                                </div>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>