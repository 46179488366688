<ngb-datepicker [displayMonths]="displayMonths" (dateSelect)="onDateSelection($event)" [navigation]="navigation" [showWeekNumbers]="showWeekNumbers" [outsideDays]="outsideDays" [dayTemplate]="customDay" [minDate]="{year: 1980, month: 1, day: 1}" [(ngModel)]="datepickerModel"
    [disabled]="datepickerDisabled">
</ngb-datepicker>

<!-- <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled"
    let-focused="focused">
    <div class="{{ dayStatus(date) }} custom-day " [class.focused]="focused" [class.bg-primary]="selected"
      [class.hidden]="date.month !== currentMonth" [class.text-muted]="disabled" (click)="dateChanged(date)">
      {{ date.day }}
  </div>
  </ng-template> -->

<ng-template #customDay let-date let-focused="focused">
    <span class="{{ dayStatus(date) }} custom-day" [class.focused]="focused" [ngClass]="[isStartAndEnd(date) ? 'startAndEnd' : isStart(date) ? 'start' : isEnd(date) ? 'end' : isRange(date) ? 'range' : isInside(date) ? 'faded' : '']" (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
      {{ date.day }}
    </span>
</ng-template>

<!--
          [class.start]="isStart(date)"
          [class.end]="isEnd(date)"
          [class.range]="isRange(date)"
          [class.faded]="isInside(date)" -->