import { ResolveEesztTorPipe } from './../resolve-eeszt-tor.pipe';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { SharedDataService } from '../services/shared-data.service';
import { AuthService } from '../auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedApiService } from '../services/shared-api.service';
import { environment } from '../../environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { W1250ToUtf8Pipe } from '../w1250-to-utf8.pipe';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../core/http/api.service';
import { EproMapResponse, VisualDetailField, VisualDetailType } from '../shared/models/response/eproMapResponse';
import { VisualDetailFieldWithFormControl } from '../shared/models/visualDetailFieldWithFormControl';
import { TpEproItem } from '../shared/models/tpEproItem';
import { ToastrService } from 'ngx-toastr';
import { faCopy, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { BusinessContent, ProfilAdat, ProfileContent, UpdateEproRequest } from '../shared/models/request/updateEproRequest';
import { EhrDocList } from '../shared/models/ehrDocList';
import { PatientEhrRequest } from '../shared/models/request/patientEhrRequest';
import { GetTorElementsGroupResponse } from '../shared/models/response/getTorElementsGroupResponse';
import { GetTorElementsCatResponse } from '../shared/models/response/geTorElementsCatResponse';
import { getTorElementsCertResponse } from '../shared/models/response/getTorElementsCertResponse';
import { EproElementRequest } from '../shared/models/request/eproElementRequest';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GetTorElementsTypeResponse } from '../shared/models/response/getTorElementsTypeResponse';
import { group } from '@angular/animations';
import { MatTabGroup } from '@angular/material/tabs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-eeszt-epro',
  templateUrl: './eeszt-epro.component.html',
  styleUrls: ['./eeszt-epro.component.css']
})
export class EesztEproComponent implements OnInit, OnDestroy {
  @ViewChild('eproMainTabGroup', {static: false}) eproMainTabGroup: MatTabGroup;

  faCopy = faCopy;
  faTrash = faTrash;
  faEdit = faEdit;

  expandEnabled: boolean = true;

  torCategories: Array<any>;
  torCert: Array<any>;

  patientDataValue = { participantName: '', servicePointFk: ''};
  isLoading: boolean;
  calendarDetail = null;
  apiUrl = environment.apiUrl;
  eproProfilAdatokAdatkitoltessel = [];

  showEhrFields: boolean;
  ehrDetailsInProgress: boolean;
  eproSaveInProgress: boolean;
  eproSaveType: string;

  displayedColumns = ['date', 'name', 'device', 'picture'];
  displayedColumnsEhr: string[] = ['doctorName', 'stampNo', 'timeStamp', 'hospitalDescr', 'diagnose', 'documentID', 'copyEhrDetails'];
  displayColumsEproToModify: string[] = ['eproId', 'dateFrom', 'inspDoctor', 'inspInstUnit', 'apprDoctor', 'cert'];
  dataSource = new MatTableDataSource<any>();

  eproToModifyDataSource = [];

  eproData = {};
  eesztEproData;
  isDeleteMode: boolean;

  groupItems: Array<any> = [];
  typeItems: Array<any> = [];

  /* breakGlass = new FormControl();
  breakGlassReason = new FormControl({value: null, disabled: true}); */

  searchDataBreakGlass = new FormControl();
  searchDataBreakGlassReason = new FormControl();

  patientListForm = new FormGroup({});

  activeTab = 0;

  tpEproItems: Map<string, any> = new Map();
  tpEproItemsArray: Array<TpEproItem> = new Array();

  showBeuFormControl: FormControl;

  eproVisualDetails: EproMapResponse;
  visualDetailType: VisualDetailType;
  visualDetailField: Array<VisualDetailField>;
  visualDetailFieldWithFormControl: Array<VisualDetailFieldWithFormControl>;

  patientEhrDateFrom = new FormControl(null, Validators.required);
  patientEhrDateTo = new FormControl(null, Validators.required);
  ehrBreakGlass = new FormControl(null);
  ehrBreakGlassReason = new FormControl(null);

  patientEhrList: MatTableDataSource<EhrDocList> = null;

  ehrIdFormControl = new FormControl();
  ehrVersionFormControl = new FormControl();
  dorFormControl = new FormControl();
  detailFormControl = new FormControl();
  certainatyFormControl = new FormControl(null, Validators.required);

  torElementTypes: GetTorElementsTypeResponse;

  ehrIdFormGroup = new FormGroup({
    'ehrId': this.ehrIdFormControl,
    'ehrVersion': this.ehrVersionFormControl
  });

  patientEhrFormGroup = new FormGroup({
    'patientDateFrom': this.patientEhrDateFrom,
    'patientDateTo': this.patientEhrDateTo,
    'breakGlass': this.ehrBreakGlass,
    'breakGlassReason': this.ehrBreakGlassReason
  });

  groupFromControl = new FormControl(null, Validators.required);
  typeFormControl = new FormControl(null, Validators.required);

  /* groupTypeFormGroup = new FormGroup({
    'groupFromControl': this.groupFromControl,
    'typeFormControl': this.typeFormControl
  }); */

  TP_EESZT_PRO_P7_IMPL_KOD_TIP_shared = new Subscription;
  TP_EESZT_PRO_P8_TERAPIA_TIPU_shared = new Subscription;
  TP_EESZT_PRO_P6_PROBLEMA_KOD_shared = new Subscription;
  TP_EESZT_PRO_P7_IMPL_KOD_shared = new Subscription;
  TP_EESZT_PRO_P9_AUTONOMIA_KO_shared = new Subscription;
  TP_EESZT_PRO_P13_VERCSOPORT_shared = new Subscription;
  TP_EESZT_PRO_P2_LEIRAS_KOD_shared = new Subscription;
  TP_EESZT_PRO_P3_OLTAS_KOD_shared = new Subscription;
  TP_EESZT_PRO_P4_PROBLEMA_KOD_shared = new Subscription;
  TP_EESZT_PRO_P5_BEAV_KOD_TIP_shared = new Subscription;
  TP_EESZT_PRO_P11_ELETMOD_KOD_shared = new Subscription;
  TP_EESZT_PRO_P12_TERH_KIMENE_shared = new Subscription;
  TP_EESZT_PRO_P1_AGENS_TIP_shared = new Subscription;
  TP_EESZT_PRO_P1_REAKCIO_KOD_shared = new Subscription;
  TP_EESZT_PRO_P10_DOZISFORMA__shared = new Subscription;
  TP_EESZT_PRO_P10_HATOANYAG_K_shared = new Subscription;
  TP_EESZT_PRO_P1_AGENS_KOD_TIP_shared = new Subscription;

    TP_EESZT_PRO_P7_IMPL_KOD_TIP = {};
    TP_EESZT_PRO_P8_TERAPIA_TIPU = {};
    TP_EESZT_PRO_P6_PROBLEMA_KOD = {};
    TP_EESZT_PRO_P7_IMPL_KOD = {};
    TP_EESZT_PRO_P9_AUTONOMIA_KO = {};
    TP_EESZT_PRO_P13_VERCSOPORT = {};
    TP_EESZT_PRO_P2_LEIRAS_KOD = {};
    TP_EESZT_PRO_P3_OLTAS_KOD = {};
    TP_EESZT_PRO_P4_PROBLEMA_KOD = {};
    TP_EESZT_PRO_P5_BEAV_KOD_TIP = {};
    TP_EESZT_PRO_P11_ELETMOD_KOD = {};
    TP_EESZT_PRO_P12_TERH_KIMENE = {};
    TP_EESZT_PRO_P1_AGENS_TIP = {};
    TP_EESZT_PRO_P1_REAKCIO_KOD = {};
    TP_EESZT_PRO_P10_DOZISFORMA_ = {};
    TP_EESZT_PRO_P10_HATOANYAG_K = {};
    TP_EESZT_PRO_P1_AGENS_KOD_TIP = {};
    timelineTest = [];


  constructor(    private sharedDataService: SharedDataService,
    private authService: AuthService,
    private http: HttpClient,
    private apiService: ApiService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private sharedApiService: SharedApiService //,
//    private w1250ToUtf8: W1250ToUtf8Pipe
    ) { }

  @Input() set patientData(data) {
    // console.log ('arrive patient patientData input triggered');
    if (data !== null && data['calendarFk']) {
      this.patientDataValue = data;
      // console.log('arrive patientData set:');
      // console.log(this.patientDataValue);
      this.isLoading = true;

      this.sharedApiService.getCalendarDetail(data['calendarFk']).subscribe((responseData) => {
        this.isLoading = false;
        if (responseData['code'] === 1) {
          // console.log ('paciens', responseData);
          // console.log (responseData);
          this.calendarDetail = responseData['data'];
          console.log('calendarDetail: ', this.calendarDetail, ' patientDataValue: ', this.patientDataValue);
          this.searchEproAdat(true);
        } else {
          console.log ('Error loading patient epro data from EESZT');
          // console.log (responseData);
        }
      });
    }
    if (this.eproMainTabGroup) {
      this.eproMainTabGroup.realignInkBar();
    }
  }

  onExpandEntry(expanded, index) {
    console.log(`Expand status of entry #${index} changed to ${expanded}`)
  }

  ngOnInit() {
    this.patientEhrDateFrom.valueChanges.pipe(debounceTime(1000)).subscribe(value => {
      this.convertDatefromUserInput(value, this.patientEhrDateFrom);
    });

    this.patientEhrDateTo.valueChanges.pipe(debounceTime(1000)).subscribe(value => {
      this.convertDatefromUserInput(value, this.patientEhrDateTo);
    });

    this.patientEhrFormGroup.controls.breakGlassReason.disable();
    this.patientEhrFormGroup.controls.breakGlass.valueChanges.subscribe(breakGlass => {
      breakGlass ? this.patientEhrFormGroup.controls.breakGlassReason.enable() : this.patientEhrFormGroup.controls.breakGlassReason.disable();
    });

    this.searchDataBreakGlassReason.disable();
    this.searchDataBreakGlass.valueChanges.subscribe(breakGlass => {
      if (!breakGlass) {
        this.searchDataBreakGlassReason.disable();
      } else {
        this.searchDataBreakGlassReason.enable();
      }
    });

    this.apiService.getTorElementsType('T_PRO_TIPUS').subscribe(torElementTypes => {
      this.torElementTypes = torElementTypes;
    });

    this.timelineTest.push('', '');
    this.apiService.getTorElementsCat('T_DOR_KATEGORIA').subscribe(res => {
      this.torCategories = res.torElements?.node.rows;
    });

    this.apiService.getTorElementsCert('T_PRO_BIZONYOSSAG').subscribe(res => {
      this.torCert = res.torElements?.node.rows;
    });

    this.apiService.getTorElementsGroup('T_PRO_CSOPORT').subscribe(res => {
      this.groupItems = res.torElements?.node.rows;
    });

    this.apiService.getEproVisualDetails().subscribe(res => {
      this.eproVisualDetails = res;
    });

    this.groupFromControl.valueChanges.subscribe(groupItem => {
      this.eproToModifyDataSource = [];
      if (groupItem) {
        this.typeItems = [];
        this.typeFormControl.reset();
        this.visualDetailFieldWithFormControl = [];
        this.visualDetailType = this.eproVisualDetails.visualDetails[groupItem.nev];
        console.log('groupItem: ', groupItem);
        console.log('this.eproVisualDetails: ', this.eproVisualDetails);
        for ( const key in this.visualDetailType) {
          if (key) {
            this.typeItems.push(key);
          }
        }
      }
    });

    this.typeFormControl.valueChanges.subscribe(typeItem => {
      this.eproToModifyDataSource = [];
      if (typeItem) {
        this.visualDetailFieldWithFormControl = [];
        this.visualDetailField = this.visualDetailType[typeItem];
        let tpEproItemElement;

        this.apiService.getTorElementsType('T_PRO_TIPUS').subscribe(res => {
        console.log('getTorElements api call response: ', res, ' typeItem: ', typeItem);
        res.torElements.node.rows.forEach(torElement => {
            if (typeItem === torElement.nev) {
              this.eproSaveType = torElement.kod;
              console.log('this.eproSaveType: ', this.eproSaveType);
            }
          });
        });
        console.log('visualDetailField: ', this.visualDetailField, ' tpEproItemsArray: ', this.tpEproItemsArray);
        this.visualDetailField.forEach(visualDetailFieldElement => {
          this.tpEproItemsArray.forEach(tpEproItem => {
            if (tpEproItem.key === visualDetailFieldElement[2]) {
              tpEproItemElement = tpEproItem.node.rows;
            }
          });
          this.visualDetailFieldWithFormControl.push(
            {
              visualDetailField: visualDetailFieldElement,
              visualDetailFormControl: new FormControl(),
              tpEproItems: tpEproItemElement
            }
          );
        });
      }
    });

    this.sharedDataService.loadTorElements();
    this.TP_EESZT_PRO_P7_IMPL_KOD_TIP_shared = this.sharedDataService.TP_EESZT_PRO_P7_IMPL_KOD_TIP_shared.subscribe( data => {
      this.TP_EESZT_PRO_P7_IMPL_KOD_TIP = data;
      const tpEproItem: TpEproItem = {
        node: data.node,
        key: 'TP_EESZT_PRO_P7_IMPLANT_KOD_TIP'
        };
        this.tpEproItemsArray.push(tpEproItem);
      });
    this.TP_EESZT_PRO_P8_TERAPIA_TIPU_shared = this.sharedDataService.TP_EESZT_PRO_P8_TERAPIA_TIPU_shared.subscribe( data => {
      this.TP_EESZT_PRO_P8_TERAPIA_TIPU = data;
      const tpEproItem: TpEproItem = {
        node: data.node,
        key: 'TP_EESZT_PRO_P8_TERAPIA_TIPUS'
        };
        this.tpEproItemsArray.push(tpEproItem);
      });
    this.TP_EESZT_PRO_P6_PROBLEMA_KOD_shared = this.sharedDataService.TP_EESZT_PRO_P6_PROBLEMA_KOD_shared.subscribe( data => {
      this.TP_EESZT_PRO_P6_PROBLEMA_KOD = data;
      const tpEproItem: TpEproItem = {
        node: data.node,
        key: 'TP_EESZT_PRO_P6_PROBLEMA_KOD'
        };
        this.tpEproItemsArray.push(tpEproItem);
      });
    this.TP_EESZT_PRO_P7_IMPL_KOD_shared = this.sharedDataService.TP_EESZT_PRO_P7_IMPL_KOD_shared.subscribe( data => {
      this.TP_EESZT_PRO_P7_IMPL_KOD = data;
      const tpEproItem: TpEproItem = {
        node: data.node,
        key: 'TP_EESZT_PRO_P7_IMPLANT_KOD'
        };
        this.tpEproItemsArray.push(tpEproItem);
      });
    this.TP_EESZT_PRO_P9_AUTONOMIA_KO_shared = this.sharedDataService.TP_EESZT_PRO_P9_AUTONOMIA_KO_shared.subscribe( data => {
      this.TP_EESZT_PRO_P9_AUTONOMIA_KO = data;
      const tpEproItem: TpEproItem = {
        node: data.node,
        key: 'TP_EESZT_PRO_P9_AUTONOMIA_KOD'
        };
        this.tpEproItemsArray.push(tpEproItem);
      });
    this.TP_EESZT_PRO_P13_VERCSOPORT_shared = this.sharedDataService.TP_EESZT_PRO_P13_VERCSOPORT_shared.subscribe( data => {
      this.TP_EESZT_PRO_P13_VERCSOPORT = data;
      const tpEproItem: TpEproItem = {
        node: data.node,
        key: 'TP_EESZT_PRO_P13_VERCSOPORT'
        };
        this.tpEproItemsArray.push(tpEproItem);
      });
    this.TP_EESZT_PRO_P2_LEIRAS_KOD_shared = this.sharedDataService.TP_EESZT_PRO_P2_LEIRAS_KOD_shared.subscribe( data => {
      this.TP_EESZT_PRO_P2_LEIRAS_KOD = data;
      const tpEproItem: TpEproItem = {
        node: data.node,
        key: 'TP_EESZT_PRO_P2_LEIRAS_KOD'
        };
        this.tpEproItemsArray.push(tpEproItem);
      });
    this.TP_EESZT_PRO_P3_OLTAS_KOD_shared = this.sharedDataService.TP_EESZT_PRO_P3_OLTAS_KOD_shared.subscribe( data => {
      this.TP_EESZT_PRO_P3_OLTAS_KOD = data;
      const tpEproItem: TpEproItem = {
        node: data.node,
        key: 'TP_EESZT_PRO_P3_OLTAS_KOD'
        };
        this.tpEproItemsArray.push(tpEproItem);
      });
    this.TP_EESZT_PRO_P4_PROBLEMA_KOD_shared = this.sharedDataService.TP_EESZT_PRO_P4_PROBLEMA_KOD_shared.subscribe( data => {
      this.TP_EESZT_PRO_P4_PROBLEMA_KOD = data;
      const tpEproItem: TpEproItem = {
        node: data.node,
        key: 'TP_EESZT_PRO_P4_PROBLEMA_KOD_TIP'
        };
        this.tpEproItemsArray.push(tpEproItem);
      });
    this.TP_EESZT_PRO_P5_BEAV_KOD_TIP_shared = this.sharedDataService.TP_EESZT_PRO_P5_BEAV_KOD_TIP_shared.subscribe( data => {
      this.TP_EESZT_PRO_P5_BEAV_KOD_TIP = data;
      const tpEproItem: TpEproItem = {
        node: data.node,
        key: 'TP_EESZT_PRO_P5_BEAV_KOD_TIP'
        };
        this.tpEproItemsArray.push(tpEproItem);
      });
    this.TP_EESZT_PRO_P11_ELETMOD_KOD_shared = this.sharedDataService.TP_EESZT_PRO_P11_ELETMOD_KOD_shared.subscribe( data => {
      this.TP_EESZT_PRO_P11_ELETMOD_KOD = data;
      const tpEproItem: TpEproItem = {
        node: data.node,
        key: 'TP_EESZT_PRO_P11_ELETMOD_KOD'
        };
        this.tpEproItemsArray.push(tpEproItem);
      });
    this.TP_EESZT_PRO_P12_TERH_KIMENE_shared = this.sharedDataService.TP_EESZT_PRO_P12_TERH_KIMENE_shared.subscribe( data => {
      this.TP_EESZT_PRO_P12_TERH_KIMENE = data;
      const tpEproItem: TpEproItem = {
        node: data.node,
        key: 'TP_EESZT_PRO_P12_TERH_KIMENETEL'
        };
        this.tpEproItemsArray.push(tpEproItem);
      });
    this.TP_EESZT_PRO_P1_AGENS_TIP_shared = this.sharedDataService.TP_EESZT_PRO_P1_AGENS_TIP_shared.subscribe( data => {
      this.TP_EESZT_PRO_P1_AGENS_TIP = data;
      const tpEproItem: TpEproItem = {
        node: data.node,
        key: 'TP_EESZT_PRO_P1_AGENS_TIP'
        };
        this.tpEproItemsArray.push(tpEproItem);
      });
    this.TP_EESZT_PRO_P1_REAKCIO_KOD_shared = this.sharedDataService.TP_EESZT_PRO_P1_REAKCIO_KOD_shared.subscribe( data => {
      this.TP_EESZT_PRO_P1_REAKCIO_KOD = data;
      const tpEproItem: TpEproItem = {
        node: data.node,
        key: 'TP_EESZT_PRO_P1_REAKCIO_KOD'
        };
        this.tpEproItemsArray.push(tpEproItem);
      });
    this.TP_EESZT_PRO_P10_DOZISFORMA__shared = this.sharedDataService.TP_EESZT_PRO_P10_DOZISFORMA__shared.subscribe( data => {
      this.TP_EESZT_PRO_P10_DOZISFORMA_ = data;
      const tpEproItem: TpEproItem = {
        node: data.node,
        key: 'TP_EESZT_PRO_P10_DOZISFORMA_KOD'
        };
        this.tpEproItemsArray.push(tpEproItem);
      });
    this.TP_EESZT_PRO_P10_HATOANYAG_K_shared = this.sharedDataService.TP_EESZT_PRO_P10_HATOANYAG_K_shared.subscribe( data => {
      this.TP_EESZT_PRO_P10_HATOANYAG_K = data;
      const tpEproItem: TpEproItem = {
        node: data.node,
        key: 'TP_EESZT_PRO_P10_HATOANYAG_KOD_TIP'
        };
        this.tpEproItemsArray.push(tpEproItem);
      });
    this.TP_EESZT_PRO_P1_AGENS_KOD_TIP_shared = this.sharedDataService.TP_EESZT_PRO_P1_AGENS_KOD_TIP_shared.subscribe( data => {
      this.TP_EESZT_PRO_P1_AGENS_KOD_TIP = data;
      const tpEproItem: TpEproItem = {
        node: data.node,
        key: 'TP_EESZT_PRO_P1_AGENS_KOD_TIP'
        };
        this.tpEproItemsArray.push(tpEproItem);
      });
  }

  convertDatefromUserInput(value, formControl: FormControl) {
    if (value && value !== '') {
      if (value.year === undefined)  {
        const dateValue = value.replace(/\./g, '-');
        const dateObject = this.sharedDataService.dateFromUserinput(dateValue);
        if (dateObject && !isNaN(dateObject.getTime())) {
          const newDate = this.sharedDataService.dateToDateString(dateObject);
          formControl.setValue(this.sharedDataService.dateStringToNgbDate(newDate));
        }
      }
    }
  }

  ngOnDestroy() {
    if (this.TP_EESZT_PRO_P7_IMPL_KOD_TIP_shared) {
      this.TP_EESZT_PRO_P7_IMPL_KOD_TIP_shared.unsubscribe();
    }
    if (this.TP_EESZT_PRO_P8_TERAPIA_TIPU_shared) {
      this.TP_EESZT_PRO_P8_TERAPIA_TIPU_shared.unsubscribe();
    }
    if (this.TP_EESZT_PRO_P6_PROBLEMA_KOD_shared) {
      this.TP_EESZT_PRO_P6_PROBLEMA_KOD_shared.unsubscribe();
    }
    if (this.TP_EESZT_PRO_P7_IMPL_KOD_shared) {
      this.TP_EESZT_PRO_P7_IMPL_KOD_TIP_shared.unsubscribe();
    }
    if (this.TP_EESZT_PRO_P9_AUTONOMIA_KO_shared) {
      this.TP_EESZT_PRO_P9_AUTONOMIA_KO_shared.unsubscribe();
    }
    if (this.TP_EESZT_PRO_P13_VERCSOPORT_shared) {
      this.TP_EESZT_PRO_P13_VERCSOPORT_shared.unsubscribe();
    }
    if (this.TP_EESZT_PRO_P2_LEIRAS_KOD_shared) {
      this.TP_EESZT_PRO_P2_LEIRAS_KOD_shared.unsubscribe();
    }
    if (this.TP_EESZT_PRO_P3_OLTAS_KOD_shared) {
      this.TP_EESZT_PRO_P3_OLTAS_KOD_shared.unsubscribe();
    }
    if (this.TP_EESZT_PRO_P4_PROBLEMA_KOD_shared) {
      this.TP_EESZT_PRO_P4_PROBLEMA_KOD_shared.unsubscribe();
    }
    if (this.TP_EESZT_PRO_P5_BEAV_KOD_TIP_shared) {
      this.TP_EESZT_PRO_P5_BEAV_KOD_TIP_shared.unsubscribe();
    }
    if (this.TP_EESZT_PRO_P11_ELETMOD_KOD_shared) {
      this.TP_EESZT_PRO_P11_ELETMOD_KOD_shared.unsubscribe();
    }
    if (this.TP_EESZT_PRO_P12_TERH_KIMENE_shared) {
      this.TP_EESZT_PRO_P12_TERH_KIMENE_shared.unsubscribe();
    }
    if (this.TP_EESZT_PRO_P1_AGENS_TIP_shared) {
      this.TP_EESZT_PRO_P1_AGENS_TIP_shared.unsubscribe();
    }
    if (this.TP_EESZT_PRO_P1_REAKCIO_KOD_shared) {
      this.TP_EESZT_PRO_P1_REAKCIO_KOD_shared.unsubscribe();
    }
    if (this.TP_EESZT_PRO_P10_DOZISFORMA__shared) {
      this.TP_EESZT_PRO_P10_DOZISFORMA__shared.unsubscribe();
    }
    if (this.TP_EESZT_PRO_P10_HATOANYAG_K_shared) {
      this.TP_EESZT_PRO_P10_HATOANYAG_K_shared.unsubscribe();
    }
  }

  clearForms() {
    this.typeFormControl.reset();
    this.visualDetailFieldWithFormControl = [];
    this.typeItems = [];
  }

  saveEpro() {
    if (this.certainatyFormControl.valid && this.groupFromControl.valid && this.typeFormControl.valid) {
      const profilAdat: Array<ProfilAdat> = [];

      this.visualDetailFieldWithFormControl.forEach(visualDetailFieldElement => {
        const businessContent = this.getBusinessContent(visualDetailFieldElement);
        if (businessContent) {
          profilAdat.push(this.getProfileDetail(businessContent));
        }
      });

      const eproRequest: UpdateEproRequest = this.getEproRequestParams(profilAdat);
      this.eproSaveInProgress = true;

      this.apiService.updateEpro(eproRequest).subscribe(res => {
        if (res.code > 0) {
          if (res.response.errors.lenght > 0) {
            res.response.errors.forEach(err => {
              this.toastr.error(err);
            });
          }
          console.log('adatkitöltés id: ', res.response.data.adatkitoltesId.id,
          '\nadatkitöltés verzió: ', res.response.data.adatkitoltesId.version,
          '\nidempotens azon: ', res.integrationId);

          this.eproSaveInProgress = false;
          this.toastr.success('sikeres rögzítés!');
          this.eproToModifyDataSource = [];
          this.visualDetailFieldWithFormControl = [];
          this.certainatyFormControl.reset();
          this.detailFormControl.reset();
          this.dorFormControl.reset();
          this.groupFromControl.reset();
          this.searchEproAdat(false);
        } else {
          this.eproSaveInProgress = false;
          this.toastr.error(res.message);
        }
      }, err => {
        console.log('updateEpro error...');
        this.eproSaveInProgress = false;
        this.toastr.error(err.error.message);
      });
    } else {
      this.toastr.error('a csoport, típus és bizonyosság mező megadása kötelező!');
      this.certainatyFormControl.markAsTouched();
      this.groupFromControl.markAsTouched();
      this.typeFormControl.markAsTouched();
    }
  }

  copyReferralId(element) {
    this.ehrIdFormGroup.controls.ehrId.setValue(element.eesztid.id);
    this.ehrIdFormGroup.controls.ehrVersion.setValue(element.eesztid.version);
  }

  searchEproAdat(isIntentional: boolean) {
    if (this.searchDataBreakGlass.value && !this.searchDataBreakGlassReason.value) {
      this.toastr.error('sürgősségi adatlekérés esetén az indokolás kötelező!');
    } else {
      this.eproSearchAdat().subscribe((eproResult) => {
        // console.log ('epro finished');
        this.isLoading = false;
        if (eproResult['code'] > 0) {
          if (eproResult['response']['code'] === '0') {
            if (eproResult['response']['errors']?.length > 0 && isIntentional) {
              eproResult['response']['errors'].forEach(error => {
                this.toastr.error(error.code + ' ' + error.message);
              });
            }
            this.eproData = eproResult['response']['data'];
            // console.log('eproData', this.eproData);
            this.eproProfilAdatokAdatkitoltessel = this.findprofilAdatokAdatkitoltessel(this.eproData);
            // console.log ('eproProfilAdatokAdatkitoltessel', this.eproProfilAdatokAdatkitoltessel);
          }
        } else {
          this.eproProfilAdatokAdatkitoltessel = [];
          this.toastr.error('egészésgügyi profil lekérdezése sikertelen!', eproResult['message']);
        }
      }, err => {
        this.toastr.error(err.message);
      });
    }
  }

  isEproFormValid (): boolean {
    let isFormValid = true;
    for (const visualDetailFieldWithFormControlElement of this.visualDetailFieldWithFormControl) {
      if (!visualDetailFieldWithFormControlElement.visualDetailFormControl.value) {
        this.toastr.error('hiányosan adta meg az adatokat!');
        isFormValid = false;
        break;
      }
    }
    return isFormValid;
  }

  getEproRequestParams(profilAdat): UpdateEproRequest {
    console.log('eproAdat: ', this.eproData);
    const eproRequest: UpdateEproRequest = {
      bizonyossag: this.certainatyFormControl.value,
      csoportId: this.groupFromControl.value.kod,
      leiras: this.detailFormControl.value ? this.detailFormControl.value : null,
      ehrId: !this.ehrIdFormGroup.controls.ehrId.value || !this.ehrIdFormGroup.controls.ehrVersion.value ? null : {
        id: this.ehrIdFormGroup.controls.ehrId.value,
        version: this.ehrIdFormGroup.controls.ehrVersion.value
      },
      eproEesztId: this.eproToModifyDataSource.length > 0 ? this.eproToModifyDataSource[0].adatkitoltes.businessObjectIdentification.eesztid.id : '',
      eproEesztIdVersion: this.eproToModifyDataSource.length > 0 ? this.eproToModifyDataSource[0].adatkitoltes.businessObjectIdentification.eesztid.version : 1,
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID(),
        sessionId: null
      },
      onrendelkezesiKategoria: this.dorFormControl.value ? this.dorFormControl.value : null,
      patientTapass: this.calendarDetail.participantTapass,
      profileContent: {
        profilAdat
      },
      tipusId: this.eproSaveType
    };

    return eproRequest;
  }

  getProfileDetail(businessContent): ProfilAdat {
    const profileDetail: ProfilAdat = {
      businessContent,
      businessObjectIdentification:
      {
        eesztid: null,
        integrationId: null
      },
      businessObjectLogging: null,
      businessObjectSignature: null
    };
    return profileDetail;
  }

  getBusinessContent(visualDetailFieldElement): BusinessContent {
    let adatszerkezetId;
    if (visualDetailFieldElement.visualDetailField[2].includes('TP_EESZT_PRO_')) {
      adatszerkezetId = visualDetailFieldElement.visualDetailField[2].replace('TP_EESZT_PRO_', '');
    } else {
      adatszerkezetId = visualDetailFieldElement.visualDetailField[2];
    }
    const businessContent: BusinessContent = {
      adatkitoltesId: '',
      adatkitoltesVerzio: 1,
      adatszerkezetId,
      adatszerkezetVerzio: 1,
      ertek: visualDetailFieldElement.visualDetailFormControl.value?.value ?
             visualDetailFieldElement.visualDetailFormControl.value.value :
             visualDetailFieldElement.visualDetailFormControl.value ? visualDetailFieldElement.visualDetailFormControl.value : null
    };
    return businessContent.ertek !== null ? businessContent : null;
  }

  switchTab(tabID) {
    this.showEhrFields = false;
    this.activeTab = tabID;
  }

  eproSearchAdat() {
    this.isLoading = true;
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      callerTransactionId: '123456',
      betegIdTipus: '1',
       breakGlass: this.searchDataBreakGlass.value,
       breakGlassOk: this.searchDataBreakGlassReason.value,
       eesztId: null,
       csoportId: null,
       specificAuthorization: '',
       betegId: this.calendarDetail.participantSocialSecurityNumber,
       tokenStr: null,
       tipusId: null
      };

    return this.http.post<any[]>(this.apiUrl + '/rest/ecm/epro/searchadat', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  resetEesztEproForm() {
    /* this.breakGlass.reset();
    this.breakGlassReason.reset(); */
    this.eproToModifyDataSource = [];
    this.searchDataBreakGlass.reset();
    this.searchDataBreakGlassReason.reset();
    this.dorFormControl.reset();
    this.certainatyFormControl.reset();
    this.detailFormControl.reset();
    this.ehrIdFormGroup.reset();
    this.patientEhrFormGroup.reset();
    this.patientEhrList = null;
    this.groupFromControl.reset();
    this.clearForms();
  }

  findprofilAdatokAdatkitoltessel(eesztAdat) {
    if (eesztAdat && eesztAdat['profilAdatokAdatkitoltessel']) {
      return eesztAdat['profilAdatokAdatkitoltessel'];
    } else {
      return [];
    }
  }

  showBeu() {
    this.showEhrFields = !this.showEhrFields;
    if (!this.showEhrFields) {
      this.patientEhrList = null;
      this.patientEhrFormGroup.reset();
    }
  }

  getPatientEhrDocumentList() {
    if (this.patientEhrFormGroup.controls.breakGlass.value && !this.patientEhrFormGroup.controls.breakGlassReason.value) {
      this.toastr.error('sürgősségi adatlekérés esetén az indoklás kötelező');
    } else {
      const params: PatientEhrRequest = {
        messageHeader: {
          currentTapass: this.authService.getUserData()['ekCardNo'],
          currentRole: this.sharedDataService.selectedRole['roleCode'],
          currentServicePointId: this.sharedDataService.selectedRole['spId'],
          locale: 'hu',
          deviceId: this.authService.getUUID()
        },
        breakGlass: this.patientEhrFormGroup.controls.breakGlass.value,
        breakGlassOk: this.patientEhrFormGroup.controls.breakGlassReason.value,
        fromDate: this.sharedDataService.NgbDateToDateString(this.patientEhrFormGroup.controls.patientDateFrom.value),
        toDate: this.sharedDataService.NgbDateToDateString(this.patientEhrFormGroup.controls.patientDateFrom.value),
        patientTapass: this.calendarDetail.participantTapass
      };
      this.ehrDetailsInProgress = true;
      this.apiService.getEhrDocumentList(params).subscribe(res => {
        this.ehrDetailsInProgress = false;
        if (res.message.includes('DOR-1017')) {
          this.toastr.error(res.message);
        } else {
          this.patientEhrList = new MatTableDataSource(res.data.documentList);
        }
      }, err => {
        this.ehrDetailsInProgress = false;
        this.toastr.error(err.error.message);
      });
    }
  }

  deleteEpro(adat) {
    const eProElement: EproElementRequest = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      eesztId: adat.adatkitoltes.businessObjectIdentification.eesztid,
      eesztIntegrationId: adat.adatkitoltes.businessObjectIdentification.integrationId
    };
    this.apiService.deleteEpro(eProElement).subscribe(res => {
      this.searchEproAdat(true);
    });
  }

  copyDataToSaveEpro(adat) {
    console.log('adat: ', adat);
    this.switchTab(1);
    this.eproToModifyDataSource = [];

    this.certainatyFormControl.setValue(adat.adatkitoltes.businessContent.bizonyossag);
    this.detailFormControl.setValue(adat.adatkitoltes.businessContent.leiras);
    this.dorFormControl.setValue(adat.adatkitoltes.businessContent.onrendelkezesiKategoria);

    this.groupItems.forEach(groupItem => {
      if (groupItem.kod === adat.adatList.profilAdatSzerkezettel[0].csoportId) {
        this.groupFromControl.setValue(groupItem);
      }
    });

    this.torElementTypes.torElements.node.rows.forEach(type => {
      if (type.kod === adat.adatList.profilAdatSzerkezettel[0].tipusId) {
        this.typeFormControl.setValue(type.nev);
      }
    });
    if (adat.adatList.profilAdatSzerkezettel) {
      this.visualDetailFieldWithFormControl.forEach(visualDetail => {
        adat.adatList.profilAdatSzerkezettel.forEach(eproData => {
          if (eproData.kulcs === visualDetail.visualDetailField[0]) {
            if (visualDetail.visualDetailField[1] === 'dropdown') {
              this.tpEproItemsArray.forEach(tpEproItem => {
                if (tpEproItem.key.includes(eproData.businessObjectIdentification.eesztid.id)) {
                  visualDetail.visualDetailFormControl.setValue(tpEproItem.node.rows.filter(x => x.value === eproData.ertek)[0]);
                }
              });
            } else {
              visualDetail.visualDetailFormControl.setValue(eproData.ertek);
            }
          }
        });
      });
    }

    this.eproToModifyDataSource.push(adat);
  }

  deleteEproModal(adat) {
    this.isDeleteMode = true;
    this.eesztEproData = adat;
  }

  onDotClick(event) {
    if (!this.expandEnabled) {
      event.stopPropagation();
    }
  }

  onHeaderClick(event) {
    this.isDeleteMode = false;
    if (!this.expandEnabled) {
      event.stopPropagation();
    }
  }


  openHelp(a){}

}



          // this.isLoading = false;
          // this.eproData = {
          //       "profilAdatokAdatkitoltessel": [
          //         {
          //           "businessObjectSignature": [

          //           ],
          //           "businessObjectLogging": {
          //             "insertDate": "2020-05-28T10:04:56.873+0000",
          //             "insertedBy": "O51364",
          //             "lastModifyDate": "2020-05-28T10:04:56.873+0000",
          //             "lastModifiedBy": "O51364",
          //             "deletedBy": ""
          //           },
          //           "adatkitoltes": {
          //             "businessObjectSignature": [

          //             ],
          //             "businessObjectLogging": {
          //               "insertDate": "2020-05-28T10:04:56.873+0000",
          //               "insertedBy": "O51364",
          //               "lastModifyDate": "2020-05-28T10:04:56.873+0000",
          //               "lastModifiedBy": "O51364",
          //               "deletedBy": ""
          //             },
          //             "businessObjectIdentification": {
          //               "eesztid": {
          //                 "id": "252000000059309179",
          //                 "version": 1
          //               },
          //               "integrationId": ""
          //             },
          //             "businessContent": {
          //               "betegIdErtek": "111111110",
          //               "betegIdTipus": "1",
          //               "letrehozasiIdopont": "2020-05-28T12:03:00.000+0000",
          //               "hitelesito": "51364",
          //               "megfigyelo": "51364",
          //               "intezmenyId": "01060J",
          //               "leiras": "Renitec",
          //               "bizonyossag": "1",
          //               "onrendelkezesiKategoria": ""
          //             }
          //           },
          //           "adatList": {
          //             "profilAdatSzerkezettel": [
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-21T19:18:00.829+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-21T19:18:00.829+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P10_HATOANYAG",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Hatóanyag (készítmény) neve",
          //                 "adatTipus": "string",
          //                 "adatAltipus": "",
          //                 "ertek": "Enalapril",
          //                 "csoportId": "GYO",
          //                 "tipusId": "GYOGYSZER",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_AF0B1A74-A0CA-11EA-B3E6-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-21T19:18:25.500+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-11-07T08:27:30.226+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P10_HATOANYAG_KOD_TIP",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Hatóanyag (készítmény) kód típusa",
          //                 "adatTipus": "tp_tip",
          //                 "adatAltipus": "HATOANYAG_KOD_TIP",
          //                 "ertek": "GYOGYSZER",
          //                 "csoportId": "GYO",
          //                 "tipusId": "GYOGYSZER",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_AF0BCE24-A0CA-11EA-B3E6-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-21T19:18:46.215+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-21T19:18:46.215+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P10_HATOANYAG_KOD",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Hatóanyag (készítmény) kódja",
          //                 "adatTipus": "string",
          //                 "adatAltipus": "",
          //                 "ertek": "C09AA02",
          //                 "csoportId": "GYO",
          //                 "tipusId": "GYOGYSZER",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_AF0C82D8-A0CA-11EA-B3E6-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-21T19:19:12.104+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-21T19:19:12.104+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P10_DOZISFORMA",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Gyógyszerészeti dózisforma",
          //                 "adatTipus": "string",
          //                 "adatAltipus": "",
          //                 "ertek": "tabletta",
          //                 "csoportId": "GYO",
          //                 "tipusId": "GYOGYSZER",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_AF0D349E-A0CA-11EA-B3E6-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-21T19:19:38.894+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-11-07T08:28:11.076+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P10_DOZISFORMA_KOD",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Gyógyszerészeti dózisforma kód",
          //                 "adatTipus": "tp_tip",
          //                 "adatAltipus": "DOZISFORMA_KOD",
          //                 "ertek": "1",
          //                 "csoportId": "GYO",
          //                 "tipusId": "GYOGYSZER",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_AF0DE27C-A0CA-11EA-B3E6-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-21T19:20:05.269+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-21T19:20:05.269+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P10_MENNYISEG",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Beszedendő mennyiség",
          //                 "adatTipus": "string",
          //                 "adatAltipus": "",
          //                 "ertek": "1x1",
          //                 "csoportId": "GYO",
          //                 "tipusId": "GYOGYSZER",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_AF0E879A-A0CA-11EA-B3E6-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-21T19:20:27.146+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-21T19:20:27.146+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P10_GYAKORISAG",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Szedés gyakorisága",
          //                 "adatTipus": "string",
          //                 "adatAltipus": "",
          //                 "ertek": "naponta",
          //                 "csoportId": "GYO",
          //                 "tipusId": "GYOGYSZER",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_AF0F30A0-A0CA-11EA-B3E6-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-21T19:21:54.282+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-21T19:21:54.282+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P10_DATUM_KEZD",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Kezelés kezdete",
          //                 "adatTipus": "string",
          //                 "adatAltipus": "",
          //                 "ertek": "2019.05.28.",
          //                 "csoportId": "GYO",
          //                 "tipusId": "GYOGYSZER",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_AF0FE4FA-A0CA-11EA-B3E6-5056921CC400"
          //               }
          //             ]
          //           },
          //           "aktiv": true
          //         },
          //         {
          //           "businessObjectSignature": [

          //           ],
          //           "businessObjectLogging": {
          //             "insertDate": "2020-05-28T09:52:18.907+0000",
          //             "insertedBy": "O51364",
          //             "lastModifyDate": "2020-05-28T09:52:18.907+0000",
          //             "lastModifiedBy": "O51364",
          //             "deletedBy": ""
          //           },
          //           "adatkitoltes": {
          //             "businessObjectSignature": [

          //             ],
          //             "businessObjectLogging": {
          //               "insertDate": "2020-05-28T09:52:18.907+0000",
          //               "insertedBy": "O51364",
          //               "lastModifyDate": "2020-05-28T09:52:18.907+0000",
          //               "lastModifiedBy": "O51364",
          //               "deletedBy": ""
          //             },
          //             "businessObjectIdentification": {
          //               "eesztid": {
          //                 "id": "252000000059259142",
          //                 "version": 1
          //               },
          //               "integrationId": ""
          //             },
          //             "businessContent": {
          //               "betegIdErtek": "111111110",
          //               "betegIdTipus": "1",
          //               "letrehozasiIdopont": "2020-05-28T11:51:00.000+0000",
          //               "hitelesito": "51364",
          //               "megfigyelo": "51364",
          //               "intezmenyId": "01060J",
          //               "leiras": "Paracetamol",
          //               "bizonyossag": "3",
          //               "onrendelkezesiKategoria": ""
          //             }
          //           },
          //           "adatList": {
          //             "profilAdatSzerkezettel": [
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T16:01:09.569+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-20T16:01:09.569+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P2_MEGNEVEZES",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Rövid leírás",
          //                 "adatTipus": "string",
          //                 "adatAltipus": "",
          //                 "ertek": "Paracetamol készítmény szedéskor kiütés az arcon",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "FIGYEGYEB",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_EB429DD4-A0C8-11EA-B3E6-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T16:01:54.688+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-11-07T08:15:28.629+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P2_LEIRAS_KOD",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Részletes leírás kód",
          //                 "adatTipus": "tp_tip",
          //                 "adatAltipus": "LEIRAS_KOD",
          //                 "ertek": "1",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "FIGYEGYEB",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_EB438866-A0C8-11EA-B3E6-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T16:02:29.242+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-20T16:02:29.242+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P2_DATUM",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Megfigyelés időpontja",
          //                 "adatTipus": "date",
          //                 "adatAltipus": "",
          //                 "ertek": "2019.05.28.",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "FIGYEGYEB",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_EB44302C-A0C8-11EA-B3E6-5056921CC400"
          //               }
          //             ]
          //           },
          //           "aktiv": true
          //         },
          //         {
          //           "businessObjectSignature": [

          //           ],
          //           "businessObjectLogging": {
          //             "insertDate": "2020-05-26T11:06:20.224+0000",
          //             "insertedBy": "O53845",
          //             "lastModifyDate": "2020-05-26T11:06:20.224+0000",
          //             "lastModifiedBy": "O53845",
          //             "deletedBy": ""
          //           },
          //           "adatkitoltes": {
          //             "businessObjectSignature": [

          //             ],
          //             "businessObjectLogging": {
          //               "insertDate": "2020-05-26T11:06:20.224+0000",
          //               "insertedBy": "O53845",
          //               "lastModifyDate": "2020-05-26T11:06:20.224+0000",
          //               "lastModifiedBy": "O53845",
          //               "deletedBy": ""
          //             },
          //             "businessObjectIdentification": {
          //               "eesztid": {
          //                 "id": "252000000058279811",
          //                 "version": 1
          //               },
          //               "integrationId": ""
          //             },
          //             "businessContent": {
          //               "betegIdErtek": "111111110",
          //               "betegIdTipus": "1",
          //               "letrehozasiIdopont": "2020-05-26T11:06:20.222+0000",
          //               "hitelesito": "53844",
          //               "megfigyelo": "53844",
          //               "intezmenyId": "011200",
          //               "leiras": "nincs",
          //               "bizonyossag": "1",
          //               "onrendelkezesiKategoria": "ADD"
          //             }
          //           },
          //           "adatList": {
          //             "profilAdatSzerkezettel": [
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T15:56:40.280+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-20T15:56:40.280+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P1_AGENS",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Kiváltó tényező",
          //                 "adatTipus": "string",
          //                 "adatAltipus": "",
          //                 "ertek": "Tej",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "ALLERGIA",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_EDAA47A4-9F40-11EA-B3E6-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T15:57:43.225+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-11-07T08:11:40.853+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P1_AGENS_KOD_TIP",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Kiváltó tényező kód típus",
          //                 "adatTipus": "tp_tip",
          //                 "adatAltipus": "AGENS_KOD_TIP",
          //                 "ertek": "ELELMISZER",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "ALLERGIA",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_EDAB2C50-9F40-11EA-B3E6-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T15:58:06.677+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-20T15:58:06.677+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P1_AGENS_KOD",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Kiváltó tényező kód",
          //                 "adatTipus": "string",
          //                 "adatAltipus": "",
          //                 "ertek": "123456",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "ALLERGIA",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_EDABD7AE-9F40-11EA-B3E6-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T15:59:11.268+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-11-07T08:13:39.323+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P1_AGENS_TIP",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Kiváltó tényező típus",
          //                 "adatTipus": "tp_tip",
          //                 "adatAltipus": "AGENS_TIPUS",
          //                 "ertek": "food",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "ALLERGIA",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_EDAC846A-9F40-11EA-B3E6-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T15:59:31.268+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-20T15:59:31.268+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P1_REAKCIO",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Allergia leírása",
          //                 "adatTipus": "string",
          //                 "adatAltipus": "",
          //                 "ertek": "2 dl tej elfogyasztĂˇsa utĂˇn fĂ©l Ăłra mulva a kĂ©zfejen kiĂĽtĂ©sek",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "ALLERGIA",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_EDAD37D4-9F40-11EA-B3E6-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T15:59:57.775+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-11-07T08:14:12.145+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P1_REAKCIO_KOD",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Allergia leírás kód",
          //                 "adatTipus": "tp_tip",
          //                 "adatAltipus": "REAKCIO_KOD",
          //                 "ertek": "8",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "ALLERGIA",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_EDADE81E-9F40-11EA-B3E6-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T16:00:20.052+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-20T16:00:20.052+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P1_DATUM",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Megfigyelés időpontja",
          //                 "adatTipus": "date",
          //                 "adatAltipus": "",
          //                 "ertek": "2018.11.29.",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "ALLERGIA",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_EDAE967E-9F40-11EA-B3E6-5056921CC400"
          //               }
          //             ]
          //           },
          //           "aktiv": true
          //         },
          //         {
          //           "businessObjectSignature": [

          //           ],
          //           "businessObjectLogging": {
          //             "insertDate": "2019-08-07T14:56:41.767+0000",
          //             "insertedBy": "O51364",
          //             "lastModifyDate": "2019-08-07T14:56:41.767+0000",
          //             "lastModifiedBy": "O51364",
          //             "deletedBy": ""
          //           },
          //           "adatkitoltes": {
          //             "businessObjectSignature": [

          //             ],
          //             "businessObjectLogging": {
          //               "insertDate": "2019-08-07T14:56:41.767+0000",
          //               "insertedBy": "O51364",
          //               "lastModifyDate": "2019-08-07T14:56:41.767+0000",
          //               "lastModifiedBy": "O51364",
          //               "deletedBy": ""
          //             },
          //             "businessObjectIdentification": {
          //               "eesztid": {
          //                 "id": "251900000016085829",
          //                 "version": 1
          //               },
          //               "integrationId": ""
          //             },
          //             "businessContent": {
          //               "betegIdErtek": "111111110",
          //               "betegIdTipus": "1",
          //               "letrehozasiIdopont": "2019-08-07T16:54:00.000+0000",
          //               "hitelesito": "51364",
          //               "megfigyelo": "51364",
          //               "intezmenyId": "01060J",
          //               "leiras": "Bal láb, térdszalag beültetés",
          //               "bizonyossag": "1",
          //               "onrendelkezesiKategoria": ""
          //             }
          //           },
          //           "adatList": {
          //             "profilAdatSzerkezettel": [
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-21T18:45:29.808+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-21T18:45:29.808+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P5_BEAV_KOD_TIP",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Beavatkozás kód típusa",
          //                 "adatTipus": "tp_tip",
          //                 "adatAltipus": "BEAV_KOD_TIP",
          //                 "ertek": "FEKVO_OENO",
          //                 "csoportId": "KOR",
          //                 "tipusId": "KMUTET",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_90E966C0-B923-11E9-AFDE-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-21T18:45:48.887+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-11-07T08:19:41.501+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P5_BEAV_KOD",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Beavatkozás kódja",
          //                 "adatTipus": "string",
          //                 "adatAltipus": "",
          //                 "ertek": "5814E",
          //                 "csoportId": "KOR",
          //                 "tipusId": "KMUTET",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_90EA7722-B923-11E9-AFDE-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-21T18:45:03.438+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-21T18:45:03.438+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P5_BEAVATKOZAS",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Beavatkozás leírása",
          //                 "adatTipus": "string",
          //                 "adatAltipus": "",
          //                 "ertek": "Térdszalag beültetés (syntethicus)",
          //                 "csoportId": "KOR",
          //                 "tipusId": "KMUTET",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_90EB2622-B923-11E9-AFDE-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-21T18:46:29.070+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-21T18:46:29.070+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P5_DATUM",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Beavatkozás időpontja",
          //                 "adatTipus": "date",
          //                 "adatAltipus": "",
          //                 "ertek": "2019.08.07.",
          //                 "csoportId": "KOR",
          //                 "tipusId": "KMUTET",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_90EBD0EA-B923-11E9-AFDE-5056921CC400"
          //               }
          //             ]
          //           },
          //           "aktiv": true
          //         },
          //         {
          //           "businessObjectSignature": [

          //           ],
          //           "businessObjectLogging": {
          //             "insertDate": "2019-08-07T14:52:36.219+0000",
          //             "insertedBy": "O51364",
          //             "lastModifyDate": "2019-08-07T14:52:36.219+0000",
          //             "lastModifiedBy": "O51364",
          //             "deletedBy": ""
          //           },
          //           "adatkitoltes": {
          //             "businessObjectSignature": [

          //             ],
          //             "businessObjectLogging": {
          //               "insertDate": "2019-08-07T14:52:36.219+0000",
          //               "insertedBy": "O51364",
          //               "lastModifyDate": "2019-08-07T14:52:36.219+0000",
          //               "lastModifiedBy": "O51364",
          //               "deletedBy": ""
          //             },
          //             "businessObjectIdentification": {
          //               "eesztid": {
          //                 "id": "251900000015669871",
          //                 "version": 1
          //               },
          //               "integrationId": ""
          //             },
          //             "businessContent": {
          //               "betegIdErtek": "111111110",
          //               "betegIdTipus": "1",
          //               "letrehozasiIdopont": "2019-08-07T16:49:00.000+0000",
          //               "hitelesito": "51364",
          //               "megfigyelo": "51364",
          //               "intezmenyId": "01060J",
          //               "leiras": "Pollen érzékenység",
          //               "bizonyossag": "1",
          //               "onrendelkezesiKategoria": ""
          //             }
          //           },
          //           "adatList": {
          //             "profilAdatSzerkezettel": [
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T15:56:40.280+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-20T15:56:40.280+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P1_AGENS",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Kiváltó tényező",
          //                 "adatTipus": "string",
          //                 "adatAltipus": "",
          //                 "ertek": "Pollen",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "ALLERGIA",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_FE8DD612-B922-11E9-AFDE-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T15:57:43.225+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-11-07T08:11:40.853+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P1_AGENS_KOD_TIP",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Kiváltó tényező kód típus",
          //                 "adatTipus": "tp_tip",
          //                 "adatAltipus": "AGENS_KOD_TIP",
          //                 "ertek": "KORNYEZETI_TENYEZO",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "ALLERGIA",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_FE8E821A-B922-11E9-AFDE-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T15:58:06.677+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-20T15:58:06.677+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P1_AGENS_KOD",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Kiváltó tényező kód",
          //                 "adatTipus": "string",
          //                 "adatAltipus": "",
          //                 "ertek": "23",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "ALLERGIA",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_FE8F2F4E-B922-11E9-AFDE-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T15:59:11.268+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-11-07T08:13:39.323+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P1_AGENS_TIP",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Kiváltó tényező típus",
          //                 "adatTipus": "tp_tip",
          //                 "adatAltipus": "AGENS_TIPUS",
          //                 "ertek": "environment",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "ALLERGIA",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_FE8FD796-B922-11E9-AFDE-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T15:59:57.775+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-11-07T08:14:12.145+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P1_REAKCIO_KOD",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Allergia leírás kód",
          //                 "adatTipus": "tp_tip",
          //                 "adatAltipus": "REAKCIO_KOD",
          //                 "ertek": "2",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "ALLERGIA",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_FE90836C-B922-11E9-AFDE-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T16:00:20.052+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-20T16:00:20.052+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P1_DATUM",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Megfigyelés időpontja",
          //                 "adatTipus": "date",
          //                 "adatAltipus": "",
          //                 "ertek": "2019.08.07.",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "ALLERGIA",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_FE912F2E-B922-11E9-AFDE-5056921CC400"
          //               }
          //             ]
          //           },
          //           "aktiv": true
          //         },
          //         {
          //           "businessObjectSignature": [

          //           ],
          //           "businessObjectLogging": {
          //             "insertDate": "2019-08-07T14:53:05.062+0000",
          //             "insertedBy": "O51364",
          //             "lastModifyDate": "2019-08-07T14:53:05.062+0000",
          //             "lastModifiedBy": "O51364",
          //             "deletedBy": ""
          //           },
          //           "adatkitoltes": {
          //             "businessObjectSignature": [

          //             ],
          //             "businessObjectLogging": {
          //               "insertDate": "2019-08-07T14:53:05.062+0000",
          //               "insertedBy": "O51364",
          //               "lastModifyDate": "2019-08-07T14:53:05.062+0000",
          //               "lastModifiedBy": "O51364",
          //               "deletedBy": ""
          //             },
          //             "businessObjectIdentification": {
          //               "eesztid": {
          //                 "id": "251900000015771347",
          //                 "version": 1
          //               },
          //               "integrationId": ""
          //             },
          //             "businessContent": {
          //               "betegIdErtek": "111111110",
          //               "betegIdTipus": "1",
          //               "letrehozasiIdopont": "2018-03-19T14:53:00.000+0000",
          //               "hitelesito": "51364",
          //               "megfigyelo": "51364",
          //               "intezmenyId": "01060J",
          //               "leiras": "s",
          //               "bizonyossag": "1",
          //               "onrendelkezesiKategoria": ""
          //             }
          //           },
          //           "adatList": {
          //             "profilAdatSzerkezettel": [
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T15:56:40.280+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-20T15:56:40.280+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P1_AGENS",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Kiváltó tényező",
          //                 "adatTipus": "string",
          //                 "adatAltipus": "",
          //                 "ertek": "Mandulaolaj",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "ALLERGIA",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_0FBEDB70-B923-11E9-AFDE-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T15:57:43.225+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-11-07T08:11:40.853+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P1_AGENS_KOD_TIP",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Kiváltó tényező kód típus",
          //                 "adatTipus": "tp_tip",
          //                 "adatAltipus": "AGENS_KOD_TIP",
          //                 "ertek": "ELELMISZER",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "ALLERGIA",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_0FBF951A-B923-11E9-AFDE-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T15:59:11.268+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-11-07T08:13:39.323+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P1_AGENS_TIP",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Kiváltó tényező típus",
          //                 "adatTipus": "tp_tip",
          //                 "adatAltipus": "AGENS_TIPUS",
          //                 "ertek": "food",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "ALLERGIA",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_0FC0480C-B923-11E9-AFDE-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T16:00:20.052+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-20T16:00:20.052+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P1_DATUM",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Megfigyelés időpontja",
          //                 "adatTipus": "date",
          //                 "adatAltipus": "",
          //                 "ertek": "2018.03.19.",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "ALLERGIA",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_0FC1074C-B923-11E9-AFDE-5056921CC400"
          //               }
          //             ]
          //           },
          //           "aktiv": true
          //         },
          //         {
          //           "businessObjectSignature": [

          //           ],
          //           "businessObjectLogging": {
          //             "insertDate": "2019-08-07T14:53:38.660+0000",
          //             "insertedBy": "O51364",
          //             "lastModifyDate": "2019-08-07T14:53:38.660+0000",
          //             "lastModifiedBy": "O51364",
          //             "deletedBy": ""
          //           },
          //           "adatkitoltes": {
          //             "businessObjectSignature": [

          //             ],
          //             "businessObjectLogging": {
          //               "insertDate": "2019-08-07T14:53:38.660+0000",
          //               "insertedBy": "O51364",
          //               "lastModifyDate": "2019-08-07T14:53:38.660+0000",
          //               "lastModifiedBy": "O51364",
          //               "deletedBy": ""
          //             },
          //             "businessObjectIdentification": {
          //               "eesztid": {
          //                 "id": "251900000015924190",
          //                 "version": 1
          //               },
          //               "integrationId": ""
          //             },
          //             "businessContent": {
          //               "betegIdErtek": "111111110",
          //               "betegIdTipus": "1",
          //               "letrehozasiIdopont": "2018-03-19T14:53:00.000+0000",
          //               "hitelesito": "51364",
          //               "megfigyelo": "51364",
          //               "intezmenyId": "01060J",
          //               "leiras": "s",
          //               "bizonyossag": "1",
          //               "onrendelkezesiKategoria": ""
          //             }
          //           },
          //           "adatList": {
          //             "profilAdatSzerkezettel": [
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T15:56:40.280+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-20T15:56:40.280+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P1_AGENS",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Kiváltó tényező",
          //                 "adatTipus": "string",
          //                 "adatAltipus": "",
          //                 "ertek": "Mandulaolaj",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "ALLERGIA",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_23C59942-B923-11E9-AFDE-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T15:57:43.225+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-11-07T08:11:40.853+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P1_AGENS_KOD_TIP",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Kiváltó tényező kód típus",
          //                 "adatTipus": "tp_tip",
          //                 "adatAltipus": "AGENS_KOD_TIP",
          //                 "ertek": "ELELMISZER",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "ALLERGIA",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_23C66C64-B923-11E9-AFDE-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T15:59:11.268+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-11-07T08:13:39.323+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P1_AGENS_TIP",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Kiváltó tényező típus",
          //                 "adatTipus": "tp_tip",
          //                 "adatAltipus": "AGENS_TIPUS",
          //                 "ertek": "food",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "ALLERGIA",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_23C72424-B923-11E9-AFDE-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T16:00:20.052+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-20T16:00:20.052+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P1_DATUM",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Megfigyelés időpontja",
          //                 "adatTipus": "date",
          //                 "adatAltipus": "",
          //                 "ertek": "2018.03.19.",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "ALLERGIA",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_23C7D84C-B923-11E9-AFDE-5056921CC400"
          //               }
          //             ]
          //           },
          //           "aktiv": true
          //         },
          //         {
          //           "businessObjectSignature": [

          //           ],
          //           "businessObjectLogging": {
          //             "insertDate": "2019-08-07T14:53:29.413+0000",
          //             "insertedBy": "O51364",
          //             "lastModifyDate": "2019-08-07T14:53:29.413+0000",
          //             "lastModifiedBy": "O51364",
          //             "deletedBy": ""
          //           },
          //           "adatkitoltes": {
          //             "businessObjectSignature": [

          //             ],
          //             "businessObjectLogging": {
          //               "insertDate": "2019-08-07T14:53:29.413+0000",
          //               "insertedBy": "O51364",
          //               "lastModifyDate": "2019-08-07T14:53:29.413+0000",
          //               "lastModifiedBy": "O51364",
          //               "deletedBy": ""
          //             },
          //             "businessObjectIdentification": {
          //               "eesztid": {
          //                 "id": "251900000015875065",
          //                 "version": 1
          //               },
          //               "integrationId": ""
          //             },
          //             "businessContent": {
          //               "betegIdErtek": "111111110",
          //               "betegIdTipus": "1",
          //               "letrehozasiIdopont": "2018-02-23T12:05:00.000+0000",
          //               "hitelesito": "51364",
          //               "megfigyelo": "51364",
          //               "intezmenyId": "01060J",
          //               "leiras": "2q3223",
          //               "bizonyossag": "1",
          //               "onrendelkezesiKategoria": ""
          //             }
          //           },
          //           "adatList": {
          //             "profilAdatSzerkezettel": [
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T15:56:40.280+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-20T15:56:40.280+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P1_AGENS",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Kiváltó tényező",
          //                 "adatTipus": "string",
          //                 "adatAltipus": "",
          //                 "ertek": "Méhméreg",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "ALLERGIA",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_1E4294B6-B923-11E9-AFDE-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T15:57:43.225+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-11-07T08:11:40.853+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P1_AGENS_KOD_TIP",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Kiváltó tényező kód típus",
          //                 "adatTipus": "tp_tip",
          //                 "adatAltipus": "AGENS_KOD_TIP",
          //                 "ertek": "KORNYEZETI_TENYEZO",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "ALLERGIA",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_1E434A82-B923-11E9-AFDE-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T15:58:06.677+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-20T15:58:06.677+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P1_AGENS_KOD",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Kiváltó tényező kód",
          //                 "adatTipus": "string",
          //                 "adatAltipus": "",
          //                 "ertek": "4",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "ALLERGIA",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_1E43F50E-B923-11E9-AFDE-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T15:59:11.268+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-11-07T08:13:39.323+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P1_AGENS_TIP",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Kiváltó tényező típus",
          //                 "adatTipus": "tp_tip",
          //                 "adatAltipus": "AGENS_TIPUS",
          //                 "ertek": "environment",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "ALLERGIA",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_1E44A1F2-B923-11E9-AFDE-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-20T16:00:20.052+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-20T16:00:20.052+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P1_DATUM",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Megfigyelés időpontja",
          //                 "adatTipus": "date",
          //                 "adatAltipus": "",
          //                 "ertek": "2018.02.23.",
          //                 "csoportId": "FIGY",
          //                 "tipusId": "ALLERGIA",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_1E454ABC-B923-11E9-AFDE-5056921CC400"
          //               }
          //             ]
          //           },
          //           "aktiv": true
          //         },
          //         {
          //           "businessObjectSignature": [

          //           ],
          //           "businessObjectLogging": {
          //             "insertDate": "2016-10-28T07:48:46.467+0000",
          //             "insertedBy": "",
          //             "lastModifyDate": "2016-10-28T07:48:46.467+0000",
          //             "lastModifiedBy": "",
          //             "deletedBy": ""
          //           },
          //           "adatkitoltes": {
          //             "businessObjectSignature": [

          //             ],
          //             "businessObjectLogging": {
          //               "insertDate": "2016-10-28T07:48:46.467+0000",
          //               "insertedBy": "",
          //               "lastModifyDate": "2016-10-28T07:48:46.467+0000",
          //               "lastModifiedBy": "",
          //               "deletedBy": ""
          //             },
          //             "businessObjectIdentification": {
          //               "eesztid": {
          //                 "id": "ADATKIT_F4C8254C-9CE2-11E6-9E3F-5056921CC400",
          //                 "version": 1
          //               },
          //               "integrationId": ""
          //             },
          //             "businessContent": {
          //               "betegIdErtek": "111111110",
          //               "betegIdTipus": "1",
          //               "letrehozasiIdopont": "2016-10-28T07:48:45.630+0000",
          //               "hitelesito": "13913",
          //               "megfigyelo": "13913",
          //               "intezmenyId": "101010",
          //               "leiras": "a",
          //               "bizonyossag": "3",
          //               "onrendelkezesiKategoria": ""
          //             }
          //           },
          //           "adatList": {
          //             "profilAdatSzerkezettel": [
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-21T19:23:55.080+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-21T19:23:55.080+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P12_VARHATO_DATUM",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Szülés várható időpontja",
          //                 "adatTipus": "date",
          //                 "adatAltipus": "",
          //                 "ertek": "2016-10-31",
          //                 "csoportId": "TER",
          //                 "tipusId": "TERHESSEG",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_F4CB904C-9CE2-11E6-9E3F-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-21T19:24:25.575+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-21T19:24:25.575+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P12_TERH_KIMENETEL",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Várandósság kimenetele",
          //                 "adatTipus": "string",
          //                 "adatAltipus": "",
          //                 "ertek": "jó",
          //                 "csoportId": "TER",
          //                 "tipusId": "TERHESSEG",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_F4CCAAA4-9CE2-11E6-9E3F-5056921CC400"
          //               }
          //             ]
          //           },
          //           "aktiv": true
          //         },
          //         {
          //           "businessObjectSignature": [

          //           ],
          //           "businessObjectLogging": {
          //             "insertDate": "2016-10-28T07:47:22.387+0000",
          //             "insertedBy": "",
          //             "lastModifyDate": "2016-10-28T07:47:22.387+0000",
          //             "lastModifiedBy": "",
          //             "deletedBy": ""
          //           },
          //           "adatkitoltes": {
          //             "businessObjectSignature": [

          //             ],
          //             "businessObjectLogging": {
          //               "insertDate": "2016-10-28T07:47:22.387+0000",
          //               "insertedBy": "",
          //               "lastModifyDate": "2016-10-28T07:47:22.387+0000",
          //               "lastModifiedBy": "",
          //               "deletedBy": ""
          //             },
          //             "businessObjectIdentification": {
          //               "eesztid": {
          //                 "id": "ADATKIT_C2AA84CE-9CE2-11E6-9E3F-5056921CC400",
          //                 "version": 1
          //               },
          //               "integrationId": ""
          //             },
          //             "businessContent": {
          //               "betegIdErtek": "111111110",
          //               "betegIdTipus": "1",
          //               "letrehozasiIdopont": "2016-10-28T07:47:20.758+0000",
          //               "hitelesito": "13913",
          //               "megfigyelo": "13913",
          //               "intezmenyId": "101010",
          //               "leiras": "1211212",
          //               "bizonyossag": "2",
          //               "onrendelkezesiKategoria": ""
          //             }
          //           },
          //           "adatList": {
          //             "profilAdatSzerkezettel": [
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-21T19:18:00.829+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-21T19:18:00.829+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P10_HATOANYAG",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Hatóanyag (készítmény) neve",
          //                 "adatTipus": "string",
          //                 "adatAltipus": "",
          //                 "ertek": "algopyrin",
          //                 "csoportId": "GYO",
          //                 "tipusId": "GYOGYSZER",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_C2AE202A-9CE2-11E6-9E3F-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-21T19:18:25.500+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-11-07T08:27:30.226+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P10_HATOANYAG_KOD_TIP",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Hatóanyag (készítmény) kód típusa",
          //                 "adatTipus": "tp_tip",
          //                 "adatAltipus": "HATOANYAG_KOD_TIP",
          //                 "ertek": "kicsi",
          //                 "csoportId": "GYO",
          //                 "tipusId": "GYOGYSZER",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_C2AF6E76-9CE2-11E6-9E3F-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-21T19:18:46.215+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-21T19:18:46.215+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P10_HATOANYAG_KOD",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Hatóanyag (készítmény) kódja",
          //                 "adatTipus": "string",
          //                 "adatAltipus": "",
          //                 "ertek": "12",
          //                 "csoportId": "GYO",
          //                 "tipusId": "GYOGYSZER",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_C2B071A4-9CE2-11E6-9E3F-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-21T20:09:48.594+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-21T20:09:48.594+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P10_EROSSEG",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Erősség",
          //                 "adatTipus": "string",
          //                 "adatAltipus": "",
          //                 "ertek": "4",
          //                 "csoportId": "GYO",
          //                 "tipusId": "GYOGYSZER",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_C2B17A5E-9CE2-11E6-9E3F-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-21T19:19:12.104+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-21T19:19:12.104+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P10_DOZISFORMA",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Gyógyszerészeti dózisforma",
          //                 "adatTipus": "string",
          //                 "adatAltipus": "",
          //                 "ertek": "pilula",
          //                 "csoportId": "GYO",
          //                 "tipusId": "GYOGYSZER",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_C2B27EF4-9CE2-11E6-9E3F-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-21T19:19:38.894+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-11-07T08:28:11.076+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P10_DOZISFORMA_KOD",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Gyógyszerészeti dózisforma kód",
          //                 "adatTipus": "tp_tip",
          //                 "adatAltipus": "DOZISFORMA_KOD",
          //                 "ertek": "2",
          //                 "csoportId": "GYO",
          //                 "tipusId": "GYOGYSZER",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_C2B382F4-9CE2-11E6-9E3F-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-21T19:20:05.269+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-21T19:20:05.269+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P10_MENNYISEG",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Beszedendő mennyiség",
          //                 "adatTipus": "string",
          //                 "adatAltipus": "",
          //                 "ertek": "34",
          //                 "csoportId": "GYO",
          //                 "tipusId": "GYOGYSZER",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_C2B4817C-9CE2-11E6-9E3F-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-21T19:20:27.146+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-21T19:20:27.146+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P10_GYAKORISAG",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Szedés gyakorisága",
          //                 "adatTipus": "string",
          //                 "adatAltipus": "",
          //                 "ertek": "3",
          //                 "csoportId": "GYO",
          //                 "tipusId": "GYOGYSZER",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_C2B585E0-9CE2-11E6-9E3F-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-21T19:20:59.103+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-21T19:20:59.103+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P10_HOSSZ",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Kezelés hossza",
          //                 "adatTipus": "string",
          //                 "adatAltipus": "",
          //                 "ertek": "12",
          //                 "csoportId": "GYO",
          //                 "tipusId": "GYOGYSZER",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_C2B68A6C-9CE2-11E6-9E3F-5056921CC400"
          //               },
          //               {
          //                 "businessObjectSignature": [

          //                 ],
          //                 "businessObjectLogging": {
          //                   "insertDate": "2016-08-21T19:21:54.282+0000",
          //                   "insertedBy": "",
          //                   "lastModifyDate": "2016-08-21T19:21:54.282+0000",
          //                   "lastModifiedBy": "",
          //                   "deletedBy": ""
          //                 },
          //                 "businessObjectIdentification": {
          //                   "eesztid": {
          //                     "id": "P10_DATUM_KEZD",
          //                     "version": 1
          //                   },
          //                   "integrationId": ""
          //                 },
          //                 "kulcs": "Kezelés kezdete",
          //                 "adatTipus": "string",
          //                 "adatAltipus": "",
          //                 "ertek": "12",
          //                 "csoportId": "GYO",
          //                 "tipusId": "GYOGYSZER",
          //                 "adatszerkezetVerzio": 1,
          //                 "adatEesztID": "ADAT_C2B79330-9CE2-11E6-9E3F-5056921CC400"
          //               }
          //             ]
          //           },
          //           "aktiv": true
          //         }
          //       ]
          //     }

            // this.eproProfilAdatokAdatkitoltessel = this.findprofilAdatokAdatkitoltessel(this.eproData);
