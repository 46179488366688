import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { SharedDataService } from '../services/shared-data.service';
import { Md5 } from 'md5-typescript';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-relogin',
  templateUrl: './relogin.component.html',
  styleUrls: ['./relogin.component.css']
})
export class ReloginComponent implements OnInit {

  constructor(public authService: AuthService,
    private http: HttpClient,
    private router: Router,
    private sharedDataService: SharedDataService,
  ) { }

  apiUrl = environment.apiUrl;

  email = new FormControl('', Validators.required);
  password = new FormControl('', Validators.required);

  loginForm = new FormGroup({
    email: this.email,
    password: this.password
  });

  reloginInProgress = false;

  ngOnInit() {
    console.log('relogin init');
  }

  relogin() {
    // console.log('reLogin');
    this.reloginInProgress = true;
    this.doLogin(this.authService.getUserData()['ekUserName'], Md5.init(this.loginForm.value['password']))
      .subscribe(
        (data: Response) => {
          this.reloginInProgress = false;
          if (data['code'] === 1) {
            this.authService.setUserData(data);
            this.router.navigate(['main']);
          } else {
            // Error on trying to relogin
          }
        }
      );
  }

  doLogin(username: string, password: string) {
    return this.http.get(this.apiUrl + '/rest/neu/authentication/loginusermobile/' +
      username + '/' + password + '/' + this.authService.getUUID() + '/hu');
  }


  logout() {
    this.authService.doLogout();
    this.sharedDataService.resetAllData();
    this.router.navigate(['/login']);
  }

}
