<!-- Modal -->
<div class="modal" id="eesztmessagelist" tabindex="-1" role="dialog" aria-labelledby="eesztmessagelist"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">


    <div [ngClass]="{'hidden': !saveInProgress}" class="eesztMessageQuitConfirmation">
      <div class="quitConfirmationContent">
        Adatbeküldés folyamatban...
      </div>
    </div>

    <div class="modal-content">
      <div class="modal-header">

        <div id="saveSuccess" [ngClass]="{'showResult': processBcpResult == 0}">
          {{ successMessage }}
        </div>
        <div id="saveError" [ngClass]="{'showResult': processBcpResult == -1}">
          <div class="closeResult" (click)="closeResult()">x</div>
          <div class="saveErrorContent">
            <span>{{ processBcpErrorDescription }}</span>
          </div>
        </div>

        <h5 class="modal-title" id="exampleModalLongTitle">EESZT adatbeküldés</h5>
        <!-- <select (change)="bulkSelect($event.target.value)" [selectedIndex]="bulkSelectIndex">
          <option value="0">Kijelölés funkciók</option>
          <option value="1">Összes kijelölés törlése</option>
          <option value="2">Összes kijelölése</option>
          <option value="3">Új üzenetek kijelölése</option>
          <option value="4">Hibás üzenetek kijelölése</option>
        </select> -->
        <button class="btn btn-primary ml-2" (click)="sendBcpMessages(); this.selectAll.reset();"
          [disabled]="!isEesztUser || selectedCount === 0">Beküldés</button>
        <button class="btn btn-primary ml-2" (click)="refreshBcpMessageList()"><i *ngIf="isBcpLoading" class="fa fa-spinner fa-spin"></i> <fa-icon *ngIf="!isBcpLoading" [icon]="faSyncAlt"></fa-icon></button>
        <div>
          <button type="button" class="btn btn-primary ml-1 mb-1" (click)="openHelp('#patient')" title="kézikönyv">
            <i class="fa fa-question-circle"></i>
          </button>
          <button type="button" class="btn btn-secondary ml-1 mb-1" (click)="this.selectAll.reset();" data-dismiss="modal">X</button>
        </div>
      </div>
      <div>
        <!-- <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab == 0}" (click)="switchTab(0)">Paciens adatai</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab == 1}" (click)="switchTab(1)">Paciens dokumentumai</a>
          </li>
        </ul> -->

      </div>
      <div style="margin-left: 16px;">
        <input type="checkbox" [formControl]="selectAll"> összes kiválasztása
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <div class="col-md-12" style="min-height: 300px;">

            <div *ngIf="eesztMessageListLoading">
              <div class="osahanloading"></div>
              <div class="loading-center">Loading eeszt message list...</div>
            </div>


            <div *ngIf="!eesztMessageListLoading">
              <div class="row" *ngIf="eesztMessageCount > 0">
                <div class="col-md-3">Dátum</div>
                <div class="col-md-2">Típus</div>
                <div class="col-md-2">Státusz</div>
                <div class="col-md-5">Leírás</div>

              </div>

              <div *ngIf="eesztMessageCount === 0" class="row">
                <div class="col-md-12 mt-4">
                  <h4 class="text-center">Nincs megjeleníthető eeszt üzenet.</h4>
                </div>
              </div>
              <div *ngIf="eesztMessageListData && eesztMessageListData.length > 0">
                <div *ngFor="let eesztMessage of eesztMessageListData.reverse(); let i = index">
                  <div class="row document-row" [ngClass]="{'eeszt-error': eesztMessage['msgStatus'] === 'ERROR'}">
                    <div class="col-md-3">
                      <input type="checkbox" (click)="itemChange(eesztMessage['transId'], $event, eesztMessage)"
                        [checked]="eesztMessage['checked']">
                      {{ eesztMessage['msgTime'] | date:'yyyy-MM-dd H:mm' }}</div>
                    <div class="col-md-2">{{ eesztMessage['msgModul'] === 'EKAT' ? "Eseménykatalógus" : eesztMessage['msgModul'] === 'EHR' ? "Egészségügyi dokumentum" : eesztMessage['msgModul']}}</div>
                    <div class="col-md-2">{{ eesztMessage['msgStatus'] === 'ERROR' ? 'Hiba' : eesztMessage['msgStatus'] === 'NEW' ? 'Új' : eesztMessage['msgStatus']}}</div>
                    <div class="col-md-5" *ngIf="eesztMessage['msgStatus'] === 'ERROR'">
                      {{
                        eesztMessage['msgReply'].indexOf("faultCode") > 0 ?
                        eesztMessage['msgReply'].substring(eesztMessage['msgReply'].indexOf("faultCode")+ 10, eesztMessage['msgReply'].indexOf("/faultCode") - 1)
                        + ' - ' +
                        eesztMessage['msgReply'].substring(eesztMessage['msgReply'].indexOf("faultString")+ 12, eesztMessage['msgReply'].indexOf("/faultString") - 1)
                        : null
                      }}
                      <!-- faultCode: {{ eesztMessage['msgReplyObj']['eesztResponse']['faultCode'] }}
                      faultString: {{ eesztMessage['msgReplyObj']['eesztResponse']['faultString'] }} -->
                    </div>
                  </div>
                </div>
              </div>

            </div>


          </div>

        </div>
      </div>
    </div>
  </div>
</div>
