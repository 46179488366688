import { ApiService } from './../core/http/api.service';
import { Injectable, OnInit } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Subject, ReplaySubject } from 'rxjs';
import { LoggerService } from './logger.service';
// import { filter, debounceTime, switchMap, map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { debounceTime } from 'rxjs/operators';
import { DateRange } from '../shared/models/dateRange';
import { PatientDataFromQueryParams } from '../shared/models/patientDataFromQueryParams';


export class Element {
  id: number;
  name: string;
}

@Injectable({
  providedIn: 'root'
})

export class SharedDataService implements OnInit {

  apiUrl = environment.apiUrl;

  selectedDateChange = new Subject<string>();
  bookingDateChange = new Subject<string>();
  bookingDateRangeChange = new Subject<DateRange>();
  patientDataForArrival = new Subject<any>();
  patientDataForArrivalSimple = new Subject<any>();

  isEkatUpdate = new ReplaySubject<any>(1);
  patientCalendarDetail = new ReplaySubject<any>(1);
  acDeviceListShared = new ReplaySubject<any>(1);
  employmentDataPositionShared = new ReplaySubject<any>(1);
  employmentDataOrganizationShared = new ReplaySubject<any>(1);

  mulriCalendarChanged = new ReplaySubject<any>(1);;

  justLoggedIn: boolean;

  patientListShared = new ReplaySubject<any>(1);
  csIdListShared = new ReplaySubject<any>(1);
  csIdList = {
    data: [],
    isLoading: true
  };
  selectedCsIdShared = new ReplaySubject<any>(1);
  selectedEmergencyCsIdShared = new ReplaySubject<any>(1);
  selectedLaborServiceIdShared = new ReplaySubject<any>(1);

  patientDataFromQueryParams: PatientDataFromQueryParams = null;
  isEbpPatientFromQueryParams = false;

  allDevicesOfInstitute = new Array<object>();
  allPrintersOfInstitute = new Array<object>();

  allPrintersOfInstituteShared = new ReplaySubject<any>(1);

  reloginState = new ReplaySubject<any>(1);

  calendarModReasonsShared = new ReplaySubject<any>(1);

  doctorsListShared = new ReplaySubject<any>(1);
  doctorsList = [];

  updateCalendarEntryShared = new Subject<any>();

  implantRegBaseShared = new Subject<any>();
  implantRegBase = {};

  onRefreshCalendar = new Subject<any>();

  getAwayTypes = [];
  getAwayTypesShared = new ReplaySubject<any>(1);

  admissionTypes = [];
  admissionTypesShared = new ReplaySubject<any>(1);

  openEkatCount = new ReplaySubject<number>(1);

  bnoListShared = new ReplaySubject<any>(1);
  bnoList = {
    data: [],
    isLoading: true
  };

  isBcpListLoading = new ReplaySubject<boolean>(1);
  labExamListShared = new ReplaySubject<any>(1);
  labExamList = {
    data: [],
    isLoading: true
  };

  labPanelListShared = new ReplaySubject<any>(1);
  labPanelList = {
    data: [],
    isLoading: true
  };

  patientCareTypesShared = new Subject<any>();
  patientCareTypes = {
    categories: [],
    types: {},
    isLoading: true
  };

  selectedService = [];
  config = {};
  calendarFirstTimeStamp = [];

  calendarFirstTimeStampUpdate = new Subject<any>();

  availServic = [];
  availServicesShared = new ReplaySubject<any>(1);

  documentTypes = [];
  documentTypesShared = new ReplaySubject<any>(1);

  selectedRole = null;
  rootDetail = { rootCode: '' };
  patientData = null;
  patientName = null;

  appointmentData = null;
  appointmentDataShared = new Subject<any>();

  isEesztActive = false;

  etListShared = new ReplaySubject<any>(1);
  spListShared = new ReplaySubject<any>(1);

  constructor(
    private loggerService: LoggerService,
    private authService: AuthService,
    private http: HttpClient,
    private apiService: ApiService
  ) { }

  laborSvId = null;

  calendarData = null;
  today = new Date();

  currentDate = null;

  layoutWidth = 1;

  schedulerDayHoursFromTo = new ReplaySubject<any>(1);

  layoutWidthShared = new ReplaySubject<any>(1);

  eszigTokenExpiration = null;
  eszigTokenExpirationShared = new ReplaySubject<any>(1);

  bcpMessageCountShared = new ReplaySubject<any>(1);
  bcpMessageListShared = new ReplaySubject<any>(1);

  // currentDate = this.today.getFullYear() + '-' + (this.today.getMonth() + 1) + '-' + this.today.getDate();
  bookingDate = this.today.getFullYear() + '-' + (this.today.getMonth() + 1) + '-' + this.today.getDate();

  callerSystemAPIURL = 'https://democallersystem.nauticom.hu/caller-system/rest/';

  dispatcherUpdateEntryShared = new Subject();

  scheduleMouseButtonState = new ReplaySubject<boolean>(1);

  uploadDocTypeFilter = {
    'UDMED': [
      'ALT_LABOR_LELET',
      'AMBLAP',
      'LABOR_LELET',
      'RTG_LELET',
      'EGYEB_ZARO_DOK',
      'EXTERNAL_PIC_JPG',
      'EXTERNAL_PIC_PNG',
      'STATUS',
      'PATOL_LELET',
      'MUTET_LEIR',
      'MIKRO_LABOR_LELET',
      'BEUTALO'
    ]
  };

  TP_EESZT_PRO_P7_IMPL_KOD_TIP_shared = new ReplaySubject<any>(1);
  TP_EESZT_PRO_P8_TERAPIA_TIPU_shared = new ReplaySubject<any>(1);
  TP_EESZT_PRO_P6_PROBLEMA_KOD_shared = new ReplaySubject<any>(1);
  TP_EESZT_PRO_P7_IMPL_KOD_shared = new ReplaySubject<any>(1);
  TP_EESZT_PRO_P9_AUTONOMIA_KO_shared = new ReplaySubject<any>(1);
  TP_EESZT_PRO_P13_VERCSOPORT_shared = new ReplaySubject<any>(1);
  TP_EESZT_PRO_P2_LEIRAS_KOD_shared = new ReplaySubject<any>(1);
  TP_EESZT_PRO_P3_OLTAS_KOD_shared = new ReplaySubject<any>(1);
  TP_EESZT_PRO_P4_PROBLEMA_KOD_shared = new ReplaySubject<any>(1);
  TP_EESZT_PRO_P5_BEAV_KOD_TIP_shared = new ReplaySubject<any>(1);
  TP_EESZT_PRO_P11_ELETMOD_KOD_shared = new ReplaySubject<any>(1);
  TP_EESZT_PRO_P12_TERH_KIMENE_shared = new ReplaySubject<any>(1);
  TP_EESZT_PRO_P1_AGENS_TIP_shared = new ReplaySubject<any>(1);
  TP_EESZT_PRO_P1_REAKCIO_KOD_shared = new ReplaySubject<any>(1);
  TP_EESZT_PRO_P10_DOZISFORMA__shared = new ReplaySubject<any>(1);
  TP_EESZT_PRO_P10_HATOANYAG_K_shared = new ReplaySubject<any>(1);
  TP_EESZT_PRO_P1_AGENS_KOD_TIP_shared = new ReplaySubject<any>(1);

  eproTor = {};

  Brand = '';

  ngOnInit() {
    this.Brand = this.authService.getBrand();
  }

  updateIsBcpLoadingStatus(isBcpLoading: boolean) {
    this.isBcpListLoading.next(isBcpLoading);
  }


  setLayoutWidth(value) {
    this.layoutWidthShared.next(value);
  }

  setSchedulerDayHoursFromTo(schedulerDayHoursFrom, schedulerDayHoursTo) {
    this.schedulerDayHoursFromTo.next({ schedulerDayHoursFrom, schedulerDayHoursTo });
  }

  loadPatientList() {

    this.searchPatientFn('').subscribe((data) => {
      // console.log('shared patientData arrived');
      if (data['code'] === 1) {
        data['data'].sort(this.sortPatients);
        let i = 0;
        data['data'].forEach(entry => {
          const medicalID = entry['medicalId'] ? ', ' + entry['medicalId'] : '';
          entry['displayData'] = entry['currName'] + ', ' + entry['chk4TDate'].substring(0, 10) + medicalID;
          i++;
        });
        // console.log(data);
        this.patientListShared.next(data['data']);
      }
    });

  }

  setSchedulerMouseButtonState(buttonState: boolean) {
    this.scheduleMouseButtonState.next(buttonState);
  }

  setEkatMode(isUpdate: boolean) {
    this.isEkatUpdate.next(isUpdate);
  }

  setIsPatientDone(patientCalendarDetail) {
    this.patientCalendarDetail.next(patientCalendarDetail);
  }

  sortPatients(a, b) {
    if (a['currName'] && b['currName']) {
      if (a['currName'].toLowerCase() > b['currName'].toLowerCase()) {
        return 1;
      } else if (a['currName'].toLowerCase() < b['currName'].toLowerCase()) {
        return -1;
      }
      return 0;
    } else {
      return 0;
    }
  }

  updateDispatcherCalEntry(calendarItem, status) {
    this.dispatcherUpdateEntryShared.next({ calItem: calendarItem, status: status });
  }


  loadInstituteDoctors() {
    this.getInstituteDoctors('').subscribe((data) => {
      // console.log('shared institute Doctors arrived');
      // console.log(data['data']);
      if (data['code'] === 1) {

        // const uniqueDoctorsList = Array.from(new Set(data['data'].map(a => a.id)))
        // .map(id => {
        //   return data['data'].find(a => a.id === id);
        // });

        const uniqueDoctorsList = data['data'].reduce((acc, current) => {
          const x = acc.find(item => item.id === current.id);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);

        uniqueDoctorsList.sort(this.sortDoctors);

        this.doctorsListShared.next(uniqueDoctorsList);
        this.doctorsList = uniqueDoctorsList;
        // console.log('uniqueDoctorsList', uniqueDoctorsList);
      } else {
        // hiba
      }
    });
  }

  sortDoctors(a, b) {
    return new Intl.Collator('hu').compare(a['nameWithTitle'], b['nameWithTitle']);  // -1
  }

  resetAllData() {
    this.doctorsList = [];
    this.implantRegBase = {};
    this.getAwayTypes = [];
    this.admissionTypes = [];
    this.bnoList = {
      data: [],
      isLoading: true
    };
    this.selectedService = [];
    this.config = {};
    this.calendarFirstTimeStamp = [];
    this.availServic = [];
    this.selectedRole = null;
    this.patientData = null;
    this.patientName = null;
    this.eszigTokenExpiration = 0;
    this.eszigTokenExpirationShared.next(0);
  }

  loadPatientCareTypes() {

    this.getPatientCareTypes('').subscribe((data) => {

      if (data['code'] === 1) {
        const careTypes = {
          categories: [],
          types: {},
          isLoading: false
        };

        data['data'].forEach(item => {
          if (!careTypes.categories.find(obj => obj.typeCode === item['typeCode'])) {
            careTypes.categories.push({ typeCode: item['typeCode'], typeDesc: item['typeDesc'] });
          }
          if (careTypes.types[item['typeCode']] === undefined) {
            careTypes.types[item['typeCode']] = [];
          }
          careTypes.types[item['typeCode']].push(item);
        });

        // console.log('shared patient care types arrived');
        // console.log(data);
        this.patientCareTypesShared.next(careTypes);
        this.patientCareTypes = careTypes;
      } else {
        // hiba
      }
    });

  }


  searchPatientFn(val: string) {

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.selectedRole['roleCode'],
        currentServicePointId: this.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      'contextParams': 'NAME_PART=' + val + '#'
    };

    return this.http.post<any[]>(this.apiUrl + '/rest/neu/authentication/searchinstituteusersv2', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }


  getInstituteDoctors(val: string) {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.selectedRole['roleCode'],
        currentServicePointId: this.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      servicePointId: this.selectedRole['spId']
    };

    return this.http.post<any[]>(this.apiUrl + '/rest/neu/calendarlist/getinstitutedoctors/', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getPatientCareTypes(val: string) {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.selectedRole['roleCode'],
        currentServicePointId: this.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      }
    };
    return this.http.post<any[]>(this.apiUrl + '/rest/neu/calendarlist/getpatientcaretypes', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }


  setCalendarData(data: any) {
    this.calendarData = data;
  }


  returnCalendarData() {
    return this.calendarData.slice();
  }

  returnCalendarDataforDay(day: NgbDate) {

    const datumString = day['year'] + '-' + day['month'] + '-' + day['day'];
    if (this.calendarData) {
      const result = this.calendarData.find
        (dateEntry => {
          const currentDate = new Date(dateEntry['dateFrom']);
          const currentDateString = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate();
          if (currentDateString === datumString) {
            return dateEntry;
          } else {
            return false;
          }
        }
        );
      return result;
    } else { return false; }
  }

  setCurrentDate(date: Date) {
    const currentDate = this.dateToDateString(new Date(date));
    const now = new Date();

    this.setConfig('selectedDate', JSON.stringify({ date: currentDate, savedAt: now.getTime() }));

    this.currentDate = currentDate;
    // console.log('date set in shared data service: ', this.currentDate);
    this.selectedDateChange.next(this.currentDate);

  }


  getCurrentDate() {

    // console.log('Shared data service getCurrentDate');

    if (this.currentDate) {
      const currentDate = this.dateToDateString(new Date(this.currentDate));
      return currentDate;

    } else {
      // console.log('Shared data service this.currentDate not set');

      if (this.getConfig('selectedDate')) {
        // console.log('Shared data service checking date in config');
        const dateFromConfig = JSON.parse(this.getConfig('selectedDate'));
        const now = new Date();
        const savedAt = new Date(dateFromConfig.savedAt);

        // console.log('Current day: ' + now.getDate());
        // console.log('Date from day: ' + savedAt.getDate());

        if (now.getDate() === savedAt.getDate()) {
          // console.log('Saved date valid, returning saved date');
          return dateFromConfig.date;
        } else {
          // console.log('Saved date too old, returning current date');
          const currentDate = this.dateToDateString(new Date());
          return currentDate;
        }
      } else {
        // console.log('No saved date found in config, returning current date');
        const currentDate = this.dateToDateString(new Date());
        return currentDate;
      }

    }

  }

  setBookingDate(date: Date) {

    // console.log ('SDS setBookingDate 1:', date);
    const currentDate = this.dateToDateString(new Date(date));
    // console.log ('SDS setBookingDate 2:', currentDate);
    // if (this.bookingDate !== currentDate || currentDate) {
    this.bookingDate = currentDate;
    // console.log('set booking date: ' + this.bookingDate);
    this.bookingDateChange.next(this.bookingDate);
    // }
  }

  setBookingDateRange(fromDate: Date, toDate: Date) {
    this.bookingDateRangeChange.next({ fromDate, toDate })
  }

  setPatientData(patientData: any) {
    // console.log('setPatientData', patientData);
    this.patientData = patientData;
    this.patientName = patientData['participantName'];
    this.patientDataForArrival.next(patientData);
  }

  setPatientDataSimple(patientData: any) {
    // console.log('setPatientDataSimple', patientData);
    this.patientData = patientData;
    this.patientName = patientData['participantName'];
    this.patientDataForArrivalSimple.next(patientData);
  }

  setAppointmentData(data: any) {
    // console.log('setAppointmentData', data);
    this.appointmentData = data;
    this.appointmentDataShared.next(data);
  }

  getBookingDate() {
    return this.bookingDate;
  }

  clearSelectedService() {
    this.selectedService = [];
    this.setConfig('selectedServices', null);
  }

  setSelectedService(service: {}, calendarInstance: string) {
    this.selectedService[calendarInstance] = service;
    // console.log(service);
    // console.log('service: ' + service + ' calendarID: ' + calendarInstance);
    this.setConfig('selectedServices', JSON.stringify(this.selectedService));
    // localStorage.setItem('selectedServices', JSON.stringify(this.selectedService));
  }

  getSelectedService(calendarInstance) {
    if (this.selectedService.length === 0 && this.getConfig('selectedServices') != null) {
      this.selectedService = JSON.parse(this.getConfig('selectedServices'));
    }
    return this.selectedService[calendarInstance];
  }


  // setAvailservices(selectedRole, services) {
  //   this.availServic[selectedRole] = services;
  // }

  // getAvailservice(selectedRole) {
  //   // console.log('get selected serice for role: ' + selectedRole);
  //   if (this.availServic !== null) {
  //     if (this.availServic[selectedRole]) {
  //       return (this.availServic[selectedRole]);
  //     } else {
  //       return null;
  //     }
  //   } else {
  //     return null;
  //   }
  // }

  setSelectedRole(role) {
    this.selectedRole = role;
    console.log('set selected role', role);
    if (this.authService.getBrand() === 'DOLGEK') {
      this.loadServiceDetail();
      this.loadAcDeviceList();
    }

    this.loadCsidList();
    // this.getAllDeviceOfInstitute(role['roleCode']);

    this.setConfig('selectedRole', JSON.stringify(this.selectedRole));

  }

  getSelectedRole() {
    // console.log('get selected role');
    if (this.selectedRole === null && this.getConfig('selectedRole') != null) {
      // console.log('get selected role from localstorage');
      this.selectedRole = JSON.parse(this.getConfig('selectedRole'));
    }
    return this.selectedRole;
  }


  // getAllDeviceOfInstitute(nauId: string) {
  //   const devices = [];
  //   const printers = [];
  //   this.apiService.getAllDevicesOfInstitute(nauId).subscribe((response: GetAllDevicesOfInstituteResponse) => {
  //     response.authorities.forEach(element => {
  //       if (element.type === 'Diszpécser' || element.type === 'Ellátóhely') {
  //         devices.push(element);
  //       }
  //       if (element.type === 'Asztali nyomtató') {
  //         printers.push(element);
  //       }
  //     });
  //     this.allDevicesOfInstitute = devices;
  //     this.allPrintersOfInstitute = printers;
  //     this.allPrintersOfInstituteShared.next(printers);

  //     console.log('allDevicesOfInstitute', devices);
  //     console.log('allPrintersOfInstitute', printers);
  //   }, error => {
  //     console.error(error);
  //   });
  // }

  getRootDetail() {
    // console.log('get rootDetail role');
    if (this.rootDetail.rootCode === '' && this.getConfig('rootDetail') != null) {
      // console.log('get rootDetail from localstorage');
      // console.log('getConfig rootDetail', this.getConfig('rootDetail'));
      this.rootDetail = this.getConfig('rootDetail');
    }
    // } else {
    //   console.log ('get rootDetail else', this.rootDetail);
    //   console.log ('getconfig rootDetail else', this.getConfig('rootDetail'));
    //   console.log ('getconfig rootDetail else3', this.rootDetail.rootCode === '');

    // }
    return this.rootDetail;
  }

  setConfig(key: string, value: any) {
    this.config[key] = value;
    localStorage.setItem('config', JSON.stringify(this.config));
  }

  getConfig(key: string) {
    const storedConfig = JSON.parse(localStorage.getItem('config'));

    if (this.config[key]) {
      return this.config[key];
    }

    if (storedConfig) {
      if (Object.keys(this.config).length === 0 && Object.keys(storedConfig).length > 0) {

        this.config = storedConfig;

      } else if (Object.keys(this.config).length > 0 && this.config[key] === undefined) {

        if (storedConfig != null && storedConfig !== undefined) {
          if (storedConfig[key] !== undefined) {
            this.config[key] = storedConfig[key];
          }
        }
      }
    }
    if (this.config !== undefined) {
      return this.config[key];
    } else {
      return null;
    }
  }

  setFirstCalenderAppTime(timestamp, calendarId) {
    this.calendarFirstTimeStamp[calendarId] = timestamp;
    this.calendarFirstTimeStampUpdate.next(this.calendarFirstTimeStamp);
  }

  loadImplantRegBase() {
    if (this.selectedRole) {
      this.getImplantReg().subscribe((data) => {

        if (data['code'] === 1) {
          const implantRegCategories = {
            categories: [],
            types: {},
            list: [],
            isLoading: false
          };

          data['data'].forEach(item => {
            // console.log('impreg shared item', item);
            if (item['validity'] === '1') {
              if (item['grpLevel'] === '1') {
                item['visibleDetail'] = item['grpCode'] + ' - ' + item['name'];
              } else {
                item['visibleDetail'] = item['code'] + ' - ' + item['name'];
              }

              if (!implantRegCategories.categories.find(obj => obj.grpCode === item['grpCode'])) {
                implantRegCategories.categories.push({ grpCode: item['grpCode'], grpDesc: item['visibleDetail'] });
              }
              if (implantRegCategories.types[item['grpCode']] === undefined) {
                implantRegCategories.types[item['grpCode']] = [];
              }
              if (item['grpLevel'] !== '1') {
                implantRegCategories.types[item['grpCode']].push(item);
                implantRegCategories.list[item['id']] = item;
              }
            }
          });

          // console.log('shared implant reg base arrived');
          // console.log(implantRegCategories);

          this.implantRegBaseShared.next(implantRegCategories);
          this.implantRegBase = implantRegCategories;
        } else {
          // hiba
          // console.log('API ERROR: "getImplantReg" - ' + data['code'] + ' ' + data['message']);
        }
      });
    }
  }



  getImplantReg() {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.selectedRole['roleCode'],
        currentServicePointId: this.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      }
    };

    return this.http.post<any[]>(this.apiUrl + '/rest/neu/calendarlist/getimplantreg', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }


  loadGetawayTypes() {

    this.getGetawayTypes().subscribe((data) => {

      if (data['code'] === 1) {

        this.getAwayTypes = data['data'];
        this.getAwayTypesShared.next(data['data']);

      } else {
        // hiba
        // console.log('API ERROR: "getGetawayTypes" - ' + data['code'] + ' ' + data['message']);
      }
    });
  }

  getGetawayTypes() {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.selectedRole['roleCode'],
        currentServicePointId: this.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      }
    };

    return this.http.post<any[]>(this.apiUrl + '/rest/neu/calendarlist/getgetawaytypes', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }


  loadCalendarModReasons() {

    this.getCalendarModReasons().subscribe((data) => {

      if (data['code'] === 1) {

        this.calendarModReasonsShared.next(data['data']);

      } else {
        // hiba
        // console.log('API ERROR: "getCalendarModReasons" - ' + data['code'] + ' ' + data['message']);
      }
    });
  }

  getCalendarModReasons() {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.selectedRole['roleCode'],
        currentServicePointId: this.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      }
    };

    return this.http.post<any[]>(this.apiUrl + '/rest/neu/calendarlist/getcalendarmodreasons', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  loadAdmissionTypes() {

    this.getAdmissionTypes().subscribe((data) => {

      if (data['code'] === 1) {

        this.admissionTypes = data['data'];
        this.admissionTypesShared.next(data['data']);

      } else {
        // hiba
        // console.log('API ERROR: "getAdmissionTypes" - ' + data['code'] + ' ' + data['message']);
      }
    });
  }

  getAdmissionTypes() {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.selectedRole['roleCode'],
        currentServicePointId: this.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      }
    };

    return this.http.post<any[]>(this.apiUrl + '/rest/neu/calendarlist/getadmissiontypes', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }



  loadBnoList() {
    this.getBnoMainGroupList().subscribe((data) => {

      if (data['code'] > 0) {
        data['data'].forEach(item => {
          item['visibleData'] = item['subGrpCode'] + ' ' + item['subGrpName'];
        });

        // console.log('BNO List arrived');
        this.bnoList['isLoading'] = false;
        this.bnoList['data'] = data['data'];
        // console.log(this.bnoList);
        this.bnoListShared.next(this.bnoList);
      } else {

        // hiba error received in response

      }

    });
  }

  getBnoMainGroupList() {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.selectedRole['roleCode'],
        currentServicePointId: this.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      }
    };

    return this.http.post<any[]>(this.apiUrl + '/rest/neu/calendarlist/getbnosubgrouplist', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  refeshCalendar(servicePointId) {
    // console.log('refeshCalendar', servicePointId);
    this.onRefreshCalendar.next(servicePointId);
  }

  refeshCalendarWithDate(servicePointId, date: Date) {
    const dateString = this.dateToDateString(date);
    if (dateString === this.getCurrentDate()) {
      this.onRefreshCalendar.next(servicePointId);
    }
  }

  setReloginState(value: boolean) {
    // console.log('set relogin state in sharedDataService:' + value);
    this.reloginState.next(value);
  }

  loadAvailServices() {
    // console.log ('Loading available services');

    this.getAvailService().subscribe(response => {
      if (response['code'] === 1) {
        // console.log ('Available services loaded', response['objAvailServicesList']);
        this.availServic = response['data'];

        let etList = [];
        let spList = [];

        this.availServic.forEach(serviceItem => {
          if (!etList.find(obj => obj.etId === serviceItem.etId)) {
            etList.push({'etId': serviceItem['etId'], 'etName': serviceItem['etName']});
          }
          if (!spList.find(obj => obj.spId === serviceItem.spId)) {
            spList.push({'spId': serviceItem['spId'], 'spName': serviceItem['spName']});
          }
        });

        // console.log('spList: ', spList);
        // console.log('etList: ', etList);
        

        this.etListShared.next(etList);
        this.spListShared.next(spList);

        this.availServicesShared.next(response['data']);

        // this.availServic.forEach(item => {
        //   console.log ('itemSpId ' + item.spId + ' | ' + 'roleSpId ' + this.selectedRole['spId'] + ' | ' + item.etCode);
        //   if (item.spId == this.selectedRole['spId'] && item.etCode == 'LABVIZSG') {
        //     this.laborSvId = item.svId;
        //     console.log ('found ', this.laborSvId);
        if (this.getSelectedLaborService()) {
          this.loadLabExamList(this.getSelectedLaborService().svId);
          this.loadLabPanelList(this.getSelectedLaborService().svId);
        }
        //   }
        // });

      } else {
        this.availServicesShared.next({});
      }
    });
  }


  getAvailService() {
    let params: {};

    if (this.selectedRole) {
      params = {
        messageHeader: {
          currentTapass: this.authService.getUserData()['ekCardNo'],
          currentRole: this.selectedRole['roleCode'],
          currentServicePointId: this.selectedRole['spId'],
          locale: 'hu',
          deviceId: this.authService.getUUID()
        },
        isXmlDetailResponseRequired: true,
        isFutureScheduleCheckRequired: false
      };
    } else {
      params = {
        messageHeader: {
          currentTapass: this.authService.getUserData()['ekCardNo'],
          currentRole: this.authService.getUserData()['roles'][0]['code'],
          currentServicePointId: this.selectedRole['spId'],
          locale: 'hu',
          deviceId: this.authService.getUUID()
        },
        isXmlDetailResponseRequired: true,
        isFutureScheduleCheckRequired: true
      };
    }
    // return this.http.post<any[]>(this.apiUrl + '/rest/neu/appointment/getavailservice/', params, {
    return this.http.post<any[]>(this.apiUrl + '/rest/neu/appointment/getuserservices/', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  loadDocumentTypes() {
    // console.log ('Loading available services');
    this.getDocumentTypes().subscribe(response => {
      if (response['code'] === 1) {
        const excludeList = [240, 242, 241, 237, 238, 236, 231, 235, 232, 233, 239, 234,
          230, 29267816, 29267815, 125, 127, 128, 26155658, 109, 110,
          65, 62, 63, 64, 108];
        const docTypeList = [];
        if (response['data'].length > 0) {
          response['data'].forEach(entry => {
            if (excludeList.indexOf(entry['id']) === -1) {
              if (this.authService.getBrand() === 'UDMED') {
                if (this.uploadDocTypeFilter['UDMED'].indexOf(entry['code']) >= 0) {
                  docTypeList.push(entry);
                }
              } else {
                docTypeList.push(entry);
              }
            }
          });
        }
        // console.log ('Document types loaded', response);
        this.documentTypes = docTypeList;
        this.documentTypesShared.next(docTypeList);
      } else {
        // error
      }
    });
  }


  getDocumentTypes() {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.selectedRole['roleCode'],
        currentServicePointId: this.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      }
    };

    return this.http.post(this.apiUrl + '/rest/neu/docinv/getdocumenttypes', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }

  loadLabExamList(laborSvId) {
    // console.log ('Loading labexamlist');
    this.getLabExamList(laborSvId).subscribe(response => {
      if (response['code'] === 1) {

        this.labExamList['isLoading'] = false;

        const labExamListData = response['labExamList'];
        labExamListData.sort(this.sortLabExamList);
        if (labExamListData.length > 0) {
          const lastExamId = parseInt(labExamListData[labExamListData.length - 1]['extExamCode'], 10);
          // console.log('lastExamId', lastExamId);

          const labExamProcessedList = [];
          let labExamItem = {};
          // for (let i = 0; i <= lastExamId; i++) {
          //   labExamItem = labExamListData.find(labExamEntry => parseInt(labExamEntry.commCode, 10) === i);
          //     if (labExamItem) {
          //       // console.log ('labExamItem', labExamItem);
          //       labExamProcessedList[i] = labExamItem;
          //     } else {
          //       labExamProcessedList[i] = {};
          //     }
          // }

          // let labExamRow = 0;

          // for (let i = 0; i <= lastExamId; i++) {
          //   labExamItem = labExamListData.find(labExamEntry => parseInt(labExamEntry.commCode, 10) === i);
          //   if (labExamItem) {
          //     labExamRow = Math.floor(i / 10);
          //     if (!labExamProcessedList[labExamRow]) {
          //       labExamProcessedList[labExamRow] = { rowNumber: (labExamRow * 10), data: [] };
          //     }
          //     labExamProcessedList[labExamRow].data[i - labExamRow * 10] = labExamItem;
          //   } else {

          //   }
          // }

          let labExamRowData = [];
          for (let i = 0; i <= Math.floor(lastExamId / 10); i++) {
            labExamRowData = [];
            for (let j = 0; j <= 9; j++) {
              labExamItem = labExamListData.find(labExamEntry => parseInt(labExamEntry.extExamCode, 10) === i * 10 + j);
              if (labExamItem) {
                labExamRowData[j] = labExamItem;
              }
            }
            if (labExamRowData.length > 0) {
              labExamProcessedList[i] = ({ rowNumber: i * 10, data: labExamRowData });
              // labExamProcessedList.push ({ rowNumber: i * 10, data: labExamRowData});
            }
          }

          // console.log('labExamProcessedList PRE', labExamProcessedList);

          labExamProcessedList.forEach(labRow => {
            for (let j = 0; j <= 9; j++) {
              if (labRow) {
                if (!labRow.data[j]) {
                  // console.log('insert empty into', ((i * 10) + j));
                  labRow.data[j] = {};
                }
              }
            }
          });

          // for (let i = 0; i < labExamProcessedList.length; i++) {
          //     for (let j = 0; j <= 9; j++) {
          //       if (labExamProcessedList[i]) {
          //         if (!labExamProcessedList[i].data[j]) {
          //           // console.log('insert empty into', ((i * 10) + j));
          //           labExamProcessedList[i].data[j] = {};
          //         }
          //       }
          //     }
          // }

          // console.log('labExamProcessedList', labExamProcessedList);

          this.labExamList['data'] = labExamProcessedList;
          this.labExamListShared.next(this.labExamList);

        } else {
          // error
        }
      }
    });
  }


  sortLabExamList(a, b) {
    if (parseInt(a['extExamCode'], 10) > parseInt(b['extExamCode'], 10)) {
      return 1;
    } else if (parseInt(a['extExamCode'], 10) < parseInt(b['extExamCode'], 10)) {
      return -1;
    }
    return 0;
  }

  getLabExamList(laborSvId) {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.selectedRole['roleCode'],
        currentServicePointId: this.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      'laborSvId': laborSvId, // 2279823,
      'examCodeList': '',
      'systemCode': 'ITG'
    };

    return this.http.post(this.apiUrl + '/rest/neu/laborder/getlabexamlist', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  loadLabPanelList(laborServiceId) {
    // console.log ('Loading available services');
    this.getLabPanelList(laborServiceId).subscribe(response => {
      if (response['code'] === 1) {
        // console.log('labPanelList', response);
        this.labPanelList['isLoading'] = false;
        this.labPanelList['data'] = response['laborPanelList'];
        this.labPanelListShared.next(this.labPanelList);
      } else {
        // error
      }
    });
  }

  getLabPanelList(laborServiceId) {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.selectedRole['roleCode'],
        currentServicePointId: this.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      'laborSvId': laborServiceId
    };

    return this.http.post(this.apiUrl + '/rest/neu/laborder/getlabpanellist', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  dateFromISO8601(isostr) {
    const parts = isostr.match(/\d+/g);
    parts[1] = parts[1] - 1;
    return new Date(parts[0], parts[1], parts[2], parts[3], parts[4], parts[5]);
  }

  /* dateFromISO8601(isostr) {
    if (isNaN(isostr)) {
    const parts = isostr.match(/\d+/g);
    parts[1] = parts[1] - 1;
    return new Date(parts[0], parts[1], parts[2], parts[3], parts[4], parts[5]);
    } else {
    return isostr;
    }
  } */

  dateFromUserinput(isostr) {
    // console.log('isostr', isostr);
    const parts = isostr.match(/\d+/g);
    if (parts !== null && parts.length > 2) {
      parts[1] = parts[1] - 1;
      parts[1] = (('0' + parts[1]).slice(-2));
      parts[2] = (('0' + parts[2]).slice(-2));
      // console.log ('parts', parts);
      return new Date(parts[0], parts[1], parts[2]);
    } else if (isostr.length === 8) {
      const year = isostr.substring(0, 4);
      const month = isostr.substring(4, 6) - 1;
      const day = isostr.substring(6, 8);
      if (year > 0 && month >= 0 && day > 0) {
        return new Date(year, month, day);
      }
    }
  }

  DateStringConvert(date: string) {
    // const dateFromDate = this.dateFromISO8601(date.substring(0, date.indexOf('.')));
    const dateFromDate = this.dateFromISO8601(date);
    const dateReturn = dateFromDate.getTime() - (dateFromDate.getTimezoneOffset() * 60000);
    return dateReturn;
  }

  dateToDateString(date: Date) {
    const dateString = date.getFullYear() + '-' +
      (('0' + (date.getMonth() + 1)).slice(-2)) + '-' +
      (('0' + date.getDate()).slice(-2));
    return dateString;
  }

  dateToDateTimeString(date: Date) {
    const dateTimeString = date.getFullYear() + '-' + (('0' + (date.getMonth() + 1)).slice(-2)) + '-' + (('0' + date.getDate()).slice(-2)) +
      ' ' + (('0' + date.getHours()).slice(-2)) + ':' + (('0' + date.getMinutes()).slice(-2));

    return dateTimeString;
  }

  dateToDateTimeStringHuman(date: Date) {
    const dateTimeString = date.getFullYear() + '-' + (('0' + (date.getMonth() + 1)).slice(-2)) + '-' + (('0' + date.getDate()).slice(-2)) +
      ' ' + (('0' + date.getHours()).slice(-2)) + ':' + (('0' + date.getMinutes()).slice(-2)) + ':' + (('0' + date.getSeconds()).slice(-2));

    return dateTimeString;
  }

  dateStringToNgbDate(dateString: string) {
    const date = new Date(dateString);
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
  }

  dateToNgbDate(date: Date) {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
  }

  NgbDateToDateString(date: NgbDate) {
    const dateString = date.year + '-' + (('0' + date.month).slice(-2)) + '-' + (('0' + date.day).slice(-2));
    return dateString;
  }

  dateToFormattedDateString(date: Date){
    return date.getFullYear() + '/' + (('0' + (date.getMonth() + 1)).slice(-2)) + '/' + (('0' + date.getDate()).slice(-2));
  }


  requireEszigToken() {

    const hisNonce = this.authService.getNonce();
    const params = {
      messageHeader: {
        // currentTapass: this.authService.getUserData()['ekCardNo'],
        // currentRole: this.selectedRole['roleCode'],
        // currentServicePointId: this.selectedRole['spId'],
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: 'AUTH$BJK-EKMED_DOCTOR',
        currentServicePointId: this.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      hisNonce: hisNonce,
      uid: ''
    };

    return this.http.post(this.apiUrl + '/rest/ecm/saml/requireeszigtoken', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }


  getEszigToken(eidToken: string) {

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: 'AUTH$BJK-EKMED_DOCTOR',
        currentServicePointId: this.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      eidToken: eidToken,
      eszigUuid: ''
    };

    return this.http.post(this.apiUrl + '/rest/ecm/saml/geteszigtoken', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  setEszigTokenExpiration(expiration: string) {
    if (this.DateStringConvert(expiration) > new Date().getTime()) {
      localStorage.setItem('eszigTokenExpiration', this.DateStringConvert(expiration).toString());
      this.eszigTokenExpiration = this.DateStringConvert(expiration);
      this.eszigTokenExpirationShared.next(this.eszigTokenExpiration);
      this.isEesztActive = true;
    }
  }

  getEszigTokenExpiration() {
    if (this.eszigTokenExpiration > 0) {
      return (this.eszigTokenExpiration);
    } else {
      if (localStorage.getItem('eszigTokenExpiration')) {
        this.eszigTokenExpiration = localStorage.getItem('eszigTokenExpiration');
        return (this.eszigTokenExpiration);
      }
    }
  }

  getBcpMessagesCount() {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.selectedRole['roleCode'],
        currentServicePointId: this.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      status: 'ERROR, NEW'
    };

    return this.http.post(this.apiUrl + '/rest/ecm/bcp/getbcpmessagescount', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  loadBcpMessageCount() {
    this.getBcpMessagesCount().subscribe(response => {
      if (response['code'] === 1) {
        // console.log ('BCP Message count:', response['data']);
        this.bcpMessageCountShared.next(response['data']);
      } else {
        console.error('BCP Message count ERROR', response['message']);
      }
    });
  }


  getBcpMessages() {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.selectedRole['roleCode'],
        currentServicePointId: this.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      status: 'ERROR, NEW'
    };

    return this.http.post(this.apiUrl + '/rest/ecm/bcp/getbcpmessages', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  loadBcpMessages() {
    this.updateIsBcpLoadingStatus(true);
    this.getBcpMessages().subscribe(response => {
      this.updateIsBcpLoadingStatus(false);
      if (response['code'] === 1) {
        // console.log ('BCP Messagelist:', response);
        this.bcpMessageListShared.next(response['bcpList']);
      } else {
        console.error('BCP Messagelist ERROR', response['message']);
      }
    }, err => {
      this.updateIsBcpLoadingStatus(false);
    });
  }

  updateCalendarEntry(calendarId, status) {
    this.updateCalendarEntryShared.next({ calendarId: calendarId, status: status });
  }


  loadCsidList() {
    // console.log('sharedDataService loadCsidList');

    const devices = [];
    const printers = [];

    if (this.getSelectedRole() && this.getSelectedRole()['roleCode']) {
      const role = this.getSelectedRole()['roleCode'];
      let rolePrefix = '';
      const csidList = [];
      if (role.indexOf('_') > 0) {
        rolePrefix = role.substr(0, role.indexOf('_'));
        // console.log('loadCsid', rolePrefix);

        this.getalldevicesofinstitute(rolePrefix).subscribe(
          (csidListResponse: Response) => {
            if (csidListResponse['code'] === 200) {
              csidListResponse['authorities'].forEach(element => {
                if (element.type === 'Diszpécser' || element.type === 'Ellátóhely' || element.type === 'Ellátóhely') {
                  csidList.push(element);
                }
                if (element.type === 'Asztali nyomtató') {
                  printers.push(element);
                }
                devices.push(element);
              });
              this.allDevicesOfInstitute = devices;
              // console.log ('allDevicesOfInstitute',this.allDevicesOfInstitute);
              this.allPrintersOfInstitute = printers;
              this.allPrintersOfInstituteShared.next(printers);
            } else {
              console.log('Error loading csidList');
            }

            this.csIdList['isLoading'] = false;
            this.csIdList['data'] = csidList;
            this.csIdListShared.next(this.csIdList);
            // console.log('csidListResponse authorities', this.csIdList['data']);
          });
      }
    }
  }

  getalldevicesofinstitute(rolePrefix: string) {
    return this.http.get(environment.callerSystemUrl + 'rest/authority/getalldevicesofinstitute/' +
      rolePrefix);
  }

  gettreeforcsid(rolePrefix: string) {
    return this.http.get(environment.callerSystemUrl + 'rest/authority/gettreeforcsid/' +
      rolePrefix);
  }

  reloadSelectedCsId() {
    if (localStorage.getItem('medcseppCsId')) {
      this.setSelectedCsId(localStorage.getItem('medcseppCsId'));
    }
    if (localStorage.getItem('medcseppEmergencyCsId')) {
      this.setSelectedEmergencyCsId(localStorage.getItem('medcseppEmergencyCsId'));
    }
  }

  setSelectedCsId(csId) {
    this.selectedCsIdShared.next(csId);
    localStorage.setItem('medcseppCsId', csId);
  }

  getSelectedCsId() {
    if (localStorage.getItem('medcseppCsId')) {
      return localStorage.getItem('medcseppCsId');
    } else {
      return null;
    }
  }

  setSelectedLaborService(item) {
    this.selectedLaborServiceIdShared.next(item);
    this.loadLabExamList(item.svId);
    this.loadLabPanelList(item.svId);
    console.log('labsv', item);
    localStorage.setItem('laborServiceProvider', JSON.stringify(item));
  }

  getSelectedLaborService() {
    if (localStorage.getItem('laborServiceProvider')) {
      return JSON.parse(localStorage.getItem('laborServiceProvider'));
      this.laborSvId = JSON.parse(localStorage.getItem('laborServiceProvider')).svId;
    } else {
      return null;
    }
  }

  setSelectedEmergencyCsId(csId) {
    this.selectedEmergencyCsIdShared.next(csId);
    localStorage.setItem('medcseppEmergencyCsId', csId);
  }

  getSelectedEmergencyCsId() {
    if (localStorage.getItem('medcseppEmergencyCsId')) {
      return localStorage.getItem('medcseppEmergencyCsId');
    } else {
      return null;
    }
  }

  setSelectedPrinterCsId(printerCsId: number) {
    localStorage.setItem('printerCsId', String(printerCsId));
  }

  getSelectedPrinterCsId() {
    if (localStorage.getItem('printerCsId')) {
      return localStorage.getItem('printerCsId');
    } else {
      return null;
    }
  }


  setSelectedEventType(eventType) {
    // this.selectedEventTypeShared.next(eventType);
    localStorage.setItem('medcseppSelectedEventType', JSON.stringify(eventType));
  }

  getSelectedEventType() {
    if (localStorage.getItem('medcseppSelectedEventType')) {
      return JSON.parse(localStorage.getItem('medcseppSelectedEventType'));
    } else {
      return null;
    }
  }


  loadAcDeviceList() {
    if (this.selectedRole) {
      this.getAcDeviceList().subscribe((responseData) => {
        if (responseData['code'] === 1) {
          this.acDeviceListShared.next(responseData['data']);
        } else {
          console.error('Error loading AcDeviceList');
        }
      });
    }
  }


  getAcDeviceList() {

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.selectedRole['roleCode'],
        currentServicePointId: this.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      isAdmin: true
    };

    return this.http.post(this.apiUrl + '/rest/neu/dlm/getacdevicelist', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }

  loadServiceDetail() {
    if (this.selectedRole) {
      this.getServiceDetail().subscribe((responseData) => {
        if (responseData['code'] === 1) {
          this.rootDetail = responseData['data']['rootDetail'];
          this.setConfig('rootDetail', responseData['data']['rootDetail']);
          // console.log('serviceDetail', responseData);
          this.loadEmploymentData('medicalPosition');
          this.loadEmploymentData('medicalOrganization');
        } else {
          console.error('Error loading serviceDetail');
        }
      });
    }
  }

  getServiceDetail() {

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.selectedRole['roleCode'],
        currentServicePointId: this.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      servicePointCode: this.selectedRole['spCode'],
      serviceCode: null
    };

    return this.http.post(this.apiUrl + '/rest/neu/authentication/getservicedetail', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }

  loadEmploymentData(type) {
    if (this.selectedRole) {
      this.getEmploymentData(type).subscribe((responseData) => {
        if (responseData['code'] === 1) {
          // console.log('employmentData', responseData);
          if (type === 'medicalPosition') {
            this.employmentDataPositionShared.next(responseData['data']);
          } else if (type === 'medicalOrganization') {
            this.employmentDataOrganizationShared.next(responseData['data']);
          }
        } else {
        }
      });
    }
  }

  getEmploymentData(type) {
    // console.log('rootDetail empdata', this.getRootDetail());
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.selectedRole['roleCode'],
        currentServicePointId: this.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      spCode: this.getRootDetail()['rootCode'],
      nodeName: type
    };

    return this.http.post(this.apiUrl + '/rest/neu/dlm/getemploymentdata', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  loadTorElements() {
    this.getTorElements('TP_EESZT_PRO_P7_IMPLANT_KOD_TIP').subscribe(response => {
      if (response['code'] === 1) {
        this.eproTor['P7_IMPL'] = response['torElements'];
        this.TP_EESZT_PRO_P7_IMPL_KOD_TIP_shared.next(response['torElements']);
      }
    });
    this.getTorElements('TP_EESZT_PRO_P8_TERAPIA_TIPU').subscribe(response => {
      if (response['code'] === 1) {
        this.eproTor['P8_TERAPIA'] = response['torElements'];
        this.TP_EESZT_PRO_P8_TERAPIA_TIPU_shared.next(response['torElements']);
      }
    });
    this.getTorElements('TP_EESZT_PRO_P6_PROBLEMA_KOD').subscribe(response => {
      if (response['code'] === 1) {
        this.eproTor['P6_PROBLEMA'] = response['torElements'];
        this.TP_EESZT_PRO_P6_PROBLEMA_KOD_shared.next(response['torElements']);
      }
    });
    this.getTorElements('TP_EESZT_PRO_P7_IMPLANT_KOD').subscribe(response => {
      if (response['code'] === 1) {
        this.eproTor['P6_PROBLEMA'] = response['torElements'];
        this.TP_EESZT_PRO_P7_IMPL_KOD_shared.next(response['torElements']);
      }
    });
    this.getTorElements('TP_EESZT_PRO_P9_AUTONOMIA_KO').subscribe(response => {
      if (response['code'] === 1) {
        this.TP_EESZT_PRO_P9_AUTONOMIA_KO_shared.next(response['torElements']);
      }
    });
    this.getTorElements('TP_EESZT_PRO_P13_VERCSOPORT').subscribe(response => {
      if (response['code'] === 1) {
        this.TP_EESZT_PRO_P13_VERCSOPORT_shared.next(response['torElements']);
      }
    });
    this.getTorElements('TP_EESZT_PRO_P2_LEIRAS_KOD').subscribe(response => {
      if (response['code'] === 1) {
        this.eproTor['P2_MEGNEVEZES'] = response['torElements'];
        this.TP_EESZT_PRO_P2_LEIRAS_KOD_shared.next(response['torElements']);
      }
    });
    this.getTorElements('TP_EESZT_PRO_P3_OLTAS_KOD').subscribe(response => {
      if (response['code'] === 1) {
        this.TP_EESZT_PRO_P3_OLTAS_KOD_shared.next(response['torElements']);
      }
    });
    this.getTorElements('TP_EESZT_PRO_P4_PROBLEMA_KOD').subscribe(response => {
      if (response['code'] === 1) {
        this.TP_EESZT_PRO_P4_PROBLEMA_KOD_shared.next(response['torElements']);
      }
    });
    this.getTorElements('TP_EESZT_PRO_P5_BEAV_KOD_TIP').subscribe(response => {
      if (response['code'] === 1) {
        this.eproTor['P5_BEAV_KOD_TIP'] = response['torElements'];
        this.TP_EESZT_PRO_P5_BEAV_KOD_TIP_shared.next(response['torElements']);
      }
    });
    this.getTorElements('TP_EESZT_PRO_P11_ELETMOD_KOD').subscribe(response => {
      if (response['code'] === 1) {
        this.TP_EESZT_PRO_P11_ELETMOD_KOD_shared.next(response['torElements']);
      }
    });
    this.getTorElements('TP_EESZT_PRO_P12_TERH_KIMENE').subscribe(response => {
      if (response['code'] === 1) {
        this.TP_EESZT_PRO_P12_TERH_KIMENE_shared.next(response['torElements']);
      }
    });
    this.getTorElements('TP_EESZT_PRO_P1_AGENS_TIP').subscribe(response => {
      if (response['code'] === 1) {
        this.eproTor['P1_AGENS_TIP'] = response['torElements'];
        this.TP_EESZT_PRO_P1_AGENS_TIP_shared.next(response['torElements']);
      }
    });
    this.getTorElements('TP_EESZT_PRO_P1_REAKCIO_KOD').subscribe(response => {
      if (response['code'] === 1) {
        this.eproTor['P1_REAKCIO_KOD'] = response['torElements'];
        this.TP_EESZT_PRO_P1_REAKCIO_KOD_shared.next(response['torElements']);
      }
    });
    this.getTorElements('TP_EESZT_PRO_P10_DOZISFORMA_').subscribe(response => {
      if (response['code'] === 1) {
        this.eproTor['P10_DOZISFORMA'] = response['torElements'];
        this.TP_EESZT_PRO_P10_DOZISFORMA__shared.next(response['torElements']);
      }
    });
    this.getTorElements('TP_EESZT_PRO_P10_HATOANYAG_K').subscribe(response => {
      if (response['code'] === 1) {
        this.eproTor['P10_HATOANYAG_KOD_TIP'] = response['torElements'];
        this.TP_EESZT_PRO_P10_HATOANYAG_K_shared.next(response['torElements']);
      }
    });
    this.getTorElements('TP_EESZT_PRO_P1_AGENS_KOD_TI').subscribe(response => {
      if (response['code'] === 1) {
        this.eproTor['P1_AGENS_KOD_TIP'] = response['torElements'];
        this.TP_EESZT_PRO_P1_AGENS_KOD_TIP_shared.next(response['torElements']);
      }
    });
  }

  getTorElements(typeName) {
    return this.http.get(this.apiUrl + '/rest/ecm/epro/gettorelements/' + typeName);
  }

}
