import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { SharedDataService } from '../services/shared-data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-dolg-ek-main',
  templateUrl: './dolg-ek-main.component.html',
  styleUrls: ['./dolg-ek-main.component.css']
})
export class DolgEkMainComponent implements OnInit, OnDestroy {

  constructor(public authService: AuthService,
    private http: HttpClient,
    private sharedDataService: SharedDataService
  ) { }

  apiUrl = environment.apiUrl;

  ngOnInit() {
    if (this.authService.getBrand() === 'DOLGEK') {
    this.sharedDataService.loadAcDeviceList();
    }
  }

  ngOnDestroy() {

  }




}
