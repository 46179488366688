<!-- Modal -->
<div class="modal" id="paciensreg" role="dialog" aria-labelledby="paciensreg" aria-hidden="true" #paciensreg>
    <div class="modal-dialog modal-lg" role="document">
        <div *ngIf="patientDataLoading && activeTab == 0" id="loadingContainer" class="loadingContainer">
            <div class="loadingContent">
                Loading...
            </div>
        </div>

        <div [ngClass]="{'hidden': !closeConfirmationDialogueVisible}" id="quitConfirmation" class="registrationQuitConfirmation">
            <div class="quitConfirmationContent">
                <h6>Az adatok megtartása a későbbi folytatáshoz?</h6>
                <div class="col-md-12 mt-4">
                    <div class="row">
                        <div class="col-md-4">
                            <button type="button" class="btn btn-primary" (click)="hideCloseConfirmationDialogue()" data-dismiss="modal">Igen</button>
                        </div>
                        <div class="col-md-4">
                            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="resetRegistrationModalAndHideDialogue()">Nem</button>
                        </div>
                        <div class="col-md-4">
                            <button type="button" class="btn btn-secondary" (click)="hideCloseConfirmationDialogue()">Mégsem</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div [ngClass]="{'hidden': !saveInProgress}" class="registrationQuitConfirmation">
            <div class="quitConfirmationContent">
                Mentés folyamatban...
            </div>
        </div>

        <div class="modal-content">
            <div class="modal-header">

                <div id="saveSuccess" [ngClass]="{'showResult': patientSaveResult == 0}">
                    {{ successMessage }}
                </div>
                <div id="saveError" [ngClass]="{'showResult': dataMissing}">
                    <div class="saveErrorContent">
                        <span>{{ savePatientErrorDescription }}</span>
                    </div>
                </div>
                <div id="saveError" [ngClass]="{'showResult': patientSaveResult == -1}">
                    <div class="closeResult" (click)="closeResult()">x</div>
                    <div class="saveErrorContent">Hiba a regisztráció során:
                        <span>{{ savePatientErrorDescription }}</span>
                    </div>
                </div>
                <h5 *ngIf="Brand !=='DOLGEK'" lass="modal-title" id="exampleModalLongTitle">Páciens regisztráció</h5>
                <h5 *ngIf="Brand =='DOLGEK'" class="modal-title" id="exampleModalLongTitle">Dolgozói regisztráció</h5>

                <div class="col-md-6" *ngIf="!registrationInProgress  && Brand !=='DOLGEK'">
                    Saját páciens keresése
                    <div class="row form-group">
                        <form [formGroup]="patientListForm" class="form-dropdown-select">
                            <ng-select [searchable]="true" formControlName="patientName" placeholder="Válasszon pácienst" [items]="patientList" bindLabel="displayData" bindValue="tapass" (change)="onSelectedPatientChange($event)" [loading]="patientListLoading" loadingText="Betöltés..."
                                notFoundText="Nincs találat">
                            </ng-select>
                        </form>
                    </div>
                </div>
                <div class="col-md-6" *ngIf="!registrationInProgress && Brand =='DOLGEK'">
                    Dolgozó keresése
                    <div class="row form-group">
                        <form [formGroup]="patientListForm" class="form-dropdown-select">
                            <ng-select [searchable]="true" formControlName="patientName" placeholder="Válasszon dolgozót" [items]="patientList" bindLabel="displayData" bindValue="tapass" (change)="onSelectedPatientChange($event)" [loading]="patientListLoading" loadingText="Betöltés..."
                                notFoundText="Nincs találat">
                            </ng-select>
                        </form>
                    </div>
                </div>


                <div>
                    <button type="button" class="btn btn-primary ml-1 mb-1" (click)="openHelp('#patient')" title="kézikönyv">
            <i class="fa fa-question-circle"></i>
          </button>
                    <button *ngIf="patientDetails.dirty" type="button" class="btn btn-secondary ml-1 mb-1" (click)="closeRegistrationModal()">X</button>
                    <button *ngIf="!patientDetails.dirty" type="button" class="btn btn-secondary ml-1 mb-1" (click)="resetRegistrationModal()" data-dismiss="modal">X</button>
                </div>
            </div>
            <!-- <nav mat-tab-nav-bar>
        <a mat-tab-link class="nav-link" [active]="activeTab === 0" (click)="switchTab(0)">
          <span *ngIf="Brand !=='DOLGEK'">Páciens adatai</span>
          <span *ngIf="Brand =='DOLGEK'">Dolgozó adatai</span>
        </a>
        <a mat-tab-link class="nav-link" [active]="activeTab === 1" (click)="switchTab(1)">
          <span *ngIf="Brand !=='DOLGEK'">Páciens dokumentumai</span>
          <span *ngIf="Brand =='DOLGEK'">Dolgozó dokumentumai</span>
        </a>
        <a mat-tab-link class="nav-link" [active]="activeTab === 2" (click)="switchTab(2)">Páciens korábbi megjelenései</a>
        <a mat-tab-link class="nav-link" [active]="activeTab === 3" (click)="switchTab(3)">
          <span *ngIf="Brand !=='DOLGEK'">Páciens egészségügyi dokumentumai</span>
          <span *ngIf="Brand =='DOLGEK'">Dolgozói kártya</span>
        </a>
        <a mat-tab-link class="nav-link" [active]="activeTab === 4" (click)="switchTab(4)">Páciens eseménykatalógusok</a>
      </nav> -->
            <!-- <div>
        <ul class="nav nav-tabs" *ngIf="Brand !=='DOLGEK'">
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab == 0}" (click)="switchTab(0)">Páciens adatai</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab == 1}" (click)="switchTab(1)">Páciens dokumentumai</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab == 2}" (click)="switchTab(2)">Páciens korábbi
              megjelenései</a>
          </li>
          <li>
            <a class="nav-link" [ngClass]="{'active': activeTab == 3}" (click)="switchTab(3)">Páciens egészségügyi dokumentumai</a>
          </li>
          <li>
            <a class="nav-link" [ngClass]="{'active': activeTab == 4}" (click)="switchTab(4)">Páciens eseménykatalógusok</a>
          </li>
        </ul>
        <ul class="nav nav-tabs" *ngIf="Brand =='DOLGEK'">
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab == 0}" (click)="switchTab(0)">Dolgozó adatai</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab == 1}" (click)="switchTab(1)">Dolgozó dokumentumai</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab == 3}" (click)="switchTab(3)">Dolgozói kártya</a>
          </li>
        </ul>

      </div> -->
            <div class="modal-body">
                <div class="container-fluid">
                    <mat-tab-group #patientMainTabGroup [(selectedIndex)]="activeTab">
                        <mat-tab [label]="Brand !== 'DOLGEK' ? 'Páciens adatai' : 'Dolgozó adatai'" (click)="switchTab(0)">
                            <ng-template matTabContent>
                                <div *ngIf="!registrationInProgress" class="col-md-12 mt-3">

                                    <form [formGroup]="patientDetails">
                                        <div class="row">
                                            <div class="col-md-6">

                                                <div class="row form-group">



                                                    <label for="dateofbirth" class="col-sm-4 control-label">Születési idő<span class="mandatory">*</span></label>
                                                    <div class="col-sm-8 form-item">

                                                        <div class="input-group mr-1">
                                                            <input class="form-control datepicker_mobile" placeholder="yyyy-mm-dd" name="dp" formControlName="dateofbirth" ngbDatepicker #d="ngbDatepicker" [maxDate]=todayDate [minDate]="{year: 1900, month: 1, day: 1}" [ngClass]="{'date-invalid': dateInvalid === true}">
                                                            <div class="input-group-append">
                                                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                                            </div>
                                                        </div>
                                                        <!--  <input type="date" id="dateofbirth" placeholder="Születési idő" class="form-control"
                            [formControl]="dateofbirth" max="{{ birthDateMax }}" (change)="changeIn4T()"> -->

                                                        <div class="formError" *ngIf="dateofbirth.invalid && formSubmitted">Nem megfelelő születési idő
                                                        </div>
                                                    </div>


                                                    <label for="ssn2t" class="col-sm-4 control-label">TAJ szám</label>
                                                    <div class="col-sm-8 form-item">
                                                        <input type="text" id="ssn2t" placeholder="TAJ szám" class="form-control" [formControl]="ssn2t">
                                                        <div class="formError" *ngIf="ssn2t.invalid && formSubmitted">Nem megfelelő név (minimum 4 karakter)
                                                        </div>
                                                    </div>
                                                    <span *ngIf="showLegalGuardianButton || userData?.legalGuardian" class="col-12 fake-link mb-2" (click)="openDialog()">Törvényes képviselő adatainak megadása</span>
                                                    <div class="col-12 mb-2">
                                                        <button type="button" class="btn btn-primary" (click)="check2T($event)" [disabled]="!dateofbirth || ssn2t.value === '' || is4tEditing">
                              Globális keresés 2T</button>
                                                    </div>

                                                    <label for="birthname" class="col-sm-4 control-label">Születési név<span
                              class="mandatory">*</span></label>
                                                    <div class="col-sm-8 form-item">
                                                        <input type="text" id="birthname" placeholder="Születési név" class="form-control" appTitleCase [formControl]="birthname" (change)="changeIn4T()">
                                                    </div>


                                                    <label for="birthplace" class="col-sm-4 control-label">Születési hely<span
                              class="mandatory">*</span></label>
                                                    <div class="col-sm-8 form-item">
                                                        <input type="text" id="birthplace" placeholder="Születési hely" class="form-control" [formControl]="birthplace" (change)="changeIn4T()" appTitleCase>
                                                        <div class="formError" *ngIf="birthplace.invalid && formSubmitted">Nem megfelelő születési hely (minimum 2 karakter)</div>
                                                    </div>


                                                    <label for="mothersname" class="col-sm-4 control-label">Anyja neve<span
                              class="mandatory">*</span></label>
                                                    <div class="col-sm-8 form-item">
                                                        <input type="text" id="mothersname" placeholder="Anyja neve" class="form-control" [formControl]="mothersname" (change)="changeIn4T()" appTitleCase>
                                                        <div class="formError" *ngIf="mothersname.invalid && formSubmitted">Nem megfelelő név (minimum 4 karakter)
                                                        </div>
                                                    </div>

                                                    <div class="col-12 mb-2">
                                                        <button type="button" class="btn btn-primary" (click)="check4T($event)" [disabled]="!birthname.valid || !birthplace.valid || !dateofbirth || !mothersname.valid || is4tEditing">
                              Globális keresés 4T</button>
                                <button  type="button" [disabled]="!is4tEditing"  class="ml-2 btn btn-danger" (click)="openCheckAuszDialog()">
                                  Módosítás
                                </button>
                                                    </div>
                                                    <div class="col-sm-12 mb-2"><strong>{{ check4TResultMessage }}</strong></div>


                                                    <label for="aliasname" class="col-sm-4 control-label">Név<span class="mandatory">*</span></label>
                                                    <div class="col-sm-8 form-item">
                                                        <input type="text" id="aliasname" placeholder="Név" class="form-control" [formControl]="aliasname">
                                                        <div class="formError" *ngIf="aliasname.invalid && formSubmitted">Nem megfelelő név (minimum 4 karakter)</div>
                                                    </div>

                                                    <label for="country" class="col-sm-4 control-label">EÜ azonosító típus<span class="mandatory">*</span></label>
                                                    <div class="col-sm-8 form-item">
                                                        <select class="form-control" id="sst" [formControl]="sst">
                              <option value="1">TAJ szám</option>
                              <option value="2">Gyermek TAJ szám</option>
                              <option value="3">Útlevélszám</option>
                              <option value="5">Befogadó igazolvány</option>
                              <option value="8">EU e-kártya</option>
                              <option value="9">Menekült azonosítója</option>
                              <option value="X">Egyéb okirat</option>
                            </select>
                                                        <div class="formError" *ngIf="sst.invalid && formSubmitted">Válasszon okmány típust!</div>
                                                    </div>

                                                    <label for="ssn" class="col-sm-4 control-label" *ngIf="sst.value != '0'">EÜ azonosítószám<span class="mandatory">*</span>
                                                    <i class="fa fa-check-circle" *ngIf="sst.value == '1'" [ngClass]="{'inactive': !ssn.dirty, 'green': ssn.valid, 'red': ssn.valid === false}">
                            </i>

                                                    </label>
                                                    <div class="col-sm-8 form-item" *ngIf="sst.value != '0'">
                                                        <input type="text" placeholder="" class="form-control" [formControl]="ssn">
                                                        <div class="formError" *ngIf="ssn.invalid && formSubmitted">Nem megfelelő TAJ szám (minimum 4 karakter)
                                                        </div>
                                                    </div>

                                                    <label for="sstd" class="col-sm-4 control-label" *ngIf="sst.value=='X'">Okmány típusának
                            leírása</label>
                                                    <div class="col-sm-8 form-item" *ngIf="sst.value=='X'">
                                                        <input type="text" id="sstd" placeholder="Okmány típusának leírása" class="form-control" [formControl]="sstd">
                                                        <div class="formError" *ngIf="sst.value == 'X' && sstd.invalid && formSubmitted">Nem megfelelő leírás (minimum 3 karakter)
                                                        </div>
                                                    </div>



                                                    <label for="gender" class="col-sm-4 control-label">Nem</label>
                                                    <div class="col-sm-8 form-item">
                                                        <input type="radio" value="M" class="form-control radio-item" name="gender" [formControl]="gender">
                                                        <div class="form-control radio-item">Férfi</div>
                                                        <input type="radio" value="F" class="form-control  radio-item" name="gender" [formControl]="gender">
                                                        <div class="form-control radio-item">Nő</div>
                                                        <div class="formError" *ngIf="gender.invalid && formSubmitted">Válasszon nemet!</div>
                                                    </div>

                                                    <label for="nationality" class="col-sm-4 control-label">Állampolgárság</label>
                                                    <div class="col-sm-8 form-item">
                                                        <select class="form-control" id="nationality" [formControl]="nationality">
                                                            <option *ngFor="let nat of nationalities" [ngValue]="nat.code">{{nat.name}}</option>
                                                        </select>
                                                        <div class="formError" *ngIf="nationality.invalid && formSubmitted">Nem megfelelő orszag (minimum 3 karakter)
                                                        </div>
                                                    </div>

                                                    <label for="email" class="col-sm-4 control-label">Email<span *ngIf="authService.getBrand() === 'NauLight' " class="mandatory">*</span></label>
                                                    <div class="col-sm-8 form-item">
                                                        <input type="text" id="email" placeholder="Email" class="form-control" [formControl]="email">
                                                        <div class="formError" *ngIf="email.invalid && formSubmitted">Nem megfelelő email cím</div>
                                                    </div>


                                                    <label for="phone" class="col-sm-4 control-label">Telefonszám<span
                              class="mandatory">*</span></label>
                                                    <div class="col-sm-8 form-item">
                                                        <input type="tel" id="phone" placeholder="Telefonszám" class="form-control" [formControl]="phone" placeholder="+36 20 123 4567">
                                                        <div class="formError" *ngIf="phone.invalid && formSubmitted">Nem megfelelő telefonszám (minimum 8 karakter)</div>
                                                    </div>


                                                    <div class="col-md-12">
                                                        <h3>Lakcím</h3>
                                                    </div>

                                                    <label for="country" class="col-sm-4 control-label">Ország</label>
                                                    <div class="col-sm-8 form-item">
                                                        <select class="form-control" id="country" [formControl]="country">
                              <option *ngFor="let country of countries" [ngValue]="country.code">{{country.name}}</option>
                            </select>
                                                        <div class="formError" *ngIf="country.invalid && formSubmitted">Nem megfelelő orszag (minimum 3 karakter)
                                                        </div>
                                                    </div>

                                                    <label for="zip" class="col-sm-4 control-label">Irányítószám</label>
                                                    <div class="col-sm-8 form-item">
                                                        <input type="text" id="zip" placeholder="Irányítószám" class="form-control" [formControl]="zip">
                                                        <div class="formError" *ngIf="zip.invalid && formSubmitted">Nem megfelelő irányítószám (minimum 3 karakter)
                                                        </div>
                                                    </div>

                                                    <label for="city" class="col-sm-4 control-label">Város</label>
                                                    <div class="col-sm-8 form-item" *ngIf="citiesByZip.length < 1">
                                                        <input type="text" id="city" placeholder="Város" class="form-control" [formControl]="city" appTitleCase>
                                                        <div class="formError" *ngIf="city.invalid && formSubmitted">Nem megfelelő város (minimum 2 karakter)
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-8" *ngIf="citiesByZip.length > 0" style="margin-bottom: 8px">
                                                        <ng-select [items]="citiesByZip" [formControl]="city" appTitleCase placeholder="Város"></ng-select>
                                                        <div class="formError" *ngIf="city.invalid && formSubmitted">Nem megfelelő város (minimum 2 karakter)
                                                        </div>
                                                    </div>

                                                    <label for="address" class="col-sm-4 control-label">Utca, házszám</label>
                                                    <div class="col-sm-8 form-item">
                                                        <input type="text" id="address" placeholder="Utca, házszám" class="form-control" appUpperCaseFirst [formControl]="address">
                                                        <div class="formError" *ngIf="address.invalid && formSubmitted">Nem megfelelő utca, házszám (minimum 4 karakter)</div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-md-6">
                                                <h3>Számlázási adatok (opcionális)</h3>
                                                <div class="row form-group">

                                                    <label for="billingname" class="col-sm-4 control-label">Név</label>
                                                    <div class="col-sm-8 form-item">
                                                        <input type="text" id="billingname" placeholder="Név" class="form-control" appTitleCase [formControl]="billingname">
                                                    </div>

                                                    <label for="billingtaxid" class="col-sm-4 control-label">Adószám</label>
                                                    <div class="col-sm-8 form-item">
                                                        <input type="text" id="billingtaxid" placeholder="Adószám" class="form-control" [formControl]="billingtaxid">
                                                    </div>

                                                    <label for="billingzip" class="col-sm-4 control-label">Irányítószám</label>
                                                    <div class="col-sm-8 form-item">
                                                        <input type="text" id="billingzip" placeholder="Irányítószám" class="form-control" [formControl]="billingzip">
                                                    </div>

                                                    <label for="billingcity" class="col-sm-4 control-label">Város</label>
                                                    <div class="col-sm-8 form-item">
                                                        <input type="text" id="billingcity" placeholder="Város" class="form-control" appUpperCaseFirst [formControl]="billingcity">
                                                    </div>

                                                    <label for="billingaddress" class="col-sm-4 control-label">Utca, házszám</label>
                                                    <div class="col-sm-8 form-item">
                                                        <input type="text" id="billingaddress" placeholder="Utca, házszám" class="form-control" appUpperCaseFirst [formControl]="billingaddress">
                                                    </div>
                                                    <button class="btn btn-primary" [disabled]="!copyDetailToBillingEnabled" (click)="copyDetailToBilling()">Adatok másolása</button>
                                                </div>

                                                <h3>Egyéb adatok</h3>
                                                <div class="row form-group">
                                                    <label for="comment" class="col-sm-4 control-label">Megjegyzés</label>
                                                    <div class="col-sm-8 form-item">
                                                        <textarea id="comment" class="form-control" [formControl]="comment"></textarea>
                                                    </div>
                                                    <label for="allergy" class="col-sm-4 control-label">Gyógyszerallergia</label>
                                                    <div class="col-sm-8 form-item">
                                                        <textarea id="allergy" class="form-control" [formControl]="allergy"></textarea>
                                                    </div>
                                                    <div *ngIf="authService.getBrand() !== 'UDMED' || authService.getBrand() !== 'DOLGEK'">
                                                        <label for="ekWorkflow" class="col-sm-4 control-label">Egészségkártya páciens</label>
                                                        <div class="col-sm-8 form-item">
                                                            <input type="checkbox" id="ekWorkflow" class="form-control" [formControl]="ekWorkflow">
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="row">
                                                    <button *ngIf="!justAssingPatient && Brand !=='DOLGEK' && !registerPatientAndBookAppointment" type="button" class="btn btn-danger mt-2" [disabled]="!patientDetails.valid || !check4TStatus" (click)="savePatient($event, 1)">
                            Páciens mentése
                          </button>
                                                    <button *ngIf="!justAssingPatient && Brand ==='DOLGEK'" type="button" class="btn btn-danger mt-2" [disabled]="!patientDetails.valid || !check4TStatus" (click)="savePatient($event, 1)">
                            Dolgozó mentése
                          </button>

                                                    <button *ngIf="justAssingPatient" type="button" class="btn btn-danger ml-3  mt-2" [disabled]="!patientDetails.valid || !check4TStatus" (click)="savePatient($event, 2)">
                            Páciens hozzárendelése
                          </button>
                                                    <button *ngIf="registerPatientAndBookAppointment" type="button" class="btn btn-danger ml-3  mt-2" [disabled]="!patientDetails.valid || !check4TStatus" (click)="savePatient($event, 3)">
                            Ment és hozzárendel
                          </button>
                                                    <button *ngIf="(userData !== null && userData.usrValid !== '1' && Brand !== 'DOLGEK') ||
                           (userData !== null && patientDetails.dirty)" type="button" class="btn btn-danger ml-3 mt-2" [disabled]="!patientDetails.valid || !check4TStatus" (click)="showValidationModal(1)">
                            Authentikálás
                          </button>
                                                    <!-- <button *ngIf="userData !== null && patientDetails.dirty" type="button"
                           class="btn btn-danger ml-3 mt-2"
                           [disabled]="!patientDetails.valid || savePatientInProgress || !check4TStatus"
                           (click)="showValidationModal(2)">
                           Mentés és Auth.
                         </button> -->
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div *ngIf="registrationInProgress" class="col-md-12 mt-3">

                                    <h3>Regisztráció véglegesítése</h3>

                                    <form [formGroup]="regValidation" class="col-md-12">
                                        <div class="row form-group">
                                            <label for="auszcode" class="col-sm-2 control-label">AUSZ kód</label>
                                            <div class="col-sm-4 form-item">
                                                <input type="text" id="auszcode" placeholder="AUSZ kód" class="form-control" [formControl]="auszcode">
                                            </div>
                                            <div class="col-sm-12">
                                                <input type="checkbox" id="agreement" class="form-control checkbox-item" [formControl]="agreement">
                                                <label for="auszcode" class="control-label">A páciens aláírta a regisztrációs lapot és
                          elfogadta annak feltételeit</label>
                                            </div>
                                            <div class="col-sm-12">
                                                <input type="checkbox" id="marketing" class="form-control checkbox-item" [formControl]="marketing">
                                                <label for="auszcode" class="control-label">A páciens hozzájárul adatainak marketing célú
                          felhasználásához</label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6"></div>
                                            <div class="col-sm-6">
                                                <button type="button" class="btn btn-primary" [disabled]="!regValidation.valid" (click)="validatePatient($event)" *ngIf="Brand !== 'DOLGEK'">
                          Páciens véglegesítése</button>
                                                <button type="button" class="btn btn-primary" [disabled]="!regValidation.valid" (click)="validatePatient($event)" *ngIf="Brand == 'DOLGEK'">
                          Dolgozó véglegesítése</button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab [label]="Brand !== 'DOLGEK' ? 'Páciens dokumentumai' : 'Dolgozó dokumentumai'" (click)="switchTab(1)">
                            <ng-template matTabContent>
                                <div class="col-md-12 mt-3" style="min-height: 300px;">

                                    <!-- <div *ngFor="let calendarEntry of calendarListDataBookings; let i = index" class="calendar_items">
                        <p>{{ calendarEntry['dateFrom'] | date:'H:mm' }} - {{ calendarEntry['dateTo'] | date:'H:mm'}}</p>
                        <p>{{ calendarEntry['participantName'] }}</p>
                        <p>{{ calendarEntry['calendarComment'] }}</p>
                      </div> -->

                                    <form [formGroup]="fileUpload" (ngSubmit)="onFileSubmit()">
                                        <div class="row">
                                            <div class="col-sm-12 mb-2">
                                                <h5>Dokumentum</h5>
                                            </div>
                                            <div class="col-sm-12">
                                                <div class="row form-group">
                                                    <label for="phone" class="col-sm-1 control-label">Típus</label>
                                                    <div class="col-sm-3 mb-2">
                                                        <ng-select class="full-width" [searchable]="true" placeholder="Dokumentum tipus" formControlName="fileDocType" [items]="uploadDocumentTypes" bindLabel="description" bindValue="code" [loading]="uploadDocumentTypesLoading" [clearable]="false" loadingText="Betöltés..."
                                                            notFoundText="Nincs találat">
                                                        </ng-select>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <label class="btn btn-primary">
                              Fájl kiválasztása <input type="file" (change)="onFileChange($event)" [formControl]="fileInput"
                                style="display: none;">
                            </label>
                                                    </div>
                                                    <!-- <input type="file" (change)="onFileChange($event)" [formControl]="fileInput" class="form-control"> -->
                                                    <!-- <button type="button" class="btn btn-sm btn-default" (click)="clearFile()">clear file</button> -->
                                                    <div class="col-sm-3 mb-2">
                                                        {{ fileTempData.filename }}
                                                    </div>
                                                    <div class="col-sm-2 mb-2">
                                                        <button type="submit" [disabled]="!fileTempData.valid || uploadInProgress || !fileUpload.valid" class="btn btn-success"><i class="fa fa-spinner fa-spin" *ngIf="uploadInProgress"></i>
                              Feltöltés</button>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </form>

                                    <div *ngIf="documentsLoading">
                                        <div class="osahanloading"></div>
                                        <div class="loading-center">Loading documents...</div>
                                    </div>

                                    <div *ngIf="!documentsLoading">

                                        <div class="row">
                                            <div class="col-md-12 mb-2">
                                                <input type="checkbox" id="showDocumentVersions" (change)='toggleShowDocumentVersions()' [checked]="showDocumentVersions"> Összes dokumentumverzió megjelenítése
                                            </div>
                                        </div>

                                        <div class="row" *ngIf="documents">
                                            <div class="col-md-3">Dátum</div>
                                            <div class="col-md-7">Típus</div>
                                            <div class="col-md-1">Verzió</div>
                                            <div class="col-md-1"></div>

                                        </div>

                                        <div *ngIf="documentsCount === 0" class="row">
                                            <div class="col-md-12 mt-4">
                                                <h4 class="text-center">Nincs megjeleníthető dokumentum.</h4>
                                            </div>
                                        </div>


                                        <div *ngFor="let documentEntry of documents; let i = index">
                                            <div class="row document-row" [ngClass]="{'hidden': documentEntry['docStatus'] == 'OBSOLETE' && showDocumentVersions !== true,
                        'obsolete': documentEntry['docStatus'] == 'OBSOLETE' && showDocumentVersions === true}">
                                                <div class="col-md-3">{{ documentEntry['docDate'] | date:'yyyy-MM-dd HH:mm' }}</div>
                                                <div class="col-md-7">{{ documentEntry['docType']}}</div>
                                                <div class="col-md-1">{{ documentEntry['docVersion']}}</div>
                                                <div class="col-md-1"><a (click)="openDocument(documentEntry)" class="downloadLink">Letöltés</a></div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="Páciens korábbi megjelenései" (click)="switchTab(2)">
                            <ng-template matTabContent>
                                <div class="col-md-12 mt-3" style="min-height: 300px;">

                                    <div *ngIf="calendarLoading && userData !== null">
                                        <div class="osahanloading"></div>
                                        <div class="loading-center">Páciens korábbi megjelenéseinek betöltése...</div>
                                    </div>

                                    <div *ngIf="!calendarLoading">

                                        <div class="col-md-12 text-center" *ngIf="calendarLength == 0 && error == ''">
                                            <h5>
                                                Önnek a megadott időintervallumban nincs előzegyzése.
                                            </h5>
                                        </div>
                                        <div class="col-md-12 text-center" *ngIf="calendarLength == 0 && error !== ''">
                                            <h5>
                                                {{ error }}
                                            </h5>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">Dátum</div>
                                            <div class="col-md-4">Orvos</div>
                                            <div class="col-md-4">Szolgáltatási pont</div>
                                        </div>
                                        <div *ngFor="let calendarEntry of calendarListDataAll.reverse(); let i = index">
                                            <div *ngIf="calendarEntry['calendarStatus'] != 'F'" class="calendar_items row height-{{ calendarEntry['slotLength'] }}" [ngClass]="{'appointment_arrived': calendarEntry['calendarStatus'] == 'A', 'appointment_discarded': calendarEntry['calendarStatus'] == 'D' }">
                                                <div class="col-md-4 col-time no-padding small-lineheight">
                                                    <small>{{ calendarEntry['dateFrom'] | date:'yyyy-MM-dd H:mm' }} -
                            {{calendarEntry['dateTo'] | date:'H:mm'}}</small></div>
                                                <div class="col-md-4 col-details">
                                                    <small>{{ calendarEntry['doctorName'] }}</small>
                                                </div>
                                                <div class="col-md-4 col-details">
                                                    <small>{{ calendarEntry['servicePointName'] }}</small>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab [label]="Brand !== 'DOLGEK' ? 'Páciens egészségügyi dokumentumai' : 'Dolgozói kártya'" (click)="switchTab(3)">
                            <ng-template matTabContent>
                                <div *ngIf="Brand =='DOLGEK'" class="col-md-12 mt-3" style="min-height: 300px;">

                                    <div class="card-left-panel" *ngIf="sharedDataService.selectedRole['roleCode'] == 'AUTH$VESZPREM_IAUSZ'">
                                        <div class="card-container">
                                            <div class="card-photo">
                                                <div *ngIf="imageDataBg !== {}" [ngStyle]="imageDataBg" class='camPicture'> </div>

                                                <!-- <img height="185" [src]="imageData | safe: 'resourceUrl'" /> -->
                                            </div>
                                            <div class="card-details-name">{{ detailsName }}</div>
                                            <div class="card-details-position">
                                                {{ detailsOrganization }}
                                                <br /> {{ detailsPosition }}
                                            </div>
                                            <div class="card-details-number">
                                                {{ tapass | eknumber}}
                                            </div>
                                        </div>
                                        <div class="text-right">
                                            <button (click)="openCard()" class="btn btn-primary mb-4">Nyomtat</button>
                                        </div>
                                    </div>

                                    <div class="card-left-panel" *ngIf="sharedDataService.selectedRole['roleCode'] == 'AUTH$SZOLNOKMAV_IAUSZ' ||
                  sharedDataService.selectedRole['roleCode'] == 'AUTH$SZOLNOKMAC_IAUSZ'">
                                        <div class="card-container card-szolnok-mav">
                                            <div class="card-photo">
                                                <div *ngIf="imageDataBg !== {}" [ngStyle]="imageDataBg" class='camPicture'> </div>

                                                <!-- <img height="185" [src]="imageData | safe: 'resourceUrl'" /> -->
                                            </div>
                                            <div class="card-details-name-mav">{{ detailsName }}</div>
                                            <div class="card-details-number-mav">
                                                {{ tapass | eknumber}}
                                            </div>
                                        </div>
                                        <div class="text-right">
                                            <button (click)="openCard()" class="btn btn-primary mb-4">Nyomtat</button>
                                        </div>
                                    </div>

                                    <div class="card-left-panel" *ngIf="sharedDataService.selectedRole['roleCode'] == 'AUTH$SZOLNOK_IAUSZ'">
                                        <div class="card-container card-szolnok-hetenyi">
                                            <!-- <div class="card-photo-hetenyi">
                        <div *ngIf="imageDataBg !== {}" [ngStyle]="imageDataBg" class='camPicture'> </div>

                        <img height="185" [src]="imageData | safe: 'resourceUrl'" />
                      </div> -->
                                            <div class="card-details-name-hetenyi">{{ detailsName }}</div>
                                            <div class="card-details-position-hetenyi">
                                                {{ detailsOrganization }}
                                                <br /> {{ detailsPosition }}
                                            </div>
                                            <div class="card-details-number-hetenyi">
                                                {{ tapass | eknumber}}
                                            </div>
                                        </div>
                                        <div class="text-right">
                                            <button (click)="openCard()" class="btn btn-primary mb-4">Nyomtat</button>
                                        </div>
                                    </div>

                                    <div class="card-left-panel" *ngIf="sharedDataService.selectedRole['roleCode'] == 'AUTH$MAGY_ADMIN'">
                                        <div class="card-container card-magy">
                                            <div class="card-photo">
                                                <!-- <div *ngIf="imageDataBg !== {}" [ngStyle]="imageDataBg" class='camPicture'> </div> -->
                                                <!-- <img height="185" [src]="imageData | safe: 'resourceUrl'" /> -->
                                            </div>
                                            <div class="card-details-name-magy">{{ detailsName }}</div>
                                            <div class="card-details-position-magy">
                                                {{ detailsOrganization }}
                                                <br /> {{ detailsPosition }}
                                            </div>
                                        </div>
                                        <div class="text-right">
                                            <button (click)="openCard()" class="btn btn-primary mb-4">Nyomtat</button>
                                        </div>
                                    </div>




                                    <div class="card-right-panel">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-4">
                                                    <label for="ssn2t" class="col-sm-4 control-label">NFC azonosító</label>
                                                </div>
                                                <div class="col-4 form-item">
                                                    <input type="text" id="NFC azonosító" placeholder="NFC azonosító" class="form-control" [formControl]="cardNfc" maxlength="8" minlength="8">
                                                    <div class="formError" *ngIf="cardNfc.invalid">Nem NFC azonosító (8 karakter)
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <button class="btn btn-primary" [disabled]="!cardNfc.valid || !tapass" (click)="saveCardDetails()">NFC mentése</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-2" *ngIf="sharedDataService.selectedRole['roleCode'] == 'AUTH$VESZPREM_IAUSZ'
                    || sharedDataService.selectedRole['roleCode'] == 'AUTH$SZOLNOK_IAUSZ' || sharedDataService.selectedRole['roleCode'] == 'AUTH$MAGY_ADMIN'">
                                            <ng-select class="mb-2" [searchable]="true" [formControl]="employmentOrganization" placeholder="Válasszon szervezeti egységet" [items]="employmentDataOrganization" bindLabel="x" bindValue="x" [loading]="employmentDataOrganizationLoading" loadingText="Betöltés..."
                                                notFoundText="Nincs találat">
                                            </ng-select>

                                            <ng-select class="mb-2" [searchable]="true" [formControl]="employmentPosition" placeholder="Válasszon munkakört" [items]="employmentDataPosition" bindLabel="x" bindValue="x" [loading]="employmentDataPositionLoading" loadingText="Betöltés..." notFoundText="Nincs találat">
                                            </ng-select>

                                            <button class="btn btn-primary" [disabled]="!tapass" (click)="saveEmploymentDetails()">Adatok
                        mentése</button>
                                        </div>

                                        <div class="col-12 mb-2" *ngIf="sharedDataService.selectedRole['roleCode'] == 'AUTH$VESZPREM_IAUSZ'
                    || sharedDataService.selectedRole['roleCode'] == 'AUTH$SZOLNOK_IAUSZ' || sharedDataService.selectedRole['roleCode'] == 'AUTH$MAGY_ADMIN'">
                                            <div class="row">
                                                <div class="col-6">
                                                    <webcam [height]="330" [width]="250" [trigger]="triggerObservable" (imageCapture)="handleImage($event)" [allowCameraSwitch]="false" [videoOptions]="videoOptions" [imageQuality]="1" (initError)="handleInitError($event)" *ngIf="!showWebcamImage"></webcam>
                                                    <div class="snapshot" *ngIf="webcamImage && showWebcamImage">
                                                        <img [src]="webcamImage.imageAsDataUrl" style="width: 100%; max-width: 250px;" />
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="row">
                                                        <button class="btn btn-danger" (click)="triggerSnapshot();" *ngIf="!showWebcamImage">Kép
                              készítése</button>
                                                    </div>
                                                    <div class="row">
                                                        <button class="btn btn-success mb-2" (click)="onSaveRegPic();" *ngIf="showWebcamImage" [disabled]="!regPicType || !tapass">Kép mentése</button>
                                                    </div>
                                                    <div class="row">
                                                        <button class="btn btn-secondary" (click)="backToCamera();" *ngIf="showWebcamImage">Új
                              kép</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row" *ngIf="errors.length > 0">
                                            <div class="col-12">
                                                <h4>Messages:</h4>
                                                <ul *ngFor="let error of errors">
                                                    <li>{{error | json}}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div *ngIf="!registrationInProgress && Brand !=='DOLGEK'" class="col-md-12 mt-3" [formGroup]="patientEhrFormGroup">
                                    <div class="row">
                                        <div class="col-sm">
                                            <label for="patientDateFrom">tól</label>
                                            <div class="input-group mr-1">
                                                <input class="form-control datepicker_mobile" placeholder="yyyy-mm-dd" name="dp" formControlName="patientDateFrom" ngbDatepicker #dFrom="ngbDatepicker" [maxDate]=todayDate [minDate]="{year: 1900, month: 1, day: 1}" [ngClass]="{'date-invalid': dateInvalid === true}">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar" (click)="dFrom.toggle()" type="button"></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm">
                                            <label for="patientDateto">ig</label>
                                            <div class="input-group mr-1">
                                                <input class="form-control datepicker_mobile" placeholder="yyyy-mm-dd" name="dp" formControlName="patientDateTo" ngbDatepicker #dTo="ngbDatepicker" [maxDate]=todayDate [minDate]="{year: 1900, month: 1, day: 1}" [ngClass]="{'date-invalid': dateInvalid === true}">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar" (click)="dTo.toggle()" type="button"></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="row">
                    <div class="col-sm-6 form-group">
                        <input type="checkBox" id="breakGlass" formControlName="breakGlass">
                        <label for="breakGlass" class="ml-1">sürgősségi adatlekérés</label>
                        <input type="text" class="form-control" formControlName="breakGlassReason">
                    </div>
                  </div> -->

                                    <div class="row">
                                        <div class="col-sm-6 form-group">
                                            <input type="checkBox" id="breakGlass" formControlName="breakGlass">
                                            <label for="breakGlass" class="ml-1">sürgősségi adatlekérés</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6 form-group">
                                            <label for="breakGlassReason" class="ml-1">sürgősségi adatlekérés indoklása</label>
                                            <input type="text" id="breakGlassReason" class="form-control" formControlName="breakGlassReason">
                                        </div>
                                        <div class="col-sm-6 form-group">
                                            <div *ngIf="this.patientEhrFormGroup.controls.breakGlass.value" class="alert alert-danger" role="alert" style="text-align: justify; text-justify: inter-word;">
                                                A "sürgősségi adatlekérés" csak a következő esetekben használható jogszerűen:<br> 1.) Sürgős esetben, ha a páciens nem tud, nem képes eseti rendelkezést tenni. (Az Eüak. 10. § (4) bekezdése szerint sürgős
                                                szükség esetén a kezelést végző orvos által ismert, a gyógykezeléssel összefüggésbe hozható minden egészségügyi és személyazonosító adat továbbítható.) <br> 2.) A páciens 24 órás egyedi engedélye alapján
                                                (minta az e-egeszegugy.gov.hu oldalon letölthető).
                                            </div>
                                        </div>
                                    </div>

                                    <button class="btn btn-secondary" (click)="getPatientEhrDocumentList()" [disabled]="!patientEhrFormGroup.valid || !patientListForm.controls.patientName.value">
                    <i *ngIf="ehrDetailsInProgress" class="fa fa-spinner fa-spin"></i>Keresés</button>
                                    <table *ngIf="patientEhrList && !showEhrDocument && !ehrDocumentLoading" mat-table class="table-striped" [dataSource]="patientEhrList">
                                        <ng-container matColumnDef="doctorName">
                                            <th mat-header-cell *matHeaderCellDef class="tablePadding"> létrehozó orvos </th>
                                            <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.doctor.firstName + ' ' + element.doctor.lastName}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="stampNo">
                                            <th mat-header-cell *matHeaderCellDef class="tablePadding"> pecsétszám </th>
                                            <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.doctor.stampNo}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="timeStamp">
                                            <th mat-header-cell *matHeaderCellDef class="tablePadding"> küldés ideje </th>
                                            <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.timeStamp | date: 'yyyy/MM/dd'}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="hospitalDescr">
                                            <th mat-header-cell *matHeaderCellDef class="tablePadding"> beküldő intézmény </th>
                                            <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.hospitalDescr}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="diagnose">
                                            <th mat-header-cell *matHeaderCellDef class="tablePadding"> diagnózis </th>
                                            <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.diagnose}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="documentID">
                                            <th mat-header-cell *matHeaderCellDef class="tablePadding"> dokumentum azonosítója </th>
                                            <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.documentID}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="details">
                                            <th mat-header-cell *matHeaderCellDef class="tablePadding"> adatok </th>
                                            <td mat-cell *matCellDef="let element" class="tablePadding">
                                                <fa-icon (click)="showEhrDetails(element)" class="showEkatInfoIcon" style="cursor: pointer;" [icon]="faInfoCircle"></fa-icon>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumnsEhr"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumnsEhr;"></tr>
                                    </table>
                                    <div *ngIf="ehrDocumentLoading">
                                        <div class="osahanloading"></div>
                                    </div>

                                    <div class="container-fluid mt-2" *ngIf="showEhrDocument">
                                        <hr>
                                        <div class="col-sm-1">
                                            <button class="btn btn-danger" (click)="showEhrDocument = false; this.patientEhrFormGroup.enable();">x</button>
                                        </div>
                                        <table mat-table [dataSource]="ehrDocumentDataSource" class="w-100">
                                            <ng-container matColumnDef="ekadId">
                                                <th mat-header-cell *matHeaderCellDef class="tablePadding"> eseménykatalógus azonosító </th>
                                                <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.document.data.ekatid}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="instId">
                                                <th mat-header-cell *matHeaderCellDef class="tablePadding"> intézmény kódja </th>
                                                <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.document.data.hospitalCode}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="institute">
                                                <th mat-header-cell *matHeaderCellDef class="tablePadding"> intézmény </th>
                                                <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.document.data.hospitalDescr}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="caseId">
                                                <th mat-header-cell *matHeaderCellDef class="tablePadding"> eset azonosító </th>
                                                <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.document.data.caseId}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="location">
                                                <th mat-header-cell *matHeaderCellDef class="tablePadding"> helyszín </th>
                                                <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.document.data.deptDescr}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="ssn">
                                                <th mat-header-cell *matHeaderCellDef class="tablePadding"> taj </th>
                                                <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.document.data.taj}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="diag">
                                                <th mat-header-cell *matHeaderCellDef class="tablePadding"> diagnózis </th>
                                                <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.document.data.diagnose}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="deleteEkat">
                                                <th mat-header-cell *matHeaderCellDef class="tablePadding"> dokumentum törlése </th>
                                                <td mat-cell *matCellDef="let element" class="tablePadding">
                                                    <fa-icon (click)="deleteEhrDocument(ehrDocument)" class="deleteEhrIcon" [icon]="faTrash"></fa-icon>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="pdf">
                                                <th mat-header-cell *matHeaderCellDef class="tablePadding"> pdf letöltés </th>
                                                <td mat-cell *matCellDef="let element" class="tablePadding">
                                                    <fa-icon (click)="downloadEhrPdf()" class="downloadPdf" [icon]="faFilePdf"></fa-icon>
                                                </td>
                                            </ng-container>
                                            <tr mat-header-row *matHeaderRowDef="displayedEkatDetails"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedEkatDetails;"></tr>
                                        </table>
                                    </div>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="Páciens eseménykatalógusok" (click)="switchTab(4)">
                            <ng-template matTabContent>
                                <div *ngIf="!registrationInProgress && Brand !=='DOLGEK'" class="col-md-12 mt-3" [formGroup]="patientEkatFormGroup">
                                    <div class="row">
                                        <div class="col-sm">
                                            <label for="patientDateFrom">tól</label>
                                            <div class="input-group mr-1">
                                                <input class="form-control datepicker_mobile" placeholder="yyyy-mm-dd" name="dp" formControlName="patientDateFrom" ngbDatepicker #dFrom="ngbDatepicker" [maxDate]=todayDate [minDate]="{year: 1900, month: 1, day: 1}" [ngClass]="{'date-invalid': dateInvalid === true}">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar" (click)="dFrom.toggle()" type="button"></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm">
                                            <label for="patientDateto">ig</label>
                                            <div class="input-group mr-1">
                                                <input class="form-control datepicker_mobile" placeholder="yyyy-mm-dd" name="dp" formControlName="patientDateTo" ngbDatepicker #dTo="ngbDatepicker" [maxDate]=todayDate [minDate]="{year: 1900, month: 1, day: 1}" [ngClass]="{'date-invalid': dateInvalid === true}">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar" (click)="dTo.toggle()" type="button"></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="row">
                    <div class="col-sm-6 form-group">
                        <input type="checkBox" id="breakGlass" formControlName="breakGlass">
                        <label for="breakGlass" class="ml-1">sürgősségi adatlekérés</label>
                        <input type="text" class="form-control" formControlName="breakGlassReason">
                    </div>
                  </div> -->

                                    <div class="row">
                                        <div class="col-sm-6 form-group">
                                            <input type="checkBox" id="breakGlass" formControlName="breakGlass">
                                            <label for="breakGlass" class="ml-1">sürgősségi adatlekérés</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6 form-group">
                                            <label for="breakGlassReason" class="ml-1">sürgősségi adatlekérés indoklása</label>
                                            <input type="text" id="breakGlassReason" class="form-control" formControlName="breakGlassReason">
                                        </div>
                                        <div class="col-sm-6 form-group">
                                            <div *ngIf="this.patientEkatFormGroup.controls.breakGlass.value" class="alert alert-danger" role="alert" style="text-align: justify; text-justify: inter-word;">
                                                A "sürgősségi adatlekérés" csak a következő esetekben használható jogszerűen:<br> 1.) Sürgős esetben, ha a páciens nem tud, nem képes eseti rendelkezést tenni. (Az Eüak. 10. § (4) bekezdése szerint sürgős
                                                szükség esetén a kezelést végző orvos által ismert, a gyógykezeléssel összefüggésbe hozható minden egészségügyi és személyazonosító adat továbbítható.) <br> 2.) A páciens 24 órás egyedi engedélye alapján
                                                (minta az e-egeszegugy.gov.hu oldalon letölthető).
                                            </div>
                                        </div>
                                    </div>

                                    <button class="btn btn-secondary" (click)="getPatientEkatDocumentList()" [disabled]="!patientEkatFormGroup.valid || !patientListForm.controls.patientName.value">
                    <i *ngIf="ekatDetailsInProgress" class="fa fa-spinner fa-spin"></i>Keresés</button>
                                    <table *ngIf="patientEkatList && !showEkatInfo && !ekatEventEntryLoading" class="table-striped" style="width: 100%;" mat-table [dataSource]="patientEkatList">
                                        <ng-container matColumnDef="eventType">
                                            <th mat-header-cell *matHeaderCellDef class="tablePadding"> eseménytípus </th>
                                            <td mat-cell *matCellDef="let element" class="tablePadding">
                                                {{ element.businessContent.esemenyTipus === 'JARO_ELBOCSATAS' ? 'elbocsátás (járó)' : element.businessContent.esemenyTipus === 'JARO_FELVETEL' ? 'felvétel (járó)' : element.businessContent.esemenyTipus === 'FEKVO_FELVETEL' ? 'felvétel (fekvő)' : element.businessContent.esemenyTipus
                                                === 'FEKVO_ELBOCSATAS' ? 'elbocsátás (fekvő)' : element.businessContent.esemenyTipus === 'HAZIORVOS_FELVETEL' ? 'felvétel (háziorvos)' : element.businessContent.esemenyTipus }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="fromDate">
                                            <th mat-header-cell *matHeaderCellDef class="tablePadding"> ellátás kezdete </th>
                                            <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.businessContent.ellatasKezdete | date: 'yyyy/MM/dd'}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="toDate">
                                            <th mat-header-cell *matHeaderCellDef class="tablePadding"> végdátum </th>
                                            <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.businessObjectLogging.lastModifyDate | date: 'yyyy/MM/dd'}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="institute">
                                            <th mat-header-cell *matHeaderCellDef class="tablePadding"> intézmény </th>
                                            <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.businessContent.ellatoOsztaly}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="section">
                                            <th mat-header-cell *matHeaderCellDef class="tablePadding"> osztály </th>
                                            <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.businessContent.ellatoOsztaly}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="doctor">
                                            <th mat-header-cell *matHeaderCellDef class="tablePadding"> orvos </th>
                                            <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.businessContent.ellatoOrvos}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="details">
                                            <th mat-header-cell *matHeaderCellDef class="tablePadding"> adatok </th>
                                            <td mat-cell *matCellDef="let element" class="tablePadding">
                                                <fa-icon (click)="showEkatDetails(element)" class="showEkatInfoIcon" style="cursor: pointer;" [icon]="faInfoCircle"></fa-icon>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayesColumnsEkat"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayesColumnsEkat;"></tr>
                                    </table>
                                    <div *ngIf="ekatEventEntryLoading">
                                        <div class="osahanloading"></div>
                                    </div>
                                    <div *ngIf="showEkatInfo" class="mt-2">
                                        <hr>

                                        <div class="col-sm-1">
                                            <button class="btn btn-danger" (click)="showEkatInfo = false">x</button>
                                        </div>
                                        <table mat-table [dataSource]="ekatEventEntryDataSource" class="w-100">
                                            <ng-container matColumnDef="ssn">
                                                <th mat-header-cell *matHeaderCellDef class="tablePadding"> taj </th>
                                                <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.response.data.businessMessageContent.businessContent.taj}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="dateFrom">
                                                <th mat-header-cell *matHeaderCellDef class="tablePadding"> ellátás kezdete </th>
                                                <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.response.data.businessMessageContent.businessContent.ellatasKezdete | date: 'yyyy/MM/dd'}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="doctor">
                                                <th mat-header-cell *matHeaderCellDef class="tablePadding"> ellátó orvos </th>
                                                <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.response.data.businessMessageContent.businessContent.ellatoOrvos}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="instUnit">
                                                <th mat-header-cell *matHeaderCellDef class="tablePadding"> ellátó osztály </th>
                                                <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.response.data.businessMessageContent.businessContent.ellatoOsztaly}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="assistanceId">
                                                <th mat-header-cell *matHeaderCellDef class="tablePadding"> ellátás id </th>
                                                <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.response.data.businessMessageContent.businessContent.ellatasId}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="caseType">
                                                <th mat-header-cell *matHeaderCellDef class="tablePadding"> esemény típus </th>
                                                <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.response.data.businessMessageContent.businessContent.esemenyTipus}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="status">
                                                <th mat-header-cell *matHeaderCellDef class="tablePadding"> státusz </th>
                                                <td mat-cell *matCellDef="let element" class="tablePadding"> {{element.response.data.businessMessageContent.businessContent.status}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="deleteEkat">
                                                <th mat-header-cell *matHeaderCellDef class="tablePadding"> eseménykatalógus törlése </th>
                                                <td mat-cell *matCellDef="let element" class="tablePadding">
                                                    <fa-icon (click)="deleteEkatDocument(element)" class="deleteEhrIcon" [icon]="faTrash"></fa-icon>
                                                </td>
                                            </ng-container>
                                            <tr mat-header-row *matHeaderRowDef="displayedEventEntry"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedEventEntry;"></tr>
                                        </table>
                                    </div>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="Páciens időpontjai" (click)="switchTab(5)">
                            <ng-template matTabContent>
                                <div class="col-md-12 mt-3">
                                    <div class="row mt-2">
                                        <div class="col">
                                            <label>tól</label>
                                            <div class="input-group mr-1">
                                                <input class="form-control datepicker_mobile" placeholder="yyyy-mm-dd" name="dp" [formControl]="patientReservationFromFormControl" ngbDatepicker #dFrom="ngbDatepicker" [minDate]="{year: 1900, month: 1, day: 1}">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar" (click)="dFrom.toggle()" type="button"></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <label>ig</label>
                                            <div class="input-group mr-1">
                                                <input class="form-control datepicker_mobile" placeholder="yyyy-mm-dd" name="dp" [formControl]="patientReservationToFormControl" ngbDatepicker #dTo="ngbDatepicker" [minDate]="{year: 1900, month: 1, day: 1}">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar" (click)="dTo.toggle()" type="button"></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <button class="btn btn-secondary mt-2" (click)="getPatientReservations()" [disabled]="!patientName.value || !patientReservationFromFormControl.valid || !patientReservationToFormControl.valid || getPatientReservationLoading">
                                    <i *ngIf="getPatientReservationLoading" class="fa fa-spinner fa-spin"></i>Keresés</button>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <table *ngIf="patientReservationsDataSource.data.length > 0" mat-table class="w-100" [dataSource]="patientReservationsDataSource">
                                            <ng-container matColumnDef="dateFrom">
                                                <th mat-header-cell *matHeaderCellDef> tól </th>
                                                <td mat-cell *matCellDef="let element"> {{element.dateFrom | date: 'yyyy-MM-dd HH:mm'}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="dateTo">
                                                <th mat-header-cell *matHeaderCellDef> ig </th>
                                                <td mat-cell *matCellDef="let element"> {{element.dateTo | date: 'yyyy-MM-dd HH:mm'}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="longDescription">
                                                <th mat-header-cell *matHeaderCellDef> leírás </th>
                                                <td mat-cell *matCellDef="let element"> {{element.longDescription}} </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                        </table>
                                    </div>
                                </div>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>
</div>
