<div id="closeError" [ngClass]="{'showResult': closeError}">
    <div class="closeResult btn btn-secondary" (click)="close()">x</div>
    <div class="saveErrorContent">
        <span style="color: red;">{{ closeErrorMessage }}</span>
    </div>
</div>


<div class="col-md-6" style="margin-right: auto; margin-left: auto;">
    Saját páciens keresése
    <div class="row form-group">
        <form [formGroup]="patientListForm" class="form-dropdown-select">
            <ng-select [searchable]="true" formControlName="patientName" placeholder="Válasszon pácienst" [items]="patientList" bindLabel="displayData" bindValue="tapass" (clear)="legalGuardianDetails.reset()" (change)="onSelectedPatientChange($event)" [loading]="patientListLoading"
                loadingText="Betöltés..." notFoundText="Nincs találat">
            </ng-select>
        </form>
    </div>
</div>

<div class="container-fluid">
    <h4 class="col-md-6"><b>Törvényes képviselő adatai</b></h4>
    <div class="col-md-12">
        <form [formGroup]="legalGuardianDetails" class="form-group">
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <label for="birthname" class="col-md-4 control-label">Születési név<span class="mandatory">*</span></label>
                        <div class="col-md-8 form-item mb-3">
                            <input type="text" id="birthname" placeholder="Születési név" class="form-control" [formControl]="birthname">
                            <div class="formError" *ngIf="birthname.invalid && formSubmitted">Nem megfelelő név (minimum 4 karakter)</div>
                        </div>
                        <label for="birthplace" class="col-md-4 control-label">Születési hely<span class="mandatory">*</span></label>
                        <div class="col-md-8 form-item mb-3">
                            <input type="text" id="birthplace" placeholder="Születési hely" class="form-control" [formControl]="birthplace">
                            <div class="formError" *ngIf="birthplace.invalid && formSubmitted">Nem megfelelő születési hely (minimum 2 karakter)</div>
                        </div>

                        <label for="dateofbirth" class="col-md-4 control-label">Születési idő<span class="mandatory">*</span></label>
                        <div class="col-md-8 form-item mb-3">
                            <div class="input-group mr-1">
                                <input class="form-control datepicker_mobile" placeholder="yyyy-mm-dd" name="dp" formControlName="dateofbirth" ngbDatepicker #d="ngbDatepicker" [ngClass]="{'date-invalid': dateInvalid === true}">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                </div>
                            </div>
                            <div class="formError" *ngIf="dateofbirth.invalid && formSubmitted">Nem megfelelő születési idő </div>
                        </div>

                        <label for="mothersname" class="col-md-4 control-label">Anyja neve<span class="mandatory">*</span></label>
                        <div class="col-md-8 form-item mb-3">
                            <input type="text" id="mothersname" placeholder="Anyja neve" class="form-control" [formControl]="mothersname">
                            <div class="formError" *ngIf="mothersname.invalid && formSubmitted">Nem megfelelő név (minimum 4 karakter)
                            </div>
                        </div>

                        <label for="aliasname" class="col-md-4 control-label">Név<span class="mandatory">*</span></label>
                        <div class="col-md-8 form-item mb-3">
                            <input type="text" id="aliasname" placeholder="Név" class="form-control" [formControl]="aliasname">
                            <div class="formError" *ngIf="aliasname.invalid && formSubmitted">Nem megfelelő név (minimum 4 karakter)</div>
                        </div>

                        <label for="lggender" class="col-md-4 control-label">Nem</label>
                        <div class="col-md-8 form-item mb-3">
                            <input type="radio" value="M" class="radio-item" name="lgGender" [formControl]="lgGender"> Férfi
                            <input type="radio" value="F" class="radio-item" name="lgGender" [formControl]="lgGender"> Nő
                            <div class="formError" *ngIf="lgGender.invalid && formSubmitted">Válasszon nemet!</div>
                        </div>

                        <label for="phone" class="col-md-4 control-label">Telefonszám</label>
                        <div class="col-md-8 form-item mb-3">
                            <input type="text" id="phone" placeholder="Telefonszám" class="form-control" [formControl]="phone">
                            <div class="formError" *ngIf="phone.invalid && formSubmitted">Nem megfelelő telefonszám (minimum 8 karakter)</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-12">
                            <h3><b>Lakcím</b></h3>
                        </div>
                        <label for="country" class="col-md-4 control-label">Ország</label>
                        <div class="col-md-8 form-item mb-3">
                            <ng-select class="full-width" [searchable]="true" placeholder="Válasszon országot..." formControlName="country" [items]="countryList" bindLabel="name" bindValue="code" [clearable]="false" loadingText="Betöltés..." notFoundText="Nincs találat">
                            </ng-select>
                        </div>

                        <label for="zip" class="col-md-4 control-label">Irányítószám</label>
                        <div class="col-md-8 form-item mb-3">
                            <input type="text" id="zip" placeholder="Irányítószám" class="form-control" [formControl]="zip">
                            <div class="formError" *ngIf="zip.invalid && formSubmitted">Nem megfelelő irányítószám (minimum 3 karakter)
                            </div>
                        </div>


                        <label for="city" class="col-md-4 control-label">Város</label>
                        <div class="col-md-8 form-item mb-3">
                            <input type="text" id="city" placeholder="Város" class="form-control" [formControl]="city">
                            <div class="formError" *ngIf="city.invalid && formSubmitted">Nem megfelelő város (minimum 2 karakter)
                            </div>
                        </div>

                        <label for="address" class="col-md-4 control-label">Utca</label>
                        <div class="col-md-8 form-item mb-3">
                            <input type="text" id="address" placeholder="Utca" class="form-control" [formControl]="street">
                            <div class="formError" *ngIf="street.invalid && formSubmitted">Nem megfelelő utca, házszám (minimum 4 karakter)</div>
                        </div>

                        <label for="houseno" class="col-md-4 control-label">Házszám</label>
                        <div class="col-md-8 form-item mb-3">
                            <input type="text" id="houseno" placeholder="Házszám" class="form-control" [formControl]="houseno">
                            <div class="formError" *ngIf="houseno.invalid && formSubmitted">Nem megfelelő utca, házszám (minimum 1 karakter)</div>
                        </div>

                        <label for="floordoor" class="col-md-4 control-label">Emelet, Ajtó</label>
                        <div class="col-md-8 form-item mb-3">
                            <input type="text" id="floordoor" placeholder="Emelet, Ajtó" class="form-control" [formControl]="floordoor">
                        </div>

                        <div class="col-md-12">
                            <div class="text-right" style="float: right; margin-left: 10px;">
                                <button type="button" class="btn btn-primary" (click)="ok()"> Rendben</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h3><b>Számlázási adatok</b></h3>
                    <div class="row form-group">

                        <label for="billingname" class="col-sm-4 control-label">Név</label>
                        <div class="col-sm-8 form-item">
                            <input type="text" id="billingname" placeholder="Név" class="form-control" appTitleCase [formControl]="billingname">
                        </div>

                        <label for="billingtaxid" class="col-sm-4 control-label">Adószám</label>
                        <div class="col-sm-8 form-item">
                            <input type="text" id="billingtaxid" placeholder="Adószám" class="form-control" [formControl]="billingtaxid">
                        </div>

                        <label for="billingzip" class="col-sm-4 control-label">Irányítószám</label>
                        <div class="col-sm-8 form-item">
                            <input type="text" id="billingzip" placeholder="Irányítószám" class="form-control" [formControl]="billingzip">
                        </div>

                        <label for="billingcity" class="col-sm-4 control-label">Város</label>
                        <div class="col-sm-8 form-item">
                            <input type="text" id="billingcity" placeholder="Város" class="form-control" appUpperCaseFirst [formControl]="billingcity">
                        </div>

                        <label for="billingaddress" class="col-sm-4 control-label">Utca, házszám</label>
                        <div class="col-sm-8 form-item">
                            <input type="text" id="billingaddress" placeholder="Utca, házszám" class="form-control" appUpperCaseFirst [formControl]="billingaddress">
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>