import { CheckEquiomentIsFreeReq } from './../book-appointment/book-appointment';
import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedDataService } from '../services/shared-data.service';
import { Subscription, ReplaySubject, timer, Subject } from 'rxjs';
import { SharedApiService } from '../services/shared-api.service';
import { environment } from '../../environments/environment';
import { debounceTime, filter } from 'rxjs/operators';
import { RedirectService } from '../services/redirect.service';
import { faUserEdit, faBed, faTablets, faIdCard, faBars, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ApiService } from '../core/http/api.service';
import { GetPatientFeterRequest } from '../shared/models/request/gePatientFeterDataRequest';
import { DatePipe, PlatformLocation } from '@angular/common';
import { MessageHeader } from '../shared/models/messageHeader';
import { WeekScheduleDay } from '../shared/models/weekschedule';
import { SaveAppointmentWeeklyRequest } from '../shared/models/request/saveAppointmentRequest';
import { ToastrService } from 'ngx-toastr';
import { PrescriptionAuthenticationRequest } from '../shared/models/request/prescriptionAuthenticationRequest';
import { SaveScheduleList } from '../book-appointment/book-appointment';

export interface BookedCells {
  dateFrom: number,
  dateTo: number,
  name: string,
  status: string,
  displayData: string
}

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit, OnDestroy {

  constructor(public authService: AuthService,
    private location: PlatformLocation,
    private http: HttpClient,
    private router: Router,
    private apiService: ApiService,
    public sharedDataService: SharedDataService,
    private sharedApiService: SharedApiService,
    private openTab: RedirectService,
    public datepipe: DatePipe,
    private toastr: ToastrService
  ) { }

  @Input() calendarID: string;
  @Input() twoColumns: boolean;

  daysArray = ['Vasárnap', 'Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Szombat'];

  saveBookingLoading = false;

  dayLenghtInHours = {
    hourFrom: 8,
    hourTo: 16
  }
  weekSchedule: Array<WeekScheduleDay> = [];

  selectedPatientName = '';

  bookingDateFrom: Date = null;
  bookingDateTo: Date = null;

  cellSelected = new ReplaySubject<any>(1);

  showPatientForm = false;
  resetWeek = false;

  scheduleToDeleteCalendarFk: number;
  deleteAppointmentModal = false;
  bookingDataValid = false;
  szentVincent = false;
  patientListLoading = true;

  selectedPatient = null;
  showEquipment = null;
  patientList = [];
  equipmentList = [];

  bookedCells = [];
  tempCells = [];
  freeCells = [];
  coCells = [];

  patientAlias = new FormControl('', [Validators.required, Validators.minLength(1)]);

  comment = new FormControl();

  patientName = new FormControl();
  equipment = new FormControl(null, Validators.required);

  bookingDetails = new FormGroup({
    'patientName': this.patientName
  });


  faUserEdit = faUserEdit;
  faBed = faBed;
  faTablets = faTablets;
  faIdCard = faIdCard;
  faBars = faBars;
  faChevronRight = faChevronRight;

  modReasonSelect = new FormControl();

  modReason = new FormGroup({
    modReasonSelect: this.modReasonSelect
  });

  isEmergency = false;
  /*limitNaucare: boolean;*/
  deleteInProgress = false;
  calendarModReasons = null;

  apiUrl = environment.apiUrl;
  calendarLoading = false;
  selectedCsId = null;
  selectedCsIdSubscription: Subscription;
  patientListDataSubscription: Subscription;

  calendarEntryButtons;
  feterLink = environment.feterUrl;
  prescriptionLink = environment.prescriptionUrl;
  error = false;
  errorDescription = '';

  showButtons = false;
  selectedRole = null;
  dateFrom = this.sharedDataService.getCurrentDate();
  dateSubscription: Subscription;

  updateCalendarEntrySubscription: Subscription;

  calendarRefreshSubscription: Subscription;

  availServic = [];
  hamburgerButtons: Array<number> = new Array();
  availServicSubscription: Subscription;
  availServiceEmpty = true;
  selectedService = null;
  eesztUserId = '';

  service = new FormControl();
  serviceSelect = new FormGroup({
    'service': this.service
  });

  calendarModReasonsSubscription: Subscription;
  calendarModReasonsLoading = true;
  currentTimeMarkerPosition = -1;
  currentScrollPosition = -100;

  calendarListDataAll = [];
  calendarListDataRows = [];
  calendarListDataRowItemCount = [];
  firstCalendarAppTimestamp: number;
  firstTimeStampSubscription: Subscription;
  lastCalendarAppTimeStamp: number;
  additionalSlots: number;
  firstDisplayTimeStamp: number;

  calendarLoadSubscription: Subscription;

  autoRefreshCalendarData = null;

  calendarRefreshTimer = environment.calendarRefreshTimer.DEFAULT;

  calendarEntryDuration = 900000;

  layoutWidthSubscription: Subscription;
  layoutWidth = 1;

  calendarLoaded = false;

  scrolled = false;
  currentScrollPos: ElementRef;

  parallelAppointments = 1;
  Brand = '';

  callNumber = '---';
  callPatientLoading = false;

  arrivePatientLoading = false;

  bookedCellDisplayData: string;

  loadCalendarOther = new ReplaySubject<any>(1);

  currentDate: number;

  @ViewChild('currentScrollPos') set csp(currentScrollPos: ElementRef) {
    if (currentScrollPos) {
      this.currentScrollPos = currentScrollPos;
    }

    if (this.calendarID === '1') {
      if (this.currentScrollPos && this.scrolled === false) {  // Scroll window to current time
        // console.log('Scroll window to current time');
        // console.log('*****currentScrollPos:', this.currentScrollPos);
        // console.log('*****currentScrollPos TOP:', this.currentScrollPos.nativeElement.style.top);
        // console.log('*****currentScrollPosition:', this.currentScrollPosition);
        if (this.currentScrollPos.nativeElement.style.top !== '-100px') {
          this.scrolled = true;
          this.currentScrollPos.nativeElement.scrollIntoView();
        }
      }
    }
    // this.calculateCurrentTimeMarkerPosition('@ViewChild(currentScrollPos)');
  }

  ngOnInit() {
    const paramsEeszt = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      }
    };
    this.apiService.getEesztUserDetail(paramsEeszt).subscribe(eesztUser => {
      this.eesztUserId = eesztUser.eesztUserId;
    });

    if (this.sharedDataService.getSelectedRole()['roleCode'].includes('AUTH$PL-ECHOKFT') ||
      this.sharedDataService.getSelectedRole()['roleCode'].includes('AUTH$SZTVINCENT')) {
      this.szentVincent = true;
    }

    timer(0, 60000).subscribe(
      () => {
        this.currentDate = new Date().getTime();
      });

    this.cellSelected.pipe(debounceTime(2000)).subscribe(showPatient => {
      if (showPatient) {
        console.log('asd', this.sharedDataService.dateToDateTimeString(this.bookingDateFrom));
        console.log('asd', this.sharedDataService.dateToDateTimeString(this.bookingDateTo));

        if (this.selectedService.svDetail.svDetail.rules && this.selectedService.svDetail.svDetail.rules.subTypes) {
          this.showEquipment = true;
          const request: CheckEquiomentIsFreeReq = {
            etCode: this.selectedService.etCode,
            types: this.selectedService.svDetail.svDetail.rules.subTypes.replace(/\;/g, ','),
            dateFrom: this.sharedDataService.dateToDateTimeString(this.bookingDateFrom),
            dateTo: this.sharedDataService.dateToDateTimeString(this.bookingDateTo)
          };

          this.apiService.checkEquipmentsIsFree(request).subscribe(
            response => {
              this.equipmentList = this.setEquipments(response.equipments);
            }
          );
        }

        this.showPatientForm = showPatient;
      }
    });

    this.patientListDataSubscription = this.sharedDataService.patientListShared.subscribe((patientList) => {
      this.patientListLoading = false;
      this.patientList = patientList;
    });

    this.selectedRole = this.sharedDataService.getSelectedRole();

    this.calendarModReasonsSubscription = this.sharedDataService.calendarModReasonsShared.subscribe(data => {
      if (data) {
        this.calendarModReasons = data;
        this.calendarModReasonsLoading = false;
        // console.log(data);
      }
    });

    const hostname = (this.location as any).location.hostname;
    /*if (hostname === 'tesztgondozas.naucare.hu') {
      this.limitNaucare = true;
    } else {
      this.limitNaucare = false;
    }*/
    this.updateCalendarEntrySubscription = this.sharedDataService.updateCalendarEntryShared.subscribe(data => {
      this.updateCalendarEntryStatus(data.calendarId, data.status);
    }
    );

    this.Brand = this.authService.getBrand();
    this.calendarRefreshTimer = environment.calendarRefreshTimer[this.Brand];
    this.selectedCsIdSubscription = this.sharedDataService.selectedCsIdShared.subscribe(data => {
      this.selectedCsId = data;
      // console.log ('calendar selected csid change:', data);
    });

    this.layoutWidthSubscription = this.sharedDataService.layoutWidthShared.subscribe(value => {
      // console.log ('calendar layout width change arrived');
      this.layoutWidth = value;
      this.calculateCurrentTimeMarkerPosition('layoutWidthSubscription');
    });

    this.calendarRefreshSubscription = this.sharedDataService.onRefreshCalendar.pipe(
      debounceTime(500)
    ).subscribe(servicePointId => {
      if (servicePointId === this.selectedService['spId']) {
        // console.log ('Matching servicePointId - should refresh');
        this.loadCalendar(true);
      }
    });

    this.dateSubscription = this.sharedDataService.selectedDateChange.pipe(
      debounceTime(500)
    ).subscribe((date: string) => {
      this.scrolled = false;
      // console.log(' ///////////////////////////////////////////////////');
      // console.log('Calendar datechange subscription: ' + this.calendarID);
      // console.log('this.scrolled: ' + this.scrolled);
      this.currentScrollPosition = -100;
      this.currentTimeMarkerPosition = -1;
      this.dateFrom = date;
      // this.initialize(false);
      this.loadCalendar(false);
      let dateFromDateString = new Date(this.dateFrom);
      dateFromDateString.setDate(new Date(dateFromDateString).getDate());
      let params: WeekScheduleDay = {
        name: dateFromDateString.getFullYear() + '/' + (('0' + (dateFromDateString.getMonth() + 1)).slice(-2)) + '/' + (('0' + dateFromDateString.getDate()).slice(-2)) + '\n' + this.daysArray[dateFromDateString.getDay()],
        timeStamp: 5
      }
      this.weekSchedule = [];
      this.weekSchedule.push(params);
    });
    let dateFromDateString = new Date(this.dateFrom);
    dateFromDateString.setDate(new Date(dateFromDateString).getDate());
    let params: WeekScheduleDay = {
      name: dateFromDateString.getFullYear() + '/' + (('0' + (dateFromDateString.getMonth() + 1)).slice(-2)) + '/' + (('0' + dateFromDateString.getDate()).slice(-2)) + '\n' + this.daysArray[dateFromDateString.getDay()],
      timeStamp: 5
    }
    this.weekSchedule = [];
    this.weekSchedule.push(params);



    this.availServicSubscription = this.sharedDataService.availServicesShared.subscribe(data => {



      // console.log('availservice: ', data);
      if (data.length === 0) {
        this.availServiceEmpty = true;
        this.availServic = data;
        this.calendarListDataAll = [];
        this.selectedService = null;
        this.service.setValue(null);
      } else {

        this.availServiceEmpty = false;
        // console.log ('Role', this.sharedDataService.getSelectedRole());
        if (this.authService.getBrand() === 'UDMED' && (this.sharedDataService.getSelectedRole()['roleCode'] === 'AUTH$UDMED-VIRT_CCS' ||
          this.sharedDataService.getSelectedRole()['roleCode'] === 'AUTH$KENEZY_SCC')) {
          const serviceTemp = [];
          // console.log ('UDMED Service --');
          data.forEach(entry => {
            // console.log ('availService entry: ', entry);
            // console.log ('entry index: ', entry.etCode.indexOf('ELOJEGYZES'));
            if (entry.etCode.indexOf('ELOJEGYZES') > -1 || entry.etCode.indexOf('BETEGFELVETEL') > -1) {
              serviceTemp.push(entry);
            }
          });
          this.availServic = serviceTemp;
          this.calendarListDataAll = [];
          this.selectedService = null;
          this.service.setValue(null);
          // console.log ('availservice', this.availServic);

        } else {
          if (this.Brand === 'MEDCSEPP') {
            data.forEach(entry => {
              // console.log ('availService entry: ', entry);
              if (entry.etId !== 12720854 || entry.spName === 'Intézményi Diszpécser M') {
                this.availServic.push(entry);
              }
            });

            this.calendarListDataAll = [];
            this.selectedService = null;
            this.service.setValue(null);
          } else {
            this.availServic = data;
            this.calendarListDataAll = [];
            this.selectedService = null;
            this.service.setValue(null);
          }
        }
      }
    });
    // console.log ('cal get sel service:', this.sharedDataService.getSelectedService(this.calendarID));
    if (this.sharedDataService.getSelectedService(this.calendarID) !== undefined) {
      // console.log('selected Service: ', this.sharedDataService.getSelectedService(this.calendarID));
      this.selectedService = this.sharedDataService.getSelectedService(this.calendarID);
      if (this.selectedService && this.selectedService['spId']) {
        this.service.setValue(this.selectedService['spId']);
      }
      this.loadCalendar(false);
    }

    this.firstTimeStampSubscription = this.sharedDataService.calendarFirstTimeStampUpdate.subscribe((timestampData: Object) => {
      // console.log('timestamp data arrived into calendar:' + this.calendarID);
      // console.log(timestampData);
      let otherCalendarFirstTimeStamp: number;
      if (this.calendarID === '1') {
        if (timestampData[2] > 0) {
          otherCalendarFirstTimeStamp = timestampData[2];
          // console.log('other calendar timestamp: ' + timestampData[2]);
        }
      } else if (this.calendarID === '2') {
        if (timestampData[1] > 0) {
          otherCalendarFirstTimeStamp = timestampData[1];
          // console.log('other calendar timestamp: ' + timestampData[1]);
        }
      }

      if (otherCalendarFirstTimeStamp < this.firstCalendarAppTimestamp) {
        this.firstDisplayTimeStamp = otherCalendarFirstTimeStamp;
      } else {
        this.firstDisplayTimeStamp = this.firstCalendarAppTimestamp;
      }

      this.additionalSlots = ((this.firstCalendarAppTimestamp - this.firstDisplayTimeStamp) / this.calendarEntryDuration);
      // console.log('Slots needed :' + this.additionalSlots);
      if (this.additionalSlots > 0) {
        this.calculateCurrentTimeMarkerPosition('firstTimeStampSubscription');
      }

    });
  }

  setEquipments(equipmentList: string[]) {
    const equipments = [];
    equipmentList.forEach(
      eq => {
        const splittedEq = eq.split('=');
        equipments.push({ name: splittedEq[1], type: splittedEq[0] });
      }
    );
    return equipments;
  }

  ngOnDestroy() {
    this.showEquipment = false;
    this.updateCalendarEntrySubscription.unsubscribe();
    this.dateSubscription.unsubscribe();
    this.availServicSubscription.unsubscribe();
    this.calendarRefreshSubscription.unsubscribe();
    this.layoutWidthSubscription.unsubscribe();
    this.equipmentList = [];
    this.showEquipment = false;
    clearInterval(this.autoRefreshCalendarData);
  }

  showDeleteAppointment(show: boolean) {
    this.deleteAppointmentModal = show;
    // console.log('Del appointment start');
  }

  onPatientAliasChange($event) {
    if (this.patientAlias.valid === true) {
      this.patientName.setValue(null);
      this.selectedPatient = null;
    }
    this.validateDataSetForBooking();
  }

  validateDataSetForBooking() {
    // console.log ('this.patientAlias.valid', this.patientAlias.valid);
    if (/* this.selectedAppointments.length > 0 && */ (this.selectedPatient !== null || this.patientAlias.valid)) {
      this.bookingDataValid = true;
    } else {
      this.bookingDataValid = false;
    }
  }

  onSelectedPatientChange($event) {
    if ($event !== undefined) {
      this.selectedPatient = $event;
      this.selectedPatientName = this.selectedPatient.displayData;
      this.validateDataSetForBooking();
      this.patientAlias.setValue(null);
    } else {
      this.selectedPatient = null;
      this.selectedPatientName = '';
      this.validateDataSetForBooking();
    }
  }

  openFeter(calendarEntry) {
    const datePipe = new DatePipe('hu-HU');
    const messageHeader: MessageHeader = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.getSelectedRole()['roleCode'],
        currentServicePointId: this.sharedDataService.getSelectedRole()['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      }
    };
    const patientData: GetPatientFeterRequest = {
      messageHeader: messageHeader.messageHeader,
      patientId: calendarEntry.participantUserID
    };
    this.apiService.getFeterIdBySp(messageHeader).subscribe(rootInstituteID => {
      if (rootInstituteID.code < 0) {
        this.toastr.error(rootInstituteID.message);
      } else {
        this.apiService.getPatientFeterData(patientData).subscribe(res => {

          if (res.patientMinimal.gender) {

            const feterPathParams = '?rootId=' + rootInstituteID.rootElementId +
              '&patientId=' + res.patientMinimal.id +
              '&userTapass=' + this.authService.getUserData()['ekCardNo'] +
              '&calendarId=' + calendarEntry['calendarFk'];
            window.open(this.feterLink.concat(feterPathParams));
          } else {
            this.toastr.error('A páciens neme nincs kitöltve. Kérem adja meg a páciens nemét!');
          }

        });
      }
    });
  }

  openButtons(index, calendarentry) {
    if (this.hamburgerButtons.includes(calendarentry.id)) {
      this.hamburgerButtons = this.hamburgerButtons.filter(x => x !== calendarentry.id);
    } else {
      this.hamburgerButtons.push(calendarentry.id);
    }
  }

  onSelectedServiceChange($event) {
    this.selectedService = $event;
    this.loadCalendar(false);
    this.sharedDataService.setSelectedService(this.selectedService, this.calendarID);
  }

  onLogout() {
    this.authService.doLogout();
    this.router.navigate(['/login']);
  }

  sortCalendarItems(a, b) {
    if (a['dateFrom'] > b['dateFrom']) {
      return 1;
    } else if (a['dateFrom'] < b['dateFrom']) {
      return -1;
    }
    /*  if (a['scheduleFk'] > b['scheduleFk']) {
       return 1;
     } else if (a['scheduleFk'] < b['scheduleFk']) {
       return -1;
     } */
    return 0;
  }

  loadCalendar(refresh) {
    this.calendarLoaded = false;

    let previousAppointmentDateFrom = 0;
    let tempParallelAppointments = 1;
    let appointmentRow = 0;

    if (this.selectedService) {

      if (this.calendarLoadSubscription) {  // Cancel previous calendar load requests
        this.calendarLoadSubscription.unsubscribe();
        // console.log('Previous calendar data load canceled');
      }

      const loadCalendarData = {
        spId: this.selectedService['spId'],
        dateFrom: this.dateFrom,
        refresh: refresh
      };

      this.loadCalendarOther.next(loadCalendarData);

      this.calendarLoadSubscription = this.getCalendarListData(
        this.sharedDataService.getSelectedRole()['roleCode'], this.selectedService, refresh).subscribe(
          (calendarData: Response) => {
            this.calendarLoaded = true;
            let bookedCellsCopy = [];
            let freeCellsCopy = [];
            let tempCellsCopy = [];
            let coCellsCopy = [];
            this.bookedCells = [];
            this.coCells = [];
            this.tempCells = [];
            this.freeCells = [];
            if (this.autoRefreshCalendarData) {
              clearInterval(this.autoRefreshCalendarData);
            }

            // console.log ('calendarRefreshTimer', this.calendarRefreshTimer);
            if (this.calendarRefreshTimer >= 1000) {

              this.autoRefreshCalendarData = setInterval(() => {
                this.loadCalendar(true);
              }, this.calendarRefreshTimer);
            }

            this.calendarLoading = false;

            if (calendarData['code'] === 1) {

              this.calendarListDataRows = [];
              this.calendarListDataRowItemCount = [];

              this.calendarListDataAll = calendarData['data'];

              if (this.calendarListDataAll.length > 0) {
                this.sharedDataService.openEkatCount.next(0);
                let counter = 0;
                calendarData['data'].forEach(element => {
                  if (element.calendarStatus === 'A') {
                    counter = counter + 1;
                  }
                });
                this.sharedDataService.openEkatCount.next(counter);

                // console.log('calendarlistdataall : ', this.calendarListDataAll);
                this.calendarListDataAll.sort(this.sortCalendarItems);

                this.calendarEntryDuration = this.sharedDataService.DateStringConvert(this.calendarListDataAll[0]['dateTo']) -
                  this.sharedDataService.DateStringConvert(this.calendarListDataAll[0]['dateFrom']);

                /* let calendarIterationIndex = 0;
                let freeCellsGroupByScheduleFk = [];
                let previousScheduleFk = null;
                let freeCellsGroupIndex = -1; */
                let searchedSchedules = [];
                this.calendarListDataAll.forEach(entry => {
                  entry['dateTo'] = this.sharedDataService.DateStringConvert(entry['dateTo']);
                  entry['dateFrom'] = this.sharedDataService.DateStringConvert(entry['dateFrom']);

                  if (previousAppointmentDateFrom === entry['dateFrom']) {
                    tempParallelAppointments++;
                  } else {
                    this.parallelAppointments = tempParallelAppointments;
                    tempParallelAppointments = 1;
                    if (previousAppointmentDateFrom !== 0) {
                      this.calendarListDataRowItemCount[appointmentRow] = 12 / this.calendarListDataRows[appointmentRow].length;
                      // console.log ('this.calendarListDataRowItemCount[appointmentRow]', this.calendarListDataRowItemCount[appointmentRow]);
                      appointmentRow++;
                    }
                  }

                  if (!this.calendarListDataRows[appointmentRow]) {
                    this.calendarListDataRows[appointmentRow] = [];
                  }

                  /* if (entry.scheduleFk !== previousScheduleFk || !previousScheduleFk) {
                    freeCellsGroupByScheduleFk.push(entry);
                    freeCellsGroupIndex++;
                    previousScheduleFk = entry.scheduleFk
                  } else {
                    freeCellsGroupByScheduleFk[freeCellsGroupIndex-1].dateTo.setMinutes(this.calendarListDataAll[calendarIterationIndex-1].dateTo.getMinutes() +
                                                                                       (this.calendarListDataAll[calendarIterationIndex-1].dateTo.getMinutes() -
                                                                                        this.calendarListDataAll[calendarIterationIndex-1].dateFrom.getMinutes()));
                  }
                  calendarIterationIndex += 1;
 */
                  let groupedSchedule = null;
                  if (!searchedSchedules.includes(entry.scheduleFk)) {
                    let groupedByIterationSchedule = this.calendarListDataAll.filter(x => x.scheduleFk === entry.scheduleFk);
                    groupedSchedule = groupedByIterationSchedule[0];
                    groupedSchedule.dateTo = groupedByIterationSchedule[groupedByIterationSchedule.length - 1].dateTo;
                    searchedSchedules.push(entry.scheduleFk);
                  }

                  previousAppointmentDateFrom = entry['dateFrom'];
                  if (entry['calendarStatus'] === 'S' || entry['calendarStatus'] === 'A' || entry['calendarStatus'] === 'D' || entry['calendarStatus'] === 'DD') {
                    bookedCellsCopy.push(this.getBookedCell(entry, 'B', entry['calendarFk'] + '/' + 'páciens: ' + entry['participantDetail'] + ', munkahely: ' + entry['servicePointName']
                      + ',megjegyzés: ' + (entry['calendarComment'] ? entry['calendarComment'] : '')
                      + ',időpont: ' + this.sharedDataService.dateToDateTimeStringHuman(new Date(entry['dateFrom']))
                    )
                    );
                  } else if (groupedSchedule && groupedSchedule['calendarStatus'] === 'F') {
                    freeCellsCopy.push(this.getBookedCell(groupedSchedule, 'A', ''));
                  } else if (groupedSchedule && groupedSchedule['calendarStatus'] === 'T') {
                    tempCellsCopy.push(this.getBookedCell(groupedSchedule, 'T', entry['calendarFk'] + '/' + entry['longDescription']));
                  } else if (entry['calendarStatus'] === 'CO') {
                    coCellsCopy.push(this.getBookedCell(entry, 'CO', entry['calendarFk'] + '/' + entry['longDescription']));
                  }

                  // console.log ('calendar dateFrom:', entry['dateFrom']);
                  // console.log ('calendar dateFrom:', entry['dateTo']);

                  entry['slotLength'] = ((entry['dateTo'] - entry['dateFrom']) / this.calendarEntryDuration);

                  if (entry['participantName']) {

                    if (entry['participantName'].indexOf('#') > -1) {
                      entry['participantUserID'] = entry['participantName'].substring(entry['participantName'].indexOf('#') + 1);
                      entry['participantName'] = (entry['participantName'].substring(0, entry['participantName'].indexOf('#'))).trim();
                      entry['registeredPatient'] = true;
                    }
                    entry['displayData'] = (entry['participantName']).trim();
                    if (entry['calendarComment'] && entry['calendarComment'] !== '') {
                      if (entry['displayData'] !== '') {
                        entry['displayData'] += ', ' + entry['calendarComment'];
                      } else {
                        entry['displayData'] = entry['calendarComment'];
                      }
                    }

                  }

                  this.calendarListDataRows[appointmentRow][tempParallelAppointments - 1] = entry;

                });


                this.calendarListDataRowItemCount[appointmentRow] = this.calendarListDataRowItemCount[appointmentRow - 1];

                this.calendarListDataRowItemCount[0] = 12 / this.calendarListDataRows[0].length;

                // console.log('this.calendarListDataRows', this.calendarListDataRows);
                // console.log('this.calendarListDataRowItemCount', this.calendarListDataRowItemCount);
                // console.log ('structured calendar', this.calendarListDataRows);

                // console.log ('this.parallelAppointments:',  this.parallelAppointments);

                this.lastCalendarAppTimeStamp = this.calendarListDataAll[this.calendarListDataAll.length - 1]['dateTo'];

                this.firstCalendarAppTimestamp = this.calendarListDataAll[0]['dateFrom'];
                // console.log ('this.calendarEntryDuration: ', this.calendarEntryDuration);

                this.sharedDataService.setFirstCalenderAppTime(this.calendarListDataAll[0]['dateFrom'], this.calendarID);

                this.calculateCurrentTimeMarkerPosition('loadCalendar');
                //  console.log ('calendar: ', this.calendarListDataAll);

              }
            } else {
              // error
            }
            this.bookedCells = bookedCellsCopy;
            this.tempCells = tempCellsCopy;
            this.freeCells = freeCellsCopy;
            this.coCells = coCellsCopy;
          },
          error => {
            // error
          }
        );
    }
  }

  getBookedCell(entry, status, displayData): BookedCells {
    let datefromCopy = new Date(entry['dateFrom']);
    let dateToCopy = new Date(entry['dateTo']);
    let dateFrom = new Date(datefromCopy).getHours() * 100 + new Date(datefromCopy).getMinutes();
    let dateTo = new Date(dateToCopy).getHours() * 100 + new Date(dateToCopy).getMinutes();
    let name = new Date(datefromCopy).getFullYear() + '/' + (('0' + (new Date(datefromCopy).getMonth() + 1)).slice(-2)) + '/' + (('0' + new Date(datefromCopy).getDate()).slice(-2)) + '\n' + this.daysArray[new Date(datefromCopy).getDay()];
    return {
      dateFrom,
      dateTo,
      name,
      status,
      displayData
    }
  }

  saveScheduleList: SaveScheduleList[] = [];

  bookCells(event) {
    this.saveScheduleList = [];
    if (event) {
      event.forEach(
        (e, i) => {
          this.isEmergency = e.status === 'F' ? true : false;
          this.resetWeek = false;
          this.bookingDateFrom = new Date(e.name.substring(0, e.name.indexOf('\n')));
          this.bookingDateTo = new Date(this.bookingDateFrom);
          this.bookingDateFrom.setHours(e.dateFrom.hour);
          this.bookingDateFrom.setMinutes(e.dateFrom.minute);
          this.bookingDateTo.setHours(e.dateTo.hour);
          this.bookingDateTo.setMinutes(e.dateTo.minute);
          this.cellSelected.next(true);

          this.saveScheduleList.push({ dateFrom: this.bookingDateFrom, dateTo: this.bookingDateTo, isEmergency: this.isEmergency });
        });
    } else {
      this.bookingDateFrom = null;
      this.bookingDateTo = null;
      this.cellSelected.next(false);
    }
  }

  deleteCells(event) {
    if (event) {
      this.scheduleToDeleteCalendarFk = +event.substring(0, event.indexOf('/'));
      this.deleteAppointmentModal = true;
    }
  }

  deleteAppointment() {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.selectedRole['roleCode'],  // currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.selectedService.spId,
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calendarId: /* this.appointmentDetails.calendarFk */this.scheduleToDeleteCalendarFk,
      patientId: /* this.appointmentDetails.registeredPatient ? this.appointmentDetails.participantUserID :  */'',
      doctorId: '',
      status: 'X',
      calModReasonCode: this.modReasonSelect.value.code,
      diagnosisComment: '',
      isCalDetailUpdateEnabled: true
    };

    this.deleteInProgress = true;

    this.updateCalendar(params).subscribe(response => {
      this.showDeleteAppointment(false);
      this.deleteInProgress = false;
      if (response['code'] === 1) {
        this.modReasonSelect.setValue(null);
        this.loadCalendar(false);
      } else {

      }
    });
  }

  changeDateRange(event) {
    this.dayLenghtInHours = { hourFrom: event.hourFrom, hourTo: event.hourTo };
  }

  saveAppointment() {
    if (this.showEquipment && this.equipment.invalid) {
      this.toastr.error('kérem válasszon eszközt a listából!');
      return;
    }
    this.saveBookingLoading = true;
    if (this.patientAlias.value || this.bookingDetails.controls.patientName.value) {
      /* let bookingDateToCopy = new Date(this.bookingDateTo);
      bookingDateToCopy.setHours(bookingDateToCopy.getHours());
      let bookingDateFromCopy = new Date(this.bookingDateFrom);
      bookingDateFromCopy.setHours(bookingDateFromCopy.getHours()); */
      if (this.isEmergency) {
        this.sharedApiService.getUserSimple(this.bookingDetails.controls.patientName.value).subscribe(res => {
          let params: SaveAppointmentWeeklyRequest = {

            participantAlias: this.patientAlias.value,
            comment: this.comment.value,
            dateFrom: this.sharedDataService.dateToDateTimeString(this.bookingDateFrom),
            dateTo: this.sharedDataService.dateToDateTimeString(this.bookingDateTo),
            isEmergency: false,
            messageHeader: {
              currentTapass: this.authService.getUserData()['ekCardNo'],
              currentRole: this.selectedRole['roleCode'],  // currentRole: this.sharedDataService.selectedRole['roleCode'],
              currentServicePointId: this.selectedService.spId,
              locale: 'hu',
              deviceId: this.authService.getUUID(),
              sessionId: null //this.bookingDetails.controls.patientName.value
            },
            eventTypeId: this.selectedService.etId,
            participantId: res['user'] ? res['user']['userId'] : null,
            servicePointId: this.selectedService.spId
          };
          console.log('saveemergency params: ', params);
          this.apiService.saveAppointmentWeekly(params).subscribe(res => {
            if (res.code > -1) {
              this.saveBookingLoading = false;
              this.showPatientForm = false;
              this.patientName.setValue(null);
              this.patientAlias.setValue('');
              this.comment.setValue('');
              this.bookingDateFrom = null;
              this.bookingDateTo = null;
              this.loadCalendar(false);
            } else {
              this.toastr.error(res.message);
              this.saveBookingLoading = false;
            }

          }, err => {
            this.saveBookingLoading = false;
            this.showPatientForm = false;
          });
        }, err => {
          console.log('error: ', err);
        });
      } else {
        let tapass = '';
        let ssnType = '';
        let ssnNumber = '';
        if (this.selectedPatient) {
          if (this.selectedPatient.tapass) {
            tapass = this.selectedPatient.tapass;
            ssnType = this.selectedPatient.medicalType;
            ssnNumber = this.selectedPatient.medicalId;
          } else if (this.selectedPatient.participantTapass) {
            tapass = this.selectedPatient.participantTapass;
          }
        }
        let dateFrom = new Date(this.bookingDateFrom);
        let dateTo = new Date(this.bookingDateTo);
        dateFrom.setHours(dateFrom.getHours()/* -1 */);
        dateTo.setHours(dateTo.getHours()/* -1 */);
        dateTo.setMinutes(dateTo.getMinutes() - 1);
        console.log('Mentés: ');
        
        let getScheduleListParams = {
          messageHeader: {
            currentTapass: this.authService.getUserData()['ekCardNo'],
            currentRole: this.selectedRole['roleCode'],  // currentRole: this.sharedDataService.selectedRole['roleCode'],
            currentServicePointId: this.selectedService.spId,
            locale: 'hu',
            deviceId: this.authService.getUUID(),
            sessionId: null //this.bookingDetails.controls.patientName.value
          },
          dateFrom: dateFrom,
          dateTo: dateTo,
          eventId: this.selectedService.etId,
          servicePointId: this.selectedService.spId
        }
        
        this.apiService.getScheduleList(getScheduleListParams).subscribe(res => {
          let params = {
            messageHeader: {
              currentTapass: this.authService.getUserData()['ekCardNo'],
              currentRole: this.selectedRole['roleCode'],  // currentRole: this.sharedDataService.selectedRole['roleCode'],
              currentServicePointId: this.selectedService.spId,
              locale: 'hu',
              deviceId: this.authService.getUUID(),
              sessionId: null //this.bookingDetails.controls.patientName.value
            },
            patientTapass: tapass,
            providerUserId: '',
            ssnType,
            ssnNumber,
            patientAlias: this.patientAlias.value,
            scheduleFkList: res.scheduleFkList,
            diagnosisComment: this.comment.value,
            eventTypeId: this.selectedService.etId,
            equipment: this.equipment.value
          }
          this.apiService.saveScheduleDay(params).subscribe(res => {
            if (res.code > -1) {
              this.toastr.success('Sikeres mentés');
              this.saveBookingLoading = false;
              this.showPatientForm = false;
              this.patientName.setValue(null);
              this.patientAlias.setValue('');
              this.comment.setValue('');
              this.bookingDateFrom = null;
              this.bookingDateTo = null;
              this.equipment.reset();
              this.equipmentList = [];
              this.loadCalendar(false);
            } else {
              this.saveBookingLoading = false;
              this.toastr.error(res.message);
            }
          }, err => {
            this.equipment.reset();
            this.equipmentList = [];
            this.saveBookingLoading = false;
            this.showPatientForm = false;
          });
        }, err => {
          console.log('getlist error:', err);
        });
      }
    } else {
      this.saveBookingLoading = false;
    }
  }

  updateCalendar(params: {}) {
    return this.http.post(this.apiUrl + '/rest/neu/calendarlist/updatecalendar', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  calculateCurrentTimeMarkerPosition(callerID) {
    // console.log ('--------------------------------------------');
    // console.log ('callerID: ', callerID);
    // console.log ((new Date).toISOString() + ' calculateCurrentTimeMarkerPosition');
    const firstItem = new Date(this.firstDisplayTimeStamp);
    // const now = new Date('2019-11-11 14:' + minutes + ':' + seconds);
    const now = new Date();

    const firstItemDate = firstItem.getFullYear() + '-' + firstItem.getMonth() + '-' +
      firstItem.getDate();

    const nowDate = now.getFullYear() + '-' + now.getMonth() + '-' +
      now.getDate();

    let position = -1;
    let scrollPosition = -100;
    // console.log('firstItemDate', firstItemDate);
    if (firstItemDate === nowDate) {  // Only calculate marker and scroll if the displayed day is today
      // console.log('today');
      // console.log('now.getTime()', now.getTime());
      // console.log('this.lastCalendarAppTimeStamp', this.lastCalendarAppTimeStamp);
      const deltaTime = (now.getTime() - this.firstDisplayTimeStamp) / 1000;

      if (deltaTime >= 0 && now.getTime() <= this.lastCalendarAppTimeStamp) { // Only calculate if it's now is between start and end

        // console.log ('this.calendarEntryDuration', this.calendarEntryDuration / 900000);

        if (this.layoutWidth === 2) {
          position = (deltaTime / 60) * 3 / (this.calendarEntryDuration / 900000);
          scrollPosition = position - 250;
        } else {
          position = (deltaTime / 60) * 5.06666666666667 / (this.calendarEntryDuration / 900000);
          scrollPosition = position - 250;
        }

        this.currentScrollPosition = Math.round(scrollPosition);
        this.currentTimeMarkerPosition = Math.round(position);

        if (this.calendarID === '1') {
          if (this.currentScrollPos && this.scrolled === false) {  // Scroll window to current time
            // console.log('Scroll window to current time');
            // console.log('*****currentScrollPos:', this.currentScrollPos);
            // console.log('*****currentScrollPos TOP:', this.currentScrollPos.nativeElement.style.top);
            // console.log('*****currentScrollPosition:', this.currentScrollPosition);
            if (this.currentScrollPos.nativeElement.style.top !== '-100px') {
              this.scrolled = true;
              this.currentScrollPos.nativeElement.scrollIntoView();
            }
          } else {
            // console.log('NO Scroll window to current time');
            if (this.currentScrollPos) {
              // console.log('this.currentScrollPos', this.currentScrollPos.nativeElement.style.top);
            }
            // console.log('this.scrolled', this.scrolled);
          }
        }
      }
    }
    this.currentScrollPosition = Math.round(scrollPosition);
    this.currentTimeMarkerPosition = Math.round(position);
  }

  getCalendarListData(role: string, servicePointFk: string, updateOnly: boolean) {
    if (updateOnly !== true) {
      this.calendarLoading = true;
    }
    let statusList = 'S,A,D,DD,F,CO,T';
    if (this.Brand === 'MEDCSEPP') {
      statusList = 'S,DD,DH,DN,A,F';
    }

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.getSelectedRole()['roleCode'],
        currentServicePointId: this.sharedDataService.getSelectedRole()['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      servicePointId: this.selectedService['spId'],
      dateFrom: this.dateFrom,
      dateTo: this.dateFrom,
      showFreeAppointments: true,
      searchTapass: '',
      searchAlias: '',
      statusList: statusList,
      eventId: this.selectedService['etId']
    };

    return this.http.post(this.apiUrl + '/rest/neu/calendarlist/getcalendarlistdata', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }


  calendarItemStatus(entry: any) {

    if (entry['calendarStatus'] === 'S') {
      return '';
    } else if (entry['calendarStatus'] === 'F') {
      return 'appointment_free';
    } else {
      return '';
    }

  }

  showBookedCellInformationModal(event) {
    if (event?.displayData) {
      this.bookedCellDisplayData = event.displayData.substring(event.displayData.indexOf('/') + 1);
      this.scheduleToDeleteCalendarFk = +event.displayData.substring(0, event.displayData.indexOf('/'));
      const cal = this.calendarListDataAll.filter(c => c.calendarFk === this.scheduleToDeleteCalendarFk);
      this.sharedDataService.setAppointmentData(cal[0]);
      this.deleteAppointmentModal = true;
    }
  }

  setPatientData(data: any, type: string) {
    data['NGdestinationService'] = type;
    this.sharedDataService.setPatientData(data);
  }

  isUpdateEkat(isEkatUpdate: boolean) {
    this.sharedDataService.setEkatMode(isEkatUpdate);
  }

  setPatientCalendarDetail(patientCalendarDetail) {
    this.sharedDataService.setIsPatientDone(patientCalendarDetail);
  }

  setAppointmentData(data: any, type: string) {
    data['NGdestinationService'] = type;
    data['multi'] = false;
    this.sharedDataService.setAppointmentData(data);
  }


  onGetCalendarDetails(calendarFk) {
    this.sharedApiService.getCalendarDetail(calendarFk).subscribe(data => {
      // console.log('Calendar Detail');
      // console.log(data);
    });
  }

  refreshCalendar() {
    // this.initialize(false);
  }

  onCallPatient(calendarId, calendarStatus) {
    // console.log ('callPatient clicked');
    if (this.callPatientLoading !== true && this.arrivePatientLoading !== true) {
      this.callPatientLoading = true;
      this.callNumber = '';
      // console.log ('callPatient calendarId: ' + calendarId + ', csId: ' + this.selectedCsId);
      this.callPatient(calendarId, this.selectedCsId).subscribe(response => {
        this.callPatientLoading = false;
        if (response['code'] === 200) {
          // console.log('callPatient:', response);
          this.callNumber = response['callNumber'];
          if (calendarStatus !== 'DD') {
            this.updateCalendarEntryStatus(response['calendarId'], 'DN');
          }
        } else {
          this.callNumber = '---';
          console.error('callPatient ERROR', response);
        }
      });
    } else {
      // console.log('Művelet folyamatban...');
    }
  }

  callPatient(calendarId, csId) {
    const params = {
      // messageHeader: {
      //   currentTapass: this.authService.getUserData()['ekCardNo'],
      //   currentRole: this.sharedDataService.getSelectedRole()['roleCode'],
      //   currentServicePointId: this.sharedDataService.getSelectedRole()['spId'],
      //   locale: 'hu',
      //   deviceId: this.authService.getUUID()
      // },
      csId: csId,
      calendarId: calendarId
    };

    return this.http.post(this.sharedDataService.callerSystemAPIURL + 'callpatient', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  onArrivePatient(ssn, calendarId) {
    if (this.callPatientLoading !== true && this.arrivePatientLoading !== true) {

      this.arrivePatientLoading = true;
      this.arrivePatient(ssn, calendarId, this.selectedCsId).subscribe(response => {
        this.arrivePatientLoading = false;
        if (response['code'] === 200) {
          this.updateCalendarEntryStatus(calendarId, 'DH');
          // console.log('arrivePatient:', response);

        } else {
          this.callNumber = '---';
          console.error('arrivePatient ERROR', response);
        }
      });
    } else {
      // console.log('Művelet folyamatban...');
    }
  }

  arrivePatient(ssn, calendarId, csId) {
    const params = {
      // messageHeader: {
      //   currentTapass: this.authService.getUserData()['ekCardNo'],
      //   currentRole: this.sharedDataService.getSelectedRole()['roleCode'],
      //   currentServicePointId: this.sharedDataService.getSelectedRole()['spId'],
      //   locale: 'hu',
      //   deviceId: this.authService.getUUID()
      // },
      calendarId: calendarId,
      csId: csId,
      ssn: ssn
    };

    return this.http.post(this.sharedDataService.callerSystemAPIURL + 'arrivepatient', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  updateCalendarEntryStatus(calendarId, status) {
    // console.log('updateCalendarEntryStatus ID:', calendarId);
    // console.log('updateCalendarEntryStatus status:', status);
    // console.log ('this.calendarListDataRows:', this.calendarListDataRows);
    // console.log('calendar', this.calendarListDataRows);
    // obj => obj.grpCode === item['grpCode'])
    const calendarListDataRowsTemp = this.calendarListDataRows;
    calendarListDataRowsTemp.forEach(row => {
      // console.log ('checking row:', row);
      row.forEach(item => {
        // console.log ('checking calendarFk:', item.calendarFk);
        if (item.calendarFk == calendarId) {
          item.calendarStatus = status;
          // console.log('item found', item);
        }
      });
    });
    this.calendarListDataRows = calendarListDataRowsTemp;
    // console.log('calendar', this.calendarListDataRows);

  }


  getPrescriptionInitData(calId) {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.getSelectedRole()['roleCode'],
        currentServicePointId: this.sharedDataService.getSelectedRole()['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calId: calId
    };

    return this.http.post(this.apiUrl + '/rest/neu/pres/getprescriptioninitdata', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  initVenyiro(calId) {
    const prescriptionAuthenticationRequest: PrescriptionAuthenticationRequest = {
      calendarId: calId,
      contextParams: 'TAPASS=' + this.authService.getUserData()['ekCardNo'] + '#ROLE=' +
        this.sharedDataService.getSelectedRole()['roleCode'] +
        '#SERVICE_POINT_FK=' + this.sharedDataService.getSelectedRole()['spId'],
      doctorId: null,
      sessionId: null,
      isEesztAuthenticated: this.sharedDataService.isEesztActive
    };
    this.apiService.getPrescriptionToken(prescriptionAuthenticationRequest).subscribe(res => {
      this.prescriptionLink = environment.prescriptionUrl + '?token=' + res.token;
      window.open(this.prescriptionLink);
    }, err => {
      this.toastr.error('hiba történt!');
    });
    /* this.getPrescriptionInitData(calId).subscribe(response => {
      if (response['code'] === 1) {
        const params = {};
        Object.keys(response['data']).forEach(key => {
          // console.log('key: ' + key + ' - ' + response['data'][key]);
          params[this.ucFirst(key)] = response['data'][key];
        });
        this.openTab.postHtml(params, 'http://172.17.16.80:8888/$');
      } else {
        this.error = true;
        this.errorDescription = 'Vényíró funkció: ' + response['message'];
        console.error('getPrescriptionInitData ERROR', response);
      }
    }); */


  }

  ucFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  calStatusUpdate(calendarId) {
    this.calStatusSave(calendarId, 'DD').subscribe(response => {
      if (response['code'] === 200) {
        this.updateCalendarEntryStatus(calendarId, 'DD');
        // console.log('calStatusSave:', response);

      } else {
        this.callNumber = '---';
        console.error('calStatusSave ERROR', response);
      }
    });
  }


  calStatusSave(calendarId, status) {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.getSelectedRole()['roleCode'],
        currentServicePointId: this.sharedDataService.getSelectedRole()['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calendarId: calendarId,
      status: status
    };

    return this.http.post(this.sharedDataService.callerSystemAPIURL + 'updatecalendar', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  openInvoice(calendarFk) {
    this.sharedApiService.getCalendarDetail(calendarFk).subscribe(data => {
      window.open(environment.nauccount + this.sharedDataService.getSelectedRole()['spId'] + '/' + this.authService.getUserData()['id'] + '/' + calendarFk + '/' + data['data']['participantId'], "_blank");
      //window.open("http://localhost:4200/billing/12403238/12406342/" + calendarFk + "/" + data['data']['participantId'], "_blank");
    });

  }

  closeError() {
    this.error = false;
    this.errorDescription = '';
  }
}
