<!-- Modal -->
<div class="modal" id="laborder" tabindex="-1" role="dialog" aria-labelledby="laborder" aria-hidden="true"
  backdrop="static">
  <div class="modal-dialog modal-lg " role="document">

    <!-- 
    <div [ngClass]="{'hidden': !closeConfirmationDialogueVisible}" id="quitConfirmation" class="quitConfirmation">
      <div class="quitConfirmationContent">
        <h6>Az adatok megtartása a későbbi folytatáshoz?</h6>
        <div class="col-md-12 mt-4">
          <div class="row">
            <div class="col-md-4">
              <button type="button" class="btn btn-primary" (click)="hideCloseConfirmationDialogue(true)"
                data-dismiss="modal">Igen</button>
            </div>
            <div class="col-md-4">
              <button type="button" class="btn btn-danger" data-dismiss="modal"
                (click)="resetModalAndHideDialogue()">Nem</button>
            </div>
            <div class="col-md-4">
              <button type="button" class="btn btn-secondary"
                (click)="hideCloseConfirmationDialogue(false)">Mégsem</button>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div [ngClass]="{'hidden': !operationInProgress}" class="quitConfirmation">
      <div class="quitConfirmationContent">
        {{ operationDescription }}
      </div>
    </div>

    <div class="modal-content modal-fh">
      <div class="modal-header">
        <div id="saveSuccess" [ngClass]="{'showResult': saveResult == 0}">
          Az adatok mentése sikeresen megtörtént!
        </div>

        <div id="saveError" [ngClass]="{'showResult': saveResult == -1}">
          <div class="closeResult" (click)="closeResult()">x</div>
          <div class="saveErrorContent">
            <span>{{ saveErrorDescription }}</span>
          </div>
        </div>
        <!-- 
        <div class="loadError" [ngClass]="{'showResult': loadResult == -1}">
          <div class="closeLoadResult" (click)="closeLoadResult()">x</div>
          <div class="saveErrorContent">Hiba az adatok betöltése során!
            <span>{{ loadErrorDescription }}</span>
          </div>
        </div>
         -->
        <h5 class="modal-title" id="exampleModalLongTitle">Labor</h5>
        <span>({{ patientName }}, {{ patientSsn }}, {{ patientDob }}, {{ patientMothersName }} )
        </span>

        <div>
          <button class="btn btn-primary ml-1 mb-1" (click)="openHelp('#care')" title="kézikönyv">
            <i class="fa fa-question-circle"></i>
          </button>
          <!-- <div *ngIf="editorDirty" type="button" class="btn btn-secondary mb-1 ml-1" (click)="closeModal()">X</div> -->
          <!-- <div type="button" class="btn btn-secondary mb-1 ml-1" (click)="resetModal()" data-dismiss="modal">X</div> -->
          <button class="btn btn-secondary mb-1 ml-1" (click)="resetModal()" data-dismiss="modal">X</button>
        </div>


      </div>

      <!-- Nav tabs section -->
      <div>
        <ul class="nav nav-tabs">
          <li class="nav-item" *ngIf="this.previousLaborder">
            <a class="nav-link" [ngClass]="{'active': activeTab == 2}" (click)="toSelectLabOrder()">Kiválasztás</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab == 0}" (click)="toLabOrderDetail()">Alapadatok
              <i class="fa fa-check-circle"
                [ngClass]="{'valid': labOrderDetails.valid, 'invalid': !labOrderDetails.valid}"></i>
            </a>
          </li>
          <li class="nav-item">
            <a *ngIf="this.labOrderDetails.valid" class="nav-link" [ngClass]="{'active': activeTab == 1}"
              (click)="toSelectLabExams()">Vizsgálatok</a>
            <a *ngIf="!this.labOrderDetails.valid" class="nav-link gray"
              [ngClass]="{'active': activeTab == 1}">Vizsgálatok</a>
          </li>
          <li class="nav-item">
            <a *ngIf="this.labOrderDetails.valid" class="nav-link" [ngClass]="{'active': activeTab == 3}"
              (click)="toInvoiceSummary()">Számlázási összesítő</a>
            <a *ngIf="!this.labOrderDetails.valid" class="nav-link gray"
              [ngClass]="{'active': activeTab == 3}">Számlázási összesítő</a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab == 2}" (click)="switchTab(2)">Dokumentum feltöltés</a>
          </li> -->
        </ul>
      </div>
      <!-- Nav tabs section end -->

      <!-- Nav body section -->
      <div class="modal-body">
        <div class="container-fluid">


          <div class="row" *ngIf="activeTab == 2">
            <div class="row mb-2">
              <form [formGroup]="labOrderGroup" role="form" class="form-group full-width">
                <label for="labOrderSelect" class="col-md-12 control-label">Korábbi laborvizsgálat kérés kiválasztása,
                  vagy új létrehozása</label>
                <div class="col-md-6 form-item">
                  <ng-select [searchable]="true" placeholder="Válasszon szolgáltatást..."
                    formControlName="labOrderSelect" [items]="previousLaborderList" bindLabel="visibleTitle"
                    [clearable]="false" loadingText="Betöltés..." notFoundText="Nincs találat">
                  </ng-select>


                </div>
                <div class="col-md-6">
                  <button type="button" class="btn btn-primary ml-2" (click)="selectPreviousLabOrder()">
                    Kiválasztás
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div class="row" *ngIf="activeTab == 0">

            <form [formGroup]="labOrderDetails" role="form" class="form-group full-width">
              <div class="row">
                <div class="col-sm-6">

                  <div class="row mb-2">
                    <label for="ldateofbirth" class="col-md-4 control-label">Születési idő<span
                        class="mandatory">*</span></label>
                    <div class="col-md-8 form-item">

                      <div class="input-group mr-1">
                        <input class="form-control datepicker_mobile" placeholder="yyyy-mm-dd" name="dp"
                          formControlName="ldateofbirth" ngbDatepicker #d="ngbDatepicker" [maxDate]="today"
                          [readOnly]="newPatient == false">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                            type="button"></button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-2">
                    <label for="lssnSearch" class="col-md-4 control-label">EÜ azon.szám<span class="mandatory">*</span>
                      <i class="fa fa-check-circle"
                        [ngClass]="{'inactive': !lssnSearch.dirty, 'valid': lssnSearch.valid, 'invalid': lssnSearch.valid === false}"></i>
                    </label>
                    <div class="col-md-8 form-item">
                      <input type="text" placeholder="" class="form-control" [formControl]="lssnSearch"
                        [readOnly]="newPatient == false">
                    </div>
                  </div>
                  <div class="row mb-2">
                    <label for="lsst" class="col-md-4 control-label">EÜ azonosító típus</label>
                    <div class="col-md-8 form-item">
                      <select class="form-control" id="lsst" [formControl]="lsst" disabled>
                        <option value="1">TAJ szám</option>
                        <option value="2">Gyermek TAJ szám</option>
                        <option value="3">Útlevélszám</option>
                        <option value="5">Befogadó igazolvány</option>
                        <option value="8">EU e-kártya</option>
                        <option value="9">Menekült azonosítója</option>
                        <option value="X">Egyéb okirat</option>
                      </select>
                      <!-- <div class="formError" *ngIf="lsst.invalid && formSubmitted">Válasszon okmány típust!</div> -->
                    </div>
                  </div>
                  <!-- <div class="row mb-2">
                    <div class="col-12">
                      <button type="button" class="btn btn-primary" (click)="check2T($event)"
                        [disabled]="!ldateofbirth || lssnSearch.value === ''">
                        Globális keresés 2T</button>
                    </div>
                  </div> -->
                  <div class="row mb-2">
                    <label for="lbirthname" class="col-md-4 control-label">Születési név<span
                        class="mandatory">*</span></label>
                    <div class="col-md-8 form-item">
                      <input type="text" id="lbirthname" placeholder="Születési név" class="form-control"
                        [formControl]="lbirthname" [readOnly]="newPatient == false">
                    </div>
                  </div>

                  <div class="row mb-2">
                    <label for="lname" class="col-md-4 control-label">Név<span class="mandatory">*</span></label>
                    <div class="col-md-8 form-item">
                      <input type="text" id="lname" placeholder="Név" class="form-control" [formControl]="lname"
                        [readOnly]="newPatient == false">
                    </div>
                  </div>

                  <div class="row mb-2">
                    <label for="lbirthplace" class="col-md-4 control-label">Születési hely<span
                        class="mandatory">*</span></label>
                    <div class="col-md-8 form-item">
                      <input type="text" id="lbirthplace" placeholder="Születési hely" class="form-control"
                        [formControl]="lbirthplace" [readOnly]="newPatient == false">
                    </div>
                  </div>



                  <div class="row mb-2">
                    <label for="lmothersname" class="col-md-4 control-label">Anyja neve<span
                        class="mandatory">*</span></label>
                    <div class="col-md-8 form-item">
                      <input type="text" id="lmothersname" placeholder="Anyja neve" class="form-control"
                        [formControl]="lmothersname" [readOnly]="newPatient == false">
                    </div>
                  </div>



                  <div class="row mb-2">
                    <label for="lgender" class="col-md-4 control-label">Nem</label>
                    <div class="col-md-8 form-item">
                      <input type="radio" value="M" class="form-control radio-item" [formControl]="lgender"
                        name="lgender">
                      <div class="form-control radio-item">Férfi</div>
                      <input type="radio" value="F" class="form-control  radio-item" [formControl]="lgender"
                        name="lgender">
                      <div class="form-control radio-item">Nő</div>
                    </div>
                  </div>

                  <div class="row mb-1">
                    <label for="lnationality" class="col-md-4 control-label">Állampolgárság</label>
                    <div class="col-md-8 form-item">
                      <select class="form-control" id="lnationality" [formControl]="lnationality">
                        <option ngValue="HUN">Magyar</option>
                      </select>
                    </div>
                  </div>


                  <div class="row mb-2">
                    <div class="col-sm-12">
                      <strong>Tartózkodási hely</strong>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <label for="lcountry" class="col-md-4 control-label">Ország</label>
                    <div class="col-md-8 form-item">
                      <ng-select class="full-width" [searchable]="true" placeholder="Válasszon szolgáltatást..."
                        formControlName="lcountry" [items]="countryList" bindLabel="name" bindValue="code"
                        [clearable]="false" loadingText="Betöltés..." notFoundText="Nincs találat">
                      </ng-select>
                    </div>
                  </div>

                  <div class="row mb-2">
                    <label for="lzip" class="col-md-4 control-label">Irányítószám</label>
                    <div class="col-md-8 form-item">
                      <input type="text" id="lzip" placeholder="Irányítószám" class="form-control" [formControl]="lzip"
                        [ngClass]="{'fc-invalid ': !lzip.valid}">
                    </div>
                  </div>

                  <div class="row mb-2">
                    <label for="lcity" class="col-md-4 control-label">Város</label>
                    <div class="col-md-8 form-item">
                      <input type="text" id="lcity" placeholder="Város" class="form-control" [formControl]="lcity"
                        [ngClass]="{'fc-invalid ': !lcity.valid}">
                    </div>
                  </div>

                  <div class="row mb-2">
                    <label for="laddress" class="col-md-4 control-label">Utca, házszám</label>
                    <div class="col-md-8 form-item">
                      <input type="text" id="laddress" placeholder="Utca, házszám" class="form-control"
                        [ngClass]="{'fc-invalid ': !laddress.valid}" [formControl]="laddress">
                    </div>
                  </div>

                </div>
                <!-- Left panel end -->
                <div class="col-sm-6">

                  <div class="row mb-1">
                    <label for="lelltip" class="col-md-4 control-label">Ellátás típusa</label>
                    <div class="col-md-8 form-item">
                      <input type="radio" value="O" class="form-control radio-item" [formControl]="lelltip"
                        name="lelltip">
                      <div class="form-control radio-item">Járó</div>
                      <input type="radio" value="I" class="form-control  radio-item" [formControl]="lelltip"
                        name="lelltip">
                      <div class="form-control radio-item">Fekvő</div>
                      <input type="radio" value="E" class="form-control  radio-item" [formControl]="lelltip"
                        name="lelltip">
                      <div class="form-control radio-item">Sürgősségi</div>
                      <input type="radio" value="C" class="form-control  radio-item" [formControl]="lelltip"
                        name="lelltip">
                      <div class="form-control radio-item">Kúra-szerű</div>
                    </div>
                  </div>

                  <div class="row mb-1">
                    <label for="lpecsetszam" class="col-md-4 control-label">Beküldő orvos pecsétszáma</label>
                    <div class="col-md-8 form-item">
                      <input type="text" id="lpecsetszam" placeholder="Pecsétszám" class="form-control"
                        [formControl]="lpecsetszam" [ngClass]="{'fc-invalid ': !lpecsetszam.valid}">
                    </div>
                  </div>

                  <div class="row mb-1">
                    <label for="lorvos" class="col-md-4 control-label">Beküldő orvos</label>
                    <div class="col-md-8 form-item">
                      <input type="text" id="orvos" placeholder="Orvos" class="form-control" [formControl]="lorvos"
                        [readOnly]="doctorLoading || existingDoctor" [ngClass]="{'fc-invalid ': !lorvos.valid}">
                    </div>
                  </div>

                  <div class="row mb-1">
                    <label for="lantszselect" class="col-md-4 control-label">Beküldő intézmény</label>
                    <div class="col-md-8 form-item">
                      <ng-select class="full-width" [searchable]="true" placeholder="Válasszon intézményt..."
                        formControlName="lantszselect" [items]="lintezmenyLista" bindLabel="intezmenyDisplayName"
                        loadingText="Betöltés..." notFoundText="Nincs találat" [clearable]="false"
                        [loading]="doctorLoading" (change)="antszChange()"
                        [ngClass]="{'fc-invalid ': !lantszselect.valid}">
                      </ng-select>
                      <!-- <div class="formError" *ngIf="lantsz.invalid && formSubmitted">Nem megfelelő ANTSZ kód
                      </div> -->
                    </div>
                  </div>

                  <div class="row mb-1" *ngIf="newantsz">
                    <label for="lantsz" class="col-md-4 control-label">Beküldő intézmény ANTSZ</label>
                    <div class="col-md-8 form-item">
                      <input type="text" id="lantsz" placeholder="ANTSZ" class="form-control" [formControl]="lantsz"
                        [ngClass]="{'fc-invalid ': !lantsz.valid}">
                      <!-- <div class="formError" *ngIf="lantsz.invalid && formSubmitted">Nem megfelelő ANTSZ kód
                      </div> -->
                    </div>
                  </div>

                  <div class="row mb-1" *ngIf="newantsz">
                    <label for="lintezmeny" class="col-md-4 control-label">Beküldő intézmény neve</label>
                    <div class="col-md-8 form-item">
                      <input type="text" id="lintezmeny" placeholder="Intézmény" class="form-control"
                        [formControl]="lintezmeny" [readOnly]="!newInstitute"
                        [ngClass]="{'fc-invalid ': !lintezmeny.valid}">
                      <!-- <div class="formError" *ngIf="lintezmeny.invalid && formSubmitted">Nem megfelelő intézménynév
                      </div> -->
                    </div>
                  </div>

                  <div class="row mb-1">
                    <label for="lterkat" class="col-md-4 control-label">Térítési kategória</label>
                    <div class="col-md-8 form-item">
                      <ng-select class="full-width" [searchable]="true" placeholder="Térítési kategória..."
                        formControlName="lterkat" [items]="terkatItems" bindLabel="name" bindValue="code"
                        [clearable]="false" loadingText="Betöltés..." notFoundText="Nincs találat">
                      </ng-select>
                      <!-- <div class="formError" *ngIf="lterkat.invalid && formSubmitted">Nem megfelelő ANTSZ kód
                      </div> -->
                    </div>
                  </div>

                  <div class="row mb-1">
                    <label for="lbeutaloKelte" class="col-md-4 control-label">Beutaló kelte
                      <i class="fa fa-check-circle" [ngClass]="{'invalid ': dateInvalid && lterkat.value == 1}"></i>
                    </label>
                    <div class="col-md-8 form-item">

                      <div class="input-group mr-1">
                        <input class="form-control datepicker_mobile" placeholder="yyyy-mm-dd" name="lbeutaloKelte"
                          formControlName="lbeutaloKelte" ngbDatepicker #e="ngbDatepicker" [maxDate]="today"
                          [ngClass]="{'fc-invalid': dateInvalid && lterkat.value == 1}">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="e.toggle()"
                            type="button"></button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="showPatientBeuFields || showInstitutionalBeuFields" class="row mb-1">

                    <label for="referralIdentification" class="col-md-4 control-label">
                      <span *ngIf="showPatientBeuFields && !showInstitutionalBeuFields">Páciens beutaló azonosító</span>
                      <span *ngIf="showInstitutionalBeuFields && !showPatientBeuFields">Intézményi beutaló
                        azonosító</span>
                      <span *ngIf="showInstitutionalBeuFields && showPatientBeuFields">beutaló azonosító</span>
                    </label>
                    <div class="col-md-8 form-item">
                      <input type="text" id="beutAzon" disabled class="form-control"
                        formControlName="referralIdentification">
                    </div>
                  </div>


                  <div class="row mb-1">
                    <label for="lnaploszam" class="col-md-4 control-label">Naplószám/törzsszám</label>
                    <div class="col-md-8 form-item">
                      <input type="text" id="lnaploszam" placeholder="Naplószám" class="form-control"
                        [formControl]="lnaploszam" [ngClass]="{'fc-invalid ': !lnaploszam.valid}">
                      <!-- <div class="formError" *ngIf="lnaploszam.invalid && formSubmitted">Nem megfelelő ANTSZ kód
                      </div> -->
                    </div>
                  </div>
                  <!-- 

                  <div class="row">
                    <label class="col-sm-12 mb-0">BNO:</label>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 form-item mb-2">
                      <ng-select class="full-width" [searchable]="true" placeholder="Beavatkozás oka (kategória)"
                        [items]="bnoList" formControlName="bnoCat0" bindLabel="visibleData" bindValue="id"
                        [loading]="bnoListLoading" (change)="onChangeBNOCategory($event,0, null)" loadingText="Betöltés..."
                        notFoundText="Nincs találat">
                      </ng-select>
                    </div>

                    <div class="col-sm-6 form-item mb-2">
                      <ng-select class="full-width" [searchable]="true" placeholder="Beavatkozás oka"
                        formControlName="bno0" [items]="bnoItemList[0]" bindLabel="visibleData"
                        [loading]="bnoItemListLoading[0]" loadingText="Betöltés..." notFoundText="Nincs találat"
                        (change)="onChangeBNO($event,0)"
                        [ngClass]="{'fc-invalid ': bno[0].valid === false}">
                      </ng-select>
                    </div>
                  </div>


                  <div class="row">
                    <div class="col-sm-6 form-item mb-2">
                      <ng-select class="full-width" [searchable]="true" placeholder="Beavatkozás oka (kategória)"
                        [items]="bnoList" formControlName="bnoCat1" bindLabel="visibleData" bindValue="id"
                        [loading]="bnoListLoading" (change)="onChangeBNOCategory($event,1, null)" loadingText="Betöltés..."
                        notFoundText="Nincs találat">
                      </ng-select>
                    </div>

                    <div class="col-sm-6 form-item mb-2">
                      <ng-select class="full-width" [searchable]="true" placeholder="Beavatkozás oka"
                        formControlName="bno1" [items]="bnoItemList[1]" bindLabel="visibleData"
                        [loading]="bnoItemListLoading[1]" loadingText="Betöltés..." notFoundText="Nincs találat">
                      </ng-select>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-6 form-item mb-2">
                      <ng-select class="full-width" [searchable]="true" placeholder="Beavatkozás oka (kategória)"
                        [items]="bnoList" formControlName="bnoCat2" bindLabel="visibleData" bindValue="id"
                        [loading]="bnoListLoading" (change)="onChangeBNOCategory($event,2, null)" loadingText="Betöltés..."
                        notFoundText="Nincs találat">
                      </ng-select>
                    </div>
 
                    <div class="col-sm-6 form-item mb-2">
                      <ng-select class="full-width" [searchable]="true" placeholder="Beavatkozás oka"
                        formControlName="bno2" [items]="bnoItemList[2]" bindLabel="visibleData"
                        [loading]="bnoItemListLoading[2]" loadingText="Betöltés..." notFoundText="Nincs találat">
                      </ng-select>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-6 form-item mb-2">
                      <ng-select class="full-width" [searchable]="true" placeholder="Beavatkozás oka (kategória)"
                        [items]="bnoList" formControlName="bnoCat3" bindLabel="visibleData" bindValue="id"
                        [loading]="bnoListLoading" (change)="onChangeBNOCategory($event,3, null)" loadingText="Betöltés..."
                        notFoundText="Nincs találat">
                      </ng-select>
                    </div>

                    <div class="col-sm-6 form-item mb-2">
                      <ng-select class="full-width" [searchable]="true" placeholder="Beavatkozás oka"
                        formControlName="bno4" [items]="bnoItemList[3]" bindLabel="visibleData"
                        [loading]="bnoItemListLoading[3]" loadingText="Betöltés..." notFoundText="Nincs találat">
                      </ng-select>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-6 form-item mb-2">
                      <ng-select class="full-width" [searchable]="true" placeholder="Beavatkozás oka (kategória)"
                        [items]="bnoList" formControlName="bnoCat4" bindLabel="visibleData" bindValue="id"
                        [loading]="bnoListLoading" (change)="onChangeBNOCategory($event,4, null)" loadingText="Betöltés..."
                        notFoundText="Nincs találat">
                      </ng-select>
                    </div>

                    <div class="col-sm-6 form-item mb-2">
                      <ng-select class="full-width" [searchable]="true" placeholder="Beavatkozás oka"
                        formControlName="bno4" [items]="bnoItemList[4]" bindLabel="visibleData"
                        [loading]="bnoItemListLoading[4]" loadingText="Betöltés..." notFoundText="Nincs találat">
                      </ng-select>
                    </div>
                  </div> 
                </div> 
                -->

                  <div class="row">
                    <div class="col-12 form-item mb-2">
                      BNO
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 form-item mb-2">
                      <ng-select class="full-width" [searchable]="true" placeholder="Beavatkozás oka"
                        formControlName="bno0" [items]="bnoItemListFiltered[0]" bindLabel="visibleData"
                        [loading]="bnoItemListLoading[0]" loadingText="Betöltés..." notFoundText="Nincs találat"
                        [typeahead]="bnoTextInput0">
                      </ng-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 form-item mb-2">
                      <ng-select class="full-width" [searchable]="true" placeholder="Beavatkozás oka"
                        formControlName="bno1" [items]="bnoItemListFiltered[1]" bindLabel="visibleData"
                        [loading]="bnoItemListLoading[1]" loadingText="Betöltés..." notFoundText="Nincs találat"
                        [typeahead]="bnoTextInput1">
                      </ng-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 form-item mb-2">
                      <ng-select class="full-width" [searchable]="true" placeholder="Beavatkozás oka"
                        formControlName="bno2" [items]="bnoItemListFiltered[2]" bindLabel="visibleData"
                        [loading]="bnoItemListLoading[2]" loadingText="Betöltés..." notFoundText="Nincs találat"
                        [typeahead]="bnoTextInput2">
                      </ng-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 form-item mb-2">
                      <ng-select class="full-width" [searchable]="true" placeholder="Beavatkozás oka"
                        formControlName="bno3" [items]="bnoItemListFiltered[3]" bindLabel="visibleData"
                        [loading]="bnoItemListLoading[3]" loadingText="Betöltés..." notFoundText="Nincs találat"
                        [typeahead]="bnoTextInput3">
                      </ng-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 form-item mb-2">
                      <ng-select class="full-width" [searchable]="true" placeholder="Beavatkozás oka"
                        formControlName="bno4" [items]="bnoItemListFiltered[4]" bindLabel="visibleData"
                        [loading]="bnoItemListLoading[4]" loadingText="Betöltés..." notFoundText="Nincs találat"
                        [typeahead]="bnoTextInput4">
                      </ng-select>
                    </div>
                  </div>


                  <div class="row mb-1">
                    <label for="lcomment" class="col-md-4 control-label">Megjegyzés</label>
                    <div class="col-md-8 form-item">
                      <textarea id="lcomment" class="form-control" [formControl]="lcomment">
                      </textarea>
                    </div>
                  </div>


                  <div class="row mb-1">
                    <div class="col-md-6 form-item">

                    </div>
                    <div class="col-md-6 text-right">
                      <!-- <button class="btn btn-success" (click)="test(12731021)">teszt</button> -->
                      <button class="btn btn-success" (click)="toSelectLabExams()"
                        [disabled]="!this.labOrderDetails.valid">Tovább</button>
                      <!-- <button class="btn btn-success" (click)="logFormGroup()">Log</button> -->
                      <!-- [disabled]="!labOrderDetails.valid" -->
                    </div>
                  </div>

                </div>
                <!-- Right panel End -->


              </div>

            </form>


            <!-- EBEU Lekérdezés -->
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <div class="mb-2 ml-5">
                    <input type="checkBox" [formControl]="showPatientBeuFormControl" (change)="showPatientBeu()"
                      class="mr-1">
                    <span>páciens beutaló csatolása</span>
                  </div>
                  <div class="mb-2 ml-5">
                    <input type="checkBox" [formControl]="showInstitutionalBeuFormControl"
                      (change)="showInstitutionalBeu()" class="mr-1">
                    <span>intézményi beutaló csatolása</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-12" [ngClass]="{'hidden': !showPatientBeuFields && !showInstitutionalBeuFields}"
                  [formGroup]="patientBeuFormGroup">
                  <div class="row">
                    <div class="col-sm">
                      <label for="patientDateFrom">tól</label>
                      <div class="input-group mr-1">
                        <input class="form-control datepicker_mobile" placeholder="yyyy-mm-dd" name="dp"
                          formControlName="patientDateFrom" ngbDatepicker #dFrom="ngbDatepicker">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="dFrom.toggle()"
                            type="button"></button>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm">
                      <label for="patientDateto">ig</label>
                      <div class="input-group mr-1">
                        <input class="form-control datepicker_mobile" placeholder="yyyy-mm-dd" name="dp"
                          formControlName="patientDateTo" ngbDatepicker #dTo="ngbDatepicker">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="dTo.toggle()"
                            type="button"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 form-group">
                      <input type="checkBox" id="breakGlass" formControlName="breakGlass">
                      <label for="breakGlass" class="ml-1">sürgősségi adatlekérés</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 form-group">
                      <label for="breakGlassReason" class="ml-1">sürgősségi adatlekérés indoklása</label>
                      <input type="text" id="breakGlassReason" class="form-control" formControlName="breakGlassReason">
                    </div>
                    <div class="col-sm-6 form-group">
                      <div *ngIf="this.breakGlass.value" class="alert alert-danger" role="alert"
                        style="text-align: justify; text-justify: inter-word;">
                        A "sürgősségi adatlekérés" csak a következő esetekben használható jogszerűen:<br>
                        1.) Sürgős esetben, ha a páciens nem tud, nem képes eseti rendelkezést tenni. (Az Eüak. 10. §
                        (4)
                        bekezdése szerint sürgős szükség esetén a kezelést végző orvos által ismert, a gyógykezeléssel
                        összefüggésbe hozható minden egészségügyi és személyazonosító adat továbbítható.) <br>
                        2.) A páciens 24 órás egyedi engedélye alapján (minta az e-egeszegugy.gov.hu oldalon
                        letölthető).
                      </div>
                    </div>
                  </div>
                  <button class="btn btn-secondary" (click)="getPatientBeuDocumentList()">
                    <i *ngIf="ehrDetailsInProgress" class="fa fa-spinner fa-spin"></i>Keresés</button>

                    <mat-table *ngIf="patientEbeuList" [dataSource]="patientEbeuList" matSort>
                    <ng-container matColumnDef="patientName">
                      <mat-header-cell *matHeaderCellDef> beteg neve </mat-header-cell>
                      <mat-cell *matCellDef="let element"> {{element.businessContent.beteg.nev.firstName + ' ' +
                        element.businessContent.beteg.nev.lastName}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="patientSsn">
                      <mat-header-cell mat-header-cell *matHeaderCellDef> beteg tajszáma </mat-header-cell>
                      <mat-cell *matCellDef="let element"> {{element.businessContent.beteg.taj}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="organizationName">
                      <mat-header-cell *matHeaderCellDef> beutaló intézmény neve </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        {{element.businessContent.beutaloIntezmeny.intezmenyMegnevezes}}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="doctorName">
                      <mat-header-cell *matHeaderCellDef> beutaló orvos </mat-header-cell>
                      <mat-cell *matCellDef="let element"> {{element.businessContent.beutaloOrvos.firstName + ' ' +
                        element.businessContent.beutaloOrvos.lastName}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="docStampNo">
                      <mat-header-cell *matHeaderCellDef> beutaló pecsétszáma </mat-header-cell>
                      <mat-cell *matCellDef="let element"> {{element.businessContent.beutaloOrvos.stampNo}}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="createDate">
                      <mat-header-cell *matHeaderCellDef> kiállítá dátuma </mat-header-cell>
                      <mat-cell *matCellDef="let element"> {{element.businessContent.kiallitasDatuma | date:
                        'yyyy/MM/dd'}}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="expirationDate">
                      <mat-header-cell *matHeaderCellDef> érvényesség ideje </mat-header-cell>
                      <mat-cell *matCellDef="let element"> {{element.businessContent.ervenytessegDatuma | date:
                        'yyyy/MM/dd'}}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="copyReferralId">
                      <mat-header-cell *matHeaderCellDef> beutaló azonosító kiválasztása </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        <fa-icon class="selectIdIcon" (click)="copyReferralId(element)" [icon]="faCopy"></fa-icon>
                      </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumnsEBEU"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsEBEU;"></mat-row>
                  </mat-table>
                </div>


              </div>

            </div>
          </div>





          <div class="row" *ngIf="activeTab == 1">
            <div class="col-sm-8">
              <div class="row">
                <div class="col-sm-6">
                  <input type="text" autofocus class="form-control" [formControl]="labExamId" #labExamIdSearchField
                    placeholder="ID" (keyup.enter)="addLabExamById()">
                </div>
                <div class="col-sm-6">
                  <input type="text" placeholder="Név" autofocus class="form-control" [formControl]="labExamName"
                    #labExamNameSearchField (keyup.enter)="addLabExamByName()">
                </div>
              </div>
              <div class="row mt-1">
                <span class="small mr-1 mb-1">Vizsgálatok</span>
                <span class="badge badge-secondary mr-1 mb-1 small"
                  *ngFor="let selectedExamItem  of selectedLabExamItemList; let s = index">
                  {{ selectedExamItem['examCode'] }}
                </span>
              </div>
              <div class="row">
                <div class="col-sm-2 small notSelectedLabExam list_h"></div>
                <div class="col-sm-1 small notSelectedLabExam list_h">0</div>
                <div class="col-sm-1 small notSelectedLabExam list_h">1</div>
                <div class="col-sm-1 small notSelectedLabExam list_h">2</div>
                <div class="col-sm-1 small notSelectedLabExam list_h">3</div>
                <div class="col-sm-1 small notSelectedLabExam list_h">4</div>
                <div class="col-sm-1 small notSelectedLabExam list_h">5</div>
                <div class="col-sm-1 small notSelectedLabExam list_h">6</div>
                <div class="col-sm-1 small notSelectedLabExam list_h">7</div>
                <div class="col-sm-1 small notSelectedLabExam list_h">8</div>
                <div class="col-sm-1 small notSelectedLabExam list_h">9</div>
              </div>
              <div class="row" *ngFor="let labExamRow of labExamList; let i = index">
                <div class="col-sm-12" *ngIf="labExamRow">
                  <div class="row">
                    <div class="col-sm-2 small notSelectedLabExam list_h">{{ labExamRow['rowNumber'] }}</div>

                    <div *ngFor="let labExam of labExamRow['data']; let i = index"
                      class="col-sm-1 small notSelectedLabExam" (click)="selectLabExam(labExamRow['rowNumber'] + i, 1)"
                      [ngClass]="{'selectedLabExam': selectedLabExams[labExamRow['rowNumber'] + i] === true}">

                      <button class="btn btn-secondary  btn-success btn-labexamitem"
                        *ngIf="labExamIdSearch == labExam['extExamCode'] && labExamIdSearch !== null || filteredLabExams.indexOf(labExam['extExamCode']) > -1">
                        <span style="font-size: 60%"
                          [title]="labExam['examName'] + ' &#13;Ár: ' + (labExam['grossPrice'] ? labExam['grossPrice'] : 0 ) + ' Ft'">
                          {{ labExam['examCode'] }}
                        </span>
                      </button>
                      <div
                        *ngIf="(labExamIdSearch != labExam['extExamCode'] || labExamIdSearch == null) && (filteredLabExams.indexOf(labExam['extExamCode']) == -1 || filteredLabExam == false)"
                        [title]="labExam['examName'] + ' &#13;Ár: ' + ( labExam['grossPrice'] ? labExam['grossPrice'] : 0 )+ ' Ft'">
                        <small [ngClass]="{'bold': selectedLabExams[labExamRow['rowNumber'] + i] === true}">
                          {{ labExam['examCode'] }}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>


              </div>

            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-6 text-left">
                  <button class="btn btn-danger" (click)="clearSelectedExams()"
                    [disabled]="selectedExamCount == 0">Törlés</button>
                </div>
                <div class="col-md-6 text-right">
                  <button class="btn btn-success" (click)="sendLabOrder()"
                    [disabled]="selectedExamCount == 0 || !labOrderDetails.valid || !lterapia.valid">
                    <span *ngIf="nauRequestId === null">
                      Mentés
                    </span>
                    <span *ngIf="nauRequestId !== null">
                      Módosítás
                    </span>
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mt-md-3" *ngIf="lterkat.value == 4">
                  <span>
                    Összesen: {{ totalPrice | number :'1.0-0' }} Ft
                  </span>
                </div>
                <div class="col-md-12 mt-md-3" *ngIf="therapyRequired">
                  <form [formGroup]="lterapiaGroup" role="form" class="form-group full-width">
                    A terápia megadása kötelező!
                    <ng-select class="full-width" [searchable]="true" placeholder="Válasszon terápiát..."
                      formControlName="lterapia" [items]="lTerapiaTipusok" [clearable]="false" bindLabel="name"
                      loadingText="Betöltés..." notFoundText="Nincs találat">
                    </ng-select>
                  </form>
                </div>
                
                <div class="col-md-12 mt-md-3" *ngIf="eBeutaloSzoveg">
                  <h4>Beutaló szöveg</h4>
                  {{ eBeutaloSzoveg }}
                </div>

                <div class="col-md-12 mt-md-3">
                  <h4>Panelek</h4>
                </div>

              </div>

              <div class="row" *ngFor="let labPanelRow of labPanelList; let h = index">
                <div class="col-sm-12 mb-1 mt-1">
                  <button class="btn btn-primary full-width btn-wrap" (click)="selectLabPanel(h)">
                    <i class="fa fa-check-circle mr-1"
                      *ngIf="selectedPanels[h] && selectedPanels[h].selected && selectedPanels[h].selected === true"></i>
                    {{ labPanelRow['panelName'] }}</button>
                </div>
              </div>
            </div>
          </div>




          <div class="row" *ngIf="activeTab == 3">
            <div class="col-12 mb-2">
              <h4>Páciens adatai:</h4>
              <p> {{ lname.value }} <br />
                {{ laddress.value }} <br />
                {{ lcity.value }} <br />
                {{ lzip.value }} </p>
            </div>
            <div class="col-12 mb-2">
              <h4>Panelek:</h4>
            </div>
            <div class="col-12 mb-2">
              <mat-table [dataSource]="invoicePanelList" matSort id="print-section">
                <ng-container matColumnDef="examName">
                  <mat-header-cell *matHeaderCellDef> Megnevezés </mat-header-cell>
                  <mat-cell *matCellDef="let element"><span class="ellipsis">
                      {{element.details.panelName}}
                    </span>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="examCode">
                  <mat-header-cell *matHeaderCellDef> Kód </mat-header-cell>
                  <mat-cell *matCellDef="let element"><span class="ellipsis">
                      {{element.details.panelCode}}
                    </span>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="grossPrice">
                  <mat-header-cell *matHeaderCellDef> Ár </mat-header-cell>
                  <mat-cell *matCellDef="let element"><span class="ellipsis">
                      {{ element.details.grossPrice ? element.details.grossPrice : 0 }} Ft
                    </span>
                  </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>
            </div>
            <div class="col-12 mb-2">
              <h4>Vizsgálatok:</h4>
            </div>
            <div class="col-12 mb-2">
              <mat-table [dataSource]="invoiceItemList" matSort id="print-section">
                <ng-container matColumnDef="examName">
                  <mat-header-cell *matHeaderCellDef> Megnevezés </mat-header-cell>
                  <mat-cell *matCellDef="let element"><span class="ellipsis">
                      {{element.examName}}
                    </span>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="examCode">
                  <mat-header-cell *matHeaderCellDef> Kód </mat-header-cell>
                  <mat-cell *matCellDef="let element"><span class="ellipsis">
                      {{element.examCode}}
                    </span>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="grossPrice">
                  <mat-header-cell *matHeaderCellDef> Ár </mat-header-cell>
                  <mat-cell *matCellDef="let element"><span class="ellipsis">
                      {{ element.grossPrice ? element.grossPrice : 0 }} Ft
                    </span>
                  </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>
            </div>
            <div class="col-12">
              <strong>
                Összesen: {{ totalPrice | number :'1.0-0' }} Ft
              </strong>
            </div>
          </div>






        </div>
      </div>
      <!-- Nav body section end -->

    </div>
  </div>
</div>