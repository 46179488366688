import { CheckEquiomentIsFreeReq } from './../../book-appointment/book-appointment';
import { SaveAppointmentWithRulerResponse, SaveAppointmentWithRuleRequest } from './../../shared/models/request/saveAppointmentRequest';
import { FindByCsIdResponse } from './../../shared/models/response/findbyCsIdResponse';
import { GetTreeForCsidResponse } from './../../shared/models/response/getTreeForCsidResponse';
import { SaveAppointmentRequest } from './../../shared/models/request/saveAppointmentsRequest';
import { BaseResponse } from './../../shared/models/response/baseResponse';
import { UpdateCalendarRequest } from './../../shared/models/request/updateCalendarRequest';
import { CallPatientResponse } from './../../shared/models/response/callPatientResponse';
import { CallPatientRequest } from './../../shared/models/request/callPatientRequest';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginResponse } from 'src/app/shared/models/response/loginResponse';
import { GetAllDevicesOfInstituteResponse } from 'src/app/shared/models/response/getAllDevicesOfInstituteResponse';
import { GetWorkListResponse } from 'src/app/shared/models/response/getWorkListResponse';
import { ArrivePatientResponse } from 'src/app/shared/models/response/arrivePatientResponse';
import { ArrivePatientRequest } from 'src/app/shared/models/request/arrivePatientRequest';
import { PatientEhrListResponse } from 'src/app/shared/models/response/patientEhrListResponse';
import { PatientEhrRequest } from 'src/app/shared/models/request/patientEhrRequest';
import { PatientEbeuRequest } from 'src/app/shared/models/request/patientEbeuRequest';
import { EproMapResponse } from 'src/app/shared/models/response/eproMapResponse';
import { PatientEkatListResponse } from 'src/app/shared/models/response/patientEkatListResponse';
import { SearchEbeuResponse } from 'src/app/shared/models/response/searchEbeuResponse';
import { UpdateEproRequest } from 'src/app/shared/models/request/updateEproRequest';
import { GetTorElementsGroupResponse } from 'src/app/shared/models/response/getTorElementsGroupResponse';
import { GetTorElementsTypeResponse } from 'src/app/shared/models/response/getTorElementsTypeResponse';
import { GetTorElementsCatResponse } from 'src/app/shared/models/response/geTorElementsCatResponse';
import { getTorElementsCertResponse } from 'src/app/shared/models/response/getTorElementsCertResponse';
import { GetPatientFeterDataResponse } from 'src/app/shared/models/response/getPatientFeterDataResponse';
import { MessageHeader } from 'src/app/shared/models/messageHeader';
import { DeleteEkatRequest } from 'src/app/shared/models/request/deleteEkatRequest';
import { EproElementRequest } from 'src/app/shared/models/request/eproElementRequest';
import { EkatEventEntryRequest } from 'src/app/shared/models/request/ekatEventEntryRequest';
import { EkatEventEntryResponse } from 'src/app/shared/models/response/ekatEventEntryResponse'
import { GetDocumentByEesztIdRequest } from 'src/app/shared/models/request/getDocumentByEesztIdRequest';
import { EhrDocumentByEesztIdResponse } from 'src/app/shared/models/response/ehrDocumentByEesztIdResponse';
import { EhrEesztIdRequest } from 'src/app/shared/models/request/ehrEesztIdRequest';
import { OtpTokenRequest } from 'src/app/shared/models/request/otpTokenRequest';
import { MobilTokenRequest } from 'src/app/shared/models/request/mobilTokenRequest';
import { UpdateEesztDetailsReques } from 'src/app/shared/models/request/UpdateEesztDetailsRequest';
import { GetBnoListResponse } from 'src/app/shared/models/response/getBnoListResponse';
import { RssResponse } from 'src/app/shared/models/response/rssResponse';
import { GetEesztUserDetailResponse } from 'src/app/shared/models/response/getEesztUserDetailResponse';
import { DeleteEkatEesztIdRequest } from 'src/app/shared/models/request/deleteEkatEesztIdRequest';
import { SaveAppointmentWeeklyRequest } from 'src/app/shared/models/request/saveAppointmentRequest';
import { SaveAdmissionRequest } from 'src/app/shared/models/request/saveAdmissionRequest';
import { GetAdmissionRequest } from 'src/app/shared/models/request/getAdmissionRequest';
import { Observable } from 'rxjs';
import { SaveAndReturnDocumentEeszt } from 'src/app/shared/models/request/saveAndReturnDocumentEesztRequest';
import { PrescriptionAuthenticationRequest } from 'src/app/shared/models/request/prescriptionAuthenticationRequest';
import { GetPatientReservationsResponse } from '../../shared/models/response/get-patient-reservations-response';
import { GetPatientReservationRequest } from '../../shared/models/request/get-patient-reservation-request';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  environment = environment;

  constructor(
    private http: HttpClient
  ) { }

  doLogin(username: string, password: string, uuid: number) {
    return this.http.get<LoginResponse>(this.environment.naucommUrl + 'rest/neu/authentication/loginusermobile/'
      + username + '/' + password + '/' + uuid + '/hu');
  }

  getAllDevicesOfInstitute(nauId: string) {
    return this.http.get<GetAllDevicesOfInstituteResponse>
      (this.environment.callerSystemUrl + 'rest/authority/getalldevicesofinstitute/' + nauId);
  }

  getWorkList(csId) {
    return this.http.get<Array<GetWorkListResponse>>(this.environment.callerSystemUrl + 'rest/getworklist/' + csId);
  }

  arrivePatient(request: ArrivePatientRequest) {
    return this.http.post<ArrivePatientResponse>(this.environment.callerSystemUrl + 'rest/arrivepatient', request, {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json' }
      )
    });
  }

  callPatient(request: CallPatientRequest) {
    return this.http.post<CallPatientResponse>(this.environment.callerSystemUrl + 'rest/callpatient', request, {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json' }
      )
    });
  }

  updateCalendar(request: UpdateCalendarRequest) {
    return this.http.post<BaseResponse>(this.environment.callerSystemUrl + 'rest/updatecalendar', request, {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json' }
      )
    });
  }

  saveAppointment(request: SaveAppointmentRequest) {
    return this.http.post<BaseResponse>(this.environment.callerSystemUrl + 'rest/save/appointment', request, {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json' }
      )
    });
  }

  saveAppointmentForParent(request: SaveAppointmentRequest) {
    return this.http.post<BaseResponse>(this.environment.callerSystemUrl + 'rest/save/appointmentforparent', request, {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json' }
      )
    });
  }

  getTreeForCsId(csId: number) {
    return this.http.get<Array<GetTreeForCsidResponse>>(this.environment.callerSystemUrl + 'rest/authority/gettreeforcsid/' + csId);
  }

  findbyCsid(csId: number) {
    return this.http.get<FindByCsIdResponse>(this.environment.callerSystemUrl + 'rest/authority/findbycsid/' + csId);
  }

  printCallNumber(params) {
    return this.http.post(this.environment.callerSystemUrl + 'rest/printer/print', params, {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json' }
      )
    });
  }

  //Naucare

  getEhrDocumentList(params: PatientEhrRequest) {
    return this.http.post<PatientEhrListResponse>(this.environment.naucommUrl + 'rest/ecm/ehr/getdocumentlist', params, {
      headers: new HttpHeaders(
        { 'content-Type': 'application/json' }
      )
    });
  }

  getEkatDocumentList(params: PatientEhrRequest) {
    return this.http.post<PatientEkatListResponse>(this.environment.naucommUrl + 'rest/ecm/ekat/getentriesofpatient/minimal', params, {
      headers: new HttpHeaders(
        { 'content-Type': 'application/json' }
      )
    });
  }

  searchEbeuMinimal(params: PatientEbeuRequest) {
    return this.http.post<SearchEbeuResponse>(this.environment.naucommUrl + 'rest/ecm/ebeu/searchebeutalo/minimal', params, {
      headers: new HttpHeaders(
        { 'content-Type': 'application/json' }
      )
    });
  }

  getEproVisualDetails() {
    return this.http.get<EproMapResponse>(this.environment.naucommUrl + 'rest/ecm/epro/geteprovisualdetails', {
      headers: new HttpHeaders(
        { 'content-Type': 'application/json' }
      )
    });
  }

  updateEpro(params: UpdateEproRequest) {
    return this.http.post<any>(this.environment.naucommUrl + 'rest/ecm/epro/updateadat/minimal', params, {
      headers: new HttpHeaders(
        { 'content-Type': 'application/json' }
      )
    });
  }

  getTorElementsGroup(typeName) {
    return this.http.get<GetTorElementsGroupResponse>(this.environment.naucommUrl + 'rest/ecm/epro/gettorelements/' + typeName, {
      headers: new HttpHeaders(
        { 'content-Type': 'application/json' }
      )
    });
  }

  getTorElementsType(typeName) {
    return this.http.get<GetTorElementsTypeResponse>(this.environment.naucommUrl + 'rest/ecm/epro/gettorelements/' + typeName, {
      headers: new HttpHeaders(
        { 'content-Type': 'application/json' }
      )
    });
  }

  getTorElementsCat(typeName) {
    return this.http.get<GetTorElementsCatResponse>(this.environment.naucommUrl + 'rest/ecm/epro/gettorelements/' + typeName, {
      headers: new HttpHeaders(
        { 'content-Type': 'application/json' }
      )
    });
  }

  getTorElementsCert(typeName) {
    return this.http.get<getTorElementsCertResponse>(this.environment.naucommUrl + 'rest/ecm/epro/gettorelements/' + typeName, {
      headers: new HttpHeaders(
        { 'content-Type': 'application/json' }
      )
    });
  }

  getPatientFeterData(patientData) {
    return this.http.post<GetPatientFeterDataResponse>(this.environment.naucommUrl +
      'rest/neu/authentication/getpatientminimal',
      patientData, {
      headers: new HttpHeaders(
        { 'content-Type': 'application/json' }
      )
    });
  }

  getFeterIdBySp(messageHeader: MessageHeader) {
    return this.http.post<any>(this.environment.naucommUrl + 'rest/neu/authentication/getfeteridbysp', messageHeader, {
      headers: new HttpHeaders(
        { 'content-Type': 'application/json' }
      )
    });
  }

  getPrescriptionToken(prescriptionAuthenticationRequest: PrescriptionAuthenticationRequest): Observable<any> {
    return this.http.post<any>(this.environment.prescriptionSecurity + 'rest/token/authenticate/prescription', prescriptionAuthenticationRequest);
  }

  deleteEkat(ekatElement: DeleteEkatRequest) {
    return this.http.post<any>(this.environment.naucommUrl + 'rest/ecm/ekat/delete/minimal', ekatElement, {
      headers: new HttpHeaders(
        { 'content-Type': 'application/json' }
      )
    });
  }

  deleteEpro(eProelement: EproElementRequest) {
    return this.http.post<any>(this.environment.naucommUrl + 'rest/ecm/epro/deleteadat/minimal', eProelement, {
      headers: new HttpHeaders(
        { 'content-Type': 'application/json' }
      )
    });
  }

  deleteEhrDocument(ehrEesztId: EhrEesztIdRequest) {
    return this.http.post<any>(this.environment.naucommUrl + 'rest/ecm/ehr/deletedocument', ehrEesztId, {
      headers: new HttpHeaders(
        { 'content-Type': 'application/json' }
      )
    });
  }

  getEkatEventEntry(ekatEventEntry: EkatEventEntryRequest) {
    return this.http.post<EkatEventEntryResponse>(this.environment.naucommUrl + 'rest/ecm/ekat/getevententry', ekatEventEntry, {
      headers: new HttpHeaders(
        { 'content-Type': 'application/json' }
      )
    });
  }

  getEhrDocumentByEesztId(eesztId: GetDocumentByEesztIdRequest) {
    return this.http.post<EhrDocumentByEesztIdResponse>(this.environment.naucommUrl + 'rest/ecm/ehr/getdocument/eesztid', eesztId, {
      headers: new HttpHeaders(
        { 'content-Type': 'application/json' }
      )
    });
  }

  saveAndReturnDocumentEeszt(saveAndReturnDocumentEeszt: SaveAndReturnDocumentEeszt) {
    return this.http.post<any>(this.environment.naucommUrl + 'rest/neu/docinv/saveandreturndocument/eeszt', saveAndReturnDocumentEeszt, {
      headers: new HttpHeaders(
        { 'content-Type': 'application/json' }
      )
    });
  }

  getOtpToken(otpTokenRequest: OtpTokenRequest) {
    return this.http.post<any>(this.environment.naucommUrl +
      'rest/ecm/saml/getotptoken',
      otpTokenRequest,
      {
        headers: new HttpHeaders(
          { 'content-Type': 'application/json' }
        )
      });
  }

  getMobilToken(mobilTokenRequest: MobilTokenRequest) {
    return this.http.post<any>(this.environment.naucommUrl + 'rest/ecm/saml/getmobiltoken', mobilTokenRequest,
      {
        headers: new HttpHeaders(
          { 'content-Type': 'application/json' }
        )

      });
  }

  updateEesztDetails(updateEesztDetailsReques: UpdateEesztDetailsReques) {
    return this.http.post<any>(this.environment.naucommUrl + 'rest/neu/calendarlist/updateeesztdetails', updateEesztDetailsReques,
      {
        headers: new HttpHeaders(
          { 'content-Type': 'application/json' }
        )

      });
  }

  /* getBnoMainGroupList (messageHeader) {
    return this.http.post<BnoMainGroupResponse>(this.environment.naucommUrl + 'rest/neu/calendarlist/getbnomaingrouplist' , messageHeader, {
      headers: new HttpHeaders(
        {'content-Type': 'application/json'}
      )
    });
  }

  getBnoSubGroupList (messageHeader) {
    return this.http.post<BnoSubGroupResponse>(this.environment.naucommUrl + 'rest/neu/calendarlist/getbnosubgrouplist', messageHeader, {
      headers: new HttpHeaders(
        {'content-Type': 'application/json'}
      )
    });
  } */

  getFullBnoList(messageHeader) {
    return this.http.post<GetBnoListResponse>(this.environment.naucommUrl + 'rest/neu/calendarlist/getfullbnolist', messageHeader, {
      headers: new HttpHeaders(
        { 'content-Type': 'application/json' }
      )
    });
  }

  /* getBnoList (getBnoListRequest: GetBnoListRequest) {
    return this.http.post<GetBnoListResponse>(this.environment.naucommUrl + 'rest/neu/calendarlist/getbnolist', getBnoListRequest, {
      headers: new HttpHeaders(
        {'content-Type': 'application/json'}
      )
    });
  } */


  samlLogOut(messageHeader) {
    return this.http.post<any>(this.environment.naucommUrl + 'rest/ecm/saml/logout', messageHeader,
      {
        headers: new HttpHeaders(
          { 'content-Type': 'application/json' }
        )
      });
  }

  getRss() {
    return this.http.get<RssResponse>(this.environment.naucommUrl + 'rest/ecm/rss');
  }

  getEesztUserDetail(messageHeader) {
    return this.http.post<GetEesztUserDetailResponse>(this.environment.naucommUrl + 'rest/neu/authentication/geteesztuserdetail', messageHeader,
      {
        headers: new HttpHeaders(
          { 'content-Type': 'application/json' }
        )
      })
  }

  deleteEkatEesztidMinimal(deleteEkatEesztIdRequest: DeleteEkatEesztIdRequest) {
    return this.http.post<any>(this.environment.naucommUrl + 'rest/ecm/ekat/delete/eesztid/minimal', deleteEkatEesztIdRequest,
      {
        headers: new HttpHeaders(
          { 'content-Type': 'application/json' }
        )
      });
  }

  saveAppointmentWeekly(saveAppointmentRequest: SaveAppointmentWeeklyRequest) {
    return this.http.post<any>(this.environment.naucommUrl + 'rest/neu/appointment/saveemergency', saveAppointmentRequest,
      {
        headers: new HttpHeaders(
          { 'content-Type': 'application/json' }
        )
      })
  }

  saveAppointmentWithRule(req: SaveAppointmentWithRuleRequest) {
    return this.http.post<SaveAppointmentWithRulerResponse>(this.environment.naucommUrl + 'rest/neu/calendarlist/saveschedulewithrule', req,
      {
        headers: new HttpHeaders(
          { 'content-Type': 'application/json' }
        )
      });
  }

  saveScheduleDay(saveScheduleRequest) {
    return this.http.post<any>(this.environment.naucommUrl + 'rest/neu/appointment/saveschedule', saveScheduleRequest,
      {
        headers: new HttpHeaders(
          { 'content-Type': 'application/json' }
        )
      })
  }

  getScheduleList(getScheduleListparams) {
    return this.http.post<any>(this.environment.naucommUrl + 'rest/neu/calendarlist/getschedulelist', getScheduleListparams,
      {
        headers: new HttpHeaders(
          { 'content-Type': 'application/json' }
        )
      })
  }

  getCalendarListData(getCalendarListDataRequest) {
    return this.http.post(this.environment.apiUrl + '/rest/neu/calendarlist/getcalendarlistdata', getCalendarListDataRequest, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }


  getMultiCalendar(params) {
    return this.http.post(this.environment.apiUrl + '/rest/neu/calendarlist/getcalendarlistdatamulti', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }


  checkUserBy2t(checkUserBy2TRequest) {
    return this.http.post(this.environment.apiUrl + '/rest/neu/authentication/checkuserby2t', checkUserBy2TRequest, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  checkUserBy4t(checkUserBy4tRequest) {
    return this.http.post(this.environment.apiUrl + '/rest/neu/authentication/checkuserby4t', checkUserBy4tRequest, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getCityByZipCode(zipCode: number) {
    return this.http.get(this.environment.apiUrl + '/rest/neu/authentication/searchcitybyzipcode/' + zipCode);
  }

  // beutalo ebp

  saveAdmission(admission: SaveAdmissionRequest) {
    return this.http.post(this.environment.apiUrl + '/rest/neu/admissions/saveadmission', admission, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getAdmissions(getAdmissionRequestBody: GetAdmissionRequest) {
    return this.http.post(this.environment.apiUrl + '/rest/neu/admissions/getadmissions', getAdmissionRequestBody, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  userRegistration(params) {
    return this.http.post(this.environment.apiUrl + '/rest/neu/authentication/userregistration', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getPatientReservations(request: GetPatientReservationRequest): Observable<GetPatientReservationsResponse> {
    return this.http.post<GetPatientReservationsResponse>(this.environment.apiUrl + '/rest/neu/calendarlist/getcalendarlistdata', request);
  }

  getAllBno(messageHeader) {
    return this.http.post<any>(this.environment.naucommUrl + 'rest/neu/calendarlist/getallbno', messageHeader, {
      headers: new HttpHeaders(
        { 'content-Type': 'application/json' }
      )
    });
  }

  getAllDiagType(messageHeader) {
    return this.http.post<any>(this.environment.naucommUrl + 'rest/neu/calendarlist/getalldiagtype', messageHeader, {
      headers: new HttpHeaders(
        { 'content-Type': 'application/json' }
      )
    });
  }

  getAllWhoJ(messageHeader) {
    return this.http.post<any>(this.environment.naucommUrl + 'rest/neu/calendarlist/getallwhoj', messageHeader, {
      headers: new HttpHeaders(
        { 'content-Type': 'application/json' }
      )
    });
  }

  getAllWhoF(messageHeader) {
    return this.http.post<any>(this.environment.naucommUrl + 'rest/neu/calendarlist/getallwhof', messageHeader, {
      headers: new HttpHeaders(
        { 'content-Type': 'application/json' }
      )
    });
  }

  updateCalendarDetail(request: any) {
    return this.http.post<any>(this.environment.naucommUrl + 'rest/neu/calendarlist/updatecalendardetail', request, {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json' }
      )
    });
  }

  getCalendarBno(request: any) {
    return this.http.post<any>(this.environment.naucommUrl + 'rest/neu/calendarlist/getcalendarbno', request, {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json' }
      )
    });
  }


  getCalendarWho(request: any) {
    return this.http.post<any>(this.environment.naucommUrl + 'rest/neu/calendarlist/getcalendarwho', request, {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json' }
      )
    });
  }

  getSurgeryType(request: any) {
    return this.http.post<any>(this.environment.naucommUrl + 'rest/neu/calendarlist/getsurgerytype', request, {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json' }
      )
    });
  }

  getNosovEnv(request: any) {
    return this.http.post<any>(this.environment.naucommUrl + 'rest/neu/calendarlist/getnosoenv', request, {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json' }
      )
    });
  }

  getHealing(request: any) {
    return this.http.post<any>(this.environment.naucommUrl + 'rest/neu/calendarlist/gethealing', request, {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json' }
      )
    });
  }

  getLaterality(request: any) {
    return this.http.post<any>(this.environment.naucommUrl + 'rest/neu/calendarlist/getlaterality', request, {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json' }
      )
    });
  }

  getQualification(request: any) {
    return this.http.post<any>(this.environment.naucommUrl + 'rest/neu/calendarlist/getqualification', request, {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json' }
      )
    });
  }

  getOenoType(request: any) {
    return this.http.post<any>(this.environment.naucommUrl + 'rest/neu/calendarlist/getoenotype', request, {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json' }
      )
    });
  }

  getAnaestOeno(request: any) {
    return this.http.post<any>(this.environment.naucommUrl + 'rest/neu/calendarlist/getanaestoeno', request, {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json' }
      )
    });
  }


  discardPatient(request: any) {
    return this.http.post<any[]>(this.environment.naucommUrl + 'rest/neu/calendarlist/discardpatient', request, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  checkEquipmentsIsFree(request: CheckEquiomentIsFreeReq){
    return this.http.post<any>(this.environment.naucommUrl + 'rest/neu/appointment/checkequipments', request, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }
  getCountries(request: MessageHeader){
    return this.http.post<any>(this.environment.naucommUrl + 'rest/neu/authentication/getcountries', request, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getnationalities(request: MessageHeader){
    return this.http.post<any>(this.environment.naucommUrl + 'rest/neu/authentication/getnationalities', request, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }
}
