import { Component, OnInit, OnDestroy, Input, ɵConsole, AfterViewInit, ViewChild, Inject, Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { SharedDataService } from '../services/shared-data.service';
import { SharedApiService } from '../services/shared-api.service';
import { RedirectService } from '../services/redirect.service';
import { environment } from '../../environments/environment';
import { Subject, Subscription } from 'rxjs';
import { GetBnoListResponse } from '../shared/models/response/getBnoListResponse';
import { ApiService } from '../core/http/api.service';
import { ToastrService } from 'ngx-toastr';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { SaveAndReturnDocumentEeszt } from '../shared/models/request/saveAndReturnDocumentEesztRequest';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.css']
})
export class DocumentationComponent implements OnInit, OnDestroy {
  @ViewChild('mainTabGroup', { static: false }) mainTabGroup: MatTabGroup;
  bnoTextInput0 = new Subject<string | null>();
  templateBody: string;

  bnoItemListCat = [];
  bnoItemList = [];

  apiUrl = environment.apiUrl;

  ckeConfig: any;
  editorDirty = false;
  showEditor = true;
  selectedTabIndex = 0;

  calendarStatus: string;

  constructor(public authService: AuthService,
    private http: HttpClient,
    private sharedDataService: SharedDataService,
    private sharedApiService: SharedApiService,
    private openTab: RedirectService,
    private apiService: ApiService,
    private toastr: ToastrService,
  ) { }

  patientDataValue = null;
  documents = null;
  documentsLoading = true;
  documentContentLoading = false;
  documentTypes = [];
  documentTemplates = [];
  getDocumentTypesAndTemplatesObservable = null;
  docContent = '';
  activeTab = 0;
  calendarDetail = {};
  implantCopyEnabled = false;
  documentLoaded = false;
  codebaseLoading = false;

  childWindow = null;

  patientDetailsDisplay = '';

  implantAllDetailsValid = false;
  implantFormsData = [];

  implantData = [];

  closeConfirmationDialogueVisible = false;

  saveInProgress = false;
  saveResult = null;
  saveErrorDescription = '';

  loadResult = null;
  loadErrorDescription = '';
  bnoListLoading: boolean;

  uploadDocumentTypesSubscription: Subscription;
  uploadDocumentTypes = [];
  uploadDocumentTypesLoading = true;

  docTypeSel = new FormControl(null);
  docTemplateSel = new FormControl(null, Validators.required);
  docTagType = new FormControl(null);
  docTagCode = new FormControl(null);
  docTagName = new FormControl(null);
  docTagDescription = new FormControl(null);
  docTagValue = new FormControl(null);
  docEmailSend = new FormControl('');

  editor = new FormControl('');

  editorValueChangeSubscription = null;

  documentTypeSelectGroup = new FormGroup({
    docTypeSel: this.docTypeSel,
    docTemplateSel: this.docTemplateSel,
    docTagType: this.docTagType,
    docTagCode: this.docTagCode,
    docTagName: this.docTagName,
    docTagDescription: this.docTagDescription,
    docTagValue: this.docTagValue,
    docEmailSend: this.docEmailSend,
    editor: this.editor
  });

  docTagBase = [];
  docTagReadOnly = true;

  implantCountry = new FormControl(null, Validators.required);
  implantZip = new FormControl(null, Validators.required);
  implantCity = new FormControl(null, Validators.required);
  implantAddress = new FormControl(null, Validators.required);
  implantPhone = new FormControl(null, Validators.required);

  implantDetails = new FormGroup({
    implantCountry: this.implantCountry,
    implantZip: this.implantZip,
    implantCity: this.implantCity,
    implantAddress: this.implantAddress,
    implantPhone: this.implantPhone
  });

  resetTemplateVisible = false;

  /* bnoSubItems: BnoSubGroupResponse; */
  bnoListItems: GetBnoListResponse;

  fileInput = new FormControl();
  fileDocType = new FormControl('', Validators.required);
  fileTypeSel = new FormControl(null);
  fileTemplateSel = new FormControl(null, Validators.required);
  fileTagType = new FormControl(null);
  fileTagCode = new FormControl(null);
  fileTagName = new FormControl(null);
  fileTagDescription = new FormControl(null);
  fileTagValue = new FormControl(null);
  fileTagReadOnly = true;
  fileEmailSend = new FormControl('');

  fileUpload = new FormGroup({
    fileInput: this.fileInput,
    fileDocType: this.fileDocType,
    fileTagType: this.fileTagType,
    fileTagCode: this.fileTagCode,
    fileTagName: this.fileTagName,
    fileTagDescription: this.fileTagDescription,
    fileTagValue: this.fileTagValue,
    fileEmailSend: this.fileEmailSend
  });

  // bnoList = new FormControl(null, Validators.required);
  // bnoListOther = new FormControl(null);
  /* bnoSubName = new FormControl(null, Validators.required); */

  // bnoFromGroup = new FormGroup({
  //   bnoListOther: this.bnoListOther,
  //   bnoList: this.bnoList
  // });

  fileTempData = {
    filename: '',
    filetype: '',
    value: '',
    valid: false
  };

  savedDocuments = [];

  uploadInProgress = false;

  eesztSendEnabled = false;

  broadcastChannelTest = null;

  allBno = [];
  allWho = [];
  allDiagType = [];

  @Input() set patientData(data) {
    if (data !== null && data['calendarFk']) {
      this.calendarStatus = data['calendarStatus'];
      let editorValue = '';
      if (this.savedDocuments && this.savedDocuments.length > 0) {
        if (this.savedDocuments[data['calendarFk']] && this.savedDocuments[data['calendarFk']] !== null) {
          editorValue = this.savedDocuments[data['calendarFk']]['documentTypeSelectGroup']['editor'];

          const implantSectionStart = editorValue.indexOf('<div id=\"implant-data\">');
          const implantSectionEnd = editorValue.indexOf('</div>', implantSectionStart);

          if (implantSectionStart > 0) {
            if (implantSectionEnd > 0) {
              this.implantCopyEnabled = true;
            }
          }

        }
      }

      // console.log ('patientData 1');
      this.editor.setValue(editorValue);
      // console.log ('editor value1', this.editor.value.substr(0, 200));


      if (this.savedDocuments && this.savedDocuments.length > 0) {
        // console.log ('savedDocuments', this.savedDocuments);
        // console.log ('savedDocument for this calendarFk', this.savedDocuments[data['calendarFk']]);
        if (this.savedDocuments[data['calendarFk']] && this.savedDocuments[data['calendarFk']] !== null) {
          // console.log ('saved Doc:', this.savedDocuments[data['calendarFk']]['documentTypeSelectGroup']);
          this.showEditor = false;
          this.showEditor = true;
          this.documentTypeSelectGroup.setValue(this.savedDocuments[data['calendarFk']]['documentTypeSelectGroup']);
          this.documentLoaded = true;
          this.editor.markAsDirty();
          console.log('itt rue? patientData');

          this.editorDirty = true;
        } else {
          this.showEditor = false;
          // console.log ('no saved record - clear form1');
          this.documentTypeSelectGroup.reset();
          this.editor.setValue(' ');
          this.editor.reset('');
          this.editor.markAsPristine();
          this.documentLoaded = false;
          this.editorDirty = false;
          this.showEditor = true;
        }
      } else {
        this.showEditor = false;
        // console.log ('no saved record - clear form2');
        this.documentTypeSelectGroup.reset();
        this.editor.setValue(' ');
        this.editor.reset('');
        this.editor.markAsPristine();
        this.documentLoaded = false;
        this.editorDirty = false;
        this.showEditor = true;
      }
      // console.log ('editor value2', this.editor.value.substr(0, 100));


      // this.editor.setValue('');
      this.patientDataValue = data;

      this.getCalendarBno();

      this.activeTab = 0;
      this.mainTabGroup?.realignInkBar();

      this.getCodebase('DOCUMENT_TAG_TYPE').subscribe((codebaseData) => {
        this.codebaseLoading = false;
        if (codebaseData['code'] === 1) {
          codebaseData['objCodeBase'].forEach(item => {
            item['displayData'] = item['code'] + ' - ' + item['name'];
            if (item['detailStr']) {
              const tcDescStart = item['detailStr'].indexOf('<typeCodeDesc>') + 14;
              const tcDescEnd = item['detailStr'].indexOf('</typeCodeDesc>');
              if (tcDescStart >= 0 && tcDescEnd > tcDescStart) {
                item['detailStr'] = item['detailStr'].substring(tcDescStart, tcDescEnd);
              } else {
                item['detailStr'] = '-';
              }
            }

          });
          codebaseData['objCodeBase'].unshift({ code: '_UJ_CIMKE_', displayData: '+ Új cimke hozzáadása', name: '' });
          this.docTagBase = codebaseData['objCodeBase'];
          // console.log(codebaseData['objCodeBase']);
        } else {
          // error
        }
      });


      // console.log ('doc getDocumentTypesAndTemplates');
      if (this.getDocumentTypesAndTemplatesObservable) {
        this.getDocumentTypesAndTemplatesObservable.unsubscribe();
      }
      this.getDocumentTypesAndTemplatesObservable = this.getDocumentTypesAndTemplates().subscribe((documents) => {
        const docTypesAndTemplates = documents['data'];
        const docTypes = [];

        if (documents['code'] === 1) {
          docTypesAndTemplates.forEach(documentType => {
            if (!docTypes.find(obj => obj.docTypeCode === documentType['docTypeCode'])) {
              docTypes.push(
                {
                  docTypeCode: documentType['docTypeCode'],
                  docTypeName: documentType['docTypeName']
                }
              );
            }
          });
        } else {
          this.loadErrorDescription += documents['code'] + ' - ' + documents['message'];
          this.loadResult = -1;
        }
        docTypes.sort(this.sortTemplateTypes);
        this.documentTypes = docTypes;

        this.documents = documents['data'];
        this.documentsLoading = false;
      });

      this.implantData = [];
      this.patientDetailsDisplay = '';


      this.sharedApiService.getCalendarDetail(data['calendarFk']).subscribe((calendarDetail) => {
        if (calendarDetail['code'] > 0) {
          this.calendarDetail = calendarDetail['data'];

          const messageHeaderParam = {
            messageHeader: {
              currentTapass: this.authService.getUserData()['ekCardNo'],
              currentRole: this.sharedDataService.selectedRole['roleCode'],
              currentServicePointId: this.sharedDataService.selectedRole['spId'],
              locale: 'hu',
              deviceId: this.authService.getUUID()
            }
          };

          const allFekvoWho = {
            messageHeader: {
              currentTapass: this.authService.getUserData()['ekCardNo'],
              currentRole: this.sharedDataService.selectedRole['roleCode'],
              currentServicePointId: this.sharedDataService.selectedRole['spId'],
              locale: 'hu',
              deviceId: this.authService.getUUID()
            },
            userId: this.calendarDetail['participantId']
          };

          if (this.calendarDetail['admTypeCat'] === 'FEKVO') {
            this.apiService.getAllWhoF(allFekvoWho).subscribe(res => {
              this.allWho = res.oenoList;
              this.getCalendarWho();
            });
          } else {
            this.apiService.getAllWhoJ(messageHeaderParam).subscribe(res => {
              this.allWho = res.oenoList;
              this.getCalendarWho();
            });
          }
          this.eesztSendEnabled = this.calendarDetail['eesztEnabled'];
          this.calendarDetail['participantForename'] = this.calendarDetail['participantForename'] ? ' ' +
            this.calendarDetail['participantForename'] : '';
          this.calendarDetail['name'] = this.calendarDetail['participantSurname'] + this.calendarDetail['participantForename'];

          this.patientDetailsDisplay = '(' + this.calendarDetail['name'] + ')';

          // this.implantCountry.setValue(this.calendarDetail['participantZipCode']);

          this.sharedApiService.getUserSimple(this.calendarDetail['participantTapass']).subscribe(
            (userData: Response) => {
              if (userData['code'] === 1) {


                userData['user']['street'] = userData['user']['street'] ? userData['user']['street'] : '';

                userData['user']['street'] += (userData['user']['streetType'] ? ' ' + userData['user']['streetType'] : '') +
                  (userData['user']['houseNumber'] ? ' ' + userData['user']['houseNumber'] : '') +
                  (userData['user']['floor'] ? ' ' + userData['user']['floor'] : '') +
                  (userData['user']['door'] ? '/' + userData['user']['door'] : '');


                this.implantCountry.setValue(userData['user']['country3Digit']);
                this.implantZip.setValue(userData['user']['zipCode']);
                this.implantCity.setValue(userData['user']['settlement']);
                this.implantAddress.setValue(userData['user']['street']);
                this.implantPhone.setValue(userData['user']['phoneNumber']);
              } else {
                // hiba
              }
            }
          );

          // let city = '';
          // let address = '';

          // if (this.calendarDetail['participantFullAddress']) {
          //   city = this.calendarDetail['participantFullAddress'].substring(
          //   0, this.calendarDetail['participantFullAddress'].indexOf(','));
          //   address = this.calendarDetail['participantFullAddress'].substring(
          //   this.calendarDetail['participantFullAddress'].indexOf(',') + 2);
          // }

          //   console.log ('--- participant details ---');
          //   console.log (this.calendarDetail['participantZipCode']);
          //   console.log (city);
          //   console.log (address);
          //   console.log (this.calendarDetail['participantPhoneNumber']);

          // this.implantZip.setValue(this.calendarDetail['participantZipCode']);
          // this.implantCity.setValue(city);
          // this.implantAddress.setValue(address);
          // this.implantPhone.setValue(this.calendarDetail['participantPhoneNumber']);

          for (let p = 0; p < 6; p++) {
            this.implantData[p] = null;
          }

          if (this.calendarDetail['implantList']) {
            if (this.calendarDetail['implantList'].length > 0) {
              this.calendarDetail['implantList'].forEach((implantItem, index) => {
                this.implantData[index] = implantItem;
                // console.log ('implantItem:', implantItem);
              });
            }
          }

          // Esetleg mar mentett implant adatok kuldese az implant modulba
        } else {
          console.error('Documentation - error getting calendar details');
          this.loadErrorDescription += calendarDetail['code'] + ' - ' + calendarDetail['message'];
          this.loadResult = -1;
          this.ngOnInit();
        }
      });
    } else {
      // console.log ('patientData X');
    }
    // console.log ('editor value3', this.editor.value.substr(0, 200));
  }

  ngOnInit() {
    const messageHeaderParam = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      }
    };

    this.apiService.getAllDiagType(messageHeaderParam).subscribe(res => {
      this.allDiagType = res.diagType;
    });

    this.apiService.getAllBno(messageHeaderParam).subscribe(res => {
      this.allBno = res.bnoList;
    });

    this.editorValueChangeSubscription = this.editor.valueChanges.subscribe(value => {
      // console.log('Value from change: ', value);
      this.editorDirty = true;
    });

    this.uploadDocumentTypesSubscription = this.sharedDataService.documentTypesShared.subscribe(value => {
      this.uploadDocumentTypesLoading = false;
      this.uploadDocumentTypes = value;
    });

    this.ckeConfig = {
      allowedContent: true,
      fullPage: true,
      autoGrow_onStartup: true,
      autoGrow_maxHeight: 800,
      autoGrow_minHeight: 400,
      height: '500px',
      toolbarGroups: [
        { name: 'document', groups: ['mode', 'document', 'doctools'] },
        { name: 'clipboard', groups: ['clipboard', 'undo'] },
        { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
        { name: 'forms', groups: ['forms'] },
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
        { name: 'links', groups: ['links'] },
        { name: 'insert', groups: ['insert'] },
        { name: 'styles', groups: ['styles'] },
        { name: 'colors', groups: ['colors'] },
        { name: 'tools', groups: ['tools'] },
        { name: 'others', groups: ['others'] },
        { name: 'about', groups: ['about'] }
      ],
      removeButtons: 'Save,NewPage,Preview,Print,Templates,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,BidiLtr,BidiRtl,Language,Link,Unlink,Anchor,Flash,Smiley,PageBreak,Iframe,Font,BGColor,ShowBlocks,About,CreateDiv,Blockquote'
    };

    const messageHeader = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      }
    };

    /* this.apiService.getBnoSubGroupList(messageHeader).subscribe(res => {
      if (res.code > 0) {
        this.bnoSubItems = res;
      } else {
        console.log('error: ', res.message);
      }
    }, err => {
      console.log('error message: ', err.message);
    }); */

    this.editor.markAsPristine();
    // console.log ('editor.dirty', this.editor.dirty);

    // this.editor.setValue(this.editorContentTest);
    // this.editorConfig.editable = true;
  }

  ngOnDestroy() {
    if (this.editorValueChangeSubscription) {
      this.editorValueChangeSubscription.unsubscribe();
    }
    if (this.uploadDocumentTypesSubscription) {
      this.uploadDocumentTypesSubscription.unsubscribe();
    }
  }

  getCalendarBno() {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calendarId: this.patientDataValue['calendarFk']
    };

    this.apiService.getCalendarBno(params).subscribe(
      res => {
        this.savedBnos = res?.bnos;
      });
  }

  savedBnos = [];
  savedWhos = [];

  getCalendarWho() {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calendarId: this.patientDataValue['calendarFk']
    };

    this.apiService.getCalendarWho(params).subscribe(
      res => {
        this.savedWhos = res.whos;
      });
  }

  copyBnoIntoDocument() {
    const templateBodyHtml = this.stringToHTML(this.editor.value);
    if (templateBodyHtml.getElementById('diagValue')) {
      let bnoOtherString = '';
      if (this.savedBnos) {
        this.savedBnos.forEach(bnoItem => {
          bnoOtherString += '<br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + bnoItem.name + ' / ' + bnoItem.code;
        });
      }
      templateBodyHtml.getElementById('diagValue').innerHTML = 'Diagnózis: <br> &nbsp;' +
        bnoOtherString;
    } else {
      this.toastr.error('a sablon nem tartalmazza a diagnózis mezőt, kérjük vegye fel a kapcsolatot a rendszergazdával!');
    }

    if (templateBodyHtml.getElementById('whoValue')) {
      let whoOtherString = '';
      if (this.savedWhos) {
        this.savedWhos.forEach(who => {
          whoOtherString += '<br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + who.name + ' / ' + who.code;
        });
      }
      templateBodyHtml.getElementById('whoValue').innerHTML = 'Beavatkozások: <br> &nbsp;' +
        whoOtherString;
      this.editor.setValue(templateBodyHtml.documentElement.innerHTML);
    } else {
      this.toastr.error('a sablon nem tartalmazza a diagnózis mezőt, kérjük vegye fel a kapcsolatot a rendszergazdával!');
    }
  }

  stringToHTML = function (str) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
    return doc;
  };

  customBnoSubSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.subGrpName.toLocaleLowerCase().indexOf(term) > -1 ||
      item.subGrpCode.toLocaleLowerCase().indexOf(term) > -1;
  }

  customBnoListSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.bnoName.toLocaleLowerCase().indexOf(term) > -1 ||
      item.bnoCode.toLocaleLowerCase().indexOf(term) > -1;
  }

  logEditorContent() {
    // console.log('editorContent:', this.editor.value.substr(0, 200));
    // this.editor.markAsPristine();
  }

  /* getBnoListItems() {
    if (this.bnoFromGroup.controls.bnoSubName.value) {
      this.bnoFromGroup.controls.bnoList.reset();
      const getBnoListRequest: GetBnoListRequest = {
        messageHeader: {
          currentTapass: this.authService.getUserData()['ekCardNo'],
          currentRole: this.sharedDataService.selectedRole['roleCode'],
          currentServicePointId: this.sharedDataService.selectedRole['spId'],
          locale: 'hu',
          deviceId: this.authService.getUUID(),
          sessionId: null
        },
        mainGrpCode: this.bnoFromGroup.controls.bnoSubName.value.mainGrpcode,
        subGrpCode: this.bnoFromGroup.controls.bnoSubName.value.subGrpCode
      };
      console.log('this.bnoFromGroup.controls.bnoSubName.value: ', this.bnoFromGroup.controls.bnoSubName.value);
      this.bnoListLoading = true;
      this.apiService.getBnoList(getBnoListRequest).subscribe(bnoList => {
        this.bnoListLoading = false;
        if (bnoList.code > 0) {
          this.bnoListItems = bnoList;
        } else {
          console.log('response ok, error : ', bnoList);
        }
      }, err => {
        this.bnoListLoading = false;
        console.log('response error, error: ', err);
      });
    } else {
      this.bnoListItems = null;
      this.bnoFromGroup.controls.bnoList.reset();
    }

  } */


  resetDocumentModal() {
    // console.log ('resetDocumentModal');
    this.docContent = '';
    this.docTypeSel.setValue(null);
    this.docTemplateSel.reset();
    this.loadErrorDescription = '';
    this.loadResult = null;
    this.implantData = [];
    this.fileUpload.reset();
    this.fileTempData = {
      filename: '',
      filetype: '',
      value: '',
      valid: false
    };
    this.patientDataValue = null;
    this.patientDetailsDisplay = '';
  }

  closeResult() {
    this.saveErrorDescription = '';
    this.saveResult = null;
  }

  closeLoadResult() {
    this.loadErrorDescription = '';
    this.loadResult = null;
  }

  onChangeDocTag($event) {
    if ($event) {
      // console.log($event);
      if ($event.code !== '_UJ_CIMKE_') {
        this.docTagReadOnly = true;
        this.docTagCode.setValue($event.code);
        this.docTagName.setValue($event.name);
        this.docTagDescription.setValue($event.description);
        this.docTagValue.setValue('');
      } else {
        this.docTagReadOnly = false;
        this.docTagCode.setValue('');
        this.docTagName.setValue('');
        this.docTagDescription.setValue('');
        this.docTagValue.setValue('');
      }
    }
  }

  onChangeFileTag($event) {
    if ($event) {
      // console.log($event);
      if ($event.code !== '_UJ_CIMKE_') {
        this.fileTagReadOnly = true;
        this.fileTagCode.setValue($event.code);
        this.fileTagName.setValue($event.name);
        this.fileTagDescription.setValue($event.description);
        this.fileTagValue.setValue('');
      } else {
        this.fileTagReadOnly = false;
        this.fileTagCode.setValue('');
        this.fileTagName.setValue('');
        this.fileTagDescription.setValue('');
        this.fileTagValue.setValue('');
      }
    }
  }

  onImplantCopy() {
    let editorContent = this.editor.value;
    if (editorContent !== null) {
      const implantSectionStart = editorContent.indexOf('<div id=\"implant-data\">');
      const implantSectionEnd = editorContent.indexOf('</div>', implantSectionStart);
      if (implantSectionStart > 0 && implantSectionEnd > 0) {

        let implantDetails = '';
        const editorContentStart = editorContent.substring(0, (implantSectionStart + 23));
        const editorContentEnd = editorContent.substring(implantSectionEnd);

        const implantListData = [];
        implantDetails = '<p>';
        this.implantFormsData.forEach((value, index) => {
          // console.log('implant: #', index);
          // console.log('implant data: ', value);
          implantListData[(index - 1)] = value.value;
          if (value.value['itemId'] !== null) {
            implantListData[(index - 1)]['itemCode'] = this.sharedDataService.implantRegBase['list'][value.value['itemId']]['code'];
            implantListData[(index - 1)]['itemSubGrp'] = this.sharedDataService.implantRegBase['list'][value.value['itemId']]['grpSubCode'];
            implantListData[(index - 1)]['itemName'] = this.sharedDataService.implantRegBase['list'][value.value['itemId']]['name'];

            // console.log('value.value');
            // console.log(value.value);

            let impType = '';
            if (value.value['impType'] === 'CS') {
              impType = 'Csere';
            } else if (value.value['impType'] === 'Ú') {
              impType = 'Új implantátum';
            } else if (value.value['impType'] === 'E') {
              impType = 'Eltávolítás';
            }

            const impregItemDetail = this.sharedDataService.implantRegBase['list'][value.value['itemId']]['visibleDetail']; // vagy name...

            if (implantDetails !== '<p>') { implantDetails += '<br/><br/>'; }

            if (value.value['side']) {
              implantDetails += value.value['side'] + ': ';
            }
            implantDetails += value.value['manufactName'];
            implantDetails += ', ' + value.value['itemType'];
            implantDetails += ', SN: ' + value.value['manufactRowNumber'];
            // implantDetails += 'Tételszám: ' + value.value['manufactNumber'] + '<br/>';
            implantDetails += '<br/>';

            // implantDetails += 'Beavatkozás típusa: ' + impType + '<br/>';
            // // console.log ('impDate', value.value['impDate']);
            // implantDetails += 'Beavatkozás dátuma: ' + value.value['impDate'].year + '.' + value.value['impDate'].month +
            //  '.' + value.value['impDate'].day + '<br/>';
            // implantDetails += 'Beavatkozás oka: ' + value.value['impBnoDetail'] + '<br/>';
            // if (value.value['impType'] === 'CS') {
            //   implantDetails += 'Csere oka: ' + value.value['impReplace'] + '<br/>';
            // }
            // implantDetails += 'Főcsoport kód: ' + value.value['itemMainGrp'] + '<br/>';
            // implantDetails += 'Alcsoport kód: ' + impregItemDetail + '<br/>';
            // implantDetails += 'Forgalmazó: ' + value.value['vendorName'] + '<br/>';
            // implantDetails += 'Forgalmazó székhelye: ' + value.value['vendorPlace'] + '<br/>';


            // implantDetails += '<strong>Implantátum #' + index + '</strong><br/><br/>';
            // implantDetails += 'Beavatkozás típusa: ' + impType + '<br/>';
            // // console.log ('impDate', value.value['impDate']);
            // implantDetails += 'Beavatkozás dátuma: ' + value.value['impDate'].year + '.' + value.value['impDate'].month +
            //  '.' + value.value['impDate'].day + '<br/>';
            // implantDetails += 'Beavatkozás oka: ' + value.value['impBnoDetail'] + '<br/>';
            // if (value.value['impType'] === 'CS') {
            //   implantDetails += 'Csere oka: ' + value.value['impReplace'] + '<br/>';
            // }
            // implantDetails += 'Főcsoport kód: ' + value.value['itemMainGrp'] + '<br/>';
            // implantDetails += 'Alcsoport kód: ' + impregItemDetail + '<br/>';
            // implantDetails += 'Típusa: ' + value.value['itemType'] + '<br/>';
            // implantDetails += 'Gyártó: ' + value.value['manufactName'] + '<br/>';
            // implantDetails += 'Gyártási tételszám: ' + value.value['manufactNumber'] + '<br/>';
            // implantDetails += 'Sorozatszám: ' + value.value['manufactRowNumber'] + '<br/>';
            // implantDetails += 'Forgalmazó: ' + value.value['vendorName'] + '<br/>';
            // implantDetails += 'Forgalmazó székhelye: ' + value.value['vendorPlace'] + '<br/>';
            // implantDetails += 'Oldal: ' + value.value['side'];
          }
        });

        implantDetails += '</p>';
        // implant adatok összegyűjtése


        editorContent = editorContentStart + implantDetails + editorContentEnd;
        // console.log ('+++++ editor setvalue');
        this.editor.setValue(editorContent);
        console.log('itt tru: onImplantCopy');

        this.editorDirty = true;
      }
    }
  }

  // htmlTagFix(html: string) {
  //   console.log('start htmlTagFix');
  //   const tags = ['<meta', '<br', '<img'];
  //   let tagStart;
  //   let tagEnd;
  //   tags.forEach(tag => {
  //     tagStart = html.indexOf(tag);
  //     while (tagStart >= 0) {
  //       tagEnd = html.indexOf('>', tagStart);
  //       if (tagEnd === -1) { break; }
  //       if (html.charAt(tagEnd - 1) !== '/') {
  //         html = html.substring(0, tagEnd) + '/' + html.substring(tagEnd);
  //       }
  //       tagStart = html.indexOf(tag, tagStart + tag.length);
  //     }
  //   });
  //   console.log(html);
  //   return html;
  // }

  getDocumentTypesAndTemplates() {

    this.documentsLoading = true;
    this.documentTypes = [];
    this.documentTemplates = [];

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calId: this.patientDataValue['calendarFk'],
      returnBody: false
    };

    // console.log ('getDocumentTypesAndTemplates params:', params);

    return this.http.post(this.apiUrl + '/rest/neu/docinv/getdocumenttypesandtemplates', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }

  getTemplateBody(templateID, empty) {
    this.documentContentLoading = true;

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calId: this.patientDataValue['calendarFk'],
      templateId: templateID,
      currentTemplate: empty
    };

    return this.http.post(this.apiUrl + '/rest/neu/docinv/gettemplatebody', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }


  getCodebase(typeCode) {
    this.codebaseLoading = true;

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      typeCode: typeCode
    };

    return this.http.post(this.apiUrl + '/rest/neu/util/getcodebase', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }

  onSelectedDocTypeChange($event) {
    const docTemplates = [];
    if ($event) {
      this.docTemplateSel.reset();
      this.documents.forEach(documentTemplate => {
        if (documentTemplate['docTypeCode'] === $event['docTypeCode']) {
          docTemplates.push(documentTemplate);
        }
      });
      docTemplates.sort(this.sortTemplates);
      this.documentTemplates = docTemplates;
    } else {
      this.docTemplateSel.reset();
      this.documentTemplates = [];
    }
  }

  sortTemplates(a, b) {
    if (a['templateName'].toLowerCase() > b['templateName'].toLowerCase()) {
      return 1;
    } else if (a['templateName'].toLowerCase() < b['templateName'].toLowerCase()) {
      return -1;
    }
    return 0;
  }

  sortTemplateTypes(a, b) {
    if (a['docTypeName'].toLowerCase() > b['docTypeName'].toLowerCase()) {
      return 1;
    } else if (a['docTypeName'].toLowerCase() < b['docTypeName'].toLowerCase()) {
      return -1;
    }
    return 0;
  }

  onLoadTemplate(loadSaved) {
    // console.log ('onLoadTemplate');
    if (this.docTemplateSel.value) {
      this.editor.setValue('Loading...');
      this.getTemplateBody(this.docTemplateSel.value, loadSaved).subscribe((document) => {
        this.editorDirty = true;
        // console.log(document);
        this.documentContentLoading = false;

        if (document['code'] > 0) {
          if (loadSaved === 0) {
            this.editor.markAsDirty();
          }
          this.templateBody = document['templateBody'];
          // templateBody = templateBody.replace('<tr/>\n<p>---------</p>\n<div id=\"implant-data\"></div>\n<p>---------</p>',
          //  '<td colspan=\"2\">\n<p>---------</p>\n<div id=\"implant-data\"></div>\n<p>---------</p>\n</td>\n</tr>');
          // console.log ('+++++++ editor setvalue2');
          this.editor.setValue(this.templateBody);
          const implantSectionStart = document['templateBody'].indexOf('<div id=\"implant-data\">');
          const implantSectionEnd = document['templateBody'].indexOf('</div>', implantSectionStart);

          // console.log('position values:' + implantSectionStart + ', ' + implantSectionEnd);

          if (implantSectionStart > 0) {
            if (implantSectionEnd > 0) {
              // console.log('end found');
              this.implantCopyEnabled = true;
            }
          }
          this.documentLoaded = true;
        } else {
          // hiba
        }
      });
    }
  }


  onSaveDocument($event, print, eeszt) {
    const editorContent = this.editor.value;
    let bno = null;


    if (this.savedBnos && eeszt) {
      this.savedBnos.forEach(bnoItem => {
        if (bnoItem.diagTypeCode === '3') {
          bno = bnoItem.code;
        }
      });

    }
    if (!bno  && eeszt) {
      this.toastr.error('Az ápolást indokló fődiagnózis megadása kötelező!');
      return;
    }

    this.saveInProgress = true;

    if (eeszt === true) {
      const saveAndReturnDocument: SaveAndReturnDocumentEeszt = {
        messageHeader: {
          currentTapass: this.authService.getUserData()['ekCardNo'],
          currentRole: this.sharedDataService.selectedRole['roleCode'],
          currentServicePointId: this.sharedDataService.selectedRole['spId'],
          locale: 'hu',
          deviceId: this.authService.getUUID()
        },
        calId: this.patientDataValue['calendarFk'],
        patientTapass: this.calendarDetail['participantTapass'],
        docType: this.docTypeSel.value,
        docInHtml: editorContent,
        returnDocument: false,
        bno
        //bno: this.bnoFromGroup.controls.bnoList.value.bnoCode
      };
      this.saveInProgress = true;
      this.apiService.saveAndReturnDocumentEeszt(saveAndReturnDocument).subscribe(res => {
        if (res['code'] > 0) {
          this.resetDataForFutureEditing();
          //          this.bnoFromGroup.reset();
          this.saveInProgress = false;
          this.saveResult = 0;
          setTimeout(() => {
            this.saveResult = 1;
          }, 5000);
        } else {
          this.saveResult = -1;
          this.saveErrorDescription = res['message'];
          setTimeout(() => {
            this.saveResult = 1;
          }, 5000);
        }
      }, err => {
        this.toastr.error(err.error.message);
      });
    } else {
      this.saveAndReturnDocument(editorContent).subscribe((document) => {
        this.saveInProgress = false;
        if (document['code'] > 0) {
          setTimeout(() => { this.saveResult = null; }, 5000);
          this.resetDataForFutureEditing();

          if (this.docTagType.value && this.docTagCode.value !== '') {   // save document tag
            this.saveInProgress = true;
            this.updateDocumentPropery(document['code'], 1).subscribe(tagData => {
              this.saveInProgress = false;
              if (tagData['code'] > 0) {
                // console.log('Document Tag Saved');
              } else {
                console.log('Document Tag Save Error!');
              }
            });
          }

          // console.log('docEmailSend value', this.docEmailSend.value);
          if (this.docEmailSend.value === true) {   // save document tag
            this.saveInProgress = true;
            this.sendEmailNotification(document['code'], 1).subscribe(tagData => {
              this.saveInProgress = false;
              if (tagData['code'] > 0) {
                // console.log('Document Tag Saved');
              } else {
                console.log('Document Tag Save Error!');
              }
            });
          }


          this.saveResult = 0;
          // this.editor.reset(editorContent);
          // this.editorDirty = false;
          if (print === 1) {

            const params = {
              messageHeader: {
                currentTapass: this.authService.getUserData()['ekCardNo'],
                currentRole: this.sharedDataService.selectedRole['roleCode'],
                currentServicePointId: this.sharedDataService.selectedRole['spId'],
                locale: 'hu',
                deviceId: this.authService.getUUID()
              },
              docId: document['code']

            };

            this.openTab.post(params, this.apiUrl + '/rest/neu/docinv/getdocument/');
            // if (!this.childWindow) {
            //  this.childWindow = window.open();
            // }

            // this.broadcastChannelTest = new BroadcastChannel ('nauCare');

            // console.log('childWindow', this.childWindow);
            // const theDoc = this.childWindow.document;
            // theDoc.title = 'Teszt';
            // const element = theDoc.createElement('div');
            // element.innerHTML = '<div>VALAMI</div>';
            // theDoc.body.appendChild(element);

            // const script = theDoc.createElement('script');
            // script.innerHTML = `
            // var bc = new BroadcastChannel('nauCare');
            // bc.onmessage = function (ev) { console.log(ev); }
            // `;
            // theDoc.body.appendChild(script);

            // this.postMessageTest();

          }
        } else {
          this.saveResult = -1;
          this.saveErrorDescription = document['code'] + ' ' + document['message'];
        }
        // console.log('document saved');
        // console.log(document);
      });
    }
  }

  saveAndReturnDocument(content: string) {
    this.saveInProgress = true;
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calId: this.patientDataValue['calendarFk'],
      patientTapass: this.calendarDetail['participantTapass'],
      docType: this.docTypeSel.value,
      docInHtml: content,
      returnDocument: false,
    };

    return this.http.post(this.apiUrl + '/rest/neu/docinv/saveandreturndocument', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  updateDocumentPropery(docId, type) {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      documentProperties: {}
    };

    if (type === 1) {
      params.documentProperties = {
        docId: docId,
        tagCode: this.docTagCode.value,
        tagName: this.docTagName.value,
        tagDescription: this.docTagDescription.value,
        tagValue: this.docTagValue.value
      };
    } else if (type === 2) {
      params.documentProperties = {
        docId: docId,
        tagCode: this.fileTagCode.value,
        tagName: this.fileTagName.value,
        tagDescription: this.fileTagDescription.value,
        tagValue: this.fileTagValue.value
      };
    }


    return this.http.post(this.apiUrl + '/rest/neu/docinv/updatedocumentproperty', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }



  sendEmailNotification(docId, type) {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      documentProperties: {}
    };

    if (type === 1) {
      params.documentProperties = {
        docId: docId,
        tagCode: 'FLAG_NOTIFY_PATIENT_BY_MAIL',
        tagName: 'Értesítendő (logikai)',
        tagDescription: '1, ha a dokumentum keletkezéséről értesíteni kell a tulajdonosát',
        tagValue: '1'
      };
    } else if (type === 2) {
      params.documentProperties = {
        docId: docId,
        tagCode: 'FLAG_NOTIFY_PATIENT_BY_MAIL',
        tagName: 'Értesítendő (logikai)',
        tagDescription: '1, ha a dokumentum keletkezéséről értesíteni kell a tulajdonosát',
        tagValue: '1'
      };
    }


    return this.http.post(this.apiUrl + '/rest/neu/docinv/updatedocumentproperty', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }


  updateDocumentProperyEeszt(docId, index) {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      documentProperties: {}
    };

    params.documentProperties = {
      docId: docId,
      tagCode: index === 0 ? 'EESZT_EHR_BNO' : 'FLAG_EESZT_SEND',
      tagName: 'EESZT beküldés (logikai)',
      tagDescription: 'Dokumentum leíró típusa',
      tagValue: /*index === 0 ? this.bnoFromGroup.controls.bnoList.value.bnoCode : 1*/ null
    };


    return this.http.post(this.apiUrl + '/rest/neu/docinv/updatedocumentproperty', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }


  closeModal() {
    // console.log('dialogue visible');
    // console.log ('closeModal');
    this.switchTab(0);
    // this.implantCopyEnabled = false;
    //this.bnoFromGroup.reset();
    this.loadErrorDescription = '';
    this.loadResult = null;
    if (this.editorDirty === true) {
      this.closeResult();
      this.closeConfirmationDialogueVisible = true;
    } else {
      this.selectedTabIndex = 0;
    }
  }

  hideCloseConfirmationDialogue(saveData) {
    if (saveData === true) {
      this.saveDataForFutureEditing();
    } else {
      this.resetDataForFutureEditing();
    }
    this.closeConfirmationDialogueVisible = false;
    this.selectedTabIndex = 0;
  }

  resetModal() {
    //  console.log ('reset modal');
    // this.implantCopyEnabled = false;
    // this.documentLoaded = false;
    // this.documentTypeSelectGroup.reset();
    // this.closeResult();
    // this.loadErrorDescription = '';
    // this.loadResult = null;
    this.editor.setValue('');
    this.editor.reset();
    this.editor.markAsPristine();
    this.editorDirty = false;
    this.docTagReadOnly = true;
    this.docTagCode.setValue('');
    this.docTagName.setValue('');
    this.docTagDescription.setValue('');
    this.docTagValue.setValue('');
    // this.documentTypeSelectGroup.reset();
    this.fileTagReadOnly = false;
    this.fileTagCode.setValue('');
    this.fileTagName.setValue('');
    this.fileTagDescription.setValue('');
    this.fileTagValue.setValue('');
    // // this.editor.setValue('Kérem válasszon dokumentum típust és sablont!');

    this.fileUpload.reset();
    this.fileTempData = {
      filename: '',
      filetype: '',
      value: '',
      valid: false
    };
    this.patientDataValue = null;
    this.patientDetailsDisplay = '';
    const data = [];
    data['NGdestinationService'] = 'documentation';
    this.sharedDataService.setPatientData(data);
    // console.log ('resetModal');
    this.selectedTabIndex = 0;
  }

  resetModalAndHideDialogue() {
    this.documentLoaded = false;
    this.implantCopyEnabled = false;
    this.editor.setValue('');
    this.editor.reset();
    this.editor.markAsPristine();
    this.editorDirty = false;
    this.docTagReadOnly = true;
    this.docTagCode.setValue('');
    this.docTagName.setValue('');
    this.docTagDescription.setValue('');
    this.docTagValue.setValue('');
    this.fileTagReadOnly = false;
    this.fileTagCode.setValue('');
    this.fileTagName.setValue('');
    this.fileTagDescription.setValue('');
    this.fileTagValue.setValue('');
    this.documentTypeSelectGroup.reset();
    this.hideCloseConfirmationDialogue(0);
    // this.editor.setValue('Kérem válasszon dokumentum típust és sablont!');

  }

  saveDataForFutureEditing() {
    const data = [];
    data['patientDetails'] = this.patientDataValue;
    data['documentTypeSelectGroup'] = this.documentTypeSelectGroup.value;
    this.savedDocuments[this.patientDataValue['calendarFk']] = data;
    // console.log (this.documentTypeSelectGroup);
  }

  resetDataForFutureEditing() {
    if (this.savedDocuments !== null && this.patientDataValue !== null) {
      const index = this.savedDocuments.indexOf(this.savedDocuments[this.patientDataValue['calendarFk']], 0);
      // console.log ('index', index);
      this.savedDocuments.splice(this.patientDataValue['calendarFk'], 1);
      this.editorDirty = false;
    }
  }

  switchTab(tabID) {
    this.activeTab = tabID;
  }

  savedWhosChanged($event) {
    this.savedWhos = $event;
  }


  savedBnosChanged($event) {
    this.savedBnos = $event;
  }

  onFormDataChange(id: number, data: any) {
    // console.log('form changed: ' + id);
    // console.log(data);

    this.implantFormsData[id] = data;
    this.validateImplantData();
  }

  validateImplantData() {
    let allValid = true;
    let implantCount = 0;

    this.implantFormsData.forEach((implantData, index) => {
      console.log ('implantData', implantData);
      console.log('form #' + index + ' valid: ' + implantData.valid + ' dirty: ' + implantData.dirty);
      implantCount++;
      if (implantData.dirty === true && implantData.valid === false) {
        allValid = false;
      }
    });

    if (implantCount > 0) {
      this.implantAllDetailsValid = allValid;
    } else {
      this.implantAllDetailsValid = false;
    }
    console.log('all valid: ' + this.implantAllDetailsValid);
  }

  logFormData() {
    // console.log('Implant details: ');
    // console.log(this.implantFormsData);
    // console.log('Implant patient details: ');
    // console.log(this.implantDetails);
  }

  saveImplantData() {
    this.saveInProgress = true;
    this.saveImplantToCalendar().subscribe(data => {
      this.saveInProgress = false;
      if (data['code'] > 0) {
        setTimeout(() => { this.saveResult = null; }, 3000);
        this.saveResult = 0;
      } else {
        this.saveResult = -1;
        this.saveErrorDescription = data['code'] + ' ' + data['message'];
      }
      // console.log('Implant saved');
      // console.log(data);
    });

    // this.saveImplantToCalendar().subscribe(data => {

    //   // console.log('implant data saved');
    //   // console.log(data);
    //   alert(JSON.stringify(data));
    // });
  }

  saveImplantToCalendar() {

    const implantListData = [];

    this.implantFormsData.forEach((value, index) => {
      if (value.value['itemId']) {
        // console.log('value.value', value.value);
        implantListData[(index - 1)] = value.value;
        implantListData[(index - 1)]['itemCode'] = this.sharedDataService.implantRegBase['list'][value.value['itemId']]['code'];
        implantListData[(index - 1)]['itemSubGrp'] = this.sharedDataService.implantRegBase['list'][value.value['itemId']]['grpSubCode'];
        implantListData[(index - 1)]['itemName'] = this.sharedDataService.implantRegBase['list'][value.value['itemId']]['name'];
        implantListData[(index - 1)]['impDate'] = implantListData[(index - 1)]['impDate'].year + '-' +
          implantListData[(index - 1)]['impDate'].month + '-' + implantListData[(index - 1)]['impDate'].day;
        // console.log ('implantdate', implantListData[(index - 1)]['impDate']);
        delete implantListData[(index - 1)]['impBnoCat'];
        delete implantListData[(index - 1)]['impBnoDetail'];
        if (implantListData[(index - 1)]['impType'] !== 'CS') {
          implantListData[(index - 1)]['impReplace'] = '';
        }
      }

    });

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calendarId: this.patientDataValue['calendarFk'],
      appendImplantList: true,
      participantZipCode: this.implantDetails.value.implantZip,
      participantFullAddress: this.implantDetails.value.implantCity +
        ', ' + this.implantDetails.value.implantAddress,
      participantPhoneNumber: this.implantDetails.value.implantPhone,
      implantList: implantListData
    };
    // console.log('IMPLANT DETAILS');
    // console.log(params);

    return this.http.post(this.apiUrl + '/rest/neu/calendarlist/saveimplanttocalendar', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  onFileSubmit() {
    // console.log('onFileSubmit');
    // console.log('fileUpload', this.fileUpload);
    // console.log('fileInput', this.fileInput.value);

    // console.log(this.fileTempData.filetype);

    if (this.fileTempData.value !== '' && (this.fileTempData.filetype === 'application/pdf' || this.fileTempData.filetype === 'image/jpeg'
      || this.fileTempData.filetype === 'image/png')) {

      this.uploadInProgress = true;

      this.savePdf().subscribe(response => {

        this.uploadInProgress = false;

        if (response['code'] > 0) {

          if (this.fileTagType.value && this.fileTagCode.value !== '') {   // save document tag
            this.uploadInProgress = true;
            this.updateDocumentPropery(response['code'], 2).subscribe(tagData => {
              this.uploadInProgress = false;
              if (tagData['code'] > 0) {
                // console.log('File Tag Saved');
              } else {
                console.log('File Tag Save Error!');
              }
            });
          }


          if (this.fileEmailSend.value === true) {   // save document tag
            this.uploadInProgress = true;
            this.sendEmailNotification(response['code'], 2).subscribe(tagData => {
              this.uploadInProgress = false;
              if (tagData['code'] > 0) {
                // console.log('File Tag Saved');
              } else {
                console.log('File Tag Save Error!');
              }
            });
          }

          // console.log ('upload successful', response);
          setTimeout(() => { this.saveResult = null; }, 3000);
          this.saveResult = 0;
          this.saveErrorDescription = '';

          this.fileUpload.reset();
          this.fileTempData = {
            filename: '',
            filetype: '',
            value: '',
            valid: false
          };

        } else {
          console.error('upload failed', response);
          this.saveResult = -1;
          this.saveErrorDescription = response['code'] + ' - ' + response['message'];
        }

      },
        error => {
          this.uploadInProgress = false;
          console.error('upload http error', error);
          this.saveResult = -1;
          this.saveErrorDescription = 'Hiba a kiszolgálóval való kommunikáció során...';
        }
      );

    }

  }



  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (file.type === 'application/pdf' || 'image/jpeg' || 'image/png') {
          this.fileTempData = {
            filename: file.name,
            filetype: file.type,
            value: (<string>reader.result).split(',')[1],
            valid: true
          };
        } else {
          this.fileTempData = {
            filename: '',
            filetype: '',
            value: '',
            valid: false
          };
        }
      };
    }
  }



  savePdf() {

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calId: this.patientDataValue['calendarFk'],
      docType: this.fileDocType.value,
      pdfInBytes: this.fileTempData['value']
    };

    // return this.http.post('http://localhost:8090' + '/rest/neu/docinv/savepdf', params, {
      return this.http.post(this.apiUrl + '/rest/neu/docinv/savepdf', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }

  openHelp(anchor) {
    const newWindow = window.open('https://' + this.authService.getHostname() + '/kezikonyv/' + anchor);
  }

  // logImplantDetails() {
  //   console.log(this.implantDetails);
  // }

  onCopyImplantData($event, instanceId) {
    // console.log ('- onCopyImplantData');
    const impDetails = $event['value'];
    // console.log ('- impDetails', impDetails);
    impDetails['impDate'] = impDetails['impDate']['year'] + '-' + impDetails['impDate']['month'] + '-' + impDetails['impDate']['day'];
    this.implantData[instanceId] = impDetails;
  }

  postMessageTest() {
    const messageData = {
      messageType: 1,
      data: {
        token: 'frewr34rewr',
        param1: 1334
      }
    };
    if (this.broadcastChannelTest !== null) {
      this.broadcastChannelTest.postMessage(messageData, 'http://localhost:4200');
    }
  }

  saveInProgressFromSurgery($event: any) {
    this.saveInProgress = $event;
  }

  saveResultFromSurgery($event: any) {
    this.saveResult = $event;
    setTimeout(() => {
      this.saveResult = null;
    }, 2000);
  }

  tabClick(tab) {
    this.selectedTabIndex = tab.index;
  }
}
