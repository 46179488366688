<!-- Modal -->
<div class="modal" id="discardPatient" tabindex="-1" role="dialog" aria-labelledby="discardPatient" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">

    <div [ngClass]="{'hidden': saveResult !== 0}" class="fullSizeCover">
      <div class="fullSizeCoverContent">
        <div class="col-md-12 mt-4">
          <div class="row">
            <div class="col-md-12 mt-4 text-center">
              <h3><i class="fa fa-check-circle green mr-2"></i>Páciens sikeresen elbocsátva!</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mt-4 text-center">
              <button class="btn btn-success" data-dismiss="modal" (click)="onClose()">Bezárás</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-content">
      <div class="modal-header">
        <div id="saveError" [ngClass]="{'showResult': saveResult == -1}">
          <div class="closeResult" (click)="closeResult()">x</div>
          <div class="saveErrorContent">Hiba az elbocsátás során!
            <span>{{ saveErrorDescription }}</span>
          </div>
        </div>

        <h5 class="modal-title">Páciens elbocsátása ({{ patientDataValue['participantName'] }})</h5>
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="onClose()">X</button>
      </div>
      <div class="modal-body">
        <div class="container-fluid" *ngIf="!isLoading">
          <div *ngIf="admTypeCat === 'ARVTYPE_OUTPAT'">
            <form [formGroup]="treatmentDetailsOutpat">
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <label for="ellatoOrvos" class="col-sm-4 control-label">Ellátó osztály</label>
                    <div class="col-sm-8 form-item">
                      <ng-select class="full-width" [searchable]="true" placeholder="Ellátó osztály"
                        formControlName="ellatoOsztaly" [items]="availServic" bindLabel="spName" bindValue="spId"
                        [clearable]="false" [compareWith]="compareSpId"
                        loadingText="Betöltés..." notFoundText="Nincs találat"></ng-select>
                    </div>

                    <label for="admDate" class="col-sm-4 control-label">Ellátás kezdete</label>
                    <div class="col-sm-8 form-item">
                      <input type="datetime-local" id="admDate" placeholder="Ellátás kezdete" class="form-control"
                        [formControl]="admDate">
                      <div class="formError" *ngIf="admDate.invalid && formSubmitted">Nem kezdő dátum
                        (minimum 2 karakter)</div>
                    </div>

                    <label for="dscDate" class="col-sm-4 control-label">Ellátás vége</label>
                    <div class="col-sm-8 form-item">
                      <input type="datetime-local" id="dscDate" placeholder="Ellátás kezdete" class="form-control"
                        [formControl]="dscDate">
                      <div class="formError" *ngIf="dscDate.invalid && formSubmitted">Nem vég dátum
                        (minimum 2 karakter)</div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="row">
                    <label for="ellatoOrvos" class="col-sm-4 control-label">Ellátó orvos</label>
                    <div class="col-sm-8 form-item">
                      <ng-select class="full-width" [searchable]="true" placeholder="Ellátó orvos"
                        formControlName="ellatoOrvos" [items]="doctorsList" bindLabel="nameWithTitle" bindValue="id"
                        [loading]="doctorsListLoading" [clearable]="false" [compareWith]="compareDoctorId"
                        loadingText="Betöltés..." notFoundText="Nincs találat"></ng-select>
                    </div>

                    <label for="ellatasTipusa" class="col-sm-4 control-label">Ellátás típusa</label>
                    <div class="col-sm-8 form-item">
                      <ng-select class="full-width" [searchable]="true" placeholder="Ellátás típusa"
                        formControlName="ellatasTipusa" [disabled]="true" [items]="careTypeList" bindLabel="description"
                        bindValue="code" [loading]="careTypesLoading" [clearable]="false"
                        loadingText="Betöltés..." notFoundText="Nincs találat">
                      </ng-select>
                    </div>

                    <label for="appNumber" class="col-sm-4 control-label">Naplószám</label>
                    <div class="col-sm-8 form-item">
                      <input type="text" id="appNumber" placeholder="Naplószám" class="form-control"
                        [formControl]="appNumber">
                      <div class="formError" *ngIf="appNumber.invalid && formSubmitted">Nem naplószám
                        (minimum 2 karakter)</div>
                    </div>
                    <div class="col-sm-12 text-right">
                      <button class="btn btn-danger mt-4" (click)="onDiscardPatient()" [disabled]="saveInProgress">
                        <i *ngIf="saveInProgress" class="fa fa-spinner fa-spin"></i> Elbocsátás</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div *ngIf="admTypeCat === 'ARVTYPE_INPAT'">
            <form [formGroup]="treatmentDetailsInpat">
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <label for="ellatoOrvos" class="col-sm-4 control-label">Ellátó osztály</label>
                    <div class="col-sm-8 form-item">
                      <ng-select class="full-width" [searchable]="true" placeholder="Ellátó osztály"
                        formControlName="ellatoOsztaly" [items]="availServic" bindLabel="spName" bindValue="spId"
                        [clearable]="false" [compareWith]="compareSpId"
                        loadingText="Betöltés..." notFoundText="Nincs találat"></ng-select>
                    </div>

                    <label for="admDate" class="col-sm-4 control-label">Ellátás kezdete</label>
                    <div class="col-sm-8 form-item">
                      <input type="datetime-local" id="admDate" placeholder="Ellátás kezdete" class="form-control"
                        [formControl]="admDate">
                      <div class="formError" *ngIf="admDate.invalid && formSubmitted">Nem kezdő dátum
                        (minimum 2 karakter)</div>
                    </div>

                    <label for="dscDate" class="col-sm-4 control-label">Ellátás vége</label>
                    <div class="col-sm-8 form-item">
                      <input type="datetime-local" id="dscDate" placeholder="Ellátás kezdete" class="form-control"
                        [formControl]="dscDate">
                      <div class="formError" *ngIf="dscDate.invalid && formSubmitted">Nem vég dátum
                        (minimum 2 karakter)</div>
                    </div>

                  </div>
                </div>

                <div class="col-md-6">
                  <div class="row">

                    <label for="ellatoOrvos" class="col-sm-4 control-label">Ellátó orvos</label>
                    <div class="col-sm-8 form-item">
                      <ng-select class="full-width" [searchable]="true" placeholder="Ellátó orvos"
                        formControlName="ellatoOrvos" [items]="doctorsList" bindLabel="nameWithTitle" bindValue="id"
                        [loading]="doctorsListLoading" [clearable]="false" [compareWith]="compareDoctorId"
                        loadingText="Betöltés..." notFoundText="Nincs találat"></ng-select>
                    </div>

                    <label for="ellatasTipusa" class="col-sm-4 control-label">Ellátás típusa</label>
                    <div class="col-sm-8 form-item">
                      <ng-select class="full-width" [searchable]="true" placeholder="Ellátás típusa"
                        formControlName="ellatasTipusa" [disabled]="true" [items]="careTypeList" bindLabel="description"
                        bindValue="code" [loading]="careTypesLoading" [clearable]="false"
                        loadingText="Betöltés..." notFoundText="Nincs találat">
                      </ng-select>
                    </div>

                    <label for="tovabbiSors" class="col-sm-4 control-label">További sors</label>
                    <div class="col-sm-8 form-item">
                      <ng-select class="full-width" [searchable]="true" placeholder="További sors"
                        formControlName="tovabbiSors" [items]="getAwayTypes" bindLabel="description" bindValue="code"
                        [clearable]="false" loadingText="Betöltés..." notFoundText="Nincs találat">
                      </ng-select>
                    </div>


                    <label for="appNumber" class="col-sm-4 control-label">Törzsszám</label>
                    <div class="col-sm-8 form-item">
                      <input type="text" id="appNumber" placeholder="Törzsszám" class="form-control"
                        [formControl]="appNumber">
                      <div class="formError" *ngIf="appNumber.invalid && formSubmitted">Nem törzsszám
                        (minimum 2 karakter)</div>
                    </div>

                    <label for="admType" class="col-sm-4 control-label">Fekvőbeteg felvétel jellege</label>
                    <div class="col-sm-8 form-item">
                      <ng-select class="full-width" [searchable]="true" placeholder="Fekvőbeteg felvétel jellege"
                        formControlName="admType" [items]="admissionTypes" bindLabel="description" bindValue="code"
                        [clearable]="false" loadingText="Betöltés..." notFoundText="Nincs találat">
                      </ng-select>
                      <div class="formError" *ngIf="appNumber.invalid && formSubmitted">Nem fekvőbeteg felvétel jel
                        (minimum 2 karakter)</div>
                    </div>
                    <div class="col-sm-12 text-right">
                      <button class="btn btn-danger mt-4" (click)="onDiscardPatient()" [disabled]="saveInProgress">
                        <i *ngIf="saveInProgress" class="fa fa-spinner fa-spin"></i> Elbocsátás</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>


          </div>

        </div>
        <div class="container-fluid" *ngIf="isLoading">Loading...</div>
      </div>
    </div>
  </div>
</div>
