import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-booking-weekly',
  templateUrl: './booking-weekly.component.html',
  styleUrls: ['./booking-weekly.component.css']
})
export class BookingWeeklyComponent implements OnInit {

  constructor() { }

  calendarData = [];
  processedCalendar = null;
  days = [
    'Hétfő',
    'Kedd',
    'Szerda',
    'Csütörtök',
    'Péntek',
    'Szombat',
    'Vasárnap'
  ];

  timeslots = [];

  renderedCalendarItems = [];


  ngOnInit() {
    this.setCalendarResponse();
    this.processedCalendar = this.processCalendar(this.calendarData, '2019-02-25', '2019-03-02');
  }


  processCalendar(data, startDateInput, endDateInput) {
    const startDate = new Date(startDateInput);
    const endDate = new Date(endDateInput);

    data.forEach(entry => {
      const dateTo = new Date(entry['dateTo'].substring(0, entry['dateTo'].indexOf('.'))).getTime() +
        environment.timeZoneCompensation;
      const dateFrom = new Date(entry['dateFrom'].substring(0, entry['dateFrom'].indexOf('.'))).getTime() +
        environment.timeZoneCompensation;
      entry['dateToTimeStamp'] = dateTo;
      entry['dateFromTimeStamp'] = dateFrom;
      entry['slotLength'] = ((entry['dateToTimeStamp'] - entry['dateFromTimeStamp']) / 900000);
    });

    // console.log('StartDate: ' + startDate.getTime());
    // console.log('EndDate: ' + endDate.getTime());

    const calendarDays = [];
    let dayIndex = 0;
    let earliestItemStart = 86400000;
    let latestItemEnd = 0;

    for (let currentDay = (startDate.getTime() - environment.timeZoneCompensation);
      currentDay <= ((endDate.getTime() - environment.timeZoneCompensation) + 86400000); currentDay += 86400000) {
      // console.log('currentDay: ' + currentDay);
      const nextDay = currentDay + 86400000;
      calendarDays[dayIndex] = {};
      calendarDays[dayIndex].entries = [];
      calendarDays[dayIndex].startDate = currentDay;

      let calendarLength = this.calendarData.length;

      for (let i = 0; i < calendarLength; i++) {

        const entry = this.calendarData[i];
        // console.log('entry #' + i);
        // console.log(entry);
        // console.log ('calendar', this.calendarData);

        if (entry['dateFromTimeStamp'] >= currentDay && entry['dateFromTimeStamp'] < nextDay) {   // entry found for today
          let currentEntryStartOffset = entry['dateFromTimeStamp'] - currentDay;
          let currentEntryEndOffset = entry['dateToTimeStamp'] - currentDay;
          if (currentEntryStartOffset < earliestItemStart) { earliestItemStart = currentEntryStartOffset; }
          if (currentEntryEndOffset > latestItemEnd) { latestItemEnd = currentEntryEndOffset; }
          calendarDays[dayIndex].entries.push(entry);
          this.calendarData.splice(i, 1);
          calendarLength--;
          i--;
        }

        if (calendarLength === 0) {
          break;
        }


      }

      dayIndex++;
    }
    // console.log('calendarDays: ', calendarDays);
    // console.log ('earliestItemStart: ' + this.msToTime(earliestItemStart) + ' latestItemEnd: ' + this.msToTime(latestItemEnd));

    this.generateTimeAxis(earliestItemStart, latestItemEnd);
    this.renderedCalendarItems = this.createSlots(calendarDays, earliestItemStart, latestItemEnd);

  }


  createSlots(calendarDays, earliestItemStart, latestItemEnd) {

    const calendarRender = [];
    const servicePoints = [];

    if (calendarDays.length > 0) {
      calendarDays.forEach((day, dayIndex) => {
        if (day.entries.length > 0) {
          // console.log('day startdate: ' + day.startDate);
          day.entries.forEach((item, itemIndex) => {
            const itemOffset = ((item.dateFromTimeStamp - day.startDate) - earliestItemStart) / 900000;
            const length = (item.dateToTimeStamp - item.dateFromTimeStamp) / 900000;
            let category = 0;

            if (item.servicePointFk === 44938985) {category = 1; }

            if (!servicePoints.find(obj => obj.servicePointFk === item.servicePointFk)) {
              servicePoints.push(
                {
                  id: servicePoints.length,
                  servicePointFk: item.servicePointFk,
                  servicePointName: item.servicePointName
                }
              );
            }

            const newItem = {
              dayIndex: dayIndex,
              itemIndex: itemOffset,
              status: item.calendarStatus,
              length: length,
              category: category,
              originalData: item
            };

            calendarRender.push(newItem);

            // console.log('calendar item: ', newItem);


          });
        }
      });
    }
    return calendarRender;
  }

  generateTimeAxis(earliestItemStart, latestItemEnd) {

     // console.log ('earliestItemStart: ' + this.msToTime(earliestItemStart) + ' latestItemEnd: ' + this.msToTime(latestItemEnd));

    const slotCount = (latestItemEnd - earliestItemStart) / 900000;
    const timeslots = [];

    // console.log ('slotCount: ' + slotCount);

    for (let t = 0; t < slotCount; t++) {

      const timeString = this.msToTime(earliestItemStart + (t * 900000));
      timeslots.push(timeString);

    }

    this.timeslots = timeslots;

  }


  logItemDetails(item) {
    console.log ('Item details', item);
  }




  msToTime(ms) {

    const hour = Math.floor(ms / 3600000);
    const minute = (ms - (hour * 3600000)) / 60000;
    const formattedTime = (hour + ':' + ('0' + minute).slice(-2));
    return formattedTime;

  }





  setCalendarResponse() {
    this.calendarData = [
    {
      'id': 147,
      'dateFrom': '2019-02-25T07:00:00.000+0000',
      'dateTo': '2019-02-25T07:15:00.000+0000',
      'timeFrom': '08:00',
      'createDateTime': '2019-02-22 15:30:21',
      'scheduleFk': 44941542,
      'calendarFk': 44943357,
      'calendarStatus': 'D',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'participantName': 'Gecző Gergő József #40928062',
      'longDescription': 'Gecző Gergő József045120044,A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 08:00',
      'participantDetail': 'Gecző Gergő József ',
      'creatorName': 'Tömöri Imre'
    },
    {
      'id': 157,
      'dateFrom': '2019-02-25T07:15:00.000+0000',
      'dateTo': '2019-02-25T08:00:00.000+0000',
      'timeFrom': '08:15',
      'createDateTime': '2019-02-22 15:35:40',
      'scheduleFk': 44941543,
      'calendarFk': 44943372,
      'calendarStatus': 'D',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'participantName': 'Gecző Gergő József #40928062',
      'longDescription': 'Gecző Gergő József045120044,A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 08:15',
      'participantDetail': 'Gecző Gergő József ',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 150,
      'dateFrom': '2019-02-25T08:00:00.000+0000',
      'dateTo': '2019-02-25T10:00:00.000+0000',
      'timeFrom': '09:00',
      'createDateTime': '2019-02-25 08:47:57',
      'scheduleFk': 44941546,
      'calendarFk': 44951695,
      'calendarStatus': 'D',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'participantName': 'Pikó Tamás #40122593',
      'longDescription': 'Pikó Tamás 042985271,A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 09:00',
      'calendarComment': 'Tominak',
      'participantDetail': 'Pikó Tamás ',
      'creatorName': 'Gecző Gergő József'
    },
    {
      'id': 66,
      'dateFrom': '2019-02-25T10:00:00.000+0000',
      'dateTo': '2019-02-25T10:15:00.000+0000',
      'timeFrom': '11:00',
      'scheduleFk': 44941554,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 11:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 137,
      'dateFrom': '2019-02-25T10:15:00.000+0000',
      'dateTo': '2019-02-25T10:30:00.000+0000',
      'timeFrom': '11:15',
      'scheduleFk': 44941555,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 11:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 21,
      'dateFrom': '2019-02-25T10:30:00.000+0000',
      'dateTo': '2019-02-25T10:45:00.000+0000',
      'timeFrom': '11:30',
      'scheduleFk': 44941556,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 11:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 108,
      'dateFrom': '2019-02-25T10:45:00.000+0000',
      'dateTo': '2019-02-25T11:00:00.000+0000',
      'timeFrom': '11:45',
      'scheduleFk': 44941557,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 11:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 35,
      'dateFrom': '2019-02-25T11:00:00.000+0000',
      'dateTo': '2019-02-25T11:15:00.000+0000',
      'timeFrom': '12:00',
      'scheduleFk': 44941558,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 12:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 47,
      'dateFrom': '2019-02-25T11:15:00.000+0000',
      'dateTo': '2019-02-25T11:30:00.000+0000',
      'timeFrom': '12:15',
      'scheduleFk': 44941559,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 12:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 26,
      'dateFrom': '2019-02-25T11:30:00.000+0000',
      'dateTo': '2019-02-25T11:45:00.000+0000',
      'timeFrom': '12:30',
      'scheduleFk': 44941560,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 12:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 7,
      'dateFrom': '2019-02-25T11:45:00.000+0000',
      'dateTo': '2019-02-25T12:00:00.000+0000',
      'timeFrom': '12:45',
      'scheduleFk': 44941561,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 12:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 89,
      'dateFrom': '2019-02-25T12:00:00.000+0000',
      'dateTo': '2019-02-25T12:15:00.000+0000',
      'timeFrom': '13:00',
      'scheduleFk': 44941562,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 13:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 61,
      'dateFrom': '2019-02-25T12:15:00.000+0000',
      'dateTo': '2019-02-25T12:30:00.000+0000',
      'timeFrom': '13:15',
      'scheduleFk': 44941563,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 13:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 149,
      'dateFrom': '2019-02-25T12:30:00.000+0000',
      'dateTo': '2019-02-25T13:15:00.000+0000',
      'timeFrom': '13:30',
      'createDateTime': '2019-02-25 13:23:25',
      'scheduleFk': 44941564,
      'calendarFk': 44964934,
      'calendarStatus': 'D',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'participantName': 'Perka Fruzsina #44872635',
      'longDescription': 'Perka Fruzsina 111946495,A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 13:30',
      'participantDetail': 'Perka Fruzsina ',
      'creatorName': 'Gecző Gergő József'
    },
    {
      'id': 5,
      'dateFrom': '2019-02-25T13:15:00.000+0000',
      'dateTo': '2019-02-25T13:30:00.000+0000',
      'timeFrom': '14:15',
      'scheduleFk': 44941567,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 14:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 70,
      'dateFrom': '2019-02-25T13:30:00.000+0000',
      'dateTo': '2019-02-25T13:45:00.000+0000',
      'timeFrom': '14:30',
      'scheduleFk': 44941568,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 14:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 126,
      'dateFrom': '2019-02-25T13:45:00.000+0000',
      'dateTo': '2019-02-25T14:00:00.000+0000',
      'timeFrom': '14:45',
      'scheduleFk': 44941569,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 14:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 123,
      'dateFrom': '2019-02-25T14:00:00.000+0000',
      'dateTo': '2019-02-25T14:15:00.000+0000',
      'timeFrom': '15:00',
      'scheduleFk': 44941570,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 15:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 148,
      'dateFrom': '2019-02-25T14:15:00.000+0000',
      'dateTo': '2019-02-25T15:00:00.000+0000',
      'timeFrom': '15:15',
      'createDateTime': '2019-02-25 13:57:26',
      'scheduleFk': 44941571,
      'calendarFk': 44965458,
      'calendarStatus': 'D',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'participantName': 'Perka Fruzsina #44872635',
      'longDescription': 'Perka Fruzsina 111946495,A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 15:15',
      'calendarComment': 'Fruzsó',
      'participantDetail': 'Perka Fruzsina ',
      'creatorName': 'Gecző Gergő József'
    },
    {
      'id': 160,
      'dateFrom': '2019-02-26T07:00:00.000+0000',
      'dateTo': '2019-02-26T14:00:00.000+0000',
      'timeFrom': '08:00',
      'createDateTime': '2019-02-25 14:09:30',
      'scheduleFk': 44941574,
      'calendarFk': 44965650,
      'calendarStatus': 'S',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'participantName': 'Alias teszt',
      'longDescription': '  ,A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.26 08:00',
      'calendarComment': 'Alias öüőéíyűőü',
      'participantDetail': 'Alias teszt',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 159,
      'dateFrom': '2019-02-26T14:00:00.000+0000',
      'dateTo': '2019-02-26T15:00:00.000+0000',
      'timeFrom': '15:00',
      'createDateTime': '2019-02-25 14:11:14',
      'scheduleFk': 44941602,
      'calendarFk': 44965678,
      'calendarStatus': 'S',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'participantName': 'alias 2',
      'longDescription': '  ,A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.26 15:00',
      'calendarComment': 'asdasd',
      'participantDetail': 'alias 2',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 25,
      'dateFrom': '2019-02-27T07:00:00.000+0000',
      'dateTo': '2019-02-27T07:15:00.000+0000',
      'timeFrom': '08:00',
      'scheduleFk': 44941606,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 08:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 158,
      'dateFrom': '2019-02-27T07:15:00.000+0000',
      'dateTo': '2019-02-27T07:30:00.000+0000',
      'timeFrom': '08:15',
      'createDateTime': '2019-02-25 15:47:25',
      'scheduleFk': 44941607,
      'calendarFk': 44967124,
      'calendarStatus': 'S',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'participantName': 'nagy József 20/9120740',
      'longDescription': '  ,A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 08:15',
      'participantDetail': 'nagy József 20/9120740',
      'creatorName': 'Nagy József'
    },
    {
      'id': 75,
      'dateFrom': '2019-02-27T07:30:00.000+0000',
      'dateTo': '2019-02-27T07:45:00.000+0000',
      'timeFrom': '08:30',
      'scheduleFk': 44941608,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 08:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 154,
      'dateFrom': '2019-02-27T07:45:00.000+0000',
      'dateTo': '2019-02-27T08:30:00.000+0000',
      'timeFrom': '08:45',
      'createDateTime': '2019-02-25 15:48:13',
      'scheduleFk': 44941609,
      'calendarFk': 44967130,
      'calendarStatus': 'D',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'participantName': 'Gaál Katalin Ildikó #44872755',
      'longDescription': 'Gaál Katalin Ildikó112438779,A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 08:45',
      'participantDetail': 'Gaál Katalin Ildikó ',
      'creatorName': 'Gaál Katalin Ildikó'
    },
    {
      'id': 156,
      'dateFrom': '2019-02-27T08:30:00.000+0000',
      'dateTo': '2019-02-27T09:00:00.000+0000',
      'timeFrom': '09:30',
      'createDateTime': '2019-02-27 08:38:36',
      'scheduleFk': 44941612,
      'calendarFk': 44994414,
      'calendarStatus': 'D',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'participantName': 'Perka Fruzsina #44872635',
      'longDescription': 'Perka Fruzsina 111946495,A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 09:30',
      'participantDetail': 'Perka Fruzsina ',
      'creatorName': 'Perka Fruzsina'
    },
    {
      'id': 68,
      'dateFrom': '2019-02-27T09:00:00.000+0000',
      'dateTo': '2019-02-27T09:15:00.000+0000',
      'timeFrom': '10:00',
      'scheduleFk': 44941614,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 10:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 40,
      'dateFrom': '2019-02-27T09:15:00.000+0000',
      'dateTo': '2019-02-27T09:30:00.000+0000',
      'timeFrom': '10:15',
      'scheduleFk': 44941615,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 10:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 91,
      'dateFrom': '2019-02-27T09:30:00.000+0000',
      'dateTo': '2019-02-27T09:45:00.000+0000',
      'timeFrom': '10:30',
      'scheduleFk': 44941616,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 10:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 146,
      'dateFrom': '2019-02-27T09:45:00.000+0000',
      'dateTo': '2019-02-27T10:00:00.000+0000',
      'timeFrom': '10:45',
      'scheduleFk': 44941617,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 10:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 14,
      'dateFrom': '2019-02-27T10:00:00.000+0000',
      'dateTo': '2019-02-27T10:15:00.000+0000',
      'timeFrom': '11:00',
      'scheduleFk': 44941618,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 11:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 19,
      'dateFrom': '2019-02-27T10:15:00.000+0000',
      'dateTo': '2019-02-27T10:30:00.000+0000',
      'timeFrom': '11:15',
      'scheduleFk': 44941619,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 11:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 100,
      'dateFrom': '2019-02-27T10:30:00.000+0000',
      'dateTo': '2019-02-27T10:45:00.000+0000',
      'timeFrom': '11:30',
      'scheduleFk': 44941620,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 11:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 76,
      'dateFrom': '2019-02-27T10:45:00.000+0000',
      'dateTo': '2019-02-27T11:00:00.000+0000',
      'timeFrom': '11:45',
      'scheduleFk': 44941621,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 11:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 74,
      'dateFrom': '2019-02-27T11:00:00.000+0000',
      'dateTo': '2019-02-27T11:15:00.000+0000',
      'timeFrom': '12:00',
      'scheduleFk': 44941622,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 12:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 56,
      'dateFrom': '2019-02-27T11:15:00.000+0000',
      'dateTo': '2019-02-27T11:30:00.000+0000',
      'timeFrom': '12:15',
      'scheduleFk': 44941623,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 12:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 8,
      'dateFrom': '2019-02-27T11:30:00.000+0000',
      'dateTo': '2019-02-27T11:45:00.000+0000',
      'timeFrom': '12:30',
      'scheduleFk': 44941624,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 12:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 116,
      'dateFrom': '2019-02-27T11:45:00.000+0000',
      'dateTo': '2019-02-27T12:00:00.000+0000',
      'timeFrom': '12:45',
      'scheduleFk': 44941625,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 12:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 99,
      'dateFrom': '2019-02-27T12:00:00.000+0000',
      'dateTo': '2019-02-27T12:15:00.000+0000',
      'timeFrom': '13:00',
      'scheduleFk': 44941626,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 13:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 69,
      'dateFrom': '2019-02-27T12:15:00.000+0000',
      'dateTo': '2019-02-27T12:30:00.000+0000',
      'timeFrom': '13:15',
      'scheduleFk': 44941627,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 13:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 41,
      'dateFrom': '2019-02-27T12:30:00.000+0000',
      'dateTo': '2019-02-27T12:45:00.000+0000',
      'timeFrom': '13:30',
      'scheduleFk': 44941628,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 13:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 4,
      'dateFrom': '2019-02-27T12:45:00.000+0000',
      'dateTo': '2019-02-27T13:00:00.000+0000',
      'timeFrom': '13:45',
      'scheduleFk': 44941629,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 13:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 39,
      'dateFrom': '2019-02-27T13:00:00.000+0000',
      'dateTo': '2019-02-27T13:15:00.000+0000',
      'timeFrom': '14:00',
      'scheduleFk': 44941630,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 14:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 42,
      'dateFrom': '2019-02-27T13:15:00.000+0000',
      'dateTo': '2019-02-27T13:30:00.000+0000',
      'timeFrom': '14:15',
      'scheduleFk': 44941631,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 14:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 96,
      'dateFrom': '2019-02-27T13:30:00.000+0000',
      'dateTo': '2019-02-27T13:45:00.000+0000',
      'timeFrom': '14:30',
      'scheduleFk': 44941632,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 14:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 82,
      'dateFrom': '2019-02-27T13:45:00.000+0000',
      'dateTo': '2019-02-27T14:00:00.000+0000',
      'timeFrom': '14:45',
      'scheduleFk': 44941633,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 14:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 143,
      'dateFrom': '2019-02-27T14:00:00.000+0000',
      'dateTo': '2019-02-27T14:15:00.000+0000',
      'timeFrom': '15:00',
      'scheduleFk': 44941634,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 15:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 38,
      'dateFrom': '2019-02-27T14:15:00.000+0000',
      'dateTo': '2019-02-27T14:30:00.000+0000',
      'timeFrom': '15:15',
      'scheduleFk': 44941635,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 15:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 135,
      'dateFrom': '2019-02-27T14:30:00.000+0000',
      'dateTo': '2019-02-27T14:45:00.000+0000',
      'timeFrom': '15:30',
      'scheduleFk': 44941636,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 15:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 16,
      'dateFrom': '2019-02-27T14:45:00.000+0000',
      'dateTo': '2019-02-27T15:00:00.000+0000',
      'timeFrom': '15:45',
      'scheduleFk': 44941637,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 15:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 138,
      'dateFrom': '2019-02-28T07:00:00.000+0000',
      'dateTo': '2019-02-28T07:15:00.000+0000',
      'timeFrom': '08:00',
      'scheduleFk': 44941638,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 08:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 106,
      'dateFrom': '2019-02-28T07:15:00.000+0000',
      'dateTo': '2019-02-28T07:30:00.000+0000',
      'timeFrom': '08:15',
      'scheduleFk': 44941639,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 08:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 125,
      'dateFrom': '2019-02-28T07:30:00.000+0000',
      'dateTo': '2019-02-28T07:45:00.000+0000',
      'timeFrom': '08:30',
      'scheduleFk': 44941640,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 08:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 77,
      'dateFrom': '2019-02-28T07:45:00.000+0000',
      'dateTo': '2019-02-28T08:00:00.000+0000',
      'timeFrom': '08:45',
      'scheduleFk': 44941641,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 08:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 153,
      'dateFrom': '2019-02-28T08:00:00.000+0000',
      'dateTo': '2019-02-28T09:00:00.000+0000',
      'timeFrom': '09:00',
      'createDateTime': '2019-02-26 13:09:22',
      'scheduleFk': 44941642,
      'calendarFk': 44982995,
      'calendarStatus': 'D',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'participantName': 'Gaál Katalin Ildikó #44872755',
      'longDescription': 'Gaál Katalin Ildikó112438779,A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 09:00',
      'participantDetail': 'Gaál Katalin Ildikó ',
      'creatorName': 'Gaál Katalin Ildikó'
    },
    {
      'id': 63,
      'dateFrom': '2019-02-28T09:00:00.000+0000',
      'dateTo': '2019-02-28T09:15:00.000+0000',
      'timeFrom': '10:00',
      'scheduleFk': 44941646,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 10:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 6,
      'dateFrom': '2019-02-28T09:15:00.000+0000',
      'dateTo': '2019-02-28T09:30:00.000+0000',
      'timeFrom': '10:15',
      'scheduleFk': 44941647,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 10:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 97,
      'dateFrom': '2019-02-28T09:30:00.000+0000',
      'dateTo': '2019-02-28T09:45:00.000+0000',
      'timeFrom': '10:30',
      'scheduleFk': 44941648,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 10:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 152,
      'dateFrom': '2019-02-28T09:45:00.000+0000',
      'dateTo': '2019-02-28T10:45:00.000+0000',
      'timeFrom': '10:45',
      'createDateTime': '2019-02-27 08:43:59',
      'scheduleFk': 44941649,
      'calendarFk': 44994489,
      'calendarStatus': 'D',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'participantName': 'Gaál Katalin Ildikó #44872755',
      'longDescription': 'Gaál Katalin Ildikó112438779,A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 10:45',
      'participantDetail': 'Gaál Katalin Ildikó ',
      'creatorName': 'Gaál Katalin Ildikó'
    },
    {
      'id': 139,
      'dateFrom': '2019-02-28T10:45:00.000+0000',
      'dateTo': '2019-02-28T11:00:00.000+0000',
      'timeFrom': '11:45',
      'scheduleFk': 44941653,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 11:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 119,
      'dateFrom': '2019-02-28T11:00:00.000+0000',
      'dateTo': '2019-02-28T11:15:00.000+0000',
      'timeFrom': '12:00',
      'scheduleFk': 44941654,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 12:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 151,
      'dateFrom': '2019-02-28T11:15:00.000+0000',
      'dateTo': '2019-02-28T12:45:00.000+0000',
      'timeFrom': '12:15',
      'createDateTime': '2019-02-28 08:42:56',
      'scheduleFk': 44941655,
      'calendarFk': 45012184,
      'calendarStatus': 'D',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'participantName': 'Gaál Katalin Ildikó #44872755',
      'longDescription': 'Gaál Katalin Ildikó112438779,A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 12:15',
      'participantDetail': 'Gaál Katalin Ildikó ',
      'creatorName': 'Gaál Katalin Ildikó'
    },
    {
      'id': 130,
      'dateFrom': '2019-02-28T12:45:00.000+0000',
      'dateTo': '2019-02-28T13:00:00.000+0000',
      'timeFrom': '13:45',
      'scheduleFk': 44941661,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 13:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 23,
      'dateFrom': '2019-02-28T13:00:00.000+0000',
      'dateTo': '2019-02-28T13:15:00.000+0000',
      'timeFrom': '14:00',
      'scheduleFk': 44941662,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 14:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 85,
      'dateFrom': '2019-02-28T13:15:00.000+0000',
      'dateTo': '2019-02-28T13:30:00.000+0000',
      'timeFrom': '14:15',
      'scheduleFk': 44941663,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 14:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 10,
      'dateFrom': '2019-02-28T13:30:00.000+0000',
      'dateTo': '2019-02-28T13:45:00.000+0000',
      'timeFrom': '14:30',
      'scheduleFk': 44941664,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 14:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 155,
      'dateFrom': '2019-02-28T13:45:00.000+0000',
      'dateTo': '2019-02-28T14:00:00.000+0000',
      'timeFrom': '14:45',
      'createDateTime': '2019-02-28 13:00:51',
      'scheduleFk': 44941665,
      'calendarFk': 45017667,
      'calendarStatus': 'D',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'participantName': 'Perka Fruzsina #44872635',
      'longDescription': 'Perka Fruzsina 111946495,A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 14:45',
      'participantDetail': 'Perka Fruzsina ',
      'creatorName': 'Perka Fruzsina'
    },
    {
      'id': 45,
      'dateFrom': '2019-02-28T14:00:00.000+0000',
      'dateTo': '2019-02-28T14:15:00.000+0000',
      'timeFrom': '15:00',
      'scheduleFk': 44941666,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 15:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 62,
      'dateFrom': '2019-02-28T14:15:00.000+0000',
      'dateTo': '2019-02-28T14:30:00.000+0000',
      'timeFrom': '15:15',
      'scheduleFk': 44941667,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 15:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 129,
      'dateFrom': '2019-02-28T14:30:00.000+0000',
      'dateTo': '2019-02-28T14:45:00.000+0000',
      'timeFrom': '15:30',
      'scheduleFk': 44941668,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 15:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 33,
      'dateFrom': '2019-02-28T14:45:00.000+0000',
      'dateTo': '2019-02-28T15:00:00.000+0000',
      'timeFrom': '15:45',
      'scheduleFk': 44941669,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 15:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 22,
      'dateFrom': '2019-03-01T07:00:00.000+0000',
      'dateTo': '2019-03-01T07:15:00.000+0000',
      'timeFrom': '08:00',
      'scheduleFk': 44941670,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 08:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 131,
      'dateFrom': '2019-03-01T07:15:00.000+0000',
      'dateTo': '2019-03-01T07:30:00.000+0000',
      'timeFrom': '08:15',
      'scheduleFk': 44941671,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 08:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 17,
      'dateFrom': '2019-03-01T07:30:00.000+0000',
      'dateTo': '2019-03-01T07:45:00.000+0000',
      'timeFrom': '08:30',
      'scheduleFk': 44941672,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 08:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 55,
      'dateFrom': '2019-03-01T07:45:00.000+0000',
      'dateTo': '2019-03-01T08:00:00.000+0000',
      'timeFrom': '08:45',
      'scheduleFk': 44941673,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 08:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 28,
      'dateFrom': '2019-03-01T08:00:00.000+0000',
      'dateTo': '2019-03-01T08:15:00.000+0000',
      'timeFrom': '09:00',
      'scheduleFk': 44941674,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 09:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 65,
      'dateFrom': '2019-03-01T08:15:00.000+0000',
      'dateTo': '2019-03-01T08:30:00.000+0000',
      'timeFrom': '09:15',
      'scheduleFk': 44941675,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 09:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 67,
      'dateFrom': '2019-03-01T08:30:00.000+0000',
      'dateTo': '2019-03-01T08:45:00.000+0000',
      'timeFrom': '09:30',
      'scheduleFk': 44941676,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 09:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 113,
      'dateFrom': '2019-03-01T08:45:00.000+0000',
      'dateTo': '2019-03-01T09:00:00.000+0000',
      'timeFrom': '09:45',
      'scheduleFk': 44941677,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 09:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 29,
      'dateFrom': '2019-03-01T09:00:00.000+0000',
      'dateTo': '2019-03-01T09:15:00.000+0000',
      'timeFrom': '10:00',
      'scheduleFk': 44941678,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 10:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 103,
      'dateFrom': '2019-03-01T09:15:00.000+0000',
      'dateTo': '2019-03-01T09:30:00.000+0000',
      'timeFrom': '10:15',
      'scheduleFk': 44941679,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 10:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 64,
      'dateFrom': '2019-03-01T09:30:00.000+0000',
      'dateTo': '2019-03-01T09:45:00.000+0000',
      'timeFrom': '10:30',
      'scheduleFk': 44941680,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 10:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 80,
      'dateFrom': '2019-03-01T09:45:00.000+0000',
      'dateTo': '2019-03-01T10:00:00.000+0000',
      'timeFrom': '10:45',
      'scheduleFk': 44941681,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 10:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 110,
      'dateFrom': '2019-03-01T10:00:00.000+0000',
      'dateTo': '2019-03-01T10:15:00.000+0000',
      'timeFrom': '11:00',
      'scheduleFk': 44941682,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 11:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 87,
      'dateFrom': '2019-03-01T10:15:00.000+0000',
      'dateTo': '2019-03-01T10:30:00.000+0000',
      'timeFrom': '11:15',
      'scheduleFk': 44941683,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 11:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 144,
      'dateFrom': '2019-03-01T10:30:00.000+0000',
      'dateTo': '2019-03-01T10:45:00.000+0000',
      'timeFrom': '11:30',
      'scheduleFk': 44941684,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 11:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 118,
      'dateFrom': '2019-03-01T10:45:00.000+0000',
      'dateTo': '2019-03-01T11:00:00.000+0000',
      'timeFrom': '11:45',
      'scheduleFk': 44941685,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 11:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 73,
      'dateFrom': '2019-03-01T11:00:00.000+0000',
      'dateTo': '2019-03-01T11:15:00.000+0000',
      'timeFrom': '12:00',
      'scheduleFk': 44941686,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 12:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 111,
      'dateFrom': '2019-03-01T11:15:00.000+0000',
      'dateTo': '2019-03-01T11:30:00.000+0000',
      'timeFrom': '12:15',
      'scheduleFk': 44941687,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 12:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 115,
      'dateFrom': '2019-03-01T11:30:00.000+0000',
      'dateTo': '2019-03-01T11:45:00.000+0000',
      'timeFrom': '12:30',
      'scheduleFk': 44941688,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 12:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 133,
      'dateFrom': '2019-03-01T11:45:00.000+0000',
      'dateTo': '2019-03-01T12:00:00.000+0000',
      'timeFrom': '12:45',
      'scheduleFk': 44941689,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 12:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 37,
      'dateFrom': '2019-03-01T12:00:00.000+0000',
      'dateTo': '2019-03-01T12:15:00.000+0000',
      'timeFrom': '13:00',
      'scheduleFk': 44941690,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 13:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 79,
      'dateFrom': '2019-03-01T12:15:00.000+0000',
      'dateTo': '2019-03-01T12:30:00.000+0000',
      'timeFrom': '13:15',
      'scheduleFk': 44941691,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 13:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 43,
      'dateFrom': '2019-03-01T12:30:00.000+0000',
      'dateTo': '2019-03-01T12:45:00.000+0000',
      'timeFrom': '13:30',
      'scheduleFk': 44941692,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 13:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 12,
      'dateFrom': '2019-03-01T12:45:00.000+0000',
      'dateTo': '2019-03-01T13:00:00.000+0000',
      'timeFrom': '13:45',
      'scheduleFk': 44941693,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 13:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 140,
      'dateFrom': '2019-03-01T13:00:00.000+0000',
      'dateTo': '2019-03-01T13:15:00.000+0000',
      'timeFrom': '14:00',
      'scheduleFk': 44941694,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 14:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 72,
      'dateFrom': '2019-03-01T13:15:00.000+0000',
      'dateTo': '2019-03-01T13:30:00.000+0000',
      'timeFrom': '14:15',
      'scheduleFk': 44941695,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 14:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 54,
      'dateFrom': '2019-03-01T13:30:00.000+0000',
      'dateTo': '2019-03-01T13:45:00.000+0000',
      'timeFrom': '14:30',
      'scheduleFk': 44941696,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 14:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 142,
      'dateFrom': '2019-03-01T13:45:00.000+0000',
      'dateTo': '2019-03-01T14:00:00.000+0000',
      'timeFrom': '14:45',
      'scheduleFk': 44941697,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 14:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 102,
      'dateFrom': '2019-03-01T14:00:00.000+0000',
      'dateTo': '2019-03-01T14:15:00.000+0000',
      'timeFrom': '15:00',
      'scheduleFk': 44941698,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 15:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 9,
      'dateFrom': '2019-03-01T14:15:00.000+0000',
      'dateTo': '2019-03-01T14:30:00.000+0000',
      'timeFrom': '15:15',
      'scheduleFk': 44941699,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 15:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 88,
      'dateFrom': '2019-03-01T14:30:00.000+0000',
      'dateTo': '2019-03-01T14:45:00.000+0000',
      'timeFrom': '15:30',
      'scheduleFk': 44941700,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 15:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 52,
      'dateFrom': '2019-03-01T14:45:00.000+0000',
      'dateTo': '2019-03-01T15:00:00.000+0000',
      'timeFrom': '15:45',
      'scheduleFk': 44941701,
      'calendarStatus': 'F',
      'servicePointFk': 44938985,
      'servicePointName': 'A Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941181,
      'longDescription': ',A Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 15:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 16,
      'dateFrom': '2019-02-25T07:00:00.000+0000',
      'dateTo': '2019-02-25T07:15:00.000+0000',
      'timeFrom': '08:00',
      'scheduleFk': 44942376,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 08:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 73,
      'dateFrom': '2019-02-25T07:15:00.000+0000',
      'dateTo': '2019-02-25T07:30:00.000+0000',
      'timeFrom': '08:15',
      'scheduleFk': 44942377,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 08:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 77,
      'dateFrom': '2019-02-25T07:30:00.000+0000',
      'dateTo': '2019-02-25T07:45:00.000+0000',
      'timeFrom': '08:30',
      'scheduleFk': 44942378,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 08:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 48,
      'dateFrom': '2019-02-25T07:45:00.000+0000',
      'dateTo': '2019-02-25T08:00:00.000+0000',
      'timeFrom': '08:45',
      'scheduleFk': 44942379,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 08:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 36,
      'dateFrom': '2019-02-25T08:00:00.000+0000',
      'dateTo': '2019-02-25T08:15:00.000+0000',
      'timeFrom': '09:00',
      'scheduleFk': 44942380,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 09:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 89,
      'dateFrom': '2019-02-25T08:15:00.000+0000',
      'dateTo': '2019-02-25T08:30:00.000+0000',
      'timeFrom': '09:15',
      'scheduleFk': 44942381,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 09:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 72,
      'dateFrom': '2019-02-25T08:30:00.000+0000',
      'dateTo': '2019-02-25T08:45:00.000+0000',
      'timeFrom': '09:30',
      'scheduleFk': 44942382,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 09:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 37,
      'dateFrom': '2019-02-25T08:45:00.000+0000',
      'dateTo': '2019-02-25T09:00:00.000+0000',
      'timeFrom': '09:45',
      'scheduleFk': 44942383,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 09:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 30,
      'dateFrom': '2019-02-25T09:00:00.000+0000',
      'dateTo': '2019-02-25T09:15:00.000+0000',
      'timeFrom': '10:00',
      'scheduleFk': 44942384,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 10:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 128,
      'dateFrom': '2019-02-25T09:15:00.000+0000',
      'dateTo': '2019-02-25T09:30:00.000+0000',
      'timeFrom': '10:15',
      'scheduleFk': 44942385,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 10:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 68,
      'dateFrom': '2019-02-25T09:30:00.000+0000',
      'dateTo': '2019-02-25T09:45:00.000+0000',
      'timeFrom': '10:30',
      'scheduleFk': 44942386,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 10:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 91,
      'dateFrom': '2019-02-25T09:45:00.000+0000',
      'dateTo': '2019-02-25T10:00:00.000+0000',
      'timeFrom': '10:45',
      'scheduleFk': 44942387,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 10:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 96,
      'dateFrom': '2019-02-25T10:00:00.000+0000',
      'dateTo': '2019-02-25T10:15:00.000+0000',
      'timeFrom': '11:00',
      'scheduleFk': 44942388,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 11:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 133,
      'dateFrom': '2019-02-25T10:15:00.000+0000',
      'dateTo': '2019-02-25T10:30:00.000+0000',
      'timeFrom': '11:15',
      'scheduleFk': 44942389,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 11:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 131,
      'dateFrom': '2019-02-25T10:30:00.000+0000',
      'dateTo': '2019-02-25T10:45:00.000+0000',
      'timeFrom': '11:30',
      'scheduleFk': 44942390,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 11:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 31,
      'dateFrom': '2019-02-25T10:45:00.000+0000',
      'dateTo': '2019-02-25T11:00:00.000+0000',
      'timeFrom': '11:45',
      'scheduleFk': 44942391,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 11:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 106,
      'dateFrom': '2019-02-25T11:00:00.000+0000',
      'dateTo': '2019-02-25T11:15:00.000+0000',
      'timeFrom': '12:00',
      'scheduleFk': 44942392,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 12:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 53,
      'dateFrom': '2019-02-25T11:15:00.000+0000',
      'dateTo': '2019-02-25T11:30:00.000+0000',
      'timeFrom': '12:15',
      'scheduleFk': 44942393,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 12:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 129,
      'dateFrom': '2019-02-25T11:30:00.000+0000',
      'dateTo': '2019-02-25T11:45:00.000+0000',
      'timeFrom': '12:30',
      'scheduleFk': 44942394,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 12:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 60,
      'dateFrom': '2019-02-25T11:45:00.000+0000',
      'dateTo': '2019-02-25T12:00:00.000+0000',
      'timeFrom': '12:45',
      'scheduleFk': 44942395,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 12:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 130,
      'dateFrom': '2019-02-25T12:00:00.000+0000',
      'dateTo': '2019-02-25T12:15:00.000+0000',
      'timeFrom': '13:00',
      'scheduleFk': 44942396,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 13:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 103,
      'dateFrom': '2019-02-25T12:15:00.000+0000',
      'dateTo': '2019-02-25T12:30:00.000+0000',
      'timeFrom': '13:15',
      'scheduleFk': 44942397,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 13:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 40,
      'dateFrom': '2019-02-25T12:30:00.000+0000',
      'dateTo': '2019-02-25T12:45:00.000+0000',
      'timeFrom': '13:30',
      'scheduleFk': 44942398,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 13:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 99,
      'dateFrom': '2019-02-25T12:45:00.000+0000',
      'dateTo': '2019-02-25T13:00:00.000+0000',
      'timeFrom': '13:45',
      'scheduleFk': 44942399,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 13:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 92,
      'dateFrom': '2019-02-25T13:00:00.000+0000',
      'dateTo': '2019-02-25T13:15:00.000+0000',
      'timeFrom': '14:00',
      'scheduleFk': 44942400,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 14:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 5,
      'dateFrom': '2019-02-25T13:15:00.000+0000',
      'dateTo': '2019-02-25T13:30:00.000+0000',
      'timeFrom': '14:15',
      'scheduleFk': 44942401,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 14:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 155,
      'dateFrom': '2019-02-25T13:30:00.000+0000',
      'dateTo': '2019-02-25T15:00:00.000+0000',
      'timeFrom': '14:30',
      'createDateTime': '2019-02-25 13:30:43',
      'scheduleFk': 44942402,
      'calendarFk': 44965107,
      'calendarStatus': 'D',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'participantName': 'Gaál Katalin Ildikó #44872755',
      'longDescription': 'Gaál Katalin Ildikó112438779,B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.25 14:30',
      'participantDetail': 'Gaál Katalin Ildikó ',
      'creatorName': 'Perka Fruzsina'
    },
    {
      'id': 160,
      'dateFrom': '2019-02-26T07:00:00.000+0000',
      'dateTo': '2019-02-26T08:00:00.000+0000',
      'timeFrom': '08:00',
      'createDateTime': '2019-02-25 14:12:31',
      'scheduleFk': 44942408,
      'calendarFk': 44965725,
      'calendarStatus': 'S',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'participantName': 'teve',
      'longDescription': '  ,B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.26 08:00',
      'participantDetail': 'teve',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 157,
      'dateFrom': '2019-02-26T08:00:00.000+0000',
      'dateTo': '2019-02-26T09:00:00.000+0000',
      'timeFrom': '09:00',
      'createDateTime': '2019-02-25 14:24:28',
      'scheduleFk': 44942412,
      'calendarFk': 44965989,
      'calendarStatus': 'D',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'participantName': 'Pikó Tamás #40122593',
      'longDescription': 'Pikó Tamás 042985271,B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.26 09:00',
      'participantDetail': 'Pikó Tamás ',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 147,
      'dateFrom': '2019-02-26T09:00:00.000+0000',
      'dateTo': '2019-02-26T10:15:00.000+0000',
      'timeFrom': '10:00',
      'createDateTime': '2019-02-25 16:21:46',
      'scheduleFk': 44942416,
      'calendarFk': 44969905,
      'calendarStatus': 'D',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'participantName': 'Gaál Katalin Ildikó #44872755',
      'longDescription': 'Gaál Katalin Ildikó112438779,B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.26 10:00',
      'participantDetail': 'Gaál Katalin Ildikó ',
      'creatorName': 'Gaál Katalin Ildikó'
    },
    {
      'id': 154,
      'dateFrom': '2019-02-26T10:15:00.000+0000',
      'dateTo': '2019-02-26T11:00:00.000+0000',
      'timeFrom': '11:15',
      'createDateTime': '2019-02-26 08:49:07',
      'scheduleFk': 44942421,
      'calendarFk': 44975200,
      'calendarStatus': 'D',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'participantName': 'Perka Fruzsina #44872635',
      'longDescription': 'Perka Fruzsina 111946495,B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.26 11:15',
      'participantDetail': 'Perka Fruzsina ',
      'creatorName': 'Perka Fruzsina'
    },
    {
      'id': 35,
      'dateFrom': '2019-02-26T11:00:00.000+0000',
      'dateTo': '2019-02-26T11:15:00.000+0000',
      'timeFrom': '12:00',
      'scheduleFk': 44942424,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.26 12:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 116,
      'dateFrom': '2019-02-26T11:15:00.000+0000',
      'dateTo': '2019-02-26T11:30:00.000+0000',
      'timeFrom': '12:15',
      'scheduleFk': 44942425,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.26 12:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 110,
      'dateFrom': '2019-02-26T11:30:00.000+0000',
      'dateTo': '2019-02-26T11:45:00.000+0000',
      'timeFrom': '12:30',
      'scheduleFk': 44942426,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.26 12:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 132,
      'dateFrom': '2019-02-26T11:45:00.000+0000',
      'dateTo': '2019-02-26T12:00:00.000+0000',
      'timeFrom': '12:45',
      'scheduleFk': 44942427,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.26 12:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 136,
      'dateFrom': '2019-02-26T12:00:00.000+0000',
      'dateTo': '2019-02-26T12:15:00.000+0000',
      'timeFrom': '13:00',
      'scheduleFk': 44942428,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.26 13:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 94,
      'dateFrom': '2019-02-26T12:15:00.000+0000',
      'dateTo': '2019-02-26T12:30:00.000+0000',
      'timeFrom': '13:15',
      'scheduleFk': 44942429,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.26 13:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 17,
      'dateFrom': '2019-02-26T12:30:00.000+0000',
      'dateTo': '2019-02-26T12:45:00.000+0000',
      'timeFrom': '13:30',
      'scheduleFk': 44942430,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.26 13:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 23,
      'dateFrom': '2019-02-26T12:45:00.000+0000',
      'dateTo': '2019-02-26T13:00:00.000+0000',
      'timeFrom': '13:45',
      'scheduleFk': 44942431,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.26 13:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 51,
      'dateFrom': '2019-02-26T13:00:00.000+0000',
      'dateTo': '2019-02-26T13:15:00.000+0000',
      'timeFrom': '14:00',
      'scheduleFk': 44942432,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.26 14:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 143,
      'dateFrom': '2019-02-26T13:15:00.000+0000',
      'dateTo': '2019-02-26T13:30:00.000+0000',
      'timeFrom': '14:15',
      'scheduleFk': 44942433,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.26 14:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 29,
      'dateFrom': '2019-02-26T13:30:00.000+0000',
      'dateTo': '2019-02-26T13:45:00.000+0000',
      'timeFrom': '14:30',
      'scheduleFk': 44942434,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.26 14:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 18,
      'dateFrom': '2019-02-26T13:45:00.000+0000',
      'dateTo': '2019-02-26T14:00:00.000+0000',
      'timeFrom': '14:45',
      'scheduleFk': 44942435,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.26 14:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 90,
      'dateFrom': '2019-02-26T14:00:00.000+0000',
      'dateTo': '2019-02-26T14:15:00.000+0000',
      'timeFrom': '15:00',
      'scheduleFk': 44942436,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.26 15:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 82,
      'dateFrom': '2019-02-26T14:15:00.000+0000',
      'dateTo': '2019-02-26T14:30:00.000+0000',
      'timeFrom': '15:15',
      'scheduleFk': 44942437,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.26 15:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 12,
      'dateFrom': '2019-02-26T14:30:00.000+0000',
      'dateTo': '2019-02-26T14:45:00.000+0000',
      'timeFrom': '15:30',
      'scheduleFk': 44942438,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.26 15:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 22,
      'dateFrom': '2019-02-26T14:45:00.000+0000',
      'dateTo': '2019-02-26T15:00:00.000+0000',
      'timeFrom': '15:45',
      'scheduleFk': 44942439,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.26 15:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 156,
      'dateFrom': '2019-02-27T07:00:00.000+0000',
      'dateTo': '2019-02-27T07:15:00.000+0000',
      'timeFrom': '08:00',
      'createDateTime': '2019-02-25 14:22:49',
      'scheduleFk': 44942440,
      'calendarFk': 44965973,
      'calendarStatus': 'D',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'participantName': 'Pikó Tamás #40122593',
      'longDescription': 'Pikó Tamás 042985271,B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 08:00',
      'calendarComment': 'asd',
      'participantDetail': 'Pikó Tamás ',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 65,
      'dateFrom': '2019-02-27T07:15:00.000+0000',
      'dateTo': '2019-02-27T07:30:00.000+0000',
      'timeFrom': '08:15',
      'scheduleFk': 44942441,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 08:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 20,
      'dateFrom': '2019-02-27T07:30:00.000+0000',
      'dateTo': '2019-02-27T07:45:00.000+0000',
      'timeFrom': '08:30',
      'scheduleFk': 44942442,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 08:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 153,
      'dateFrom': '2019-02-27T07:45:00.000+0000',
      'dateTo': '2019-02-27T09:00:00.000+0000',
      'timeFrom': '08:45',
      'createDateTime': '2019-02-27 08:28:36',
      'scheduleFk': 44942443,
      'calendarFk': 44992996,
      'calendarStatus': 'D',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'participantName': 'Perka Fruzsina #44872635',
      'longDescription': 'Perka Fruzsina 111946495,B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 08:45',
      'participantDetail': 'Perka Fruzsina ',
      'creatorName': 'Perka Fruzsina'
    },
    {
      'id': 158,
      'dateFrom': '2019-02-27T09:00:00.000+0000',
      'dateTo': '2019-02-27T10:00:00.000+0000',
      'timeFrom': '10:00',
      'createDateTime': '2019-02-25 15:52:52',
      'scheduleFk': 44942448,
      'calendarFk': 44967199,
      'calendarStatus': 'D',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'participantName': 'Zavadovics Ákos #31589725',
      'longDescription': 'Zavadovics Ákos 033033529,B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 10:00',
      'participantDetail': 'Zavadovics Ákos ',
      'creatorName': 'Zavadovics Ákos'
    },
    {
      'id': 109,
      'dateFrom': '2019-02-27T10:00:00.000+0000',
      'dateTo': '2019-02-27T10:15:00.000+0000',
      'timeFrom': '11:00',
      'scheduleFk': 44942452,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 11:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 98,
      'dateFrom': '2019-02-27T10:15:00.000+0000',
      'dateTo': '2019-02-27T10:30:00.000+0000',
      'timeFrom': '11:15',
      'scheduleFk': 44942453,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 11:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 50,
      'dateFrom': '2019-02-27T10:30:00.000+0000',
      'dateTo': '2019-02-27T10:45:00.000+0000',
      'timeFrom': '11:30',
      'scheduleFk': 44942454,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 11:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 13,
      'dateFrom': '2019-02-27T10:45:00.000+0000',
      'dateTo': '2019-02-27T11:00:00.000+0000',
      'timeFrom': '11:45',
      'scheduleFk': 44942455,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 11:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 71,
      'dateFrom': '2019-02-27T11:00:00.000+0000',
      'dateTo': '2019-02-27T11:15:00.000+0000',
      'timeFrom': '12:00',
      'scheduleFk': 44942456,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 12:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 83,
      'dateFrom': '2019-02-27T11:15:00.000+0000',
      'dateTo': '2019-02-27T11:30:00.000+0000',
      'timeFrom': '12:15',
      'scheduleFk': 44942457,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 12:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 67,
      'dateFrom': '2019-02-27T11:30:00.000+0000',
      'dateTo': '2019-02-27T11:45:00.000+0000',
      'timeFrom': '12:30',
      'scheduleFk': 44942458,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 12:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 137,
      'dateFrom': '2019-02-27T11:45:00.000+0000',
      'dateTo': '2019-02-27T12:00:00.000+0000',
      'timeFrom': '12:45',
      'scheduleFk': 44942459,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 12:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 104,
      'dateFrom': '2019-02-27T12:00:00.000+0000',
      'dateTo': '2019-02-27T12:15:00.000+0000',
      'timeFrom': '13:00',
      'scheduleFk': 44942460,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 13:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 9,
      'dateFrom': '2019-02-27T12:15:00.000+0000',
      'dateTo': '2019-02-27T12:30:00.000+0000',
      'timeFrom': '13:15',
      'scheduleFk': 44942461,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 13:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 24,
      'dateFrom': '2019-02-27T12:30:00.000+0000',
      'dateTo': '2019-02-27T12:45:00.000+0000',
      'timeFrom': '13:30',
      'scheduleFk': 44942462,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 13:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 10,
      'dateFrom': '2019-02-27T12:45:00.000+0000',
      'dateTo': '2019-02-27T13:00:00.000+0000',
      'timeFrom': '13:45',
      'scheduleFk': 44942463,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 13:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 145,
      'dateFrom': '2019-02-27T13:00:00.000+0000',
      'dateTo': '2019-02-27T13:15:00.000+0000',
      'timeFrom': '14:00',
      'scheduleFk': 44942464,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 14:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 140,
      'dateFrom': '2019-02-27T13:15:00.000+0000',
      'dateTo': '2019-02-27T13:30:00.000+0000',
      'timeFrom': '14:15',
      'scheduleFk': 44942465,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 14:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 122,
      'dateFrom': '2019-02-27T13:30:00.000+0000',
      'dateTo': '2019-02-27T13:45:00.000+0000',
      'timeFrom': '14:30',
      'scheduleFk': 44942466,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 14:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 79,
      'dateFrom': '2019-02-27T13:45:00.000+0000',
      'dateTo': '2019-02-27T14:00:00.000+0000',
      'timeFrom': '14:45',
      'scheduleFk': 44942467,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 14:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 45,
      'dateFrom': '2019-02-27T14:00:00.000+0000',
      'dateTo': '2019-02-27T14:15:00.000+0000',
      'timeFrom': '15:00',
      'scheduleFk': 44942468,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 15:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 49,
      'dateFrom': '2019-02-27T14:15:00.000+0000',
      'dateTo': '2019-02-27T14:30:00.000+0000',
      'timeFrom': '15:15',
      'scheduleFk': 44942469,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 15:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 139,
      'dateFrom': '2019-02-27T14:30:00.000+0000',
      'dateTo': '2019-02-27T14:45:00.000+0000',
      'timeFrom': '15:30',
      'scheduleFk': 44942470,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 15:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 41,
      'dateFrom': '2019-02-27T14:45:00.000+0000',
      'dateTo': '2019-02-27T15:00:00.000+0000',
      'timeFrom': '15:45',
      'scheduleFk': 44942471,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.27 15:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 152,
      'dateFrom': '2019-02-28T07:00:00.000+0000',
      'dateTo': '2019-02-28T10:15:00.000+0000',
      'timeFrom': '08:00',
      'createDateTime': '2019-02-26 13:29:05',
      'scheduleFk': 44942472,
      'calendarFk': 44983280,
      'calendarStatus': 'D',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'participantName': 'Perka Fruzsina #44872635',
      'longDescription': 'Perka Fruzsina 111946495,B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 08:00',
      'participantDetail': 'Perka Fruzsina ',
      'creatorName': 'Perka Fruzsina'
    },
    {
      'id': 151,
      'dateFrom': '2019-02-28T10:15:00.000+0000',
      'dateTo': '2019-02-28T10:30:00.000+0000',
      'timeFrom': '11:15',
      'createDateTime': '2019-02-28 10:26:34',
      'scheduleFk': 44942485,
      'calendarFk': 45014424,
      'calendarStatus': 'D',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'participantName': 'Perka Fruzsina #44872635',
      'longDescription': 'Perka Fruzsina 111946495,B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 11:15',
      'participantDetail': 'Perka Fruzsina ',
      'creatorName': 'Perka Fruzsina'
    },
    {
      'id': 150,
      'dateFrom': '2019-02-28T10:30:00.000+0000',
      'dateTo': '2019-02-28T10:45:00.000+0000',
      'timeFrom': '11:30',
      'createDateTime': '2019-02-28 10:40:33',
      'scheduleFk': 44942486,
      'calendarFk': 45014645,
      'calendarStatus': 'D',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'participantName': 'Perka Fruzsina #44872635',
      'longDescription': 'Perka Fruzsina 111946495,B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 11:30',
      'participantDetail': 'Perka Fruzsina ',
      'creatorName': 'Perka Fruzsina'
    },
    {
      'id': 149,
      'dateFrom': '2019-02-28T10:45:00.000+0000',
      'dateTo': '2019-02-28T11:00:00.000+0000',
      'timeFrom': '11:45',
      'createDateTime': '2019-02-28 11:31:47',
      'scheduleFk': 44942487,
      'calendarFk': 45015756,
      'calendarStatus': 'D',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'participantName': 'Perka Fruzsina #44872635',
      'longDescription': 'Perka Fruzsina 111946495,B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 11:45',
      'participantDetail': 'Perka Fruzsina ',
      'creatorName': 'Perka Fruzsina'
    },
    {
      'id': 27,
      'dateFrom': '2019-02-28T11:00:00.000+0000',
      'dateTo': '2019-02-28T11:15:00.000+0000',
      'timeFrom': '12:00',
      'scheduleFk': 44942488,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 12:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 46,
      'dateFrom': '2019-02-28T11:15:00.000+0000',
      'dateTo': '2019-02-28T11:30:00.000+0000',
      'timeFrom': '12:15',
      'scheduleFk': 44942489,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 12:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 107,
      'dateFrom': '2019-02-28T11:30:00.000+0000',
      'dateTo': '2019-02-28T11:45:00.000+0000',
      'timeFrom': '12:30',
      'scheduleFk': 44942490,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 12:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 47,
      'dateFrom': '2019-02-28T11:45:00.000+0000',
      'dateTo': '2019-02-28T12:00:00.000+0000',
      'timeFrom': '12:45',
      'scheduleFk': 44942491,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 12:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 148,
      'dateFrom': '2019-02-28T12:00:00.000+0000',
      'dateTo': '2019-02-28T12:45:00.000+0000',
      'timeFrom': '13:00',
      'createDateTime': '2019-02-27 13:14:08',
      'scheduleFk': 44942492,
      'calendarFk': 45003967,
      'calendarStatus': 'D',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'participantName': 'Perka Fruzsina #44872635',
      'longDescription': 'Perka Fruzsina 111946495,B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 13:00',
      'participantDetail': 'Perka Fruzsina ',
      'creatorName': 'Perka Fruzsina'
    },
    {
      'id': 146,
      'dateFrom': '2019-02-28T12:45:00.000+0000',
      'dateTo': '2019-02-28T13:45:00.000+0000',
      'timeFrom': '13:45',
      'createDateTime': '2019-02-27 08:44:15',
      'scheduleFk': 44942495,
      'calendarFk': 44994497,
      'calendarStatus': 'D',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'participantName': 'Gaál Katalin Ildikó #44872755',
      'longDescription': 'Gaál Katalin Ildikó112438779,B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 13:45',
      'participantDetail': 'Gaál Katalin Ildikó ',
      'creatorName': 'Gaál Katalin Ildikó'
    },
    {
      'id': 124,
      'dateFrom': '2019-02-28T13:45:00.000+0000',
      'dateTo': '2019-02-28T14:00:00.000+0000',
      'timeFrom': '14:45',
      'scheduleFk': 44942499,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 14:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 95,
      'dateFrom': '2019-02-28T14:00:00.000+0000',
      'dateTo': '2019-02-28T14:15:00.000+0000',
      'timeFrom': '15:00',
      'scheduleFk': 44942500,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 15:00',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 101,
      'dateFrom': '2019-02-28T14:15:00.000+0000',
      'dateTo': '2019-02-28T14:30:00.000+0000',
      'timeFrom': '15:15',
      'scheduleFk': 44942501,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 15:15',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 38,
      'dateFrom': '2019-02-28T14:30:00.000+0000',
      'dateTo': '2019-02-28T14:45:00.000+0000',
      'timeFrom': '15:30',
      'scheduleFk': 44942502,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 15:30',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 33,
      'dateFrom': '2019-02-28T14:45:00.000+0000',
      'dateTo': '2019-02-28T15:00:00.000+0000',
      'timeFrom': '15:45',
      'scheduleFk': 44942503,
      'calendarStatus': 'F',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'longDescription': ',B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.02.28 15:45',
      'creatorName': 'Pikó Tamás'
    },
    {
      'id': 159,
      'dateFrom': '2019-03-01T07:00:00.000+0000',
      'dateTo': '2019-03-01T15:00:00.000+0000',
      'timeFrom': '08:00',
      'createDateTime': '2019-02-25 15:54:11',
      'scheduleFk': 44942504,
      'calendarFk': 44967207,
      'calendarStatus': 'A',
      'servicePointFk': 44939003,
      'servicePointName': 'B Szektor (Plasztikai sebészet)',
      'eventTypeFk': 44938716,
      'serviceFk': 44941347,
      'participantName': 'Zavadovics Ákos #31589725',
      'longDescription': 'Zavadovics Ákos 033033529,B Szektor,Plasztikai sebészet,Debrecen,István út 6, 2019.03.01 08:00',
      'participantDetail': 'Zavadovics Ákos ',
      'creatorName': 'Zavadovics Ákos'
    }
  ];

    // this.calendarData = [
    //   {
    //     'id': 25,
    //     'dateFrom': '2019-02-26T07:00:00.000+0000',
    //     'dateTo': '2019-02-26T07:15:00.000+0000',
    //     'timeFrom': '08:00',
    //     'createDateTime': '2019-01-31 13:01:24',
    //     'scheduleFk': 12440308,
    //     'calendarFk': 12440968,
    //     'calendarStatus': 'A',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Állapotfelmérés)',
    //     'eventTypeFk': 12403276,
    //     'serviceFk': 12403315,
    //     'participantName': 'Bubó Bianka #12417130',
    //     'longDescription': 'Bubó Bianka 000000000,Konzultációs terem,Állapotfelmérés,Debrecen,István út 7, 2019.02.26 08:00',
    //     'scheduleComment': 'visszrendelt beteg',
    //     'scheduleColor': 'COLOR_9',
    //     'participantDetail': 'Bubó Bianka ',
    //     'creatorName': 'Szép Irma'
    //   },
    //   {
    //     'id': 64,
    //     'dateFrom': '2019-02-26T07:00:00.000+0000',
    //     'dateTo': '2019-02-26T07:15:00.000+0000',
    //     'timeFrom': '08:00',
    //     'createDateTime': '2019-01-31 12:00:42',
    //     'scheduleFk': 12405105,
    //     'calendarFk': 12440942,
    //     'calendarStatus': 'D',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'participantName': 'Szép Irma #12406363',
    //     'longDescription': 'Szép Irma HUN22334455,Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.26 08:00',
    //     'participantDetail': 'Szép Irma ',
    //     'creatorName': 'Szép Irma'
    //   },
    //   {
    //     'id': 27,
    //     'dateFrom': '2019-02-26T07:15:00.000+0000',
    //     'dateTo': '2019-02-26T11:00:00.000+0000',
    //     'timeFrom': '08:15',
    //     'createDateTime': '2019-02-22 12:26:52',
    //     'scheduleFk': 12440309,
    //     'calendarFk': 12472619,
    //     'calendarStatus': 'S',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Állapotfelmérés)',
    //     'eventTypeFk': 12403276,
    //     'serviceFk': 12403315,
    //     'participantName': 'Alma Teszt #12447361',
    //     'longDescription': 'Alma Teszt  ,Konzultációs terem,Állapotfelmérés,Debrecen,István út 7, 2019.02.26 08:15',
    //     'participantDetail': 'Alma Teszt ',
    //     'creatorName': 'Szép Irma'
    //   },
    //   {
    //     'id': 70,
    //     'dateFrom': '2019-02-26T07:15:00.000+0000',
    //     'dateTo': '2019-02-26T07:30:00.000+0000',
    //     'timeFrom': '08:15',
    //     'createDateTime': '2019-02-22 12:27:52',
    //     'scheduleFk': 12405107,
    //     'calendarFk': 12472621,
    //     'calendarStatus': 'S',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'participantName': 'dsfsdfdsf a #12440520',
    //     'longDescription': 'dsfsdfdsf a 123456789,Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.26 08:15',
    //     'participantDetail': 'dsfsdfdsf a ',
    //     'creatorName': 'Szép Irma'
    //   },
    //   {
    //     'id': 28,
    //     'dateFrom': '2019-02-26T07:30:00.000+0000',
    //     'dateTo': '2019-02-26T07:45:00.000+0000',
    //     'timeFrom': '08:30',
    //     'createDateTime': '2019-02-18 16:29:59',
    //     'scheduleFk': 12440310,
    //     'calendarFk': 12464194,
    //     'calendarStatus': 'S',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Állapotfelmérés)',
    //     'eventTypeFk': 12403276,
    //     'serviceFk': 12403315,
    //     'participantName': 'Teszt Péter #12457204',
    //     'longDescription': 'Teszt Péter  13123123123,Konzultációs terem,Állapotfelmérés,Debrecen,István út 7, 2019.02.26 08:30',
    //     'participantDetail': 'Teszt Péter ',
    //     'creatorName': 'Szép Irma'
    //   },
    //   {
    //     'id': 29,
    //     'dateFrom': '2019-02-26T07:45:00.000+0000',
    //     'dateTo': '2019-02-26T08:00:00.000+0000',
    //     'timeFrom': '08:45',
    //     'createDateTime': '2019-02-15 11:34:15',
    //     'scheduleFk': 12440311,
    //     'calendarFk': 12453717,
    //     'calendarStatus': 'S',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Állapotfelmérés)',
    //     'eventTypeFk': 12403276,
    //     'serviceFk': 12403315,
    //     'participantName': ' ',
    //     'longDescription': '  ,Konzultációs terem,Állapotfelmérés,Debrecen,István út 7, 2019.02.26 08:45',
    //     'calendarComment': 'nagyonkomment',
    //     'participantDetail': ' ',
    //     'creatorName': 'Szép Irma'
    //   },
    //   {
    //     'id': 65,
    //     'dateFrom': '2019-02-26T08:00:00.000+0000',
    //     'dateTo': '2019-02-26T08:15:00.000+0000',
    //     'timeFrom': '09:00',
    //     'createDateTime': '2019-02-15 11:33:44',
    //     'scheduleFk': 12405113,
    //     'calendarFk': 12453716,
    //     'calendarStatus': 'D',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'participantName': 'Teszt Mária #12453693',
    //     'longDescription': 'Teszt Mária  045125639,Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.26 09:00',
    //     'calendarComment': 'Mell műtét első vizsgálata',
    //     'participantDetail': 'Teszt Mária ',
    //     'creatorName': 'Szép Irma'
    //   },
    //   {
    //     'id': 30,
    //     'dateFrom': '2019-02-26T08:15:00.000+0000',
    //     'dateTo': '2019-02-26T08:30:00.000+0000',
    //     'timeFrom': '09:15',
    //     'createDateTime': '2019-02-21 16:30:28',
    //     'scheduleFk': 12440313,
    //     'calendarFk': 12469780,
    //     'calendarStatus': 'S',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Állapotfelmérés)',
    //     'eventTypeFk': 12403276,
    //     'serviceFk': 12403315,
    //     'participantName': 'Alma Teszt #12447361',
    //     'longDescription': 'Alma Teszt  ,Konzultációs terem,Állapotfelmérés,Debrecen,István út 7, 2019.02.26 09:15',
    //     'participantDetail': 'Alma Teszt ',
    //     'creatorName': 'Szép Irma'
    //   },
    //   {
    //     'id': 26,
    //     'dateFrom': '2019-02-26T08:30:00.000+0000',
    //     'dateTo': '2019-02-26T08:45:00.000+0000',
    //     'timeFrom': '09:30',
    //     'createDateTime': '2019-02-18 14:38:21',
    //     'scheduleFk': 12440314,
    //     'calendarFk': 12454994,
    //     'calendarStatus': 'A',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Állapotfelmérés)',
    //     'eventTypeFk': 12403276,
    //     'serviceFk': 12403315,
    //     'participantName': 'Teszt Antal #12440881',
    //     'longDescription': 'Teszt Antal  12345,Konzultációs terem,Állapotfelmérés,Debrecen,István út 7, 2019.02.26 09:30',
    //     'participantDetail': 'Teszt Antal ',
    //     'creatorName': 'Szép Irma'
    //   },
    //   {
    //     'id': 71,
    //     'dateFrom': '2019-02-26T08:45:00.000+0000',
    //     'dateTo': '2019-02-26T09:00:00.000+0000',
    //     'timeFrom': '09:45',
    //     'createDateTime': '2019-02-22 12:28:03',
    //     'scheduleFk': 12405119,
    //     'calendarFk': 12472622,
    //     'calendarStatus': 'S',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'participantName': 'Gecző Gergő #12468714',
    //     'longDescription': 'Gecző Gergő  555555555,Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.26 09:45',
    //     'participantDetail': 'Gecző Gergő ',
    //     'creatorName': 'Szép Irma'
    //   },
    //   {
    //     'id': 72,
    //     'dateFrom': '2019-02-26T09:00:00.000+0000',
    //     'dateTo': '2019-02-26T09:45:00.000+0000',
    //     'timeFrom': '10:00',
    //     'createDateTime': '2019-02-20 15:30:35',
    //     'scheduleFk': 12405121,
    //     'calendarFk': 12468653,
    //     'calendarStatus': 'S',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'participantName': 'Tóth Andrea #12454845',
    //     'longDescription': 'Tóth Andrea  ,Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.26 10:00',
    //     'participantDetail': 'Tóth Andrea ',
    //     'creatorName': 'Szép Irma'
    //   },
    //   {
    //     'id': 73,
    //     'dateFrom': '2019-02-26T09:45:00.000+0000',
    //     'dateTo': '2019-02-26T10:45:00.000+0000',
    //     'timeFrom': '10:45',
    //     'createDateTime': '2019-02-22 12:27:22',
    //     'scheduleFk': 12405127,
    //     'calendarFk': 12472620,
    //     'calendarStatus': 'S',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'participantName': 'asfrs #12453829',
    //     'longDescription': 'asfrs  ,Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.26 10:45',
    //     'participantDetail': 'asfrs ',
    //     'creatorName': 'Szép Irma'
    //   },
    //   {
    //     'id': 74,
    //     'dateFrom': '2019-02-26T10:45:00.000+0000',
    //     'dateTo': '2019-02-26T11:00:00.000+0000',
    //     'timeFrom': '11:45',
    //     'createDateTime': '2019-02-21 17:29:05',
    //     'scheduleFk': 12405136,
    //     'calendarFk': 12469820,
    //     'calendarStatus': 'S',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'participantName': 'Magó Zsolt #11860313',
    //     'longDescription': 'Magó Zsolt 123-456-HUN,Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.26 11:45',
    //     'participantDetail': 'Magó Zsolt ',
    //     'creatorName': 'Szép Irma'
    //   },
    //   {
    //     'id': 23,
    //     'dateFrom': '2019-02-27T07:00:00.000+0000',
    //     'dateTo': '2019-02-27T07:30:00.000+0000',
    //     'timeFrom': '08:00',
    //     'createDateTime': '2019-02-22 08:48:41',
    //     'scheduleFk': 12440324,
    //     'calendarFk': 12470055,
    //     'calendarStatus': 'D',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Állapotfelmérés)',
    //     'eventTypeFk': 12403276,
    //     'serviceFk': 12403315,
    //     'participantName': 'Lovas Dóra #12470023',
    //     'longDescription': 'Lovas Dóra  012345678,Konzultációs terem,Állapotfelmérés,Debrecen,István út 7, 2019.02.27 08:00',
    //     'scheduleComment': 'visszrendelt beteg',
    //     'calendarComment': 'új cici',
    //     'scheduleColor': 'COLOR_9',
    //     'participantDetail': 'Lovas Dóra ',
    //     'creatorName': 'Szép Irma'
    //   },
    //   {
    //     'id': 75,
    //     'dateFrom': '2019-02-27T07:00:00.000+0000',
    //     'dateTo': '2019-02-27T07:15:00.000+0000',
    //     'timeFrom': '08:00',
    //     'createDateTime': '2019-02-22 16:41:14',
    //     'scheduleFk': 12405138,
    //     'calendarFk': 12472741,
    //     'calendarStatus': 'S',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'participantName': 'Gecző Gergő #12468714',
    //     'longDescription': 'Gecző Gergő  555555555,Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.27 08:00',
    //     'participantDetail': 'Gecző Gergő ',
    //     'creatorName': 'Szép Irma'
    //   },
    //   {
    //     'id': 76,
    //     'dateFrom': '2019-02-27T07:15:00.000+0000',
    //     'dateTo': '2019-02-27T07:45:00.000+0000',
    //     'timeFrom': '08:15',
    //     'createDateTime': '2019-02-21 16:30:19',
    //     'scheduleFk': 12405140,
    //     'calendarFk': 12469779,
    //     'calendarStatus': 'S',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'participantName': 'Tandi2 Tandi #12469325',
    //     'longDescription': 'Tandi2 Tandi  ,Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.27 08:15',
    //     'participantDetail': 'Tandi2 Tandi ',
    //     'creatorName': 'Szép Irma'
    //   },
    //   {
    //     'id': 66,
    //     'dateFrom': '2019-02-27T07:30:00.000+0000',
    //     'dateTo': '2019-02-27T07:45:00.000+0000',
    //     'timeFrom': '08:30',
    //     'createDateTime': '2019-02-25 15:32:09',
    //     'scheduleFk': 12405142,
    //     'calendarFk': 12473642,
    //     'calendarStatus': 'D',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'participantName': 'Alma Teszt #12447361',
    //     'longDescription': 'Alma Teszt  000000,Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.27 08:30',
    //     'calendarComment': 'asdasd',
    //     'participantDetail': 'Alma Teszt ',
    //     'creatorName': 'Szép Irma'
    //   },
    //   {
    //     'id': 31,
    //     'dateFrom': '2019-02-27T07:45:00.000+0000',
    //     'dateTo': '2019-02-27T08:00:00.000+0000',
    //     'timeFrom': '08:45',
    //     'createDateTime': '2019-02-25 15:33:45',
    //     'scheduleFk': 12440327,
    //     'calendarFk': 12473643,
    //     'calendarStatus': 'S',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Állapotfelmérés)',
    //     'eventTypeFk': 12403276,
    //     'serviceFk': 12403315,
    //     'participantName': 'alias',
    //     'longDescription': '  ,Konzultációs terem,Állapotfelmérés,Debrecen,István út 7, 2019.02.27 08:45',
    //     'calendarComment': 'megjegy',
    //     'participantDetail': 'alias',
    //     'creatorName': 'Szép Irma'
    //   },
    //   {
    //     'id': 67,
    //     'dateFrom': '2019-02-27T07:45:00.000+0000',
    //     'dateTo': '2019-02-27T08:30:00.000+0000',
    //     'timeFrom': '08:45',
    //     'createDateTime': '2019-02-25 15:44:44',
    //     'scheduleFk': 12405144,
    //     'calendarFk': 12473650,
    //     'calendarStatus': 'D',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'participantName': 'Alma Teszt #12447361',
    //     'longDescription': 'Alma Teszt  assd,Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.27 08:45',
    //     'participantDetail': 'Alma Teszt ',
    //     'creatorName': 'Szép Irma'
    //   },
    //   {
    //     'id': 68,
    //     'dateFrom': '2019-02-27T08:00:00.000+0000',
    //     'dateTo': '2019-02-27T08:15:00.000+0000',
    //     'timeFrom': '09:00',
    //     'createDateTime': '2019-02-26 15:51:58',
    //     'scheduleFk': 12405146,
    //     'calendarFk': 12475666,
    //     'calendarStatus': 'D',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'participantName': 'Alma Teszt #12447361',
    //     'longDescription': 'Alma Teszt  123354678,Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.27 09:00',
    //     'participantDetail': 'Alma Teszt ',
    //     'creatorName': 'Szép Irma'
    //   },
    //   {
    //     'id': 38,
    //     'dateFrom': '2019-02-27T08:15:00.000+0000',
    //     'dateTo': '2019-02-27T08:30:00.000+0000',
    //     'timeFrom': '09:15',
    //     'scheduleFk': 12405148,
    //     'calendarStatus': 'F',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'longDescription': ',Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.27 09:15',
    //     'creatorName': 'Meditcom-T Adminisztrátor'
    //   },
    //   {
    //     'id': 24,
    //     'dateFrom': '2019-02-27T08:30:00.000+0000',
    //     'dateTo': '2019-02-27T08:45:00.000+0000',
    //     'timeFrom': '09:30',
    //     'createDateTime': '2019-02-27 09:18:09',
    //     'scheduleFk': 12440330,
    //     'calendarFk': 12475878,
    //     'calendarStatus': 'D',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Állapotfelmérés)',
    //     'eventTypeFk': 12403276,
    //     'serviceFk': 12403315,
    //     'participantName': 'Humor Herold #12475882',
    //     'longDescription': 'Humor Herold  12345678,Konzultációs terem,Állapotfelmérés,Debrecen,István út 7, 2019.02.27 09:30',
    //     'calendarComment': 'Csak a személyi igazolványa volt nála.',
    //     'participantDetail': 'Humor Herold ',
    //     'creatorName': 'Szép Irma'
    //   },
    //   {
    //     'id': 39,
    //     'dateFrom': '2019-02-27T08:30:00.000+0000',
    //     'dateTo': '2019-02-27T08:45:00.000+0000',
    //     'timeFrom': '09:30',
    //     'scheduleFk': 12405150,
    //     'calendarStatus': 'F',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'longDescription': ',Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.27 09:30',
    //     'creatorName': 'Meditcom-T Adminisztrátor'
    //   },
    //   {
    //     'id': 18,
    //     'dateFrom': '2019-02-27T08:45:00.000+0000',
    //     'dateTo': '2019-02-27T09:00:00.000+0000',
    //     'timeFrom': '09:45',
    //     'scheduleFk': 12440331,
    //     'calendarStatus': 'F',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Állapotfelmérés)',
    //     'eventTypeFk': 12403276,
    //     'serviceFk': 12403315,
    //     'longDescription': ',Konzultációs terem,Állapotfelmérés,Debrecen,István út 7, 2019.02.27 09:45',
    //     'creatorName': 'Meditcom-T Adminisztrátor'
    //   },
    //   {
    //     'id': 77,
    //     'dateFrom': '2019-02-27T08:45:00.000+0000',
    //     'dateTo': '2019-02-27T09:00:00.000+0000',
    //     'timeFrom': '09:45',
    //     'createDateTime': '2019-02-22 08:54:18',
    //     'scheduleFk': 12405152,
    //     'calendarFk': 12470061,
    //     'calendarStatus': 'S',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'participantName': 'Gecző Gergő #12468714',
    //     'longDescription': 'Gecző Gergő  555555555,Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.27 09:45',
    //     'participantDetail': 'Gecző Gergő ',
    //     'creatorName': 'Szép Irma'
    //   },
    //   {
    //     'id': 32,
    //     'dateFrom': '2019-02-27T09:00:00.000+0000',
    //     'dateTo': '2019-02-27T09:15:00.000+0000',
    //     'timeFrom': '10:00',
    //     'createDateTime': '2019-02-27 09:56:53',
    //     'scheduleFk': 12440332,
    //     'calendarFk': 12475910,
    //     'calendarStatus': 'S',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Állapotfelmérés)',
    //     'eventTypeFk': 12403276,
    //     'serviceFk': 12403315,
    //     'participantName': 'Dübdüb-riprop matyi',
    //     'longDescription': '  ,Konzultációs terem,Állapotfelmérés,Debrecen,István út 7, 2019.02.27 10:00',
    //     'scheduleComment': 'visszrendelt beteg',
    //     'calendarComment': 'köcsög ügyfelek, megamúgyis',
    //     'scheduleColor': 'COLOR_9',
    //     'participantDetail': 'Dübdüb-riprop matyi',
    //     'creatorName': 'Szép Irma'
    //   },
    //   {
    //     'id': 59,
    //     'dateFrom': '2019-02-27T09:00:00.000+0000',
    //     'dateTo': '2019-02-27T09:15:00.000+0000',
    //     'timeFrom': '10:00',
    //     'scheduleFk': 12405154,
    //     'calendarStatus': 'F',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'longDescription': ',Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.27 10:00',
    //     'creatorName': 'Meditcom-T Adminisztrátor'
    //   },
    //   {
    //     'id': 14,
    //     'dateFrom': '2019-02-27T09:15:00.000+0000',
    //     'dateTo': '2019-02-27T09:30:00.000+0000',
    //     'timeFrom': '10:15',
    //     'scheduleFk': 12440333,
    //     'calendarStatus': 'F',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Állapotfelmérés)',
    //     'eventTypeFk': 12403276,
    //     'serviceFk': 12403315,
    //     'longDescription': ',Konzultációs terem,Állapotfelmérés,Debrecen,István út 7, 2019.02.27 10:15',
    //     'creatorName': 'Meditcom-T Adminisztrátor'
    //   },
    //   {
    //     'id': 35,
    //     'dateFrom': '2019-02-27T09:15:00.000+0000',
    //     'dateTo': '2019-02-27T09:30:00.000+0000',
    //     'timeFrom': '10:15',
    //     'scheduleFk': 12405156,
    //     'calendarStatus': 'F',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'longDescription': ',Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.27 10:15',
    //     'creatorName': 'Meditcom-T Adminisztrátor'
    //   },
    //   {
    //     'id': 5,
    //     'dateFrom': '2019-02-27T09:30:00.000+0000',
    //     'dateTo': '2019-02-27T09:45:00.000+0000',
    //     'timeFrom': '10:30',
    //     'scheduleFk': 12440334,
    //     'calendarStatus': 'F',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Állapotfelmérés)',
    //     'eventTypeFk': 12403276,
    //     'serviceFk': 12403315,
    //     'longDescription': ',Konzultációs terem,Állapotfelmérés,Debrecen,István út 7, 2019.02.27 10:30',
    //     'creatorName': 'Meditcom-T Adminisztrátor'
    //   },
    //   {
    //     'id': 50,
    //     'dateFrom': '2019-02-27T09:30:00.000+0000',
    //     'dateTo': '2019-02-27T09:45:00.000+0000',
    //     'timeFrom': '10:30',
    //     'scheduleFk': 12405158,
    //     'calendarStatus': 'F',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'longDescription': ',Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.27 10:30',
    //     'creatorName': 'Meditcom-T Adminisztrátor'
    //   },
    //   {
    //     'id': 17,
    //     'dateFrom': '2019-02-27T09:45:00.000+0000',
    //     'dateTo': '2019-02-27T10:00:00.000+0000',
    //     'timeFrom': '10:45',
    //     'scheduleFk': 12440335,
    //     'calendarStatus': 'F',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Állapotfelmérés)',
    //     'eventTypeFk': 12403276,
    //     'serviceFk': 12403315,
    //     'longDescription': ',Konzultációs terem,Állapotfelmérés,Debrecen,István út 7, 2019.02.27 10:45',
    //     'creatorName': 'Meditcom-T Adminisztrátor'
    //   },
    //   {
    //     'id': 47,
    //     'dateFrom': '2019-02-27T09:45:00.000+0000',
    //     'dateTo': '2019-02-27T10:00:00.000+0000',
    //     'timeFrom': '10:45',
    //     'scheduleFk': 12405160,
    //     'calendarStatus': 'F',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'longDescription': ',Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.27 10:45',
    //     'creatorName': 'Meditcom-T Adminisztrátor'
    //   },
    //   {
    //     'id': 8,
    //     'dateFrom': '2019-02-27T10:00:00.000+0000',
    //     'dateTo': '2019-02-27T10:15:00.000+0000',
    //     'timeFrom': '11:00',
    //     'scheduleFk': 12440336,
    //     'calendarStatus': 'F',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Állapotfelmérés)',
    //     'eventTypeFk': 12403276,
    //     'serviceFk': 12403315,
    //     'longDescription': ',Konzultációs terem,Állapotfelmérés,Debrecen,István út 7, 2019.02.27 11:00',
    //     'creatorName': 'Meditcom-T Adminisztrátor'
    //   },
    //   {
    //     'id': 55,
    //     'dateFrom': '2019-02-27T10:00:00.000+0000',
    //     'dateTo': '2019-02-27T10:15:00.000+0000',
    //     'timeFrom': '11:00',
    //     'scheduleFk': 12405162,
    //     'calendarStatus': 'F',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'longDescription': ',Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.27 11:00',
    //     'creatorName': 'Meditcom-T Adminisztrátor'
    //   },
    //   {
    //     'id': 11,
    //     'dateFrom': '2019-02-27T10:15:00.000+0000',
    //     'dateTo': '2019-02-27T10:30:00.000+0000',
    //     'timeFrom': '11:15',
    //     'scheduleFk': 12440337,
    //     'calendarStatus': 'F',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Állapotfelmérés)',
    //     'eventTypeFk': 12403276,
    //     'serviceFk': 12403315,
    //     'longDescription': ',Konzultációs terem,Állapotfelmérés,Debrecen,István út 7, 2019.02.27 11:15',
    //     'creatorName': 'Meditcom-T Adminisztrátor'
    //   },
    //   {
    //     'id': 36,
    //     'dateFrom': '2019-02-27T10:15:00.000+0000',
    //     'dateTo': '2019-02-27T10:30:00.000+0000',
    //     'timeFrom': '11:15',
    //     'scheduleFk': 12405164,
    //     'calendarStatus': 'F',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'longDescription': ',Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.27 11:15',
    //     'creatorName': 'Meditcom-T Adminisztrátor'
    //   },
    //   {
    //     'id': 16,
    //     'dateFrom': '2019-02-27T10:30:00.000+0000',
    //     'dateTo': '2019-02-27T10:45:00.000+0000',
    //     'timeFrom': '11:30',
    //     'scheduleFk': 12440338,
    //     'calendarStatus': 'F',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Állapotfelmérés)',
    //     'eventTypeFk': 12403276,
    //     'serviceFk': 12403315,
    //     'longDescription': ',Konzultációs terem,Állapotfelmérés,Debrecen,István út 7, 2019.02.27 11:30',
    //     'creatorName': 'Meditcom-T Adminisztrátor'
    //   },
    //   {
    //     'id': 61,
    //     'dateFrom': '2019-02-27T10:30:00.000+0000',
    //     'dateTo': '2019-02-27T10:45:00.000+0000',
    //     'timeFrom': '11:30',
    //     'scheduleFk': 12405166,
    //     'calendarStatus': 'F',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'longDescription': ',Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.27 11:30',
    //     'creatorName': 'Meditcom-T Adminisztrátor'
    //   },
    //   {
    //     'id': 1,
    //     'dateFrom': '2019-02-27T10:45:00.000+0000',
    //     'dateTo': '2019-02-27T11:00:00.000+0000',
    //     'timeFrom': '11:45',
    //     'scheduleFk': 12440339,
    //     'calendarStatus': 'F',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Állapotfelmérés)',
    //     'eventTypeFk': 12403276,
    //     'serviceFk': 12403315,
    //     'longDescription': ',Konzultációs terem,Állapotfelmérés,Debrecen,István út 7, 2019.02.27 11:45',
    //     'creatorName': 'Meditcom-T Adminisztrátor'
    //   },
    //   {
    //     'id': 33,
    //     'dateFrom': '2019-02-27T10:45:00.000+0000',
    //     'dateTo': '2019-02-27T11:00:00.000+0000',
    //     'timeFrom': '11:45',
    //     'scheduleFk': 12405169,
    //     'calendarStatus': 'F',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'longDescription': ',Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.27 11:45',
    //     'creatorName': 'Meditcom-T Adminisztrátor'
    //   },
    //   {
    //     'id': 78,
    //     'dateFrom': '2019-02-28T07:00:00.000+0000',
    //     'dateTo': '2019-02-28T08:00:00.000+0000',
    //     'timeFrom': '08:00',
    //     'createDateTime': '2019-02-21 16:30:50',
    //     'scheduleFk': 12405171,
    //     'calendarFk': 12469781,
    //     'calendarStatus': 'S',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'participantName': 'Teszt Vilmos #12464537',
    //     'longDescription': 'Teszt Vilmos  789-123-456,Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.28 08:00',
    //     'participantDetail': 'Teszt Vilmos ',
    //     'creatorName': 'Szép Irma'
    //   },
    //   {
    //     'id': 79,
    //     'dateFrom': '2019-02-28T08:00:00.000+0000',
    //     'dateTo': '2019-02-28T08:30:00.000+0000',
    //     'timeFrom': '09:00',
    //     'createDateTime': '2019-02-26 08:45:41',
    //     'scheduleFk': 12405180,
    //     'calendarFk': 12473917,
    //     'calendarStatus': 'S',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'participantName': 'Alias',
    //     'longDescription': '  ,Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.28 09:00',
    //     'calendarComment': 'asd',
    //     'participantDetail': 'Alias',
    //     'creatorName': 'Szép Irma'
    //   },
    //   {
    //     'id': 69,
    //     'dateFrom': '2019-02-28T08:30:00.000+0000',
    //     'dateTo': '2019-02-28T09:00:00.000+0000',
    //     'timeFrom': '09:30',
    //     'createDateTime': '2019-02-26 13:50:40',
    //     'scheduleFk': 12405184,
    //     'calendarFk': 12475570,
    //     'calendarStatus': 'D',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'participantName': 'Alias#12475573',
    //     'longDescription': 'Teszt Pannika  123124,Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.28 09:30',
    //     'calendarComment': 'asdasd',
    //     'participantDetail': 'Alias',
    //     'creatorName': 'Szép Irma'
    //   },
    //   {
    //     'id': 80,
    //     'dateFrom': '2019-02-28T09:00:00.000+0000',
    //     'dateTo': '2019-02-28T09:30:00.000+0000',
    //     'timeFrom': '10:00',
    //     'createDateTime': '2019-02-26 14:18:17',
    //     'scheduleFk': 12405188,
    //     'calendarFk': 12475601,
    //     'calendarStatus': 'S',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'participantName': 'Teszt Alias#12475603',
    //     'longDescription': 'Teszt Blanka  ,Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.28 10:00',
    //     'calendarComment': 'asd',
    //     'participantDetail': 'Teszt Alias',
    //     'creatorName': 'Szép Irma'
    //   },
    //   {
    //     'id': 42,
    //     'dateFrom': '2019-02-28T09:30:00.000+0000',
    //     'dateTo': '2019-02-28T09:45:00.000+0000',
    //     'timeFrom': '10:30',
    //     'scheduleFk': 12405192,
    //     'calendarStatus': 'F',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'longDescription': ',Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.28 10:30',
    //     'creatorName': 'Meditcom-T Adminisztrátor'
    //   },
    //   {
    //     'id': 43,
    //     'dateFrom': '2019-02-28T09:45:00.000+0000',
    //     'dateTo': '2019-02-28T10:00:00.000+0000',
    //     'timeFrom': '10:45',
    //     'scheduleFk': 12405194,
    //     'calendarStatus': 'F',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'longDescription': ',Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.28 10:45',
    //     'creatorName': 'Meditcom-T Adminisztrátor'
    //   },
    //   {
    //     'id': 45,
    //     'dateFrom': '2019-02-28T10:00:00.000+0000',
    //     'dateTo': '2019-02-28T10:15:00.000+0000',
    //     'timeFrom': '11:00',
    //     'scheduleFk': 12405196,
    //     'calendarStatus': 'F',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'longDescription': ',Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.28 11:00',
    //     'creatorName': 'Meditcom-T Adminisztrátor'
    //   },
    //   {
    //     'id': 46,
    //     'dateFrom': '2019-02-28T10:15:00.000+0000',
    //     'dateTo': '2019-02-28T10:30:00.000+0000',
    //     'timeFrom': '11:15',
    //     'scheduleFk': 12405199,
    //     'calendarStatus': 'F',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'longDescription': ',Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.28 11:15',
    //     'creatorName': 'Meditcom-T Adminisztrátor'
    //   },
    //   {
    //     'id': 60,
    //     'dateFrom': '2019-02-28T10:30:00.000+0000',
    //     'dateTo': '2019-02-28T10:45:00.000+0000',
    //     'timeFrom': '11:30',
    //     'scheduleFk': 12405201,
    //     'calendarStatus': 'F',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'longDescription': ',Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.28 11:30',
    //     'creatorName': 'Meditcom-T Adminisztrátor'
    //   },
    //   {
    //     'id': 44,
    //     'dateFrom': '2019-02-28T10:45:00.000+0000',
    //     'dateTo': '2019-02-28T11:00:00.000+0000',
    //     'timeFrom': '11:45',
    //     'scheduleFk': 12405203,
    //     'calendarStatus': 'F',
    //     'servicePointFk': 12403224,
    //     'servicePointName': 'Konzultációs terem (Konzultáció)',
    //     'eventTypeFk': 12403277,
    //     'serviceFk': 12403316,
    //     'longDescription': ',Konzultációs terem,Konzultáció,Debrecen,István út 7, 2019.02.28 11:45',
    //     'creatorName': 'Meditcom-T Adminisztrátor'
    //   }
    // ];
  }


}
