import { Pipe, PipeTransform } from '@angular/core';
import { ApiService } from './core/http/api.service';
import { Row } from './shared/models/response/getTorElementsTypeResponse';

@Pipe({
  name: 'filterTorElementName'
})
export class FilterTorElementNamePipe implements PipeTransform {

  constructor(private apiService: ApiService){

  }

  transform(typeId: string, torElements: Array<Row>): string {
    let torElementName = null;
    torElements.forEach(torElement => {
      if (torElement.kod === typeId) {
        torElementName = torElement.nev
      }
    });
    return torElementName;
  }

}
