<!-- Modal -->

<div class="modal" id="arrivePatientSimple" tabindex="-1" role="dialog" aria-labelledby="arrivePatientSimple" aria-hidden="true">
  <div class="modal-dialog" role="document">

    <div [ngClass]="{'hidden': saveResult !== 0}" class="fullSizeCover">
      <div class="fullSizeCoverContent">
        <div class="col-md-12 mt-4">
          <div class="row">
            <div class="col-md-12 mt-4 text-center">
              <h3><i class="fa fa-check-circle green mr-2"></i>Páciens sikeresen érkeztetve!</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mt-4 text-center">
              <button class="btn btn-success" data-dismiss="modal" (click)="onClose()">Bezárás</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-content">
      <div class="modal-header">
        <div id="saveError" [ngClass]="{'showResult': saveResult == -1}">
          <div class="closeResult" (click)="closeResult()">x</div>
          <div class="saveErrorContent">Hiba az érkeztetés során!
            <span>{{ saveErrorDescription }}</span>
          </div>
        </div>

        <h5 class="modal-title">Páciens érkeztetés ({{ patientDataValue['participantName'] }})</h5>
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="resetForm()">X</button>
      </div>

      <div class="modal-body">
        <div class="container-fluid" *ngIf="!isLoading">
          <form [formGroup]="treatmentDetails">
            <div class="row">

              <div class="col-md-12 center margin-top" style="text-align: right">
                <button class="btn btn-primary" (click)="onArrivePatient()"
                  [disabled]="saveInProgress">
                  <i *ngIf="saveInProgress" class="fa fa-spinner fa-spin"></i> Ellátás megkezdése</button>
              </div>
            </div>

          </form>
        </div>
        <div class="container-fluid" *ngIf="isLoading">Loading...</div>

      </div>

    </div>
  </div>
</div>
