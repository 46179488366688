import { faCalendarAlt, faCog } from '@fortawesome/free-solid-svg-icons';
import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { SharedDataService } from '../services/shared-data.service';
import { Subscription, ReplaySubject } from 'rxjs';
import { SharedApiService } from '../services/shared-api.service';
import { environment } from '../../environments/environment';
import { debounceTime } from 'rxjs/operators';
import { RedirectService } from '../services/redirect.service';
import { ConstantPool } from '@angular/compiler';

declare var $: any;

@Component({
  selector: 'app-multicalendar',
  templateUrl: './multicalendar.component.html',
  styleUrls: ['./multicalendar.component.css']
})
export class MulticalendarComponent implements OnInit {

  faCog = faCog;
  faCalendarAlt = faCalendarAlt;
  showSettings = false;

  @ViewChild('idopontkezelo') closeModal: ElementRef;
  @ViewChild('showBookingModal') showBookingModal: ElementRef; 

  constructor(public authService: AuthService,
    private http: HttpClient,
    private router: Router,
    public sharedDataService: SharedDataService,
    private sharedApiService: SharedApiService,
    private openTab: RedirectService
  ) { }

  @Input() calendarID: string;
  @Input() twoColumns: boolean;

  apiUrl = environment.apiUrl;
  calendarLoading = false;
  selectedCsId = null;
  selectedCsIdSubscription: Subscription;

  error = false;
  errorDescription = '';

  dateFrom = this.sharedDataService.getCurrentDate();

  updateCalendarEntrySubscription: Subscription;

  calendarRefreshSubscription: Subscription;
  multiCalendarChangedSubscription: Subscription;

  availServic = [];
  availServicSubscription: Subscription;
  availServiceEmpty = true;
  selectedService = null;

  service = new FormControl();
  eventType = new FormControl();
  servicePoint = new FormControl();
  serviceSelect = new FormGroup({
    'service': this.service,
    'eventType': this.eventType,
    'servicePoint': this.servicePoint
  });

  currentTimeMarkerPosition = -1;
  currentScrollPosition = -100;

  etList = [];
  spList = [];
  etListFiltered = [];
  spListFiltered = [];
  calendarListDataAll = [];
  calendarListDataRows = [];
  calendarListDataArray = [];
  calendarListDataRowItemCount = [];
  firstCalendarAppTimestamp: number;
  firstTimeStampSubscription: Subscription;
  dateSubscription: Subscription;
  lastCalendarAppTimeStamp: number;
  additionalSlots: number;
  firstDisplayTimeStamp: number;

  calendarLoadSubscription: Subscription;

  autoRefreshCalendarData = null;

  calendarRefreshTimer = environment.calendarRefreshTimer.DEFAULT;

  calendarEntryDuration = 900000;

  layoutWidthSubscription: Subscription;
  layoutWidth = 1;
  calendarTimeAxis = [];
  calendarLoaded = false;
  columns = 0;
  col_size = 3;

  scrolled = false;
  currentScrollPos: ElementRef;

  parallelAppointments = 1;
  Brand = '';

  callNumber = '---';
  callPatientLoading = false;

  arrivePatientLoading = false;

  calendarMinHour = 24;
  calendarMaxHour = 0;
  calendarMinTime = 0;
  calendarMinTimeString = '';

  loadCalendarOther = new ReplaySubject<any>(1);

  @ViewChild('currentScrollPos') set csp(currentScrollPos: ElementRef) {
    if (currentScrollPos) {
      this.currentScrollPos = currentScrollPos;
    }

    if (this.calendarID === '1') {
      if (this.currentScrollPos && this.scrolled === false) {  // Scroll window to current time
        // console.log('Scroll window to current time');
        // console.log('*****currentScrollPos:', this.currentScrollPos);
        // console.log('*****currentScrollPos TOP:', this.currentScrollPos.nativeElement.style.top);
        // console.log('*****currentScrollPosition:', this.currentScrollPosition);
        if (this.currentScrollPos.nativeElement.style.top !== '-100px') {
          this.scrolled = true;
          this.currentScrollPos.nativeElement.scrollIntoView();
        }
      }
    }
    // this.calculateCurrentTimeMarkerPosition('@ViewChild(currentScrollPos)');
  }

  ngOnInit() {

    this.sharedDataService.spListShared.subscribe(
      value => {
        this.spList = value;
        this.spListFiltered = value;
      });

    this.sharedDataService.etListShared.subscribe(
      value => {
        this.etList = value;
        this.etListFiltered = value;
      });

    // console.log('calendar init');
    this.updateCalendarEntrySubscription = this.sharedDataService.updateCalendarEntryShared.subscribe(data => {
      this.updateCalendarEntryStatus(data.calendarId, data.status);
    }
    );

    this.Brand = this.authService.getBrand();
    this.calendarRefreshTimer = environment.calendarRefreshTimer[this.Brand];
    this.selectedCsIdSubscription = this.sharedDataService.selectedCsIdShared.subscribe(data => {
      this.selectedCsId = data;
      // console.log ('calendar selected csid change:', data);
    });

    this.layoutWidthSubscription = this.sharedDataService.layoutWidthShared.subscribe(value => {
      // console.log ('calendar layout width change arrived');
      this.layoutWidth = value;
      this.calculateCurrentTimeMarkerPosition('layoutWidthSubscription');
    });



    this.firstTimeStampSubscription = this.sharedDataService.calendarFirstTimeStampUpdate.subscribe((timestampData: Object) => {
      // console.log('timestamp data arrived into calendar:' + this.calendarID);
      // console.log(timestampData);
      let otherCalendarFirstTimeStamp: number;
      if (this.calendarID === '1') {
        if (timestampData[2] > 0) {
          otherCalendarFirstTimeStamp = timestampData[2];
          // console.log('other calendar timestamp: ' + timestampData[2]);
        }
      } else if (this.calendarID === '2') {
        if (timestampData[1] > 0) {
          otherCalendarFirstTimeStamp = timestampData[1];
          // console.log('other calendar timestamp: ' + timestampData[1]);
        }
      }

      if (otherCalendarFirstTimeStamp < this.firstCalendarAppTimestamp) {
        this.firstDisplayTimeStamp = otherCalendarFirstTimeStamp;
      } else {
        this.firstDisplayTimeStamp = this.firstCalendarAppTimestamp;
      }

      this.additionalSlots = ((this.firstCalendarAppTimestamp - this.firstDisplayTimeStamp) / this.calendarEntryDuration);
      // console.log('Slots needed :' + this.additionalSlots);
      if (this.additionalSlots > 0) {
        this.calculateCurrentTimeMarkerPosition('firstTimeStampSubscription');
      }

    });


    this.availServicSubscription = this.sharedDataService.availServicesShared.subscribe(data => {

      // console.log('availservice: ', data);
      if (data.length === 0) {
        this.availServiceEmpty = true;
        this.availServic = data;
        this.calendarListDataAll = [];
        this.selectedService = null;
        this.service.setValue(null);
      } else {

        this.availServiceEmpty = false;
        // console.log ('Role', this.sharedDataService.getSelectedRole());
        if (this.authService.getBrand() === 'UDMED' && (this.sharedDataService.getSelectedRole()['roleCode'] === 'AUTH$UDMED-VIRT_CCS' ||
          this.sharedDataService.getSelectedRole()['roleCode'] === 'AUTH$KENEZY_SCC')) {
          const serviceTemp = [];
          // console.log ('UDMED Service --');
          data.forEach(entry => {
            // console.log ('availService entry: ', entry);
            // console.log ('entry index: ', entry.etCode.indexOf('ELOJEGYZES'));
            if (entry.etCode.indexOf('ELOJEGYZES') > -1 || entry.etCode.indexOf('BETEGFELVETEL') > -1) {
              serviceTemp.push(entry);
            }
          });
          this.availServic = serviceTemp;

          this.calendarListDataAll = [];
          this.selectedService = null;
          this.service.setValue(null);
          // console.log ('availservice', this.availServic);

        } else {
          if (this.Brand === 'MEDCSEPP') {
            data.forEach(entry => {
              // console.log ('availService entry: ', entry);
              if (entry.etId !== 12720854 || entry.spName === 'Intézményi Diszpécser M') {
                this.availServic.push(entry);
              }
            });

            this.calendarListDataAll = [];
            this.selectedService = null;
            this.service.setValue(null);
          } else {
            this.availServic = data;
            this.calendarListDataAll = [];
            this.selectedService = null;
            this.service.setValue(null);
          }
        }
      }
    });

    this.eventType.setValue(JSON.parse(localStorage.getItem('et')));
    this.servicePoint.setValue(JSON.parse(localStorage.getItem('sp')));
    this.setServices();

    this.dateSubscription = this.sharedDataService.selectedDateChange.subscribe(
      value => {
        if (value) {
          this.dateFrom = value;
          this.eventType.setValue(JSON.parse(localStorage.getItem('et')));
          this.servicePoint.setValue(JSON.parse(localStorage.getItem('sp')));
          this.setServices();
        }
      });

    this.multiCalendarChangedSubscription = this.sharedDataService.mulriCalendarChanged.subscribe(
      value => {
        if (value) {
          // $('#idopontkezelo').modal('hide');
          this.eventType.setValue(JSON.parse(localStorage.getItem('et')));
          this.servicePoint.setValue(JSON.parse(localStorage.getItem('sp')));
          this.setServices();
        }
      });

  }

  ngOnDestroy() {
    //this.updateCalendarEntrySubscription.unsubscribe();
    this.dateSubscription.unsubscribe();
    /* this.availServicSubscription.unsubscribe();
     this.calendarRefreshSubscription.unsubscribe();
     this.layoutWidthSubscription.unsubscribe();*/
    clearInterval(this.autoRefreshCalendarData);
  }

  emptyCalendarMessage = '';

  initCalendar(refresh, services) {
    this.emptyCalendarMessage = '';
    let ids = '';

    if (services) {
      services.forEach(
        s => {
          ids += s.svId + ',';
        });
      ids = ids.slice(0, -1);
    }

    this.calendarLoadSubscription = this.getCalendarListDataMulti(ids, refresh).subscribe(
      (calendarData: Response) => {
        this.calendarListDataArray = [];
        this.calendarLoaded = true;
        this.calendarMinTime = 0;
        this.calendarMinHour = 24;
        this.calendarMaxHour = 0;
        this.calendarTimeAxis = [];

        this.calendarLoading = false;

        // console.log('!calendarData[data]: ', calendarData['data'].length === 0 );

        // console.log('et: ', localStorage.getItem('et') === null);
        // console.log('sp: ', localStorage.getItem('sp') === null);


        if (calendarData['data'].length === 0 && (!JSON.parse(localStorage.getItem('et')) && !JSON.parse(localStorage.getItem('sp')))) {
          this.emptyCalendarMessage = 'Az adott napra nincsen megjeleníthető adat.';
          return;
        } else if (calendarData['data'].length === 0  && (JSON.parse(localStorage.getItem('et')) || JSON.parse(localStorage.getItem('sp')))) {
          this.emptyCalendarMessage = 'Az adott napra nincsen megjeleníthető adat a beállított szűrési feltételekkel.';
          return;
        }
        
        if (this.autoRefreshCalendarData) {
          clearInterval(this.autoRefreshCalendarData);
        }

        // console.log ('calendarRefreshTimer', this.calendarRefreshTimer);
        if (this.calendarRefreshTimer >= 1000) {

          this.autoRefreshCalendarData = setInterval(() => {
            this.initCalendar(true, services);
          }, this.calendarRefreshTimer);
        }


        if (calendarData['code'] === 1) {

          let i = 0;

          calendarData['data'].forEach(calendarColumn => {
            calendarColumn.calendarData.sort(this.sortCalendarItems);
            const dateFrom = this.sharedDataService.DateStringConvert(calendarColumn.calendarData[0].dateFrom);
            if (this.calendarMinTime > dateFrom || this.calendarMinTime === 0) { this.calendarMinTime = dateFrom; this.calendarMinTimeString = calendarColumn.calendarData[0].dateFrom; }
          });

          calendarData['data'].forEach(calendarColumn => {
            calendarColumn.calendarData.sort(this.sortCalendarItems);
            const dateFrom = this.sharedDataService.DateStringConvert(calendarColumn.calendarData[0].dateFrom);
            if (this.calendarMinTime < dateFrom) {
              // console.log('pop');
              calendarColumn.calendarData.unshift(
                {
                  'dateFrom': this.calendarMinTimeString,
                  'dateTo': calendarColumn.calendarData[0].dateFrom,
                  'eventTypeFk': 0, // entry['eventTypeFk']
                  'calendarStatus': '_'
                });
            }
          });

          calendarData['data'].forEach(calendarColumn => {
            let calendarDataColumn = this.loadCalendarColumn(calendarColumn.calendarData);
            this.calendarListDataArray[i] = {};
            this.calendarListDataArray[i].items = calendarDataColumn.items;
            this.calendarListDataArray[i].itemCount = calendarDataColumn.itemCount;
            if (calendarColumn['serviceFk'] && this.availServic.find(obj => obj.svId === calendarColumn['serviceFk'])) {
              this.calendarListDataArray[i].header = (this.availServic.find(obj => obj.svId === calendarColumn['serviceFk']));
            }
            i++;
          });

            
          this.calendarListDataArray.forEach(column => {
            const dateFrom = column.items[0][0].dateFrom;
            // console.log ('df: ', dateFrom);
             // console.log ('item secsSinceMidnight:', this.SecsSinceMidnight(dateFrom));
            //  console.log ('(this.getMinutes(dateFrom)):', (this.getMinutes(dateFrom)));
              if (this.calendarMinTime < this.SecsSinceMidnight(dateFrom)) {
                console.log ('pop');
                column.items.unshift([
                  {
                    dateFrom: this.calculateStartTimeStamp(column.items[0][0].dateFrom, this.calendarMinTime),
                    dateTo: column.items[0][0].dateFrom,
                    eventTypeFk: 0, // entry['eventTypeFk']
                    calendarStatus: '_',
                    slotLength: ((column.items[0][0].dateFrom -this.calculateStartTimeStamp(column.items[0][0].dateFrom, this.calendarMinTime)) / this.calendarEntryDuration) + ((this.getMinutes(this.calendarMinTime) * 60000) / this.calendarEntryDuration),
                    specialEntry: 'pop'
                  }]);
               }
               else if ((this.getMinutes(dateFrom)) > 0 && (!column.items[0][0].specialEntry && column.items[0][0].specialEntry !== 'pop')) {
                  console.log ('correction');
                  column.items.unshift([
                    {
                      dateFrom: (dateFrom - (this.getMinutes(dateFrom) * 60000)),
                      dateTo: dateFrom,
                      eventTypeFk: 0, // entry['eventTypeFk']
                      calendarStatus: '_',
                      slotLength: (this.getMinutes(dateFrom) * 60000) / this.calendarEntryDuration,
                    }]);
               }

        });
        


          this.columns = i;
          if (this.columns < 11) {
            this.col_size = 2 + Math.floor(12 / this.columns);
          } else { this.col_size = 3; }
          // console.log('(columns * ((col_size*50)+3))', (this.columns * ((this.col_size * 50) + 3)));
          // console.log(calendarData['data']);

          this.calendarTimeAxis = [];
          for (let o = this.calendarMinHour; o <= this.calendarMaxHour; o++) {
            this.calendarTimeAxis.push(o);
          }
        }
      });
  }

  loadCalendarColumn(calendarInput) {

    let previousAppointmentDateFrom = 0;
    let previousAppointmentDateTo = 0;
    let tempParallelAppointments = 1;
    let appointmentRow = 0;

    this.calendarListDataRows = [];
    this.calendarListDataRowItemCount = [];

    if (calendarInput.length > 0) {

      calendarInput.sort(this.sortCalendarItems);

      this.calendarEntryDuration = 300000;

      // this.calendarEntryDuration = this.sharedDataService.DateStringConvert(this.calendarListDataAll[0]['dateTo']) -
      //   this.sharedDataService.DateStringConvert(this.calendarListDataAll[0]['dateFrom']);

      // console.log(this.calendarEntryDuration);

      calendarInput.forEach(entry => {

        entry['dateTo'] = this.sharedDataService.DateStringConvert(entry['dateTo']);
        entry['dateFrom'] = this.sharedDataService.DateStringConvert(entry['dateFrom']);

        if (entry && entry['dateFrom'] && new Date(entry['dateFrom']).getHours() < this.calendarMinHour) {
          // console.log ('calendarMinHour', new Date(entry['dateFrom']).getHours());
          this.calendarMinHour = new Date(entry['dateFrom']).getHours();
        }

        let dateTo = new Date(entry['dateTo']);
        if (entry && entry['dateTo'] && dateTo.getHours() > this.calendarMaxHour) {
          if (dateTo.getMinutes() > 0) {
            this.calendarMaxHour = dateTo.getHours();
          } else {
            this.calendarMaxHour = dateTo.getHours() - 1;
          }
          // console.log ('this.calendarMaxHour', this.calendarMaxHour);
        }



        if (previousAppointmentDateTo !== entry['dateFrom'] && previousAppointmentDateTo > 0) {
          appointmentRow++;
          this.calendarListDataRows[appointmentRow] = [];
          // console.log('add Empty Space');
          // add empty space
          // console.log('previousAppointmentDateTo', previousAppointment2DateTo);
          // console.log('entry[DateFrom]', entry['dateFrom']);
          this.calendarListDataRows[appointmentRow][tempParallelAppointments - 1] = {
            dateFrom: previousAppointmentDateTo,
            dateTo: entry['dateFrom'],
            eventTypeFk: 0, // entry['eventTypeFk']
            calendarStatus: '_'

          }

          this.calendarListDataRows[appointmentRow][tempParallelAppointments - 1]['slotLength'] = ((this.calendarListDataRows[appointmentRow][tempParallelAppointments - 1]['dateTo'] - this.calendarListDataRows[appointmentRow][tempParallelAppointments - 1]['dateFrom']) / this.calendarEntryDuration);

          this.parallelAppointments = tempParallelAppointments;
          tempParallelAppointments = 1;

          // console.log('this.calendarListDataRows[appointmentRow]', this.calendarListDataRows[appointmentRow]);
          this.calendarListDataRowItemCount[appointmentRow] = 12 / this.calendarListDataRows[appointmentRow].length;

          // console.log ('this.calendarListDataRowItemCount[appointmentRow]', this.calendarListDataRowItemCount[appointmentRow]);

        }



        if (previousAppointmentDateFrom === entry['dateFrom']) {
          tempParallelAppointments++;
        } else {

          // if (previousAppointmentDateTo !==  entry['dateFrom'] && previousAppointmentDateTo > 0) {
          //   console.log ('add Empty Space');
          //   // add empty space
          //   console.log ('previousAppointmentDateTo',previousAppointmentDateTo);
          //   console.log ('entry[DateFrom]',entry['dateFrom']);
          //   this.calendarListDataRows[appointmentRow][tempParallelAppointments - 1] = {
          //     dateFrom: previousAppointmentDateTo,
          //     dateTo: entry['dateFrom'],
          //     eventTypeFk: 0 // entry['eventTypeFk']

          //   }
          //   this.calendarListDataRows[appointmentRow][tempParallelAppointments - 1]['slotLength'] = ((this.calendarListDataRows[appointmentRow][tempParallelAppointments - 1]['dateTo'] - this.calendarListDataRows[appointmentRow][tempParallelAppointments - 1]['dateFrom']) / this.calendarEntryDuration);
          // }

          this.parallelAppointments = tempParallelAppointments;
          // console.log('this.parallelAppointments 2', this.parallelAppointments);
          tempParallelAppointments = 1;
          if (previousAppointmentDateFrom !== 0) {
            // console.log('this.calendarListDataRows[appointmentRow]', this.calendarListDataRows[appointmentRow]);
            this.calendarListDataRowItemCount[appointmentRow] = 12 / this.calendarListDataRows[appointmentRow].length;
            appointmentRow++;
          }
        }

        if (!this.calendarListDataRows[appointmentRow]) {
          this.calendarListDataRows[appointmentRow] = [];
        }

        previousAppointmentDateFrom = entry['dateFrom'];
        previousAppointmentDateTo = entry['dateTo'];

        // console.log ('calendar dateFrom:', entry['dateFrom']);
        // console.log ('calendar dateFrom:', entry['dateTo']);

        entry['slotLength'] = ((entry['dateTo'] - entry['dateFrom']) / this.calendarEntryDuration);

        if (entry['participantName']) {

          if (entry['participantName'].indexOf('#') > -1) {
            entry['participantUserID'] = entry['participantName'].substring(entry['participantName'].indexOf('#') + 1);
            entry['participantName'] = (entry['participantName'].substring(0, entry['participantName'].indexOf('#'))).trim();
            entry['registeredPatient'] = true;
          }
          entry['displayData'] = (entry['participantName']).trim();
          if (entry['calendarComment'] && entry['calendarComment'] !== '') {
            if (entry['displayData'] !== '') {
              entry['displayData'] += ', ' + entry['calendarComment'];
            } else {
              entry['displayData'] = entry['calendarComment'];
            }
          }

        }

        this.calendarListDataRows[appointmentRow][tempParallelAppointments - 1] = entry;
      });
      this.calendarListDataRowItemCount[appointmentRow] = 12 / this.calendarListDataRows[appointmentRow].length;

      return { 'header': { 'name': 'oszlop' }, items: this.calendarListDataRows, itemCount: this.calendarListDataRowItemCount };

      this.calendarListDataRowItemCount[appointmentRow] = this.calendarListDataRowItemCount[appointmentRow - 1];

      this.calendarListDataRowItemCount[0] = 12 / this.calendarListDataRows[0].length;

      // console.log('this.calendarListDataRows', this.calendarListDataRows);
      // console.log('this.calendarListDataRowItemCount', this.calendarListDataRowItemCount);
      // console.log ('structured calendar', this.calendarListDataRows);

      // console.log ('this.parallelAppointments:',  this.parallelAppointments);

      this.lastCalendarAppTimeStamp = calendarInput[calendarInput.length - 1]['dateTo'];

      this.firstCalendarAppTimestamp = calendarInput[0]['dateFrom'];
      // console.log ('this.calendarEntryDuration: ', this.calendarEntryDuration);

      this.sharedDataService.setFirstCalenderAppTime(calendarInput[0]['dateFrom'], this.calendarID);

      this.calculateCurrentTimeMarkerPosition('loadCalendar');
      //  console.log ('calendar: ', this.calendarListDataAll);

    }
  }

  onLogout() {
    this.authService.doLogout();
    this.router.navigate(['/login']);
  }

  sortCalendarItems(a, b) {
    if (a['dateFrom'] > b['dateFrom']) {
      return 1;
    } else if (a['dateFrom'] < b['dateFrom']) {
      return -1;
    }
    return 0;
  }



  calculateCurrentTimeMarkerPosition(callerID) {
    // console.log ('--------------------------------------------');
    // console.log ('callerID: ', callerID);
    // console.log ((new Date).toISOString() + ' calculateCurrentTimeMarkerPosition');
    const firstItem = new Date(this.firstDisplayTimeStamp);
    const minutes = (new Date).getMinutes();
    const seconds = (new Date).getSeconds();
    // const now = new Date('2019-11-11 14:' + minutes + ':' + seconds);
    const now = new Date();

    const firstItemDate = firstItem.getFullYear() + '-' + firstItem.getMonth() + '-' +
      firstItem.getDate();

    const nowDate = now.getFullYear() + '-' + now.getMonth() + '-' +
      now.getDate();

    let position = -1;
    let scrollPosition = -100;
    // console.log('firstItemDate', firstItemDate);
    if (firstItemDate === nowDate) {  // Only calculate marker and scroll if the displayed day is today
      // console.log('today');
      // console.log('now.getTime()', now.getTime());
      // console.log('this.lastCalendarAppTimeStamp', this.lastCalendarAppTimeStamp);
      const deltaTime = (now.getTime() - this.firstDisplayTimeStamp) / 1000;

      if (deltaTime >= 0 && now.getTime() <= this.lastCalendarAppTimeStamp) { // Only calculate if it's now is between start and end

        // console.log ('this.calendarEntryDuration', this.calendarEntryDuration / 900000);

        if (this.layoutWidth === 2) {
          position = (deltaTime / 60) * 3 / (this.calendarEntryDuration / 300000);
          scrollPosition = position - 250;
        } else {
          position = (deltaTime / 60) * 5.06666666666667 / (this.calendarEntryDuration / 900000);
          scrollPosition = position - 250;
        }

        this.currentScrollPosition = Math.round(scrollPosition);
        this.currentTimeMarkerPosition = Math.round(position);

        if (this.calendarID === '1') {
          if (this.currentScrollPos && this.scrolled === false) {  // Scroll window to current time
            // console.log('Scroll window to current time');
            // console.log('*****currentScrollPos:', this.currentScrollPos);
            // console.log('*****currentScrollPos TOP:', this.currentScrollPos.nativeElement.style.top);
            // console.log('*****currentScrollPosition:', this.currentScrollPosition);
            if (this.currentScrollPos.nativeElement.style.top !== '-100px') {
              this.scrolled = true;
              this.currentScrollPos.nativeElement.scrollIntoView();
            }
          } else {
            // console.log('NO Scroll window to current time');
            if (this.currentScrollPos) {
              // console.log('this.currentScrollPos', this.currentScrollPos.nativeElement.style.top);
            }
            // console.log('this.scrolled', this.scrolled);
          }
        }
      }
    }
    this.currentScrollPosition = Math.round(scrollPosition);
    this.currentTimeMarkerPosition = Math.round(position);
  }

  getCalendarListDataMulti(serviceId: string, updateOnly: boolean) {

    if (updateOnly !== true) {
      this.calendarLoading = true;
    }

    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.getSelectedRole()['roleCode'],
        currentServicePointId: this.sharedDataService.getSelectedRole()['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      dateFrom: this.dateFrom,
      serviceId: serviceId
    };

    return this.http.post(this.apiUrl + '/rest/neu/calendarlist/getcalendarlistdatamulti', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

  }


  calendarItemStatus(entry: any) {

    if (entry['calendarStatus'] === 'S') {
      return '';
    } else if (entry['calendarStatus'] === 'F') {
      return 'appointment_free';
    } else {
      return '';
    }

  }

  setPatientData(data: any, type: string) {
    // console.log('---- calendar setpatientdata');
    // console.log('type', type);
    // console.log('data', data);
    data['NGdestinationService'] = type;
    this.sharedDataService.setPatientData(data);
  }

  setAppointmentData(data: any, header: any, type: string) {
    if (data?.calendarStatus == 'F' || data?.calendarStatus == 'S') {
      // console.log('data', data);
    // console.log('header', header);
    // console.log('type', type);
    data['NGdestinationService'] = type;
    data['servicePointFk'] = header.spId;
    data['serviceFk'] = header.svId;
    data['eventTypeFk'] = header.etId;
    data['multi'] = true;
    this.sharedDataService.setAppointmentData(data);
    this.showBookingModal.nativeElement.click();
    }
  }


  onGetCalendarDetails(calendarFk) {
    this.sharedApiService.getCalendarDetail(calendarFk).subscribe(data => {
      // console.log('Calendar Detail');
      // console.log(data);
    });
  }

  refreshCalendar() {
    // this.initialize(false);
  }

  onCallPatient(calendarId, calendarStatus) {
    // console.log ('callPatient clicked');
    if (this.callPatientLoading !== true && this.arrivePatientLoading !== true) {
      this.callPatientLoading = true;
      this.callNumber = '';
      // console.log ('callPatient calendarId: ' + calendarId + ', csId: ' + this.selectedCsId);
      this.callPatient(calendarId, this.selectedCsId).subscribe(response => {
        this.callPatientLoading = false;
        if (response['code'] === 200) {
          // console.log('callPatient:', response);
          this.callNumber = response['callNumber'];
          if (calendarStatus !== 'DD') {
            this.updateCalendarEntryStatus(response['calendarId'], 'DN');
          }
        } else {
          this.callNumber = '---';
          console.error('callPatient ERROR', response);
        }
      });
    } else {
      // console.log('Művelet folyamatban...');
    }
  }

  callPatient(calendarId, csId) {
    const params = {
      // messageHeader: {
      //   currentTapass: this.authService.getUserData()['ekCardNo'],
      //   currentRole: this.sharedDataService.getSelectedRole()['roleCode'],
      //   currentServicePointId: this.sharedDataService.getSelectedRole()['spId'],
      //   locale: 'hu',
      //   deviceId: this.authService.getUUID()
      // },
      csId: csId,
      calendarId: calendarId
    };

    return this.http.post(this.sharedDataService.callerSystemAPIURL + 'callpatient', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  onArrivePatient(ssn, calendarId) {
    if (this.callPatientLoading !== true && this.arrivePatientLoading !== true) {

      this.arrivePatientLoading = true;
      this.arrivePatient(ssn, calendarId, this.selectedCsId).subscribe(response => {
        this.arrivePatientLoading = false;
        if (response['code'] === 200) {
          this.updateCalendarEntryStatus(calendarId, 'DH');
          // console.log('arrivePatient:', response);

        } else {
          this.callNumber = '---';
          console.error('arrivePatient ERROR', response);
        }
      });
    } else {
      // console.log('Művelet folyamatban...');
    }
  }

  arrivePatient(ssn, calendarId, csId) {
    const params = {
      // messageHeader: {
      //   currentTapass: this.authService.getUserData()['ekCardNo'],
      //   currentRole: this.sharedDataService.getSelectedRole()['roleCode'],
      //   currentServicePointId: this.sharedDataService.getSelectedRole()['spId'],
      //   locale: 'hu',
      //   deviceId: this.authService.getUUID()
      // },
      calendarId: calendarId,
      csId: csId,
      ssn: ssn
    };

    return this.http.post(this.sharedDataService.callerSystemAPIURL + 'arrivepatient', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  updateCalendarEntryStatus(calendarId, status) {
    // console.log('updateCalendarEntryStatus ID:', calendarId);
    // console.log('updateCalendarEntryStatus status:', status);
    // console.log ('this.calendarListDataRows:', this.calendarListDataRows);
    // console.log('calendar', this.calendarListDataRows);
    // obj => obj.grpCode === item['grpCode'])
    const calendarListDataRowsTemp = this.calendarListDataRows;
    calendarListDataRowsTemp.forEach(row => {
      // console.log ('checking row:', row);
      row.forEach(item => {
        // console.log ('checking calendarFk:', item.calendarFk);
        if (item.calendarFk == calendarId) {
          item.calendarStatus = status;
          // console.log('item found', item);
        }
      });
    });
    this.calendarListDataRows = calendarListDataRowsTemp;
    // console.log('calendar', this.calendarListDataRows);

  }


  getPrescriptionInitData(calId) {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.getSelectedRole()['roleCode'],
        currentServicePointId: this.sharedDataService.getSelectedRole()['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calId: calId
    };

    return this.http.post(this.apiUrl + '/rest/neu/pres/getprescriptioninitdata', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  initVenyiro(calId) {
    this.getPrescriptionInitData(calId).subscribe(response => {
      if (response['code'] === 1) {
        const params = {};
        Object.keys(response['data']).forEach(key => {
          // console.log('key: ' + key + ' - ' + response['data'][key]);
          params[this.ucFirst(key)] = response['data'][key];
        });
        this.openTab.postHtml(params, 'http://localhost:8888/$');
      } else {
        this.error = true;
        this.errorDescription = 'Vényíró funkció: ' + response['message'];
        console.error('getPrescriptionInitData ERROR', response);
      }
    });


  }

  ucFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  calStatusUpdate(calendarId) {
    this.calStatusSave(calendarId, 'DD').subscribe(response => {
      if (response['code'] === 200) {
        this.updateCalendarEntryStatus(calendarId, 'DD');
        // console.log('calStatusSave:', response);

      } else {
        this.callNumber = '---';
        console.error('calStatusSave ERROR', response);
      }
    });
  }


  calStatusSave(calendarId, status) {
    const params = {
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.getSelectedRole()['roleCode'],
        currentServicePointId: this.sharedDataService.getSelectedRole()['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      },
      calendarId: calendarId,
      status: status
    };

    return this.http.post(this.sharedDataService.callerSystemAPIURL + 'updatecalendar', params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  closeError() {
    this.error = false;
    this.errorDescription = '';
  }

  setServices() {
    let filteredService = [];

    localStorage.setItem('et', null);
    localStorage.setItem('sp', null);

    if (this.servicePoint.value) {
      filteredService = this.availServic.filter(avail => avail.spId === this.servicePoint.value.spId);
      localStorage.setItem('sp', JSON.stringify(this.servicePoint.value));
    }

    if (this.eventType.value) {
      filteredService = this.availServic.filter(avail => avail.etId === this.eventType.value.etId);
      localStorage.setItem('et', JSON.stringify(this.eventType.value));
    }

    if (this.eventType.value && this.servicePoint.value) {
      filteredService = this.availServic.filter(avail => avail.etId === this.eventType.value.etId
        && avail.spId === this.servicePoint.value.spId);
    }
    this.filterEt (this.servicePoint.value);
    this.filterSp (this.eventType.value);

    this.initCalendar(false, filteredService);
    this.showSettings = false;
  }

  filterEt(event) {
    if (event && event.spId) {
      const etList = [];
      this.availServic.forEach(
        serv => {
          if (serv.spId === event.spId && !etList.find(e => e.etId === serv.etId)) {
            etList.push({ etId: serv.etId, etName: serv.etName });
          }
        });
      this.etListFiltered = etList;

    } else {
      this.etListFiltered = this.etList;
    }
  }

  filterSp(event) {

    if (event && event.etId) {
      const spList = [];
      this.availServic.forEach(
        serv => {
          if (serv.etId === event.etId && !spList.find(s => s.spId === serv.spId)) {
            spList.push({ spId: serv.spId, spName: serv.spName });
          }
        });
      this.spListFiltered = spList;

    } else {
      this.spListFiltered = this.spList;
    }
  }


  SecsSinceMidnight(date) {
    let dateObj;
    let miliSeconds = null;
    if (date) {
      try {
        dateObj= new Date(this.sharedDataService.DateStringConvert(date));
      } catch (e){
        //  console.log(e);
      }
      if (!dateObj){
        try {
          dateObj= new Date(date);
        } catch (e){
          // console.log(e);
        }
      }
    }
    if (dateObj) {
      miliSeconds = ((dateObj.getHours()*60)+ dateObj.getMinutes())*60000;
    } 
    return miliSeconds;
  }

  getMinutes(date) {
    let dateObj;
    let minutes = null;
    if (date) {
      try {
        dateObj= new Date(this.sharedDataService.DateStringConvert(date));
      } catch (e){
        //  console.log(e);
      }
      if (!dateObj){
        try {
          dateObj= new Date(date);
        } catch (e){
          // console.log(e);
        }
      }
    }
    if (dateObj) {
      minutes = (dateObj.getMinutes());
    } 
    return minutes;
  }

  

  calculateStartTimeStamp (dateFrom, minTime) {
    const newDateFrom = dateFrom - this.SecsSinceMidnight(dateFrom) + minTime;
    return newDateFrom;
  }


}