import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appUpperCaseFirst]'
})
export class UpperCaseFirstDirective {

  constructor(
    public ngControl: NgControl
    ) { }

  @HostListener('ngModelChange', ['$event'])
  onInputChange(value) {
    // console.log ('uc change', value);
    if (value !== null && value !== undefined) {
      const titleCaseStr = value.charAt(0).toUpperCase() + value.slice(1);
      // console.log ('uc new', titleCaseStr);
      this.ngControl.valueAccessor.writeValue(titleCaseStr);
    }
  }
}
