import { Subscription } from 'rxjs';
import { LoggerService } from './../services/logger.service';
import { ToastrService } from "ngx-toastr";
import {
  AfterViewInit,
  Component,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  faChevronDown,
  faChevronUp,
  faTrash,
  faInfoCircle,
  faCog,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FormControl, FormGroup } from "@angular/forms";
import { EventEmitter } from "@angular/core";
import { SharedDataService } from "../services/shared-data.service";

export interface StatusTimeLines {
  dateFrom: number;
  dateTo: number;
  name: string;
  status: string;
  displayData;
}

export interface Time {
  hour: number;
  minute: number;
}

export interface CellsToBook {
  dateFrom: Time;
  dateTo: Time;
  name: string;
  status: string;
}

export interface WeekScheduleDay {
  name: string;
  timeStamp: number;
}

export interface DayHoursFromTo {
  hourFrom: number;
  hourTo: number;
}

export interface CellData {
  name: string;
  bookStart: boolean;
  bookEnd: boolean;
  hour: number;
  minute: number;
  defaultStatus;
  displayData;
}

export interface Week {
  name: string;
  timeStamp;
  daySettingControl: FormControl;
  cells: Array<CellData>;
}

export interface hoveredCell {
  dayName: string;
  hour: number;
}

export interface CellTimeData {
  dateString: string;
  dateHour: number;
  dateMinute: number;
}

@Component({
  selector: "app-scheduler-modal",
  templateUrl: "./scheduler-modal.component.html",
  styleUrls: ["./scheduler-modal.component.css"],
})
export class SchedulerModalComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  leftMouseButtonOnlyDown: boolean;
  showSettings: boolean;
  faChevronDownIcon = faChevronDown;
  faChevronUpIcon = faChevronUp;
  faTrash = faTrash;
  faInfo = faInfoCircle;
  faCog = faCog;
  faCalendarAlt = faCalendarAlt;

  previousIndex = null;
  onDeleteMode = false;

  startTimeFormControl = new FormControl();
  endTimeFormControl = new FormControl();

  settingsFormGroup = new FormGroup({
    startTimeFormControl: this.startTimeFormControl,
    endTimeFormControl: this.endTimeFormControl,
  });
  week: Week[] = [];

  dayHours: Array<number> = new Array();

  scheduleBody;

  timeStamps = [1, 5, 10, 15, 30, 60];
  timeOptions = [
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
  ];
  hoveredCell: hoveredCell = { dayName: null, hour: null };
  statusTimeLines: Array<Array<StatusTimeLines>>;

  constructor(
    private toastr: ToastrService,
    private zone: NgZone,
    private sharedDataService: SharedDataService
  ) { }

  @Input() weekScheduleDay: Array<WeekScheduleDay>;
  @Input() dayLenghtInHours: DayHoursFromTo;
  @Input() bookedCells: Array<StatusTimeLines>;
  @Input() coCells: Array<StatusTimeLines>;
  @Input() tempCells: Array<StatusTimeLines>;
  @Input() availableCells: Array<StatusTimeLines>;
  @Input() fromBooking: boolean;
  @Input() resetSelection: boolean;
  @Output()
  bookCells: EventEmitter<CellsToBook[]> = new EventEmitter<CellsToBook[]>();
  @Output() deleteCells: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  dateRangeChange: EventEmitter<DayHoursFromTo> = new EventEmitter<DayHoursFromTo>();
  @Output() bookedCellClick: EventEmitter<any> = new EventEmitter<any>();

  cellBooks: CellsToBook[] = [];

  appointmentSubscription: Subscription;

  ngOnChanges() {
    this.setWeekHours(
      this.dayLenghtInHours.hourFrom,
      this.dayLenghtInHours.hourTo
    );
    this.settingsFormGroup.controls.startTimeFormControl.setValue(
      this.dayLenghtInHours.hourFrom
    );
    this.settingsFormGroup.controls.endTimeFormControl.setValue(
      this.dayLenghtInHours.hourTo
    );
    this.createWeek();
    /* this.groupAvailableCellsToContinualTimelines(); */
    this.markCells();
    this.resetSelections();
  }

  ngOnInit() {
    this.sharedDataService.scheduleMouseButtonState.subscribe((buttonState) => {
      this.leftMouseButtonOnlyDown = buttonState;
    });
    this.settingsFormGroup.controls.startTimeFormControl.setValue(
      this.dayLenghtInHours.hourFrom
    );
    this.settingsFormGroup.controls.endTimeFormControl.setValue(
      this.dayLenghtInHours.hourTo
    );
    this.setWeekHours(
      this.dayLenghtInHours.hourFrom,
      this.dayLenghtInHours.hourTo
    );
    document.body.onmousedown = () => {
      /* this.leftMouseButtonOnlyDown = true; */
      this.sharedDataService.setSchedulerMouseButtonState(true);
    };
    document.body.onmouseup = () => {
      /* this.leftMouseButtonOnlyDown = false; */
      this.onDeleteMode = false;
      this.sharedDataService.setSchedulerMouseButtonState(false);
    };

    this.appointmentSubscription = this.sharedDataService.appointmentDataShared.subscribe(
      value => {
        this.selectedCal = value;
      });
  }

  selectedCal = null;

  ngAfterViewInit() {
    this.scheduleBody = document.getElementById("scheduleBody");
    this.scheduleBody.addEventListener("mouseleave", (e) => {
      /* this.leftMouseButtonOnlyDown = false; */
      this.sharedDataService.setSchedulerMouseButtonState(false);
      this.hoveredCell.dayName = null;
      this.hoveredCell.hour = null;
    });
  }

  ngOnDestroy() {
    this.scheduleBody.removeEventListener(
      "mouseleave",
      this.resetMouseButton()
    );

    this.appointmentSubscription.unsubscribe();
  }

  resetMouseButton() {
    this.leftMouseButtonOnlyDown = false;
  }

  resetSelections() {
    if (this.resetSelection) {
      this.week.forEach((day) => {
        day.cells.forEach((cell) => {
          if (cell.name === "S") {
            cell.name = "F";
          }
        });
      });
    }
    this.toastr.clear();
  }

  isCellCurrentTime(cellData: CellData): boolean {
    const currentTime = new Date();
    const cellMinutePlusFive = ((cellData.minute + 5) === 60 ? 0 : cellData.minute + 5);
    const cellMinutePlusTen = ((cellData.minute + 10) === 65 ? 5 : cellData.minute + 10);
    if (cellData && cellData.hour === currentTime.getHours() &&
      (cellMinutePlusFive <= currentTime.getMinutes() && cellMinutePlusTen > currentTime.getMinutes())) {
      return true;
    } else {
      return false;
    }
  }

  showData(data: string) {
    this.toastr.clear();
    this.toastr.info(data.substring(data.indexOf("/") + 1), "Foglalás adatai", {
      disableTimeOut: true,
      closeButton: true,
    });
  }

  deleteBooking(description: string) {
    this.toastr.clear();
    this.deleteCells.emit(description);
  }

  markCells() {
    this.statusTimeLines = [this.availableCells, this.bookedCells, this.tempCells, this.coCells];
    const clonedWeekArray: Week[] = [];
    this.week.forEach((val) => clonedWeekArray.push(Object.assign({}, val)));
    this.statusTimeLines.forEach((statusTimeLine) => {
      statusTimeLine?.forEach((cellToMark) => {
        if (clonedWeekArray.find((x) => x.name === cellToMark.name)) {
          let timeStampTemp = clonedWeekArray.find(
            (x) => x.name === cellToMark.name
          ).timeStamp;
          let dateFromData: CellTimeData = this.getCellDateData(
            cellToMark,
            true
          );
          let dateToData: CellTimeData = this.getCellDateData(
            cellToMark,
            false
          );
          let dateFromTimeInMinutes =
            dateFromData.dateHour * 60 + dateFromData.dateMinute;
          let dateToTimeInMinutes =
            dateToData.dateHour * 60 + dateToData.dateMinute;

          clonedWeekArray.forEach((day) => {
            if (day.name === cellToMark.name) {
              day.cells.forEach((cell) => {
                let cellFromTimeInMinutes = cell.hour * 60 + cell.minute;
                if (
                  dateToTimeInMinutes - dateFromTimeInMinutes ===
                  timeStampTemp &&
                  cellFromTimeInMinutes === dateFromTimeInMinutes
                ) {
                  if ((cellFromTimeInMinutes = dateFromTimeInMinutes)) {
                    cell.name = cellToMark.status;
                    cell.displayData = cellToMark.displayData;
                    cell.bookStart = true;
                    cell.bookEnd = true;
                    cell.defaultStatus = cellToMark.status;
                  }
                } else {
                  if (
                    cellFromTimeInMinutes >= dateFromTimeInMinutes &&
                    cellFromTimeInMinutes < dateToTimeInMinutes - timeStampTemp
                  ) {
                    cell.name = cellToMark.status;
                    cell.defaultStatus = cellToMark.status;
                    cell.displayData = cellToMark.displayData;
                    if (cellFromTimeInMinutes === dateFromTimeInMinutes) {
                      cell.bookStart = true;
                      cell.displayData = cellToMark.displayData;
                    }
                  } else if (
                    cellFromTimeInMinutes ===
                    dateToTimeInMinutes - timeStampTemp
                  ) {
                    cell.name = cellToMark.status;
                    cell.defaultStatus = cellToMark.status;
                    cell.displayData = cellToMark.displayData;
                    cell.bookEnd = true;
                  }
                }
              });
            }
          });
        }
      });
    });

    this.week = clonedWeekArray;
  }

  getCellDateData(cell, getFromDate): CellTimeData {
    let dateString: string = getFromDate
      ? cell.dateFrom.toString()
      : cell.dateTo.toString();
    let dateHour =
      dateString.length === 4
        ? +dateString.substring(0, 2)
        : +dateString.substring(0, 1);
    let dateMinute =
      dateString.length === 4 ? +dateString.substr(2) : +dateString.substr(1);
    return {
      dateHour,
      dateMinute,
      dateString,
    };
  }

  createWeek() {
    this.week = [];

    this.weekScheduleDay.forEach((day) => {
      let dayCells: CellData[] = [];
      this.dayHours.forEach((dayHour) => {
        for (let i = 0; i < 60 / day.timeStamp; i++) {
          dayCells.push({
            name: "F",
            bookStart: false,
            bookEnd: false,
            hour: dayHour,
            minute: (i * day.timeStamp) % 60,
            displayData: "",
            defaultStatus: "F",
          });
        }
      });

      this.week.push({
        name: day.name,
        cells: dayCells,
        timeStamp: day.timeStamp,
        daySettingControl: new FormControl(),
      });
    });

    // console.log('week: ', this.week);

  }

  setWeekHours(from, to) {
    this.dayHours = [];
    let i = from;
    while (i <= to) {
      this.dayHours.push(i);
      i += 1;
    }
    this.createWeek();
    this.markCells();
  }

  updateDateSubject() {
    this.dateRangeChange.emit({
      hourFrom: this.settingsFormGroup.controls.startTimeFormControl.value,
      hourTo: this.settingsFormGroup.controls.endTimeFormControl.value,
    });
  }

  isDayIncludesCellType(day, cellType: string): boolean {
    for (let i = 0; i < this.week[day].cells.length; i++) {
      if (this.week[day].cells[i].name === cellType) {
        return true;
      }
    }
    return false;
  }

  daysIndexOfCellType(day, cellType: string): number {
    for (let i = 0; i < this.week[day].cells.length; i++) {
      if (this.week[day].cells[i].name === cellType) {
        return i;
      }
    }
    return -1;
  }

  daysLastIndexOfCellType(day, cellType: string): number {
    for (let i = this.week[day].cells.length - 1; i >= 0; i--) {
      if (this.week[day].cells[i].name === cellType) {
        return i;
      }
    }
    return -1;
  }

  emitData(z, deleted) {

    if (this.isDayIncludesCellType(z, "S") && !deleted) {
      let cellsToBook: CellsToBook = null;
      let timeStamp = this.weekScheduleDay.find(
        (x) => x.name === this.week[z].name
      ).timeStamp;

      cellsToBook = {
        dateFrom: {
          hour: this.dayHours[
            ((this.daysIndexOfCellType(z, "S") + 1) * timeStamp) % 60 === 0
              ? Math.trunc((this.daysIndexOfCellType(z, "S") * timeStamp) / 60)
              : Math.trunc(
                ((this.daysIndexOfCellType(z, "S") + 1) * timeStamp) / 60
              )
          ],
          minute:
            this.daysIndexOfCellType(z, "S") * timeStamp >= 60
              ? (this.daysIndexOfCellType(z, "S") * timeStamp) % 60
              : this.daysIndexOfCellType(z, "S") * timeStamp,
        },
        dateTo: {
          hour: this.dayHours[
            Math.trunc(
              ((this.daysLastIndexOfCellType(z, "S") + 1) * timeStamp) / 60
            )
          ]
            ? this.dayHours[
            Math.trunc(
              ((this.daysLastIndexOfCellType(z, "S") + 1) * timeStamp) / 60
            )
            ]
            : this.dayHours[this.dayHours.length - 1] + 1,
          minute:
            (this.daysLastIndexOfCellType(z, "S") + 1) * timeStamp >= 60
              ? ((this.daysLastIndexOfCellType(z, "S") + 1) * timeStamp) % 60
              : (this.daysLastIndexOfCellType(z, "S") + 1) * timeStamp,
        },
        name: this.week[z].name,
        status: this.week[z].cells.find((x) => x.name === "S").defaultStatus,
      };


      this.toastr.clear();
      this.toastr.info(
        cellsToBook.dateFrom.hour +
        ":" +
        ("0" + cellsToBook.dateFrom.minute).slice(-2) +
        " - " +
        cellsToBook.dateTo.hour +
        ":" +
        ("0" + cellsToBook.dateTo.minute).slice(-2),
        "",
        { disableTimeOut: true, closeButton: true }
      );
      this.cellBooks.push(cellsToBook);
      this.bookCells.emit(this.cellBooks);
    } else {
      this.bookCells.emit(this.cellBooks);

      this.week[z].cells.forEach((cell) => {
        if (cell.name === "S") {
          cell.name = cell.defaultStatus;
        }
      });
      this.toastr.clear();
    }
  }

  setCellByClick(cellIndex, z) {
    this.startCellSelectionProcess(cellIndex, z, true);
  }

  setCellByHover(cellIndex: number, z: number) {
    if (this.leftMouseButtonOnlyDown) {
      this.startCellSelectionProcess(cellIndex, z, false);
    }
  }

  setAppointmentData() {
    this.selectedCal['NGdestinationService'] = 'bookAppointment';
    this.selectedCal['multi'] = false;
    this.sharedDataService.setAppointmentData(this.selectedCal);
  }

  startCellSelectionProcess(cellIndex, z, isClickSelection) {
    //check if cell booked    
    if (this.week[z].cells[cellIndex].name !== "B") {
      //check if cell not selected already
      if (this.week[z].cells[cellIndex].name !== "S" && !this.onDeleteMode) {
        //check if cell to select is first or last selected cell, or first cell to select
        if (
          (cellIndex > 0 && this.week[z].cells[cellIndex - 1].name === "S") ||
          (cellIndex < this.week[z].cells.length - 1 &&
            this.week[z].cells[cellIndex + 1].name === "S") ||
          this.week[z].cells.filter((x) => x.name === "S").length === 0
        ) {
          this.checkIsBookable(z, cellIndex);

        } else {
          this.toastr.clear();
          this.toastr.error("már megkezdett egy foglalást mára!");
        }
        //check if the clicked selected cell is first or last
      } else if (
        this.daysIndexOfCellType(z, "S") === cellIndex ||
        this.daysLastIndexOfCellType(z, "S") === cellIndex ||
        this.onDeleteMode
      ) {
        // check if next hovered cell is a group of available cells
        if (
          ((this.previousIndex || isClickSelection) &&
            this.week[z].cells[cellIndex].defaultStatus === "A" &&
            !(
              this.week[z].cells[cellIndex].bookStart &&
              this.week[z].cells[cellIndex].bookEnd
            )) ||
          this.onDeleteMode
        ) {
          // check if cursor hovered from available cell group or in the delete mode
          if (
            this.week[z].cells[cellIndex].bookStart &&
            (this.previousIndex < cellIndex || isClickSelection)
          ) {
            this.week[z].cells[cellIndex].name = this.week[z].cells[
              cellIndex
            ].defaultStatus;
            this.selectRemainingAvailableCellsOfGroup(cellIndex, z, false);
            this.previousIndex = cellIndex;
            this.onDeleteMode = true;
            this.week[z].cells.forEach((cell) => {
              this.cellBooks = this.cellBooks.filter(c => !(JSON.stringify(c.dateFrom.hour) === JSON.stringify(cell.hour) &&
                JSON.stringify(c.dateFrom.minute) === JSON.stringify(cell.minute) &&
                JSON.stringify(this.week[z].name) === JSON.stringify(c.name)));
            });
            this.emitData(z, true);

          } else if (
            this.week[z].cells[cellIndex].bookEnd &&
            (this.previousIndex > cellIndex || isClickSelection)
          ) {
            this.week[z].cells[cellIndex].name = this.week[z].cells[
              cellIndex
            ].defaultStatus;
            this.selectRemainingAvailableCellsOfGroup(cellIndex, z, false);
            this.previousIndex = cellIndex;
            this.onDeleteMode = true;

            this.week[z].cells.forEach((cell) => {
              this.cellBooks = this.cellBooks.filter(c => !(JSON.stringify(c.dateFrom.hour) === JSON.stringify(cell.hour) &&
                JSON.stringify(c.dateFrom.minute) === JSON.stringify(cell.minute) &&
                JSON.stringify(this.week[z].name) === JSON.stringify(c.name)));
            });
            this.emitData(z, true);

          }
        } else {
          this.week[z].cells[cellIndex].name = this.week[z].cells[
            cellIndex
          ].defaultStatus;
          this.previousIndex = cellIndex;
        }
        this.emitData(z, false);
      } else if (isClickSelection) {
        this.week[z].cells.forEach((cell) => {
          if (cell.name === "S") {
            cell.name = cell.defaultStatus;

            if (cell.bookStart) {
              this.cellBooks = this.cellBooks.filter(c => !(JSON.stringify(c.dateFrom.hour) === JSON.stringify(cell.hour) &&
                JSON.stringify(c.dateFrom.minute) === JSON.stringify(cell.minute) &&
                JSON.stringify(this.week[z].name) === JSON.stringify(c.name)));
            }
          }
        });
        //this.emitData(z, true);
      }
    } else {
      this.toastr.clear();
      this.bookedCellClick.emit(this.week[z].cells[cellIndex]);
      /* this.toastr.error("ez az időpont foglalt!"); */
    }
  }

  checkIsBookable(z, cellIndex) {
    if (this.sharedDataService.getSelectedRole()['roleCode'].includes('AUTH$PL-ECHOKFT') ||
      this.sharedDataService.getSelectedRole()['roleCode'].includes('AUTH$SZTVINCENT')) {
      if (
        this.week.filter(
          (x) =>
            x.name !== this.week[z].name &&
            x.cells.filter((z) => z.name === "S").length > 0
        ).length > 0 && this.week[z].cells[cellIndex].name === 'F'
      ) {
        this.toastr.clear();
        this.toastr.error("A kiválasztott időpont lefoglalására nincs lehetőség.");
        return;
      }
      //check if cell not available (not available cells are emergency cells (F))
      if (this.week[z].cells[cellIndex].name !== "A") {
        //check if there is available (A) cell selected
        if (
          this.week[z].cells.filter(
            (x) => x.name === "S" && x.defaultStatus === "A"
          ).length > 0
        ) {
          this.toastr.clear();
          this.toastr.error(
            "már megkezdett egy foglalást egy kiírt időpontra"
          );
        } else {
          this.toastr.error(
            "Nincs kigenerálva időpont erre az időszakra!"
          );
        }
      } else {
        //cell is available
        //check if there is emergency (F) cell selected
        if (
          this.week[z].cells.filter(
            (x) => x.name === "S" && x.defaultStatus === "F"
          ).length > 0
        ) {
          this.toastr.clear();
          this.toastr.error(
            "már megkezdett egy foglalást egy sürgősségi időpontra"
          );
        } else {
          // select all remaining cell of Available cell group if not the only one
          if (
            !(
              this.week[z].cells[cellIndex].bookStart &&
              this.week[z].cells[cellIndex].bookEnd
            )
          ) {
            if (this.selectRemainingAvailableCellsOfGroup(cellIndex, z, true)) {
              return;
            }
          }
          this.week[z].cells[cellIndex].name = "S";
          this.previousIndex = cellIndex;
          this.emitData(z, false);
        }
      }
    } else {
      if (
        this.week.filter(
          (x) =>
            x.name !== this.week[z].name &&
            x.cells.filter((z) => z.name === "S").length > 0
        ).length > 0
      ) {
        this.toastr.clear();
        this.toastr.error("már megkezdett egy foglalást egy másik napra");
      } else {
        //check if cell not available (not available cells are emergency cells (F))
        if (this.week[z].cells[cellIndex].name !== "A") {
          //check if there is available (A) cell selected
          if (
            this.week[z].cells.filter(
              (x) => x.name === "S" && x.defaultStatus === "A"
            ).length > 0
          ) {
            this.toastr.clear();
            this.toastr.error(
              "már megkezdett egy foglalást egy kiírt időpontra"
            );
          } else {
            this.week[z].cells[cellIndex].name = "S";
            this.previousIndex = cellIndex;
            this.emitData(z, false);
          }
        } else {
          //cell is available
          //check if there is emergency (F) cell selected
          if (
            this.week[z].cells.filter(
              (x) => x.name === "S" && x.defaultStatus === "F"
            ).length > 0
          ) {
            this.toastr.clear();
            this.toastr.error(
              "már megkezdett egy foglalást egy sürgősségi időpontra"
            );
          } else {

            // select all remaining cell of Available cell group if not the only one
            if (
              !(
                this.week[z].cells[cellIndex].bookStart &&
                this.week[z].cells[cellIndex].bookEnd
              )
            ) {
              if (this.selectRemainingAvailableCellsOfGroup(cellIndex, z, true)) {
                return;
              }
              this.week[z].cells[cellIndex].name = "S";
              this.previousIndex = cellIndex;
            }
            this.emitData(z, false);
          }
        }
      }
    }
  }

  selectRemainingAvailableCellsOfGroup(cellIndex, z, isSelect) {

    if (cellIndex === 0) {
      if (!this.week[z].cells[cellIndex].bookStart) {
        this.toastr.info('Az Ön által választani kívánt időpont nem esik bele a megjelenített rendelési időintervallumba, kérem módosítsa a megjelenítési beállításokat!');
        return true;
      } else {
        if (
          this.week[z].cells[cellIndex].bookStart &&
          !this.week[z].cells[cellIndex].bookEnd
        ) {
          this.selectRemainingAvailableCellsFromStart(cellIndex, z, isSelect);
        } else if (
          this.week[z].cells[cellIndex].bookEnd &&
          !this.week[z].cells[cellIndex].bookStart
        ) {
          this.selectRemainingAvailableCellsFromEnd(cellIndex, z, isSelect);
        } else {
          this.selectRemainingAvailableCellsFromStart(cellIndex, z, isSelect);
          this.selectRemainingAvailableCellsFromEnd(cellIndex, z, isSelect);
        }
        return false;
      }
    } else {
      let haveStartCell = false;
      let haveEndCell = false;

      let i = cellIndex;

      do {
        if (i >= 0) {
          haveStartCell = this.week[z].cells[i].bookStart;
        }
      }
      while (i >= 0 && !this.week[z].cells[i--]?.bookStart);

      i = cellIndex;

      do {
        if (i <= this.week[z].cells.length - 1) {
          haveEndCell = this.week[z].cells[i].bookEnd;
        }
      }
      while (i <= this.week[z].cells.length - 1 && !this.week[z].cells[i++]?.bookEnd);

      if (haveStartCell && haveEndCell) {
        if (
          this.week[z].cells[cellIndex].bookStart &&
          !this.week[z].cells[cellIndex].bookEnd
        ) {
          this.selectRemainingAvailableCellsFromStart(cellIndex, z, isSelect);
        } else if (
          this.week[z].cells[cellIndex].bookEnd &&
          !this.week[z].cells[cellIndex].bookStart
        ) {
          this.selectRemainingAvailableCellsFromEnd(cellIndex, z, isSelect);
        } else {
          this.selectRemainingAvailableCellsFromStart(cellIndex, z, isSelect);
          this.selectRemainingAvailableCellsFromEnd(cellIndex, z, isSelect);
        }
        return false;
      } else {
        this.toastr.info('Az Ön által választani kívánt időpont nem esik bele a megjelenített rendelési időintervallumba, kérem módosítsa a megjelenítési beállításokat!');
        return true;
      }
    }
  }

  selectRemainingAvailableCellsFromStart(cellIndex, z, isSelect) {
    let i = cellIndex + 1;
    do {
      this.week[z].cells[i].name = isSelect
        ? "S"
        : this.week[z].cells[i].defaultStatus;
    } while (!this.week[z].cells[i++].bookEnd);
  }

  selectRemainingAvailableCellsFromEnd(cellIndex, z, isSelect) {
    let i = cellIndex - 1;

    do {
      this.week[z].cells[i].name = isSelect
        ? "S"
        : this.week[z].cells[i].defaultStatus;
    } while (!this.week[z].cells[i--].bookStart);


  }

  isOnlyDaySelected(z): boolean {
    let isOnlyDaySelected = true;
    for (let i = 0; i < this.week.length; i++) {
      if (z != i && this.isDayIncludesCellType(i, "S")) {
        isOnlyDaySelected = false;
        break;
      }
    }
    return isOnlyDaySelected;
  }
}
