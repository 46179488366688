<div id="saveSuccess" [ngClass]="{'showResult': saveResult == 0}">
    {{ successMessage }}
</div>

<div class="wrapper topWrapper">
    <div class="callPatient mt-3 mr-2" *ngIf="authorityData && authorityData.authorityTypeId && generalService.checkModuleAccess(authorityData.authorityTypeId) && generalService.checkModuleAccess(authorityData.authorityTypeId).callPatient">
        <button class="callPatientButton btn btn-primary" (click)="callPatient(generalService.getCurrentCsId())">Behív</button>
    </div>
    <div *ngIf="error  == -118" class="alert alert-danger" role="alert">
        {{errorMessage}}
    </div>
    <div class="searchByTaj">
        <form class=" form-inline mt-3">
            <input [formControl]="arriveInput" class="appointmentInput form-control" type="text" placeholder="Adja meg a TAJ számot..." />
            <button (click)="searchByTaj(arriveInput.value)" class="addAppointmentButton btn btn-primary" type="submit" form="searchByTaj">Keresés</button>
        </form>
        <div *ngIf="error  == 2" class="alert alert-danger" role="alert">
            {{errorMessage}}
        </div>
    </div>
    <div class="saveAppointment">
        <form class=" form-inline mt-3" *ngIf="authorityData && authorityData.authorityTypeId && generalService.checkModuleAccess(authorityData.authorityTypeId) && generalService.checkModuleAccess(authorityData.authorityTypeId).saveAppointment">
            <input [formControl]="arriveInput" class="saveAppointmentInput form-control" type="text" placeholder="Sürgős eset rögzítése..." />
            <button (click)="saveAppointment()" class="addAppointmentButton btn btn-primary" type="submit" form="saveAppointment">Mentés</button>
        </form>
        <div *ngIf="error == 1" class="alert alert-danger" role="alert">
            {{errorMessage}}
        </div>
    </div>
</div>

<div class="wrapper">
    <div class="worklistWrapper">
        <div *ngFor="let worklistRecord of worklist" class="worklistRecord">
            <div id="{{worklistRecord['ssn']}}" class="calendarStatus-{{worklistRecord['nauStatus']}}">
                <div class="calendarDetail-time">
                    {{worklistRecord['timeFrom'].substr(worklistRecord.timeFrom.indexOf('T')+ 1,5)}} </div>
                <div class="calendarDetail-description">{{worklistRecord['longDescription']}} </div>
                <div class="calendarDetail-callnumber">#{{worklistRecord['callNumber']}}</div>

                <button *ngIf="authorityData && worklistRecord['nauStatus'] == 'S' && authorityData.authorityTypeId && generalService.checkModuleAccess(authorityData.authorityTypeId).arrivePatient" (click)="arrivePatient(worklistRecord['calendarId'], worklistRecord['ssn'])"
                    class="modifyCalendarDataButton btn btn-light">
          <i aria-hidden="true" class="fa fa-play"></i>
        </button>
                <button *ngIf="authorityData && worklistRecord['nauStatus'] == 'DH' && authorityData.authorityTypeId &&
                    generalService.checkModuleAccess(authorityData.authorityTypeId).callPatient && authorityData.type !== 'V'" (click)="callPatient(generalService.getCurrentCsId(),worklistRecord['calendarId'])" class="modifyCalendarDataButton mr-auto btn btn-light">
          <i aria-hidden="true" class="fa fa-desktop"></i>
        </button>

                <button type="button" class="modifyCalendarDataButton btn btn-danger mr-auto" title="Páciens regisztrálása" data-toggle="modal" *ngIf="(authorityData && authorityData.authorityTypeId &&
          generalService.checkModuleAccess(authorityData.authorityTypeId).savePatientToAppointment) && 
          worklistRecord['nauStatus'] == 'DH' && authService.getFeature() == 'FULL'
          && worklistRecord['patientId'] <= 0" data-target="#paciensreg" (click)="setPatientData(worklistRecord, 'registerPatient')">
          <i class="fa fa-user-plus"></i>
        </button>

                <button *ngIf="(authorityData && authorityData.authorityTypeId &&
          generalService.checkModuleAccess(authorityData.authorityTypeId).saveLabOrder) && 
          worklistRecord['nauStatus'] == 'DH' && authService.getFeature() == 'FULL'
          && worklistRecord['patientId'] > 0" class="modifyCalendarDataButton mr-auto btn btn-light" data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#laborder" title="Laborder" (click)="setPatientData(worklistRecord, 'labOrder')">
          <i aria-hidden="true" class="fa fa-folder"></i>
        </button>

                <button type="button" class="modifyCalendarDataButton mr-auto btn btn-light" *ngIf="(authorityData && authorityData.authorityTypeId &&
          generalService.checkModuleAccess(authorityData.authorityTypeId).saveSamples) && 
          worklistRecord['nauStatus'] == 'DN' && authService.getFeature() == 'FULL'
          && worklistRecord['patientId'] > 0" data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#labsample" title="LabSample" (click)="setPatientData(worklistRecord, 'labSample')">
          <i aria-hidden="true" class="fa fa-flask"></i>
        </button>

                <button *ngIf="authorityData && worklistRecord['nauStatus'] == 'DN' && authorityData.authorityTypeId &&
                    generalService.checkModuleAccess(authorityData.authorityTypeId).callPatient && authorityData.type !== 'V'
                    && authService.getFeature() == 'LIGHT'
                    " (click)="updateCalendar(worklistRecord)" class="modifyCalendarDataButton mr-auto btn btn-light">
          <i aria-hidden="true" class="fa fa-check"></i>
        </button>


                <button *ngIf="authorityData && worklistRecord['nauStatus'] == 'DN' && authorityData.authorityTypeId &&
                    generalService.checkModuleAccess(authorityData.authorityTypeId).callPatient && authorityData.type !== 'V'" (click)="callPatient(generalService.getCurrentCsId(),worklistRecord['calendarId'])" class="modifyCalendarDataButton btn btn-light">
          <i aria-hidden="true" class="fa fa-desktop"></i>
        </button>



                <button *ngIf="worklistRecord['nauStatus'] == 'DD' && authorityData.authorityTypeId && generalService.checkModuleAccess(authorityData.authorityTypeId).callPatientDD" (click)="callPatient(generalService.getCurrentCsId(),worklistRecord['calendarId'])" class="modifyCalendarDataButton btn btn-light">
          <i aria-hidden="true" class="fa fa-desktop"></i>
        </button>
                <button *ngIf="worklistRecord['nauStatus'] == 'DB' && authorityData.authorityTypeId && generalService.checkModuleAccess(authorityData.authorityTypeId).callPatientDD" (click)="callPatient(generalService.getCurrentCsId(),worklistRecord['calendarId'])" class="modifyCalendarDataButton btn btn-light">
          <i aria-hidden="true" class="fa fa-desktop"></i>
        </button>
                <button type="button" class="modifyCalendarDataButton btn btn-light" *ngIf="(authorityData && authorityData.authorityTypeId &&
        generalService.checkModuleAccess(authorityData.authorityTypeId).saveSamples) &&
        worklistRecord['nauStatus'] == 'DN' && authService.getFeature() == 'FULL'" (click)="returnPatientToDispatcher(generalService.getCurrentCsId(),worklistRecord)">
        <i aria-hidden="true" class="fa fa-arrow-left"></i>
      </button>
                <!-- <button type="button" class="modifyCalendarDataButton btn btn-primary ml-1 mb-xs-1" data-toggle="modal" data-backdrop="static"
            data-keyboard="false" data-target="#laborder" title="Laborder"
            (click)="setPatientData(worklistRecord, 'labOrder')"
            *ngIf="worklistRecord.nauStatus == 'DH'">
            <i class="fa fa-flask"></i>
          </button> -->
                <!-- {{ worklistRecord.nauStatus }} -->
            </div>
        </div>
    </div>
</div>