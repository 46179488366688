import { Authority } from './../shared/models/authority';
import { FindByCsIdResponse } from './../shared/models/response/findbyCsIdResponse';
import { BaseResponse } from './../shared/models/response/baseResponse';
import { SaveAppointmentRequest } from './../shared/models/request/saveAppointmentsRequest';
import { ArrivePatientResponse } from './../shared/models/response/arrivePatientResponse';
import { ArrivePatientRequest } from './../shared/models/request/arrivePatientRequest';
import { GetWorkListResponse } from 'src/app/shared/models/response/getWorkListResponse';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ApiService } from './../core/http/api.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { GetTreeForCsidResponse } from 'src/app/shared/models/response/getTreeForCsidResponse';
import { timer, Subscription } from 'rxjs';
import { CallPatientRequest } from 'src/app/shared/models/request/callPatientRequest';
import { CallPatientResponse } from 'src/app/shared/models/response/callPatientResponse';
import { UpdateCalendarRequest } from 'src/app/shared/models/request/updateCalendarRequest';
import { AuthService } from '../auth/auth.service';
import { SharedDataService } from '../services/shared-data.service';

@Component({
  selector: 'app-dispatcher',
  templateUrl: './dispatcher.component.html',
  styleUrls: ['./dispatcher.component.css']
})

export class DispatcherComponent implements OnInit, OnDestroy {

  errorMessage: string;
  error: number;

  worklist = new Array<object>();
  arriveInput = new FormControl('');
  authorityData: Authority;

  saveResult = null;
  successMessage = '';

  autoRefreshCalendarData = null;

  setSelectedCsIdSubscription: Subscription;
  selectedCsId: null;
  updateCalendarEntrySubscription = new Subscription();

  constructor(
    public generalService: GeneralService,
    private apiService: ApiService,
    private sharedDataService: SharedDataService,
    public authService: AuthService
  ) { }

  ngOnInit() {

    this.updateCalendarEntrySubscription = this.sharedDataService.dispatcherUpdateEntryShared.subscribe(data => {
      const calendarEntry = data['calItem'];
      const status = data['status'];
      // console.log('update cal data', data);
      data['calItem']['newStatus'] = data['status'];
      this.updateCalendar(data['calItem']);
    });

    this.setSelectedCsIdSubscription = this.sharedDataService.selectedCsIdShared.subscribe(data => {
      if (data) {
        this.findByCsId(Number(localStorage.getItem('medcseppCsId')));
        this.getWorklist(Number(localStorage.getItem('medcseppCsId')));
      }
      this.selectedCsId = data;
    });

    this.findByCsId(Number(localStorage.getItem('medcseppCsId')));
    this.getWorklist(Number(localStorage.getItem('medcseppCsId')));
    if (this.autoRefreshCalendarData) {
      clearInterval(this.autoRefreshCalendarData);
    }

    this.autoRefreshCalendarData = setInterval(() => {
      this.getWorklist(Number(localStorage.getItem('medcseppCsId')));
    }, 10000);

  }

  ngOnDestroy() {
    if (this.updateCalendarEntrySubscription) {
      this.updateCalendarEntrySubscription.unsubscribe();
    }
    if (this.autoRefreshCalendarData) {
      clearInterval(this.autoRefreshCalendarData);
    }
  }

  findByCsId(csId: number) {
    // console.log('////////////////');
    // console.log('authority data csid', csId);
    this.apiService.findbyCsid(csId).subscribe((data: FindByCsIdResponse) => {
      // console.log('authority data', data);
      this.authorityData = data.callerSystemAuthority;
    }, error => {
      console.error('authority data', error);
    });
  }

  getWorklist(csId: number) {
    // console.log('getWorklist csId', csId);
    const worklist = new Array<object>();
    this.apiService.getWorkList(csId).subscribe((response: Array<GetWorkListResponse>) => {
      // response.sort((a, b) => a.timeFrom < b.timeFrom ? -1 : a.timeFrom > b.timeFrom ? 1 : 0);
      if (this.authorityData && this.authorityData.authorityTypeId &&
        this.generalService.checkModuleAccess(this.authorityData.authorityTypeId).worklist) {
        response.forEach(element => {
          element['timeStamp'] = this.sharedDataService.DateStringConvert(element['timeFrom']);
          worklist.push(element);
        });
        this.worklist = response;
      } else {
        response.forEach(element => {
          if (element.nauStatus === 'DN' || element.nauStatus === 'DH') { // element.nauStatus === 'DN' || element.nauStatus === 'DH'
            element['timeStamp'] = this.sharedDataService.DateStringConvert(element['timeFrom']);
            worklist.push(element);
          }
        });
      }
      // console.log ('------------ worklist before', worklist);
      // worklist.sort(this.sortWorklist);
      this.worklist = worklist;
      // console.log('worklist', worklist);
    }, error => {
      console.error(error);
    });
  }

  sortWorklist(a, b) {
    // console.log('sort', a);
    if (a['timeStamp'] > b['timeStamp']) {
      return 1;
    } else if (a['timeStamp'] < b['timeStamp']) {
      return -1;
    }
    return 0;
  }

  returnPatientToDispatcher(csId, worklistRecord) {
    worklistRecord['newStatus'] = 'DH';
    this.updateCalendar(worklistRecord);
}

  callPatient(csId: number, calendarId: number) {
    const request: CallPatientRequest = {
      csId,
      calendarId
    };
    this.apiService.callPatient(request).subscribe((response: CallPatientResponse) => {
      if (response.code > 0) {
        // console.log(response);
        this.getWorklist(Number(this.generalService.getCurrentCsId()));
      } else if (response.code === -118) {
        this.error = response.code;
        this.errorMessage = 'Nem található a sorban elem!';
        setTimeout(() => {
          this.error = 0;
        }, 2000);
      }
    }, error => {
      console.error(error);
    });
  }

  updateCalendar($event) {
    const currentStatus = $event.nauStatus;

    if (!$event['newStatus'] || $event['newStatus'] === '') {
      if (currentStatus === 'DH') {
        status = 'DN';
      } else if (currentStatus === 'DN') {
        status = 'DD';
      }
    } else {
      status = $event['newStatus'];
    }

    // console.log('getMessageHeader', this.generalService.getMessageHeader());
    const request: UpdateCalendarRequest = {
      calModReasonCode: null,
      calendarId: $event.calendarId,
      callNumber: $event.callNumber,
      deviceId: null,
      diagnosisComment: null,
      doctorId: null,
      patientId: null,
      status,
      messageHeader: {
        currentTapass: this.authService.getUserData()['ekCardNo'],
        currentRole: this.sharedDataService.selectedRole['roleCode'],
        currentServicePointId: this.sharedDataService.selectedRole['spId'],
        locale: 'hu',
        deviceId: this.authService.getUUID()
      }
    };
    this.apiService.updateCalendar(request).subscribe((response: BaseResponse) => {
      this.getWorklist(Number(this.generalService.getCurrentCsId()));
    }, error => {
      console.error(error);
    });
  }


  arrivePatient(calendarId: string, ssn: string) {
    const request: ArrivePatientRequest = {
      csId: Number(this.generalService.getCurrentCsId()),
      ssn,
      calendarId
    };
    this.apiService.arrivePatient(request).subscribe((response: ArrivePatientResponse) => {
      this.getWorklist(request.csId);
      this.printCallNumber(response.callNumber);
    }, error => {
      console.error(error);
    });
  }

  saveAppointment() {
    // this.apiService.getTreeForCsId(Number(this.generalService.getCurrentCsId())).subscribe((response: Array<GetTreeForCsidResponse>) => {

    //   let csIdOfServicePoint = null;

    // response.forEach(element => {
    //   if (element.type === 'C') {
    //     csIdOfServicePoint = element.csId;
    //   }
    // });
    let etId = 0;
    if (this.sharedDataService.getSelectedEventType()) {
      etId = this.sharedDataService.getSelectedEventType().etId;
    }
    const participantId = null;

    const request: SaveAppointmentRequest = {
      csId: Number(this.generalService.getCurrentCsId()),
      emergencyAppointmentDto: {
        eventTypeId: etId,
        participantAlias: this.arriveInput.value,
        participantId: participantId
      },
      longDescription: this.arriveInput.value,
      ssn: ' '
    };
    if (request.longDescription.length >= 5) {
      this.apiService.saveAppointmentForParent(request).subscribe((saveAppointmentResponse: BaseResponse) => {
        // console.log('saveAppointment: ', saveAppointmentResponse.message);
        this.arriveInput.reset();
        this.getWorklist(Number(this.generalService.getCurrentCsId()));
        this.printCallNumber(saveAppointmentResponse.code);
        this.saveResult = 0;
        const maxEmergency = saveAppointmentResponse['quantity']['maximalQuantity'] - saveAppointmentResponse['quantity']['actualQuantity'];
        this.successMessage = 'Még ' + maxEmergency + ' sürgős megjelenés rögzíthető a mai napon.';
        setTimeout(() => { this.saveResult = null; }, 3000);
      }, error => {
        console.error(error);
      });
    } else {
      this.error = 1;
      this.errorMessage = 'Legalább 5 karakter hosszú karakterlánc megadása szükséges!';
      setTimeout(() => {
        this.error = 0;
      }, 2000);
    }
    // }, error => {
    //   error.log(error);
    // });
    // }
  }

  printCallNumber(callNumber) {

    const params = {
      callNumber,
      csId: localStorage.getItem('printerCsId')
    };

    this.apiService.printCallNumber(params).subscribe((data: Response) => {
      // console.log(data);

    }, error => {
      console.error(error);

    });
  }

  searchByTaj(ssn: string) {
    const yOffset = -150;
    let record = document.getElementById(ssn) as HTMLElement;
    if (record && record.tagName === 'SPAN') {
      record = record.parentElement;
    }
    if (record) {
      const y = record.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
      // record.scrollIntoView({ behavior: 'smooth' });

      record.style.backgroundColor = "rgb(168, 50, 50)";
      setTimeout(() => {
        record.style.backgroundColor = ''
      }, 2000)
    } else {
      // console.log('record :  ' + record);
      this.error = 2;
      this.errorMessage = 'Nincs találat!';
      setTimeout(() => {
        this.error = 0;
      }, 2000);
    }
  }

  setPatientData(data: any, type: string) {
    // console.log('---- calendar setpatientdata');
    // console.log('type', type);
    // console.log('data', data);
    data['NGdestinationService'] = type;
    this.sharedDataService.setPatientData(data);
  }

  log(csId, data) {
    console.log('csid', csId);
    console.log('data', data);
  }

}
