<!-- Modal -->
<div class="modal" id="idopontkezelo" tabindex="-1" role="dialog" aria-labelledby="Időpontkezelő" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document" (window:resize)="onResize($event)">

        <div [ngClass]="{'hidden': !deleteAppointmentModal}" class="bookAppointmentQuitConfirmation">
            <div class="quitConfirmationContent">
                <!-- <div *ngIf="bookedCellDisplayData && bookedCellDisplayData.length > 0" class="alert alert-primary" role="alert">
                    <h4>Adatok: </h4>
                    <h5 *ngFor="let bookedCellInforations of bookedCellDisplayData.split(',')"><b>{{bookedCellInforations}}</b></h5>
                </div> -->
                <h3>Kérem adja meg a törlés okát</h3>
                <div class="col-md-12 mt-4">
                    <form [formGroup]="modReason">
                        <ng-select class="full-width mb-4" [searchable]="true" placeholder="Válasszon..." formControlName="modReasonSelect" [items]="calendarModReasons" bindLabel="description" [clearable]="false" loadingText="Betöltés..." notFoundText="Nincs találat">
                            <!-- [loading]="availServiceLoading" -->
                        </ng-select>
                    </form>
                    <div class="row">
                        <div class="col-md-6">
                            <button type="button" class="btn btn-secondary" (click)="showDeleteAppointment(false)" [disabled]="deleteInProgress">Mégsem</button>
                        </div>
                        <div class="col-md-6 text-right">
                            <button type="button" class="btn btn-danger" (click)="deleteAppointment()" [disabled]="deleteInProgress">
              <i *ngIf="deleteInProgress" class="fa fa-spinner fa-spin"></i>
              Törlés
            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="modal-content">
            <div class="modal-header">
                <div id="saveSuccess" [ngClass]="{'showResult': bookingSaveResult == 0}">
                    {{ successMessage }}
                </div>
                <div id="saveError" [ngClass]="{'showResult': bookingSaveResult == -1}">
                    <div class="closeResult" (click)="closeResult()">x</div>
                    <div class="saveErrorContent">
                        <span>{{ saveBookingErrorDescription }}</span>
                    </div>
                </div>

                <h5 class="modal-title" id="exampleModalLongTitle">Időpontkezelő</h5>
                <div>
                    <button type="button" class="btn btn-primary ml-1 mb-1" (click)="openHelp('#appointment')" title="kézikönyv">
                <i class="fa fa-question-circle"></i>
              </button>
                    <button id="closeButton" type="button" class="btn btn-secondary ml-1 mb-1" data-dismiss="modal" (click)="resetBookingModal()">X</button>
                </div>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-md-6">
                            <div class="row form-group">
                                <div class="col-md-6">
                                    <form [formGroup]="serviceSelect">
                                        <!-- <ng-select *ngIf="Brand !== 'UDMED'" class="full-width" [searchable]="true" placeholder="Válasszon szolgáltatást..." formControlName="service" [items]="availServic" bindLabel="displayName" [clearable]="false" loadingText="Betöltés..." notFoundText="Nincs találat">
                                           
                                        </ng-select> -->
                                        <ng-container *ngIf="szentVincent">
                                            <ng-select class="full-width" [searchable]="true" placeholder="Válasszon szolgáltatást..." formControlName="eventType" [items]="etListFiltered" bindLabel="etName" (change)="filterSp($event)" [clearable]="true" loadingText="Betöltés..." notFoundText="Nincs találat">
                                                <!-- [loading]="availServiceLoading" -->
                                            </ng-select>

                                            <ng-select class="full-width mt-3" [searchable]="true" placeholder="Válasszon szolgáltatási pontot..." formControlName="servicePoint" [items]="spListFiltered" bindLabel="spName" (change)="filterEt($event)" [clearable]="true" loadingText="Betöltés..."
                                                notFoundText="Nincs találat">
                                                <!-- [loading]="availServiceLoading" -->
                                            </ng-select>
                                            <ng-select *ngIf="calendarStatus !== 'S'" class="full-width mt-3" [searchable]="true" placeholder="Válasszon eszközt..." formControlName="equipment" [items]="equipmentList" bindLabel="name" bindValue="type" [clearable]="true" loadingText="Betöltés..."
                                                notFoundText="Nincs találat">
                                                <!-- [loading]="availServiceLoading" -->
                                            </ng-select>
                                        </ng-container>
                                        <ng-container *ngIf="!szentVincent">
                                            <ng-select *ngIf="Brand === 'UDMED'" class="full-width" [searchable]="true" placeholder="Válasszon szolgáltatást..." formControlName="service" [items]="availServic" bindLabel="etName" [clearable]="false" loadingText="Betöltés..." notFoundText="Nincs találat">
                                                <!-- [loading]="availServiceLoading" -->
                                            </ng-select>
                                            <ng-select *ngIf="Brand !== 'UDMED'" class="full-width" [searchable]="true" placeholder="Válasszon szolgáltatást..." formControlName="service" [items]="availServic" bindLabel="displayName" [clearable]="false" loadingText="Betöltés..." notFoundText="Nincs találat">
                                                <!-- [loading]="availServiceLoading" -->
                                            </ng-select>
                                        </ng-container>
                                    </form>
                                </div>
                                <div class="col-md-6">
                                    <button *ngIf="calendarStatus == 'F' || calendarStatus == ''" type="button" class="btn btn-primary" [disabled]="(!patientAlias.value && !bookingDetails.controls.patientName.value) || saveBookingInProgress" (click)="saveAppointment($event)">
                                        <i *ngIf="saveBookingInProgress" class="fa fa-spinner fa-spin"></i>
                                        Foglalás mentése
                                    </button>

                                    <!-- <button *ngIf="calendarStatus == 'S'" type="button" class="btn btn-primary"
                    [disabled]="!bookingDataValid || saveBookingInProgress" (click)="saveAppointment($event)">
                    Módosítás
                  </button> -->

                                    <!-- <button *ngIf="calendarStatus == 'S'" type="button" class="btn btn-danger ml-4"
                    [disabled]="!bookingDataValid || saveBookingInProgress" (click)="showDeleteAppointment(true)">
                    Törlés
                  </button> -->
                                    <button *ngIf="calendarStatus == 'S'" type="button" class="btn btn-danger ml-4" [disabled]="saveBookingInProgress || (!patientAlias.value && !bookingDetails.controls.patientName.value)" (click)="showDeleteAppointment(true)">Törlés</button>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="row form-group" [ngClass]="{'hidden': Brand === 'UDMED'}">
                                    <input type="text" [formControl]="patientAlias" (change)="onPatientAliasChange($event)" placeholder="Adjon meg páciens alias-t" class="form-control">
                                </div>

                                <div class="row form-group">
                                    <form [formGroup]="bookingDetails" class="form-dropdown-select">
                                        <ng-select [searchable]="true" formControlName="patientName" placeholder="Válaszon pácienst" [items]="patientList" bindLabel="displayData" bindValue="tapass" (change)="onSelectedPatientChange($event)" [loading]="patientListLoading" loadingText="Betöltés..."
                                            notFoundText="Nincs találat">
                                        </ng-select>
                                    </form>
                                </div>


                                <div class="row form-group">
                                    <input type="text" [formControl]="comment" placeholder="Megjegyzés" class="form-control">
                                </div>

                                <div *ngIf="showSaveWithRule && (sharedDataService.getSelectedRole()['roleCode'].includes('AUTH$PL-ECHOKFT') ||
                                sharedDataService.getSelectedRole()['roleCode'].includes('AUTH$SZTVINCENT'))" class=" row form-group ">
                                    <!-- <div *ngIf="showSaveWithRule && !multiCal" class=" row form-group "> -->
                                    <label class="mr-3 " style="display: flex; align-items: center; ">Gyakoriság</label>
                                    <input type="number " min="1 " [formControl]="time " class="form-control col-sm-2 ">
                                    <ng-select [formControl]="timeType " [items]="timeTypes " class="col-sm-3 "></ng-select>
                                    <label class="mr-3 col-sm-2" style="display: flex; align-items: center; ">Időpontok mennyisége</label>
                                    <input type="number " min="2 " step="1" [formControl]="quantity " class="form-control col-sm-2 " placeholder="db ">
                                </div>
                                <!-- <div class="row " *ngIf="calendarStatus !=='S' ">
                  <ngbd-datepicker [displayMonths]="calendarDisplayMonths "></ngbd-datepicker>
                </div> -->
                            </div>
                        </div>

                        <div class="col-md-6 ">
                            <div class="row " *ngIf="!multiCal && calendarStatus !=='S' ">
                                <ngbd-datepicker [displayMonths]="calendarDisplayMonths "></ngbd-datepicker>
                            </div>
                            <div class="row " style="background-color: #c9e2ec; " *ngIf="multiCal ">
                                <span style="font-weight: bold; padding: 15px; ">{{start | date: 'yyyy-MM-dd HH:mm'}} - {{end | date: 'HH:mm'}}</span>
                            </div>
                            <!-- <div *ngIf="!freeAppointmentsLoading " class="calendar-list ">
                <h4>Szabad időpontok: {{ dateFrom | date:'yyyy-MM-dd' }}</h4>
                <hr>


                <div *ngFor="let calendarEntry of calendarListDataAll; let i=i ndex "
                  class="appointment_booking {{ calendarItemStatus(calendarEntry) }} ">
                  <span class="addAppointmentItem " (click)="showAppointmentDetails(i) ">?</span>
                  <span class="addAppointmentItem " (click)="selectAppointment(i) "
                    *ngIf="calendarEntry[ 'calendarStatus']=='F' && calendarEntry[ 'isSelected'] !==t rue && calendarStatus!=='S' ">+</span>
                  <span class="removeAppointmentItem " (click)="deSelectAppointment(i) "
                    *ngIf="calendarEntry[ 'isSelected']==t rue && calendarStatus!=='S' ">-</span>
                    <span class="cancelAppointmentItem " *ngIf="calendarEntry[ 'isSelected']==t rue && calendarStatus=='S' ">
                      !
                    </span>
                    <div *ngIf="calendarEntry[ 'eventTypeFk']=='12234835' || calendarEntry[ 'eventTypeFk']=='52342809' "><strong>&nbsp;</strong></div>
                  {{ calendarEntry['displayData'] }}
                  <div *ngIf="calendarEntry[ 'eventTypeFk'] !='12234835' && calendarEntry[ 'eventTypeFk'] !='52342809' "><strong>{{ calendarEntry['dateFrom'] | date:'H:mm' }}-{{ calendarEntry['dateTo'] | date:'H:mm'}}</strong>
                   </div>
                </div>
              </div>

              <div *ngIf="freeAppointmentsLoading " class="calendar-list ">
                <h4>Szabad időpontok: {{ dateFrom | date:'yyyy-MM-dd' }}</h4>
                <hr>
                <div class="osahanloading "></div>
              </div> -->
                        </div>
                    </div>

                    <app-scheduler-modal *ngIf="!multiCal && weekSchedule.length> 0 && !bookingDataLoading" [weekScheduleDay]="weekSchedule" [tempCells]="tempCells" [availableCells]="freeCells" [coCells]="coCells" [fromBooking]="true" [dayLenghtInHours]="dayLenghtInHours"
                        [bookedCells]="bookedCells" (bookCells)="bookCells($event)" (bookedCellClick)="showBookedCellInformationModal($event)" (dateRangeChange)="changeDateRange($event)"></app-scheduler-modal>
                </div>
            </div>
        </div>
    </div>
</div>