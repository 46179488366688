import { version } from '../../package.json';


// prod
  // const naucommUrl =  'https://naucomm.nauticom.hu/';
  // const callerSystemUrl = 'https://cs.nauticom.hu/caller-system/';
  // const apiUrl = 'https://naucomm.nauticom.hu';

// dev
const naucommUrl = 'https://ekrest.nauticom.hu/';
const callerSystemUrl = 'https://testcallersystem.nauticom.hu/caller-system/';
const apiUrl = 'https://ekrest.nauticom.hu';

export const environment = {
    production: false,
    naucommUrl: naucommUrl,
    callerSystemUrl: callerSystemUrl,
    apiUrl: apiUrl,
    printerUrl: 'http://172.16.16.211:9100',
    // apiUrl: 'https://ekrest.nauticom.hu',
    timeZoneCompensation: 3600000,
    sessionValidity: 28800000,
    weeklyBookingViewEnabled: false,
    version: version,
    eesztEnabled: false,
    calendarRefreshTimer: {
      DEFAULT: 60000,
      NAUCARE: 60000,
      MEDCSEPP: 10000,
      UDMED: 60000
    },
    // acDeviceListTemp: [
    //   {deviceId: 'A6012F357E4DD8AFE05011AC0D09767C', deviceName: 'Beléptető eszköz 1'},
    //   {deviceId: 'A6012F357E4ED8AFE05011AC0D09767C', deviceName: 'Beléptető eszköz 2'},
    //   {deviceId: 'A6012F357E4FD8AFE05011AC0D09767C', deviceName: 'Beléptető eszköz 3'},
    //   {deviceId: 'A6012F357E50D8AFE05011AC0D09767C', deviceName: 'Beléptető eszköz 4'}
    // ],
    acDeviceListTemp: [
      {deviceId: 'A60137836BF53E8BE05011AC030148B1', deviceName: 'Onkológia BE'},
      {deviceId: 'A60137836BF63E8BE05011AC030148B1', deviceName: 'Onkológia KI'},
      {deviceId: 'A60137836BF73E8BE05011AC030148B1', deviceName: 'Mentő 1'},
      {deviceId: 'A60137836BF83E8BE05011AC030148B1', deviceName: 'Mentő 2'}
    ],
    countryList: [
      {
        'code': 'HUN',
        'name': 'Magyarország'
      },
      {
        'code': 'ALA',
        'name': 'Áland'
      },
      {
        'code': 'ALB',
        'name': 'Albánia'
      },
      {
        'code': 'DZA',
        'name': 'Algéria'
      },
      {
        'code': 'UMI',
        'name': 'Amerikai Csendes-óceáni szigetek'
      },
      {
        'code': 'USA',
        'name': 'Amerikai Egyesült Államok'
      },
      {
        'code': 'ASM',
        'name': 'Amerikai Szamoa'
      },
      {
        'code': 'VIR',
        'name': 'Amerikai Virgin-szigetek'
      },
      {
        'code': 'AND',
        'name': 'Andorra'
      },
      {
        'code': 'AGO',
        'name': 'Angola'
      },
      {
        'code': 'AIA',
        'name': 'Anguilla'
      },
      {
        'code': 'ATA',
        'name': 'Antarktisz'
      },
      {
        'code': 'ATG',
        'name': 'Antigua és Barbuda'
      },
      {
        'code': 'ARG',
        'name': 'Argentína'
      },
      {
        'code': 'ABW',
        'name': 'Aruba'
      },
      {
        'code': 'AUS',
        'name': 'Ausztrália'
      },
      {
        'code': 'AUT',
        'name': 'Ausztria'
      },
      {
        'code': 'AZE',
        'name': 'Azerbajdzsán'
      },
      {
        'code': 'BHS',
        'name': 'Bahama-szigetek'
      },
      {
        'code': 'BHR',
        'name': 'Bahrein'
      },
      {
        'code': 'BGD',
        'name': 'Banglades'
      },
      {
        'code': 'BRB',
        'name': 'Barbados'
      },
      {
        'code': 'BEL',
        'name': 'Belgium'
      },
      {
        'code': 'BLZ',
        'name': 'Belize'
      },
      {
        'code': 'BEN',
        'name': 'Benin'
      },
      {
        'code': 'BMU',
        'name': 'Bermuda'
      },
      {
        'code': 'BTN',
        'name': 'Bhután'
      },
      {
        'code': 'GNB',
        'name': 'Bissau-Guinea'
      },
      {
        'code': 'BOL',
        'name': 'Bolívia'
      },
      {
        'code': 'BIH',
        'name': 'Bosznia-Hercegovina'
      },
      {
        'code': 'BWA',
        'name': 'Botswana'
      },
      {
        'code': 'BVT',
        'name': 'Bouvet-sziget'
      },
      {
        'code': 'BRA',
        'name': 'Brazília'
      },
      {
        'code': 'IOT',
        'name': 'Brit Indiai-óceáni Terület'
      },
      {
        'code': 'VGB',
        'name': 'Brit Virgin-szigetek'
      },
      {
        'code': 'BRN',
        'name': 'Brunei'
      },
      {
        'code': 'BGR',
        'name': 'Bulgária'
      },
      {
        'code': 'BFA',
        'name': 'Burkina Faso'
      },
      {
        'code': 'BDI',
        'name': 'Burundi'
      },
      {
        'code': 'CHL',
        'name': 'Chile'
      },
      {
        'code': 'CYP',
        'name': 'Ciprus'
      },
      {
        'code': 'COM',
        'name': 'Comore-szigetek'
      },
      {
        'code': 'COK',
        'name': 'Cook-szigetek'
      },
      {
        'code': 'CRI',
        'name': 'Costa Rica'
      },
      {
        'code': 'TCD',
        'name': 'Csád'
      },
      {
        'code': 'CZE',
        'name': 'Csehország'
      },
      {
        'code': 'DNK',
        'name': 'Dánia'
      },
      {
        'code': 'ZAF',
        'name': 'Dél-afrikai Köztársaság'
      },
      {
        'code': 'SGS',
        'name': 'Déli-Georgia és Déli-Sandwich-szigetek'
      },
      {
        'code': 'DMA',
        'name': 'Dominikai Közösség'
      },
      {
        'code': 'DOM',
        'name': 'Dominikai Köztársaság'
      },
      {
        'code': 'DJI',
        'name': 'Dzsibuti'
      },
      {
        'code': 'ECU',
        'name': 'Ecuador'
      },
      {
        'code': 'GNQ',
        'name': 'Egyenlítői-Guinea'
      },
      {
        'code': 'ARE',
        'name': 'Egyesült Arab Emírségek'
      },
      {
        'code': 'GBR',
        'name': 'Egyesült Királyság'
      },
      {
        'code': 'EGY',
        'name': 'Egyiptom'
      },
      {
        'code': 'CIV',
        'name': 'Elefántcsontpart'
      },
      {
        'code': 'SLV',
        'name': 'El Salvador'
      },
      {
        'code': 'ERI',
        'name': 'Eritrea'
      },
      {
        'code': 'MNP',
        'name': 'Északi-Mariana-szigetek'
      },
      {
        'code': 'EST',
        'name': 'Észtország'
      },
      {
        'code': 'ETH',
        'name': 'Etiópia'
      },
      {
        'code': 'FLK',
        'name': 'Falkland-szigetek'
      },
      {
        'code': 'BLR',
        'name': 'Fehéroroszország'
      },
      {
        'code': 'FRO',
        'name': 'Feröer'
      },
      {
        'code': 'FJI',
        'name': 'Fidzsi'
      },
      {
        'code': 'FIN',
        'name': 'Finnország'
      },
      {
        'code': 'ATF',
        'name': 'Francia Déli és Antarktiszi Területek'
      },
      {
        'code': 'GUF',
        'name': 'Francia Guyana'
      },
      {
        'code': 'FRA',
        'name': 'Franciaország'
      },
      {
        'code': 'PYF',
        'name': 'Francia Polinézia'
      },
      {
        'code': 'PHL',
        'name': 'Fülöp-szigetek'
      },
      {
        'code': 'GAB',
        'name': 'Gabon'
      },
      {
        'code': 'GMB',
        'name': 'Gambia'
      },
      {
        'code': 'GHA',
        'name': 'Ghána'
      },
      {
        'code': 'GIB',
        'name': 'Gibraltár'
      },
      {
        'code': 'GRC',
        'name': 'Görögország'
      },
      {
        'code': 'GRD',
        'name': 'Grenada'
      },
      {
        'code': 'GRL',
        'name': 'Grönland'
      },
      {
        'code': 'GEO',
        'name': 'Grúzia'
      },
      {
        'code': 'GLP',
        'name': 'Guadeloupe'
      },
      {
        'code': 'GUM',
        'name': 'Guam'
      },
      {
        'code': 'GTM',
        'name': 'Guatemala'
      },
      {
        'code': 'GIN',
        'name': 'Guinea'
      },
      {
        'code': 'GUY',
        'name': 'Guyana'
      },
      {
        'code': 'HTI',
        'name': 'Haiti'
      },
      {
        'code': 'HMD',
        'name': 'Heard-sziget és McDonald-szigetek'
      },
      {
        'code': 'ANT',
        'name': 'Holland Antillák'
      },
      {
        'code': 'NLD',
        'name': 'Hollandia'
      },
      {
        'code': 'HND',
        'name': 'Honduras'
      },
      {
        'code': 'HKG',
        'name': 'Hongkong'
      },
      {
        'code': 'HRV',
        'name': 'Horvátország'
      },
      {
        'code': 'IND',
        'name': 'India'
      },
      {
        'code': 'IDN',
        'name': 'Indonézia'
      },
      {
        'code': 'IRQ',
        'name': 'Irak'
      },
      {
        'code': 'IRN',
        'name': 'Irán'
      },
      {
        'code': 'IRL',
        'name': 'Írország'
      },
      {
        'code': 'ISL',
        'name': 'Izland'
      },
      {
        'code': 'ISR',
        'name': 'Izrael'
      },
      {
        'code': 'JAM',
        'name': 'Jamaica'
      },
      {
        'code': 'JPN',
        'name': 'Japán'
      },
      {
        'code': 'YEM',
        'name': 'Jemen'
      },
      {
        'code': 'JOR',
        'name': 'Jordánia'
      },
      {
        'code': 'CYM',
        'name': 'Kajmán-szigetek'
      },
      {
        'code': 'KHM',
        'name': 'Kambodzsa'
      },
      {
        'code': 'CMR',
        'name': 'Kamerun'
      },
      {
        'code': 'CAN',
        'name': 'Kanada'
      },
      {
        'code': 'CXR',
        'name': 'Karácsony-sziget'
      },
      {
        'code': 'QAT',
        'name': 'Katar'
      },
      {
        'code': 'KAZ',
        'name': 'Kazahsztán'
      },
      {
        'code': 'TLS',
        'name': 'Kelet-Timor'
      },
      {
        'code': 'KEN',
        'name': 'Kenya'
      },
      {
        'code': 'CHN',
        'name': 'Kína'
      },
      {
        'code': 'KGZ',
        'name': 'Kirgizisztán'
      },
      {
        'code': 'KIR',
        'name': 'Kiribati'
      },
      {
        'code': 'CCK',
        'name': 'Kókusz (Keeling)-szigetek'
      },
      {
        'code': 'COL',
        'name': 'Kolumbia'
      },
      {
        'code': 'COD',
        'name': 'Kongói Demokratikus Köztársaság (Zaire)'
      },
      {
        'code': 'COG',
        'name': 'Kongói Köztársaság (Kongó)'
      },
      {
        'code': 'KOR',
        'name': 'Dél-Korea (Koreai Köztársaság)'
      },
      {
        'code': 'PRK',
        'name': 'Észak-Korea (Koreai NDK)'
      },
      {
        'code': 'CAF',
        'name': 'Közép-Afrika'
      },
      {
        'code': 'CUB',
        'name': 'Kuba'
      },
      {
        'code': 'KWT',
        'name': 'Kuvait'
      },
      {
        'code': 'LAO',
        'name': 'Laosz'
      },
      {
        'code': 'POL',
        'name': 'Lengyelország'
      },
      {
        'code': 'LSO',
        'name': 'Lesotho'
      },
      {
        'code': 'LVA',
        'name': 'Lettország'
      },
      {
        'code': 'LBN',
        'name': 'Libanon'
      },
      {
        'code': 'LBR',
        'name': 'Libéria'
      },
      {
        'code': 'LBY',
        'name': 'Líbia'
      },
      {
        'code': 'LIE',
        'name': 'Liechtenstein'
      },
      {
        'code': 'LTU',
        'name': 'Litvánia'
      },
      {
        'code': 'LUX',
        'name': 'Luxemburg'
      },
      {
        'code': 'MKD',
        'name': 'Macedónia'
      },
      {
        'code': 'MDG',
        'name': 'Madagaszkár'
      },
      {
        'code': 'ZZZ',
        'name': 'Magyar ország'
      },
      {
        'code': 'MAC',
        'name': 'Makaó'
      },
      {
        'code': 'MYS',
        'name': 'Malajzia'
      },
      {
        'code': 'MWI',
        'name': 'Malawi'
      },
      {
        'code': 'MDV',
        'name': 'Maldív-szigetek'
      },
      {
        'code': 'MLI',
        'name': 'Mali'
      },
      {
        'code': 'MLT',
        'name': 'Málta'
      },
      {
        'code': 'MAR',
        'name': 'Marokkó'
      },
      {
        'code': 'MHL',
        'name': 'Marshall-szigetek'
      },
      {
        'code': 'MTQ',
        'name': 'Martinique'
      },
      {
        'code': 'MRT',
        'name': 'Mauritánia'
      },
      {
        'code': 'MUS',
        'name': 'Mauritius'
      },
      {
        'code': 'MYT',
        'name': 'Mayotte'
      },
      {
        'code': 'MEX',
        'name': 'Mexikó'
      },
      {
        'code': 'MMR',
        'name': 'Mianmar'
      },
      {
        'code': 'FSM',
        'name': 'Mikronézia'
      },
      {
        'code': 'MDA',
        'name': 'Moldova'
      },
      {
        'code': 'MCO',
        'name': 'Monaco'
      },
      {
        'code': 'MNG',
        'name': 'Mongólia'
      },
      {
        'code': 'MNE',
        'name': 'Montenegró'
      },
      {
        'code': 'MSR',
        'name': 'Montserrat (Egyesült Királyság)'
      },
      {
        'code': 'MOZ',
        'name': 'Mozambik'
      },
      {
        'code': 'NAM',
        'name': 'Namíbia'
      },
      {
        'code': 'NRU',
        'name': 'Nauru'
      },
      {
        'code': 'DEU',
        'name': 'Németország'
      },
      {
        'code': 'NPL',
        'name': 'Nepál'
      },
      {
        'code': 'NIC',
        'name': 'Nicaragua'
      },
      {
        'code': 'NER',
        'name': 'Niger'
      },
      {
        'code': 'NGA',
        'name': 'Nigéria'
      },
      {
        'code': 'NIU',
        'name': 'Niue'
      },
      {
        'code': 'NFK',
        'name': 'Norfolk-sziget'
      },
      {
        'code': 'NOR',
        'name': 'Norvégia'
      },
      {
        'code': 'ESH',
        'name': 'Nyugat-Szahara'
      },
      {
        'code': 'ITA',
        'name': 'Olaszország'
      },
      {
        'code': 'OMN',
        'name': 'Omán'
      },
      {
        'code': 'RUS',
        'name': 'Oroszország'
      },
      {
        'code': 'ARM',
        'name': 'Örményország'
      },
      {
        'code': 'PAK',
        'name': 'Pakisztán'
      },
      {
        'code': 'PLW',
        'name': 'Palau'
      },
      {
        'code': 'PSE',
        'name': 'Palesztina'
      },
      {
        'code': 'PAN',
        'name': 'Panama'
      },
      {
        'code': 'PNG',
        'name': 'Pápua Új-Guinea'
      },
      {
        'code': 'PRY',
        'name': 'Paraguay'
      },
      {
        'code': 'PER',
        'name': 'Peru'
      },
      {
        'code': 'PCN',
        'name': 'Pitcairn-szigetek'
      },
      {
        'code': 'PRT',
        'name': 'Portugália'
      },
      {
        'code': 'PRI',
        'name': 'Puerto Rico'
      },
      {
        'code': 'REU',
        'name': 'Réunion Réunion'
      },
      {
        'code': 'ROU',
        'name': 'Románia'
      },
      {
        'code': 'RWA',
        'name': 'Ruanda'
      },
      {
        'code': 'KNA',
        'name': 'Saint Kitts és Nevis'
      },
      {
        'code': 'LCA',
        'name': 'Saint Lucia'
      },
      {
        'code': 'SPM',
        'name': 'Saint-Pierre és Miquelon'
      },
      {
        'code': 'VCT',
        'name': 'Saint Vincent és a Grenadine-szigetek'
      },
      {
        'code': 'SLB',
        'name': 'Salamon-szigetek'
      },
      {
        'code': 'SMR',
        'name': 'San Marino'
      },
      {
        'code': 'STP',
        'name': 'Sao Tomé és Príncipe'
      },
      {
        'code': 'SYC',
        'name': 'Seychelle-szigetek'
      },
      {
        'code': 'SLE',
        'name': 'Sierra Leone'
      },
      {
        'code': 'ESP',
        'name': 'Spanyolország'
      },
      {
        'code': 'LKA',
        'name': 'Srí Lanka'
      },
      {
        'code': 'SUR',
        'name': 'Suriname'
      },
      {
        'code': 'CHE',
        'name': 'Svájc'
      },
      {
        'code': 'SJM',
        'name': 'Svalbard (Spitzbergák) és Jan Mayen'
      },
      {
        'code': 'SWE',
        'name': 'Svédország'
      },
      {
        'code': 'WSM',
        'name': 'Szamoa'
      },
      {
        'code': 'SAU',
        'name': 'Szaúd-Arábia'
      },
      {
        'code': 'SEN',
        'name': 'Szenegál'
      },
      {
        'code': 'SHN',
        'name': 'Szent Ilona'
      },
      {
        'code': 'SRB',
        'name': 'Szerbia'
      },
      {
        'code': 'SCG',
        'name': 'Szerbia és Montenegró'
      },
      {
        'code': 'SGP',
        'name': 'Szingapúr'
      },
      {
        'code': 'SYR',
        'name': 'Szíria'
      },
      {
        'code': 'SVK',
        'name': 'Szlovákia'
      },
      {
        'code': 'SVN',
        'name': 'Szlovénia'
      },
      {
        'code': 'SOM',
        'name': 'Szomália'
      },
      {
        'code': 'SDN',
        'name': 'Szudán'
      },
      {
        'code': 'SWZ',
        'name': 'Szváziföld'
      },
      {
        'code': 'TJK',
        'name': 'Tádzsikisztán'
      },
      {
        'code': 'TWN',
        'name': 'Tajvan'
      },
      {
        'code': 'TZA',
        'name': 'Tanzánia'
      },
      {
        'code': 'THA',
        'name': 'Thaiföld'
      },
      {
        'code': 'TGO',
        'name': 'Togo'
      },
      {
        'code': 'TKL',
        'name': 'Tokelau-szigetek'
      },
      {
        'code': 'TON',
        'name': 'Tonga'
      },
      {
        'code': 'TUR',
        'name': 'Törökország'
      },
      {
        'code': 'TTO',
        'name': 'Trinidad és Tobago'
      },
      {
        'code': 'TUN',
        'name': 'Tunézia'
      },
      {
        'code': 'TCA',
        'name': 'Turks- és Caicos-szigetek'
      },
      {
        'code': 'TUV',
        'name': 'Tuvalu'
      },
      {
        'code': 'TKM',
        'name': 'Türkmenisztán'
      },
      {
        'code': 'UGA',
        'name': 'Uganda'
      },
      {
        'code': 'NCL',
        'name': 'Új-Kaledónia'
      },
      {
        'code': 'NZL',
        'name': 'Új-Zéland'
      },
      {
        'code': 'UKR',
        'name': 'Ukrajna'
      },
      {
        'code': 'URY',
        'name': 'Uruguay'
      },
      {
        'code': 'UZB',
        'name': 'Üzbegisztán'
      },
      {
        'code': 'VUT',
        'name': 'Vanuatu'
      },
      {
        'code': 'VAT',
        'name': 'Vatikán'
      },
      {
        'code': 'VEN',
        'name': 'Venezuela'
      },
      {
        'code': 'VNM',
        'name': 'Vietnam'
      },
      {
        'code': 'WLF',
        'name': 'Wallis és Futuna'
      },
      {
        'code': 'ZMB',
        'name': 'Zambia'
      },
      {
        'code': 'ZWE',
        'name': 'Zimbabwe'
      },
      {
        'code': 'CPV',
        'name': 'Zöld-foki Köztársaság'
      },
      {
        'code': 'AFG',
        'name': 'Afganisztán'
      }
    ]
};
