<!-- Modal -->
<div class="modal" tabindex="-1" id="dolgGroupPermission" role="dialog" aria-labelledby="dolgGroupPermission" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">

    <div [ngClass]="{'hidden': !saveInProgress}" class="dolgGroupQuitConfirmation">
      <div class="quitConfirmationContent">
        Mentés folyamatban...
      </div>
    </div>

    <div [ngClass]="{'hidden': !loading}" class="dolgGroupQuitConfirmation">
      <div class="quitConfirmationContent">
        Adatok betöltése folyamatban...
      </div>
    </div>

    <div id="saveSuccess" [ngClass]="{'showResult': saveResult == 0}">
      {{ successMessage }}
    </div>

    <div id="saveError" [ngClass]="{'showResult': saveResult == -1}">
      <div class="closeResult" (click)="closeResult()">x</div>
      <div class="saveErrorContent">
        <span>{{ saveErrorDescription }}</span>
      </div>
    </div>



    <div class="modal-content">
      <div class="modal-header">

        <h5 class="modal-title">Csoportos áthaladási szabályok</h5>
        <button class="btn btn-secondary" data-dismiss="modal" (click)="resetModal()">X</button>
      </div>
      <div class="modal-body">
        <div class="container-fluid min-height">

          <div *ngIf="!loading">

            <form class="form-group" [formGroup]="requestDetails">
              <div class="col-md-12">

                <div class="row">
                  <div class="col-12">
                    <div class="row mb-3">
                      <ng-select class="mr-2 w100-90" [searchable]="true" [formControl]="employmentPosition"
                      placeholder="Válasszon munkakört" [items]="employmentDataPosition" bindLabel="x" bindValue="x"
                      [loading]="employmentDataPositionLoading" loadingText="Betöltés..." notFoundText="Nincs találat">
                    </ng-select>
                    </div>

                    <div class="row  mb-3">
                      <div class="col-md-3">
                        <div class="row">
                        <ng-select class="w100" [searchable]="true" placeholder="Beléptető eszköz"
                          formControlName="acDeviceFilter" [items]="acDeviceList" bindLabel="deviceName"
                          bindValue="deviceId" [clearable]="false" loadingText="Betöltés..."
                          notFoundText="Nincs találat" [multiple]="true">
                        </ng-select>
                      </div>
                      </div>

                      <div class="col-md-3">
                        <div class="row">
                          <div class="col-md-3">Tól:</div>
                          <div class="col-md-9">
                            <!-- <input type="date" class="form-control" [formControl]="dateFromInput"
                          (change)="setStartDate()"> -->

                            <div class="input-group mr-1">
                              <input class="form-control mb-2" placeholder="yyyy-mm-dd" name="dp"
                                formControlName="dateFromInput" ngbDatepicker #dFrom="ngbDatepicker" [minDate]="{year: 1900, month: 1, day: 1}"
                                [ngClass]="{'date-invalid': dateInvalidStart === true}">
                              <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="dFrom.toggle()"
                                  type="button"></button>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="row">
                          <div class="col-md-3">Ig:</div>
                          <div class="col-md-9">
                            <!-- <input type="date" class="form-control" [formControl]="dateToInput"
                          (change)="setEndDate()"> -->

                            <div class="input-group mr-1">
                              <input class="form-control mb-2" placeholder="yyyy-mm-dd" name="dp"
                                formControlName="dateToInput" ngbDatepicker #dTo="ngbDatepicker" [minDate]="{year: 1900, month: 1, day: 1}"
                                [ngClass]="{'date-invalid': dateInvalidEnd === true}">
                              <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="dTo.toggle()"
                                  type="button"></button>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="col-md-3">
                        <button class="btn btn-primary" (click)="onManageCheckPermission()"
                          [disabled]="dateInvalidEnd || dateInvalidStart || requestDetails.invalid">
                          Mentés
                        </button>
                      </div>
                    </div>





                  </div>
                </div>
              </div>
            </form>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>
